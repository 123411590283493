import { ItemProperty, ItemPropertyType } from '@/components/Properties/types'
import { TranslationHelper } from '@/context/Locale/Locale'
import { DomainDto, StereotypeDto } from '@/api/models'
import { TableColumn, TableConstraint } from '@/api/schemas'
import { TableColumnFlat } from '@/store/modules/table/types'
import { useMemo } from 'react'

const customDomainPredicate = (
	domains: DomainDto[],
	constraints: TableConstraint[]
) => (item: TableColumnFlat) =>
	!domains.find(x => x.id === item.domainId && x.custom === true) ||
	isPrimaryOrForeignKey(constraints)(item)

const isPrimaryOrForeignKey = (constraints: TableConstraint[]) => (
	item: TableColumn
) =>
	!!constraints.find(
		x =>
			(x.constraintType === TableConstraint.ConstraintTypeEnum.PRIMARY_KEY ||
				x.constraintType === TableConstraint.ConstraintTypeEnum.FOREIGN_KEY) &&
			x.columns.find(c => c.uuid === item.uuid)
	)

const foreignKeyValue = (constraints: TableConstraint[]) => (
	item: TableColumn
) =>
	!!constraints.find(
		x =>
			x.constraintType === TableConstraint.ConstraintTypeEnum.FOREIGN_KEY &&
			x.columns.find(c => c.uuid === item.uuid)
	)

const primaryKeyValue = (constraints: TableConstraint[]) => (
	item: TableColumn
) => {
	const keyOrder = constraints
		.filter(
			x => x.constraintType === TableConstraint.ConstraintTypeEnum.PRIMARY_KEY
		)
		.map(x => x.columns.findIndex(c => c.uuid === item.uuid))
		.find(x => x !== undefined && x >= 0)

	return keyOrder !== undefined ? `PK [${keyOrder + 1}]` : null
}

export const useColumnProperties = (
	t: TranslationHelper,
	domains: DomainDto[],
	constraints: TableConstraint[],
	stereotypes: { data: StereotypeDto[] | undefined; reloading: boolean },
	canGenerateNaming: boolean
) =>
	useMemo<ItemProperty<TableColumnFlat>[]>(() => {
		return [
			{
				field: 'name',
				label: t('TABLE_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				flex: 1.7,
				autoWidth: true,
				propertiesWidth: '50%',
				showWhenPanelOpened: true
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				flex: 1.25,
				autoWidth: true,
				propertiesWidth: '50%',
				showWhenPanelOpened: true,
				disabled: canGenerateNaming,
				disableGenerateCode: !canGenerateNaming
			},
			{
				field: 'description',
				label: t('TABLE_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				flex: 1.5,
				width: 100
			},
			{
				field: 'comment',
				label: t('TABLE_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				flex: 1.5,
				width: 100
			},
			{
				field: 'domainId',
				label: t('TABLE_COLUMNS_DOMAIN'),
				type: ItemPropertyType.OPTION,
				flex: 1,
				optionValue: 'id',
				optionLabel: 'name',
				options: domains,
				isNumeric: true,
				disabled: isPrimaryOrForeignKey(constraints),
				propertiesWidth: '50%',
				width: 200
			},
			{
				field: 'dataType',
				label: t('TABLE_COLUMNS_DATA_TYPE'),
				type: ItemPropertyType.TEXT,
				flex: 1,
				disabled: customDomainPredicate(domains, constraints),
				propertiesWidth: '50%',
				width: 100
			},
			{
				field: 'defaultValue',
				label: t('DOMAIN_DEFAULT_VALUE'),
				type: ItemPropertyType.TEXT,
				flex: 1,
				propertiesWidth: '50%',
				width: 100
			},
			{
				field: 'stereotypeId',
				label: t('TABLE_COLUMNS_STEREOTYPE'),
				type: ItemPropertyType.OPTION,
				flex: 1,
				optionValue: 'id',
				optionLabel: 'name',
				options: stereotypes.data || [],
				isNumeric: true,
				isLoading: stereotypes.reloading,
				propertiesWidth: '50%',
				width: 200
			},
			{
				field: 'virtualColumnScript',
				label: t('TABLE_COLUMNS_VIRTUAL_COLUMN_SCRIPT'),
				value: column =>
					column.stereotypeCode && column.stereotypeCode === 'VIRTUAL_COLUMN'
						? column.virtualColumnScript
						: '',
				type: ItemPropertyType.ACE_EDITOR,
				disabled: column => column.stereotypeCode !== 'VIRTUAL_COLUMN',
				flex: 1,
				aceEditorProps: {
					autocompleteConfig: {
						disableSystemTablesAndColumns: true
					}
				},
				width: 100
			},
			{
				label: t('TABLE_COLUMNS_PRIMARY_KEY_ORDER'),
				tableLabel: t('TABLE_COLUMNS_PRIMARY_KEY_ORDER_SHORT'),
				type: ItemPropertyType.TEXT,
				flex: 0.3,
				propertiesWidth: '50%',
				disabled: true,
				value: primaryKeyValue(constraints),
				width: 100
			},
			{
				field: 'notNullFlag',
				label: t('TABLE_COLUMNS_MANDATORY'),
				tableLabel: t('TABLE_COLUMNS_MANDATORY_SHORT'),
				type: ItemPropertyType.CHECKBOX,
				flex: 0.3,
				disabled: customDomainPredicate(domains, constraints),
				propertiesWidth: '50%',
				width: 35
			},
			{
				label: t('TABLE_COLUMNS_FOREIGN_KEY'),
				tableLabel: t('TABLE_COLUMNS_FOREIGN_KEY_SHORT'),
				type: ItemPropertyType.CHECKBOX,
				flex: 0.3,
				propertiesWidth: '50%',
				disabled: true,
				value: foreignKeyValue(constraints),
				width: 35
			}
		]
	}, [
		canGenerateNaming,
		constraints,
		domains,
		stereotypes.data,
		stereotypes.reloading,
		t
	])
