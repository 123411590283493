import { NodeStatus } from '@/api/schemas/enums'
import { Col, FieldSet, Legend, Row } from '@/components/Layout'
import {
	Form,
	FormField,
	NamingInputFormField,
	SelectFormField,
	TextFormField,
	useGetNamingConfig
} from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { OpenedWorkflowData } from '@/store/modules/workflow/types'
import { booleanString } from '@/utils/booleans'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
	useNodeStates,
	useResponsibleUsers,
	useSystemUsers
} from '../../../../utils'
import { Container } from '../../../TableDetail/components/StyledComponents'
import ObjectSettingsControl from '@/pages/User/pages/Home/components/ObjectSettingsControl'
import { ObjectSettingsUpdateDto, StructureDto } from '@/api/models'
import { IdCodeName } from '@/api/schemas'
import { uniqueNameValidator } from '@/utils/validators'
import {
	CustomAttributeForm,
	useCustomAttributesInitValues
} from '../../../../components/CustomAttributes/hooks/useCustomAttributes'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'

interface OverviewValues {
	name: string
	code: string
	ownerId: number
	ownerName: string
	status: NodeStatus
	responsibleAnalystId: number
	responsibleAnalystName: string
	responsiblePersonId: number
	responsiblePersonName: string
	objectSettings?: IdCodeName[]
	customAttributes?: CustomAttributeForm[]
}

interface Props {
	node?: StructureDto
	data: OpenedWorkflowData
	editMode: boolean
	systemNodeId: number
	onChange: (v: Partial<OverviewValues>) => void
	/** Selected nodes for batch edit */
	selectedNodes?: StructureDto[]
}

export const Overview = ({
	node,
	onChange,
	data,
	editMode,
	systemNodeId,
	selectedNodes
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const systemUsers = useSystemUsers(systemNodeId)
	const userList = useResponsibleUsers(data)
	const nodeStates = useNodeStates(t)

	const {
		initialValuesCustomAttributes,
		parseCustomAttribute,
		customAttributes
	} = useCustomAttributesInitValues(data.form, StructureDto.TypeEnum.WORKFLOW)

	const handleChange = useCallback(
		(
			item: Partial<OverviewValues>,
			source?: FormlessFormField<OverviewValues>
		) => {
			if (!source) {
				return onChange(item)
			}

			const name = source.props.name

			if (name === 'responsiblePersonId' || name === 'responsibleAnalystId') {
				const user = userList.data?.find(u => u.id === item[name])

				const userName =
					name === 'responsiblePersonId'
						? 'responsiblePersonName'
						: 'responsibleAnalystName'

				onChange({
					[name]: user?.id,
					[userName]: user?.value
				})

				return
			}

			if (name === 'ownerId') {
				const owner = systemUsers?.data?.find(u => u.id === item[name])

				onChange({
					ownerId: owner?.id,
					ownerName: owner?.name
				})

				return
			}

			onChange(
				parseCustomAttribute(
					{
						[name]: item[name]
					},
					data.form.customAttributes,
					customAttributes
				)
			)
		},
		[
			data.form.customAttributes,
			onChange,
			parseCustomAttribute,
			systemUsers,
			userList.data
		]
	)

	const handleObjectSettingsChange = (newValue: IdCodeName[]) => {
		onChange({
			objectSettings: newValue
		})
	}

	const namingConfig = useGetNamingConfig(data.form, node)

	return (
		<Container>
			<Form<OverviewValues>
				onChange={handleChange}
				defaultValues={{ ...data.form, ...initialValuesCustomAttributes }}
				disabled={!editMode}
				key={booleanString(editMode)}
				enableFieldHighlight
			>
				<Row>
					<Col size="medium">
						<TextFormField
							title={t('WORKFLOW_NAME')}
							name="name"
							initialValue={data.form.name}
							validators={[
								uniqueNameValidator(dispatch, data.original.name, systemNodeId)
							]}
							disabled={Boolean(selectedNodes)}
						/>
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('WORKFLOW_CODE')}
							callNamingWorkingDataParams={namingConfig}
							initialValue={data.form.code}
							disabled={Boolean(selectedNodes)}
							validators={[
								uniqueNameValidator(dispatch, data.original.code, systemNodeId)
							]}
						/>
					</Col>
					<Col size="medium">
						<TextFormField
							title={t('DATABASE_CONNECTION')}
							name="databaseConnection"
							initialValue={data.form.databaseConnection}
						/>
					</Col>
				</Row>

				<ObjectSettingsControl
					name="objectSettings"
					label={t('OBJECTS')}
					onChange={handleObjectSettingsChange}
					disabled={!editMode}
					data={data.form.objectSettings}
					type={ObjectSettingsUpdateDto.StructureTypeEnum.WORKFLOW}
				/>

				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<FormField disabled={!editMode} name="status">
								<SelectFormField
									name="status"
									title={t('TABLE_GENERAL_TABLE_STATUS')}
									initialValue={data.form.status}
									options={nodeStates}
									valueKey="value"
									labelKey="label"
									allowEmpty
								/>
							</FormField>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsiblePersonId"
								title={t('RESPONSIBLE_PERSON')}
								options={userList.data || []}
								valueKey="id"
								fieldIsLoading={userList.reloading}
								labelKey="value"
								initialValue={data.form.responsiblePersonId}
								isNumeric
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsibleAnalystId"
								title={t('RESPONSIBLE_ANALYST')}
								options={userList.data || []}
								fieldIsLoading={userList.reloading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsibleAnalystId}
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="ownerId"
								title={t('NODE_OWNER')}
								options={systemUsers.data || []}
								fieldIsLoading={systemUsers.loading}
								initialValue={data.form.ownerId}
								valueKey="id"
								labelKey="name"
								isNumeric
								allowEmpty
								clearable
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</Container>
	)
}

const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
