/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * This file is generated from swagger definition. Do not modify manually!
 */

import { request, HttpMethod, ResultType } from './utils'
import {
	AcronymCreateDto,
	AcronymDto,
	AcronymUpdateDto,
	AdjustResultDto,
	ApiNodeColumnDto,
	AutocompleteResultDto,
	BusinessDomainComboDto,
	BusinessDomainDto,
	CheckResultDto,
	Company,
	CsvImportColumnDto,
	CsvImportDomainDto,
	CsvImportExistingDomainDto,
	CsvImportReturnDto,
	CsvImportRowDto,
	CsvImportTableDto,
	CustomAttribute,
	CustomAttributeComboDto,
	CustomAttributeCreateDto,
	CustomAttributeDto,
	CustomAttributeUpdateDto,
	CustomLovComboDto,
	CustomLovCreateDto,
	CustomLovDto,
	CustomLovUpdateDto,
	CustomLovValueComboDto,
	DeploymentDetailDto,
	DeploymentDto,
	DeploymentNewDto,
	DomainComboDto,
	DomainCreateDto,
	DomainDto,
	DomainTechnologyDto,
	DomainUpdateDto,
	EditUserInfoDto,
	EditUserPasswordDto,
	EnvironmentDeploymentDto,
	EnvironmentDto,
	FullTextSearchDto,
	GeneratedSqlDto,
	GitPushDto,
	HistoryDetailDto,
	HistoryDto,
	HistoryTable,
	ImpactChangeCodeListDto,
	ImpactedColumnDto,
	ImportColumn,
	ImportReturnMessage,
	ImportTable,
	JsonPatch,
	MassExportConfigDto,
	MassUpdateDto,
	MddError,
	NamingDto,
	NewHistoryDto,
	NewStructureDto,
	NewStructureUserPermissionBatchDto,
	NewStructureUserPermissionDto,
	NewUserDto,
	Number,
	ObjectSettings,
	ObjectSettingsComboDto,
	ObjectSettingsCreateDto,
	ObjectSettingsDto,
	ObjectSettingsUpdateDto,
	PageableDto,
	PageableDtoAcronymDto,
	Parent,
	Permission,
	PrePushValidationDto,
	ReferenceTable,
	RelationColumnDto,
	RelationCreateDto,
	RelationDto,
	RelationStructureDto,
	ReleaseDto,
	ReleaseEnvironmentDto,
	ReorderStructureDto,
	ResetPasswordDto,
	SessionContextDto,
	StereotypeCategoryDto,
	StereotypeCreateDto,
	StereotypeDto,
	StereotypeTypeDto,
	StereotypeUpdateDto,
	StructureAudit,
	StructureDataDto,
	StructureDetailDto,
	StructureDto,
	StructureExport,
	StructureExportDto,
	StructureObjectDto,
	StructureObjectPair,
	StructureSearchedDto,
	StructureTypeDto,
	StructureUserPermissionDto,
	StructureWorkingDataDto,
	SystemUser,
	Table,
	TableColumn,
	TableColumnDomain,
	TableColumnStereotype,
	TableConstraint,
	TableConstraintColumn,
	TableDiagramDto,
	TableDiagramLinkDto,
	TableDiagramNodeDto,
	TableIndex,
	TableIndexColumn,
	TablePermission,
	TechnicalColumnCreateDto,
	TechnicalColumnDto,
	TechnicalColumnStereotypeDto,
	TechnicalColumnUpdateDto,
	TechnologyDto,
	TemplateDto,
	TemplateListDto,
	User,
	UserComboDto,
	UserDto,
	UserMessageDto,
	UserRolesDto,
	UtilsNameRequestDto,
	ValidationMessageDto
} from './models'

type MockCall = any

export function getLastCall(): MockCall | undefined {
	return undefined
}

export function getCalls(): MockCall[] {
	return []
}

export function mock(_: string, __: any) {}

export const getAcronyms = (opts?: {
	page?: number
	size?: number
	searchQuery?: string
}) =>
	request<PageableDtoAcronymDto>(HttpMethod.GET, 'acronyms', undefined, {
		page: opts && opts.page,
		size: opts && opts.size,
		searchQuery: opts && opts.searchQuery
	})

export const createAcronym = (opts?: { body?: AcronymCreateDto }) =>
	request<AcronymDto>(
		HttpMethod.POST,
		'acronyms',
		undefined,
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const getAcronym = (acronymId: number) =>
	request<void>(HttpMethod.GET, 'acronyms/{acronymId}', { acronymId })

export const deleteAcronym = (acronymId: number) =>
	request<void>(HttpMethod.DELETE, 'acronyms/{acronymId}', { acronymId })

export const updateAcronym = (
	acronymId: number,
	opts?: { body?: AcronymUpdateDto }
) =>
	request<AcronymDto>(
		HttpMethod.PUT,
		'acronyms/{acronymId}',
		{ acronymId },
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const getAuditLogs = (
	entityId: number,
	entityType: 'STRUCTURE' | 'DOMAIN'
) =>
	request<StructureAudit[]>(HttpMethod.GET, 'audit/{entityType}/{entityId}', {
		entityId,
		entityType
	})

export const getAllBusinessDomains = () =>
	request<BusinessDomainDto[]>(HttpMethod.GET, 'business-domains')

export const createBusinessDomain = (body: BusinessDomainDto) =>
	request<BusinessDomainDto>(
		HttpMethod.POST,
		'business-domains',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getAllBusinessDomainsForCombo = () =>
	request<BusinessDomainComboDto[]>(HttpMethod.GET, 'business-domains/combo')

export const getBusinessDomain = (businessDomainId: number) =>
	request<BusinessDomainDto>(
		HttpMethod.GET,
		'business-domains/{businessDomainId}',
		{ businessDomainId }
	)

export const deleteBusinessDomain = (businessDomainId: number) =>
	request<void>(HttpMethod.DELETE, 'business-domains/{businessDomainId}', {
		businessDomainId
	})

export const updateBusinessDomain = (
	businessDomainId: number,
	body: BusinessDomainDto
) =>
	request<BusinessDomainDto>(
		HttpMethod.PUT,
		'business-domains/{businessDomainId}',
		{ businessDomainId },
		undefined,
		JSON.stringify(body)
	)

export const getCompany = () => request<Company>(HttpMethod.GET, 'company')

export const getAllCustomAttributeStructureTypeDto = () =>
	request<StructureTypeDto[]>(
		HttpMethod.GET,
		'custom-attribute-structure-types'
	)

export const getAllCustomAttributes = () =>
	request<CustomAttributeDto[]>(HttpMethod.GET, 'custom-attributes')

export const createCustomAttribute = (body: CustomAttributeCreateDto) =>
	request<CustomAttributeDto>(
		HttpMethod.POST,
		'custom-attributes',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getAllCustomAttributesForCombo = (
	structureType: StructureDto.TypeEnum
) =>
	request<CustomAttributeComboDto[]>(
		HttpMethod.GET,
		'custom-attributes/combo/{structureType}',
		{ structureType }
	)

export const getCustomAttribute = (customAttributeId: number) =>
	request<CustomAttributeDto>(
		HttpMethod.GET,
		'custom-attributes/{customAttributeId}',
		{ customAttributeId }
	)

export const deleteCustomAttribute = (customAttributeId: number) =>
	request<void>(HttpMethod.DELETE, 'custom-attributes/{customAttributeId}', {
		customAttributeId
	})

export const updateCustomAttribute = (
	customAttributeId: number,
	body: CustomAttributeUpdateDto
) =>
	request<CustomAttributeDto>(
		HttpMethod.PUT,
		'custom-attributes/{customAttributeId}',
		{ customAttributeId },
		undefined,
		JSON.stringify(body)
	)

export const getAllCustomLovs = () =>
	request<CustomLovDto[]>(HttpMethod.GET, 'custom-lov')

export const createCustomLov = (body: CustomLovCreateDto) =>
	request<CustomLovDto>(
		HttpMethod.POST,
		'custom-lov',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getAllCustomLovForCombo = () =>
	request<CustomLovComboDto[]>(HttpMethod.GET, 'custom-lov/combo')

export const getCustomLov = (customLovId: number) =>
	request<CustomLovDto>(HttpMethod.GET, 'custom-lov/{customLovId}', {
		customLovId
	})

export const deleteCustomLov = (customLovId: number) =>
	request<void>(HttpMethod.DELETE, 'custom-lov/{customLovId}', { customLovId })

export const updateCustomLov = (
	customLovId: number,
	body: CustomLovUpdateDto
) =>
	request<CustomLovDto>(
		HttpMethod.PUT,
		'custom-lov/{customLovId}',
		{ customLovId },
		undefined,
		JSON.stringify(body)
	)

export const getCustomLovForComboById = (customLovId: number) =>
	request<CustomLovComboDto>(HttpMethod.GET, 'custom-lov/{customLovId}/combo', {
		customLovId
	})

export const getAllNodeDeployments = (
	structureId: number,
	sqlType:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
) =>
	request<DeploymentDto[]>(
		HttpMethod.GET,
		'deployments/structure/{structureId}',
		{ structureId, sqlType }
	)

export const getAllSpDeployments = (
	structureId: number,
	environmentId: number,
	objectTypeId: number
) =>
	request<DeploymentDto>(
		HttpMethod.GET,
		'deployments/structure/{structureId}/environments/{environmentId}/objectTypes/{objectTypeId}',
		{ structureId, environmentId, objectTypeId }
	)

export const createDeploy = (
	structureId: number,
	environmentId: number,
	objectTypeId: number,
	body: DeploymentNewDto
) =>
	request<DeploymentDto>(
		HttpMethod.POST,
		'deployments/structure/{structureId}/environments/{environmentId}/objectTypes/{objectTypeId}',
		{ structureId, environmentId, objectTypeId },
		undefined,
		JSON.stringify(body)
	)

export const getDeploymentDetail = (deploymentId: number) =>
	request<DeploymentDetailDto>(HttpMethod.GET, 'deployments/{deploymentId}', {
		deploymentId
	})

export const getAllDomains = () =>
	request<DomainDto[]>(HttpMethod.GET, 'domains')

export const createDomain = (body: DomainCreateDto) =>
	request<DomainDto>(
		HttpMethod.POST,
		'domains',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getAllDomainsForCombo = () =>
	request<DomainComboDto[]>(HttpMethod.GET, 'domains/combo')

export const deleteDomain = (domainId: number) =>
	request<void>(HttpMethod.DELETE, 'domains/{domainId}', { domainId })

export const updateDomain = (
	domainId: number,
	body: DomainUpdateDto,
	opts?: { updateElements?: boolean }
) =>
	request<DomainDto>(
		HttpMethod.PUT,
		'domains/{domainId}',
		{ domainId },
		{ updateElements: opts && opts.updateElements },
		JSON.stringify(body)
	)

export const getColumnsByDomain = (domainId: number) =>
	request<ImpactedColumnDto[]>(HttpMethod.GET, 'domains/{domainId}/column', {
		domainId
	})

export const getColumnsByDomainCSV = (domainId: number) =>
	request<ArrayBuffer>(
		HttpMethod.GET,
		'domains/{domainId}/column/csv',
		{ domainId },
		undefined,
		undefined,
		ResultType.ARRAY_BUFFER
	)

export const computeImpactDomainChange = (
	domainId: number,
	body: DomainUpdateDto
) =>
	request<ImpactChangeCodeListDto>(
		HttpMethod.POST,
		'domains/{domainId}/impact',
		{ domainId },
		undefined,
		JSON.stringify(body)
	)

export const getAllDomainsFilteredBySystemTechnology = (
	systemStructureId: number
) =>
	request<DomainDto[]>(HttpMethod.GET, 'domains/{systemStructureId}', {
		systemStructureId
	})

export const getEnvironments = (structureId: number) =>
	request<EnvironmentDto[]>(
		HttpMethod.GET,
		'environments/structure/{structureId}',
		{ structureId }
	)

export const getEnvironmentsWithLastDeployment = (structureId: number) =>
	request<EnvironmentDeploymentDto[]>(
		HttpMethod.GET,
		'environments/structure/{structureId}/deployments',
		{ structureId }
	)

export const getEnvironmentsWithLastDeploymentPerObjectType = (
	structureId: number
) =>
	request<EnvironmentDeploymentDto[]>(
		HttpMethod.GET,
		'environments/structure/{structureId}/deploymentsWithObjectType',
		{ structureId }
	)

export const getEnvironmentById = (environmentId: number) =>
	request<EnvironmentDto>(HttpMethod.GET, 'environments/{environmentId}', {
		environmentId
	})

export const getNamings = () => request<NamingDto[]>(HttpMethod.GET, 'naming')

export const createNaming = (body: NamingDto) =>
	request<NamingDto>(
		HttpMethod.POST,
		'naming',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const updateNamings = (body: NamingDto[]) =>
	request<NamingDto[]>(
		HttpMethod.PUT,
		'naming',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const dummyNaming = (opts?: { body?: Table }) =>
	request<AdjustResultDto>(
		HttpMethod.POST,
		'naming/dummy',
		undefined,
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const executeNaming = (
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME',
	structureId: number,
	opts?: {
		sequence_column?: string
		master_node_name?: string
		master_node_code?: string
	}
) =>
	request<AdjustResultDto>(
		HttpMethod.POST,
		'naming/execute/{namingType}/{structureId}',
		{ namingType, structureId },
		{
			sequence_column: opts && opts.sequence_column,
			master_node_name: opts && opts.master_node_name,
			master_node_code: opts && opts.master_node_code
		}
	)

export const executeNamingOverWorkingData = (
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME',
	structureId: number,
	sqlType:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE',
	opts?: {
		sequence_column?: string
		master_node_name?: string
		master_node_code?: string
		body?: StructureWorkingDataDto
	}
) =>
	request<AdjustResultDto>(
		HttpMethod.POST,
		'naming/execute/{namingType}/{structureId}/{sqlType}',
		{ namingType, structureId, sqlType },
		{
			sequence_column: opts && opts.sequence_column,
			master_node_name: opts && opts.master_node_name,
			master_node_code: opts && opts.master_node_code
		},
		opts && JSON.stringify(opts.body)
	)

export const getNaming = (
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
) => request<NamingDto>(HttpMethod.GET, 'naming/{namingType}', { namingType })

export const deleteNaming = (
	namingType:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
) => request<void>(HttpMethod.DELETE, 'naming/{namingType}', { namingType })

export const getAllObjectSettings = () =>
	request<ObjectSettingsDto[]>(HttpMethod.GET, 'objectsettings')

export const createObjectSettings = (body: ObjectSettingsCreateDto) =>
	request<ObjectSettingsDto>(
		HttpMethod.POST,
		'objectsettings',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getObjectSettingsByType = (
	structureType:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
) =>
	request<ObjectSettingsComboDto[]>(
		HttpMethod.GET,
		'objectsettings/combo',
		undefined,
		{ structureType }
	)

export const deleteObjectSettings = (objectSettingsId: number) =>
	request<void>(HttpMethod.DELETE, 'objectsettings/{objectSettingsId}', {
		objectSettingsId
	})

export const updateObjectSettings = (
	objectSettingsId: number,
	body: ObjectSettingsUpdateDto
) =>
	request<ObjectSettingsDto>(
		HttpMethod.PUT,
		'objectsettings/{objectSettingsId}',
		{ objectSettingsId },
		undefined,
		JSON.stringify(body)
	)

export const getAllObjectSettingsForTargetStructure = (structureId: number) =>
	request<ObjectSettingsDto[]>(HttpMethod.GET, 'objectsettings/{structureId}', {
		structureId
	})

export const getPermissions = (type: 'NODE' | 'GLOBAL') =>
	request<Permission[]>(HttpMethod.GET, 'permissions', undefined, { type })

export const getAllRelationsByRelationType = (opts?: {
	relationType?:
		| 'PK_FK_TABLE'
		| 'PK_FK_COLUMN'
		| 'MAPPING_WRITE'
		| 'LINK'
		| 'LINK_COLUMN'
		| 'CUSTOM_LINK'
}) =>
	request<RelationDto[]>(HttpMethod.GET, 'relation', undefined, {
		relationType: opts && opts.relationType
	})

export const getStereotypeCategories = () =>
	request<StereotypeCategoryDto[]>(HttpMethod.GET, 'stereotype-categories')

export const getStereotypeTypes = () =>
	request<StereotypeTypeDto[]>(HttpMethod.GET, 'stereotype-types')

export const getStereotypeTypesForObjectSettings = () =>
	request<StereotypeTypeDto[]>(
		HttpMethod.GET,
		'stereotype-types/objectsettings'
	)

export const getStereotypes = (opts?: {
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
	internal?: boolean
}) =>
	request<StereotypeDto[]>(HttpMethod.GET, 'stereotypes', undefined, {
		type: opts && opts.type,
		internal: opts && opts.internal
	})

export const createStereotype = (body: StereotypeCreateDto) =>
	request<StereotypeDto>(
		HttpMethod.POST,
		'stereotypes',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getStereotypesForTemplates = (opts?: {
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
}) =>
	request<StereotypeDto[]>(HttpMethod.GET, 'stereotypes/templates', undefined, {
		type: opts && opts.type
	})

export const getStereotypesForTableTemplates = () =>
	request<StereotypeDto[]>(HttpMethod.GET, 'stereotypes/templates/tables')

export const deleteStereotype = (stereotypeId: number) =>
	request<void>(HttpMethod.DELETE, 'stereotypes/{stereotypeId}', {
		stereotypeId
	})

export const updateStereotype = (
	stereotypeId: number,
	body: StereotypeUpdateDto
) =>
	request<StereotypeDto>(
		HttpMethod.PUT,
		'stereotypes/{stereotypeId}',
		{ stereotypeId },
		undefined,
		JSON.stringify(body)
	)

export const getSubStereotypes = (stereotypeId: number) =>
	request<StereotypeDto[]>(
		HttpMethod.GET,
		'stereotypes/{stereotypeId}/sub-stereotypes',
		{ stereotypeId }
	)

export const getRootNodes = () =>
	request<StructureDto[]>(HttpMethod.GET, 'structure')

export const createNode = (body: NewStructureDto) =>
	request<StructureDto>(
		HttpMethod.POST,
		'structure',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getAllStructureTypeDto = () =>
	request<StructureTypeDto[]>(HttpMethod.GET, 'structure-types')

export const searchFullText = (searchText: string) =>
	request<FullTextSearchDto[]>(
		HttpMethod.GET,
		'structure/fulltext',
		undefined,
		{ searchText }
	)

export const searchFullTextJson = (searchText: string) =>
	request<FullTextSearchDto[]>(
		HttpMethod.GET,
		'structure/fulltextJson',
		undefined,
		{ searchText }
	)

export const searchFullTextJsonBVector = (searchText: string) =>
	request<FullTextSearchDto[]>(
		HttpMethod.GET,
		'structure/fulltextJsonVector',
		undefined,
		{ searchText }
	)

export const importStructureFile = (body: StructureExportDto) =>
	request<void>(
		HttpMethod.POST,
		'structure/import',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const importStructureFileIntoNode = (
	structureId: number,
	body: StructureExportDto
) =>
	request<void>(
		HttpMethod.POST,
		'structure/import/{structureId}',
		{ structureId },
		undefined,
		JSON.stringify(body)
	)

export const importStructureCsvIntoNode = (
	structureId: number,
	body: CsvImportRowDto[],
	opts?: {
		caseSensitive?: boolean
		createNewDomains?: boolean
		generateCodes?: boolean
		importAllColumns?: boolean
		nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	}
) =>
	request<CsvImportReturnDto>(
		HttpMethod.POST,
		'structure/importCsv/{structureId}',
		{ structureId },
		{
			caseSensitive: opts && opts.caseSensitive,
			createNewDomains: opts && opts.createNewDomains,
			generateCodes: opts && opts.generateCodes,
			importAllColumns: opts && opts.importAllColumns,
			nameNormalisation: opts && opts.nameNormalisation
		},
		JSON.stringify(body)
	)

export const importStructureCsvIntoNodeCheck = (
	structureId: number,
	body: CsvImportRowDto[],
	opts?: {
		caseSensitive?: boolean
		createNewDomains?: boolean
		generateCodes?: boolean
		nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	}
) =>
	request<CsvImportReturnDto>(
		HttpMethod.POST,
		'structure/importCsv/{structureId}/check',
		{ structureId },
		{
			caseSensitive: opts && opts.caseSensitive,
			createNewDomains: opts && opts.createNewDomains,
			generateCodes: opts && opts.generateCodes,
			nameNormalisation: opts && opts.nameNormalisation
		},
		JSON.stringify(body)
	)

export const generateMassExportFile = (
	body: MassExportConfigDto,
	opts?: { ignoreErrors?: boolean }
) =>
	request<ArrayBuffer>(
		HttpMethod.POST,
		'structure/mass-export',
		undefined,
		{ ignoreErrors: opts && opts.ignoreErrors },
		JSON.stringify(body),
		ResultType.ARRAY_BUFFER
	)

export const searchNodes = (searchText: string) =>
	request<StructureSearchedDto[]>(
		HttpMethod.GET,
		'structure/search',
		undefined,
		{ searchText }
	)

export const searchStructure = (
	searchText: string,
	opts?: {
		nodeType?:
			| 'SYSTEM_NODE'
			| 'FOLDER'
			| 'WORKFLOW'
			| 'TABLE'
			| 'MAPPING'
			| 'VIEW'
			| 'API'
			| 'QUEUE'
	}
) =>
	request<StructureObjectDto[]>(
		HttpMethod.GET,
		'structure/search-by-name-or-code',
		undefined,
		{ searchText, nodeType: opts && opts.nodeType }
	)

export const getSystemEnvironments = (systemId: number) =>
	request<EnvironmentDto[]>(
		HttpMethod.GET,
		'structure/system/{systemId}/environment',
		{ systemId }
	)

/**
 * Delete all deployments in this environment
 */
export const resetVersion = (systemId: number, envId: number) =>
	request<void>(
		HttpMethod.POST,
		'structure/system/{systemId}/environment/{envId}/reset',
		{ systemId, envId }
	)

/**
 * Takeover version from other environment
 */
export const takeOverVersion = (
	systemId: number,
	envId: number,
	fromId: number
) =>
	request<void>(
		HttpMethod.POST,
		'structure/system/{systemId}/environment/{envId}/takeover/{fromId}',
		{ systemId, envId, fromId }
	)

export const getSystemReleases = (
	systemId: number,
	opts?: { onlyOpen?: boolean }
) =>
	request<ReleaseDto[]>(
		HttpMethod.GET,
		'structure/system/{systemId}/release',
		{ systemId },
		{ onlyOpen: opts && opts.onlyOpen }
	)

export const createSystemRelease = (systemId: number, body: ReleaseDto) =>
	request<ReleaseDto>(
		HttpMethod.POST,
		'structure/system/{systemId}/release',
		{ systemId },
		undefined,
		JSON.stringify(body)
	)

export const getSystemRelease = (systemId: number, releaseId: number) =>
	request<ReleaseDto>(
		HttpMethod.GET,
		'structure/system/{systemId}/release/{releaseId}',
		{ systemId, releaseId }
	)

export const deleteSystemRelease = (systemId: number, releaseId: number) =>
	request<void>(
		HttpMethod.DELETE,
		'structure/system/{systemId}/release/{releaseId}',
		{ systemId, releaseId }
	)

export const updateSystemRelease = (
	systemId: number,
	releaseId: number,
	body: ReleaseDto
) =>
	request<ReleaseDto>(
		HttpMethod.PUT,
		'structure/system/{systemId}/release/{releaseId}',
		{ systemId, releaseId },
		undefined,
		JSON.stringify(body)
	)

export const getReleaseEnvironments = (systemId: number, releaseId: number) =>
	request<ReleaseEnvironmentDto[]>(
		HttpMethod.GET,
		'structure/system/{systemId}/release/{releaseId}/environment',
		{ systemId, releaseId }
	)

/**
 * Create mapping between release and environment
 */
export const createReleaseEnvironment = (
	systemId: number,
	releaseId: number,
	body: ReleaseEnvironmentDto
) =>
	request<ReleaseEnvironmentDto>(
		HttpMethod.POST,
		'structure/system/{systemId}/release/{releaseId}/environment',
		{ systemId, releaseId },
		undefined,
		JSON.stringify(body)
	)

export const deleteReleaseEnvironment = (
	systemId: number,
	releaseId: number,
	envId: number
) =>
	request<void>(
		HttpMethod.DELETE,
		'structure/system/{systemId}/release/{releaseId}/environment/{envId}',
		{ systemId, releaseId, envId }
	)

export const updateReleaseEnvironment = (
	systemId: number,
	releaseId: number,
	envId: number,
	body: ReleaseEnvironmentDto
) =>
	request<ReleaseEnvironmentDto>(
		HttpMethod.PUT,
		'structure/system/{systemId}/release/{releaseId}/environment/{envId}',
		{ systemId, releaseId, envId },
		undefined,
		JSON.stringify(body)
	)

export const createReleasePreview = (
	systemId: number,
	releaseId: number,
	envId: number,
	body: MassExportConfigDto
) =>
	request<string>(
		HttpMethod.POST,
		'structure/system/{systemId}/release/{releaseId}/environment/{envId}/preview',
		{ systemId, releaseId, envId },
		undefined,
		JSON.stringify(body),
		ResultType.STRING
	)

export const createReleaseScripts = (
	systemId: number,
	releaseId: number,
	envId: number,
	body: MassExportConfigDto
) =>
	request<ArrayBuffer>(
		HttpMethod.POST,
		'structure/system/{systemId}/release/{releaseId}/environment/{envId}/scripts',
		{ systemId, releaseId, envId },
		undefined,
		JSON.stringify(body),
		ResultType.ARRAY_BUFFER
	)

export const createDeployments = (
	systemId: number,
	releaseId: number,
	envId: number,
	body: MassExportConfigDto
) =>
	request<void>(
		HttpMethod.POST,
		'structure/system/{systemId}/release/{releaseId}/environment/{envId}/scripts/deploy',
		{ systemId, releaseId, envId },
		undefined,
		JSON.stringify(body)
	)

export const getReleaseStructures = (systemId: number, releaseId: number) =>
	request<StructureObjectDto[]>(
		HttpMethod.GET,
		'structure/system/{systemId}/release/{releaseId}/structure',
		{ systemId, releaseId }
	)

/**
 * Create mapping between release and structure
 */
export const addStructureToRelease = (
	systemId: number,
	releaseId: number,
	body: number[]
) =>
	request<void>(
		HttpMethod.POST,
		'structure/system/{systemId}/release/{releaseId}/structure',
		{ systemId, releaseId },
		undefined,
		JSON.stringify(body)
	)

export const deleteReleaseStructure = (
	systemId: number,
	releaseId: number,
	structureId: number
) =>
	request<void>(
		HttpMethod.DELETE,
		'structure/system/{systemId}/release/{releaseId}/structure/{structureId}',
		{ systemId, releaseId, structureId }
	)

export const getAllTablesForSystemNode = (systemId: number) =>
	request<StructureDto[]>(
		HttpMethod.GET,
		'structure/system/{systemId}/tables',
		{ systemId }
	)

export const getMappingsOfSystemNodes = (systemStructureId: number) =>
	request<StructureDto[]>(
		HttpMethod.GET,
		'structure/system/{systemStructureId}/mapping',
		{ systemStructureId }
	)

export const getWorkflowsOfSystemNode = (systemStructureId: number) =>
	request<StructureDto[]>(
		HttpMethod.GET,
		'structure/system/{systemStructureId}/workflow',
		{ systemStructureId }
	)

export const getNodeDetail = (
	structureId: number,
	abortController?: AbortController
) =>
	request<StructureDetailDto>(HttpMethod.GET, 'structure/{structureId}', {
		structureId
	})

export const deleteNode = (structureId: number) =>
	request<void>(HttpMethod.DELETE, 'structure/{structureId}', { structureId })

export const getApiNodeColumnFromAllStructures = (structureId: number) =>
	request<ApiNodeColumnDto[]>(
		HttpMethod.GET,
		'structure/{structureId}/all-columns',
		{ structureId }
	)

export const getNodesOfSpecificParent = (
	structureId: number,
	opts?: { recursive?: boolean }
) =>
	request<StructureDto[]>(
		HttpMethod.GET,
		'structure/{structureId}/children',
		{ structureId },
		{ recursive: opts && opts.recursive }
	)

export const createChildNode = (structureId: number, body: NewStructureDto) =>
	request<StructureDetailDto>(
		HttpMethod.POST,
		'structure/{structureId}/children',
		{ structureId },
		undefined,
		JSON.stringify(body)
	)

export const importColumnsFromCSV = (
	structureId: number,
	body: ImportColumn[],
	opts?: {
		caseSensitive?: boolean
		createNewDomains?: boolean
		generateCodes?: boolean
		importAllColumns?: boolean
		nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	}
) =>
	request<CsvImportReturnDto>(
		HttpMethod.POST,
		'structure/{structureId}/columns',
		{ structureId },
		{
			caseSensitive: opts && opts.caseSensitive,
			createNewDomains: opts && opts.createNewDomains,
			generateCodes: opts && opts.generateCodes,
			importAllColumns: opts && opts.importAllColumns,
			nameNormalisation: opts && opts.nameNormalisation
		},
		JSON.stringify(body)
	)

export const importColumnsFromCSVCheck = (
	structureId: number,
	body: ImportColumn[],
	opts?: {
		caseSensitive?: boolean
		createNewDomains?: boolean
		generateCodes?: boolean
		nameNormalisation?: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	}
) =>
	request<CsvImportReturnDto>(
		HttpMethod.POST,
		'structure/{structureId}/columns/check',
		{ structureId },
		{
			caseSensitive: opts && opts.caseSensitive,
			createNewDomains: opts && opts.createNewDomains,
			generateCodes: opts && opts.generateCodes,
			nameNormalisation: opts && opts.nameNormalisation
		},
		JSON.stringify(body)
	)

export const editDataNode = (structureId: number) =>
	request<StructureDataDto>(HttpMethod.GET, 'structure/{structureId}/data', {
		structureId
	})

export const deleteDataNode = (structureId: number) =>
	request<void>(HttpMethod.DELETE, 'structure/{structureId}/data', {
		structureId
	})

export const updateDataNode = (
	structureId: number,
	body: StructureWorkingDataDto
) =>
	request<void>(
		HttpMethod.PUT,
		'structure/{structureId}/data',
		{ structureId },
		undefined,
		JSON.stringify(body)
	)

export const getDiagram = (structureId: number) =>
	request<TableDiagramDto>(HttpMethod.GET, 'structure/{structureId}/diagram', {
		structureId
	})

export const getDeepDiagram = (structureId: number) =>
	request<TableDiagramDto>(
		HttpMethod.GET,
		'structure/{structureId}/diagram/deep',
		{ structureId }
	)

export const createFromSystemStructure = (structureId: number) =>
	request<TableDiagramDto>(
		HttpMethod.GET,
		'structure/{structureId}/diagram/fromSystem',
		{ structureId }
	)

export const getDiagramFromRelation = (structureId: number) =>
	request<TableDiagramDto>(
		HttpMethod.GET,
		'structure/{structureId}/diagram/relation',
		{ structureId }
	)

export const duplicateNode = (structureId: number) =>
	request<StructureDetailDto>(
		HttpMethod.POST,
		'structure/{structureId}/duplicate',
		{ structureId }
	)

export const duplicateNodeRecursive = (
	structureId: number,
	opts?: { suffix?: string; useSuffixOnly4System?: boolean }
) =>
	request<string>(
		HttpMethod.POST,
		'structure/{structureId}/duplicate-recursive',
		{ structureId },
		{
			suffix: opts && opts.suffix,
			useSuffixOnly4System: opts && opts.useSuffixOnly4System
		},
		undefined,
		ResultType.STRING
	)

export const generateAndPushToGit = (
	structureId: number,
	environmentId: number,
	objectSettingsId: number
) =>
	request<GitPushDto>(
		HttpMethod.GET,
		'structure/{structureId}/environments/{environmentId}/objectTypes/{objectSettingsId}/git',
		{ structureId, environmentId, objectSettingsId }
	)

export const generateSqlPreviewEnvironment = (
	structureId: number,
	environmentId: number,
	objectSettingsId: number
) =>
	request<GeneratedSqlDto>(
		HttpMethod.GET,
		'structure/{structureId}/environments/{environmentId}/objectTypes/{objectSettingsId}/preview',
		{ structureId, environmentId, objectSettingsId }
	)

export const generateSql = (
	structureId: number,
	environmentId: number,
	objectSettingsId: number
) =>
	request<GeneratedSqlDto>(
		HttpMethod.GET,
		'structure/{structureId}/environments/{environmentId}/objectTypes/{objectSettingsId}/sql',
		{ structureId, environmentId, objectSettingsId }
	)

export const generateSqlDownloadZipFile = (
	structureId: number,
	environmentId: number,
	objectTypeId: number
) =>
	request<ArrayBuffer>(
		HttpMethod.GET,
		'structure/{structureId}/environments/{environmentId}/objectTypes/{objectTypeId}/sql/download-zip',
		{ structureId, environmentId, objectTypeId },
		undefined,
		undefined,
		ResultType.ARRAY_BUFFER
	)

export const exportStructure = (structureId: number) =>
	request<StructureExport>(HttpMethod.GET, 'structure/{structureId}/export', {
		structureId
	})

export const getAllNodeHistory = (structureId: number) =>
	request<HistoryDto[]>(HttpMethod.GET, 'structure/{structureId}/history', {
		structureId
	})

export const createNodeHistory = (
	structureId: number,
	body: NewHistoryDto,
	opts?: { fixImpacts?: boolean }
) =>
	request<HistoryDto>(
		HttpMethod.POST,
		'structure/{structureId}/history',
		{ structureId },
		{ fixImpacts: opts && opts.fixImpacts },
		JSON.stringify(body)
	)

export const getNodeHistoryDetailByVersion = (
	structureId: number,
	version: number,
	opts?: { envId?: number }
) =>
	request<HistoryDetailDto>(
		HttpMethod.GET,
		'structure/{structureId}/history/version/{version}',
		{ structureId, version },
		{ envId: opts && opts.envId }
	)

export const getNodeHistoryDetail = (structureId: number, historyId: number) =>
	request<HistoryDetailDto>(
		HttpMethod.GET,
		'structure/{structureId}/history/{historyId}',
		{ structureId, historyId }
	)

export const deleteNodeHistory = (structureId: number, historyId: number) =>
	request<void>(
		HttpMethod.DELETE,
		'structure/{structureId}/history/{historyId}',
		{ structureId, historyId }
	)

export const createNodeLock = (structureId: number) =>
	request<void>(HttpMethod.POST, 'structure/{structureId}/lock', {
		structureId
	})

export const deleteNodeLock = (structureId: number) =>
	request<void>(HttpMethod.DELETE, 'structure/{structureId}/lock', {
		structureId
	})

export const getMappings = (structureId: number) =>
	request<StructureDto[]>(HttpMethod.GET, 'structure/{structureId}/mapping', {
		structureId
	})

export const generateMassExportPushGit = (
	structureId: number,
	body: MassExportConfigDto
) =>
	request<GitPushDto>(
		HttpMethod.POST,
		'structure/{structureId}/mass-export/git',
		{ structureId },
		undefined,
		JSON.stringify(body)
	)

export const massPatchStructuresList = (
	structureId: number,
	opts?: { body?: MassUpdateDto }
) =>
	request<string>(
		HttpMethod.PATCH,
		'structure/{structureId}/mass-update-list',
		{ structureId },
		undefined,
		opts && JSON.stringify(opts.body),
		ResultType.STRING
	)

export const moveNode = (structureId: number, destinationStructureId: number) =>
	request<void>(
		HttpMethod.PUT,
		'structure/{structureId}/move/{destinationStructureId}',
		{ structureId, destinationStructureId }
	)

export const reorderNode = (structureId: number, body: ReorderStructureDto) =>
	request<void>(
		HttpMethod.PUT,
		'structure/{structureId}/order',
		{ structureId },
		undefined,
		JSON.stringify(body)
	)

export const patchMergeStructures = (
	structureId: number,
	opts?: { body?: MassUpdateDto }
) =>
	request<string>(
		HttpMethod.PATCH,
		'structure/{structureId}/patch-merge',
		{ structureId },
		undefined,
		opts && JSON.stringify(opts.body),
		ResultType.STRING
	)

export const getStructurePermissions = (structureId: number) =>
	request<StructureUserPermissionDto[]>(
		HttpMethod.GET,
		'structure/{structureId}/permissions',
		{ structureId }
	)

export const updateBatchEnvironments = (
	structureId: number,
	body: NewStructureUserPermissionBatchDto[]
) =>
	request<StructureUserPermissionDto[]>(
		HttpMethod.PUT,
		'structure/{structureId}/permissions',
		{ structureId },
		undefined,
		JSON.stringify(body)
	)

export const getUserPermission = (structureId: number, userId: number) =>
	request<StructureUserPermissionDto>(
		HttpMethod.GET,
		'structure/{structureId}/permissions/user/{userId}',
		{ structureId, userId }
	)

export const deleteUserPermission = (structureId: number, userId: number) =>
	request<void>(
		HttpMethod.DELETE,
		'structure/{structureId}/permissions/user/{userId}',
		{ structureId, userId }
	)

export const changeUserPermission = (
	body: NewStructureUserPermissionDto,
	structureId: number,
	userId: number
) =>
	request<void>(
		HttpMethod.PUT,
		'structure/{structureId}/permissions/user/{userId}',
		{ structureId, userId },
		undefined,
		JSON.stringify(body)
	)

export const prePushValidation = (structureId: number) =>
	request<PrePushValidationDto[]>(
		HttpMethod.GET,
		'structure/{structureId}/prepush-validation',
		{ structureId }
	)

export const getNodeRelations = (structureId: number) =>
	request<RelationDto[]>(HttpMethod.GET, 'structure/{structureId}/relation', {
		structureId
	})

export const createRelation = (
	structureId: number,
	opts?: { body?: RelationCreateDto }
) =>
	request<RelationDto>(
		HttpMethod.POST,
		'structure/{structureId}/relation',
		{ structureId },
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const deleteRelation = (structureId: number, relationId: number) =>
	request<void>(
		HttpMethod.DELETE,
		'structure/{structureId}/relation/{relationId}',
		{ structureId, relationId }
	)

export const pathRelation = (
	structureId: number,
	relationId: number,
	opts?: { body?: JsonPatch }
) =>
	request<RelationDto>(
		HttpMethod.PATCH,
		'structure/{structureId}/relation/{relationId}',
		{ structureId, relationId },
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const updateRelation = (
	structureId: number,
	relationId: number,
	opts?: { body?: RelationDto }
) =>
	request<RelationDto>(
		HttpMethod.PUT,
		'structure/{structureId}/relation/{relationId}',
		{ structureId, relationId },
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const generateSqlPreview = (
	structureId: number,
	opts?: { objectSettingsId?: number }
) =>
	request<GeneratedSqlDto>(
		HttpMethod.GET,
		'structure/{structureId}/sql',
		{ structureId },
		{ objectSettingsId: opts && opts.objectSettingsId }
	)

export const generateSqlPreviewDownloadZipFile = (
	structureId: number,
	opts?: { objectTypeId?: number }
) =>
	request<ArrayBuffer>(
		HttpMethod.GET,
		'structure/{structureId}/sql/download-zip',
		{ structureId },
		{ objectTypeId: opts && opts.objectTypeId },
		undefined,
		ResultType.ARRAY_BUFFER
	)

export const getAvailableStructureForSourceObject = (
	structureId: number,
	sourceObjectType:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
) =>
	request<StructureObjectDto[]>(
		HttpMethod.GET,
		'structure/{structureId}/structure-object/{sourceObjectType}',
		{ structureId, sourceObjectType }
	)

export const getSystemUsers = (structureId: number) =>
	request<SystemUser[]>(
		HttpMethod.GET,
		'structure/{structureId}/system-users',
		{ structureId }
	)

export const getSystemUser = (structureId: number, id: number) =>
	request<SystemUser>(
		HttpMethod.GET,
		'structure/{structureId}/system-users/{id}',
		{ structureId, id }
	)

export const getUniversalColumnFromAllStructures = (structureId: number) =>
	request<ApiNodeColumnDto[]>(
		HttpMethod.GET,
		'structure/{structureId}/universal/all-columns',
		{ structureId }
	)

export const getWriteColumnFromAllStructures = (structureId: number) =>
	request<ApiNodeColumnDto[]>(
		HttpMethod.GET,
		'structure/{structureId}/write-mapping/all-columns',
		{ structureId }
	)

export const getAllSystemTechnologies = () =>
	request<TechnologyDto[]>(HttpMethod.GET, 'system-technologies')

export const searchTableColumns = (
	tableCode: string,
	systemId: number,
	opts?: { searchText?: string; actualStructureId?: number }
) =>
	request<AutocompleteResultDto[]>(
		HttpMethod.GET,
		'system/{systemId}/structure/table/{tableCode}/columns',
		{ tableCode, systemId },
		{
			searchText: opts && opts.searchText,
			actualStructureId: opts && opts.actualStructureId
		}
	)

export const searchNodesWithType = (
	searchText: string,
	structureType:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN',
	systemId: number,
	opts?: { actualStructureId?: number }
) =>
	request<AutocompleteResultDto[]>(
		HttpMethod.GET,
		'system/{systemId}/structure/{structureType}/search',
		{ structureType, systemId },
		{ searchText, actualStructureId: opts && opts.actualStructureId }
	)

export const getAllTechnicalColumns = () =>
	request<TechnicalColumnDto[]>(HttpMethod.GET, 'technical-columns')

export const createTechnicalColumn = (
	body: TechnicalColumnCreateDto,
	opts?: { updateElements?: boolean }
) =>
	request<TechnicalColumnDto>(
		HttpMethod.POST,
		'technical-columns',
		undefined,
		{ updateElements: opts && opts.updateElements },
		JSON.stringify(body)
	)

export const getTechnicalColumnStereotype = (
	stereotypeId: number,
	technologyId: number
) =>
	request<TechnicalColumnStereotypeDto[]>(
		HttpMethod.GET,
		'technical-columns/combo/{stereotypeId}',
		{ stereotypeId },
		{ technologyId }
	)

export const deleteTechnicalColumn = (
	technicalColumnId: number,
	opts?: { updateElements?: boolean }
) =>
	request<void>(
		HttpMethod.DELETE,
		'technical-columns/{technicalColumnId}',
		{ technicalColumnId },
		{ updateElements: opts && opts.updateElements }
	)

export const updateTechnicalColumn = (
	technicalColumnId: number,
	body: TechnicalColumnUpdateDto,
	opts?: { updateElements?: boolean }
) =>
	request<TechnicalColumnDto>(
		HttpMethod.PUT,
		'technical-columns/{technicalColumnId}',
		{ technicalColumnId },
		{ updateElements: opts && opts.updateElements },
		JSON.stringify(body)
	)

export const computeImpactTCChange = (
	technicalColumnId: number,
	body: TechnicalColumnUpdateDto
) =>
	request<ImpactChangeCodeListDto>(
		HttpMethod.POST,
		'technical-columns/{technicalColumnId}/impact',
		{ technicalColumnId },
		undefined,
		JSON.stringify(body)
	)

export const getTablesByTechnicalColumn = (technicalColumnId: number) =>
	request<StructureObjectDto[]>(
		HttpMethod.GET,
		'technical-columns/{technicalColumnId}/table',
		{ technicalColumnId }
	)

export const getTablesByTechnicalColumnCSV = (technicalColumnId: number) =>
	request<ArrayBuffer>(
		HttpMethod.GET,
		'technical-columns/{technicalColumnId}/table/csv',
		{ technicalColumnId },
		undefined,
		undefined,
		ResultType.ARRAY_BUFFER
	)

export const getAllTechnologies = () =>
	request<TechnologyDto[]>(HttpMethod.GET, 'technologies')

export const getAllTemplates = (opts?: {
	technologyId?: number
	stereotypeId?: number
}) =>
	request<TemplateListDto[]>(HttpMethod.GET, 'templates', undefined, {
		technologyId: opts && opts.technologyId,
		stereotypeId: opts && opts.stereotypeId
	})

export const createTemplate = (body: TemplateDto) =>
	request<TemplateDto>(
		HttpMethod.POST,
		'templates',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getTemplate = (id: number) =>
	request<TemplateDto>(HttpMethod.GET, 'templates/{id}', { id })

export const getTemplateHistory = (id: number) =>
	request<TemplateListDto[]>(HttpMethod.GET, 'templates/{id}/history', { id })

export const getTemplateHistoryDetail = (id: number, version: number) =>
	request<TemplateDto>(HttpMethod.GET, 'templates/{id}/history/{version}', {
		id,
		version
	})

export const deleteTemplate = (templateId: number) =>
	request<void>(HttpMethod.DELETE, 'templates/{templateId}', { templateId })

export const updateTemplate = (templateId: number, body: TemplateDto) =>
	request<TemplateDto>(
		HttpMethod.PUT,
		'templates/{templateId}',
		{ templateId },
		undefined,
		JSON.stringify(body)
	)

export const getUsers = () => request<UserDto[]>(HttpMethod.GET, 'users')

export const addUser = (body: NewUserDto) =>
	request<UserDto>(
		HttpMethod.POST,
		'users',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const getUsersForCombo = () =>
	request<UserComboDto[]>(HttpMethod.GET, 'users/combo')

export const getSessionContext = () =>
	request<SessionContextDto>(HttpMethod.GET, 'users/context')

export const updateSessionContext = (body: SessionContextDto) =>
	request<SessionContextDto>(
		HttpMethod.PUT,
		'users/context',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const lockUser = (userId: number) =>
	request<UserMessageDto>(HttpMethod.POST, 'users/lock/{userId}', { userId })

export const resetUserPassword = (opts?: { body?: ResetPasswordDto }) =>
	request<void>(
		HttpMethod.POST,
		'users/password',
		undefined,
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const changeUserPassword = (opts?: { body?: EditUserPasswordDto }) =>
	request<UserDto>(
		HttpMethod.PUT,
		'users/password',
		undefined,
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const unlockUser = (userId: number) =>
	request<UserMessageDto>(HttpMethod.POST, 'users/unlock/{userId}', { userId })

export const getUser = (userId: number) =>
	request<UserDto>(HttpMethod.GET, 'users/{userId}', { userId })

export const deleteUser = (userId: number) =>
	request<void>(HttpMethod.DELETE, 'users/{userId}', { userId })

export const editUser = (userId: number, opts?: { body?: EditUserInfoDto }) =>
	request<UserDto>(
		HttpMethod.PUT,
		'users/{userId}',
		{ userId },
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const resetLoginAttempts = (userId: number) =>
	request<void>(HttpMethod.DELETE, 'users/{userId}/login-attempts', { userId })

export const getUserRoles = (userId: number) =>
	request<UserRolesDto>(HttpMethod.GET, 'users/{userId}/roles', { userId })

export const editUserRoles = (userId: number, opts?: { body?: UserRolesDto }) =>
	request<UserRolesDto>(
		HttpMethod.PUT,
		'users/{userId}/roles',
		{ userId },
		undefined,
		opts && JSON.stringify(opts.body)
	)

export const adjustTableName = (body: UtilsNameRequestDto) =>
	request<AdjustResultDto>(
		HttpMethod.POST,
		'util/adjust/name',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const checkUniqueName = (body: UtilsNameRequestDto) =>
	request<CheckResultDto>(
		HttpMethod.POST,
		'util/unique/name',
		undefined,
		undefined,
		JSON.stringify(body)
	)

export const checkStructure = (structureId: number) =>
	request<ValidationMessageDto[]>(
		HttpMethod.GET,
		'validation/structure/{structureId}',
		{ structureId }
	)

export const getAllWorkflowTechnologies = () =>
	request<TechnologyDto[]>(HttpMethod.GET, 'workflow-technologies')
