import React, { Suspense } from 'react'
import { GrowTabs } from '../../TableDetail/components/StyledComponents'
import { StructureDto } from '@/api/models'
import { Overview } from '../pages/Overview/Overview'
import { useAppContext } from '@/utils/hooks'
import { UpdateDeepPartial } from '@/store/utils'
import {
	OpenedWorkflowData,
	WorkflowTab,
	WorkflowDataForm
} from '@/store/modules/workflow/types'
import { useAppDispatch } from '@/utils/hooks'
import { selectWorkflowTab } from '@/store/modules/workflow/actions'
import { Loader } from '@/components'
import { Deployments } from '../../../components/Deployments/Deployments'
import { Preview } from '../../../components/Preview/Preview'
import { Validation } from '../../../components/Validation/Validation'
import { IdCodeName } from '@/api/schemas/general'
import { Dependencies } from '../../../components/Dependencies/Dependencies'

const Graph = React.lazy(() =>
	import(/* webpackChunkName: "workflow" */ '../pages/Graph/Graph')
)

type Props = {
	node: StructureDto
	data: OpenedWorkflowData
	editMode: boolean
	objectTypes?: IdCodeName[]
	systemNodeId: number
	onChange: (v: UpdateDeepPartial<WorkflowDataForm>) => void
}

const WorkflowTabsComponent = ({
	node,
	data,
	editMode,
	objectTypes,
	onChange,
	systemNodeId
}: Props) => {
	const { t } = useAppContext()

	const dispatch = useAppDispatch()

	return (
		<GrowTabs
			onChange={tab => dispatch(selectWorkflowTab(node, tab.id as WorkflowTab))}
			selectedTabId={data.tab}
			tabs={[
				{
					id: WorkflowTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							node={node}
							key={WorkflowTab.Overview}
							data={data}
							editMode={editMode}
							onChange={onChange}
							systemNodeId={systemNodeId}
						/>
					)
				},
				{
					id: WorkflowTab.Graph,
					title: t('TAB_GRAPH'),
					content: (
						<Suspense
							fallback={<Loader loaded={false} key={WorkflowTab.Overview} />}
						>
							<Graph
								node={node}
								data={data}
								onChange={onChange}
								systemNodeId={systemNodeId}
							/>
						</Suspense>
					)
				},
				{
					id: WorkflowTab.Preview,
					title: t('TAB_PREVIEW'),
					content: (
						<Preview
							key={WorkflowTab.Preview}
							node={node}
							showObjectTypes
							objectTypes={objectTypes}
						/>
					)
				},
				{
					id: WorkflowTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={WorkflowTab.Deployments}
							editMode={editMode}
						/>
					)
				},
				{
					id: WorkflowTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={WorkflowTab.Validation} node={node} />
				},
				{
					id: WorkflowTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={WorkflowTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					)
				}
			]}
		/>
	)
}

export const WorkflowTabs = React.memo(WorkflowTabsComponent)
