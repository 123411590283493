export function floatWithPrecision(value: number, precision: number): number {
	const power = Math.pow(10, precision)

	return Math.round(value * power) / power
}

export const clamp = (x: number, min: number, max: number) =>
	Math.max(min, Math.min(max, x))

/**
 * Transform milliseconds to minutes and seconds string (e.g.: 2:36)
 * @param millis milliseconds
 */
export const millisToMinsAndSecs = (millis: number): string => {
	const minutes = Math.floor(millis / 60000)
	const seconds = (millis % 60000) / 1000
	const secondsFixed = seconds.toFixed(0)

	return secondsFixed === '60'
		? `${minutes + 1}:00`
		: `${minutes}:${parseFloat(secondsFixed) < 10 ? '0' : ''}${secondsFixed}`
}

export const toLocaleString = (num?: number | string) =>
	num ? new Date(num).toLocaleString('en-GB') : '-'

export const toLocaleDateString = (num?: number | string) =>
	num ? new Date(num).toLocaleDateString('en-GB') : '-'
