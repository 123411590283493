import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

export const Resizable = ({
	children,
	initialWidth,
	minWidth,
	maxWidth,
	onChange
}: {
	children: React.ReactNode
	initialWidth: number
	minWidth?: number
	maxWidth?: number
	onChange?: (width: number) => void
}) => {
	const [width, setWidth] = useState(initialWidth)

	useEffect(() => {
		if (maxWidth && maxWidth < width) {
			setWidth(maxWidth)
		}
	}, [maxWidth])

	const [dragged, setDragged] = useState({
		dragged: false,
		startWidth: 0,
		startX: 0
	})

	const handleMouseMove = useCallback(
		(e: MouseEvent) => {
			if (dragged.dragged) {
				let width = Math.max(
					minWidth || 0,
					dragged.startWidth - (e.pageX - dragged.startX)
				)

				if (maxWidth) {
					width = Math.min(maxWidth, width)
				}

				setWidth(width)
				onChange && onChange(width)
			}
		},
		[dragged]
	)

	const handleMouseDown = (e: React.MouseEvent) => {
		setDragged({
			dragged: true,
			startWidth: width,
			startX: e.pageX
		})

		e.stopPropagation()
	}

	const handleMouseUp = useCallback(() => {
		setDragged({
			dragged: false,
			startWidth: 0,
			startX: 0
		})
	}, [])

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove, false)
		window.addEventListener('mouseup', handleMouseUp, false)

		return () => {
			window.removeEventListener('mousemove', handleMouseMove)
			window.removeEventListener('mouseup', handleMouseUp)
		}
	})

	return (
		<Container style={{ width: width + 'px' }} className="resizable">
			<Resizer onMouseDown={handleMouseDown} className="resizable-resizer" />
			{children}
		</Container>
	)
}

const Container = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	padding-left: 6px;
	box-sizing: border-box;
	z-index: 99;
	background: #fff;
`

const Resizer = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 4px;
	height: 100%;
	cursor: w-resize;
	border: 1px solid #ddd;
	border-top: 0;
	border-bottom: 0;
	background: #f5f5f5;
`
