import { useApiRequest } from '@/api/hooks'
import { importColumnsFromCSV } from '@/api'
import { loadNode } from '@/store/modules/node/general-actions'
import { setApiError } from '@/store/modules/apiError/actions'
import { ImportColumn } from '@/api/models'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { useEffect, useState } from 'react'

export type ImportStructureProps = {
	namesNormalisation: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	caseSensitive: boolean
	createNewDomains: boolean
	generateCodes: boolean
	importAllColumns: boolean
}

export const useImport = (onImported: () => void, nodeId?: number) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const [file, setFile] = useState(null as File | null)
	const [csvData, setCsvData] = useState(null as string | null)
	const [csvRows, setCsvRows] = useState<ImportColumn[]>()
	const [importProps, setImportProps] = useState<ImportStructureProps>()
	const [loading, setLoading] = useState(false)
	const request = useApiRequest()

	useEffect(() => {
		if (!file) {
			setCsvData(null)

			return
		}

		const reader = new FileReader()

		reader.onload = () => {
			if (
				file.type === 'application/vnd.ms-excel' ||
				file.type === 'text/csv'
			) {
				setCsvData(reader.result as string)
			} else {
				dispatch(
					setApiError({
						title: t('IMPORT_ERROR_TITLE'),
						description: t('IMPORT_ERROR_DESCRIPTION'),
						message: null
					})
				)

				setFile(null)
				setCsvData(null)
			}
		}

		reader.readAsText(file)
	}, [dispatch, file, t])

	const handleFile = (files: File[]) => {
		setFile(files[0])
	}

	const handleImport = async () => {
		if (csvData) {
			setLoading(true)

			if (nodeId && csvRows && importProps) {
				await request(importColumnsFromCSV(nodeId, csvRows, importProps))
			}

			// update nodes after import in tree panel
			if (nodeId) {
				dispatch(loadNode(nodeId))
			}

			onImported()

			setLoading(false)
		}
	}

	return {
		handleFile,
		handleImport,
		loading,
		csvData,
		setCsvRows,
		setImportProps,
		file,
		setFile
	}
}
