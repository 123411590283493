import { ApiNodeColumn, ApiNodeData } from '@/api/schemas/api-node'
import { ensureValidData } from '../helpers'
import { v4 } from 'uuid'

export const defaultApiNodeData: Readonly<ApiNodeData> = {
	name: '',
	code: '',
	columns: [],
	columnsLastId: 0,
	objectPermissions: [],
	objectPermissionLastId: 0
}

export const getInitialApiNodeData = (name: string, code = ''): ApiNodeData => {
	return ensureValidData(
		{
			name,
			code
		},
		defaultApiNodeData
	)
}

export const createEmptyColumn = (columns: ApiNodeColumn[]): ApiNodeColumn => ({
	id: -1,
	uuid: v4(),
	order: Math.max(0, ...columns.map(c => c.order || 0)) + 1,
	name: '',
	mandatory: false
})
