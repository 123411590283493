import React, { useState } from 'react'
import SplitPane from 'react-split-pane'
import { TreeView } from './components/TreeView'
import { View } from './components/View'
import { SettingsItem } from './types'
import { TreeContainer, ViewContainer, Panels } from './styles'

export const Settings = () => {
	const [selected, setSelected] = useState(null as SettingsItem | null)

	return (
		<Panels>
			<SplitPane
				split="vertical"
				minSize={120}
				maxSize={500}
				defaultSize={parseInt(
					localStorage.getItem('NavigationWidth') || '200',
					10
				)}
				onChange={size =>
					localStorage.setItem('NavigationWidth', size.toString())
				}
			>
				<TreeContainer>
					<TreeView
						selected={selected}
						onSelected={type => setSelected(type)}
					/>
				</TreeContainer>
				<ViewContainer>
					<View item={selected} />
				</ViewContainer>
			</SplitPane>
		</Panels>
	)
}
