import { UPDATE_ACTIONS_TIMESTAMPS } from './constants'

type Payload = {
	actionType: string
	id: string | number
	start: number
}

interface UpdateActionsTimestamps {
	type: typeof UPDATE_ACTIONS_TIMESTAMPS
	payload: Payload
}

export const updateActionsTimestamps = (
	opts: Payload
): UpdateActionsTimestamps => ({
	type: UPDATE_ACTIONS_TIMESTAMPS,
	payload: opts
})

export type Actions = UpdateActionsTimestamps
