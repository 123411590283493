import { IdCodeName } from './general'
import { SystemUser } from '@/api/models'
import { SourceItem } from './source'
import { CustomAttributeForm } from '@/pages/User/pages/Home/components/CustomAttributes/hooks/useCustomAttributes'

export interface MappingData {
	code?: string
	columns?: MappingColumn[]
	objectSettings?: IdCodeName[]
	columnsLastId?: number
	criteria?: string
	criteriaDescription?: string
	targetCriteria?: string
	targetCriteriaDescription?: string
	description?: string
	comment?: string
	distinct?: boolean
	subStereotypeId?: number
	name?: string
	outWhere?: string
	outWhereDescription?: string
	postMappingScript?: string
	postMappingScriptDescription?: string
	preMappingScript?: string
	preMappingScriptDescription?: string
	mappingContextScript?: string
	mappingContextScriptDescription?: string
	mappingDeleteContextScript?: string
	mappingDeleteContextScriptDescription?: string
	sourceHints?: string
	sourceHintsDescription?: string
	sourcesStructured?: boolean
	sources?: string
	sourcesDescription?: string
	sourceItems?: SourceItem[]
	sourceItemsLastId?: number
	status?: MappingDataEnums.MappingStatusEnum
	stereotypeId?: number
	stereotypeCode?: string
	targetHints?: string
	targetHintsDescription?: string
	truncate?: boolean
	responsiblePersonId?: number
	responsiblePersonName?: string
	responsibleAnalystId?: number
	responsibleAnalystName?: number
	ownerId?: number
	defaultMappingType?: MappingDataEnums.MappingDefaultMappingTypeEnum
	ownerName?: string
	objectPermissions?: MappingPermission[]
	objectPermissionLastId?: number
	customAttributes?: CustomAttributeForm[]
}

export interface MappingColumn {
	id: number
	columnCode: string
	keyFlag?: boolean
	mapping: string
	mappingDescription?: string
	mappingComment?: string
	outerMapping?: string
	updateFlag?: boolean
	groupByFlag?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MappingDataEnums {
	export enum MappingStatusEnum {
		LOGICAL_IN_PROGRESS = 'logical_in_progress',
		LOGICAL_COMPLETED = 'logical_completed',
		PHYSICAL_IN_PROGRESS = 'physical_in_progress',
		PHYSICAL_COMPLETED = 'physical_completed',
		FROZEN = 'frozen'
	}
	export enum MappingDefaultMappingTypeEnum {
		INTERFACE = 'interface',
		REFERENCE = 'reference',
		HISTORY = 'history'
	}
}

export interface MappingPermission {
	id?: number // int64
	user?: SystemUser
	permissions?: MappingPermission.PermissionsEnum[]
	order?: number // int64
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MappingPermission {
	export enum PermissionsEnum {
		EXECUTE = 'EXECUTE',
		DEBUG = 'DEBUG'
	}
}
