import { StructureDto } from '@/api/models'
import { AnyObject } from '@/components/UberForm/Form'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { CallNamingWorkingDataParams } from '@/store/modules/table/utils/callNaming'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'
import { SqlTypeEnum } from '@/typings/enum/SqlTypeEnum'
import { useMemo } from 'react'

/** Not used for Table & Default Mappings because they have different modes (reference, historical) */
export const useGetNamingConfig = (
	form: AnyObject,
	node?: StructureDto
): Readonly<CallNamingWorkingDataParams> | undefined => {
	const detailTabContext = useDetailTabContext()

	const namingConfig = useMemo(() => {
		if (node === undefined || detailTabContext === undefined) {
			return undefined
		}

		const {
			state: { editMode }
		} = detailTabContext

		if (!editMode) {
			return undefined
		}

		switch (node.type) {
			case StructureDto.TypeEnum.API_COLLECTION: {
				return [
					NamingDtoTypeEnum.API_COLLECTION_CODE,
					node.id,
					SqlTypeEnum.API_COLLECTION,
					{
						body: { data: JSON.stringify(form) }
					}
				] as const
			}

			case StructureDto.TypeEnum.API_NODE: {
				return [
					NamingDtoTypeEnum.API_NODE_CODE,
					node.id,
					SqlTypeEnum.API_NODE,
					{
						body: { data: JSON.stringify(form) }
					}
				] as const
			}

			case StructureDto.TypeEnum.QUEUE: {
				return [
					NamingDtoTypeEnum.QUEUE_CODE,
					node.id,
					SqlTypeEnum.QUEUE,
					{
						body: { data: JSON.stringify(form) }
					}
				] as const
			}

			case StructureDto.TypeEnum.VIEW: {
				return [
					NamingDtoTypeEnum.VIEW_CODE,
					node.id,
					SqlTypeEnum.VIEW,
					{
						body: { data: JSON.stringify(form) }
					}
				] as const
			}

			case StructureDto.TypeEnum.MAPPING_READ: {
				return [
					NamingDtoTypeEnum.MAPPING_CODE,
					node.id,
					SqlTypeEnum.MAPPING_READ,
					{
						body: { data: JSON.stringify(form) }
					}
				] as const
			}

			case StructureDto.TypeEnum.MAPPING_WRITE: {
				return [
					NamingDtoTypeEnum.MAPPING_CODE,
					node.id,
					SqlTypeEnum.MAPPING_WRITE,
					{
						body: { data: JSON.stringify(form) }
					}
				] as const
			}

			case StructureDto.TypeEnum.WORKFLOW: {
				return [
					NamingDtoTypeEnum.WORKFLOW_CODE,
					node.id,
					SqlTypeEnum.WORKFLOW,
					{
						body: { data: JSON.stringify(form) }
					}
				] as const
			}

			default: {
				console.error('Unknown structure type in useGetNamingConfig')

				return undefined
			}
		}
	}, [detailTabContext, form, node])

	return namingConfig
}
