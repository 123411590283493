import React from 'react'

import { splitProps } from '@/utils/collections'
import FormField, { FormFieldProps, FormlessFormField } from '../FormField'
import Text, { TextProps } from '../Input/Text/Text'

/**
 * Form field with text input.
 */
export default class TextFormField extends React.PureComponent<
	Omit<TextProps, 'onChange'> & FormFieldProps & { formless?: boolean }
> {
	render() {
		const fieldProps = { ...this.props }

		const inputProps = splitProps(fieldProps, [
			'placeholder',
			'type',
			'autoFocus',
			'enableAutocomplete',
			'formatter',
			'titleContainer'
		])

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps}>
				<Text {...inputProps} />
			</FF>
		)
	}
}
