import React, { useCallback, useMemo } from 'react'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { StructureDto } from '@/api/models'
import {
	Form,
	ReadonlyFormField,
	TextFormField,
	SelectFormField
} from '@/components/UberForm'
import { Container } from '../../../TableDetail/components/StyledComponents'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { booleanString } from '@/utils/booleans'
import { Flex } from '@/components/Layout'
import { uniqueNameValidator } from '@/utils/validators'
import {
	getAllSystemTechnologies,
	getAllWorkflowTechnologies,
	getSystemUsers
} from '@/api'
import { useApi } from '@/api/hooks'
import { SystemData } from '@/api/schemas'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { GenerateCodeEnum } from '@/typings/enum/GenerateCodeEnum'
import { useCustomAttributesInitValues } from '../../../../components/CustomAttributes/hooks/useCustomAttributes'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'

type Props = {
	node?: StructureDto
	data: OpenedSystemData
	editMode: boolean
	systemNodeId: number
	onChange: (v: Partial<SystemData>) => void
	/** Selected nodes for batch edit */
	selectedNodes?: StructureDto[]
}

export const Overview = ({
	node,
	data,
	editMode,
	systemNodeId,
	onChange,
	selectedNodes
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const systemTechnologies = useApi(getAllSystemTechnologies())
	const workflowTechnologies = useApi(getAllWorkflowTechnologies())

	const [systemUsers, isSystemUserLoading] = useApi(
		getSystemUsers(systemNodeId)
	)

	const {
		initialValuesCustomAttributes,
		parseCustomAttribute,
		customAttributes
	} = useCustomAttributesInitValues(data.form, StructureDto.TypeEnum.SYSTEM)

	const handleChange = useCallback(
		(
			item: Partial<SystemDataForm>,
			source?: FormlessFormField<SystemDataForm>
		) => {
			if (source) {
				const name = source.props.name

				if (name === 'ownerId') {
					const owner = systemUsers?.find(u => u.id === item[name])

					onChange({
						[name]: owner?.id,
						ownerName: owner?.name
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name]
						},
						data.form.customAttributes,
						customAttributes
					)
				)
			}
		},
		[data.form.customAttributes, onChange, parseCustomAttribute, systemUsers]
	)

	const generateCodeOptions = useMemo(
		() =>
			Object.values(GenerateCodeEnum)
				.map(key => ({
					value: key,
					label: t(key)
				}))
				.filter(opt => opt.value !== GenerateCodeEnum.INHERIT),
		[t]
	)

	return (
		<Container>
			<Form<SystemDataForm>
				defaultValues={{
					name: node?.name,
					ownerId: data.form.ownerId,
					generateCode: data.form.generateCode,
					generateTableColumnCode: data.form.generateTableColumnCode,
					...initialValuesCustomAttributes
				}}
				onChange={handleChange}
				disabled={!editMode}
				key={booleanString(editMode)}
				enableFieldHighlight
			>
				<Flex>
					<TextFormField
						title={t('NAME')}
						name="name"
						validators={[uniqueNameValidator(dispatch, data.original.name)]}
						disabled={Boolean(selectedNodes)}
						initialValue={data.form.name}
					/>
					<SelectFormField
						name="ownerId"
						title={t('NODE_OWNER')}
						options={systemUsers || []}
						fieldIsLoading={isSystemUserLoading}
						initialValue={data.form.ownerId}
						valueKey="id"
						labelKey="name"
						isNumeric
						allowEmpty
						clearable
					/>
				</Flex>
				<Flex>
					<div style={{ width: '200px' }}>
						<SelectFormField
							name="generateCode"
							title={t('GENERATE_ELEMENT_CODE')}
							options={generateCodeOptions}
							clearable={false}
							initialValue={data.form.generateCode}
						/>
					</div>
					<div style={{ width: '200px' }}>
						<SelectFormField
							name="generateTableColumnCode"
							title={t('GENERATE_TABLE_COLUMN_CODE')}
							options={generateCodeOptions}
							clearable={false}
							initialValue={data.form.generateTableColumnCode}
						/>
					</div>
				</Flex>
				<ReadonlyFormField
					title={t('SYSTEM_TECHNOLOGY')}
					value={
						systemTechnologies.data?.find(
							systemTechnology =>
								systemTechnology.id === data.form.systemTechnologyId
						)?.name || t('NOTHING_HERE')
					}
				/>
				<ReadonlyFormField
					title={t('WORKFLOW_TECHNOLOGY')}
					value={
						workflowTechnologies.data?.find(
							workflowTechnology =>
								workflowTechnology.id === data.form.workflowTechnologyId
						)?.name || t('NOTHING_HERE')
					}
				/>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</Container>
	)
}
