import React, { useCallback } from 'react'
import { StructureDto, StructureDetailDto, DomainDto } from '@/api/models'
import { SystemLookupProperties } from './Properties'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { createEmptySystemMapping } from '@/store/modules/system/helpers'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/utils/hooks'
import { ListEditTab } from '@/components'
import { useDomainTechnology } from '@/utils/domain'

type Props = {
	node: StructureDetailDto
	data: OpenedSystemData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
	domains: DomainDto[]
}

export const Lookups = ({ node, data, editMode, domains, onChange }: Props) => {
	const { t } = useAppContext()
	const { getDomainData } = useDomainTechnology(node?.id)	

	const handleChange = useCallback(
		(v: UpdateDeepPartial<SystemDataForm>) => {
			if (v.lookups) {
				Object.values(v.lookups).forEach(l => {
					if (l.domainId) {
						const d = domains.find(d => d.id === l.domainId)
						const domainData = getDomainData(d)
						const colData = data.form.lookups?.find(col => col.id === l.id)

						// don't change domain data, when domain is same
						if (colData && colData.domainId === l.domainId) {
							return
						}

						if (d) {
							l.domainId = d.id
							l.domainCode = d.code
							l.dataType = domainData?.dataType;
							
						} else {
							l.domainId = undefined
							l.domainCode = undefined							
							l.dataType = undefined
						}
					}
				
				})
			}

			onChange(v)
		},
		[domains, onChange, getDomainData]
	)

	return (
		<ListEditTab
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptySystemMapping}
			itemsKey={'lookups'}
			idCounterKey={'lookupsLastId'}
			onChange={handleChange}
			properties={SystemLookupProperties(t, domains)}
			tabKey="lookup"
		/>
	)
}
