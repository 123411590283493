import React from 'react'
import { PrePushValidationDto } from '@/api/models'
import { LightTableColumn } from '@/components/LightTable'
import { useMemo } from 'react'
import { useAppContext } from '@/utils/hooks'
import { CheckboxSimpleTable } from '@/pages/User/pages/Settings/pages/TechnicalColumns/Columns'

export const useValidationsColumns = () => {
	const { t } = useAppContext()

	return useMemo<LightTableColumn<PrePushValidationDto>[]>(
		() => [
			{
				field: 'path',
				title: t('ELEMENT'),
				width: 140
			},
			{
				field: 'type',
				title: t('TYPE'),
				width: 50
			},
			{
				field: 'structureType',
				title: t('ELEMENT_TYPE'),
				width: 50
			},
			{
				field: 'message',
				title: t('MESSAGE'),
				width: 50
			},
			{
				field: 'allowedIgnore',
				title: t('ALLOWED_IGNORE'),
				width: 50,
				formatter: (_, row) => (
					<CheckboxSimpleTable
						name="allowedIgnore"
						disabled
						initialValue={row.allowedIgnore ?? false}
					/>
				)
			},
			{
				field: 'editableByActualUser',
				title: t('EDITABLE_BY_ACTUAL_USER'),
				width: 50,
				formatter: (_, row) => (
					<CheckboxSimpleTable
						name="editableByActualUser"
						disabled
						initialValue={row.allowedIgnore ?? false}
					/>
				)
			}
		],
		[t]
	)
}
