import { Actions } from './actions'
import { SET_API_ERROR } from './constants'

type State = Readonly<typeof initialState>

const initialState = {
	title: null as string | null,
	description: null as string | null,
	message: null as string | null
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case SET_API_ERROR: {
			return {
				...state,
				...action.payload
			}
		}

		default: {
			return state
		}
	}
}
