import { ImportReturnMessage } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import { useMemo } from 'react'

export const useCheckMessages = () => {
	const { t } = useAppContext()

	const checkMessages = useMemo<
		Record<ImportReturnMessage.MessageTypeEnum, string>
	>(
		() => ({
			[ImportReturnMessage.MessageTypeEnum.DOMAIN_NOT_FOUND]: t(
				'DOMAIN_NOT_FOUND'
			),
			[ImportReturnMessage.MessageTypeEnum.INCOMPATIBLE_TARGET]: t(
				'INCOMPATIBLE_TARGET'
			),
			[ImportReturnMessage.MessageTypeEnum.INCONSISTENT_DOMAINS]: t(
				'INCONSISTENT_DOMAINS'
			),
			[ImportReturnMessage.MessageTypeEnum.INCONSISTENT_TABLES]: t(
				'INCONSISTENT_TABLES'
			),
			[ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_FOLDER]: t(
				'TABLE_ALREADY_EXISTS_IN_FOLDER'
			),
			[ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_SYSTEM]: t(
				'TABLE_ALREADY_EXISTS_IN_FOLDER'
			),
			[ImportReturnMessage.MessageTypeEnum.TABLE_STEREOTYPE_NOT_FOUND]: t(
				'TABLE_STEREOTYPE_NOT_FOUND'
			),
			[ImportReturnMessage.MessageTypeEnum.STEREOTYPE_NOT_FOUND]: t(
				'STEREOTYPE_NOT_FOUND'
			),
			[ImportReturnMessage.MessageTypeEnum
				.COLUMN_WITH_SAME_NAME_ALREADY_EXISTS]: t(
				'COLUMN_WITH_SAME_NAME_ALREADY_EXISTS'
			),
			[ImportReturnMessage.MessageTypeEnum
				.COLUMN_WITH_SAME_CODE_ALREADY_EXISTS]: t(
				'COLUMN_WITH_SAME_CODE_ALREADY_EXISTS'
			),
			[ImportReturnMessage.MessageTypeEnum.DUPLICATE_COLUMN_NAME]: t(
				'DUPLICATE_COLUMN_NAME'
			),
			[ImportReturnMessage.MessageTypeEnum.DUPLICATE_COLUMN_CODE]: t(
				'DUPLICATE_COLUMN_CODE'
			)
		}),
		[t]
	)

	return checkMessages
}
