import { DomainDto, StereotypeDto } from '@/api/models'
import { ApiNodeColumn } from '@/api/schemas/api-node'
import { ItemProperty, ItemPropertyType } from '@/components/Properties/types'
import { TranslationHelper } from '@/context/Locale/Locale'
import memoizeOne from 'memoize-one'
import { ApiNodeColumnType } from '@/api/schemas/api-node'

const customDomainPredicate = (domains: DomainDto[]) => (item: ApiNodeColumn) =>
	!domains.find(x => x.id === item.domainId && x.custom === true)

export const ColumnProperties = memoizeOne(
	(
		t: TranslationHelper,
		domains: DomainDto[],
		stereotypes: { data: StereotypeDto[] | undefined; reloading: boolean },
		apiNodeColumnTypes: { value: ApiNodeColumnType; label: string }[]
	): ItemProperty<ApiNodeColumn>[] => [
		{
			field: 'name',
			label: t('TABLE_COLUMNS_NAME'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			propertiesWidth: '50%',
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'code',
			label: t('TABLE_COLUMNS_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			flex: 1,
			propertiesWidth: '50%',
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'description',
			label: t('TABLE_COLUMNS_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 1.5,
			width: 200
		},
		{
			field: 'comment',
			label: t('TABLE_COLUMNS_COMMENT'),
			type: ItemPropertyType.TEXTAREA,
			flex: 1.5,
			width: 200
		},
		{
			field: 'domainId',
			label: t('TABLE_COLUMNS_DOMAIN'),
			type: ItemPropertyType.OPTION,
			flex: 1,
			optionValue: 'id',
			optionLabel: 'name',
			options: domains,
			isNumeric: true,
			propertiesWidth: '50%',
			width: 200
		},
		{
			field: 'dataType',
			label: t('TABLE_COLUMNS_DATA_TYPE'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			disabled: customDomainPredicate(domains),
			propertiesWidth: '50%',
			width: 200
		},
		{
			field: 'defaultValue',
			label: t('DOMAIN_DEFAULT_VALUE'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			propertiesWidth: '50%',
			width: 200
		},
		{
			field: 'stereotypeId',
			label: t('TABLE_COLUMNS_STEREOTYPE'),
			type: ItemPropertyType.OPTION,
			flex: 1,
			optionValue: 'id',
			optionLabel: 'name',
			options: stereotypes.data || [],
			isNumeric: true,
			isLoading: stereotypes.reloading,
			width: 200
		},
		{
			field: 'type',
			label: t('API_NODE_COLUMNS_TYPE'),
			type: ItemPropertyType.OPTION,
			flex: 1,
			optionValue: 'value',
			optionLabel: 'label',
			options: apiNodeColumnTypes || [],
			isNumeric: true,
			isLoading: stereotypes.reloading,
			width: 200
		},
		{
			field: 'notNull',
			label: t('TABLE_COLUMNS_MANDATORY'),
			tableLabel: t('TABLE_COLUMNS_MANDATORY_SHORT'),
			type: ItemPropertyType.CHECKBOX,
			propertiesWidth: '33.3333%',
			width: 60
		}
	]
)
