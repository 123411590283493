import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '@/components'
import { useAppContext } from '@/utils/hooks'
import { Loader, Message } from '@/components'
import { StructureDto } from '@/api/models'
import { useGeneratedSql } from '@/utils/sql'
import { generateSqlPreview } from '@/api'
import { Container } from '../../pages/TableDetail/components/StyledComponents'
import { Flex } from '@/components/Layout'
import { SelectFormField } from '@/components/UberForm'
import { IdCodeName } from '@/api/schemas'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import AutoSizer from 'react-virtualized-auto-sizer'

interface Props {
	node: StructureDto
	showObjectTypes?: boolean
	objectTypes?: IdCodeName[]
}

export const Preview = ({ node, showObjectTypes, objectTypes }: Props) => {
	const [isRawView, setIsRawView] = useState(false)

	const [objectTypeId, setObjectTypeId] = useState(objectTypes?.[0]?.id || null)

	const { t } = useAppContext()

	const [sql, loading, error] = useGeneratedSql(
		generateSqlPreview(
			node.id,
			objectTypeId ? { objectSettingsId: objectTypeId } : undefined
		),
		[node.id, objectTypeId]
	)

	const handleSwitchView = () => {
		setIsRawView(!isRawView)
	}

	const isObjectTypeValid = !showObjectTypes || objectTypeId

	return loading ? (
		<Loader loaded={false} />
	) : (
		<>
			<ActionBarContainer>
				<SwitchButton>
					<Button
						onClick={handleSwitchView}
						schema={!isRawView ? 'primary' : 'primaryOutlined'}
					>
						{t('SCRIPT')}
					</Button>
					<Button
						onClick={handleSwitchView}
						schema={isRawView ? 'primary' : 'primaryOutlined'}
					>
						{t('RAW_JSON')}
					</Button>
				</SwitchButton>
				{showObjectTypes && (
					<ObjectTypeSelect
						formless={true}
						name="objectType"
						options={objectTypes || []}
						valueKey="id"
						isNumeric
						allowEmpty={false}
						clearable={false}
						onChange={e => {
							setObjectTypeId(e ? e : null)
						}}
						initialValue={objectTypeId}
						labelKey="name"
					/>
				)}
			</ActionBarContainer>

			<Container>
				{error && (
					<Message
						type="error"
						header={t('DDL_CREATION_FAILED')}
						message={error}
					/>
				)}
				{!isObjectTypeValid && (
					<Message
						type="error"
						header={t('DDL_CREATION_FAILED')}
						message={t('PREVIEW_ERROR_OBJECT_TYPE')}
					/>
				)}
				{sql?.errors?.map(error => (
					<Message
						key={error}
						type="error"
						header={t('ERROR')}
						message={error}
					/>
				))}
				{isObjectTypeValid && !error && (
					<FullscreenSql>
						<AutoSizer>
							{({ height, width }) => (
								<div style={{ height, width }}>
									{isRawView ? (
										<AceEditorField
											name="script"
											value={JSON.stringify(sql?.templateDataMap, null, 2)}
											disabled
											mode="json"
											height={height}
										/>
									) : (
										<AceEditorField
											name="script"
											value={sql?.sql}
											disabled
											mode="sql"
											height={height}
										/>
									)}
								</div>
							)}
						</AutoSizer>
					</FullscreenSql>
				)}
			</Container>
		</>
	)
}

const FullscreenSql = styled.div`
	height: 100%;
	width: 100%;
`

const ActionBarContainer = styled(Flex)`
	margin: 1rem;
	margin-bottom: 0;
`

const ObjectTypeSelect = styled(SelectFormField)`
	margin: 1px;
	margin-left: 1rem;
	max-width: 250px;
`

const SwitchButton = styled.div`
	flex: 0;
	border-radius: 20px;
	display: flex;

	button {
		&:first-child {
			border-radius: 20px 0 0 20px;
		}
		&:last-child {
			border-radius: 0 20px 20px 0;
		}
	}
`
