import React from 'react'

import { splitProps } from '@/utils/collections'
import FormField, { FormFieldProps, FormlessFormField } from '../FormField'
import { NamingProps, NamingInput } from '../Input/Naming/NamingInput'

export default class NamingInputFormField extends React.PureComponent<
	Omit<NamingProps, 'onChange'> & FormFieldProps & { formless?: boolean }
> {
	render() {
		const fieldProps = { ...this.props }

		const inputProps = splitProps(fieldProps, [
			'node',
			'updateNaming',
			'className',
			'callNamingWorkingDataParams',
			'onFocusCustom',
			'onBlurCustom',
			'autoFocus',
			'readonlyContainer'
		])

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps}>
				<NamingInput {...inputProps} />
			</FF>
		)
	}
}
