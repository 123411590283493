import React from 'react'
import styled, { css } from 'styled-components'

import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { nextFrame } from '@/utils/async'

export interface TextAreaProps extends FormFieldContext {
	className?: string
	rows?: number
	value?: string
	autoFocus?: boolean
}

class TextArea extends React.PureComponent<TextAreaProps> {
	input = React.createRef<HTMLTextAreaElement>()

	async componentDidMount() {
		await nextFrame()

		if (this.input.current && this.props.autoFocus) {
			this.input.current.focus()
		}
	}

	onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (this.props.onChange) {
			this.props.onChange(e.target.value)
		}
	}

	onFocus = (e: React.FocusEvent) => {
		const { onFocus } = this.props

		if (onFocus) {
			onFocus(e)
		}
	}

	onBlur = (e: React.FocusEvent) => {
		const { onBlur } = this.props

		if (onBlur) {
			onBlur(e)
		}
	}

	render() {
		const { id, value, className, name, disabled, rows, compact } = this.props

		return (
			<Container
				id={id}
				ref={this.input}
				name={name}
				className={className}
				disabled={disabled}
				onChange={this.onChange}
				compact={!!compact}
				rows={rows}
				value={value || ''}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
			/>
		)
	}
}

const Container = styled.textarea<{ compact: boolean; disabled?: boolean }>`
	${props => css`
		resize: vertical;
		min-height: 84px;
		font-size: ${props.theme.input.fontSize};
		border-color: ${props.compact
			? `${props.theme.colors.input.compact.horizontalBorder} ${props.theme.colors.input.compact.verticalBorder}`
			: props.theme.colors.input.border};
		border-radius: ${props.compact ? 0 : props.theme.input.borderRadius};
		padding: ${props.theme.input.padding};
		:focus {
			border-color: ${props.theme.colors.primary.base};
			box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
		}
		:hover {
			box-shadow: ${props.disabled
				? 'none'
				: `0 0 3px ${props.theme.colors.primary.shadowColor}`};
		}
	`}
`

export default withFormField(TextArea)
