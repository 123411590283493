import React from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { AcronymDto, EditUserInfoDto } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import { TextFormField } from '@/components/UberForm'
import { useApiRequest } from '@/api/hooks'
import { updateAcronym, createAcronym } from '@/api'
import { AuditForm } from '../../../components/AuditForm'
import { auditFieldsRemove, auditFieldsAdd } from '../../../utils'

type Props = {
	acronym?: Partial<AcronymDto>
	onSave: () => void
	onClose: () => void
}

export const AcronymModal = ({ acronym, onSave, onClose }: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const handleSubmit = async (form: Partial<EditUserInfoDto | AcronymDto>) => {
		auditFieldsRemove(form)

		const action =
			acronym && acronym.id
				? updateAcronym(acronym.id, { body: form as AcronymDto })
				: createAcronym({ body: form as AcronymDto })

		const result = await request(action)

		if (result) {
			onSave()
			onClose()
		}
	}

	return (
		<ModalForm<EditUserInfoDto | AcronymDto>
			open={true}
			onClose={onClose}
			saveTitle={acronym ? t('PUSH') : t('ADD_NEW')}
			contentStyle={{
				width: '550px'
			}}
			initialValues={{
				text: acronym?.text,
				acronym: acronym?.acronym,
				...auditFieldsAdd(acronym)
			}}
			header={acronym ? t('UPDATE_ACRONYM') : t('ADD_NEW_ACRONYM')}
			onSubmit={handleSubmit}
		>
			<TextFormField title={t('ACRONYM_WORD')} name="text" autoFocus required />
			<TextFormField title={t('ACRONYM')} name="acronym" required />
			<AuditForm isUpdating={!!acronym} />
		</ModalForm>
	)
}
