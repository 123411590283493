import React, { useEffect, useMemo } from 'react'
import { faColumns, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/utils/hooks'
import { DialogWrapper, Button, LightTable } from '@/components'
import { ColumnProperties } from './Columns'
import { TechColumnModal } from './components/TechColumnModal'
import { Container } from '@/components/Container'
import { useApi } from '@/api/hooks'
import {
	getAllTechnicalColumns,
	getStereotypes,
	updateTechnicalColumn,
	getAllDomainsForCombo
} from '@/api'
import { useApiRequest } from '@/api/hooks'
import { StereotypeDto } from '@/api/models'
import {
	auditFieldsRemove,
	rowOrderChangeHandler,
	usePageItemsSort,
	useSettingsPageSaveHandler
} from '../../utils'
import { excludeCustomDomains } from '@/utils/domain'
import {
	TitleContainer,
	TitleIcon,
	TitleText,
	TitleActions
} from '../../styles'
import { useFilter } from '../../hooks/useFilter'
import {
	TechnicalColumnsFilter,
	TechnicalColumnsFilterValues
} from './components/TechnicalColumnsFilter'
import { useTechnicalColumnsFilteredData } from './hooks/useTechnicalColumnsFilteredData'

export const TechnicalColumns = () => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const { filter, onChangeFilter } = useFilter<TechnicalColumnsFilterValues>()

	const techColumns = useApi(getAllTechnicalColumns())
	const domains = useApi(getAllDomainsForCombo())

	const domainsData = useMemo(() => excludeCustomDomains(domains.data), [
		domains.data
	])

	const stereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.TABLE })
	)

	const historyStereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.TABLE_HISTORY })
	)

	const stereotypesWithHistory = useMemo(
		() => (stereotypes.data || []).concat(historyStereotypes.data || []),
		[stereotypes, historyStereotypes]
	)

	const handleSave = useSettingsPageSaveHandler(techColumns)

	const columns = ColumnProperties(
		t,
		handleSave,
		domainsData,
		stereotypesWithHistory
	)

	useEffect(() => {
		if (
			techColumns &&
			techColumns.data &&
			techColumns.data?.length > 0 &&
			techColumns.data[0].ordering === null
		) {
			Promise.all(
				techColumns.data.map((item, i) => {
					auditFieldsRemove(item)

					return request(
						updateTechnicalColumn(item.id as number, { ...item, ordering: i })
					)
				})
			).then(() => techColumns.invalidate())
		}
	}, [request, techColumns])

	const sortedTechColumns = usePageItemsSort(techColumns)

	const filteredTechColumns = useTechnicalColumnsFilteredData(
		filter,
		sortedTechColumns
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faColumns} />
				<TitleText>{t('TECHNICAL_COLUMNS_TITLE')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<TechColumnModal
									stereotypes={stereotypesWithHistory}
									position={techColumns.data ? techColumns.data.length : 0}
									onClose={onClose}
									onSave={handleSave}
								/>
							)
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<TechnicalColumnsFilter
					onChangeFilter={onChangeFilter}
					stereotypes={stereotypesWithHistory}
				/>
				<LightTable
					dragArrowVisible={false}
					isRowOrderable
					onRowOrderChanged={(oldIndex, newIndex) =>
						rowOrderChangeHandler(
							oldIndex,
							newIndex,
							techColumns,
							request,
							updateTechnicalColumn
						)
					}
					columns={columns}
					keyField={'id'}
					data={filteredTechColumns}
					style={{
						marginLeft: 15
					}}
				/>
			</Container>
		</>
	)
}
