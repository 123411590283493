import { UserDto } from '../../../api/models'
import { Actions } from './actions'
import { AUTH_LOGOUT, AUTH_TOKEN, AUTH_USER } from './constants'
import { LANGUAGE } from '@/context/Locale'
import { loadState, saveState } from '@/store/utils'
import keycloak from '@/api/keycloak'

const AUTH_STORAGE_KEY = 'AUTH_TOKEN'
const AUTH_STORAGE_VERSION = '1.0'
const AUTH_STORAGE_FIELDS = ['locale', 'token'] as const

const loadAuthState = () =>
	loadState(AUTH_STORAGE_KEY, AUTH_STORAGE_VERSION, AUTH_STORAGE_FIELDS)

const saveAuthState = (state: State) =>
	saveState(AUTH_STORAGE_KEY, AUTH_STORAGE_VERSION, AUTH_STORAGE_FIELDS, state)

const initialState = {
	user: null as UserDto | null,
	locale: 'en' as LANGUAGE,
	token: null as string | null
}

type State = Readonly<typeof initialState>

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case AUTH_TOKEN: {
			return saveAuthState({
				...state,
				token: action.token
			})
		}

		case AUTH_LOGOUT: {
			keycloak.logout()

			return saveAuthState({
				...state,
				user: null,
				token: null,
				locale: 'en'
			})
		}

		case AUTH_USER: {
			return saveAuthState({
				...state,
				user: action.user
			})
		}

		default: {
			return state
		}
	}
}
