import { getReleaseEnvironments } from '@/api'
import { useApiRequest, useApi } from '@/api/hooks'
import { ReleaseDto, StructureDto } from '@/api/models'
import { Button, DialogWrapper, Loader } from '@/components'
import { Flex, ThCell } from '@/components/Layout'
import { useAppContext } from '@/utils/hooks'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { AddEnvironment } from './AddEnvironment'
import { ReleaseEnvironment } from './ReleaseEnvironment'

type Props = {
	node: StructureDto
	release: ReleaseDto
	editMode: boolean
}

export const ReleaseEnvironments = ({ node, release, editMode }: Props) => {
	const { t } = useAppContext()

	const request = useApiRequest()

	const { data: environments, loading, invalidate } = useApi(
		getReleaseEnvironments(node.id, release.id!)
	)

	const environmentsSorted = useMemo(
		() => sortBy(environments, env => env.id),
		[environments]
	)

	const reloadEnvs = useCallback(() => invalidate(), [invalidate])

	return (
		<>
			<HeaderWrapper>
				<Header>{t('ENVIRONMENTS_IN_RELEASE')}</Header>
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<>
								<AddEnvironment
									node={node}
									release={release}
									envs={environmentsSorted}
									onClose={() => {
										reloadEnvs()
										onClose()
									}}
								/>
							</>
						)
					}
				>
					{onClick => <Button onClick={onClick}>{t('ADD_ENVIRONMENT')}</Button>}
				</DialogWrapper>
			</HeaderWrapper>
			<Flex align="stretch">
				<ThCell>{t('NAME')}</ThCell>
				<ThCell>{t('CODE')}</ThCell>
				<ThCell title={t('RELEASE_DATE')}>{t('RELEASE_DATE')}</ThCell>
				<ThCell title={t('RELEASED_TIME')}>{t('RELEASED_TIME')}</ThCell>
				<ReleasedThCell title={t('RELEASED')}>{t('RELEASED')}</ReleasedThCell>
				<DeployThCell />
				<Actions />
			</Flex>

			<Loader loaded={!loading} absolute />

			{environmentsSorted?.map(env => (
				<ReleaseEnvironment
					key={env.id}
					node={node}
					release={release}
					env={env}
					editMode={editMode}
					reloadEnvs={reloadEnvs}
				/>
			))}
		</>
	)
}

const HeaderWrapper = styled(Flex)`
	margin-top: 10px;
	margin-bottom: 10px;
`

const Header = styled.div`
	font-size: 16px;
	margin-right: 6px;
`

const Actions = styled.div`
	width: 40px;
`

const ReleasedThCell = styled(ThCell)`
	flex: 0.5;
`

const DeployThCell = styled(ThCell)`
	width: 200px;
`
