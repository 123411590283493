import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { ItemPropertyType, ItemProperty } from '@/components/Properties/types'
import { ReadMappingColumn } from '@/api/schemas'
import { ApiNodeColumn } from '@/api/schemas/api-node'

export const ReadMappingColumnProperties = memoizeOne(
	(
		t: TranslationHelper,
		columns: ApiNodeColumn[],
		availableColumns: ApiNodeColumn[]
	): ItemProperty<ReadMappingColumn>[] => [
		{
			field: 'columnCode',
			label: t('MAPPING_COLUMNS_COLUMN'),
			type: ItemPropertyType.OPTION,
			options: item =>
				item && item.columnCode
					? [
							columns.find(c => c.code == item.columnCode),
							...availableColumns
					  ].filter(c => !!c)
					: availableColumns,
			optionValue: 'code',
			optionLabel: 'name',
			width: 200,
			isNumeric: true,
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'mapping',
			label: t('MAPPING_COLUMNS_MAPPING'),
			type: ItemPropertyType.ACE_EDITOR,
			width: 200
		},
		{
			field: 'mappingDescription',
			label: t('MAPPING_COLUMNS_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			width: 200
		},
		{
			field: 'mappingComment',
			label: t('MAPPING_COLUMNS_COMMENT'),
			type: ItemPropertyType.TEXTAREA,
			width: 200
		},
		{
			field: 'keyFlag',
			label: t('MAPPING_COLUMNS_KEY_FLAG'),
			type: ItemPropertyType.CHECKBOX,
			width: 60
		},
		{
			field: 'updateFlag',
			label: t('MAPPING_COLUMNS_UPDATE_FLAG'),
			type: ItemPropertyType.CHECKBOX,
			width: 100
		},
		{
			field: 'groupByFlag',
			label: t('MAPPING_COLUMNS_GROUP_BY_FLAG'),
			type: ItemPropertyType.CHECKBOX,
			width: 100
		}
	]
)
