import { TextProps } from '../Input/Text/Text'

export enum InputTypeEnum {
	TEXT = 'text'
}

export type ArrayFormFieldType = { inputType: InputTypeEnum } & TextProps

export type ArrayFormFieldProps = {
	name: string
	title: string
	fields: ArrayFormFieldType[]
	disabled?: boolean
}
