import { useState, useEffect } from 'react'
import { GeneratedSqlDto } from '@/api/models'
import { ApiHookCallback } from '@/api/hooks'
import { useApi } from '@/api/hooks'
import { ApiError } from '@/api/utils'
import { tryParseJSON } from './json'

export const useGeneratedSql = (
	apiCall: ApiHookCallback<GeneratedSqlDto>,
	deps: unknown[]
): [GeneratedSqlDto | null, boolean, string | null, () => void] => {
	const result = useApi(apiCall)

	const [error, setError] = useState(null as string | null)
	const [sql, setSql] = useState(null as GeneratedSqlDto | null)

	useEffect(() => {
		setSql(result.data ?? null)
	}, [...deps, result.data])

	useEffect(() => {
		const loadError = async () => {
			if (result.error instanceof ApiError) {
				const text = await result.error.response.clone().text()
				const json = tryParseJSON(text)

				if (json && json.errorDescription) {
					setError(json.errorDescription)
				} else {
					setError(text)
				}
			} else {
				setError(result.error?.message)
			}
		}

		loadError()
	}, [result.error])

	return [sql, result.loading || result.reloading, error, result.invalidate]
}
