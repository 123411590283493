import React from 'react'
import styled from 'styled-components'
import { MessageModal } from '@/components'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import { setApiError } from '@/store/modules/apiError/actions'

export const ApiErrorMessage = () => {
	const dispatch = useAppDispatch()
	const { title, description, message } = useAppStore(state => state.apiError)

	return (
		<>
			{title && description && (
				<MessageModal
					opened={true}
					onClose={() =>
						dispatch(
							setApiError({ title: null, description: null, message: null })
						)
					}
					type="error"
					title={title}
					width={message ? 800 : undefined}
					stickyFooter
					message={
						<>
							<Description>{description}</Description>
							{message && <Message>{message}</Message>}
						</>
					}
				/>
			)}
		</>
	)
}

const Description = styled.p`
	font-size: 110%;
	word-break: break-all;
	font-weight: 600;
	white-space: pre-wrap;
`

const Message = styled.p`
	white-space: pre-wrap;
	word-break: break-all;
	margin-top: 20px;
`
