import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import React, { memo } from 'react'

import styled from 'styled-components'

interface Props {
	icon?: IconProp
	tooltip?: string
}

const HelpIcon = ({ icon = faInfo, tooltip = '' }: Props) => (
	<Container title={tooltip}>
		{icon && <FontAwesomeIcon icon={icon} />}
	</Container>
)

const Container = styled.span`
	margin-left: 5px;
	opacity: 0.7;
	transition: 0.2s opacity;
	&:hover {
		opacity: 1;
	}
`

export default memo(HelpIcon)
