import {
	NODE_LOAD_SYSTEM,
	NODE_LOAD_CHILDREN,
	NODE_EXPAND,
	NODE_COLLAPSE,
	NODE_ADD,
	NODE_ADD_SYSTEM,
	NODE_DELETE,
	NODE_LOCK,
	NODE_UNLOCK,
	NODE_IMPORT_STATE,
	NODE_LOAD_PERMISSIONS,
	NODE_REFRESH_TREE,
	NODE_SELECT,
	NODE_DESELECT,
	NODE_SELECT_MODE_CHANGE,
	NODE_LOAD_NESTED_CHILDREN
} from './constants'
import { apiCallAction } from '@/store/utils'
import {
	getRootNodes,
	getNodesOfSpecificParent,
	createChildNode,
	createNode,
	deleteNode as deleteStructure,
	createNodeLock,
	deleteNodeLock,
	getStructurePermissions,
	changeUserPermission,
	deleteUserPermission,
	duplicateNodeRecursive
} from '@/api'
import {
	StructureDto,
	NewStructureDto,
	StructureUserPermissionDto,
	NewStructureUserPermissionDto
} from '@/api/models'
import { openTab, closeTabAndRelated } from '../tab/actions'
import { StoreState } from '@/store'
import { NodeSession } from '.'
import { AppDispatch } from '@/store/utils'
import { isEditableNodeType } from '@/utils/nodes'
import { LoadNode, LoadNodeHistoryVersion } from './general-actions'
import { getParents } from './helpers'

interface LoadSystemNodes {
	type: typeof NODE_LOAD_SYSTEM
	payload: StructureDto[]
}

interface LoadNodeChildren {
	type: typeof NODE_LOAD_CHILDREN
	payload: StructureDto[]
	metadata: {
		parentId: number
	}
}

interface LoadNestedNodeChildren {
	type: typeof NODE_LOAD_NESTED_CHILDREN
	payload: StructureDto[]
}

interface AddNode {
	type: typeof NODE_ADD | typeof NODE_ADD_SYSTEM
	payload: StructureDto
}

interface ExpandNode {
	type: typeof NODE_EXPAND
	key: number
}

interface CollapseNode {
	type: typeof NODE_COLLAPSE
	key: number
}

interface DeleteNode {
	type: typeof NODE_DELETE
	payload: void
	metadata: {
		node: StructureDto
	}
}

interface LockNode {
	type: typeof NODE_LOCK
	payload: void
	metadata: {
		node: StructureDto
		userId: number
		userName: string
	}
}

interface UnlockNode {
	type: typeof NODE_UNLOCK
	payload: void
	metadata: {
		node: StructureDto
	}
}

interface NodeImportState {
	type: typeof NODE_IMPORT_STATE
	session: NodeSession
}

interface NodeLoadPermissions {
	type: typeof NODE_LOAD_PERMISSIONS
	payload: StructureUserPermissionDto[]
	metadata: {
		nodeId: number
	}
}

interface NodeRefreshTree {
	type: typeof NODE_REFRESH_TREE
}

interface SelectNodeModeChange {
	type: typeof NODE_SELECT_MODE_CHANGE
	metadata: {
		selectMode: boolean
	}
}
interface SelectNodes {
	type: typeof NODE_SELECT
	metadata: {
		selectedNodes: number[]
	}
}

interface DeselectNodes {
	type: typeof NODE_DESELECT
	metadata: {
		deselectedNodes: number[]
	}
}

export const loadSystemNodes = () =>
	apiCallAction<LoadSystemNodes>(() => getRootNodes(), NODE_LOAD_SYSTEM)

export const loadNodeChildren = (id: number) =>
	apiCallAction<LoadNodeChildren>(
		() => getNodesOfSpecificParent(id),
		NODE_LOAD_CHILDREN,
		{ parentId: id }
	)

export const loadNestedNodeChildren = (id: number) => async (
	dispatch: AppDispatch
) => {
	const children = await dispatch(
		apiCallAction<LoadNestedNodeChildren>(
			() => getNodesOfSpecificParent(id, { recursive: true }),
			NODE_LOAD_NESTED_CHILDREN
		)
	)

	return children
}

export const selectNode = (node: StructureDto) => async (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	const selectMode = getValues().node.selectMode

	if (!selectMode) {
		dispatch(selectModeChange(true))
	}

	dispatch({
		type: NODE_SELECT,
		metadata: {
			selectedNodes: [node.id]
		}
	})
}

export const selectAllNode = (node: StructureDto) => async (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	const selectMode = getValues().node.selectMode

	if (!selectMode) {
		dispatch(selectModeChange(true))
	}

	const nodesToSelect = await dispatch(loadNestedNodeChildren(node.id))

	dispatch({
		type: NODE_SELECT,
		metadata: {
			selectedNodes: [node.id, ...nodesToSelect.map(n => n.id)]
		}
	})
}

export const deselectNodes = (nodeIds: number[]) => async (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	const { nodes } = getValues().node

	const parentIds = nodeIds.reduce((parentIds, nodeId) => {
		const node = nodes[nodeId]

		if (node === undefined) {
			return parentIds
		}

		return [...parentIds, ...getParents(node, nodes, [])]
	}, [] as number[])

	dispatch({
		type: NODE_DESELECT,
		metadata: {
			deselectedNodes: [...nodeIds, ...parentIds]
		}
	})
}

export const deselectAllNode = (node: StructureDto) => async (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	const { children, nodes } = getValues().node

	const getChildren = (parentId: number) => {
		let result = [parentId]
		const parentChildren = children[parentId]

		if (!parentChildren) {
			return []
		}

		result = [...result, ...parentChildren]

		parentChildren?.forEach(child => {
			const ch = getChildren(child)

			if (ch) {
				result = [...result, ...ch]
			}
		})

		return result
	}

	const nodeToDeselect = getChildren(node.id)
	const parentNodes = getParents(node, nodes, [])

	dispatch({
		type: NODE_DESELECT,
		metadata: {
			deselectedNodes: [...nodeToDeselect, ...parentNodes, node.id]
		}
	})
}

export const selectModeChange = (selectMode: boolean) => async (
	dispatch: AppDispatch
) =>
	dispatch({
		type: NODE_SELECT_MODE_CHANGE,
		metadata: {
			selectMode
		}
	})

export const addNode = (parentId: number, data: NewStructureDto) => async (
	dispatch: AppDispatch
) => {
	const newNode = await dispatch(
		apiCallAction<AddNode>(() => createChildNode(parentId, data), NODE_ADD)
	)

	const isEditable = isEditableNodeType(data.type)

	dispatch(loadNodeChildren(parentId))
	dispatch(expandNode(parentId))

	if (isEditable) {
		dispatch(lockNode(newNode))
	}

	dispatch(openTab(newNode, false, isEditable))
}

export const addSystemNode = (data: NewStructureDto) => async (
	dispatch: AppDispatch
) => {
	const newSystemNode = await dispatch(
		apiCallAction<AddNode>(() => createNode(data), NODE_ADD_SYSTEM)
	)

	dispatch(loadSystemNodes())
	dispatch(openTab(newSystemNode, false))
}

export const deleteNode = (node: StructureDto) => async (
	dispatch: AppDispatch
) => {
	dispatch(closeTabAndRelated(node))

	await dispatch(
		apiCallAction<DeleteNode>(() => deleteStructure(node.id), NODE_DELETE, {
			node
		})
	)

	if (node.type && node.type !== StructureDto.TypeEnum.SYSTEM) {
		dispatch(loadNodeChildren(node.parentStructureId as number))
	} else {
		dispatch(loadSystemNodes())
	}
}

export const lockNode = (node: StructureDto) => async (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	const user = getValues().auth.user

	if (!user) {
		throw new Error(`There is no authenticated user`)
	}

	await dispatch(
		apiCallAction<LockNode>(() => createNodeLock(node.id), NODE_LOCK, {
			node,
			userId: user.id,
			userName: user.compositeName
		})
	)

	dispatch(loadNodeChildren(node.parentStructureId as number))
}

export const unlockNode = (node: StructureDto) => async (
	dispatch: AppDispatch
) => {
	await dispatch(
		apiCallAction<UnlockNode>(() => deleteNodeLock(node.id), NODE_UNLOCK, {
			node
		})
	)

	dispatch(loadNodeChildren(node.parentStructureId as number))
}

export const expandNode = (key: number): ExpandNode => ({
	type: NODE_EXPAND,
	key
})

export const collapseNode = (key: number): CollapseNode => ({
	type: NODE_COLLAPSE,
	key
})

export const loadNodeState = (session: NodeSession) => (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	const updated = { ...session }
	const children = getValues().node.children

	// Normalize expanded nodes
	const expanded = new Set<number>()
	session.expanded.forEach(id => expanded.add(id))
	updated.expanded = Array.from(expanded)

	dispatch({
		type: NODE_IMPORT_STATE,
		session: updated
	})

	return Promise.all(
		getValues()
			.node.expanded.filter(nodeId => !children[nodeId])
			.map(nodeId =>
				dispatch(loadNodeChildren(nodeId)).catch(() => {
					dispatch(collapseNode(nodeId))
				})
			)
	)
}

export const loadNodePermissions = (id: number) =>
	apiCallAction<NodeLoadPermissions>(
		() => getStructurePermissions(id),
		NODE_LOAD_PERMISSIONS,
		{ nodeId: id }
	)

export const setNodePermission = (
	nodeId: number,
	userId: number,
	permissionCode: NewStructureUserPermissionDto.PermissionCodeEnum
) =>
	apiCallAction(() => changeUserPermission({ permissionCode }, nodeId, userId))

export const removeNodePermission = (nodeId: number, userId: number) =>
	apiCallAction(() => deleteUserPermission(nodeId, userId))

export const nodeRefreshTree = () => async (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	await dispatch(loadSystemNodes())

	await Promise.all(
		getValues().node.expanded.map(async nodeId => {
			try {
				await dispatch(loadNodeChildren(nodeId))
			} catch (e) {
				console.warn(`Failed to reload children for ${nodeId}:`, e)
			}
		})
	)
}

export const duplicateNodeInTree = (node: StructureDto, opts?: { suffix?: string; useSuffixOnly4System?: boolean } ) => async (
	dispatch: AppDispatch
) => {
	await dispatch(apiCallAction(() => duplicateNodeRecursive(node.id, opts)))

	if (node.parentStructureId) {
		dispatch(loadNodeChildren(node.parentStructureId))
	}
}

export type Actions =
	| LoadSystemNodes
	| LoadNodeChildren
	| LoadNestedNodeChildren
	| AddNode
	| ExpandNode
	| CollapseNode
	| DeleteNode
	| LockNode
	| UnlockNode
	| NodeImportState
	| LoadNode
	| NodeLoadPermissions
	| NodeRefreshTree
	| SelectNodes
	| DeselectNodes
	| SelectNodeModeChange
	| LoadNodeHistoryVersion
