export enum SettingsItem {
	Security,
	Users,
	Configuration,
	Acronyms,
	Technologies,
	Templates,
	Naming,
	BusinessDomains,
	Domains,
	TechnicalColumns,
	ObjectSettings,
	StereoTypes,
	CustomAttributes,
	CustomLovs,
	UserInterface,
	ScriptEditor
}

export interface Audit {
	createdAt?: string
	createdBy?: string
	deleted?: boolean
	updatedAt?: string
	updatedBy?: string
	version?: number
}
