import { StereotypeDto } from '@/api/models'
import { Form, SelectFormField, TextFormField } from '@/components/UberForm'
import { useAppContext } from '@/utils/hooks'
import React from 'react'
import { FilterCell, FilterContainer } from '../../../components/Filter'

export type TechnicalColumnsFilterValues = {
	stereotypeIds?: number
	updatedBy?: string
}

type Props = {
	onChangeFilter: (values: TechnicalColumnsFilterValues) => void
	stereotypes: StereotypeDto[]
}

export const TechnicalColumnsFilter = ({
	onChangeFilter,
	stereotypes
}: Props) => {
	const { t } = useAppContext()

	return (
		<Form<TechnicalColumnsFilterValues> onChange={onChangeFilter}>
			<FilterContainer style={{ marginLeft: 15 }}>
				<FilterCell width={50} maxWidth={210} />
				<FilterCell width={50} maxWidth={170} />
				<FilterCell width={50} maxWidth={170} />
				<FilterCell width={60} maxWidth={80} />
				<FilterCell width={50}>
					<SelectFormField
						isNumeric
						valueKey={'id'}
						labelKey={'name'}
						title={t('STEREOTYPE')}
						name="stereotypeIds"
						options={stereotypes}
						clearable
					/>
				</FilterCell>
				<FilterCell width={200} maxWidth={200}>
					<TextFormField title={t('UPDATED_BY')} name="updatedBy" />
				</FilterCell>
				<FilterCell width={170} maxWidth={170} />
				<FilterCell width={58} maxWidth={58} />
				<FilterCell width={60} maxWidth={60} />
			</FilterContainer>
		</Form>
	)
}
