import {
	TableData,
	TableConstraint,
	TableColumn,
	TableConstraintColumn
} from '@/api/schemas'
import { PossibleForeignKeys } from '@/components/Properties/types'
import { UpdateDeepPartial } from '@/store/utils'
import { OpenedTableData, TableColumnFlat } from '@/store/modules/table/types'

export const findPrimarykeyColumns = (
	table: TableData,
	node: OpenedTableData
) => {
	const columnCodes = table.constraints
		.find(
			x => x.constraintType === TableConstraint.ConstraintTypeEnum.PRIMARY_KEY
		)
		?.columns.map(x => x.code)

	if (!columnCodes) {
		return {
			columnCodes: [],
			primaryColumns: []
		}
	}

	const colMixed = [...table.columns, ...node.stereotypeColumns]

	return {
		primaryColumns: colMixed.filter(x => x.code && columnCodes.includes(x.code))
	}
}

/**
 * Possible FKs match primaryKeys data type
 * @param primaryColumns referenced table primaryKeys
 * @param columns current table columns
 */
export const getPossibleForeignKeys = (
	primaryColumns: TableColumn[],
	columns: TableColumn[],
	stereotypeColumns: TableColumn[]
): PossibleForeignKeys => {
	const colMixed = [...columns, ...stereotypeColumns]

	const possibleForeignKeys = primaryColumns.reduce((acc, pk) => {
		const columnsDataType = colMixed.filter(
			col => col.dataType && pk.dataType && col.dataType === pk.dataType
		)

		const columnsForPK = columnsDataType

		return {
			...acc,
			[pk.uuid]: columnsForPK
		}
	}, {})

	return possibleForeignKeys
}

const getValidColumn = (
	name: string,
	code: string,
	columns: TableColumn[],
	index: number
): {
	id: number
	order: number
	name: string
	code: string
} => {
	index += 1

	if (columns.some(c => c.name === name || c.code === code)) {
		return getValidColumn(
			`${name}_${index}`,
			`${code}_${index}`,
			columns,
			index
		)
	}

	const getLastCount = (property: keyof TableColumn) =>
		columns.reduce((acc, cur) => {
			const value = cur[property] as number

			if (value >= acc) {
				return value + 1
			}

			return acc
		}, 0)

	const order = getLastCount('order')
	const id = getLastCount('id')

	return {
		id,
		order,
		name,
		code
	}
}

export const mergeColumnIntoTable = (
	table: TableData,
	colNew: TableConstraintColumn,
	pk: TableColumnFlat
) => {
	// eslint-disable-next-line prefer-const
	let { id, order, name, code } = getValidColumn(
		colNew.name as string,
		colNew.code,
		table.columns,
		0
	)

	const colNewValidated: TableColumnFlat = {
		id,
		uuid: colNew.uuid as string,
		name,
		code,
		order,
		domainId: pk.domainId,
		domainCode: pk.domainCode,
		domainName: pk.domainName,
		dataType: pk.dataType,
		defaultValue: pk.defaultValue,
		notNullFlag: pk.notNullFlag
	}

	// replace empty last row
	let replaced = false

	const columns = table.columns.map(c => {
		if (c.id === -1 && c.name === '') {
			replaced = true

			return {
				...colNewValidated,
				order: colNewValidated.order - 1
			}
		}

		return c
	})

	return {
		colNewValidated,
		columns: replaced ? columns : [...columns, colNewValidated],
		columnsLastId: colNewValidated.id
	}
}

export const getParsedData = (
	v: UpdateDeepPartial<TableData>,
	data: OpenedTableData,
	primaryKeys: TableColumn[]
) =>
	Object.keys(v.constraints ?? {}).reduce(
		(acc, cur) => {
			const constraint = (v.constraints as any)[cur] as TableConstraint

			const columnsMerge = [...data.form.columns, ...data.stereotypeColumns]

			const colNew = constraint.columns?.find(
				colConstraint =>
					columnsMerge?.every(col => col.uuid !== colConstraint.uuid) &&
					colConstraint.foreignColumnUuid
			)

			if (colNew) {
				const pk = primaryKeys.find(pk => pk.uuid === colNew.foreignColumnUuid)

				if (!pk) {
					return acc
				}

				const {
					columns,
					columnsLastId,
					colNewValidated
				} = mergeColumnIntoTable(data.form, colNew, pk)

				return {
					...acc,
					constraints: {
						[cur]: {
							// auto-fix name for duplicate name or code
							columns: constraint.columns.map(c =>
								c.uuid === colNewValidated.uuid
									? {
											...c,
											name: colNewValidated.name,
											code: colNewValidated.code
									  }
									: c
							)
						}
					} as { [key: number]: UpdateDeepPartial<TableConstraint> },
					columns,
					columnsLastId
				}
			}

			if (Object.keys(constraint).includes('foreignKeyTableId')) {
				return {
					...acc,
					foreignKeyTableChanged: true,
					current: cur
				}
			}

			if (Object.keys(constraint).includes('constraintType')) {
				return {
					...acc,
					constraintTypeChanged: true,
					current: cur
				}
			}

			return acc
		},
		{
			constraints: {} as {
				[key: number]: UpdateDeepPartial<TableConstraint>
			},
			columns: [] as TableColumn[],
			columnsLastId: 0,
			constraintTypeChanged: false,
			foreignKeyTableChanged: false,
			current: ''
		}
	)
