import React, { useEffect, useState, useMemo, useRef } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { TemplateDto, StereotypeDto, GeneratedSqlDto } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import {
	SelectFormField,
	Form,
	TextAreaFormField,
	TextFormField
} from '@/components/UberForm'
import { useApiRequest, useApi } from '@/api/hooks'
import {
	updateTemplate,
	createTemplate,
	getStereotypesForTemplates,
	getStereotypeTypes,
	getAllTechnologies,
	getObjectSettingsByType,
	getTemplate
} from '@/api'
import { Flex } from '@/components/Layout'
import { FlexItem } from '@/components/Layout/Flex'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import AutoSizer from 'react-virtualized-auto-sizer'
import validators from '@/components/UberForm/Validators'
import { TemplateModalProps } from './types'
import { ELEMENTS_WITH_OBJECT_TYPE, noSpacesRegexPattern } from './utils'

export const TemplateModal = ({
	template,
	onSave,
	onClose
}: TemplateModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const technologies = useApi(getAllTechnologies())
	const elements = useApi(getStereotypeTypes())
	const refForm = useRef<Form<TemplateDto>>(null)

	const formatTypes = useMemo(
		() => [
			{ name: t('PYTHON'), code: GeneratedSqlDto.FormatTypeEnum.PYTHON },
			{ name: t('SQL'), code: GeneratedSqlDto.FormatTypeEnum.SQL },
			{ name: t('PLAIN_TEXT'), code: GeneratedSqlDto.FormatTypeEnum.PLAIN_TEXT }
		],
		[t]
	)

	const templateDetail = useApi(getTemplate(template?.id!))

	const [selectedElement, setSelectedElement] = useState(
		template?.structureType as StereotypeDto.TypeEnum | undefined
	)

	const objectSettings = useApi(
		getObjectSettingsByType((selectedElement as any) || 'ROOT')
	)

	useEffect(() => {
		objectSettings.invalidate()
	}, [objectSettings])

	const stereotypes = useApi(
		getStereotypesForTemplates({ type: selectedElement })
	)

	const handleSubmit = async (form: Partial<TemplateDto>) => {
		const action =
			template && template.id
				? updateTemplate(template.id, form as TemplateDto)
				: createTemplate(form as TemplateDto)

		const result = await request(action)

		if (result) {
			templateDetail.invalidate()
			onSave()
			onClose()
		}
	}

	const formData = useMemo(() => templateDetail?.data || ({} as TemplateDto), [
		templateDetail
	])

	const initialValues = useMemo(
		() => ({
			technologyId: formData.technologyId,
			stereotypeId: formData.stereotypeId,
			structureType: formData.structureType,
			comment: formData.comment,
			description: formData.description,
			fileExtension: formData.fileExtension,
			data: formData.data,
			formatType: formData.formatType,
			objectSettingsId: formData.objectSettingsId,
			version: formData.version
		}),
		[formData]
	)

	useEffect(() => {
		refForm.current?.setValues(initialValues)

		setSelectedElement(
			initialValues.structureType as StereotypeDto.TypeEnum | undefined
		)
	}, [initialValues])

	return (
		<>
			<ModalForm<TemplateDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: 1000,
					minWidth: 1000
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column'
				}}
				resizable
				maximizeButtonVisible
				maximized
				initialValues={initialValues}
				formRef={refForm}
				header={() => (
					<>{template ? t('TEMPLATE_UPDATE') : t('TEMPLATE_NEW')}</>
				)}
				onSubmit={handleSubmit}
			>
				<Flex>
					<SelectFormField
						title={t('TEMPLATE_ELEMENT')}
						name="structureType"
						options={elements.data || []}
						fieldIsLoading={elements.loading}
						valueKey="code"
						labelKey="code"
						isNumeric
						clearable
						allowEmpty
						onChange={value => {
							setSelectedElement(value as StereotypeDto.TypeEnum)
						}}
					/>
					<SelectFormField
						title={t('STEREOTYPE')}
						name="stereotypeId"
						options={stereotypes.data}
						fieldIsLoading={stereotypes.loading}
						valueKey="id"
						labelKey="name"
						isNumeric
						clearable
						allowEmpty
						disabled={!selectedElement || !stereotypes.data?.length}
					/>
					<SelectFormField
						title={t('OBJECT_TYPE')}
						name="objectSettingsId"
						options={objectSettings.data || []}
						fieldIsLoading={objectSettings.loading}
						valueKey="id"
						labelKey="name"
						isNumeric
						clearable
						required={
							selectedElement &&
							ELEMENTS_WITH_OBJECT_TYPE.includes(selectedElement)
						}
						allowEmpty
						disabled={
							!selectedElement ||
							!ELEMENTS_WITH_OBJECT_TYPE.includes(selectedElement)
						}
					/>
					<SelectFormField
						title={t('TECHNOLOGY')}
						name="technologyId"
						options={technologies.data || []}
						fieldIsLoading={technologies.reloading}
						valueKey="id"
						labelKey="name"
						required={true}
						isNumeric
					/>
				</Flex>
				<Flex style={{ alignItems: 'flex-start', marginTop: 10 }}>
					<FlexItem flex={1}>
						<TextFormField
							title={t('FILE_EXTENSION')}
							name="fileExtension"
							validators={[
								validators.maxLength(10),
								validators.customRegex(noSpacesRegexPattern)
							]}
						/>
					</FlexItem>
					<FlexItem flex={1}>
						<SelectFormField
							title={t('OUTPUT_FORMAT')}
							name="formatType"
							options={formatTypes}
							valueKey="code"
							labelKey="name"
							clearable
							allowEmpty
						/>
					</FlexItem>
					<FlexItem flex={1}>
						<TextAreaFormField title={t('TEMPLATE_COMMENT')} name="comment" />
					</FlexItem>
					<FlexItem flex={2}>
						<TextAreaFormField
							title={t('TEMPLATE_DESCRIPTION')}
							name="description"
						/>
					</FlexItem>
				</Flex>
				<FlexItem>
					<AutoSizer>
						{({ width, height }) => (
							<div style={{ height: height! - 15, width }}>
								<AceEditorField
									name="data"
									title={t('TEMPLATE_DATA')}
									initialValue={formData?.data}
									required
									height={height! - 15}
								/>
							</div>
						)}
					</AutoSizer>
				</FlexItem>
			</ModalForm>
		</>
	)
}
