import React from 'react'
import styled from 'styled-components'

import { Tabs, TabProps } from '@/components/Tabs/Tabs'
import {
	ViewTab,
	OpenedViewData,
	ViewDataForm
} from '@/store/modules/view/types'
import { Overview } from '../pages/Overview/Overview'
import { StructureDto } from '@/api/models'
import { Columns } from '../pages/Columns/Columns'
import { Sources } from '../pages/Sources/Sources'
import { useAppContext } from '@/utils/hooks'
import { Deployments } from '../../../components/Deployments/Deployments'
import { Preview } from '../../../components/Preview/Preview'
import { Validation } from '../../../components/Validation/Validation'
import { IdCodeName } from '@/api/schemas/general'
import { UpdateDeepPartial } from '@/store/utils'
import { Permissions } from '../pages/Permissions/Permissions'
import { Dependencies } from '../../../components/Dependencies/Dependencies'

type Props = {
	node: StructureDto
	data: OpenedViewData
	editMode: boolean
	systemNodeId: number
	objectTypes?: IdCodeName[]
	onTabChange: (tab: TabProps) => void
	onChange: (v: UpdateDeepPartial<ViewDataForm>) => void
}

export const ViewTabs = ({
	data,
	node,
	editMode,
	systemNodeId,
	objectTypes,
	onTabChange,
	onChange
}: Props) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={onTabChange}
			selectedTabId={data.tab}
			tabs={[
				{
					id: ViewTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							node={node}
							key={ViewTab.Overview}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
						/>
					)
				},
				{
					id: ViewTab.Columns,
					title: t('TAB_COLUMNS'),
					content: (
						<Columns
							key={ViewTab.Columns}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					)
				},
				{
					id: ViewTab.Sources,
					title: t('TAB_SOURCES'),
					content: (
						<Sources
							key={ViewTab.Sources}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					)
				},
				{
					id: ViewTab.Permissions,
					title: t('PERMISSIONS'),
					content: (
						<Permissions
							key={ViewTab.Permissions}
							node={node}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
						/>
					)
				},
				{
					id: ViewTab.Preview,
					title: t('TAB_PREVIEW'),
					content: (
						<Preview
							key={ViewTab.Preview}
							node={node}
							showObjectTypes
							objectTypes={objectTypes}
						/>
					)
				},
				{
					id: ViewTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={ViewTab.Deployments}
							editMode={editMode}
						/>
					)
				},
				{
					id: ViewTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={ViewTab.Validation} node={node} />
				},
				{
					id: ViewTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={ViewTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					)
				}
			]}
		/>
	)
}

const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
