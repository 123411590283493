import { ApiHookCallback, useApiRequest } from '@/api/hooks'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/utils/hooks'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

type Props<T> = {
	object: T
	onClose: () => void
	deleteRequest: ApiHookCallback<void>
	saveTitle?: string
	description?: string
}

export const DeleteModal = <T extends { name?: string }>({
	object,
	deleteRequest,
	onClose,
	saveTitle,
	description
}: Props<T>) => {
	const { t } = useAppContext()

	const request = useApiRequest()

	const handleSubmit = async () => {
		await request(deleteRequest)

		onClose()
	}

	return (
		<ModalForm
			saveTitle={saveTitle ?? t('DELETE')}
			saveSchema="danger"
			saveIcon={faTrash}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '400px'
			}}
			header={saveTitle ?? t('DELETE')}
		>
			{description ?? t('DELETE_NODE_MESSAGE', [object.name!])}
		</ModalForm>
	)
}
