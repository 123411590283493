import { GraphEditorData } from '@/components/GraphEditor/types'
import { CustomAttributeForm } from '@/pages/User/pages/Home/components/CustomAttributes/hooks/useCustomAttributes'
import { IdCodeName } from './general'
import { MappingPermission } from './mapping'

export interface ApiNodeData {
	name: string
	code: string
	columns: ApiNodeColumn[]
	columnsLastId: number
	objectPermissions: MappingPermission[]
	objectPermissionLastId: number
	description?: string
	comment?: string
	objectSettings?: IdCodeName[]
	graph?: GraphEditorData
	businessDomainId?: number
	status?: ApiNodeData.ApiNodeStatusEnum
	ownerId?: number
	ownerName?: string
	responsiblePersonId?: number
	responsiblePersonName?: string
	responsibleAnalystId?: number
	responsibleAnalystName?: string
	customAttributes?: CustomAttributeForm[]
}

export enum ApiNodeStatusEnum {
	LOGICAL_IN_PROGRESS = 'logical_in_progress',
	LOGICAL_COMPLETED = 'logical_completed',
	PHYSICAL_IN_PROGRESS = 'physical_in_progress',
	PHYSICAL_COMPLETED = 'physical_completed',
	FROZEN = 'frozen'
}

export interface ApiNodeColumn {
	id: number
	uuid?: string
	order: number
	name?: string
	code?: string
	description?: string
	comment?: string
	domainId?: number
	dataType?: string
	defaultValue?: string
	stereotypeId?: string
	stereotypeName?: string
	mandatory?: boolean
	stereotypeColumn?: boolean
	type?: ApiNodeColumnType
	notNull?: boolean
}

export enum ApiNodeColumnType {
	IN = 'in',
	OUT = 'out',
	IN_OUT = 'in_out',
	INTERNAL = 'internal'
}
export namespace ApiNodeData {
	export enum ApiNodeStatusEnum {
		LOGICAL_IN_PROGRESS = 'logical_in_progress',
		LOGICAL_COMPLETED = 'logical_completed',
		PHYSICAL_IN_PROGRESS = 'physical_in_progress',
		PHYSICAL_COMPLETED = 'physical_completed',
		FROZEN = 'frozen'
	}
}
