import { UPDATE_FORM_HIGHLIGHTS } from './constants'
import { State } from './index'

interface UpdateFormHighlights {
	type: typeof UPDATE_FORM_HIGHLIGHTS
	payload: State
}

export const updateFormHighlights = (state: State): UpdateFormHighlights => ({
	type: UPDATE_FORM_HIGHLIGHTS,
	payload: state
})

export type Actions = UpdateFormHighlights
