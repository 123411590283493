import { StereotypeDto } from '@/api/models'

export const ELEMENTS_WITH_OBJECT_TYPE = [
	StereotypeDto.TypeEnum.TABLE,
	StereotypeDto.TypeEnum.MAPPING,
	StereotypeDto.TypeEnum.QUEUE,
	StereotypeDto.TypeEnum.API_NODE,
	StereotypeDto.TypeEnum.VIEW,
	StereotypeDto.TypeEnum.WORKFLOW
]

export const noSpacesRegexPattern = '/^\\S+$/'
