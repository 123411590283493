import React, { useMemo } from 'react'
import { ReleaseDto, ReleaseEnvironmentDto, StructureDto } from '@/api/models'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button, DialogWrapper, MessageModal } from '@/components'
import { Flex, Cell } from '@/components/Layout'
import { deleteReleaseEnvironment } from '@/api'
import { useAppContext } from '@/utils/hooks'
import { ReleaseEnvironmentEdit } from './ReleaseEnvironmentEdit'
import { CheckBox } from '@/components/UberForm'
import { DdlModal } from './DdlModal'
import { DeleteModal } from '../DeleteModal'
import { toLocaleDateString, toLocaleString } from '@/utils/numbers'

type Props = {
	node: StructureDto
	release: ReleaseDto
	env: ReleaseEnvironmentDto
	editMode: boolean
	reloadEnvs: () => void
}

export const ReleaseEnvironment = ({
	node,
	release,
	env,
	editMode,
	reloadEnvs
}: Props) => {
	const { t } = useAppContext()

	const deleteRequest = useMemo(
		() => deleteReleaseEnvironment(node.id, release.id!, env.id!),
		[node.id, release.id]
	)

	return (
		<Flex align="stretch">
			<Cell>{env.name}</Cell>
			<Cell>{env.code}</Cell>
			<Cell>{env.releaseDate ? toLocaleDateString(env.releaseDate) : ''}</Cell>
			<Cell>{env.releasedTime ? toLocaleString(env.releasedTime) : ''}</Cell>
			<ReleasedCell>
				<Checkbox disabled name={`${env.id}-released`} value={env.released} />
			</ReleasedCell>
			<DeployCell>
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<>
								<DdlModal
									preview
									env={env}
									node={node}
									release={release}
									onClose={() => {
										onClose()
									}}
								/>
							</>
						)
					}
				>
					{onClick => (
						<Button size="sm" onClick={onClick}>
							{t('PREVIEW_DDL')}
						</Button>
					)}
				</DialogWrapper>
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<>
								<DdlModal
									env={env}
									node={node}
									release={release}
									onClose={() => {
										reloadEnvs()
										onClose()
									}}
								/>
								{editMode && (
									<MessageModal
										title={t('DEPLOYED_UNPUSHED_CHANGES_WARN_TITLE')}
										type="warn"
										message={t('DEPLOYED_UNPUSHED_CHANGES_WARN')}
									/>
								)}
							</>
						)
					}
				>
					{onClick => (
						<Button size="sm" onClick={onClick}>
							{t('CREATE_DDL')}
						</Button>
					)}
				</DialogWrapper>
			</DeployCell>
			<Actions>
				{editMode && (
					<>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<ReleaseEnvironmentEdit
										env={env}
										node={node}
										release={release}
										onClose={() => {
											reloadEnvs()
											onClose()
										}}
									/>
								)
							}
						>
							{onClick => (
								<Action onClick={onClick}>
									<FontAwesomeIcon icon={faEdit} />
								</Action>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<DeleteModal
										object={env}
										deleteRequest={deleteRequest}
										onClose={() => {
											reloadEnvs()
											onClose()
										}}
										saveTitle={t('REMOVE_ELEMENT_FROM_RELEASE')}
										description={t('REMOVE_ELEMENT_FROM_RELEASE_DESCRIPTION', [
											env.name ?? ''
										])}
									/>
								)
							}
						>
							{onClick => (
								<Action onClick={onClick}>
									<FontAwesomeIcon icon={faTimes} />
								</Action>
							)}
						</DialogWrapper>
					</>
				)}
			</Actions>
		</Flex>
	)
}

const Actions = styled(Flex)`
	width: 40px;
`

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`

const Checkbox = styled(CheckBox)`
	flex: 0;
	margin: 0;
`

const ReleasedCell = styled(Cell)`
	flex: 0.5;
	align-items: center;
`

const DeployCell = styled(Cell)`
	flex-direction: row;
	width: 200px;
	align-items: center;
	justify-content: space-around;
`
