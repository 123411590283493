import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/utils/hooks'

export const EmptySearch = () => {
	const { t } = useAppContext()

	return (
		<Container>
			<FontAwesomeIcon icon={faFilter} size="2x" />
			<div>{t('SEARCH_EMPTY')}</div>
		</Container>
	)
}

const Container = styled.div`
	margin-top: 40px;
	text-align: center;
	color: #999;
`
