import { searchNodesWithType, searchTableColumns } from '@/api'
import { useApiRequest } from '@/api/hooks'
import { AutocompleteResultDto, StructureDto } from '@/api/models'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { DetailTabContextType } from '@/pages/User/pages/Home/components/DetailTab/context/types'
import { useAppStore } from '@/utils/hooks'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { AutocompleteConfig, Search } from '../types'
import { getColumnName, getTableName } from '../utils'

type Autocomplete = {
	tables: AutocompleteResultDto[]
	columns: AutocompleteResultDto[]
}

const TYPES_WITH_MASTER_TABLE: StructureDto.TypeEnum[] = [
	StructureDto.TypeEnum.MAPPING,
	StructureDto.TypeEnum.TABLE
]

export const useAutocomplete = (
	search?: Search,
	autocompleteConfig?: AutocompleteConfig
) => {
	const request = useApiRequest()

	const [autocomplete, setAutocomplete] = useState<Autocomplete>({
		tables: [],
		columns: []
	})

	const { nodes } = useAppStore(state => state.node)

	const detailTabContext = useDetailTabContext() as
		| DetailTabContextType
		| undefined

	const systemNodeId = detailTabContext?.state.systemNodeId

	/** BE needs to get node form data to get table name aliases in mapping */
	const actualStructureId = useMemo(() => {
		if (detailTabContext === undefined) {
			return undefined
		}

		const { node } = detailTabContext.state

		switch (node.type) {
			case StructureDto.TypeEnum.MAPPING: {
				return node.id
			}
		}

		return undefined
	}, [detailTabContext])

	const searchTable = getTableName(search?.table)
	const searchColumn = getColumnName(search?.column)

	const updateAutocomplete = useCallback(async () => {
		if (systemNodeId === undefined || detailTabContext === undefined) {
			return
		}

		const {
			state: { node }
		} = detailTabContext

		let tables = [] as AutocompleteResultDto[]
		let columns = [] as AutocompleteResultDto[]

		const disableColumns = autocompleteConfig?.disableColumns
		const disableColumnsMasterElement = autocompleteConfig?.disableColumns

		// Search master table columns
		if (
			searchTable &&
			searchTable.trim().length &&
			TYPES_WITH_MASTER_TABLE.includes(node.type) &&
			!disableColumns &&
			!disableColumnsMasterElement
		) {
			try {
				let masterTableCode: string | undefined = undefined

				switch (node.type) {
					case StructureDto.TypeEnum.TABLE: {
						masterTableCode = node.code
						break
					}

					case StructureDto.TypeEnum.MAPPING: {
						if (node.parentStructureId) {
							masterTableCode = nodes[node.parentStructureId]?.code
							break
						}

						break
					}
				}

				if (masterTableCode) {
					columns =
						(
							await request(
								searchTableColumns(masterTableCode, systemNodeId, {
									searchText: searchTable,
									actualStructureId
								}),
								{ disableError: true }
							)
						).data ?? []
				}
			} catch (e) {
				console.warn(e)
			}
		}

		if (autocompleteConfig?.disableSystemTablesAndColumns) {
			setAutocomplete({
				tables,
				columns
			})

			return
		}

		if (searchTable && searchTable.trim().length && !disableColumns) {
			try {
				columns = [
					...columns,
					...((
						await request(
							searchTableColumns(searchTable, systemNodeId, {
								searchText: searchColumn,
								actualStructureId
							}),
							{ disableError: true }
						)
					).data ?? [])
				]
			} catch (e) {
				console.warn(e)
			}
		}

		if (searchTable && searchTable.trim().length && searchTable.length > 2) {
			try {
				tables =
					(
						await request(
							searchNodesWithType(searchTable, 'TABLE', systemNodeId, {
								actualStructureId
							}),
							{
								disableError: true
							}
						)
					).data ?? []
			} catch (e) {
				console.warn(e)
			}
		}

		setAutocomplete({
			tables,
			columns
		})
	}, [
		actualStructureId,
		autocompleteConfig,
		detailTabContext,
		nodes,
		request,
		searchColumn,
		searchTable,
		systemNodeId
	])

	useEffect(() => {
		updateAutocomplete()
	}, [updateAutocomplete])

	return autocomplete
}
