import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { checkPasswordStrength, PasswordStrength } from '@/utils/passwords'
import { useAppContext } from '@/utils/hooks'
import { TranslationHelper } from '@/context/Locale/Locale'

const passwordStrengthToLabel = (t: TranslationHelper) => ({
	[PasswordStrength.VERY_WEAK]: t('PASSWORD_VERY_WEAK'),
	[PasswordStrength.WEAK]: t('PASSWORD_WEAK'),
	[PasswordStrength.GOOD]: t('PASSWORD_GOOD'),
	[PasswordStrength.STRONG]: t('PASSWORD_STRONG'),
	[PasswordStrength.VERY_STRONG]: t('PASSWORD_VERY_STRONG')
})

export const PasswordStrengthMeter = ({ password }: { password: string }) => {
	const { t } = useAppContext()
	const result = checkPasswordStrength(password)
	const labels = useMemo(() => passwordStrengthToLabel(t), [t])

	if (!result) {
		return null
	}

	return (
		<Container>
			{labels[result.complexity]}
			<Meter title={`${result.score}%`}>
				<Indicator {...result} />
			</Meter>
		</Container>
	)
}

const Container = styled.div`
	position: absolute;
	right: 0;
	top: -17px;
`

const Meter = styled.span`
	display: inline-block;
	height: 10px;
	width: 50px;
	background-color: #eee;
	margin-left: 5px;
`

const Indicator = styled.span<{
	score: number
	complexity: PasswordStrength
}>`
	display: block;
	height: 100%;
	transition: 0.3s background-color;

	${props =>
		css`
			width: ${props.score}%;
			background: ${props.theme.colors.passwordStrength[props.complexity]};
		`}
`
