import { ApiNodeColumn, ApiNodeData } from '@/api/schemas/api-node'
import { MappingPermissionFlat } from '../mapping/types'

export interface OpenedApiNodeData {
	form: ApiNodeDataForm
	original: ApiNodeDataForm
	tab: ApiNodeTab
	selected: {
		column: number | null
	}
	dirty: boolean
	parsedEditMode: boolean

	graph: ApiNodeGraphData
}

export type ApiNodeGraphData = {
	selected?: number
	offset: { x: number; y: number }
	zoom: number
}

export type ApiNodeDataForm = Omit<
	Partial<ApiNodeData>,
	'objectPermissions' | 'columns'
> & {
	objectPermissions: MappingPermissionFlat[]
	columns: ApiNodeColumn[]
}

export type PropertyItemType = 'column' | 'index' | 'key'

export enum ApiNodeTab {
	General = 'General',
	Columns = 'Columns',
	ReadMappings = 'ReadMappings',
	ApiCollection = 'ApiCollection',
	Preview = 'Preview',
	Deployments = 'Deployments',
	Validation = 'Validation',
	Permissions = 'Permissions',
	Dependencies = 'Dependencies'
}
