import React, { useMemo, useState } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { StructureDto } from '@/api/models'
import { TextFormField } from '@/components/UberForm'
import { ModalForm } from '@/components/Modal/ModalForm'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { apiCallAction } from '@/store/utils'
import { Validator } from '@/components/UberForm/Validators'
import { asyncDebounce } from '@/utils/async'
import { checkUniqueName } from '@/store/modules/misc/actions'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { duplicateNodeRecursive } from '@/api'
import { loadNodeChildren, loadSystemNodes } from '@/store/modules/node/actions'

type Props = {
	node?: StructureDto
	onClose: () => void
}

type FormData = {
	suffix: string
	useSuffixOnly4System?: boolean
}

export const DuplicateNodeModal = ({ node, onClose }: Props) => {
	const { t } = useAppContext()
	const nodes = useAppStore(state => state.node.nodes)
	const dispatch = useAppDispatch()
	const [loading, setLoading] = useState(false)

	const systemNodeId = useMemo(() => node && findSystemNodeId(node, nodes), [
		node,
		nodes
	])

	const handleSubmit = async (form: Partial<FormData>) => {
		setLoading(true)

		await dispatch(
			apiCallAction(() => duplicateNodeRecursive(node?.id as number, form))
		)

		if (node?.type == StructureDto.TypeEnum.SYSTEM) {
			dispatch(loadSystemNodes())
		} else if (node?.parentStructureId) {
			dispatch(loadNodeChildren(node.parentStructureId))
		}

		setLoading(false)
		onClose()
	}

	const suffixValidator = (): Validator =>
		asyncDebounce(async value => {
			if (!value || typeof value !== 'string') {
				return
			}

			const res = await dispatch(
				checkUniqueName(
					node?.name + value,
					node?.type == StructureDto.TypeEnum.SYSTEM ? undefined : systemNodeId
				)
			)

			if (res.errors && (res.errors?.length ?? 0) > 0) {
				return res.errors.join('\n')
			}
		})

	return (
		<ModalForm<FormData>
			saveTitle={t('DUPLICATE')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			isLoading={loading}
			onClose={onClose}
			contentStyle={{
				width: '400px'
			}}
			header={t('DUPLICATE') + ': ' + node?.name}
		>
			<TextFormField
				title={t('SUFFIX')}
				name="suffix"
				required
				autoFocus
				enableAutocomplete={false}
				validators={[suffixValidator()]}
			/>
			{node?.type == StructureDto.TypeEnum.SYSTEM && (
				<CheckboxFormField
					title={t('USE_SUFFIX_ONLY_4_SYSTEM')}
					name="useSuffixOnly4System"
				/>
			)}
		</ModalForm>
	)
}
