import { PureComponent } from 'react'

/**
 * Adds support for async setState.
 */
export default class EnhancedPureComponent<P, S = {}> extends PureComponent<
	P,
	S
> {
	async setState<K extends keyof S>(
		state:
			| ((prevState: Readonly<S>, props: Readonly<P>) => Pick<S, K> | S | null)
			| (Pick<S, K> | S | null),
		callback?: () => void
	): Promise<void> {
		return new Promise(resolve => {
			super.setState(state, async () => {
				if (callback) {
					await callback()
				}

				resolve()
			})
		})
	}
}
