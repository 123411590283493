import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Container } from '../../components/StyledComponents'
import { StructureDto, NewStructureDto } from '@/api/models'
import { Button, Loader, DialogWrapper, ListEditTab } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { AddModal } from '../../../../components/AddModal'
import { useAppContext } from '@/utils/hooks'
import { TabsActions } from '../../../../components/TabActions'
import { useApi } from '@/api/hooks'
import { getMappings } from '@/api'
import { useOpenTab } from '../../../../components/Dependencies/hooks/useOpenTab'
import { MappingProperties } from './Properties'

type Props = {
	node: StructureDto
}

export const Mappings = ({ node }: Props) => {
	const { t } = useAppContext()
	const { openTabById } = useOpenTab()

	const mappings = useApi(getMappings(node.id))

	const onEdit = useCallback(
		(nodeId: number) => {
			openTabById(nodeId)
		},
		[openTabById]
	)

	const data = useMemo(
		() => ({
			mappings: mappings.data ?? ([] as StructureDto[]),
			counter: mappings.data?.length ?? 0
		}),
		[mappings.data]
	)

	return (
		<>
			{node.canEdit && (
				<TabsActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<AddModal
									type={NewStructureDto.TypeEnum.MAPPING}
									onClose={onClose}
									node={node}
								/>
							)
						}
					>
						{onClick => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActions>
			)}
			<Container>
				<LoaderContainer>
					<Loader loaded={!mappings.loading} absolute />
				</LoaderContainer>

				<ListEditTab
					node={node}
					data={data}
					editMode={false}
					itemsKey={'mappings'}
					idCounterKey={'counter'}
					isRowOrderable
					properties={MappingProperties(t, onEdit, removed => removed && mappings.invalidate())}
					tabKey="mapping"
					onChange={() => undefined}
					createEmpty={() => ({} as StructureDto)}
					disablePanelProperties
				/>
			</Container>
		</>
	)
}

const LoaderContainer = styled.div`
	position: relative;
`
