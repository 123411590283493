import React, { useCallback, useMemo } from 'react'
import { TableData } from '@/api/schemas'
import { OpenedTableData, TableDataForm } from '@/store/modules/table/types'
import { ListEditTab } from '@/components'
import { StructureDto, TablePermission } from '@/api/models'
import { PermissionsProperties } from './Properties'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/utils/hooks'
import { createEmptyPermission } from '@/store/modules/table/helpers'
import { useSystemUsers } from '../../../../utils'

type Props = {
	node: StructureDto
	data: OpenedTableData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<TableData>) => void
	systemNodeId: number
}

export const Permissions = ({
	node,
	data,
	editMode,
	onChange,
	systemNodeId
}: Props) => {
	const { t } = useAppContext()
	const systemUsers = useSystemUsers(systemNodeId)

	const handleChange = useCallback(
		(update: UpdateDeepPartial<TableDataForm>) => {
			Object.values(update.objectPermissions ?? {}).forEach(permission => {
				if (permission.userId) {
					const user = systemUsers.data?.find(
						user => user.id === permission.userId
					)

					permission.userCode = user?.code
					permission.userComment = user?.comment
					permission.userDescription = user?.description
					permission.userName = user?.name
				}
			})

			onChange(update)
		},
		[onChange, systemUsers.data]
	)

	const properties = useMemo(
		() =>
			PermissionsProperties(
				t,
				Object.values(TablePermission.PermissionsEnum),
				systemUsers
			),
		[systemUsers, t]
	)

	return (
		<ListEditTab
			idKey="id"
			existsKey="userId"
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptyPermission}
			itemsKey={'objectPermissions'}
			idCounterKey={'objectPermissionLastId'}
			onChange={handleChange}
			isRowOrderable
			properties={properties}
			tabKey="permissions"
		/>
	)
}
