import { StructureDto } from '@/api/models'
import { MappingDataEnums } from '@/api/schemas'
import { MappingDataForm } from '@/store/modules/mapping/types'
import { CallNamingWorkingDataParams } from '@/store/modules/table/utils/callNaming'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'
import { SqlTypeEnum } from '@/typings/enum/SqlTypeEnum'
import { useMemo } from 'react'

export const useNamingMapping = (
	form: MappingDataForm,
	defaultMapping: boolean,
	editMode: boolean,
	node?: StructureDto
) => {
	const namingConfig = useMemo<
		Readonly<CallNamingWorkingDataParams>[] | undefined
	>(() => {
		if (node === undefined) {
			return undefined
		}

		const sharedConfig = [
			node.id,
			SqlTypeEnum.MAPPING,
			{
				body: { data: JSON.stringify(form) }
			}
		] as const

		if (form.defaultMappingType) {
			switch (form.defaultMappingType) {
				case MappingDataEnums.MappingDefaultMappingTypeEnum.HISTORY: {
					return [
						[
							NamingDtoTypeEnum.HISTORICAL_DEFAULT_MAPPING_NAME,
							...sharedConfig
						],
						[NamingDtoTypeEnum.HISTORICAL_DEFAULT_MAPPING_CODE, ...sharedConfig]
					]
				}

				case MappingDataEnums.MappingDefaultMappingTypeEnum.REFERENCE: {
					return [
						[NamingDtoTypeEnum.REFERENCE_DEFAULT_MAPPING_NAME, ...sharedConfig],
						[NamingDtoTypeEnum.REFERENCE_DEFAULT_MAPPING_CODE, ...sharedConfig]
					]
				}

				case MappingDataEnums.MappingDefaultMappingTypeEnum.INTERFACE: {
					return [
						[NamingDtoTypeEnum.INTERFACE_DEFAULT_MAPPING_NAME, ...sharedConfig],
						[NamingDtoTypeEnum.INTERFACE_DEFAULT_MAPPING_CODE, ...sharedConfig]
					]
				}
			}
		}

		if (defaultMapping) {
			return [
				[NamingDtoTypeEnum.DEFAULT_MAPPING_NAME, ...sharedConfig],
				[NamingDtoTypeEnum.DEFAULT_MAPPING_CODE, ...sharedConfig]
			]
		}

		return [[NamingDtoTypeEnum.MAPPING_CODE, ...sharedConfig]]
	}, [form, defaultMapping, node])

	return editMode ? namingConfig : undefined
}
