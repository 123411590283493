import { StructureDto } from '@/api/models'
import { Loader } from '@/components'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { TableMode } from '@/store/modules/table/types'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useBatchEditContext } from './context/BatchEditContext'
import { ActionTypeEnum } from './context/types'
import { useTypeData, PropsTypeGeneric } from './hooks/useTypeData'
import { getGroupedData } from './utils'

export const BatchEditSwitch = () => {
	const dispatch = useAppDispatch()

	const { nodes } = useAppStore(state => state.node)
	const [loadedForms, setLoadedForms] = useState(false)

	const {
		state: { selectedStructures, formChanged, selectedNodes, type },
		dispatch: dispatchContext
	} = useBatchEditContext()

	const { formData, initData, FormComponent } = useTypeData(type)

	const systemNodeId = findSystemNodeId(
		nodes[selectedNodes[0]] as StructureDto,
		nodes
	)

	const loadForms = useCallback(async () => {
		const loadingForms = selectedNodes.map(selectedNode =>
			dispatch(initData({ nodeId: selectedNode, editMode: true }))
		)

		await Promise.allSettled(loadingForms)
		setLoadedForms(true)
	}, [dispatch, selectedNodes, initData])

	useEffect(() => {
		loadForms()
	}, [dispatch, loadForms])

	const onChange = useCallback(
		(values: any) => {
			dispatchContext({
				type: ActionTypeEnum.FORM_CHANGED_UPDATE,
				formChanged: values
			})
		},
		[dispatchContext]
	)

	const data = useMemo<any | null>(() => {
		if (!loadedForms) {
			return null
		}

		const forms = selectedNodes.map(selectedNode => ({
			...(formData[selectedNode]?.form ?? {})
		}))

		const originals = selectedNodes.map(selectedNode => ({
			...(formData[selectedNode]?.original ?? {})
		}))

		const form = getGroupedData(forms)
		const original = getGroupedData(originals)

		return {
			form: {
				...form,
				...formChanged
			},
			original,
			dirty: false,
			tab: 'Overview' as any,
			parsedEditMode: true
		}
	}, [formData, loadedForms, selectedNodes, formChanged])

	const props = useMemo(() => {
		if (!data) {
			return null
		}

		return {
			data,
			systemNodeId,
			onChange,
			readOnly: false,
			selectedNodes: selectedStructures,
			editMode: true,
			/** Table specific props */
			unsyncedFields: [],
			onSync: () => null,
			mode: TableMode.TABLE,
			modeForm: data.form,
			modeFormTable: data.form,
			/** Mapping specific props */
			defaultMapping: type === StructureDto.TypeEnum.MAPPING_DEFAULT
		} as PropsTypeGeneric
	}, [data, systemNodeId, onChange, selectedStructures, type])

	return (
		<>
			{props ? (
				<>
					<FormComponent {...props} />
				</>
			) : (
				<Loader loaded={false} />
			)}
		</>
	)
}
