import React from 'react'
import { StructureDto } from '@/api/models'
import { SystemUsersProperties } from './Properties'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { createEmptySystemUser } from '@/store/modules/system/helpers'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/utils/hooks'
import { ListEditTab } from '@/components'

type Props = {
	node: StructureDto
	data: OpenedSystemData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
}

export const Users = ({ node, data, editMode, onChange }: Props) => {
	const { t } = useAppContext()

	return (
		<ListEditTab
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptySystemUser}
			itemsKey={'systemUsers'}
			idCounterKey={'systemUserLastId'}
			onChange={onChange}
			properties={SystemUsersProperties(t)}
			tabKey="users"
		/>
	)
}
