import { StructureDtoRedux } from '@/store/modules/node'

export interface DetailTabContextState {
	node: StructureDtoRedux
	/** Edit mode is disabled during history view */
	editMode: boolean
	/** Is edit mode selected (history view independent) */
	editModeProp: boolean
	systemNodeId: number
	versionActive: boolean
	selectedTab: any
	version?: number
	versionMax?: number
	envId?: number
}

export interface DetailTabContextType {
	state: DetailTabContextState
	dispatch: React.Dispatch<Actions>
}

export enum DetailTabStepEnum {
	PREVIEW,
	COLUMNS,
	CHECK
}

export enum ActionTypeEnum {
	DETAIL_TAB_UPDATE
}

type ActionDetailTabUpdate = {
	type: ActionTypeEnum.DETAIL_TAB_UPDATE
	update: Partial<DetailTabContextState>
}

export type Actions = ActionDetailTabUpdate
