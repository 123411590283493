import React from 'react'

import FormField, { FormlessFormField, FormFieldProps } from '../FormField'

export interface ReadonlyFormFieldProps extends Partial<FormFieldProps> {
	value?: string
	name?: string
	formless?: boolean
}

export default class ReadonlyFormField extends React.PureComponent<
	ReadonlyFormFieldProps
> {
	render() {
		const { value, formless } = this.props
		const props = { ...this.props }
		props.readonly = true

		delete props.value
		delete props.formless

		const FF = formless ? FormlessFormField : FormField

		return (
			<FF name={props.name || 'readonly'} {...props}>
				{value}
			</FF>
		)
	}
}
