import { StereotypeDto } from '@/api/models'
import { Actions } from './actions'
import { STEREOTYPE_LOAD } from './constants'

type State = Readonly<typeof initialState>

const initialState = {
	table: [] as StereotypeDto[],
	column: [] as StereotypeDto[],
	mapping: [] as StereotypeDto[],
	history: null as StereotypeDto | null
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case STEREOTYPE_LOAD: {
			if (action.metadata.type === StereotypeDto.TypeEnum.COLUMN) {
				return {
					...state,
					column: action.payload
				}
			}

			if (action.metadata.type === StereotypeDto.TypeEnum.TABLE) {
				return {
					...state,
					table: action.payload
				}
			}

			if (action.metadata.type === StereotypeDto.TypeEnum.MAPPING) {
				return {
					...state,
					mapping: action.payload
				}
			}

			if (action.metadata.type === StereotypeDto.TypeEnum.TABLE_HISTORY) {
				return {
					...state,
					history: action.payload[0]
				}
			}

			return state
		}

		default:
			return state
	}
}
