import React from 'react'
import styled from 'styled-components'

import { StructureDto } from '@/api/models'
import { EntityTypeIcon } from './EntityTypeIcon'
import { useDetailTabContext } from './DetailTab/context/DetailTabContext'

interface Props {
	type: StructureDto.TypeEnum
	rightContent?: React.ReactNode
	leftContent?: React.ReactNode
	title?: string
	editMode?: boolean
}

export const Title = ({
	type,
	title,
	rightContent,
	leftContent,
	editMode
}: Props) => {
	const {
		state: { version }
	} = useDetailTabContext()

	return (
		<TitleContainer
			editMode={editMode}
			historyMode={version !== undefined}
			role="heading"
		>
			<TitleText title={title}>
				<EntityTypeIcon type={type} />
				<Text>{title}</Text>
			</TitleText>
			{leftContent}
			{rightContent && (
				<RightContainer role="menubar">{rightContent}</RightContainer>
			)}
		</TitleContainer>
	)
}

export const TitleContainer = styled.div<{
	editMode?: boolean
	historyMode?: boolean
}>`
	display: flex;
	border-bottom: 1px solid ${props => props.theme.colors.layout.borderColor};
	background-color: ${props =>
		props.historyMode
			? props.theme.colors.warn.light
			: props.editMode
			? props.theme.colors.success.light
			: '#fafafa'};
`

export const TitleText = styled.div`
	display: flex;
	max-width: 550px;
	font-size: 150%;
	padding: 10px 15px;
	@media (max-width: 1675px) {
		width: 350px;
	}
	@media (max-width: 1450px) {
		width: 250px;
	}
`

export const Text = styled.span`
	margin-left: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

const RightContainer = styled.div`
	margin-left: 4px;
	button {
		margin: 0;
		background-color: #f8f8f8;
		color: #333;
		border-radius: 0;
		border: none;
		border-left: 1px solid ${props => props.theme.colors.layout.borderColor};
		line-height: 22px;
	}
`
