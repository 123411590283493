import { StereotypeDto } from '@/api/models'
import { hasObjectAllValues } from '@/utils/collections'
import { omit } from 'lodash'
import { useMemo } from 'react'
import { StereoTypeFilterValues } from '../components/StereoTypeFilter'

export const useStereoTypeFilteredData = (
	filter: StereoTypeFilterValues,
	data: StereotypeDto[] = []
) => {
	const filteredData = useMemo(() => {
		const dataValues = data.filter(item => {
			const valuesEqual = hasObjectAllValues(item, omit(filter, 'updatedBy'))

			return valuesEqual
		})

		return dataValues
	}, [data, filter])

	return filteredData
}
