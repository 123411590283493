import { PropertiesErrorProps, ItemProperty, ItemPropertyType } from './types'
import { useAppStore } from '@/utils/hooks'
import { useMemo } from 'react'

export const valueGet = <T>(prop: ItemProperty<T>, item: T) => {
	if (prop.value) {
		return prop.value(item)
	}

	if (prop.field) {
		return (item as any)[prop.field]
	}

	return undefined
}

export const valueOrLabelGet = <T>(prop: ItemProperty<T>, item: T) => {
	if (prop.type === ItemPropertyType.OPTION) {
		let options: any[] = []

		if (typeof prop.options === 'function') {
			options = prop.options(item)
		} else {
			options = prop.options ?? []
		}

		const value = valueGet(prop, item)

		const option = options.find(
			option => option[prop.optionValue ?? ''] === value
		)

		return option?.[prop.optionLabel ?? ''] ?? undefined
	}

	return valueGet(prop, item)
}

export const errorGet = <T>(
	properties: ItemProperty<T>[],
	prop: ItemProperty<T>,
	item: T | undefined,
	errors?: PropertiesErrorProps
) =>
	// TODO: Maybe add id property as mandatory to item type to fix any?
	prop.field && errors?.[(item as any).id]?.[prop.field]?.message

export const usePropertiesPanel = (nodeId?: number, tabKey?: string) => {
	const openedTabs = useAppStore(state => state.tab.openedTabs)

	const tab = useMemo(() => openedTabs.find(t => t.nodeId === nodeId), [
		openedTabs,
		nodeId
	])

	const propertiesPanel = useMemo(
		() => (tabKey ? tab?.propertiesShown[tabKey] : undefined),
		[tab, tabKey]
	)

	return {
		propertiesHidden: !propertiesPanel?.shown,
		propertiesWidth: propertiesPanel?.width
	}
}
