import styled, { css } from 'styled-components'

export const Header = styled.div`
	padding: 15px;
	text-align: left;
	font-size: 125%;
	flex: 0;
`

export const Body = styled.div`
	padding: 15px 20px;
	flex: 1;
`

export const Footer = styled.div<{
	stretchFooterButtons: boolean
	stickyFooter?: boolean
}>`
	text-align: center;
	padding: 10px;
	display: flex;
	justify-content: flex-end;
	flex: 0;

	> button {
		display: inline-block;
		margin: 0 4px;

		${props =>
			props.stretchFooterButtons &&
			css`
				padding: 8px 20px;
			`}
	}

	${props =>
		props.stickyFooter &&
		css`
			position: sticky;
			bottom: 0;
			left: 0;
			width: 100%;
			box-sizing: border-box;
			background: #fff;
		`}
`

export const Dialog = styled.div`
	height: 100%;
	width: 100%;
	max-height: 98vh;
	max-width: 98vw;
	display: flex;
	flex-direction: column;
	overflow: auto;
`

export const PopupBackground = styled.div<{ zIndex?: number }>`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	z-index: ${props => (props.zIndex ? props.zIndex : 999)};
`

export const Resizer = styled.div`
	opacity: 0.6;
	position: absolute;
	bottom: 2px;
	right: 2px;
	width: 16px;
	height: 16px;
	border-bottom: 2px solid #333;
	border-right: 2px solid #333;
	cursor: se-resize;
	transition: 0.2s opacity;
	&:hover {
		opacity: 1;
	}
`

export const Maximizer = styled.div`
	opacity: 0.75;
	position: absolute;
	right: 20px;
	top: 16px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	transition: 0.2s opacity;
	&:hover {
		opacity: 1;
	}
`

export const Popup = styled.div`
	position: relative;
	background: #fff;
	width: 400px;
	max-width: 98%;
	max-height: 98%;
	margin: auto;
	border: 1px solid rgb(187, 187, 187);
	padding: 0px;
	border-radius: 0px;
`
