/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '.'
import { NativeMap } from '@/utils/collections'

interface ReduxAction {
	type: string
	payload: unknown
	metadata?: unknown
}

type ApiCallActionCallback<I extends ReduxAction> = (
	token: string | null
) => Promise<I['payload']>

type Returned<T> = T extends (...args: any[]) => any ? ReturnType<T> : T

export type AppDispatch = <T>(action: T) => Returned<T>

export type AppThunkAction<R> = (
	dispatch: AppDispatch,
	getState: () => StoreState
) => R

export const thunkAction = <R>(cb: AppThunkAction<R>): AppThunkAction<R> => {
	return (dispatch: AppDispatch, getState: () => StoreState) => {
		return cb(dispatch, getState)
	}
}

export const apiCallAction = <I extends ReduxAction>(
	call: (getState: () => StoreState) => ApiCallActionCallback<I>,
	success?: I['type'],
	metadata?: I['metadata']
) => async (
	dispatch: AppDispatch,
	getState: () => StoreState
): Promise<I['payload']> => {
	const data = await call(getState)(getState().auth.token)

	if (success) {
		dispatch({
			type: success,
			payload: data,
			metadata
		})
	}

	return data
}

export const saveState = <T>(
	key: string,
	version: string,
	keys: readonly (keyof T)[],
	state: T
) => {
	localStorage[key] = JSON.stringify(
		(Object.keys(state) as (keyof T)[])
			.filter(k => k !== '__version' && keys.includes(k))
			.reduce(
				(acc, k) => {
					acc[k] = state[k]

					return acc
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{ __version: version } as any
			)
	)

	return state
}

export const loadState = <T>(
	key: string,
	version: string,
	keys: readonly (keyof T)[]
): T => {
	if (!localStorage[key]) {
		return {} as T
	}

	try {
		const saved = JSON.parse(localStorage[key])

		if (!saved || saved.__version !== version) {
			throw new Error(
				`Invalid version (expected ${version}, got ${saved.__version})`
			)
		}

		return (Object.keys(saved) as (keyof T)[])
			.filter(k => k !== '__version' && keys.includes(k))
			.reduce(
				(acc, k) => {
					acc[k] = saved[k]

					return acc
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{} as any
			)
	} catch (e) {
		console.warn('Failed to load previous state from local storage', e)
	}

	return {} as T
}

export const updateTabData = <T>(
	nodes: NativeMap<T>,
	nodeId: number,
	newData: UpdateDeepPartial<T> | ((node: T) => UpdateDeepPartial<T>),
	replace = false
): NativeMap<T> => {
	const original = nodes[nodeId]

	if (original === undefined) {
		console.warn('Trying to update unitialized node', nodeId)

		return nodes
	}

	const extended = deepExtend(
		!replace
			? {
					...original
			  }
			: {},
		typeof newData === 'function' ? newData(original) : newData
	)

	return {
		...nodes,
		[nodeId]: extended
	}
}

/**
 * Deep partial, where Arrays can also be objects with numeric indexes (updating only specific array key)
 */
export type UpdateDeepPartial<T> = {
	[K in keyof T]?: T[K] extends (infer U)[]
		? { [key: number]: UpdateDeepPartial<U> } | U[]
		: T[K] extends object
		? UpdateDeepPartial<T[K]>
		: T[K] extends (infer U)[]
		? { [key: string]: U }
		: T[K]
}

/**
 * Extends specified object with specified object, the extension is deep,
 * which means you can update only part of object in extended object.
 *
 * The extension is inplace.
 *
 * Note about extending arrays:
 *  - when source value is array and target value is also array, it's replaced with target value and that's it
 *  - when source value is array and target is object, it's expected that object only has numeric properties and only
 *      values that correspond to the index (which is numeric prop of target object) are extended with object values
 *
 * @param source object to be extended
 * @param extending extending object
 * @returns the source (same reference that was in the source param)
 */
export const deepExtend = (source: any, extending: any) => {
	Object.entries(extending).forEach(([key, value]: [string | number, any]) => {
		let sourceValue = (source as any)[key]

		if (typeof value === 'object') {
			if (value === null) {
				;(source as any)[key] = null
			} else {
				if (sourceValue === undefined) {
					;(source as any)[key] = sourceValue = Array.isArray(value) ? [] : {}
				} else {
					// eslint-disable-next-line padding-line-between-statements
					;(source as any)[key] = sourceValue = Array.isArray(sourceValue)
						? [...sourceValue]
						: { ...sourceValue }
				}

				if (Array.isArray(value)) {
					;(source as any)[key] = [...value]
				} else {
					deepExtend(sourceValue, value)
				}
			}
		} else {
			;(source as any)[key] = value
		}
	})

	return source
}
