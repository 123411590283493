import { MappingData, MappingPermission } from '@/api/schemas'

export interface OpenedMappingData {
	form: MappingDataForm
	original: MappingDataForm
	stereotypeId: number
	tab: MappingTab
	dirty: boolean
	parsedEditMode: boolean
	ownerId?: number
	responsiblePersonId: string
	responsibleAnalystId: string
}

export type MappingPermissionFlat = Omit<MappingPermission, 'user'> & {
	userId?: number
	userName?: string
	userCode?: string
	userDescription?: string
	userComment?: string
}

export type MappingDataForm = Omit<
	Partial<MappingData>,
	'objectPermissions'
> & {
	objectPermissions: MappingPermissionFlat[]
}

export enum MappingTab {
	Overview = 'Overview',
	Columns = 'Columns',
	Sources = 'Sources',
	Permissions = 'Permissions',
	Others = 'Others',
	Preview = 'Preview',
	Deployments = 'Deployments',
	Validation = 'Validation',
	Dependencies = 'Dependencies'
}
