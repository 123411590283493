import memoizeOne from 'memoize-one'
import {
	ItemPropertyType,
	PossibleForeignKeys
} from '../../../../../../../../components/Properties/types'
import { ItemProperty } from '../../../../../../../../components/Properties/types'
import { TableConstraint, TableColumn } from '@/api/schemas'
import { TranslationHelper } from '@/context/Locale/Locale'
import { StructureDto } from '@/api/models'

const constraintTypes = (t: TranslationHelper) => [
	{
		label: t('CONSTRAINT_TYPE_PRIMARY_KEY'),
		value: TableConstraint.ConstraintTypeEnum.PRIMARY_KEY
	},
	{
		label: t('CONSTRAINT_TYPE_FOREIGN_KEY'),
		value: TableConstraint.ConstraintTypeEnum.FOREIGN_KEY
	},
	{
		label: t('CONSTRAINT_TYPE_CUSTOM'),
		value: TableConstraint.ConstraintTypeEnum.CUSTOM
	},
	{
		label: t('CONSTRAINT_TYPE_UNIQUE'),
		value: TableConstraint.ConstraintTypeEnum.UNIQUE
	},
	{
		label: t('CONSTRAINT_TYPE_CHECK'),
		value: TableConstraint.ConstraintTypeEnum.CHECK
	}
]

export const ConstraintsProperties = memoizeOne(
	(
		t: TranslationHelper,
		node: StructureDto,
		columns: TableColumn[],
		tables: { data: StructureDto[] | undefined; reloading: boolean },
		possibleForeignKeys: PossibleForeignKeys,
		primaryKeys: TableColumn[],
		foreignKeyTableId?: number
	): ItemProperty<TableConstraint>[] => [
		{
			field: 'name',
			label: t('TABLE_CONSTRAINTS_NAME'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'code',
			label: t('TABLE_CONSTRAINTS_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			flex: 1,
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'constraintType',
			label: t('TABLE_CONSTRAINTS_CONSTRAINT_NAME'),
			type: ItemPropertyType.OPTION,
			options: constraintTypes(t),
			flex: 1,
			showWhenPanelOpened: true,
			width: 160
		},
		{
			field: 'foreignKeyTableId',
			label: t('TABLE_CONSTRAINTS_REFERENCE'),
			type: ItemPropertyType.OPTION,
			options: tables.data || [],
			optionValue: 'id',
			optionLabel: 'fullPath',
			isNumeric: true,
			isLoading: tables.reloading,
			condition: item =>
				item.constraintType === TableConstraint.ConstraintTypeEnum.FOREIGN_KEY,
			hideInTable: true
		},
		{
			field: 'columns',
			label: t('TABLE_CONSTRAINTS_COLUMNS'),
			type: ItemPropertyType.FOREIGN_KEY,
			condition: item =>
				item.constraintType === TableConstraint.ConstraintTypeEnum.FOREIGN_KEY,
			hideInTable: true,
			possibleForeignKeys,
			foreignKeyTableId: foreignKeyTableId,
			primaryKeys
		},
		{
			field: 'columns',
			label: t('TABLE_CONSTRAINTS_COLUMNS'),
			type: ItemPropertyType.COLUMNS,
			options: columns,
			condition: item =>
				item.constraintType ===
					TableConstraint.ConstraintTypeEnum.PRIMARY_KEY ||
				item.constraintType === TableConstraint.ConstraintTypeEnum.UNIQUE ||
				item.constraintType === TableConstraint.ConstraintTypeEnum.CHECK ||
				item.constraintType === TableConstraint.ConstraintTypeEnum.CUSTOM,
			hideInTable: true
		},
		{
			field: 'description',
			label: t('TABLE_CONSTRAINTS_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 2,
			width: 100
		},
		{
			field: 'comment',
			label: t('TABLE_CONSTRAINTS_COMMENT'),
			type: ItemPropertyType.TEXTAREA,
			flex: 2,
			width: 100
		},
		{
			field: 'physicalOptions',
			label: t('TABLE_CONSTRAINTS_PHYSICAL_OPTIONS'),
			type: ItemPropertyType.ACE_EDITOR,
			flex: 2,
			condition: item =>
				item.constraintType ===
					TableConstraint.ConstraintTypeEnum.PRIMARY_KEY ||
				item.constraintType ===
					TableConstraint.ConstraintTypeEnum.FOREIGN_KEY ||
				item.constraintType === TableConstraint.ConstraintTypeEnum.UNIQUE,
			hideInTable: true
		},
		{
			field: 'script',
			label: t('TABLE_CONSTRAINTS_SCRIPT'),
			type: ItemPropertyType.ACE_EDITOR,
			condition: item =>
				item.constraintType === TableConstraint.ConstraintTypeEnum.CUSTOM,
			hideInTable: true
		},
		{
			field: 'expression',
			label: t('TABLE_CONSTRAINTS_EXPRESSION'),
			type: ItemPropertyType.ACE_EDITOR,
			condition: item =>
				item.constraintType === TableConstraint.ConstraintTypeEnum.CHECK,
			hideInTable: true
		},
		{
			field: 'enabled',
			label: t('TABLE_CONSTRAINTS_ENABLED'),
			type: ItemPropertyType.CHECKBOX,
			flex: 0.7,
			width: 80
		}
	]
)
