import { executeNamingOverWorkingData } from '@/api'
import { useApiRequest } from '@/api/hooks'
import { StructureDto } from '@/api/models'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { useTabContext } from '@/context/TabContext/TabContext'
import { saveTable, updateTable } from '@/store/modules/table/actions'
import { OpenedTableData, TableMode } from '@/store/modules/table/types'
import { getTableData } from '@/store/modules/table/utils/getTableData'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'
import { SqlTypeEnum } from '@/typings/enum/SqlTypeEnum'
import { useAppDispatch } from '@/utils/hooks'
import { useCallback } from 'react'

export const useUpdateNaming = (
	node: StructureDto,
	table?: OpenedTableData
) => {
	const dispatch = useAppDispatch()
	const request = useApiRequest()
	const { onSaveError } = useTabContext()
	const canGenerateNaming = useCanGenerateNaming(node?.id)

	const updateNaming = useCallback(
		async (modeTarget: TableMode) => {
			if (!canGenerateNaming) {
				return
			}

			if (modeTarget !== TableMode.TABLE && table) {
				try {
					const tableData = await getTableData(
						dispatch,
						node,
						table,
						canGenerateNaming
					)

					const sharedConfig = [
						node.id,
						modeTarget === TableMode.HISTORY
							? SqlTypeEnum.TABLE_HISTORY
							: SqlTypeEnum.TABLE_REFERENCE,
						{
							master_node_name: tableData.name,
							master_node_code: tableData.code,
							body: {
								data:
									modeTarget === TableMode.HISTORY
										? JSON.stringify(tableData.historyTable)
										: JSON.stringify(tableData.referenceTable)
							}
						}
					] as const

					const namings = await Promise.all([
						request(
							executeNamingOverWorkingData(
								modeTarget === TableMode.HISTORY
									? NamingDtoTypeEnum.HISTORICAL_TABLE_NAME
									: NamingDtoTypeEnum.REFERENCE_TABLE_NAME,
								...sharedConfig
							)
						),
						request(
							executeNamingOverWorkingData(
								modeTarget === TableMode.HISTORY
									? NamingDtoTypeEnum.HISTORICAL_TABLE_CODE
									: NamingDtoTypeEnum.REFERENCE_TABLE_CODE,
								...sharedConfig
							)
						)
					])

					dispatch(
						updateTable(
							node,
							{
								name: namings[0]?.data?.result,
								code: namings[1]?.data?.result
							},
							modeTarget
						)
					)

					await dispatch(saveTable(node))
				} catch (e) {
					onSaveError(e)
				}
			}
		},
		[canGenerateNaming, table, dispatch, node, request, onSaveError]
	)

	return updateNaming
}
