import { DiagramData } from '@/api/schemas/diagram'
import { CustomAttributeForm } from '@/pages/User/pages/Home/components/CustomAttributes/hooks/useCustomAttributes'
import { GenerateCodeEnum } from '@/typings/enum/GenerateCodeEnum'

export interface OpenedFolderData {
	dirty: boolean
	tab: OpenedFolderTab
	parsedEditMode: boolean
	form: FolderDataForm
}

export type FolderDataForm = Omit<Partial<FolderData>, 'namingConfig'> & {
	generateCode?: GenerateCodeEnum
	generateTableColumnCode?: GenerateCodeEnum
}

export interface FolderData {
	name: string
	diagram: DiagramData
	ownerId?: number
	ownerName?: string
	stereotypeId?: number
	stereotypeCode?: string
	namingConfig?: {
		generateCode?: GenerateCodeEnum
		generateTableColumnCode?: GenerateCodeEnum
	}
	customAttributes?: CustomAttributeForm[]
}

export enum OpenedFolderTab {
	Overview = 'Overview',
	Diagram = 'Diagram',
	Validation = 'Validation',
	Dependencies = 'Dependencies'
}
