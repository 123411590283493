import React from 'react'
import { FormlessText } from '../Input/Text/Text'
import { ArrayFormFieldType, InputTypeEnum } from './types'

export const ArrayFieldSwitcher = (props: ArrayFormFieldType) => {
	switch (props.inputType) {
		case InputTypeEnum.TEXT: {
			return <FormlessText {...props} isRequired />
		}

		default: {
			console.error('Unknown field type')

			return <></>
		}
	}
}
