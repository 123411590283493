import { reorderNode } from '@/api'
import { useApiRequest } from '@/api/hooks'
import { StructureDto, ReorderStructureDto } from '@/api/models'
import { TreeItemDropDirection } from '@/components/Tree/types'
import {
	loadNodeChildren,
	loadSystemNodes,
	expandNode,
	collapseNode
} from '@/store/modules/node/actions'
import { loadNode } from '@/store/modules/node/general-actions'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import { isNestableNodeType } from '@/utils/nodes'
import { useCallback, useState } from 'react'
import { ExtendedTreeNode, TreePanelDrag } from './utils'

export const TREE_PANEL_DRAG_DROP_NODE_ID = 'TREE_PANEL_DRAG_DROP_NODE_ID'

export const useDragHandler = ({
	onSortingDisabled
}: {
	onSortingDisabled: () => void
}) => {
	const request = useApiRequest()

	const dispatch = useAppDispatch()
	const expanded = useAppStore(state => state.node.expanded)

	const [dragData, setDragData] = useState<TreePanelDrag | null>(null)

	return useCallback(
		(node: ExtendedTreeNode) => {
			const nodeType = node.node.type
			const nodeId = node.node.id
			const parentStructureId = node.node.parentStructureId
			const isSystem = nodeType === StructureDto.TypeEnum.SYSTEM
			const canDropInside = isNestableNodeType(nodeType)

			const moveNode = async (
				dragDataMove: TreePanelDrag,
				dropDirection: TreeItemDropDirection
			) => {
				const shouldMoveInside = dropDirection === TreeItemDropDirection.INSIDE

				const targetParentStructureId = shouldMoveInside
					? nodeId
					: parentStructureId

				const enterBeforeStructureId = shouldMoveInside ? undefined : nodeId

				await request(
					reorderNode(dragDataMove.nodeId, {
						enterBeforeStructureId,
						targetParentStructureId,
						reorderStrategy: ReorderStructureDto.ReorderStrategyEnum.CUSTOM
					})
				)

				if (parentStructureId) {
					// update folder from which the node was moved (remove item)
					if (
						dragDataMove.parentStructureId &&
						dragDataMove.parentStructureId !== targetParentStructureId
					) {
						dispatch(loadNode(dragDataMove.parentStructureId))
						dispatch(loadNodeChildren(dragDataMove.parentStructureId))
					}

					// update node where the node was moved (add item)
					if (isSystem) {
						dispatch(loadSystemNodes())
					} else {
						await Promise.all([
							dispatch(loadNodeChildren(targetParentStructureId as number)),
							dispatch(loadNode(targetParentStructureId as number))
						])
					}

					if (shouldMoveInside) {
						dispatch(expandNode(node.key as number))
					}
				}

				setDragData(null)
			}

			const onDragStart = (e: React.DragEvent) => {
				e.stopPropagation()
				e.dataTransfer.effectAllowed = 'all'
				e.dataTransfer.setData(TREE_PANEL_DRAG_DROP_NODE_ID, nodeId.toString())

				const dragDataStart: TreePanelDrag = {
					nodeId,
					parentStructureId,
					type: nodeType
				}

				setDragData(dragDataStart)
			}

			const onDragOver = (e: React.DragEvent) => {
				e.preventDefault()
				e.stopPropagation()
			}

			let timerOpenNode: number | null = null

			// open folder or system
			const openNode = async () => {
				timerOpenNode = (setTimeout(async () => {
					if (!canDropInside) {
						return
					}

					if (!expanded.find(id => id === nodeId)) {
						dispatch(expandNode(node.key as number))
						dispatch(loadNodeChildren(node.key as number))
					} else {
						dispatch(collapseNode(node.key as number))
					}
				}, 1200) as unknown) as number
			}

			const onDragEnter = (e: React.DragEvent) => {
				e.preventDefault()
				e.stopPropagation()
				openNode()
			}

			const onDragLeave = () => {
				if (timerOpenNode) {
					clearTimeout(timerOpenNode)
				}
			}

			const onDrop = (
				e: React.DragEvent,
				dropDirection: TreeItemDropDirection
			) => {
				e.preventDefault()
				e.stopPropagation()

				if (timerOpenNode) {
					clearTimeout(timerOpenNode)
				}

				onSortingDisabled()

				if (dragData) {
					moveNode(dragData, dropDirection)
				}
			}

			return {
				draggable: true,
				onDrop,
				onDragStart,
				onDragLeave,
				onDragEnter,
				onDragOver,
				canDropInside
			}
		},
		[request, dispatch, expanded, dragData, onSortingDisabled]
	)
}
