import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useAppContext } from '@/utils/hooks'
import { SelectFormField } from '@/components/UberForm'
import { useApi } from '@/api/hooks'
import { getTemplateHistory, getTemplateHistoryDetail } from '@/api'
import { Flex } from '@/components/Layout'
import { useSaveFile } from '@/utils/hooks/useSaveFile'
import styled from 'styled-components'
import { Modal } from '@/components/Modal/Modal'
import { diff, formatters } from 'jsondiffpatch'
import { Button } from '@/components'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { TemplateDiffProps } from './types'

export const TemplateDiff = ({ template, onClose }: TemplateDiffProps) => {
	const { t } = useAppContext()
	const saveFile = useSaveFile()
	const refDiv = useRef<HTMLDivElement>(null)
	const [version, setVersion] = useState(template?.version!)

	const versionDiffInit = useMemo(
		() =>
			template?.version! <= 2 ? template?.version! : template?.version! - 1,
		[template]
	)

	const [versionDiff, setVersionDiff] = useState(versionDiffInit)

	const templateHistory = useApi(getTemplateHistory(template?.id!))

	const templateVersion = useApi(
		getTemplateHistoryDetail(template?.id!, version)
	)

	const templateVersionDiff = useApi(
		getTemplateHistoryDetail(template?.id!, versionDiff)
	)

	const onDownload = async () => {
		if (templateVersionDiff.data) {
			saveFile(
				`template_${templateVersionDiff.data?.id}_${templateVersionDiff.data.version}.sql`,
				[templateVersionDiff.data.data ?? '']
			)
		}
	}

	const versions = useMemo(() => templateHistory.data ?? [], [templateHistory])

	useEffect(() => {
		const left = templateVersion.data
		const right = templateVersionDiff.data
		const delta = diff(left, right)

		// beautiful html diff
		if (refDiv.current) {
			refDiv.current.innerHTML = formatters.html.format(delta ?? {}, left)
		}
	}, [templateVersion.data, templateVersionDiff.data])

	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				contentStyle={{
					width: 1000,
					minWidth: 1000
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column'
				}}
				resizable
				maximizeButtonVisible
				maximized
				headerStyle={{ paddingBottom: 0 }}
				header={() => (
					<>
						<Flex>
							{t('TEMPLATE_VERSIONS')}

							<StyledSelectVersion
								title={t('VERSION')}
								name="version"
								options={versions}
								fieldIsLoading={templateHistory.reloading}
								valueKey="version"
								labelKey="version"
								required={true}
								isNumeric
								clearable={false}
								onChange={value => setVersion(value as number)}
								initialValue={template?.version}
								formless
							/>
							<StyledSelectVersion
								title={t('VERSION_TO_COMPARE')}
								name="versionDiff"
								options={versions}
								fieldIsLoading={templateHistory.reloading}
								valueKey="version"
								labelKey="version"
								required={true}
								isNumeric
								clearable={false}
								onChange={value => setVersionDiff(value as number)}
								initialValue={versionDiffInit}
								formless
							/>
						</Flex>
					</>
				)}
			>
				<div ref={refDiv} />
				<div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
					<Button onClick={onDownload}>
						{t('DOWNLOAD_VERSION_TO_COMPARE')}
					</Button>
					<Button onClick={onClose} schema="transparent" icon={faTimes}>
						{t('CLOSE')}
					</Button>
				</div>
			</Modal>
		</>
	)
}

const StyledSelectVersion = styled(SelectFormField)`
	margin-left: 10px;
	flex: 0 1;
	font-size: 14px;
	min-width: 130px;
`
