import React, { useCallback, useMemo } from 'react'
import { has } from 'lodash'
import { TableData, TableColumn } from '@/api/schemas'
import {
	OpenedTableData,
	TableDataForm,
	TableMode
} from '@/store/modules/table/types'
import {
	ListEditTab,
	TableProperties,
	DialogWrapper,
	Button
} from '@/components'
import { StructureDto, DomainDto, StereotypeDto } from '@/api/models'
import { useColumnProperties } from './Properties'
import { createEmptyColumn } from '@/store/modules/table/helpers'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import styled, { css } from 'styled-components'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { TabsActions } from '../../../../components/TabActions'
import { useApi } from '@/api/hooks'
import { getStereotypes } from '@/api'
import { TableColumnFlat } from '@/store/modules/table/types'
import { duplication } from './validation'
import { useDomainTechnology } from '@/utils/domain'
import { updateSequenceCode } from '@/store/modules/table/actions'
import { ImportColumnsCsv } from '../../../../components/ImportColumnsCsv/ImportColumnsCsv'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { NamingTypeEnum } from '@/components/UberForm/Input/Naming/types'

type Props = {
	node: StructureDto
	data: OpenedTableData
	systemNodeId: number
	editMode: boolean
	onChange: (v: UpdateDeepPartial<TableData>) => void
	technicalColumns: TableColumn[]
	domains: DomainDto[]
	reloadTableData: () => void
}

const TAB_KEY = 'column'

const ColumnsComponent = ({
	node,
	data,
	systemNodeId,
	editMode,
	onChange,
	technicalColumns,
	domains,
	reloadTableData
}: Props) => {
	const dispatch = useAppDispatch()
	const { t } = useAppContext()
	const { getDomainData } = useDomainTechnology(systemNodeId)

	const columnStereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.COLUMN })
	)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<TableDataForm>) => {
			// sync sequenceColumnCode when removing column
			if (data.form.sequenceColumnCode && Array.isArray(v.columns)) {
				const isSequenceColumnRemoved = Object.values(v.columns).some(
					c => !(c.code === data.form.sequenceColumnCode)
				)

				if (isSequenceColumnRemoved) {
					v.sequenceColumnCode = undefined
					v.sequenceCode = undefined
				}
			}

			if (v.columns) {
				Object.values(v.columns).forEach(c => {
					// sync sequenceColumnCode when renaming column
					if (data.form.sequenceColumnCode) {
						const previousCode = data.form.columns.find(col => col.id === c.id)
							?.code

						if (data.form.sequenceColumnCode === previousCode && c.code) {
							dispatch(
								updateSequenceCode(
									node,
									{ sequenceColumnCode: c.code },
									TableMode.TABLE,
									[
										NamingDtoTypeEnum.SEQUENCE_NAME,
										node.id!,
										{
											sequence_column: c.code
										}
									]
								)
							)
						}
					}

					if (has(c, 'domainId')) {
						const d = domains.find(d => d.id === c.domainId)
						const domainData = getDomainData(d)
						const colData = data.form.columns.find(col => col.id === c.id)

						// don't change domain data, when domain is same
						if (colData && colData.domainId === c.domainId) {
							return
						}

						if (d) {
							c.domainId = d.id
							c.domainName = d.name
							c.domainCode = d.code
							c.notNullFlag = d.notNullFlag

							if (!d.custom) {
								c.dataType = domainData?.dataType
								c.defaultValue = domainData?.defaultValue
							}
						} else {
							c.domainId = undefined
							c.domainName = undefined
							c.domainCode = undefined
							c.notNullFlag = undefined
							c.dataType = undefined
							c.defaultValue = undefined
						}
					}

					if (c.stereotypeId) {
						const colStereotype = columnStereotypes.data?.find(
							col => col.id === c.stereotypeId
						)

						c.stereotypeName = colStereotype?.name
						c.stereotypeCode = colStereotype?.code
					}
				})
			}

			onChange(v)
		},
		[
			data.form.sequenceColumnCode,
			data.form.columns,
			onChange,
			dispatch,
			node,
			domains,
			getDomainData,
			columnStereotypes.data
		]
	)

	const errors = useMemo(
		() => duplication(data.form.columns, technicalColumns, t),
		[data.form.columns, t, technicalColumns]
	)

	const canGenerateNaming = useCanGenerateNaming(
		node.id,
		NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE
	)

	const properties = useColumnProperties(
		t,
		domains,
		data.form.constraints,
		columnStereotypes,
		canGenerateNaming
	)

	return (
		<>
			{editMode && (
				<TabsActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<ImportColumnsCsv
									node={node}
									onClose={() => {
										onClose()
										reloadTableData()
									}}
								/>
							)
						}
					>
						{onClick => (
							<Button
								icon={faDownload}
								onClick={onClick}
								coloredIcon={true}
								schema="primary"
							>
								{t('IMPORT_COLUMNS')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActions>
			)}

			<ListEditTab
				node={node}
				data={data.form}
				editMode={editMode}
				isRowOrderable
				createEmpty={createEmptyColumn}
				itemsKey={'columns'}
				idCounterKey={'columnsLastId'}
				onChange={handleChange}
				properties={properties}
				tabKey={TAB_KEY}
				errors={errors}
			>
				{(propertiesHidden, columnWidths) =>
					technicalColumns.length > 0 && (
						<TechnicalColumnsContainer editMode={editMode}>
							<StereotypeTitle>{t('TECHNICAL_COLUMNS_TITLE')}</StereotypeTitle>
							<TableProperties<TableColumnFlat>
								items={technicalColumns}
								properties={properties}
								propertiesHidden={propertiesHidden}
								readonly
								columnWidthsParent={columnWidths}
							/>
						</TechnicalColumnsContainer>
					)
				}
			</ListEditTab>
		</>
	)
}

const TechnicalColumnsContainer = styled.div<{ editMode?: boolean }>`
	${props => css`
		margin-left: ${props.editMode ? '25px' : '0'};
	`}
`

const StereotypeTitle = styled.div`
	margin: 20px 0 5px 0;
	font-size: 115%;
`

export const Columns = React.memo(ColumnsComponent)
