import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { User } from '../User/User'
import { ErrorContainer } from './components/ErrorContainer'
import { GlobalStyle } from '@/styles/global'
import { ApiErrorMessage } from './components/ApiErrorMessage'
import { setToken, setUser } from '@/store/modules/auth/actions'
import { useAppDispatch } from '@/utils/hooks'
import { getSessionContext } from '@/api'
import { useApiRequest } from '@/api/hooks'
import {ConfirmationServiceProvider} from "@/pages/App/components/ConfirmationService";

type Props = {
	token?: string
}

export const App = ({ token }: Props) => {
	const dispatch = useAppDispatch()
	const [loaded, setLoaded] = useState(false)
	const request = useApiRequest()

	useEffect(() => {
		const updateToken = async () => {
			if (token) {
				dispatch(setToken(token))

				if (!loaded) {
					await request(getSessionContext()).then(value => {
						value.data?.user && dispatch(setUser(value.data.user))
						setLoaded(true)
					})
				}
			}
		}

		updateToken()
	}, [dispatch, loaded, request, token])

	return (
		<AppContainer>
			<GlobalStyle />
			<ErrorContainer>
				<ConfirmationServiceProvider>
					<ApiErrorMessage />
					{loaded && <User />}
				</ConfirmationServiceProvider>
			</ErrorContainer>
		</AppContainer>
	)
}

const AppContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	overflow: auto;
	flex-direction: column;
	background-color: ${props => props.theme.colors.layout.appBackgroundColor};
`
