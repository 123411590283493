import { Switch, Route } from 'react-router'
import React, { useEffect, useState } from 'react'

import { Home } from './pages/Home/Home'
import Navbar from './components/Navbar/Navbar'
import { Settings } from './pages/Settings/Settings'
import { useAppStore, useAppDispatch } from '@/utils/hooks'
import { loadNodeState } from '@/store/modules/node/actions'
import { loadStereotypes } from '@/store/modules/stereotype/actions'
import { StereotypeDto } from '@/api/models'
import {
	loadUserSession,
	updateUserSession
} from '@/store/modules/user/actions'
import { loadTabState } from '@/store/modules/tab/actions'
import { Loader } from '@/components'
import { updateSettings } from '@/store/modules/settings/actions'

export const User = () => {
	const dispatch = useAppDispatch()
	const userSession = useAppStore(state => state.user.session)
	const [loaded, setLoaded] = useState(false)
	const [userLoaded, setUserLoaded] = useState(true)

	// Load global values
	useEffect(() => {
		const load = async () => {
			await Promise.all([
				dispatch(loadStereotypes(StereotypeDto.TypeEnum.TABLE_HISTORY)),
				dispatch(loadUserSession())
			])

			setLoaded(true)
		}

		load()
	}, [])

	// Process user session
	useEffect(() => {
		const load = async () => {
			setUserLoaded(false)

			if (userSession) {
				await dispatch(loadNodeState(userSession.node))
				await dispatch(loadTabState(userSession.tab))
				dispatch(updateSettings(userSession.settings))
			}

			setUserLoaded(true)
		}

		load()
	}, [userSession])

	// Periodically save user session
	// TODO: Is this the best way to do this?
	useEffect(() => {
		if (loaded) {
			let lastSaved = JSON.stringify(userSession)

			const interval = setInterval(async () => {
				lastSaved = ((await dispatch(updateUserSession(lastSaved))) || {})
					.context as string
			}, 1000)

			return () => clearInterval(interval)
		}
	}, [dispatch, updateUserSession, loaded])

	return (
		<>
			<Loader loaded={loaded && userLoaded} absolute={true} />
			<Navbar />
			<Switch>
				<Route path="/settings" component={Settings} />
				<Route path="/" component={Home} />
			</Switch>
		</>
	)
}
