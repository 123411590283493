import React from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/utils/hooks'

import { useApiRequest, ApiHookCallback } from '@/api/hooks'

type Props = {
	removeId: number | undefined
	params?: any[]
	textOnModal: string
	header: string
	callback?: (id: number, ...params: any) => ApiHookCallback<void>
	styles?: React.CSSProperties
	onRemove: () => void
	onClose: () => void
}

export const RemoveModal = ({
	removeId,
	params,
	textOnModal,
	callback,
	header,
	styles = { width: '400px' },
	onRemove,
	onClose
}: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const handleSubmit = async () => {
		removeId &&
			callback &&
			(await request(callback(removeId, ...(params ?? []))))

		onRemove()
		onClose()
	}

	return (
		<ModalForm<FormData>
			open={true}
			onClose={onClose}
			saveTitle={t('REMOVE')}
			saveSchema="danger"
			contentStyle={styles}
			header={header}
			onSubmit={handleSubmit}
		>
			{textOnModal}
		</ModalForm>
	)
}
