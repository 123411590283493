import { ObjectSettingsDto } from '@/api/models'
import { hasObjectAllValues } from '@/utils/collections'
import { useMemo } from 'react'
import { ObjectSettingsFilterValues } from '../components/ObjectSettingsFilter'

export const useObjectSettingsFilteredData = (
	filter: ObjectSettingsFilterValues,
	data: ObjectSettingsDto[] = []
) => {
	const filteredData = useMemo(() => {
		const dataValues = data.filter(item => {
			const valuesEqual = hasObjectAllValues(item, filter)

			return valuesEqual
		})

		return dataValues
	}, [data, filter])

	return filteredData
}
