import { StructureDto } from '@/api/models'
import { StoreState } from '@/store'

//TODO: MDD-1516 fix the type
export const TYPE_TO_SELECTOR: Record<
	// StructureDto.TypeEnum,
	any,
	(state: StoreState) => Record<string, unknown>
> = {
	[StructureDto.TypeEnum.API_COLLECTION]: state => state.apiCollection.items,
	[StructureDto.TypeEnum.API_NODE]: state => state.apiNode.nodes,
	[StructureDto.TypeEnum.FOLDER]: state => state.folder.folders,
	[StructureDto.TypeEnum.FOLDER_WORKFLOW]: () => ({}),
	[StructureDto.TypeEnum.MAPPING]: state => state.mapping.mappings,
	[StructureDto.TypeEnum.MAPPING_DEFAULT]: state => state.mapping.mappings,
	[StructureDto.TypeEnum.MAPPING_READ]: state => state.readMapping.mappings,
	[StructureDto.TypeEnum.QUEUE]: state => state.queue.queues,
	[StructureDto.TypeEnum.ROOT]: () => ({}),
	[StructureDto.TypeEnum.SYSTEM]: state => state.system.systems,
	[StructureDto.TypeEnum.TABLE]: state => state.table.tables,
	[StructureDto.TypeEnum.VIEW]: state => state.view.views,
	[StructureDto.TypeEnum.WORKFLOW]: state => state.workflow.workflows,
	[StructureDto.TypeEnum.MAPPING_WRITE]: state => state.writeMapping.mappings
}
