import { getObjectSettingsByType } from '@/api'
import { ObjectSettingsUpdateDto } from '@/api/models'
import { IdCodeName } from '@/api/schemas'
import { FieldSet, Legend, Row } from '@/components/Layout'
import { FormValue } from '@/components/UberForm/Form'
import { SelectWithoutForm } from '@/components/UberForm/Input/Select/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@/components'
import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/utils/hooks'
import styled from 'styled-components'
import { useApi } from '@/api/hooks'
import { useIsFieldHighlighted } from '@/components/UberForm'

const ObjectSettingsControl = ({
	name,
	label,
	type,
	data,
	disabled,
	onChange
}: {
	type: ObjectSettingsUpdateDto.StructureTypeEnum
	label: string
	name: string
	data?: IdCodeName[]
	disabled?: boolean
	onChange?: (newValue: IdCodeName[]) => void
}) => {
	const objectSettings = useApi(getObjectSettingsByType(type))
	const { t } = useAppContext()

	const handleAdd = (value: FormValue) => {
		const os = objectSettings?.data?.find(o => o.code == value) || undefined

		if (os) {
			onChange?.([...(data || []), { id: os.id, code: os.code, name: os.name }])
		}
	}

	const handleRemove = (osId: number) => {
		onChange?.([...(data || []).filter(os => os.id != osId)])
	}

	const isHighlighted = useIsFieldHighlighted(name, data)

	return (
		<>
			<FieldSetProject>
				<Legend isHighlighted={isHighlighted}>{label}</Legend>
				{data && (
					<ItemsContainer>
						{data.map(os => (
							<ItemContainer key={os.code}>
								<RemoveButtonContainer>
									{!disabled && (
										<RemoveButton
											size="sm"
											schema="transparent"
											onClick={() => handleRemove(os.id as number)}
										>
											<FontAwesomeIcon
												title={t('REMOVE')}
												color="#d00"
												icon={faTimes}
											/>
										</RemoveButton>
									)}
								</RemoveButtonContainer>

								<Name>{os.name}</Name>
								<Code>{os.code}</Code>
							</ItemContainer>
						))}
					</ItemsContainer>
				)}
				{!disabled && (
					<SelectWithoutForm
						options={(objectSettings.data || []).filter(
							os => !(data || []).some(o => o.code == os.code)
						)}
						placeholder={t('ADD_OBJECT_SETTINGS')}
						labelKey="name"
						valueKey="code"
						loading={objectSettings.loading}
						onChange={handleAdd}
						compact
						allowEmpty
						value={data?.map(d => d.code ?? '')}
					/>
				)}
			</FieldSetProject>
		</>
	)
}

const FieldSetProject = styled(FieldSet)`
	margin-top: 15px;
`

const RemoveButton = styled(Button)`
	padding: 0;
	opacity: 0;
	display: inline-block;
`

const RemoveButtonContainer = styled.span`
	width: 12px;
	display: inline-block;
`

const ItemsContainer = styled(Row)`
	padding-bottom: 0.25em;
	margin: 0;
	flex-direction: column;
`

const ItemContainer = styled(Row)`
	padding: 0.33em 0.5em;
	align-items: baseline;
	&:hover ${RemoveButton} {
		opacity: 1;
	}
`

const Code = styled.span`
	padding: 0 0.5em;
	font-size: 85%;
`

const Name = styled.span`
	padding-left: 0.25em;
`

export default ObjectSettingsControl
