import React from 'react'
import { TableData, TableColumn } from '@/api/schemas'
import { OpenedTableData } from '@/store/modules/table/types'
import { ListEditTab } from '@/components'
import { StructureDto } from '@/api/models'
import { IndexProperties } from './Properties'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/utils/hooks'
import { createEmptyIndex } from '@/store/modules/table/helpers'

type Props = {
	node: StructureDto
	data: OpenedTableData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<TableData>) => void
	technicalColumns: TableColumn[]
}

const IndexesComponent = ({
	node,
	data,
	editMode,
	onChange,
	technicalColumns
}: Props) => {
	const { t } = useAppContext()

	return (
		<ListEditTab
			idKey="id"
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptyIndex}
			itemsKey={'indexes'}
			idCounterKey={'indexesLastId'}
			onChange={onChange}
			isRowOrderable
			properties={IndexProperties(t, [
				...data.form.columns.filter(c => c.name),
				...technicalColumns
			])}
			tabKey="index"
		/>
	)
}

export const Indexes = React.memo(IndexesComponent)
