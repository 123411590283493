import React from 'react'
import { useTablePropertiesContext } from '../context/TablePropertiesContext'
import { TablePropertiesHeader } from './TablePropertiesHeader'
import { TablePropertiesRow } from './TablePropertiesRow'

type Props = {
	index: number
	style: React.CSSProperties
}

export const TablePropertiesRowOrHeader = ({ style, index }: Props) => {
	const {
		state: { firstRenderedItemIndex }
	} = useTablePropertiesContext()

	const indexWithoutHeader = index - 1

	if (firstRenderedItemIndex === index || indexWithoutHeader === -1) {
		return <TablePropertiesHeader key={indexWithoutHeader} />
	}

	return <TablePropertiesRow style={style} index={indexWithoutHeader} />
}
