import styled, { css } from 'styled-components'
import Tooltip from '@/components/Tooltip/Tooltip'
import { SelectFormField } from '@/components/UberForm'

export interface FormProps {
	error?: string
	focused?: boolean
	fromProps?: boolean
	variant?: 'textarea'
}

export const cssError = css<FormProps>`
	${props =>
		!!props.error &&
		css`
			background: ${props.theme.colors.input.error.background};
			border-color: ${props.theme.colors.input.error.border};
		`}
`

export const cssFocused = css<FormProps>`
	${props =>
		props.focused &&
		css`
			:focus {
				border: 1px solid ${props.theme.colors.primary.base} !important;
				box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
			}
		`}
`

export const FormInput = styled.input<FormProps>`
	${props =>
		props.fromProps &&
		css`
			:hover {
				box-shadow: 0 0 3px ${props => props.theme.colors.primary.shadowColor};
			}
			${cssFocused};
		`};
	${cssError};
`

export const TextAreaInput = styled.textarea<FormProps>`
	background: #fff;
	z-index: 11;
	width: 250px;
	${cssFocused};
	${cssError};
`

export const ErrorTooltip = styled(Tooltip).attrs({
	styleTrigger: {
		width: '100%'
	}
})`
	${props => css`
		background: ${props.theme.colors.form.error.background};
		color: ${props.theme.colors.form.error.color};

		padding: 5px 10px;

		&::before {
			content: ' ';
			position: absolute;
			top: -15px;
			left: 50%;
			margin-left: -5px;
			height: 5px;
			border: 5px solid transparent;
			border-bottom-color: ${props.theme.colors.form.error.background};
		}
	`}
`

export const Select = styled(SelectFormField)<
	FormProps & { fromProps: boolean }
>`
	width: 100%;
	border: 0;
	margin-bottom: 1px;
	margin-right: 0px;
	.react-select__control {
		${props =>
			props.fromProps
				? css`
						border-color: #ddd;
						border-radius: ${props => props.theme.input.borderRadius};
				  `
				: css`
						border: 0;
				  `}
		${cssError}
	}
`

export const ValueContainer = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	padding: 8px 6px;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	${props =>
		props.disabled &&
		css`
			border: 1px solid ${props => props.theme.colors.input.disabled.border};
			background: ${props => props.theme.colors.input.disabled.background};
		`}
`
export const ValueOverflow = styled.div`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`
