import React from 'react'
import styled from 'styled-components'

import { TreePanel } from './components/TreePanel/TreePanel'
import { ViewPanel } from './components/TabsView/TabsView'
import SplitPane from 'react-split-pane'
import { useDebounceCallback } from '@/utils/hooks'

const HomeComponent = () => {
	const saveWidth = useDebounceCallback(
		size => localStorage.setItem('NavigationWidth', size.toString()),
		1000
	)

	return (
		<Panels>
			<SplitPane
				split="vertical"
				minSize={120}
				maxSize={500}
				defaultSize={parseInt(
					localStorage.getItem('NavigationWidth') || '200',
					10
				)}
				onChange={saveWidth}
			>
				<TreeContainer>
					<TreePanel />
				</TreeContainer>
				<ViewContainer>
					<ViewPanel />
				</ViewContainer>
			</SplitPane>
		</Panels>
	)
}

const Panels = styled.div`
	display: flex;
	flex: 1;
	position: relative;
	overflow: auto;

	.Resizer {
		background-color: transparent;
		z-index: 1;
		box-sizing: border-box;
		background-clip: padding-box;
	}

	.Resizer:hover {
		transition: all 2s ease;
	}

	.Resizer.vertical {
		width: 7px;
		border-left: 1px solid ${props => props.theme.colors.layout.borderColor};
		border-right: 1px solid ${props => props.theme.colors.layout.borderColor};
		cursor: col-resize;
	}

	.Resizer.vertical:hover {
		background-color: #eee;
	}
	.Resizer.disabled {
		cursor: not-allowed;
	}
	.Resizer.disabled:hover {
		border-color: transparent;
	}

	.Pane2 {
		min-width: 0px;
	}
`

const TreeContainer = styled.div`
	overflow: auto;
	height: 100%;
	display: flex;
`

const ViewContainer = styled.div`
	flex-direction: column;
	overflow: auto;
	height: 100%;
	display: flex;
`

export const Home = HomeComponent
