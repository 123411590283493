/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from 'redux'

import auth from './auth'
import node from './node'
import table from './table'
import view from './view'
import mapping from './mapping'
import readMapping from './readMapping'
import workflow from './workflow'
import stereotype from './stereotype'
import technology from './technology'
import system from './system'
import history from './history'
import businessDomain from './businessDomain'
import user from './user'
import misc from './misc'
import tab from './tab'
import acronyms from './acronyms'
import { AUTH_LOGOUT } from './auth/constants'
import folder from './folder'
import apiError from './apiError'
import apiNode from './apiNode'
import apiCollection from './apiCollection'
import queue from './queue'
import actionsTimestamps from './actionsTimestamps'
import settings from './settings'
import formHighlights from './formHighlights'
import writeMapping from './writeMapping'

const reducers = combineReducers({
	auth,
	node,
	table,
	view,
	stereotype,
	technology,
	mapping,
	readMapping,
	workflow,
	system,
	history,
	businessDomain,
	user,
	misc,
	tab,
	acronyms,
	folder,
	apiError,
	apiNode,
	apiCollection,
	queue,
	actionsTimestamps,
	settings,
	formHighlights,
	writeMapping
})

export default (state: any, action: any) => {
	if (action.type === AUTH_LOGOUT) {
		state = undefined
	}

	return reducers(state, action)
}
