import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React, { memo, useMemo } from 'react'
import Tooltip from '@/components/Tooltip/Tooltip'
import styled, { css } from 'styled-components'

export type Schema =
	| 'default'
	| 'primary'
	| 'secondary'
	| 'success'
	| 'info'
	| 'danger'
	| 'warn'
	| 'transparent'
	| 'primaryOutlined'

export type Size = 'xs' | 'sm' | 'md' | 'lg'

interface Props {
	disabled?: boolean
	isLoading?: boolean
	icon?: IconProp
	schema?: Schema
	size?: Size
	type?: 'button' | 'submit' | 'reset'
	name?: string
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
	onMouseOver?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	tooltip?: string
	ariaLabel?: string
	className?: string
	coloredIcon?: boolean
	children?: React.ReactNode
	title?: string
	style?: React.CSSProperties
}

const Button = ({
	disabled = false,
	name,
	schema,
	type = 'button',
	size = 'md',
	children,
	icon,
	onClick,
	isLoading = false,
	onMouseOver,
	onMouseDown,
	tooltip,
	ariaLabel,
	className,
	coloredIcon,
	title,
	style
}: Props) => {
	const hasContent = !!children

	let iconToShow = icon

	if (isLoading) {
		iconToShow = faSpinner
	}

	let contents = useMemo(
		() => (
			<>
				{iconToShow && (
					<Icon
						disabled={disabled || false}
						hasContent={hasContent}
						coloredIcon={coloredIcon}
						schema={schema || 'primary'}
					>
						<FontAwesomeIcon icon={iconToShow} spin={isLoading} />
					</Icon>
				)}
				{children}
			</>
		),
		[children, iconToShow, disabled, hasContent, coloredIcon, schema, isLoading]
	)

	if (tooltip) {
		contents = <Tooltip content={tooltip}>{contents}</Tooltip>
	}

	return (
		<Container
			className={className}
			disabled={disabled || false}
			name={name}
			onClick={!disabled ? onClick : undefined}
			onMouseOver={onMouseOver}
			onMouseDown={onMouseDown}
			type={type}
			hasContent={hasContent}
			schema={schema || 'primary'}
			size={size || 'md'}
			aria-label={ariaLabel}
			title={title}
			style={style}
		>
			{contents}
		</Container>
	)
}

const Container = styled.button<{
	disabled: boolean
	hasContent: boolean
	schema: Schema
	size: Size
}>`
	transition: 0.2s;
	border-radius: 0;
	text-transform: uppercase;
	border: 0;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;

	${props => css`
		padding: ${props.size === 'sm' ? '3px 5px' : '10px 15px'};
		font-weight: ${props.theme.font.weight.medium};
		border-width: 1px;
		border-style: solid;
	`}

	${props =>
		!props.disabled &&
		css`
			background: ${props.theme.colors.button[props.schema].background};
			border-color: ${props.theme.colors.button[props.schema].borderColor};
			color: ${props.theme.colors.button[props.schema].color};

			&:hover {
				background: ${props.theme.colors.button[props.schema].hover.background};
				border-color: ${props.theme.colors.button[props.schema].hover
					.borderColor};
				color: ${props.theme.colors.button[props.schema].hover.color};
				& svg {
					color: ${props.theme.colors.button[props.schema].hover.color};
				}
			}
		`}

	${props =>
		props.disabled &&
		css`
			cursor: not-allowed;
			background: ${props.theme.colors.button.disabledBackground} !important;
		`}
`

const Icon = styled.span<{
	disabled: boolean
	hasContent: boolean
	coloredIcon?: boolean
	schema: Schema
}>`
	margin-right: 8px;

	${props =>
		props.coloredIcon &&
		!props.disabled &&
		css`
			color: ${props.theme.colors.button[props.schema].background};
		`}

	${props =>
		!props.hasContent &&
		css`
			margin-right: 0;
		`}
`

export default memo(Button)
