import React, { useCallback, useRef } from 'react'
import {
	Form,
	TextAreaFormField,
	SelectFormField,
	TextFormField,
	NamingInputFormField,
	useGetNamingConfig
} from '@/components/UberForm'
import { IdCodeName, QueueData } from '@/api/schemas'
import { Container } from '@/components/Container'
import ObjectSettingsControl from '@/pages/User/pages/Home/components/ObjectSettingsControl'
import { FieldSet, Legend, Row, Col } from '@/components/Layout'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import {
	StructureDto,
	StereotypeDto,
	ObjectSettingsUpdateDto
} from '@/api/models'
import { booleanString } from '@/utils/booleans'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { uniqueNameValidator } from '@/utils/validators'
import styled from 'styled-components'
import {
	useNodeStates,
	useResponsibleUsers,
	useStereotypes,
	useSystemUsers
} from '../../../../utils'
import { useTechnologyId } from '@/utils/domain'
import { OpenedQueueData } from '@/store/modules/queue/types'
import { useCustomAttributesInitValues } from '../../../../components/CustomAttributes/hooks/useCustomAttributes'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'

type Props = {
	node?: StructureDto
	data: OpenedQueueData
	systemNodeId: number
	editMode: boolean
	onChange: (data: Partial<QueueData>) => void
	/** Selected nodes for batch edit */
	selectedNodes?: StructureDto[]
}

export const Overview = ({
	node,
	data,
	systemNodeId,
	editMode,
	onChange,
	selectedNodes
}: Props) => {
	const { t } = useAppContext()
	const refForm = useRef<Form<QueueData>>(null)

	const dispatch = useAppDispatch()
	const technologyId = useTechnologyId(systemNodeId)

	const stereotypes = useStereotypes(StereotypeDto.TypeEnum.QUEUE)
	const userList = useResponsibleUsers(data)
	const systemUsers = useSystemUsers(systemNodeId)

	const nodeStates = useNodeStates(t)

	const handleObjectSettingsChange = (newValue: IdCodeName[]) => {
		onChange({
			objectSettings: newValue
		})
	}

	const {
		initialValuesCustomAttributes,
		getCustomAttributesReset,
		parseCustomAttribute,
		customAttributes
	} = useCustomAttributesInitValues(data.form, StructureDto.TypeEnum.QUEUE)

	const handleChange = useCallback(
		(item: Partial<QueueData>, source?: FormlessFormField<QueueData>) => {
			if (source) {
				const name = source.props.name

				if (name === 'stereotypeId' && technologyId) {
					const stereotype = stereotypes.data?.find(s => s.id === item[name])

					const {
						customAttributesForm,
						customAttributesRedux
					} = getCustomAttributesReset(stereotype?.id)

					refForm.current?.setValues(customAttributesForm)

					onChange({
						stereotypeId: stereotype?.id,
						stereotypeCode: stereotype?.code,
						customAttributes: customAttributesRedux
					})
				} else if (
					name === 'responsiblePersonId' ||
					name === 'responsibleAnalystId'
				) {
					const user = userList.data?.find(u => u.id === item[name])

					const userName =
						name === 'responsiblePersonId'
							? 'responsiblePersonName'
							: 'responsibleAnalystName'

					onChange({
						[name]: user?.id,
						[userName]: user?.value
					})
				} else if (name === 'ownerId') {
					const owner = systemUsers?.data?.find(u => u.id === item[name])

					onChange({
						ownerId: owner?.id,
						ownerName: owner?.name
					})
				} else {
					onChange(
						parseCustomAttribute(
							{
								[name]: item[name]
							},
							data.form.customAttributes,
							customAttributes
						)
					)
				}
			}
		},
		[
			data.form.customAttributes,
			onChange,
			parseCustomAttribute,
			getCustomAttributesReset,
			stereotypes.data,
			systemUsers,
			technologyId,
			userList.data
		]
	)

	const namingConfig = useGetNamingConfig(data.form, node)

	return (
		<Container>
			<Form<QueueData>
				onChange={handleChange}
				disabled={!editMode}
				defaultValues={{ ...data.form, ...initialValuesCustomAttributes }}
				key={booleanString(editMode)}
				ref={refForm}
				enableFieldHighlight
			>
				<Row>
					<Col size="medium">
						<TextFormField
							title={t('NAME')}
							name="name"
							initialValue={data.form.name}
							validators={[
								uniqueNameValidator(dispatch, data.original.name, systemNodeId)
							]}
							required
							disabled={Boolean(selectedNodes)}
						/>
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('CODE')}
							callNamingWorkingDataParams={namingConfig}
							initialValue={data.form.code}
							disabled={Boolean(selectedNodes)}
							validators={[
								uniqueNameValidator(dispatch, data.original.code, systemNodeId)
							]}
						/>
					</Col>
					<Col size="medium">
						<SelectFormField
							name="stereotypeId"
							title={t('STEREOTYPE')}
							initialValue={data.form.stereotypeId}
							fieldIsLoading={stereotypes.reloading}
							options={stereotypes.data || []}
							valueKey="id"
							labelKey="name"
							isNumeric
							allowEmpty
						/>
					</Col>
				</Row>
				<Row alignItems="flex-end">
					<Col size="medium">
						<TextAreaFormField
							title={t('COMMENT')}
							name="comment"
							initialValue={data.form.comment}
							rows={5}
						/>
					</Col>
					<Col size="medium">
						<TextAreaFormField
							title={t('DESCRIPTION')}
							name="description"
							initialValue={data.form.description}
							rows={5}
						/>
					</Col>
				</Row>
				<ObjectSettingsControl
					name="objectSettings"
					label={t('OBJECTS')}
					onChange={handleObjectSettingsChange}
					disabled={!editMode}
					data={data.form.objectSettings}
					type={ObjectSettingsUpdateDto.StructureTypeEnum.QUEUE}
				/>

				<FieldSetProject>
					<Legend>{t('PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="status"
								title={t('STATUS')}
								initialValue={data.form.status}
								options={nodeStates}
								valueKey="value"
								labelKey="label"
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsiblePersonId"
								title={t('RESPONSIBLE_PERSON')}
								options={userList.data || []}
								valueKey="id"
								fieldIsLoading={userList.reloading}
								labelKey="value"
								initialValue={data.form.responsiblePersonId}
								isNumeric
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsibleAnalystId"
								title={t('RESPONSIBLE_ANALYST')}
								options={userList.data || []}
								fieldIsLoading={userList.reloading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsibleAnalystId}
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetProject>
				<FieldSetGap>
					<Legend>{t('TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="ownerId"
								title={t('NODE_OWNER')}
								options={systemUsers.data || []}
								fieldIsLoading={systemUsers.loading}
								initialValue={data.form.ownerId}
								valueKey="id"
								labelKey="name"
								isNumeric
								allowEmpty
								clearable
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</Container>
	)
}

const FieldSetProject = styled(FieldSet)`
	margin-top: 15px;
`

const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
