import { CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX } from '@/pages/User/pages/Home/components/CustomAttributes/constants'
import { CustomAttributeComboDto, TableColumn } from '@/api/models'
import { PageType } from '@/pages/User/pages/Home/components/CustomAttributes/types'

export const getCustomAttributeName = <T extends TableColumn>(
	type: PageType | undefined,
	customAttribute: CustomAttributeComboDto,
	item: T | undefined
) => {
	switch (type) {
		case PageType.COLUMN_PAGE:
			return `${CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX}.${customAttribute.id}.${item?.code}.${customAttribute.name}`
		default:
			return `${CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX}.${customAttribute.id}.${customAttribute.name}`
	}
}
