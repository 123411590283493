import React, { useMemo } from 'react'
import { LightTableColumn } from '@/components/LightTable'
import { Button, LightTable } from '@/components'
import { Container } from '@/components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faEdit,
	faTimes,
	faMoneyBillWave,
	faPlus
} from '@fortawesome/free-solid-svg-icons'
import { Flex } from '@/components/Layout'
import { useAppContext } from '@/utils/hooks'
import { DialogWrapper } from '@/components'
import { getAllBusinessDomains, deleteBusinessDomain } from '@/api'
import { useApi } from '@/api/hooks'
import { BusinessDomainDto } from '@/api/models'
import { BusinessDomainModal } from './components/BusinessDomainModal'
import { RemoveModal } from '@/components/RemoveModal/RemoveModal'
import {
	TitleContainer,
	TitleText,
	TitleIcon,
	TitleActions
} from '../../styles'
import { columnsAudit, useSettingsPageSaveHandler } from '../../utils'

export const BusinessDomains = () => {
	const { t } = useAppContext()
	const businessDomains = useApi(getAllBusinessDomains())

	const handleSave = useSettingsPageSaveHandler(businessDomains)

	const columns = useMemo<LightTableColumn<BusinessDomainDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 50
			},
			{
				field: 'description',
				title: t('TABLE_GENERAL_DESCRIPTION'),
				width: 50
			},
			...columnsAudit(t),
			{
				field: '',
				width: 60,
				maxWidth: 60,
				// eslint-disable-next-line react/display-name
				formatter: (_, domain) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<BusinessDomainModal
										businessDomain={domain}
										onSave={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<RemoveModal
										callback={deleteBusinessDomain}
										removeId={domain.id}
										textOnModal={t('REMOVE_DESC', [domain.name])}
										header={t('REMOVE_BUSINESS_DOMAIN')}
										onRemove={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('REMOVE')}
										color="#d00"
										icon={faTimes}
									/>
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				)
			}
		],
		[handleSave]
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon color="#8b9a80" icon={faMoneyBillWave} />
				<TitleText>{t('BUSINESS_DOMAINS')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<BusinessDomainModal onSave={handleSave} onClose={onClose} />
							)
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<LightTable<BusinessDomainDto>
					data={businessDomains.data || []}
					columns={columns}
					keyField={'id'}
					isLoading={businessDomains.loading}
				/>
			</Container>
		</>
	)
}
