import React, { useEffect, useCallback, useMemo } from 'react'
import { ReadMappingDataForm } from '@/store/modules/readMapping/types'
import { ReadMappingColumnProperties } from './Properties'
import { createEmptyReadMappingColumn } from '@/store/modules/readMapping/helpers'
import { useAppContext, useAppStore, useAppDispatch } from '@/utils/hooks'
import { ListEditTab } from '@/components'
import { initApiNode } from '@/store/modules/apiNode/actions'
import { useApi } from '@/api/hooks'
import { getApiNodeColumnFromAllStructures } from '@/api'
import { ApiNodeColumn } from '@/api/schemas/api-node'
import { ReadMappingColumn } from '@/api/schemas/readMapping'
import { ApiNodeColumnDto } from '@/api/models'
import { UpdateDeepPartial } from '@/store/utils'
import { ReadMappingColumnProps } from '@/pages/User/pages/Home/pages/ReadMappingDetail/pages/Columns/types'
import {
	getColumnMappingsOptions,
	getFilteredColumns
} from '@/pages/User/pages/Home/utils'

export const Columns = ({
	node,
	data,
	editMode,
	onChange
}: ReadMappingColumnProps) => {
	const { t } = useAppContext()

	const dispatch = useAppDispatch()
	const apiNodes = useAppStore(state => state.apiNode.nodes)

	const rowSetFieldValue = useAppStore(
		state => state.readMapping.mappings[node.id]?.form.rowsetCode
	)

	const parent = apiNodes[node.parentStructureId as number]

	useEffect(() => {
		if (!parent && node.parentStructureId) {
			dispatch(initApiNode({ nodeId: node.parentStructureId }))
		}
	}, [node.parentStructureId])

	const columnsAll = useApi(
		getApiNodeColumnFromAllStructures(Number(node.parentStructureId))
	)

	const items = data.form.columns || []

	//FIXME - is there a way to fix this cast?
	const columnsMapping2 = getColumnMappingsOptions(
		getFilteredColumns(parent, columnsAll?.data),
		rowSetFieldValue
	).map(c => {
		const x: ApiNodeColumn = {
			id: -1,
			order: 0,
			name: c.columnName!,
			code: c.columnCode,
			mandatory: false
		}

		return x
	})

	const availableColumns = columnsMapping2?.filter(
		c => !items?.find(item => item?.columnCode == c.code)
	)

	const props = useMemo(
		() => ReadMappingColumnProperties(t, columnsMapping2, availableColumns),
		[t, columnsMapping2, availableColumns]
	)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<ReadMappingDataForm>) => {
			if (v.columns) {
				Object.values(v.columns).forEach(c => {
					if (c.columnCode) {
						//FIXME - Is this the right way to check the column from API? How to make sure it is actually loaded?
						const x = getColumnMappingsOptions(
							getFilteredColumns(parent, columnsAll?.data),
							rowSetFieldValue
						).find(col => col.columnCode == c.columnCode)

						//FIXME - what is the best way to cast these 2 enums?
						if (x?.sourceType === ApiNodeColumnDto.SourceTypeEnum.API_NODE) {
							c.columnSourceType =
								ReadMappingColumn.ReadMappingColumnSourceType.API_NODE
						} else {
							c.columnSourceType =
								ReadMappingColumn.ReadMappingColumnSourceType.ROW_SET
						}

						c.columnSourceColumnUuid = x?.columnUuid
						c.columnSourceStructureId = x?.sourceStructureId
						c.columnSourceStructureCode = x?.sourceStructureCode
					}
				})
			}

			onChange(v)
		},
		[columnsAll, onChange, parent, rowSetFieldValue]
	)

	return (
		<ListEditTab
			node={node}
			data={data.form}
			editMode={editMode}
			isRowOrderable
			createEmpty={createEmptyReadMappingColumn}
			itemsKey={'columns'}
			idCounterKey={'columnsLastId'}
			onChange={handleChange}
			properties={props}
			tabKey="column"
			existsKey="columnCode"
		/>
	)
}
