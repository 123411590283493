/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, DeepPartial } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxThunk from 'redux-thunk'
import { cancelAction } from './middlewares/cancelAction/cancelAction'

import reducers from './modules'

export type StoreState = ReturnType<typeof reducers>

const middleWares = [reduxThunk, cancelAction]

export const buildStore = (initialState: DeepPartial<StoreState> = {}) =>
	createStore(
		reducers,
		initialState as any,
		composeWithDevTools({ trace: true })(applyMiddleware(...middleWares))
	)

export const store = buildStore()
