import { ItemPropertyType } from '../../../../../../../../components/Properties/types'
import { ItemProperty } from '../../../../../../../../components/Properties/types'
import { TableColumn, TableIndex } from '@/api/schemas'
import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'

export const IndexProperties = memoizeOne(
	(
		t: TranslationHelper,
		columns: TableColumn[]
	): ItemProperty<TableIndex>[] => [
		{
			field: 'name',
			label: t('TABLE_INDEXES_NAME'),
			type: ItemPropertyType.TEXT,
			width: 100,
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'code',
			label: t('TABLE_COLUMNS_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			width: 100,
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'isUnique',
			label: t('TABLE_INDEXES_UNIQUE'),
			type: ItemPropertyType.CHECKBOX,
			flex: 0.4,
			width: 60
		},
		{
			field: 'physicalOptions',
			label: t('TABLE_INDEXES_PHYSICAL_OPTIONS'),
			type: ItemPropertyType.ACE_EDITOR,
			hideInTable: true
		},
		{
			field: 'description',
			label: t('TABLE_INDEXES_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			width: 300,
			flex: 2
		},
		{
			field: 'comment',
			label: t('TABLE_INDEXES_COMMENT'),
			type: ItemPropertyType.TEXTAREA,
			width: 300,
			flex: 2
		},
		{
			field: 'columns',
			label: t('TABLE_INDEXES_COLUMNS'),
			type: ItemPropertyType.COLUMNS_WITH_ORDER,
			options: columns,
			hideInTable: true
		}
	]
)
