import { getStereotypes } from '@/api'
import { useApi } from '@/api/hooks'
import { StereotypeDto } from '@/api/models'
import { useMemo } from 'react'

export const useCustomAttributesStereotypes = () => {
	const stereotypes = useApi(getStereotypes())

	const stereotypeHistory = useMemo(
		() =>
			stereotypes.data?.find(
				stereotype => stereotype.type === StereotypeDto.TypeEnum.TABLE_HISTORY
			),
		[stereotypes.data]
	)

	const result = useMemo(() => {
		const data =
			stereotypes.data?.filter(
				stereotype => (stereotypeHistory?.id ?? -1) !== stereotype.id
			) ?? []

		return {
			...stereotypes,
			data
		}
	}, [stereotypeHistory, stereotypes])

	return result
}
