import {
	SystemUser,
	TablePermission,
	TechnicalColumnStereotypeDto
} from '@/api/models'
import {
	HistoryTableData,
	IdCodeName,
	TableColumn,
	TableConstraint,
	TableData,
	TableIndex
} from '@/api/schemas'
import {
	TableColumnFlat,
	TablePermissionFlat
} from '@/store/modules/table/types'
import { v4 } from 'uuid'
import { ensureValidData } from '../helpers'

export const defaultHistoryTableData = (
	defaultObjectSettings: IdCodeName | undefined = undefined
): Readonly<HistoryTableData> => ({
	name: '',
	code: '',
	objectSettings: defaultObjectSettings ? [defaultObjectSettings] : [],
	nonSyncFields: ['code', 'name'],
	columns: []
})

export const defaultReferenceTableData = (
	defaultObjectSettings: IdCodeName | undefined = undefined
): Readonly<HistoryTableData> => ({
	name: '',
	code: '',
	objectSettings: defaultObjectSettings ? [defaultObjectSettings] : [],
	nonSyncFields: ['code', 'name']
})

export const defaultTableData = (
	defaultObjectSettings: IdCodeName | undefined = undefined
): Readonly<TableData> => ({
	name: '',
	code: '',
	columns: [],
	columnsLastId: 0,
	indexes: [],
	indexesLastId: 0,
	constraints: [],
	constraintsLastId: 0,
	hasHistoryTable: false,
	hasReferenceTable: false,
	script: '',
	preTableScript: '',
	postTableScript: '',
	objectSettings: defaultObjectSettings ? [defaultObjectSettings] : [],
	referenceTable: defaultReferenceTableData(),
	historyTable: defaultHistoryTableData(),
	objectPermissions: [],
	objectPermissionLastId: 0
})

export const getInitialTableData = (
	name: string,
	code = '',
	defaultObjectSettings: IdCodeName | undefined = undefined
): TableData => {
	return ensureValidData(
		{
			name,
			code,
			objectSettings: defaultObjectSettings ? [defaultObjectSettings] : []
		},
		defaultTableData(defaultObjectSettings)
	)
}

export const createEmptyColumn = (columns: TableColumn[]): TableColumn => ({
	id: -1,
	uuid: v4(),
	order: Math.max(0, ...columns.map(c => c.order || 0)) + 1,
	name: ''
})

export const createEmptyIndex = (indexes: TableIndex[]): TableIndex => ({
	id: -1,
	order: Math.max(0, ...indexes.map(c => c.order || 0)) + 1,
	name: '',
	isUnique: false,
	columns: []
})

export const createEmptyKey = () => {
	return {
		id: -1,
		name: '',
		code: '',
		description: '',
		enabled: true,
		columns: []
	} as TableConstraint
}

export const flatColumns = (col: TableColumn) => {
	const { domain, stereotype, ...tableData } = col

	const copied: TableColumnFlat = {
		...tableData,
		domainId: domain?.id,
		domainName: domain?.name,
		domainCode: domain?.code,
		stereotypeId: stereotype?.id,
		stereotypeName: stereotype?.name,
		stereotypeCode: stereotype?.code
	}

	return copied
}

export const inflateColumns = (col: TableColumnFlat) => {
	const {
		domainId,
		domainCode,
		domainName,
		stereotypeId,
		stereotypeCode,
		stereotypeName,
		...tableData
	} = col

	const copied: TableColumn = {
		...tableData,
		domain: {
			id: domainId,
			code: domainCode,
			name: domainName
		},
		stereotype: {
			id: stereotypeId,
			name: stereotypeName,
			code: stereotypeCode
		}
	}

	return copied
}

export const flatTablePermission = (permission: TablePermission) => {
	const { user, ...data } = permission

	const copied: TablePermissionFlat = {
		...data,
		userId: user?.id,
		userName: user?.name,
		userCode: user?.code,
		userDescription: user?.description,
		userComment: user?.comment
	}

	return copied
}

export const inflateTablePermission = (permission: TablePermissionFlat) => {
	const {
		userId,
		userName,
		userCode,
		userComment,
		userDescription,
		...data
	} = permission

	const copied: TablePermission = {
		...data,
		user: {
			id: userId,
			code: userCode,
			name: userName,
			comment: userComment,
			description: userDescription
		}
	}

	return copied
}

export const columnFromTechnicalColumn = (
	tc: TechnicalColumnStereotypeDto,
	existingStereotypeColumns?: TableColumnFlat[]
): TableColumnFlat => {
	const existingColumn = existingStereotypeColumns?.find(
		x => x.code === tc.code
	)

	if (existingColumn) {
		return existingColumn
	}

	return {
		id: tc.id,
		uuid: v4(),
		order: tc.ordering,
		name: tc.name,
		code: tc.code,
		description: tc.description,
		comment: tc.comment,
		virtualColumnScript: tc.virtualColumnScript,
		dataType: tc.dataType,
		defaultValue: tc.defaultValue,
		notNullFlag: tc.notNull,
		stereotypeId: tc.columnStereotypeId,
		stereotypeName: tc.columnStereotypeName,
		stereotypeCode: tc.columnStereotypeCode,
		domainId: tc.domainId,
		domainName: tc.domainName,
		domainCode: tc.domainCode,
		technicalColumnId: tc.id
	}
}

export const createEmptyPermission = (
	permissions: TablePermission[]
): TablePermission => ({
	id: -1,
	order: Math.max(0, ...permissions.map(c => c.order || 0)) + 1,
	user: undefined,
	permissions: []
})
