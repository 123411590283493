import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Modal } from '@/components/Modal/Modal'
import { logout } from '@/store/modules/auth/actions'
import styled from 'styled-components'
import { ApiError } from '@/api/utils'
import { Button } from '@/components'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

type Props = Readonly<ReturnType<typeof mapDispatchToProps>>

type State = Readonly<typeof initialState>

enum ErrorType {
	HARD,
	SOFT
}

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			logout
		},
		dispatch
	)

const initialState = {
	open: true,
	timestamp: null as Date | null,
	error: null as ApiError | null,
	body: null as string | null,
	type: ErrorType.HARD
}

class ErrorContainerComponent extends React.PureComponent<Props, State> {
	state = initialState

	componentDidCatch(error: Error) {
		this.setState({
			error: error as ApiError,
			timestamp: new Date(),
			open: true
		})
	}

	componentDidMount() {
		window.onunhandledrejection = (error: PromiseRejectionEvent) => {
			this.setState({
				error: error.reason,
				timestamp: new Date(),
				open: true
			})

			const response = error.reason.response as Response | undefined

			if (response) {
				response
					.text()
					.then((data: string) => {
						try {
							const json: {
								errorCode?: string
								errorDescription?: string
								error?: string
								message?: string
							} = JSON.parse(data)

							this.setState({
								body:
									json.errorDescription || json.error || json.message || data,
								type: response.status === 423 ? ErrorType.SOFT : ErrorType.HARD
							})
						} catch (err) {
							this.setState({ body: data })
						}
					})
					.catch(err => {
						console.warn('Failed to load response body', err)
					})
			}
		}
	}

	handleRefresh() {
		window.location.reload()
	}

	render() {
		const { error, body, timestamp, type, open } = this.state
		// eslint-disable-next-line react/prop-types
		const { children } = this.props
		const isErrorSoft = type === ErrorType.SOFT

		if (error) {
			return (
				<>
					<Modal
						open={open}
						closeOnEscape={isErrorSoft}
						zIndex={1001}
						contentStyle={{
							width: 'auto',
							minWidth: '250px',
							maxWidth: '50%'
						}}
						header={
							error.response
								? 'Error while communicating with server'
								: 'Runtime error'
						}
						footer={
							isErrorSoft ? (
								<Button
									schema="warn"
									onClick={() => this.setState({ open: false })}
								>
									Close
								</Button>
							) : (
								<RefreshButton onClick={this.handleRefresh} icon={faRedo}>
									Reload page
								</RefreshButton>
							)
						}
					>
						{error.response && (
							<Items>
								<ItemTitle>Timestamp</ItemTitle>
								<ItemValue>{timestamp && timestamp.toISOString()}</ItemValue>
								<ItemTitle>Version</ItemTitle>
								<ItemValue>{process.env.VERSION}</ItemValue>
								<ItemTitle>Url</ItemTitle>
								<ItemValue>{error.response.url}</ItemValue>
								<ItemTitle>Message</ItemTitle>
								<ItemValue>{error.message}</ItemValue>
								<ItemTitle>Status</ItemTitle>
								<ItemValue>
									{error.response.status} {error.response.statusText}
								</ItemValue>
								<ItemTitle>Body</ItemTitle>
								<ItemValue>{body}</ItemValue>
							</Items>
						)}
						{!error.response && (
							<Items>
								<ItemTitle>Timestamp</ItemTitle>
								<ItemValue>{timestamp && timestamp.toISOString()}</ItemValue>
								<ItemTitle>Version</ItemTitle>
								<ItemValue>{process.env.VERSION}</ItemValue>
								<ItemTitle>Message</ItemTitle>
								<ItemValue>{error.message}</ItemValue>
								<ItemTitle>Stack</ItemTitle>
								<ItemValue>
									<pre>{error.stack}</pre>
								</ItemValue>
							</Items>
						)}
					</Modal>
					{isErrorSoft && children}
				</>
			)
		}

		return children
	}
}

const Items = styled.div``

const ItemTitle = styled.div`
	color: #aaa;
	margin-bottom: 3px;
`

const ItemValue = styled.div`
	overflow-x: auto;
	margin-bottom: 15px;
	padding-bottom: 5px;

	pre {
		margin: 0;
	}
`

const RefreshButton = styled(Button)`
	&& {
		flex-grow: 0;
		flex-basis: 150px;
		margin-left: auto;
		width: auto;
	}
`

export const ErrorContainer = connect(
	null,
	mapDispatchToProps
)(ErrorContainerComponent) as React.FC
