import { getAllNodeHistory } from '@/api'
import { useApi } from '@/api/hooks'
import { Button, DialogWrapper } from '@/components'
import { Modal } from '@/components/Modal/Modal'
import { SelectWithoutForm } from '@/components/UberForm/Input/Select/Select'
import { useAppContext } from '@/utils/hooks'
import {
	faHistory,
	faMinus,
	faPlus,
	faTimes,
	faUpload
} from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDetailTabContext } from '../context/DetailTabContext'
import { useReplaceVersionData } from '../hooks/useReplaceVersionData'
import { SelectEnv } from './SelectEnv'

type Props = {
	onChangeVersion: (version?: number) => void
	onChangeActive: (active: boolean) => void
	onChangeVersionMax: (versionMax?: number) => void
}

export const ViewHistory = ({
	onChangeVersion,
	onChangeActive,
	onChangeVersionMax
}: Props) => {
	const [version, setVersion] = useState(0)
	const [active, setActive] = useState(false)
	const { t } = useAppContext()

	const {
		state: { node }
	} = useDetailTabContext()

	const changeVersion = useCallback(
		(version: number) => setVersion(version),
		[]
	)

	const histories = useApi(getAllNodeHistory(node.id))
	const { replaceVersionData, replacing } = useReplaceVersionData(changeVersion)

	const versions = useMemo(
		() => histories.data?.map(history => history.version) ?? [0],
		[histories.data]
	)

	const { versionMin, versionMax } = useMemo(() => {
		const versionMin = Math.min(...versions)
		const versionMax = Math.max(...versions)

		return {
			versionMin,
			versionMax
		}
	}, [versions])

	useEffect(() => {
		onChangeVersionMax(versionMax)
	}, [onChangeVersionMax, versionMax])

	useEffect(() => {
		onChangeActive(active)
	}, [onChangeActive, active])

	useEffect(() => {
		setVersion(versionMax)
	}, [versionMax])

	useEffect(() => {
		onChangeVersion(active && versionMax !== version ? version : undefined)
	}, [active, onChangeVersion, version, versionMax])

	return (
		<Wrapper>
			{!active && (
				<Button
					onClick={() => setActive(true)}
					icon={faHistory}
					schema="transparent"
					size="sm"
				>
					{t('VIEW_HISTORY')}
				</Button>
			)}
			{active && (
				<>
					{version < versionMax && (
						<>
							<SelectEnv replacing={replacing} />
							<DialogWrapper
								dialog={(opened, onClose) => (
									<Modal
										open={opened}
										onClose={onClose}
										header={t('REPLACE_VERSION')}
										footer={() => (
											<>
												<Button
													onClick={async () => {
														await replaceVersionData()
														onClose()
													}}
													icon={faUpload}
													schema="primary"
													style={{
														marginRight: '15px'
													}}
													disabled={replacing}
												>
													{t('YES')}
												</Button>
												<Button
													onClick={onClose}
													icon={faTimes}
													schema="danger"
													disabled={replacing}
												>
													{t('NO')}
												</Button>
											</>
										)}
									>
										<>{t('MAKE_CURRENTLY_DISPLAYED_VERSION_THE_LATEST_ONE')}</>
									</Modal>
								)}
							>
								{open => (
									<Button
										onClick={open}
										icon={faUpload}
										schema="transparent"
										size="sm"
										style={{
											marginRight: '15px'
										}}
										disabled={replacing}
									>
										{t('REPLACE_VERSION')}
									</Button>
								)}
							</DialogWrapper>
						</>
					)}
					<Button
						onClick={() => setVersion(prev => prev - 1)}
						icon={faMinus}
						schema="transparent"
						size="sm"
						disabled={versionMin >= version || replacing}
					/>
					<Select
						name="version"
						value={version}
						options={histories.data}
						loading={histories.loading}
						valueKey="version"
						labelKey="version"
						clearable
						isNumeric
						onChange={value => {
							if (value === undefined) {
								setActive(false)
								setVersion(versionMax)

								return
							}

							setVersion(value)
						}}
						disabled={replacing}
					/>
					<Button
						onClick={() => setVersion(prev => prev + 1)}
						icon={faPlus}
						schema="transparent"
						size="sm"
						disabled={versionMax <= version || replacing}
					/>
				</>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	padding-left: 6px;
`

const Select = styled(SelectWithoutForm)`
	width: 100px;
`
