import { useAppContext, useAppStore } from '@/utils/hooks'
import React from 'react'
import { NodeActionsWrapper } from './styles'
import { NodeActionsProps } from '@/pages/User/pages/Home/components/TreePanel/components/NodeActions/types'
import { renderFrozenIcon, renderLockedIcon } from '@/utils/node-action-icons'

export const NodeActions = ({ node }: NodeActionsProps) => {
	const user = useAppStore(state => state.auth.user)
	const { t } = useAppContext()

	return (
		<NodeActionsWrapper>
			{renderFrozenIcon(node.node.status)}
			{renderLockedIcon(node.node, user, t)}
		</NodeActionsWrapper>
	)
}
