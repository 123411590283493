import { StructureDto, ObjectSettingsUpdateDto } from '@/api/models'
import { ApiNodeData } from '@/api/schemas/api-node'
import { Col, Row, Legend, FieldSet } from '@/components/Layout'
import {
	Form,
	TextAreaFormField,
	TextFormField,
	SelectFormField,
	NamingInputFormField,
	useGetNamingConfig
} from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { OpenedApiNodeData } from '@/store/modules/apiNode/types'
import { booleanString } from '@/utils/booleans'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { uniqueNameValidator } from '@/utils/validators'
import React, { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Container } from '../../components/StyledComponents'
import { useApi } from '@/api/hooks'
import { useSystemUsers, useResponsibleUsers } from '../../../../utils'
import { getAllBusinessDomains, getUsersForCombo } from '@/api'
import { IdCodeName } from '@/api/schemas'
import ObjectSettingsControl from '../../../../components/ObjectSettingsControl'
import { useCustomAttributesInitValues } from '../../../../components/CustomAttributes/hooks/useCustomAttributes'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'

type Props = {
	node?: StructureDto
	data: OpenedApiNodeData
	editMode: boolean
	systemNodeId: number
	onChange: (data: Partial<ApiNodeData>) => void
	/** Selected nodes for batch edit */
	selectedNodes?: StructureDto[]
}

export const Overview = ({
	node,
	data,
	editMode,
	systemNodeId,
	onChange,
	selectedNodes
}: Props) => {
	const { t } = useAppContext()
	const refForm = useRef<Form<ApiNodeData>>(null)

	const businessDomains = useApi(getAllBusinessDomains())
	const systemUsers = useSystemUsers(systemNodeId)
	const userList = useResponsibleUsers(data)

	const [users, isUserLoading] = useApi(getUsersForCombo())

	const {
		initialValuesCustomAttributes,
		parseCustomAttribute,
		customAttributes
	} = useCustomAttributesInitValues(data.form, StructureDto.TypeEnum.API_NODE)

	const states = useMemo(
		() => [
			{
				label: t('MAPPING_STATUS_LOGICAL_IN_PROGRESS'),
				value: ApiNodeData.ApiNodeStatusEnum.LOGICAL_IN_PROGRESS
			},
			{
				label: t('MAPPING_STATUS_LOGICAL_COMPLETED'),
				value: ApiNodeData.ApiNodeStatusEnum.LOGICAL_COMPLETED
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_IN_PROGRESS'),
				value: ApiNodeData.ApiNodeStatusEnum.PHYSICAL_IN_PROGRESS
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_COMPLETED'),
				value: ApiNodeData.ApiNodeStatusEnum.PHYSICAL_COMPLETED
			},
			{
				label: t('MAPPING_STATUS_FROZEN'),
				value: ApiNodeData.ApiNodeStatusEnum.FROZEN
			}
		],
		[t]
	)

	const handleObjectSettingsChange = (newValue: IdCodeName[]) => {
		onChange({
			objectSettings: newValue
		})
	}

	const dispatch = useAppDispatch()

	const handleChange = useCallback(
		(item: Partial<ApiNodeData>, source?: FormlessFormField<ApiNodeData>) => {
			if (source) {
				const { name } = source.props

				if (name === 'businessDomainId') {
					const businessDomain = businessDomains?.data?.find(
						d => d.id === item[name]
					)

					onChange({
						businessDomainId: businessDomain?.id
					})

					return
				} else if (
					name === 'responsiblePersonId' ||
					name === 'responsibleAnalystId'
				) {
					const user = userList.data?.find(u => u.id === item[name])

					const userName =
						name === 'responsiblePersonId'
							? 'responsiblePersonName'
							: 'responsibleAnalystName'

					onChange({
						[name]: user?.id,
						[userName]: user?.value
					})

					return
				} else if (name === 'ownerId') {
					const owner = systemUsers?.data?.find(u => u.id === item[name])

					onChange({
						ownerId: owner?.id,
						ownerName: owner?.name
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name]
						},
						data.form.customAttributes,
						customAttributes
					)
				)
			}
		},
		[
			onChange,
			parseCustomAttribute,
			data.form.customAttributes,
			businessDomains,
			userList.data,
			systemUsers
		]
	)

	const form = data.form

	const namingConfig = useGetNamingConfig(form, node)

	return (
		<Container>
			<Form<ApiNodeData>
				onChange={handleChange}
				disabled={!editMode}
				defaultValues={{ ...form, ...initialValuesCustomAttributes }}
				key={booleanString(editMode)}
				ref={refForm}
				enableFieldHighlight
			>
				<Row>
					<Col size="medium">
						<TextFormField
							title={t('TABLE_GENERAL_NAME')}
							name="name"
							initialValue={form.name}
							validators={[
								uniqueNameValidator(dispatch, data.original.name, systemNodeId)
							]}
							required
							disabled={Boolean(selectedNodes)}
						/>
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('TABLE_GENERAL_CODE')}
							callNamingWorkingDataParams={namingConfig}
							initialValue={form.code}
							disabled={Boolean(selectedNodes)}
							validators={[
								uniqueNameValidator(dispatch, data.original.code, systemNodeId)
							]}
						/>
					</Col>
				</Row>
				<Row alignItems="flex-end">
					<Col size="medium">
						<TextAreaFormField
							title={t('TABLE_GENERAL_COMMENT')}
							name="comment"
							initialValue={form.comment}
							rows={5}
						/>
					</Col>
					<Col size="medium">
						<TextAreaFormField
							title={t('TABLE_GENERAL_DESCRIPTION')}
							name="description"
							initialValue={form.description}
							rows={5}
						/>
					</Col>
				</Row>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="status"
								title={t('MAPPING_STATUS')}
								initialValue={data.form.status}
								options={states}
								valueKey="value"
								labelKey="label"
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsiblePersonId"
								title={t('RESPONSIBLE_PERSON')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsiblePersonId}
								isNumeric
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsibleAnalystId"
								title={t('RESPONSIBLE_ANALYST')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsibleAnalystId}
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetGap>

				<ObjectSettingsControl
					name="objectSettings"
					label={t('OBJECTS')}
					onChange={handleObjectSettingsChange}
					disabled={!editMode}
					data={data.form.objectSettings}
					type={ObjectSettingsUpdateDto.StructureTypeEnum.API_NODE}
				/>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_BUSINESS_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="businessDomainId"
								title={t('TABLE_GENERAL_BUSINESS_DOMAIN')}
								initialValue={form.businessDomainId}
								options={businessDomains.data}
								fieldIsLoading={businessDomains.reloading}
								valueKey="id"
								labelKey="name"
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="ownerId"
								title={t('NODE_OWNER')}
								options={systemUsers.data || []}
								fieldIsLoading={systemUsers.loading}
								initialValue={form.ownerId}
								valueKey="id"
								labelKey="name"
								isNumeric
								allowEmpty
								clearable
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</Container>
	)
}

const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
