import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { App } from './pages/App/App'
import { store } from './store'
import AppContextWrapper from './context/AppContext/AppContextWrapper'
import { AuthContextProvider } from './context/AuthContext/AuthContext'
import * as styles from '@/styles'
import 'jsondiffpatch/dist/formatters-styles/html.css'

type Props = {
	token?: string
}

export const Root = ({ token }: Props) => {
	return (
		<Provider store={store}>
			<ThemeProvider theme={styles}>
				<AuthContextProvider>
					<BrowserRouter>
						<AppContextWrapper>
							<App token={token} />
						</AppContextWrapper>
					</BrowserRouter>
				</AuthContextProvider>
			</ThemeProvider>
		</Provider>
	)
}
