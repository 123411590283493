import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { getCanGenerateNaming } from './getCanGenerateNaming'
import { NamingTypeEnum } from './types'

/** Check parent folders & system if the node can auto generate naming */
export const useCanGenerateNaming = (
	nodeId?: number,
	namingType?: NamingTypeEnum
) => {
	const [generateCode, setGenerateCode] = useState(false)

	const getCanGenerateNamingDebounced = useMemo(
		() =>
			debounce(async () => {
				if (nodeId) {
					const generateCode = await getCanGenerateNaming(nodeId, namingType)

					setGenerateCode(generateCode)
				}
			}, 200),

		[namingType, nodeId]
	)

	useEffect(() => {
		getCanGenerateNamingDebounced()
	}, [getCanGenerateNamingDebounced])

	return generateCode
}
