import React from 'react'
import { CustomLovDto } from '@/api/models'
import { LightTableColumn } from '@/components/LightTable'
import { useMemo } from 'react'
import { useAppContext } from '@/utils/hooks'
import { Flex } from '@/components/Layout'
import { Button, DialogWrapper, RemoveModal } from '@/components'
import { CustomLovModal } from '../components/CustomLovModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { deleteCustomAttribute, deleteCustomLov } from '@/api'
import { columnsAudit } from '../../../utils'

export const useCustomLovColumns = (handleSave: () => void) => {
	const { t } = useAppContext()

	return useMemo<LightTableColumn<CustomLovDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 80,
				formatter: cell => cell || '-'
			},
			{
				field: 'description',
				title: t('DESCRIPTION'),
				width: 80,
				formatter: cell => cell || '-'
			},
			...columnsAudit(t),
			{
				field: '',
				width: 85,
				maxWidth: 85,
				// eslint-disable-next-line react/display-name
				formatter: (_, customLov) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<CustomLovModal
										customLov={customLov}
										onSave={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>

						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<RemoveModal
										removeId={customLov.id}
										callback={deleteCustomLov}
										textOnModal={t('REMOVE_DESC', [
											customLov.description || t('CUSTOM_LOV')
										])}
										header={t('DELETE_CUSTOM_LOV')}
										onRemove={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('REMOVE')}
										color="#d00"
										icon={faTimes}
									/>
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				)
			}
		],
		[handleSave, t]
	)
}
