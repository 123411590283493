import { apiCallAction } from '@/store/utils'
import { adjustTableName, checkUniqueName as checkName } from '@/api'
import { AdjustResultDto, CheckResultDto } from '@/api/models'

export const generateCode = (name?: string): Promise<AdjustResultDto> =>
	((!name || name.length === 0
		? () => Promise.resolve({ result: '' })
		: apiCallAction(() => adjustTableName({ name }))) as unknown) as Promise<
		AdjustResultDto
	>

export const checkUniqueName = (
	name?: string,
	systemFolderId?: number
): Promise<CheckResultDto> =>
	((!name || name.length === 0
		? () => Promise.resolve({ result: true })
		: apiCallAction(() =>
				checkName({ name, systemFolderId })
		  )) as unknown) as Promise<CheckResultDto>
