import { NativeMap } from '@/utils/collections'
import { Action } from './actions'
import { FOLDER_INIT, FOLDER_UPDATE, FOLDER_SAVE } from './constants'
import { FolderData, OpenedFolderData, OpenedFolderTab } from './types'
import {
	getInitialFolderData,
	defaultFolderData,
	getFolderDataForm
} from './helpers'
import { updateTabData } from '@/store/utils'
import { ensureValidData } from '../helpers'
import { DiagramData } from '@/api/schemas/diagram'

type State = Readonly<typeof initialState>

const initialState = {
	folders: {} as NativeMap<OpenedFolderData>
}

export default (state = initialState, action: Action): State => {
	switch (action.type) {
		case FOLDER_INIT: {
			const { node, editMode, force } = action

			const previous = state.folders[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: FolderData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultFolderData)
				: getInitialFolderData(node.name)

			const folderDataForm = getFolderDataForm(data)

			return {
				...state,
				folders: {
					...state.folders,
					[node.id]: {
						form: folderDataForm,
						dirty: false,
						parsedEditMode: editMode,
						tab: previous ? previous.tab : OpenedFolderTab.Overview
					}
				}
			}
		}

		case FOLDER_UPDATE: {
			const { node, update } = action

			return {
				...state,
				folders: updateTabData(state.folders, node.id, node => ({
					...node,
					form: {
						...node.form,
						...update,
						diagram: {
							...node.form.diagram,
							...update.diagram
						} as DiagramData
					},
					dirty: true
				}))
			}
		}

		case FOLDER_SAVE: {
			const {
				metadata: { nodeId }
			} = action

			return {
				...state,
				folders: updateTabData(state.folders, nodeId, node => ({
					...node,
					dirty: false
				}))
			}
		}

		default: {
			return state
		}
	}
}
