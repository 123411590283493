import { getStereotypes, getSystemUsers, getUsersForCombo } from '@/api'
import { useApi } from '@/api/hooks'
import { ApiNodeColumnDto, StereotypeDto } from '@/api/models'
import { NodeStatus } from '@/api/schemas/enums'
import { TranslationHelper } from '@/context/Locale/Locale'
import { uniqBy } from 'lodash'
import { useMemo } from 'react'
import { GetColumnMappingsOptionsType } from '@/pages/User/pages/Home/types'
import SourceTypeEnum = ApiNodeColumnDto.SourceTypeEnum
import { ApiNodeColumnType } from '@/api/schemas/api-node'
import { OpenedApiNodeData } from '@/store/modules/apiNode/types'

export const useSystemUsers = (systemNodeId: number) =>
	useApi(getSystemUsers(systemNodeId))

export const useStereotypes = (type: StereotypeDto.TypeEnum) =>
	useApi(getStereotypes({ type }))

export const useResponsibleUsers = (formData: Record<string, any>) => {
	const response = useApi(getUsersForCombo())

	const initUsers = useMemo(
		() =>
			[
				{
					id: formData.responsiblePersonId,
					value: formData.responsiblePersonName
				},
				{
					id: formData.responsibleAnalystId,
					value: formData.responsibleAnalystName
				}
			].filter(v => v.id !== undefined),
		[
			formData.responsibleAnalystId,
			formData.responsibleAnalystName,
			formData.responsiblePersonId,
			formData.responsiblePersonName
		]
	)

	const responseWithInitialValue = useMemo(() => {
		const data = response.data ? [...response.data, ...initUsers] : initUsers
		const dataNoDuplicates = uniqBy(data, item => item.id)

		return {
			...response,
			data: dataNoDuplicates
		}
	}, [initUsers, response])

	return responseWithInitialValue
}

export const useNodeStates = (t: TranslationHelper) =>
	useMemo(
		() => [
			{
				label: t('TABLE_STATUS_LOGICAL_IN_PROGRESS'),
				value: NodeStatus.LOGICAL_IN_PROGRESS
			},
			{
				label: t('TABLE_STATUS_LOGICAL_COMPLETED'),
				value: NodeStatus.LOGICAL_COMPLETED
			},
			{
				label: t('TABLE_STATUS_PHYSICAL_IN_PROGRESS'),
				value: NodeStatus.PHYSICAL_IN_PROGRESS
			},
			{
				label: t('TABLE_STATUS_PHYSICAL_COMPLETED'),
				value: NodeStatus.PHYSICAL_COMPLETED
			},
			{
				label: t('TABLE_STATUS_FROZEN'),
				value: NodeStatus.FROZEN
			}
		],
		[t]
	)

/**
 * Returns proper dropdown options array based on what user selects in the RowSetField (rowSetFieldValue) or if the data
 * is for WriteMapping only (columns from a target table)
 * @param columnsData
 * @param rowSetFieldValue
 * @param isWriteMapping
 */

export const getColumnMappingsOptions: GetColumnMappingsOptionsType = (
	columnsData,
	rowSetFieldValue,
	isWriteMapping
) => {
	if (columnsData && rowSetFieldValue && isWriteMapping === undefined) {
		return columnsData.filter(c => {
			return c.sourceStructureCode === rowSetFieldValue
		})
	} else if (
		columnsData &&
		rowSetFieldValue === undefined &&
		isWriteMapping === undefined
	) {
		return columnsData.filter(c => {
			return c.sourceType === ApiNodeColumnDto.SourceTypeEnum.API_NODE
		})
	} else if (
		columnsData &&
		rowSetFieldValue === undefined &&
		isWriteMapping === true
	) {
		return columnsData.filter(c => {
			return c.sourceType === ApiNodeColumnDto.SourceTypeEnum.TABLE
		})
	}

	return []
}

/**
 * Returns properly filtered array of columnsData for the`getColumnMappingsOptions` function as a parameter.
 * @param parentNodeData
 * @param columnsData
 */

export const getFilteredColumns = (
	parentNodeData: OpenedApiNodeData | undefined,
	columnsData: ApiNodeColumnDto[] | undefined
) => {
	const filteredParentColumns = parentNodeData?.form.columns.filter(
		column => column.type !== ApiNodeColumnType.IN
	)

	const filteredParentColumnsIds = filteredParentColumns?.map(
		filteredParentColumn => filteredParentColumn.uuid
	)

	const filteredApiNodesAttributes = columnsData?.filter(c =>
		filteredParentColumnsIds?.includes(c.columnUuid)
	)

	const allColumnsWithoutApiNodes = columnsData?.filter(
		c => c.sourceType !== SourceTypeEnum.API_NODE
	)

	return [
		...(filteredApiNodesAttributes || []),
		...(allColumnsWithoutApiNodes || [])
	]
}
