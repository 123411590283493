import React from 'react'
import { SettingsItem } from '../types'
import { Users } from '../pages/Users/Users'
import { Acronyms } from '../pages/Acronyms/Acronyms'
import { Templates } from '../pages/Templates/Templates'
import { BusinessDomains } from '../pages/BusinessDomains/BusinessDomais'
import { Domains } from '../pages/Domains/Domains'
import { TechnicalColumns } from '../pages/TechnicalColumns/TechnicalColums'
import { ObjectSettings } from '../pages/ObjectSettings/ObjectSettings'
import { CustomAttributes } from '../pages/CustomAttributes/CustomAttributes'
import { StereoTypes } from '../pages/StereoTypes/StereoTypes'
import { useAuthContext } from '@/utils/hooks'
import { Naming } from '../pages/Naming/Naming'
import { ScriptEditor } from '../pages/ScriptEditor/ScriptEditor'
import { CustomLov } from '../pages/CustomLov/CustomLov'

export const View = ({ item }: { item: SettingsItem | null }) => {
	const auth = useAuthContext()

	return (
		<>
			{auth.canManageUsers() && item === SettingsItem.Users && <Users />}
			{auth.canManageLOVs() && item === SettingsItem.Acronyms && <Acronyms />}
			{auth.canManageLOVs() && item === SettingsItem.Templates && <Templates />}
			{auth.canManageLOVs() && item === SettingsItem.Naming && <Naming />}
			{auth.canManageLOVs() && item === SettingsItem.BusinessDomains && (
				<BusinessDomains />
			)}
			{auth.canManageLOVs() && item === SettingsItem.Domains && <Domains />}
			{auth.canManageLOVs() && item === SettingsItem.TechnicalColumns && (
				<TechnicalColumns />
			)}
			{auth.canManageLOVs() && item === SettingsItem.ObjectSettings && (
				<ObjectSettings />
			)}
			{auth.canManageLOVs() && item === SettingsItem.StereoTypes && (
				<StereoTypes />
			)}
			{auth.canManageLOVs() && item === SettingsItem.CustomAttributes && (
				<CustomAttributes />
			)}
			{auth.canManageLOVs() && item === SettingsItem.CustomLovs && (
				<CustomLov />
			)}
			{item === SettingsItem.ScriptEditor && <ScriptEditor />}
		</>
	)
}
