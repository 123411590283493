/* eslint-disable react/display-name */
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { ReleaseDto, StructureDto } from '@/api/models'
import { Button, Loader, DialogWrapper } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Release } from './components/Release'
import { Flex } from '@/components/Layout/Flex'
import { ThCell, Empty } from '@/components/Layout'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { TabsActions } from '../../../../components/TabActions'
import { useApi } from '@/api/hooks'
import { getSystemReleases } from '@/api'
import { AddRelease } from './components/AddRelease'
import { Container } from '../../../TableDetail/components/StyledComponents'
import { saveSystem, updateSystem } from '@/store/modules/system/actions'
import { ReleaseDetail } from './components/ReleaseDetail'
import { OpenedSystemData } from '@/store/modules/system/types'

type Props = {
	node: StructureDto
	editMode: boolean
	data: OpenedSystemData
}

export const Releases = ({ node, editMode, data }: Props) => {
	const { t } = useAppContext()

	const dispatch = useAppDispatch()

	const selectedReleaseId = data.form.selectedReleaseId

	const { data: releases, loading, invalidate } = useApi(
		getSystemReleases(node.id)
	)

	const handleEdit = useCallback(
		(release: ReleaseDto) => {
			dispatch(
				updateSystem(node, {
					selectedReleaseId: release.id
				})
			)

			if (editMode) {
				dispatch(saveSystem(node))
			}
		},
		[dispatch, node, editMode]
	)

	const reloadReleases = useCallback(() => {
		invalidate()
	}, [invalidate])

	return (
		<>
			{selectedReleaseId ? (
				<ReleaseDetail
					node={node}
					selectedReleaseId={selectedReleaseId}
					editMode={editMode}
					reloadReleases={reloadReleases}
				/>
			) : (
				<>
					<TabsActions>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<AddRelease
										onClose={() => {
											reloadReleases()
											onClose()
										}}
										node={node}
									/>
								)
							}
						>
							{onClick => (
								<Button
									icon={faPlus}
									onClick={onClick}
									schema="success"
									coloredIcon={true}
									disabled={!editMode}
								>
									{t('ADD_NEW_RELEASE')}
								</Button>
							)}
						</DialogWrapper>
					</TabsActions>

					<Container>
						<Loader loaded={!loading} absolute />

						<Flex align="stretch">
							<ThCell>{t('NAME')}</ThCell>
							<TextCell>{t('DESCRIPTION')}</TextCell>
							<Actions />
						</Flex>

						{releases?.map(release => (
							<Release
								key={release.id}
								node={node}
								release={release}
								onEdit={handleEdit}
								editMode={editMode}
								reloadReleases={reloadReleases}
							/>
						))}

						{(releases?.length ?? 0) === 0 && (
							<Empty>{t('NOTHING_HERE')}</Empty>
						)}
					</Container>
				</>
			)}
		</>
	)
}

const Actions = styled.div`
	width: 40px;
`

const TextCell = styled(ThCell)`
	flex: 5;
	text-overflow: ellipsis;
	white-space: nowrap;
`
