import React, {
	useContext,
	useReducer,
	createContext,
	useMemo,
	useEffect
} from 'react'
import {
	ActionTypeEnum,
	DetailTabContextState,
	DetailTabContextType
} from './types'
import { reducer } from './reducer'

const DetailTabContext = createContext<DetailTabContextType | undefined>(
	undefined
)

export const useDetailTabContext = () =>
	useContext(DetailTabContext) as DetailTabContextType

type Props = {
	children: React.ReactNode
	initialState: DetailTabContextState
}

export const DetailTabContextProvider = ({ children, initialState }: Props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const context = useMemo<DetailTabContextType>(
		() => ({
			state,
			dispatch
		}),
		[state, dispatch]
	)

	useEffect(() => {
		dispatch({
			type: ActionTypeEnum.DETAIL_TAB_UPDATE,
			update: initialState
		})
	}, [initialState])

	return (
		<DetailTabContext.Provider value={context}>
			{children}
		</DetailTabContext.Provider>
	)
}
