import { StructureDto } from '@/api/models'
import { Overview as OverviewApiNode } from '@/pages/User/pages/Home/pages/ApiNode/pages/Overview/Overview'
import { Overview as OverviewFolder } from '@/pages/User/pages/Home/pages/FolderDetail/pages/Overview/Overview'
import { Overview as OverviewMapping } from '@/pages/User/pages/Home/pages/MappingDetail/pages/Overview/Overview'
import { Overview as OverviewQueue } from '@/pages/User/pages/Home/pages/QueueDetail/pages/Overview/Overview'
import { Overview as OverviewReadMapping } from '@/pages/User/pages/Home/pages/ReadMappingDetail/pages/Overview/Overview'
import { Overview as OverviewWriteMapping } from '@/pages/User/pages/Home/pages/WriteMappingDetail/pages/Overview/Overview'
import { Overview as OverviewSystem } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Overview/Overview'
import { Overview as OverviewTable } from '@/pages/User/pages/Home/pages/TableDetail/pages/Overview/Overview'
import { Overview as OverviewView } from '@/pages/User/pages/Home/pages/ViewDetail/pages/Overview/Overview'
import { Overview as OverviewWorkflow } from '@/pages/User/pages/Home/pages/WorkflowDetail/pages/Overview/Overview'
import { TableMode } from '@/store/modules/table/types'
import { useAppStore } from '@/utils/hooks'
import {
	StructureTypeActions,
	useStructureTypeActions
} from '@/utils/structureType/useStructureTypeActions'
import { useMemo } from 'react'
import { TYPE_TO_SELECTOR } from '../constants'

export type PropsTypeGeneric = {
	readOnly: boolean
	editMode: boolean
	defaultMapping: boolean
	data: any
	systemNodeId: number
	onChange: (v: any) => void
	node?: StructureDto
	/** Selected nodes for batch edit */
	selectedNodes?: StructureDto[]

	mode: TableMode.TABLE
	unsyncedFields: any[]
	onSync: () => void
	modeForm: any
	modeFormTable: any
	[key: string]: any
}

type FormInfo = {
	formData: Record<string, any>
	FormComponent: (props: PropsTypeGeneric) => JSX.Element
}

type TypeData = FormInfo & StructureTypeActions

/** Get specific node type form data & component and redux actions */
export const useTypeData = (type: StructureDto.TypeEnum) => {
	const selector = useMemo(() => TYPE_TO_SELECTOR[type], [type])
	const formData = useAppStore(selector)
	const structureTypeActions = useStructureTypeActions(type)

	const typeData = useMemo<TypeData>(() => {
		let formInfo: Partial<FormInfo> = {
			FormComponent: OverviewWorkflow
		}

		switch (type) {
			case StructureDto.TypeEnum.API_NODE:
				formInfo = {
					FormComponent: OverviewApiNode
				}

				break
			case StructureDto.TypeEnum.FOLDER:
				formInfo = {
					FormComponent: OverviewFolder
				}

				break
			case StructureDto.TypeEnum.MAPPING:
			case StructureDto.TypeEnum.MAPPING_DEFAULT:
				formInfo = {
					FormComponent: OverviewMapping
				}

				break
			case StructureDto.TypeEnum.MAPPING_READ:
				formInfo = {
					FormComponent: OverviewReadMapping
				}

				break
			case StructureDto.TypeEnum.MAPPING_WRITE:
				formInfo = {
					FormComponent: OverviewWriteMapping
				}

				break
			case StructureDto.TypeEnum.QUEUE:
				formInfo = {
					FormComponent: OverviewQueue
				}

				break
			case StructureDto.TypeEnum.SYSTEM:
				formInfo = {
					FormComponent: OverviewSystem
				}

				break
			case StructureDto.TypeEnum.TABLE:
				formInfo = {
					FormComponent: OverviewTable
				}

				break
			case StructureDto.TypeEnum.VIEW:
				formInfo = {
					FormComponent: OverviewView
				}

				break
			case StructureDto.TypeEnum.WORKFLOW:
				formInfo = {
					FormComponent: OverviewWorkflow
				}

				break
			default:
				console.error('Unknown structure type')

				formInfo = {
					FormComponent: OverviewWorkflow
				}
		}

		return {
			...formInfo,
			...structureTypeActions,
			formData
		} as TypeData
	}, [formData, structureTypeActions, type])

	return typeData
}
