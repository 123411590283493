export enum SearchEnum {
	SYSTEM_NODE = 'SYSTEM_NODE',
	FOLDER = 'FOLDER',
	WORKFLOW = 'WORKFLOW',
	TABLE = 'TABLE',
	MAPPING = 'MAPPING',
	VIEW = 'VIEW',
	API = 'API',
	QUEUE = 'QUEUE'
}

export enum TypeEnum {
	PK_FK_TABLE = 'PK_FK_TABLE',
	PK_FK_COLUMN = 'PK_FK_COLUMN',
	LINK = 'LINK',
	LINK_COLUMN = 'LINK_COLUMN',
	CUSTOM_LINK = 'CUSTOM_LINK'
}
