import React from 'react'
import { useAppContext } from '@/utils/hooks'
import { Button, LightTable, Loader } from '@/components'
import { Container } from '@/components/Container'
import { faList, faPlus } from '@fortawesome/free-solid-svg-icons'
import { CustomLovModal } from './components/CustomLovModal'
import { DialogWrapper } from '@/components'
import { CustomLovDto } from '@/api/models'
import { useApi } from '@/api/hooks'
import { getAllCustomLovs } from '@/api'
import {
	TitleContainer,
	TitleText,
	TitleIcon,
	TitleActions
} from '../../styles'
import { useCustomLovColumns } from './hooks/useCustomLovColumns'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'

export const CustomLov = () => {
	const { t } = useAppContext()
	const customLov = useApi(getAllCustomLovs())

	const handleSave = useSettingsPageSaveHandler(customLov)

	const columns = useCustomLovColumns(handleSave)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faList} />
				<TitleText>{t('CUSTOM_LISTS_OF_VALUES')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <CustomLovModal onSave={handleSave} onClose={onClose} />
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>

			<Container>
				<Loader loaded={!customLov.loading} absolute />

				<LightTable<CustomLovDto>
					data={customLov.data ?? []}
					columns={columns}
					keyField={'id'}
					isLoading={customLov.loading}
				/>
			</Container>
		</>
	)
}
