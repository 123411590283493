import { NewStructureDto } from '@/api/models'
import { DialogWrapper } from '@/components'
import { nodeRefreshTree, selectModeChange } from '@/store/modules/node/actions'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useAuthContext
} from '@/utils/hooks'
import {
	faCheck,
	faFileImport,
	faPlus,
	faSortAlphaDown,
	faSync
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { AddModal } from '../../AddModal'
import { TREE_PANEL_ORDER } from '../utils'
import { ImportStructureModal } from './ImportStructureModal'

type Props = {
	alphabet: boolean
	setAlphabet: (value: boolean) => void
}

export const Header = ({ alphabet, setAlphabet }: Props) => {
	const { t } = useAppContext()
	const auth = useAuthContext()

	const dispatch = useAppDispatch()

	const selectMode = useAppStore(store => store.node.selectMode)

	const [refreshing, setRefreshing] = useState(false)

	const handleRefresh = useCallback(async () => {
		setRefreshing(true)
		await dispatch(nodeRefreshTree())
		setRefreshing(false)
	}, [dispatch])

	const changeSelectMode = (value: boolean) => dispatch(selectModeChange(value))

	return (
		<Title role="heading">
			<TitleActions>
				<ToggleButton
					isChecked={selectMode}
					onClick={() => {
						changeSelectMode(!selectMode)
					}}
				>
					<FontAwesomeIcon icon={faCheck} title="Select mode" />
				</ToggleButton>

				<ToggleButton
					title="Alphabetical order"
					isChecked={alphabet}
					onClick={() => {
						setAlphabet(!alphabet)
						localStorage.setItem(TREE_PANEL_ORDER, !alphabet ? 'true' : 'false')
					}}
				>
					<FontAwesomeIcon icon={faSortAlphaDown} title="Alphabetical order" />
				</ToggleButton>

				<TitleAction title={t('REFRESH')} onClick={handleRefresh} role="button">
					<FontAwesomeIcon icon={faSync} spin={refreshing} />
				</TitleAction>
				{auth.canCreateSystem() && (
					<>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<AddModal
										type={NewStructureDto.TypeEnum.SYSTEM}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<TitleAction
									title={t('CREATE_SYSTEM')}
									onClick={onClick}
									role="button"
								>
									<FontAwesomeIcon icon={faPlus} />
								</TitleAction>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<ImportStructureModal
										onClose={onClose}
										title={t('IMPORT_SYSTEM')}
									/>
								)
							}
						>
							{onClick => (
								<TitleAction
									title={t('IMPORT_SYSTEM')}
									onClick={onClick}
									role="button"
								>
									<FontAwesomeIcon icon={faFileImport} />
								</TitleAction>
							)}
						</DialogWrapper>
					</>
				)}
			</TitleActions>
		</Title>
	)
}

const Title = styled.div`
	padding: 8px 12px;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	background: ${props =>
		props.theme.colors.layout.treePanel.headerBackgroundColor};
	text-transform: uppercase;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
`

const TitleActions = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
`

const TitleAction = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 8px;

	:hover {
		opacity: 0.8;
	}
`

const ToggleButton = styled.div<{ isChecked?: boolean }>`
	margin: 0;
	cursor: pointer;
	padding: 3px;
	margin: -3px 0;
	border-radius: 4px;
	margin-left: 4px;
	width: 14px;
	text-align: center;

	:hover {
		opacity: 0.8;
	}

	${props => css`
		opacity: ${props.isChecked ? 1.0 : 0.5};
		color: ${props.isChecked ? 'white' : 'initial'};
		background: ${props.isChecked ? '#265560' : 'initial'};
		box-shadow: ${props.isChecked ? '0px 0px 2px 0px #265560' : 'initial'};
	`}
`
