import React, { useCallback } from 'react'
import { Form } from '@/components/UberForm'
import { Container } from '@/components/Container'
import { useAppContext } from '@/utils/hooks'
import { QueueData } from '@/api/schemas'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { OpenedQueueData } from '@/store/modules/queue/types'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'

interface Props {
	data: OpenedQueueData
	editMode: boolean
	onChange: (v: Partial<QueueData>) => void
}

export const PhysicalOptions = ({ data, onChange, editMode }: Props) => {
	const { t } = useAppContext()

	const handleChange = useCallback(
		(item: Partial<QueueData>, source?: FormlessFormField<QueueData>) => {
			if (source) {
				const name = source.props.name

				onChange({
					[name]: item[name]
				})
			}
		},
		[onChange]
	)

	return (
		<Container>
			<Form<QueueData>
				onChange={handleChange}
				defaultValues={data.form}
				disabled={!editMode}
				enableFieldHighlight
			>
				<AceEditorField
					name="script"
					title={t('SCRIPT')}
					initialValue={data.form.script}
				/>

				<AceEditorField
					name="preScript"
					title={t('PRE_SCRIPT')}
					initialValue={data.form.preScript}
				/>

				<AceEditorField
					name="postScript"
					title={t('POST_SCRIPT')}
					initialValue={data.form.postScript}
				/>
			</Form>
		</Container>
	)
}
