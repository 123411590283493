/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { getRowValue } from '../helpers'
import { LightTableColumn, RowCallback } from '../types'
import Cell from './Cell'
import styled, { css } from 'styled-components'
import { RowOrderer } from './RowOrderer'
import { ITEM_HEIGHT } from '@/components/Properties/tableProperties/constants'

type Props<T> = {
	trClassName?: string | RowCallback<T, string>
	trStyle?: React.CSSProperties | RowCallback<T, React.CSSProperties>
	isExpandable?: boolean
	isExpanded?: boolean
	onRowClick?: (row: T) => void
	columns: { column: LightTableColumn<T>; style: React.CSSProperties }[]
	onRowExpand?: (row: T, index: number) => void

	row: T
	rowIndex: number
	key?: string
	position?: number
	width?: string
	scrollLeft?: number

	isRowOrderable: { up?: boolean; down?: boolean; enabled: boolean }
	onRowOrderChanged?: (oldIndex: number, newIndex: number) => void
	onDragChanged?: (isDragging: boolean) => void
	onRowDrop?: (dropIndex: number) => void
	isDragging?: boolean
	isDragOver?: boolean
	onDragOver?: (index: number) => void
	dragArrowVisible?: boolean
}

const Row = <T extends any>({
	row,
	rowIndex,
	trClassName,
	isExpandable,
	isExpanded,
	position,
	trStyle,
	onRowClick,
	columns,
	onRowExpand,
	width,
	scrollLeft = 0,
	isRowOrderable,
	onRowOrderChanged,
	onDragChanged,
	onRowDrop,
	isDragOver,
	isDragging,
	onDragOver,
	dragArrowVisible = true
}: Props<T>) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const [isRowDragging, setIsRowDragging] = useState(false)

	const handleOrderDragStart = (e: React.DragEvent<HTMLDivElement>) => {
		onDragChanged?.(true)

		if (containerRef.current) {
			e.dataTransfer.setDragImage(containerRef.current, 0, 0)
		}

		setIsRowDragging(true)
	}

	const handleOrderDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
		onDragChanged?.(false)

		setIsRowDragging(false)

		e.preventDefault()
	}

	const handleOrderDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		if (
			onDragOver &&
			isRowOrderable.enabled &&
			(isRowOrderable.up || isRowOrderable.down)
		) {
			onDragOver(rowIndex)
		}

		e.stopPropagation()
		e.preventDefault()
	}

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		if (
			onRowDrop &&
			isRowOrderable.enabled &&
			(isRowOrderable.up || isRowOrderable.down)
		) {
			onRowDrop(rowIndex)
		}

		e.preventDefault()
	}

	return (
		<Container
			isRowDragging={isRowDragging}
			isDragOver={isDragOver && isDragging}
			role="row"
			onDrop={handleDrop}
			onDragOver={handleOrderDragOver}
			ref={containerRef}
			className={cn(
				'div-tr',
				getRowValue(trClassName, row, rowIndex),
				isExpandable && (isExpanded ? 'expanded' : 'collapsed')
			)}
			style={{
				...(position !== undefined && {
					position: 'absolute',
					transform: `translateY(${position}px)`,
					minWidth: width
				}),
				...getRowValue(trStyle, row, rowIndex)
			}}
			onClick={onRowClick && (() => onRowClick(row))}
		>
			{isRowOrderable.enabled && onRowOrderChanged && (
				<RowOrderer
					dragArrowVisible={dragArrowVisible}
					onOrderDragStart={handleOrderDragStart}
					onOrderDragEnd={handleOrderDragEnd}
					onRowOrderChanged={onRowOrderChanged}
					index={rowIndex}
					isRowOrderable={isRowOrderable}
				/>
			)}
			{columns.map(({ column, style }, index) => {
				const value = column.field ? row[column.field] : null

				return (
					<Cell
						key={index.toString()}
						column={column}
						style={style}
						value={value}
						row={row}
						rowIndex={rowIndex}
						isExpandable={isExpandable}
						onRowExpand={onRowExpand}
						scrollLeft={scrollLeft}
					/>
				)
			})}
		</Container>
	)
}

const Container = styled.div<{
	isDragOver?: boolean
	isRowDragging: boolean
}>`
	display: flex;
	align-items: stretch;
	height: ${ITEM_HEIGHT}px;
	position: relative;

	${props =>
		props.isRowDragging &&
		css`
			background: #f0f0ff;
		`}

	${props =>
		props.isDragOver &&
		!props.isRowDragging &&
		css`
			background: #f0f0f0;
		`}
`

export default React.memo(Row) as typeof Row
