import { updateDataNode } from '@/api'
import { StructureDetailDto, StructureDto } from '@/api/models'
import { HistoryTableData, TableColumn, TableData } from '@/api/schemas'
import { StoreState } from '@/store'
import {
	apiCallAction,
	AppDispatch,
	thunkAction,
	UpdateDeepPartial
} from '@/store/utils'
import { setTimestampStart } from '../helpers'
import { loadStereotypeColumns } from '../stereotype/actions'
import {
	TABLE_INIT,
	TABLE_SAVE,
	TABLE_SELECT_TAB,
	TABLE_SYNC_FIELD,
	TABLE_UPDATE,
	TABLE_UPDATE_HISTORY_COLUMNS,
	TABLE_UPDATE_STEREOTYPES_COLUMNS
} from './constants'
import { columnFromTechnicalColumn } from './helpers'
import { TableMode, TableTab } from './types'
import { callNaming, CallNamingParams } from './utils/callNaming'
import { getTableData } from './utils/getTableData'
import { Timestamp } from '../actionsTimestamps/index'
import { getCanGenerateNaming } from '@/components/UberForm/Input/Naming/getCanGenerateNaming'
import { loadNodeOrHistoryVersion } from '../node/utils'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'

interface InitTable {
	type: typeof TABLE_INIT
	node: StructureDetailDto
	editMode: boolean
	mode: TableMode
	force: boolean
}

interface UpdateTable {
	type: typeof TABLE_UPDATE
	node: StructureDto
	update: UpdateDeepPartial<TableData>
	mode: TableMode
}

interface SaveTable {
	type: typeof TABLE_SAVE
	payload: void
	metadata: {
		node: StructureDto
	}
}

interface SelectTableTab {
	type: typeof TABLE_SELECT_TAB
	node: StructureDto
	tab: TableTab
}

interface TableSyncField {
	type: typeof TABLE_SYNC_FIELD
	node: StructureDto
	field: keyof HistoryTableData
	mode: TableMode
}

type TableUpdateStereotypeColumns = {
	type: typeof TABLE_UPDATE_STEREOTYPES_COLUMNS
	node: StructureDto
	stereotypeColumns: TableColumn[]
} & Timestamp

type TableUpdateHistoryColumns = {
	type: typeof TABLE_UPDATE_HISTORY_COLUMNS
	node: StructureDto
	historyColumns: TableColumn[]
	historyFormColumns: TableColumn[]
} & Timestamp

export const initTable = ({
	nodeId,
	editMode = false,
	mode = TableMode.TABLE,
	force = false,
	version,
	envId
}: InitDataParams) => async (dispatch: AppDispatch) => {
	const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

	dispatch({
		type: TABLE_INIT,
		node,
		editMode,
		mode,
		force
	} as InitTable)
}

export const updateSequenceCode = (
	node: StructureDto,
	update: UpdateDeepPartial<TableData>,
	mode: TableMode,
	callNamingParams: CallNamingParams
) => async (dispatch: AppDispatch, getState: () => StoreState) => {
	const table = getState().table.tables[node.id]

	if (!table) {
		throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
	}

	if (update.sequenceColumnCode == null) {
		update.sequenceCode = ''
	} else {
		const sequenceCode = await dispatch(callNaming(callNamingParams))

		update.sequenceCode = sequenceCode.result
	}

	dispatch({
		type: TABLE_UPDATE,
		node,
		update,
		mode
	})
}

export const updateColumnsWithHistoryColumns = (
	node: StructureDto,
	technologyId: number,
	hasHistoryTable: boolean
) => async (dispatch: AppDispatch, getState: () => StoreState) => {
	const timestamp = setTimestampStart(
		dispatch,
		TABLE_UPDATE_HISTORY_COLUMNS,
		node.id
	)

	const table = getState().table.tables[node.id]

	if (!table) {
		throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
	}

	if (!hasHistoryTable) {
		dispatch({
			type: TABLE_UPDATE_HISTORY_COLUMNS,
			node,
			historyColumns: [],
			historyFormColumns: [],
			timestamp
		})
	} else {
		const historyStereotype = getState().stereotype.history

		if (historyStereotype?.id === undefined) {
			throw new Error(`History stereotype not found`)
		}

		const historyStereotypeColumns = await dispatch(
			loadStereotypeColumns(historyStereotype.id, technologyId)
		)

		const withOrder = historyStereotypeColumns.map(c =>
			columnFromTechnicalColumn(c)
		)

		dispatch({
			type: TABLE_UPDATE_HISTORY_COLUMNS,
			node,
			historyColumns: withOrder,
			historyFormColumns: withOrder,
			timestamp
		})
	}
}

export const updateStereotypeColumns = (
	node: StructureDto,
	technologyId: number,
	stereotypeId?: number
) =>
	thunkAction(async (dispatch, getState) => {
		const timestamp = setTimestampStart(
			dispatch,
			TABLE_UPDATE_STEREOTYPES_COLUMNS,
			node.id
		)

		const table = getState().table.tables[node.id]
		const existingStereotypeColumns = table?.stereotypeColumns

		if (!table) {
			throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
		}

		const stereotypeColumns =
			stereotypeId === undefined
				? []
				: await dispatch(loadStereotypeColumns(stereotypeId, technologyId))

		const mappedStereotypeColumns = stereotypeColumns.map(s =>
			columnFromTechnicalColumn(s, existingStereotypeColumns)
		)

		dispatch({
			type: TABLE_UPDATE_STEREOTYPES_COLUMNS,
			stereotypeColumns: mappedStereotypeColumns,
			node: node,
			timestamp
		})
	})

export const saveTable = (node: StructureDto) =>
	thunkAction(async (dispatch, getState) => {
		const table = getState().table.tables[node.id]

		if (!table) {
			throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
		}

		const canGenerateNaming = await getCanGenerateNaming(node.id)

		const formData = await getTableData(
			dispatch,
			node,
			table,
			canGenerateNaming
		)

		await dispatch(
			apiCallAction<SaveTable>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(formData)
					}),
				TABLE_SAVE,
				{ node }
			)
		)
	})

export const updateTable = (
	node: StructureDto,
	update: UpdateDeepPartial<TableData>,
	mode = TableMode.TABLE
): Actions => ({
	type: TABLE_UPDATE,
	node,
	update,
	mode
})

export const selectTableTab = (node: StructureDto, tab: TableTab): Actions => ({
	type: TABLE_SELECT_TAB,
	node,
	tab
})

export const syncTableField = (
	node: StructureDto,
	field: keyof HistoryTableData,
	mode: TableMode
): Actions => ({
	type: TABLE_SYNC_FIELD,
	node,
	field,
	mode
})

export type Actions =
	| InitTable
	| SaveTable
	| UpdateTable
	| SelectTableTab
	| TableSyncField
	| TableUpdateStereotypeColumns
	| TableUpdateHistoryColumns
