import React, { useState, useRef, useCallback } from 'react'
import SimpleBar from 'simplebar-react'
import {
	faChevronLeft,
	faChevronRight,
	faCaretSquareDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import {
	useAppStore,
	useWindowEvent,
	useDocumentEvent,
	useAppDispatch
} from '@/utils/hooks'
import { StructureDto } from '@/api/models'
import { HeadItem } from './HeadItem'
import { Tooltip } from '@/components'
import { Position } from '@/components/Tooltip/Tooltip'
import { isNotUndefined } from '@/utils/collections'
import { closeTab } from '@/store/modules/tab/actions'
import { OpenedTab } from '@/store/modules/tab/types'

type Props = {
	onConfirm: (nodes: StructureDto[]) => void
}

export const Head = ({ onConfirm }: Props) => {
	const dispatch = useAppDispatch()
	const nodes = useAppStore(state => state.node.nodes)
	const openedTabs = useAppStore(state => state.tab.openedTabs)
	const selectedTab = useAppStore(state => state.tab.selectedTab)
	const temporaryTab = useAppStore(state => state.tab.temporaryTab)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const scrollbarRef = useRef<any>(null)
	const scrollingInterval = useRef<ReturnType<typeof setTimeout>>()

	const [menuShown, setMenuShown] = useState(false)

	const handleCloseAll = useCallback(() => {
		handleCloseMultiple(openedTabs)
	}, [openedTabs, nodes])

	const handleCloseOther = useCallback(
		(node: StructureDto) => {
			handleCloseMultiple(openedTabs.filter(tab => tab.nodeId !== node.id))
		},
		[openedTabs, nodes]
	)

	const handleCloseMultiple = useCallback(
		(tabs: OpenedTab[]) => {
			onConfirm(
				tabs
					.filter(tab => tab.editMode)
					.map(tab => nodes[tab.nodeId])
					.filter(isNotUndefined)
			)

			tabs
				.filter(tab => !tab.editMode)
				.forEach(tab => dispatch(closeTab(tab.nodeId)))
		},
		[nodes]
	)

	// Starts button-invoked scrolling
	const startScrolling = useCallback((offset: number) => {
		stopScrolling()

		scrollingInterval.current = setInterval(() => {
			const el: HTMLDivElement = scrollbarRef.current?.getScrollElement()
			el.scrollLeft = el.scrollLeft + offset
		}, 10)
	}, [])

	const stopScrolling = useCallback(() => {
		if (scrollingInterval.current) {
			clearInterval(scrollingInterval.current)
			scrollingInterval.current = undefined
		}
	}, [])

	/*
		Stop button-invoked scrolling when user releases mouse
			- having mouseup on the button won't work when user moves mouse outside of button
	*/
	useWindowEvent('mouseup', () => {
		stopScrolling()
	})

	const handleScrollLeft = useCallback(() => {
		startScrolling(-5)
	}, [])

	const handleScrollRight = useCallback(() => {
		startScrolling(+5)
	}, [])

	// Hide tabs menu when user clicks outside
	useDocumentEvent('click', () => {
		setMenuShown(false)
	})

	return (
		<TabsHead>
			<SimpleBar ref={scrollbarRef} style={{ overflowY: 'hidden' }}>
				<TabsList role="tablist">
					{openedTabs.map((tab, index) => (
						<HeadItem
							key={index}
							node={nodes[tab.nodeId]}
							tab={tab}
							isSelected={index === selectedTab}
							isTemporary={index === temporaryTab}
							onConfirm={onConfirm}
							onCloseOther={handleCloseOther}
							onCloseAll={handleCloseAll}
						/>
					))}
				</TabsList>
			</SimpleBar>
			<TabsControls>
				<TabsControl onMouseDown={handleScrollLeft}>
					<FontAwesomeIcon icon={faChevronLeft} />
				</TabsControl>
				<TabsControl onMouseDown={handleScrollRight}>
					<FontAwesomeIcon icon={faChevronRight} />
				</TabsControl>
				<TabsControl
					onClick={e => {
						e.nativeEvent.stopImmediatePropagation()
						setMenuShown(!menuShown)
					}}
				>
					<TabsTooltip
						showOnHover={false}
						disableStyle
						shown={menuShown}
						position={Position.BottomLeft}
						content={
							<TabsMenu>
								{openedTabs.map((tab, index) => (
									<HeadItem
										key={index}
										node={nodes[tab.nodeId]}
										tab={tab}
										isSelected={index === selectedTab}
										isTemporary={index === temporaryTab}
										isVertical
										onConfirm={onConfirm}
										onCloseOther={handleCloseOther}
										onCloseAll={handleCloseAll}
									/>
								))}
							</TabsMenu>
						}
					>
						<FontAwesomeIcon icon={faCaretSquareDown} />
					</TabsTooltip>
				</TabsControl>
			</TabsControls>
		</TabsHead>
	)
}

const TabsTooltip = styled(Tooltip)``

const TabsList = styled.div`
	display: flex;
	z-index: 0;
	flex: 1;
	position: relative;
`

const TabsHead = styled.div`
	position: relative;
	padding-right: 75px;
	border-bottom: 1px solid ${props => props.theme.colors.layout.borderColor};
`

const TabsControls = styled.div`
	display: flex;
	position: absolute;
	right: 0;
	top: 0;
	height: 30px;
	background: #f0f0f0;
	border-left: 1px solid ${props => props.theme.colors.layout.borderColor};
	border-top: 1px solid ${props => props.theme.colors.layout.borderColor};
`

const TabsControl = styled.div`
	cursor: pointer;
	padding: 0 8px;
	display: flex;
	align-items: center;
	color: #999;
`

const TabsMenu = styled.div`
	border: 1px solid ${props => props.theme.colors.layout.borderColor};
	box-sizing: border-box;
	overflow: auto;
	max-height: inherit;
	min-width: 220px;
	box-shadow: ${props => props.theme.styles.rightMenusShadows};
`
