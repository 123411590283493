import { WorkflowData } from '@/api/schemas'

export const getInitialWorkflowData = (
	name: string,
	code = ''
): WorkflowData => ({
	name,
	code,
	graph: {
		executeReadNodes: [],
		executeWriteNodes: [],
		parallelMergeNodes: [],
		parallelStartNodes: [],
		links: []
	}
})
