import React, { useMemo } from 'react'
import { getViewColumnProperties } from './Properties'
import { createEmptyViewColumn } from '@/store/modules/view/helpers'
import { useAppContext, useAppStore } from '@/utils/hooks'
import { ListEditTab } from '@/components'
import { duplication } from './validation'
import { ColumnsComponentProps } from './types'
import { getParsedGenerateTableColumnCode } from './utils'

export const ColumnsComponent = ({
	node,
	data,
	editMode,
	onChange
}: ColumnsComponentProps) => {
	const { t } = useAppContext()
	const folders = useAppStore(state => state.folder.folders)
	const parentStructureId = node?.parentStructureId
	const parentFolderFormState = folders[parentStructureId as number]?.form
	const generateTableColumnCode = parentFolderFormState?.generateTableColumnCode

	const errors = useMemo(() => duplication(data.form.columns || [], t), [
		data.form.columns
	])

	const properties = getViewColumnProperties(
		getParsedGenerateTableColumnCode(generateTableColumnCode),
		t
	)

	return (
		<ListEditTab
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptyViewColumn}
			itemsKey={'columns'}
			idCounterKey={'columnsLastId'}
			onChange={onChange}
			properties={properties}
			tabKey="column"
			errors={errors}
		/>
	)
}

export const Columns = ColumnsComponent
