import { StructureDto, TableColumn, NewStructureDto, Table } from '@/api/models'
import { AppDispatch } from '@/store/utils'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'
import { SqlTypeEnum } from '@/typings/enum/SqlTypeEnum'
import { getDefaultObjectSettings } from '../../objectSettings'
import {
	inflateColumns,
	defaultReferenceTableData,
	defaultHistoryTableData,
	inflateTablePermission
} from '../helpers'
import { OpenedTableData } from '../types'
import { callNamingWorkingData } from './callNaming'

export const getTableData = async (
	dispatch: AppDispatch,
	node: StructureDto,
	table: OpenedTableData,
	canGenerateNaming: boolean
) => {
	const nonEmptyColumns = table.form.columns
		.filter(x => x.name)
		.map(col => inflateColumns(col))

	let maxId = Math.max(0, ...nonEmptyColumns.map(c => c.id)) + 1

	const inflatedTechnicalColums = (table.stereotypeColumns || [])
		.map(col => inflateColumns(col))
		.map(
			col =>
				({
					...col,
					id: maxId++,
					stereotypeColumn: true
				} as TableColumn)
		)

	const columns = [...nonEmptyColumns, ...inflatedTechnicalColums]

	columns.forEach(column => {
		if (column.stereotype?.code !== 'VIRTUAL_COLUMN') {
			delete column.virtualColumnScript
		}
	})

	const formData = {
		...table.form,
		columns,
		indexes: table.form.indexes.filter(x => x.name),
		constraints: table.form.constraints.filter(x => x.name),
		objectPermissions: table.form.objectPermissions
			.filter(x => x.userName)
			.map(x => inflateTablePermission(x))
	}

	// Check reference table data
	if (formData.hasReferenceTable) {
		if (!formData.referenceTable) {
			formData.referenceTable = defaultReferenceTableData()
		}

		if (
			!formData.referenceTable.code?.length &&
			!formData.referenceTable.objectSettings?.length
		) {
			// load default object setting on first init
			const osDefault = await dispatch(
				getDefaultObjectSettings(NewStructureDto.TypeEnum.TABLE)
			)

			formData.referenceTable.objectSettings = osDefault ? [osDefault] : []
		}

		if (canGenerateNaming) {
			const sharedConfig = [
				node.id,
				SqlTypeEnum.TABLE_REFERENCE,
				{
					master_node_name: formData.name,
					master_node_code: formData.code,
					body: { data: JSON.stringify(formData.referenceTable) }
				}
			] as const

			const namings = [
				dispatch(
					callNamingWorkingData([
						NamingDtoTypeEnum.REFERENCE_TABLE_NAME,
						...sharedConfig
					])
				),
				dispatch(
					callNamingWorkingData([
						NamingDtoTypeEnum.REFERENCE_TABLE_CODE,
						...sharedConfig
					])
				)
			]

			try {
				const namingsResults = await Promise.all(namings)

				formData.referenceTable = {
					...formData.referenceTable,
					name: namingsResults[0]?.result,
					code: namingsResults[1]?.result
				}
			} catch (e) {
				console.error(e)
			}
		}
	}

	// Check history table data
	if (formData.hasHistoryTable) {
		if (!formData.historyTable) {
			formData.historyTable = defaultHistoryTableData()
		}

		if (
			!formData.historyTable.code?.length &&
			!formData.historyTable.objectSettings?.length
		) {
			// load default object setting on first init
			const osDefault = await dispatch(
				getDefaultObjectSettings(NewStructureDto.TypeEnum.TABLE)
			)

			formData.historyTable.objectSettings = osDefault ? [osDefault] : []
		}

		if (canGenerateNaming) {
			const sharedConfig = [
				node.id,
				SqlTypeEnum.TABLE_HISTORY,
				{
					master_node_name: formData.name,
					master_node_code: formData.code,
					body: { data: JSON.stringify(formData.historyTable) }
				}
			] as const

			const namings = [
				dispatch(
					callNamingWorkingData([
						NamingDtoTypeEnum.HISTORICAL_TABLE_NAME,
						...sharedConfig
					])
				),
				dispatch(
					callNamingWorkingData([
						NamingDtoTypeEnum.HISTORICAL_TABLE_CODE,
						...sharedConfig
					])
				)
			]

			try {
				const namingsResults = await Promise.all(namings)

				formData.historyTable = {
					...formData.historyTable,
					name: namingsResults[0]?.result,
					code: namingsResults[1]?.result
				}
			} catch (e) {
				console.error(e)
			}
		}

		// InflateColumns in historyTable
		const nonEmptyColumns =
			formData.historyTable?.columns
				?.filter(x => x.name)
				.map(col => inflateColumns(col)) ?? []

		formData.historyTable.columns = nonEmptyColumns
	}

	return formData as Table
}
