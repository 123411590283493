export const NODE_LOAD_SYSTEM = 'NODE_LOAD_SYSTEM'
export const NODE_LOAD_CHILDREN = 'NODE_LOAD_CHILDREN'
export const NODE_LOAD_NESTED_CHILDREN = 'NODE_LOAD_NESTED_CHILDREN'
export const NODE_EXPAND = 'NODE_EXPAND'
export const NODE_COLLAPSE = 'NODE_COLLAPSE'
export const NODE_ADD = 'NODE_ADD'
export const NODE_ADD_SYSTEM = 'NODE_ADD_SYSTEM'
export const NODE_DELETE = 'NODE_DELETE'
export const NODE_LOCK = 'NODE_LOCK'
export const NODE_UNLOCK = 'NODE_UNLOCK'
export const NODE_LOAD = 'NODE_LOAD'
export const NODE_IMPORT_STATE = 'NODE_IMPORT_STATE'
export const NODE_LOAD_PERMISSIONS = 'NODE_LOAD_PERMISSIONS'
export const NODE_REFRESH_TREE = 'NODE_REFRESH_TREE'
export const NODE_SELECT = 'NODE_SELECT'
export const NODE_DESELECT = 'NODE_DESELECT'
export const NODE_SELECT_MODE_CHANGE = 'NODE_SELECT_MODE_CHANGE'
export const NODE_LOAD_HISTORY_VERSION = 'NODE_LOAD_HISTORY_VERSION'
