import React from 'react'
import ReactSelect from 'react-select'
import { compareFlatArrays } from '@/utils/collections'
import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { FormValue } from '../../Form'
import { FormatOptionLabelMeta } from 'react-select/src/Select'
import styled, { css } from 'styled-components'
import { EnhancedPureComponentWithContext } from '@/components/EnhancedComponents'
import { nextFrame } from '@/utils/async'
import { colors } from '@/styles'
import CustomComponentsOptions from '@/utils/react-select-customization'

export interface SelectProps<T> extends FormFieldContext {
	autoFocus?: boolean
	placeholder?: string
	className?: string
	labelKey?: string
	valueKey?: string
	noResultText?: string
	options?: T[]
	valueRenderer?: (value: T) => React.ReactNode
	multi?: boolean
	openMenuOnFocus?: boolean
	onMenuClose?: () => void
	onMenuOpen?: () => void
	allowEmpty?: boolean
	clearable?: boolean
	isNumeric?: boolean
	formatOptionLabel?: (
		option: T,
		labelMeta: FormatOptionLabelMeta<T, boolean>
	) => React.ReactNode
	onCoerseValue?: (
		oldValue: readonly T[] | T | undefined | null,
		newValue: readonly T[] | T | undefined | null
	) => readonly T[] | T | undefined | null
	refInput?: React.MutableRefObject<any>
	onFocus?: () => void
	onBlur?: () => void
	onInputChange?: (newValue: string) => void
}

const SELECT_CLASSNAME_PREFIX = 'react-select'
export const SELECT_MENU_CLASSNAME = SELECT_CLASSNAME_PREFIX + '__menu'

export class SelectWithoutForm<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends Record<string, any>
> extends EnhancedPureComponentWithContext<SelectProps<T>> {
	static defaultProps = {
		labelKey: 'label',
		valueKey: 'value',
		clearable: true,
		allowEmpty: true
	}
	input = React.createRef<ReactSelect>()
	async componentDidMount() {
		const { register, refInput } = this.props

		if (refInput && refInput !== this.input) {
			this.input = refInput
		}

		if (register) {
			register(this)
		}

		await nextFrame()

		if (this.input.current && this.props.autoFocus) {
			this.input.current.focus()
		}
	}

	getValueKey = () => this.props.valueKey || 'value'
	getLabelKey = () => this.props.labelKey || 'label'

	componentWillReceiveProps(nextProps: SelectProps<T>) {
		const { options, multi, allowEmpty, loading, onChange } = this.props
		const { value } = nextProps

		// Skip value checks if we're still loading
		if (nextProps.loading) {
			return
		}

		if (
			nextProps.loading !== loading ||
			nextProps.options !== options ||
			nextProps.value !== this.props.value ||
			(!value && !allowEmpty)
		) {
			let updatedValue = value

			if (multi) {
				if (value && Array.isArray(value)) {
					updatedValue = nextProps.options
						? value.filter(item =>
								(nextProps.options as T[]).find(
									option => this.getOptionValue(option) === item
								)
						  )
						: []
				}
			} else {
				updatedValue =
					value === null || value === undefined
						? value
						: nextProps.options &&
						  (nextProps.options.some(
								option => this.getOptionValue(option) === value
						  )
								? value
								: allowEmpty
								? undefined
								: nextProps.options[0] &&
								  this.getOptionValue(nextProps.options[0]))
			}

			if (
				updatedValue !== value &&
				(!multi ||
					!Array.isArray(updatedValue) ||
					!Array.isArray(value) ||
					!compareFlatArrays(updatedValue, value))
			) {
				onChange && onChange(updatedValue)
			}
		}
	}

	recap = (value: FormValue) => {
		const { multi, options } = this.props

		if (!options) {
			return undefined
		}

		const keys = (multi ? value : [value]) as string[]

		const items = keys
			? options
					.filter(option => keys.includes(this.getOptionValue(option)))
					.map(option => option[this.getLabelKey()])
			: []

		return items.join(', ')
	}

	onChange = (newValue: readonly T[] | T | undefined | null) => {
		const {
			multi,
			options,
			allowEmpty,
			onChange,
			onCoerseValue,
			value
		} = this.props

		if (onCoerseValue && options) {
			const oldValue = multi
				? value
					? (value as [string]).map(
							val =>
								options.filter(option => this.getOptionValue(option) === val)[0]
					  )
					: []
				: value
				? options.filter(
						option => this.getOptionValue(option) === (value as string)
				  )
				: undefined

			newValue = onCoerseValue(oldValue, newValue)
		}

		onChange &&
			onChange(
				multi
					? newValue
						? (newValue as T[]).map(option => this.getOptionValue(option))
						: []
					: newValue
					? this.getOptionValue(newValue as T)
					: allowEmpty
					? undefined
					: options && options[0] && this.getOptionValue(options[0])
			)
	}

	getOptionValue = (opt: T): string => {
		const value =
			opt[this.getValueKey()] !== undefined && opt[this.getValueKey()] !== null
				? opt[this.getValueKey()]
				: null

		return !value || this.props.isNumeric ? value : value.toString()
	}

	getOptionLabel = (opt: T) => {
		let label = opt[this.getLabelKey()]

		if (opt.wasDeleted) {
			label += ' !'
		}

		return label
	}

	getNoOptionsMessage = () =>
		this.props.noResultText
			? this.props.noResultText
			: this.context.t('FILTER_NO_RESULT_TEXT')

	render() {
		const { t } = this.context

		const {
			placeholder,
			className,
			name,
			options,
			value,
			id,
			multi,
			disabled,
			clearable,
			formatOptionLabel,
			compact,
			openMenuOnFocus,
			onMenuClose,
			onMenuOpen,
			onFocus,
			onBlur,
			refInput,
			onInputChange
		} = this.props

		const selectedValue = (multi
			? options
					?.filter(opt => {
						const values = value ? (Array.isArray(value) ? value : [value]) : []

						return values.includes(this.getOptionValue(opt))
					})
					.filter(v => !!v) ?? null
			: options?.find(opt => this.getOptionValue(opt) === value) ?? null) as any

		return (
			<div
				role="listbox"
				onKeyDown={e => {
					if (e.key === 'Enter') {
						e.stopPropagation()
						e.preventDefault()
					}
				}}
			>
				<StyledReactSelect
					ref={refInput ?? this.input}
					className={className}
					styles={{
						menuPortal: (base: React.CSSProperties) => ({
							...base,
							zIndex: 999
						}),
						option: (provided: any, state: any) => {
							const { base, light } = colors.primary

							return {
								...provided,
								background: state.isSelected
									? base
									: state.isFocused
									? light
									: '#fff',
								':hover': {
									...provided[':hover'],
									background: state.isSelected ? '#000' : light
								},
								':active': {
									...provided[':active'],
									background: state.isSelected ? '#000' : light
								}
							}
						}
					}}
					compact={compact}
					classNamePrefix={SELECT_CLASSNAME_PREFIX}
					inputId={id}
					name={name}
					options={options}
					closeMenuOnSelect={!multi}
					isDisabled={disabled}
					isMulti={multi}
					isClearable={clearable}
					placeholder={
						!disabled
							? placeholder !== undefined
								? placeholder
								: multi
								? t('FILTER_ALL_PLACEHOLDER')
								: ''
							: ''
					}
					noOptionsMessage={this.getNoOptionsMessage}
					hideSelectedOptions={false}
					onChange={this.onChange}
					value={selectedValue as any}
					components={{ ...CustomComponentsOptions }}
					getOptionValue={this.getOptionValue}
					getOptionLabel={this.getOptionLabel}
					menuPortalTarget={document.body}
					menuPlacement="auto"
					formatOptionLabel={formatOptionLabel}
					openMenuOnFocus={openMenuOnFocus}
					onMenuClose={onMenuClose}
					onMenuOpen={onMenuOpen}
					onFocus={onFocus}
					onBlur={onBlur}
					onInputChange={onInputChange}
				/>
			</div>
		)
	}
}

export default withFormField(SelectWithoutForm) as typeof SelectWithoutForm

const StyledReactSelect = (styled(ReactSelect)<{
	compact: boolean
	isDisabled?: boolean
	isClearable?: boolean
}>`
	${props => css`
		.react-select__control {
			min-height: auto;
			border-color: ${props.theme.colors.input.border};
			border-radius: ${props.theme.input.borderRadius};

			:focus {
				border-color: ${props.theme.colors.primary.base};

				box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
				.react-select__indicator {
					color: ${props.theme.colors.primary.base};
				}
			}
			:hover {
				&:not(:focus) {
					border-color: ${props.compact
						? `${props.theme.colors.input.compact.horizontalBorder} ${props.theme.colors.input.compact.verticalBorder}`
						: props.theme.colors.input.border};
				}
				box-shadow: ${props.isDisabled
					? 'none'
					: `0 0 3px ${props.theme.colors.primary.shadowColor}`};
				.react-select__indicator {
					color: ${props.theme.colors.primary.base};
				}
			}
			.react-select__placeholder {
				color: ${props.theme.colors.input.placeholder};
			}
			.react-select__value-container {
				margin-right: ${props.isClearable ? '55px' : '26px'};
			}
		}
		.react-select__indicators {
			position: absolute;
			top: 50%;
			right: 0;
			.react-select__indicator {
				padding: 8px 4px;
			}
			transform: translate(0, -50%);
			height: 100%;
		}
		.Select-input {
			height: 24px;
		}

		.Select-input > input {
			line-height: 22px;
		}

		.Select-value {
			line-height: 1;
			margin-bottom: -1px;
			margin-top: 0;
		}

		.react-select__single-value {
			overflow: initial;
		}
		span.Select-arrow-zone {
			top: 1px;
		}
	`}

	${props =>
		props.compact &&
		css`
			div.react-select__control {
				min-height: auto;
				border-radius: 0;
				border-color: ${props.theme.colors.input.compact.horizontalBorder}
					${props.theme.colors.input.compact.verticalBorder};
				border-width: 0;
				box-shadow: none;
				:hover {
					box-shadow: none;
				}

				div.react-select__value-container {
					margin-right: ${props.isClearable ? '38px' : '18px'};
				}
			}

			.react-select__indicators {
				div.react-select__indicator {
					padding: 0;
				}
			}

			.react-select__indicator-separator {
				width: 0;
			}
			.Select-input {
				height: 20px;
			}

			.Select-input > input {
				line-height: 16px;
			}

			.react-select__value-container {
				top: 0px;
				white-space: nowrap;
				flex-wrap: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0 1px 0 1px;
				padding: 1px 3px;

				.react-select__multi-value {
					transition: 0.2s all;
					&:hover {
						flex-shrink: 0;
					}
				}
			}
		`}
` as unknown) as typeof ReactSelect
