import React from 'react'

import { splitProps } from '@/utils/collections'
import FormField, { FormFieldProps, FormlessFormField } from '../FormField'
import Select, { SelectProps } from '../Input/Select/Select'

/**
 * Form field with select input.
 */
export default class SelectFormField<T> extends React.PureComponent<
	FormFieldProps &
		Omit<SelectProps<T>, 'onChange'> & {
			formless?: boolean
		}
> {
	render() {
		const fieldProps = { ...this.props }

		const inputProps = splitProps(fieldProps, [
			'valueKey',
			'labelKey',
			'options',
			'allowEmpty',
			'multi',
			'placeholder',
			'clearable',
			'formatOptionLabel',
			'isNumeric',
			'autoFocus',
			'openMenuOnFocus',
			'onMenuClose',
			'refInput',
			'onFocus',
			'onBlur',
			'onInputChange'
		]) as SelectProps<T>

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps}>
				<Select {...inputProps} />
			</FF>
		)
	}
}
