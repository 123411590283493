import { TranslationHelper } from '@/context/Locale/Locale'
import { ItemPropertyType } from '@/components/Properties/types'

export const getViewColumnProperties = (
	hasGenerateTableColumnCode: boolean,
	t: TranslationHelper
) => {
	return [
		{
			field: 'name',
			label: t('VIEW_COLUMNS_NAME'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			propertiesWidth: '50%',
			showWhenPanelOpened: true
		},
		{
			field: 'code',
			label: t('VIEW_COLUMNS_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			flex: 1,
			propertiesWidth: '50%',
			showWhenPanelOpened: true,
			disabled: hasGenerateTableColumnCode,
			disableGenerateCode: !hasGenerateTableColumnCode
		},
		{
			field: 'description',
			label: t('VIEW_COLUMNS_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 1.5
		},
		{
			field: 'comment',
			label: t('VIEW_COLUMNS_COMMENT'),
			type: ItemPropertyType.TEXTAREA,
			flex: 1.5
		},
		{
			field: 'mapping',
			label: t('VIEW_COLUMNS_MAPPING'),
			type: ItemPropertyType.ACE_EDITOR,
			flex: 1
		}
	]
}
