/* eslint-disable @typescript-eslint/no-explicit-any */
import { StructureDto } from '@/api/models'
import {
	ItemProperty,
	PropertiesChangeCallback,
	PropertiesErrorProps
} from '@/components/Properties/types'
import { setTabPropertyItem } from '@/store/modules/tab/actions'
import { UpdateDeepPartial } from '@/store/utils'
import { arrayMoveIndex } from '@/utils/collections'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import React, {
	useCallback,
	useEffect,
	useRef,
	useMemo,
	CSSProperties
} from 'react'
import { Container } from '../../pages/User/pages/Home/pages/TableDetail/components/StyledComponents'
import { PanelProperties } from '../Properties/PanelProperties'
import { TableProperties } from '../Properties/TableProperties'

type Props<
	T extends object,
	I extends object,
	IK extends Extract<keyof T, string>,
	ICK extends Extract<keyof T, string>,
	IDK extends Extract<keyof I, string>,
	EK extends Extract<keyof I, string>
> = {
	data: T
	node: StructureDto
	editMode: boolean
	className?: string
	style?: CSSProperties
	onChange: (v: UpdateDeepPartial<T>, keyChange?: string) => void
	properties: ItemProperty<I>[]
	itemsKey: IK
	idCounterKey: ICK
	idKey?: IDK
	existsKey?: any
	createEmpty: (items: I[]) => I
	isRowOrderable?: boolean
	onRowOrderChanged?: (oldIndex: number, newIndex: number) => void
	tabKey: string
	isReadonly?: (item: I) => boolean
	errors?: PropertiesErrorProps
	children?:
		| React.ReactNode
		| ((
				propertiesHidden: boolean,
				columnWidths: Record<string, number>
		  ) => React.ReactNode)
	/** When item in table / properties is selected */
	onSelect?: (item: I) => void
	panelPropertiesMinWidth?: number
	panelPropertiesDefaultOpen?: boolean
	disablePanelProperties?: boolean
}

export const ListEditTab = <
	T extends object,
	I extends object,
	IK extends Extract<keyof T, string>,
	ICK extends Extract<keyof T, string>,
	IDK extends Extract<keyof I, string>,
	EK extends Extract<keyof I, string>
>({
	children,
	properties,
	data,
	node,
	editMode,
	className,
	style,
	onChange,
	itemsKey,
	createEmpty,
	tabKey,
	isReadonly,
	isRowOrderable = true,
	idKey = 'id' as any,
	existsKey = 'name' as any,
	idCounterKey,
	errors,
	onSelect,
	panelPropertiesMinWidth,
	panelPropertiesDefaultOpen,
	disablePanelProperties
}: Props<T, I, IK, ICK, IDK, EK>) => {
	const dispatch = useAppDispatch()
	const refWrapper = useRef<HTMLDivElement>(null)

	const tab = useAppStore(store =>
		store.tab.openedTabs.find(t => t.nodeId === node.id)
	)

	const selectedItemIndex = useMemo(
		() => ((tab || { properitesItems: {} }).properitesItems || {})[tabKey],
		[tab, tabKey]
	)

	const itemsForm = useMemo(() => data[itemsKey] || [], [data, itemsKey]) as I[]

	const items = useMemo(() => {
		// Make sure there's always at least one empty row
		if (
			editMode &&
			(!itemsForm ||
				itemsForm.length === 0 ||
				(itemsForm[itemsForm.length - 1] as any)[existsKey])
		) {
			return [...(itemsForm || []), createEmpty(itemsForm || [])]
		}

		return itemsForm
	}, [createEmpty, editMode, existsKey, itemsForm])

	const handleChange: PropertiesChangeCallback<I> = useCallback(
		(item, key, value) => {
			const originalIndex = (items || []).findIndex(
				c => c[idKey] === item[idKey]
			)
			if (originalIndex >= 0 && !value?.customAttributes) {
				const update: UpdateDeepPartial<T> = {
					[itemsKey]: {
						[originalIndex]: {
							[idKey]: item[idKey],
							[key]: value
						}
					}
				} as any

				// Add new empty row, when the previous one is used
				const updatedRows = update[itemsKey] as UpdateDeepPartial<I>

				if (
					updatedRows &&
					originalIndex === items.length - 1 &&
					key === existsKey &&
					value
				) {
					;(updatedRows as any)[originalIndex] = {
						...createEmpty(items),
						...(updatedRows as any)[originalIndex]
					}

					// Generate new ID
					const newId = ((data[idCounterKey] as unknown) as number) + 1 ?? 1

					;(updatedRows as any)[originalIndex][idKey] = newId

					;(update as any)[idCounterKey] = newId
				}

				onChange(update, key)
			}

			if (originalIndex >= 0 && value?.customAttributes) {
				const updatedValues: UpdateDeepPartial<T> = {
					[itemsKey]: {
						[originalIndex]: {
							...value
						}
					}
				} as any

				onChange(updatedValues, key)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onChange, items]
	)

	const handleRowOrderChanged = (oldIndex: number, newIndex: number) => {
		const reordered = arrayMoveIndex(itemsForm, oldIndex, newIndex)

		onChange({
			[itemsKey]: reordered
		} as any)
	}

	const handleDelete = useCallback(
		(item: I) => {
			const newItems = itemsForm
				? itemsForm.filter(c => c[idKey] !== item[idKey])
				: []

			onChange({
				[itemsKey]: newItems
			} as any)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onChange, items]
	)

	const handleSelect = useCallback(
		(index: number) => {
			dispatch(setTabPropertyItem(node, tabKey, index))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[node, tabKey, idKey]
	)

	const selectedItem = useMemo(
		() =>
			selectedItemIndex !== undefined ? items[selectedItemIndex] : undefined,
		[items, selectedItemIndex]
	)

	useEffect(() => {
		onSelect && items[selectedItemIndex] && onSelect(items[selectedItemIndex])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItemIndex])

	const resolveIsDeletable = (item: I, itemIndex: number) => {
		if (itemIndex + 1 < items.length) {
			return true
		}

		return !!(item as any)[existsKey]
	}

	const getTable = (propertiesWidth: number, propertiesHidden: boolean) => {
		const width = refWrapper?.current?.getBoundingClientRect().width

		return (
			<div ref={refWrapper} style={{ height: '100%' }}>
				<Container
					isRowOrderable={isRowOrderable && editMode}
					className={className || ''}
					style={{
						overflowX: 'hidden',
						width:
							propertiesHidden || !width
								? 'auto'
								: `${width - propertiesWidth}px`,
						paddingRight: propertiesHidden ? '32px' : 0,
						...(style || {})
					}}
				>
					<TableProperties<I>
						items={items}
						selectedItemIndex={selectedItemIndex}
						readonly={!editMode}
						properties={properties}
						onChange={handleChange}
						onSelect={handleSelect}
						onDelete={handleDelete}
						isDeletable={resolveIsDeletable}
						isRowOrderable={isRowOrderable}
						isReadonly={isReadonly}
						onRowOrderChanged={handleRowOrderChanged}
						errors={errors}
						propertiesHidden={propertiesHidden}
					>
						{children}
					</TableProperties>
				</Container>
			</div>
		)
	}

	if (disablePanelProperties) {
		return getTable(0, true)
	}

	return (
		<PanelProperties
			node={node}
			item={selectedItem}
			index={selectedItemIndex}
			properties={properties}
			listOfValues={items}
			readonly={!editMode}
			onChange={handleChange}
			errors={errors}
			tabKey={tabKey}
			onSelect={handleSelect}
			panelPropertiesMinWidth={panelPropertiesMinWidth}
			panelPropertiesDefaultOpen={panelPropertiesDefaultOpen}
		>
			{getTable}
		</PanelProperties>
	)
}
