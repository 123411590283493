import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { LightTableColumn } from '@/components/LightTable'
import styled from 'styled-components'
import { Button } from '@/components'
import { Container } from '@/components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faEdit,
	faTimes,
	faFont,
	faPlus
} from '@fortawesome/free-solid-svg-icons'
import { Flex } from '@/components/Layout'
import { PaginatedTable } from '@/components/PaginatedTable/PaginatedTable'
import { useAppContext } from '@/utils/hooks'
import { DialogWrapper, RemoveModal } from '@/components'
import { AcronymModal } from './components/AcronymModal'
import { getAcronyms, deleteAcronym } from '@/api'
import { useApi } from '@/api/hooks'
import { AcronymDto } from '@/api/models'
import { SearchBar, FilterValues } from '@/components/SearchBar/SearchBar'
import {
	TitleContainer,
	TitleIcon,
	TitleText,
	TitleActions
} from '../../styles'
import { columnsAudit, useSettingsPageSaveHandler } from '../../utils'

const PAGE_SIZE = 20

export const Acronyms = () => {
	const { t } = useAppContext()
	const [currentPage, setCurrentPage] = useState(1)
	const [filter, setFilter] = useState(undefined as FilterValues)

	const acronyms = useApi(
		getAcronyms({
			page: currentPage,
			size: PAGE_SIZE,
			searchQuery: filter || undefined
		})
	)

	const handleSave = useSettingsPageSaveHandler(acronyms)

	useEffect(() => {
		setCurrentPage(1)
	}, [filter])

	const handlePage = useCallback((page: number) => setCurrentPage(page), [
		setCurrentPage
	])

	const columns = useMemo<LightTableColumn<AcronymDto>[]>(
		() => [
			{
				field: 'text',
				title: t('ACRONYM_WORD'),
				width: 50
			},
			{
				field: 'acronym',
				title: t('ACRONYM'),
				width: 50
			},
			...columnsAudit(t),
			{
				field: '',
				width: 60,
				maxWidth: 60,
				// eslint-disable-next-line react/display-name
				formatter: (_, acronym) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<AcronymModal
										acronym={acronym}
										onSave={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<RemoveModal
										callback={deleteAcronym}
										textOnModal={t('REMOVE_DESC', [acronym.text])}
										onRemove={handleSave}
										onClose={onClose}
										header={t('REMOVE_ACRONYM')}
										removeId={acronym.id}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('REMOVE')}
										color="#d00"
										icon={faTimes}
									/>
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				)
			}
		],
		[handleSave]
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faFont} />
				<TitleText>{t('ACRONYMS')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <AcronymModal onSave={handleSave} onClose={onClose} />
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<SearchContainer>
					<SearchBar
						debounceTime={500}
						clearable={true}
						placeholder={t('SEARCH_PLACEHOLDER')}
						value={filter}
						setValue={setFilter}
					/>
				</SearchContainer>
				<PaginatedTable
					data={acronyms.data?.acronyms || []}
					columns={columns}
					keyField={'id'}
					currentPage={currentPage}
					onPage={handlePage}
					pages={acronyms.data?.totalPages as number}
					isLoading={acronyms.loading}
				/>
			</Container>
		</>
	)
}

const SearchContainer = styled.div`
	margin-bottom: 10px;
`
