import { createSystemRelease } from '@/api'
import { useApiRequest } from '@/api/hooks'
import { StructureDto } from '@/api/models'
import { ModalForm } from '@/components/Modal/ModalForm'
import { TextAreaFormField, TextFormField } from '@/components/UberForm'
import { useAppContext } from '@/utils/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

type Props = {
	node: StructureDto
	onClose: () => void
}

type FormData = {
	name: string
	description: string
}

export const AddRelease = ({ node, onClose }: Props) => {
	const { t } = useAppContext()

	const request = useApiRequest()

	const handleSubmit = async (form: Partial<FormData>) => {
		await request(
			createSystemRelease(node.id, {
				name: form.name,
				description: form.description
			})
		)

		onClose()
	}

	return (
		<ModalForm<FormData>
			saveTitle={t('CREATE')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '400px'
			}}
			header={t('ADD_NEW_RELEASE')}
		>
			<TextFormField
				title={t('NAME')}
				name="name"
				required
				autoFocus
				enableAutocomplete={false}
			/>

			<TextAreaFormField title={t('DESCRIPTION')} name="description" />
		</ModalForm>
	)
}
