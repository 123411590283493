import React, { useEffect, useMemo } from 'react'
import { StructureDto } from '@/api/models'
import {
	OpenedMappingData,
	MappingDataForm
} from '@/store/modules/mapping/types'
import { MappingColumnProperties } from './Properties'
import { createEmptyMappingColumn } from '@/store/modules/mapping/helpers'
import { useAppContext, useAppStore, useAppDispatch } from '@/utils/hooks'
import { initTable } from '@/store/modules/table/actions'
import { ListEditTab } from '@/components'
import { UpdateDeepPartial } from '@/store/utils'

type Props = {
	node: StructureDto
	data: OpenedMappingData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<MappingDataForm>) => void
}

export const Columns = ({ node, data, editMode, onChange }: Props) => {
	const { t } = useAppContext()

	const dispatch = useAppDispatch()
	const tables = useAppStore(state => state.table.tables)
	const parent = tables[node.parentStructureId as number]

	useEffect(() => {
		if (!parent && node.parentStructureId) {
			dispatch(initTable({ nodeId: node.parentStructureId }))
		}
	}, [node.parentStructureId])

	const items = data.form.columns || []

	const columnsMapping = parent?.form.columns
		? parent?.form.columns.filter(c => c.name)
		: []

	const columns = [
		...columnsMapping,
		...(parent?.stereotypeColumns ?? []),
		...(parent?.historyColumns ?? [])
	]

	const availableColumns = columns.filter(
		c => !items.find(item => item.columnCode == c.code)
	)

	const props = useMemo(
		() => MappingColumnProperties(t, columns, availableColumns),
		[t, columns, availableColumns]
	)

	return (
		<ListEditTab
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptyMappingColumn}
			itemsKey={'columns'}
			idCounterKey={'columnsLastId'}
			onChange={onChange}
			properties={props}
			tabKey="column"
			existsKey="columnCode"
		/>
	)
}
