import { Form, SelectFormField, TextFormField } from '@/components/UberForm'
import {
	FilterCell,
	FilterContainer
} from '@/pages/User/pages/Settings/components/Filter'
import { useAppContext } from '@/utils/hooks'
import React from 'react'

export type DependenciesFilterValues = {
	name?: string
	relationType?: string
	status?: string
}

type Props = {
	onChangeFilter: (values: DependenciesFilterValues) => void
	statusStates: { value: string, label: string }[]
	typeStates: { value: string, label: string }[]
}

export const DependenciesFilter = ({ onChangeFilter, statusStates, typeStates }: Props) => {
	const { t } = useAppContext()

	return (
		<Form<DependenciesFilterValues> onChange={onChangeFilter}>
			<FilterContainer>
				<FilterCell width={50}>
					<TextFormField title={t('NAME')} name="name" />
				</FilterCell>
				<FilterCell width={50} />
				<FilterCell width={50} />
				<FilterCell width={50}>
					<SelectFormField
						title={t('TYPE')}
						name="relationType"
						options={typeStates}
						clearable
					/>
				</FilterCell>
				<FilterCell width={50}>
					<SelectFormField
						title={t('STATUS')}
						name="status"
						options={statusStates}
						clearable
					/>
				</FilterCell>
				<FilterCell width={100} />
				<FilterCell width={60} maxWidth={60} />
			</FilterContainer>
		</Form>
	)
}
