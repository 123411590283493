import React, { useMemo } from 'react'
import { BatchEditContextProvider } from './context/BatchEditContext'
import { BatchEditSelectType } from './BatchEditSelectType'
import { useAppStore } from '@/utils/hooks'
import { StructureDto } from '@/api/models'

type Props = {
	onClose: () => void
	node: StructureDto
}

export const BatchEdit = ({ onClose, node }: Props) => {
	const selectedNodes = useAppStore(state => state.node.selectedNodes)

	return (
		<BatchEditContextProvider
			initialState={{
				formChanged: {},
				onClose,
				locking: false,
				submitting: false,
				errors: {},
				selectedNodes,
				node
			}}
		>
			<BatchEditSelectType />
		</BatchEditContextProvider>
	)
}
