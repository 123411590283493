import React from 'react'
import { DomainTechnologyDto, TechnologyDto } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import { TextFormField } from '@/components/UberForm'
import { Column, Row } from '../../../styles'
import { FormValue } from '@/components/UberForm/Form'
import styled from 'styled-components'

type Props = {
	technology: TechnologyDto
	domainTechnologies: DomainTechnologyDto[]
	onChange: (domainTechnology: DomainTechnologyDto) => void
}

export const DomainTechnologyRow = ({
	technology,
	domainTechnologies,
	onChange
}: Props) => {
	const { t } = useAppContext()

	const domainTechnology = domainTechnologies.find(
		x => x.technologyId === technology.id
	)

	const fieldName = (property: string) => `${technology.id}_${property}`

	const propertyName = (field: string) => field.split('_')[1]

	const handleChange = (value: FormValue, field: string) => {
		const property = propertyName(field)

		const updated = {
			...(domainTechnology ?? {
				technologyId: technology.id,
				technologyCode: technology.code
			}),
			[property]: value
		} as DomainTechnologyDto

		onChange(updated)
	}

	return (
		<Container>
			<Row>
				<Header>{technology.name}</Header>
				<Column width="40%">
					<TextFormField
						title={t('DOMAIN_DATA_TYPE')}
						initialValue={domainTechnology?.dataType}
						name={fieldName('dataType')}
						onChange={handleChange}
						formless
					/>
				</Column>
				<Column width="40%">
					<TextFormField
						title={t('DOMAIN_DEFAULT_VALUE')}
						initialValue={domainTechnology?.defaultValue}
						name={fieldName('defaultValue')}
						onChange={handleChange}
						formless
					/>
				</Column>
			</Row>
		</Container>
	)
}

const Container = styled.div`
	margin: 10px 0;
`

const Header = styled.h3`
	font-size: 120%;
	width: 20%;
	align-self: center;
`
