import { CsvImportReturnDto, CsvImportRowDto } from '@/api/models'
import { getDuplicates } from './getDuplicates'

export const getRowsWithoutDuplicates = (
	createAnywayTableNames: string[],
	rows?: CsvImportRowDto[],
	checkResult?: CsvImportReturnDto | null
) => {
	const duplicates = getDuplicates(checkResult)

	const rowsWithoutDuplicates = rows?.filter(
		row =>
			!duplicates?.includes(row.table.name) ||
			createAnywayTableNames.includes(row.table.name)
	)

	return rowsWithoutDuplicates
}
