import React, { useCallback, useMemo } from 'react'
import { ReleaseDto, StructureDto, StructureObjectDto } from '@/api/models'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { DeleteModal } from '../DeleteModal'
import { DialogWrapper } from '@/components'
import { Flex, Cell, ActionsCell } from '@/components/Layout'
import { deleteReleaseStructure } from '@/api'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { openTab } from '@/store/modules/tab/actions'

type Props = {
	node: StructureDto
	release: ReleaseDto
	object: StructureObjectDto
	editMode: boolean
	reloadObjects: () => void
}

export const ReleaseObject = ({
	node,
	release,
	object,
	editMode,
	reloadObjects
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const nodeObject = useAppStore(state => state.node.nodes[object.structureId!])

	const deleteRequest = useMemo(
		() => deleteReleaseStructure(node.id, release.id!, object.structureId!),
		[node.id, release.id]
	)

	const onEdit = useCallback(() => {
		if (nodeObject) {
			dispatch(openTab(nodeObject, false))
		}
	}, [dispatch, nodeObject])

	return (
		<Flex align="stretch">
			<Cell>{object.name}</Cell>
			<Cell>{object.code}</Cell>
			<Cell>{object.type}</Cell>
			<TextCell title={object.fullPath}>{object.fullPath}</TextCell>
			<Actions>
				<Action onClick={onEdit}>
					<FontAwesomeIcon icon={faEdit} />
				</Action>
				{editMode && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<DeleteModal
									object={object}
									deleteRequest={deleteRequest}
									onClose={() => {
										reloadObjects()
										onClose()
									}}
									saveTitle={t('REMOVE_ELEMENT_FROM_RELEASE')}
									description={t('REMOVE_ELEMENT_FROM_RELEASE_DESCRIPTION', [
										object.name ?? ''
									])}
								/>
							)
						}
					>
						{onClick => (
							<Action onClick={onClick}>
								<FontAwesomeIcon icon={faTimes} />
							</Action>
						)}
					</DialogWrapper>
				)}
			</Actions>
		</Flex>
	)
}

const Actions = styled(Flex)`
	width: 40px;
`

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`

const TextCell = styled(Cell)`
	flex: 2;
	text-overflow: ellipsis;
	white-space: nowrap;
`
