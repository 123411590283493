import React, { useState, useCallback, useMemo } from 'react'
import { Modal } from '@/components/Modal/Modal'
import { Button, Loader, Message, MessageModal } from '@/components'
import { useApiRequest, getApiRef } from '@/api/hooks'
import {
	generateSql,
	generateSqlPreviewEnvironment,
	createDeploy,
	getEnvironmentsWithLastDeploymentPerObjectType,
	generateAndPushToGit
} from '@/api'
import { StructureDto, EnvironmentDeploymentDto } from '@/api/models'
import styled from 'styled-components'
import {
	faTimes,
	faDownload,
	faDatabase,
	faServer
} from '@fortawesome/free-solid-svg-icons'
import FileSaver from 'file-saver'
import { useAppContext } from '@/utils/hooks'
import { useGeneratedSql } from '@/utils/sql'
import { DDL_MODAL_ID } from '@/constants'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { AceEditorWithoutForm } from '@/components/AceEditor/AceEditor'
import AutoSizer from 'react-virtualized-auto-sizer'

type Props = {
	node: StructureDto
	environment: EnvironmentDeploymentDto
	preview?: boolean
	drop?: boolean
	onClose: () => void
}

const DdlModalComponent = ({
	node,
	environment: environment,
	preview,
	drop,
	onClose
}: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const [successShown, setSuccessShown] = useState(false)
	const [deploying, setDeploying] = useState(false)
	const [pushingToGit, setPushingToGit] = useState(false)
	const [successGitShown, setSuccessGitShown] = useState(false)

	const [
		sqlPreview,
		loadingPreview,
		errorPreview
	] = useGeneratedSql(
		generateSqlPreviewEnvironment(
			node.id,
			environment.id,
			environment.objectType?.id!
		),
		[node.id, environment.id]
	)

	const [sqlProd, loadingProd, errorProd] = useGeneratedSql(
		generateSql(node.id, environment.id, environment.objectType?.id!),
		[node.id, environment.id]
	)

	const { sql, loading, error } = useMemo(
		() =>
			preview
				? { sql: sqlPreview, loading: loadingPreview, error: errorPreview }
				: { sql: sqlProd, loading: loadingProd, error: errorProd },
		[
			errorPreview,
			errorProd,
			loadingPreview,
			loadingProd,
			preview,
			sqlPreview,
			sqlProd
		]
	)

	const saveFile = useCallback(
		(fileName: string, blobPart: BlobPart[], type?: string) => {
			const blob = new Blob(blobPart, {
				type: type ?? 'text/plain;charset=utf-8'
			})

			FileSaver.saveAs(blob, fileName)
		},
		[]
	)

	const handleDownload = useCallback(() => {
		if (sql) {
			saveFile(`${sql.fileName ?? node.name}`, [sql.sql])
		}
	}, [sql, saveFile, node.name])

	const pushToGit = async () => {
		setPushingToGit(true)

		await request(
			generateAndPushToGit(node.id, environment.id, environment.objectType?.id!)
		)

		setPushingToGit(false)
		setSuccessGitShown(true)
	}

	const handleDeploy = async () => {
		if (!sql) {
			return
		}

		setDeploying(true)

		await request(
			createDeploy(node.id, environment.id, environment.objectType?.id!, {
				data: sql.sql,
				description: '-',
				structureHistoryId: sql.structureHistoryId,
				templateId: sql.templateId
			})
		)

		setDeploying(false)
		setSuccessShown(true)
	}

	const handleInfoClose = async () => {
		getApiRef(
			getEnvironmentsWithLastDeploymentPerObjectType(node.id)
		).invalidate()

		onClose()
	}

	return (
		<>
			<Modal
				open
				onClose={onClose}
				stretchFooterButtons={false}
				resizable
				maximized
				maximizeButtonVisible
				dialogId={DDL_MODAL_ID}
				header={
					drop ? t('DROP_TABLE') : preview ? t('PREVIEW_DDL') : t('CREATE_DDL')
				}
				contentStyle={{ width: 800, minHeight: 530, minWidth: 530 }}
				footer={
					<>
						{!preview && (
							<Button
								onClick={handleDeploy}
								icon={faDatabase}
								isLoading={deploying}
							>
								{t('MARK_AS_DEPLOYED')}
							</Button>
						)}
						<Button icon={faDownload} onClick={handleDownload}>
							{t('DOWNLOAD')}
						</Button>
						{!preview && (
							<Button
								onClick={pushToGit}
								icon={faServer}
								isLoading={pushingToGit}
							>
								{t('PUSH_TO_GIT')}
							</Button>
						)}
						<Button onClick={onClose} schema="transparent" icon={faTimes}>
							{t('CLOSE')}
						</Button>
					</>
				}
			>
				{error ? (
					<>
						<Message
							type="error"
							header={t('DDL_CREATION_FAILED')}
							message={error}
						/>
						{sql?.errors?.map(error => (
							<Message
								key={error}
								type="error"
								header={t('ERROR')}
								message={error}
							/>
						))}
					</>
				) : loading ? (
					<Loader loaded={false} />
				) : (
					<>
						{sql?.errors?.map(error => (
							<Message
								key={error}
								type="error"
								header={t('ERROR')}
								message={error}
							/>
						))}
						<AutoSizer>
							{({ width, height }) => (
								<div style={{ height: height, width }}>
									<AceEditorWithoutForm
										name="sqlData"
										value={sql?.sql}
										disabled
										height={height}
									/>
								</div>
							)}
						</AutoSizer>
					</>
				)}
			</Modal>

			<MessageModal
				type="info"
				opened={successShown}
				onClose={handleInfoClose}
				title={t('DEPLOYMENT')}
				message={t('DEPLOYMENT_MARK_CREATED')}
			/>

			<MessageModal
				type="info"
				opened={successGitShown}
				onClose={handleInfoClose}
				title={t('DEPLOYMENT_GIT')}
				message={t('DEPLOYMENT_GIT_PUSHED')}
			/>
		</>
	)
}

export const DdlModal = DdlModalComponent
