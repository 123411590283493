import React from 'react'
import { TableIndexColumn } from '@/api/schemas'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const SortIcon = ({
	sortType
}: {
	sortType: TableIndexColumn.SortTypeEnum
}) => (
	<OptionSortTypeIcon
		icon={
			sortType === TableIndexColumn.SortTypeEnum.ASC ? faArrowUp : faArrowDown
		}
		color={
			sortType === TableIndexColumn.SortTypeEnum.ASC ? '#28a745' : '#dc3545'
		}
	/>
)

const OptionSortTypeIcon = styled(FontAwesomeIcon)`
	margin-right: 4px;
`
