import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/utils/hooks'

type Props = {
	multiple?: boolean
	onFiles: (files: File[]) => void
}

const FileImportComponent = ({ multiple = false, onFiles }: Props) => {
	const { t } = useAppContext()
	const [dropping, setDropping] = useState(false)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files

		if (files) {
			if (multiple) {
				onFiles(Array.from(files))
			} else if (files.length > 0) {
				onFiles([files[0]])
			}
		}
	}

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		setDropping(false)

		const files = e.dataTransfer.files

		if (files) {
			if (multiple) {
				onFiles(Array.from(files))
			} else if (files.length > 0) {
				onFiles([files[0]])
			}
		}
	}

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		setDropping(true)
	}

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		setDropping(false)
	}

	return (
		<FileImportContainer
			dropping={dropping}
			onDrop={handleDrop}
			onDragEnter={handleDragOver}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDragEnd={handleDragLeave}
		>
			<DropIcon icon={faArrowDown} size="4x" />
			<UpLine>{t('DROP_FILE')}</UpLine>
			<CustomUpload>
				<FontAwesomeIcon icon={faFileUpload} />
				<span>{t('SELECT_FILE')}</span>
				<input type="file" onChange={handleChange} multiple={multiple} />
			</CustomUpload>
		</FileImportContainer>
	)
}

const FileImportContainer = styled.div<{ dropping: boolean }>`
	padding: 10px;
	border: 4px dashed #eee;
	text-align: center;

	${props =>
		props.dropping &&
		css`
			border: 4px solid #ccc;
		`}
`

const UpLine = styled.div`
	color: #bbb;
	margin-top: 5px;
	margin-bottom: 15px;
`

const DropIcon = styled(FontAwesomeIcon)`
	color: #ccc;
	border-bottom: 6px solid #ccc;
`

const CustomUpload = styled.label`
	width: 150px;
	margin: 10px auto 0px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	background: #ddd;
	border-radius: 3px;

	span {
		margin-left: 10px;
	}

	input[type='file'] {
		display: none;
	}
`

export const FileImport = FileImportComponent
