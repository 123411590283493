import { ViewData, ViewColumn } from '@/api/schemas'
import { ensureValidData } from '../helpers'

export const defaultViewData: Readonly<ViewData> = {
	columns: [],
	objectPermissions: [],
	sourceItems: [],
	sourceItemsLastId: 0
}

export const getInitialViewData = (name: string, code = '') =>
	ensureValidData(
		{
			name,
			code,
			description: '',
			columnsLastId: 0
		},
		defaultViewData
	)

export const createEmptyViewColumn = (columns: ViewColumn[]): ViewColumn => ({
	id: -1,
	order: Math.max(0, ...columns.map(c => c.order || 0)) + 1,
	name: '',
	code: ''
})
