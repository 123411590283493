import React, { useState, useEffect } from 'react'
import { withFormField } from '@/components/UberForm/FormFieldContext'
import { useAppContext } from '@/utils/hooks'
import { InputWithError } from '../InputWithError/InputWithError'
import { TextProps } from '../UberForm/Input/Text/Text'

export type RegExpProps = {
	sampleValue?: string
	expression?: RegExp
}
type Props = TextProps & RegExpProps

const RegExpValidatedInputComponent = ({
	sampleValue,
	expression,
	...textProps
}: Props) => {
	const { t } = useAppContext()
	const [error, setError] = useState(null as string | null)
	const { disabled, value } = textProps

	useEffect(() => {
		!disabled && value && expression && !expression.test(value)
			? setError(t('VALIDATOR_REGEXP', [sampleValue ?? expression.toString()]))
			: setError(null)
	}, [value, disabled, expression])

	return (
		<InputWithError {...textProps} placeholder={sampleValue} error={error} />
	)
}

export const RegExpValidatedInputFormless = React.memo(
	RegExpValidatedInputComponent
)

export const RegExpValidatedInput = withFormField(RegExpValidatedInputFormless)
