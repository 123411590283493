import { State } from '.'
import { UPDATE_SETTINGS } from './constants'

interface UpdateSettings {
	type: typeof UPDATE_SETTINGS
	payload: State
}

export const updateSettings = (opts: State): UpdateSettings => ({
	type: UPDATE_SETTINGS,
	payload: opts
})

export type Actions = UpdateSettings
