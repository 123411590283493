import { STEREOTYPE_LOAD, STEREOTYPE_LOAD_COLUMNS } from './constants'
import { StereotypeDto, TechnicalColumnStereotypeDto } from '@/api/models'
import { apiCallAction } from '@/store/utils'
import { getStereotypes, getTechnicalColumnStereotype } from '@/api'

interface LoadStereotypes {
	type: typeof STEREOTYPE_LOAD
	payload: StereotypeDto[]
	metadata: {
		type: StereotypeDto.TypeEnum
	}
}

interface LoadStereotypeColumns {
	type: typeof STEREOTYPE_LOAD_COLUMNS
	payload: TechnicalColumnStereotypeDto[]
	metadata: {
		stereotypeId: number
	}
}

export const loadStereotypes = (type: StereotypeDto.TypeEnum) =>
	apiCallAction<LoadStereotypes>(
		() => getStereotypes({ type }),
		STEREOTYPE_LOAD,
		{
			type
		}
	)

export const loadStereotypeColumns = (
	stereotypeId: number,
	technologyId: number
) =>
	apiCallAction<LoadStereotypeColumns>(
		() => getTechnicalColumnStereotype(stereotypeId, technologyId),
		STEREOTYPE_LOAD_COLUMNS,
		{ stereotypeId }
	)

export type Actions = LoadStereotypes | LoadStereotypeColumns
