import { getNodeHistoryDetailByVersion } from '@/api'
import { useApiRequest } from '@/api/hooks'
import { updateFormHighlights } from '@/store/modules/formHighlights/actions'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import { useEffect } from 'react'
import { useDetailTabContext } from '../components/DetailTab/context/DetailTabContext'
import Promise from 'bluebird'
import { StructureDto } from '@/api/models'
import { getFolderDataForm } from '@/store/modules/folder/helpers'
import { getSystemDataForm } from '@/store/modules/system/helpers'
import { getModeForm, getUnsyncedFields } from '../pages/TableDetail/utils'
import { TableMode } from '@/store/modules/table/types'
import { useIsMounted } from '@/utils/hooks/useIsMounted'

/** Update redux with current (latest) version element form data */
export const useFormHighlightsData = () => {
	const isMounted = useIsMounted()
	const dispatch = useAppDispatch()
	const request = useApiRequest()

	useEffect(() => {
		return () => {
			dispatch(updateFormHighlights({ active: false, prevVersionValues: null }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const {
		state: { node, version, versionActive, versionMax }
	} = useDetailTabContext()

	const mode = useAppStore(
		state => state.table.tables[node.id]?.mode ?? TableMode.TABLE
	)

	useEffect(() => {
		const call = new Promise(async (resolve, _, onCancel) => {
			let cancelled = false

			onCancel?.(() => {
				cancelled = true
			})

			if (
				(version === undefined && !versionActive) ||
				(version ?? 2) - 1 <= 0
			) {
				dispatch(
					updateFormHighlights({ active: false, prevVersionValues: null })
				)

				return resolve()
			}

			const targetVersion = version ? version : versionMax ? versionMax : 1

			const nodeData = await request(
				getNodeHistoryDetailByVersion(node.id, targetVersion - 1)
			)

			let prevVersionValues = JSON.parse(
				nodeData.data ? nodeData.data.data : '{}'
			)

			switch (node.type) {
				case StructureDto.TypeEnum.FOLDER: {
					prevVersionValues = getFolderDataForm(prevVersionValues)
					break
				}

				case StructureDto.TypeEnum.SYSTEM: {
					prevVersionValues = getSystemDataForm(prevVersionValues)
					break
				}

				case StructureDto.TypeEnum.TABLE: {
					const unsyncedFields = getUnsyncedFields(mode, prevVersionValues)
					const modeForm = getModeForm(mode, prevVersionValues, unsyncedFields)
					prevVersionValues = modeForm
					break
				}
			}

			if (cancelled) {
				return resolve()
			}

			if (targetVersion && isMounted()) {
				dispatch(updateFormHighlights({ active: true, prevVersionValues }))
			}

			resolve()
		})

		return () => {
			call.cancel()
		}
	}, [
		dispatch,
		mode,
		node.id,
		node.type,
		request,
		version,
		versionActive,
		versionMax,
		isMounted
	])
}
