import React from 'react'
import { useAppContext } from '@/utils/hooks'
import { Button, LightTable, Loader } from '@/components'
import { Container } from '@/components/Container'
import { faPlus, faWrench } from '@fortawesome/free-solid-svg-icons'
import { CustomAttributesModal } from './components/CustomAttributesModal'
import { DialogWrapper } from '@/components'
import { CustomAttributeDto } from '@/api/models'
import { useApi, useApiRequest } from '@/api/hooks'
import { getAllCustomAttributes, updateCustomAttribute } from '@/api'
import {
	TitleContainer,
	TitleText,
	TitleIcon,
	TitleActions
} from '../../styles'
import { useCustomAttributesColumns } from './hooks/useCustomAttributesColumns'
import { useFilter } from '../../hooks/useFilter'
import { useCustomAttributesFilteredData } from './hooks/useCustomAttributesFilteredData'
import {
	CustomAttributesFilter,
	CustomAttributesFilterValues
} from './components/CustomAttributesFilter'
import {
	rowOrderChangeHandler,
	usePageItemsSort,
	useSettingsPageSaveHandler
} from '@/pages/User/pages/Settings/utils'

export const CustomAttributes = () => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const customAttributes = useApi(getAllCustomAttributes())
	const { filter, onChangeFilter } = useFilter<CustomAttributesFilterValues>()
	const handleSave = useSettingsPageSaveHandler(customAttributes)

	const columns = useCustomAttributesColumns(
		useSettingsPageSaveHandler(customAttributes)
	)

	const sortedCustomAttributes = usePageItemsSort(customAttributes)

	const customAttributesFiltered = useCustomAttributesFilteredData(
		filter,
		sortedCustomAttributes
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faWrench} />
				<TitleText>{t('CUSTOM_ATTRIBUTES')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<CustomAttributesModal
									position={
										customAttributes.data ? customAttributes.data.length : 0
									}
									onSave={handleSave}
									onClose={onClose}
								/>
							)
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>

			<Container>
				<Loader loaded={!customAttributes.loading} absolute />
				<CustomAttributesFilter onChangeFilter={onChangeFilter} />
				<LightTable<CustomAttributeDto>
					dragArrowVisible={false}
					isRowOrderable
					onRowOrderChanged={(oldIndex, newIndex) =>
						rowOrderChangeHandler(
							oldIndex,
							newIndex,
							customAttributes,
							request,
							updateCustomAttribute
						)
					}
					data={customAttributesFiltered}
					columns={columns}
					keyField={'id'}
					isLoading={customAttributes.loading}
					style={{
						marginLeft: 15
					}}
				/>
			</Container>
		</>
	)
}
