import { getAllNodeHistory, getNodeDetail } from '@/api'
import { getApiRef } from '@/api/hooks'
import { pushHistory } from '@/store/modules/history/actions'
import {
	loadNodeChildren,
	lockNode,
	unlockNode
} from '@/store/modules/node/actions'
import { loadNode } from '@/store/modules/node/general-actions'
import { cancelEditingTab } from '@/store/modules/tab/actions'
import { nextFrame } from '@/utils/async'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import { isLockedByMe } from '@/utils/locking'
import { useStructureTypeActions } from '@/utils/structureType/useStructureTypeActions'
import { useCallback, useMemo, useState } from 'react'
import { useDetailTabContext } from '../context/DetailTabContext'

/** Replace working data (in edit mode) or push new version (in read mode) with data from older version   */
export const useReplaceVersionData = (
	changeVersion: (version: number) => void
) => {
	const [replacing, setReplacing] = useState(false)
	const user = useAppStore(state => state.auth.user)

	const {
		state: { node, editModeProp, version }
	} = useDetailTabContext()

	const dispatch = useAppDispatch()
	const { initData, saveData } = useStructureTypeActions(node.type)

	const afterReplace = useCallback(() => {
		changeVersion(0)
		setReplacing(false)
	}, [changeVersion])

	const replaceVersionData = useCallback(async () => {
		try {
			setReplacing(true)
			const isLockedbyMe = isLockedByMe(node, user)

			if (!node.lockTime) {
				await dispatch(lockNode(node))
			}

			const description = `Reverted from version ${version}`

			// replace working data
			await dispatch(
				initData({
					nodeId: node.id,
					editMode: true,
					version,
					force: true
				})
			)

			await nextFrame()
			await dispatch(saveData(node))

			if (editModeProp) {
				afterReplace()

				return
			}

			// push new version in read only mode
			await nextFrame()

			await dispatch(
				pushHistory(node, {
					description
				})
			)

			await dispatch(loadNodeChildren(node.id))
			await dispatch(loadNode(node.id))
			getApiRef(getAllNodeHistory(node.id)).invalidate()
			getApiRef(getNodeDetail(node.id)).invalidate()

			dispatch(cancelEditingTab(node))

			if (!isLockedbyMe) {
				await dispatch(unlockNode(node))
			}

			afterReplace()
		} catch (e) {
			setReplacing(false)
			throw e
		}
	}, [
		node,
		user,
		version,
		dispatch,
		initData,
		saveData,
		editModeProp,
		afterReplace
	])

	const info = useMemo(() => ({ replacing, replaceVersionData }), [
		replaceVersionData,
		replacing
	])

	return info
}
