import React, { useState, useMemo } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/utils/hooks'
import { useApi } from '@/api/hooks'
import {
	TextFormField,
	CheckBoxFormField,
	SelectFormField,
	TextAreaFormField,
	FormField
} from '@/components/UberForm'
import { getStereotypes, getAllDomainsForCombo } from '@/api'
import { TechnicalColumnDto, StereotypeDto } from '@/api/models'
import { FormValue } from '@/components/UberForm/Form'
import { CodeInput, Loader } from '@/components'
import styled from 'styled-components'
import { AuditForm } from '../../../components/AuditForm'
import { auditFieldsRemove, auditFieldsAdd } from '../../../utils'
import { excludeCustomDomains } from '@/utils/domain'
import { useConfirmationTechnicalColumn } from './hooks/useConfirmationTechnicalColumn'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { columnStereotypeValue } from '@/constants'

type Props = {
	techCol?: Partial<TechnicalColumnDto>
	onSave: () => void
	onClose: () => void
	position?: number
	stereotypes: StereotypeDto[]
}

interface State {
	dataType?: string
	defaultValue?: string
	domainId?: number
	notNull?: boolean
	nameValue?: string
}

export const TechColumnModal = ({
	techCol,
	onClose,
	onSave,
	position,
	stereotypes
}: Props) => {
	const { t } = useAppContext()

	const stereotypesColumn = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.COLUMN })
	)

	const domains = useApi(getAllDomainsForCombo())

	const domainsData = useMemo(() => excludeCustomDomains(domains.data), [
		domains.data
	])

	const getDomain = (id: number | undefined) =>
		domainsData.find(dom => dom.id === id)

	const initLocalValues = () => ({
		domainId: techCol?.domainId,
		notNull: getDomain(techCol?.domainId)?.notNullFlag,
		nameValue: techCol?.name
	})

	const [domainInheritData, setDomainInheritData] = useState<State>(
		initLocalValues()
	)

	const [selectedStereotypeValue, setSelectedStereotypeValue] = useState<
		undefined | number
	>(techCol?.stereotypeColumnId)

	const { onConfirmationTechnicalColumn } = useConfirmationTechnicalColumn()

	const handleSubmit = async (form: Partial<TechnicalColumnDto>) => {
		auditFieldsRemove(form)

		if (!form.ordering && typeof position === 'number') {
			form.ordering = position + 1
		}

		const postProcess = (dto: TechnicalColumnDto | null) => {
			if (dto) {
				onSave()
				onClose()
			}
		}

		await onConfirmationTechnicalColumn(form as TechnicalColumnDto, postProcess)
	}

	const onDomainChange = (id: FormValue) => {
		const domain = getDomain(id as number)

		setDomainInheritData({
			...domainInheritData,
			notNull: domain?.notNullFlag || undefined,
			domainId: id as number
		})
	}

	const onNameChange = (v: FormValue) => {
		setDomainInheritData({ ...domainInheritData, nameValue: v as string })
	}

	const onChange = (value: FormValue, field: string) => {
		setDomainInheritData({
			...domainInheritData,
			[field]: value
		})
	}

	if (stereotypesColumn.loading) {
		return <Loader loaded={false} absolute />
	}

	return (
		<>
			<ModalForm<TechnicalColumnDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: '40%',
					maxHeight: '85%',
					overflow: 'auto'
				}}
				initialValues={{
					id: techCol?.id,
					name: techCol?.name,
					code: techCol?.code,
					description: techCol?.description,
					comment: techCol?.comment,
					domainId: techCol?.domainId,
					stereotypeIds: techCol?.stereotypeIds,
					stereotypeColumnId: techCol?.stereotypeColumnId,
					notNull: domainInheritData.notNull,
					ordering: techCol?.ordering,
					virtualColumnScript: techCol?.virtualColumnScript,
					...auditFieldsAdd(techCol)
				}}
				header={techCol ? t('UPDATE_TECH_COLUMN') : t('CREATE_TECH_COLUMN')}
				onSubmit={handleSubmit}
			>
				<SplitFields>
					<TextFormField
						title={t('TECH_COLUMN_NAME')}
						onChange={v => onNameChange(v)}
						name="name"
						required
					/>
					<FormField
						name="code"
						title={t('TECH_COLUMN_CODE')}
						initialValue={techCol?.code}
					>
						<CodeInput nameValue={domainInheritData.nameValue} />
					</FormField>
				</SplitFields>
				<SplitFields>
					<TextAreaFormField
						title={t('TECH_COLUMN_DESCRIPTION')}
						name="description"
					/>
					<TextAreaFormField title={t('TECH_COLUMN_COMMENT')} name="comment" />
				</SplitFields>

				<SelectFormField
					isNumeric
					valueKey={'id'}
					labelKey={'name'}
					required
					title={t('TECH_COLUMN_DOMAIN')}
					name="domainId"
					onChange={v => onDomainChange(v)}
					options={domainsData}
				/>
				<CheckBoxFormField
					disabled={true}
					title={t('TECH_COLUMN_NOT_NULL')}
					name="notNull"
					formless={true}
					onChange={onChange}
					initialValue={domainInheritData.notNull}
				/>
				<SelectFormField
					isNumeric
					valueKey={'id'}
					labelKey={'name'}
					title={t('TECH_COLUMN_STEREOTYPE_COLUMN')}
					name="stereotypeColumnId"
					options={stereotypesColumn.data || []}
					required
					onChange={v => setSelectedStereotypeValue(v)}
				/>
				{selectedStereotypeValue === columnStereotypeValue.VIRTUAL_COLUMN && (
					<AceEditorField
						name="virtualColumnScript"
						title={t('TECH_COLUMN_SCRIPT')}
					/>
				)}

				<Stereotypes>
					<SelectFormField
						isNumeric
						valueKey={'id'}
						labelKey={'name'}
						multi
						title={t('TECH_COLUMN_STEREOTYPE')}
						name="stereotypeIds"
						options={stereotypes}
					/>
				</Stereotypes>
				<AuditForm isUpdating={!!techCol} />
			</ModalForm>
		</>
	)
}

const SplitFields = styled.div`
	display: flex;
`

const Stereotypes = styled.div`
	margin-top: 20px;
`
