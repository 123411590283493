import React from 'react'
import styled from 'styled-components'
import { Form, TextAreaFormField } from '@/components/UberForm'
import { Container } from '../../../TableDetail/components/StyledComponents'
import { OpenedReadMappingData } from '@/store/modules/readMapping/types'
import { useAppContext } from '@/utils/hooks'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Source } from '../../../../components/Source/Source'

interface SourcesValues {
	criteria?: string
	criteriaDescription?: string
	sources?: string
	sourcesDescription?: string
}

interface Props {
	data: OpenedReadMappingData
	editMode: boolean
	onChange: (v: Partial<SourcesValues>) => void
}

export const Sources = ({ onChange, data, editMode }: Props) => {
	const { t } = useAppContext()

	return (
		<StyledContainer>
			<Form<SourcesValues>
				onChange={onChange}
				defaultValues={data.form}
				disabled={!editMode}
				enableFieldHighlight
			>
				<Source data={data} onChange={onChange} />
				<TextAreaFormField
					name="sourcesDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_SOURCES')])}
					initialValue={data.form.sourcesDescription}
				/>
				<AceEditorField
					name="criteria"
					title={t('MAPPING_CRITERIA')}
					initialValue={data.form.criteria}
				/>
				<TextAreaFormField
					name="criteriaDescription"
					title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_CRITERIA')])}
					initialValue={data.form.criteriaDescription}
				/>
			</Form>
		</StyledContainer>
	)
}

const StyledContainer = styled(Container)`
	textarea {
		border-radius: 0;
	}
`
