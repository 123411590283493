import React, { useRef, useState } from 'react'
import { TextProps, FormlessText } from '../UberForm/Input/Text/Text'
import { useDebounce, useEffectWithoutMount } from '@/utils/hooks'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { splitProps } from '../../utils/collections'

export type FilterValues = string | undefined

interface Props extends TextProps {
	value: FilterValues
	setValue: (value: FilterValues) => void
	debounceTime: number
}

export const SearchBar = (props: Props) => {
	const searchProps = { ...props }

	const { value, setValue, debounceTime } = splitProps<
		Props,
		['value', 'setValue', 'debounceTime']
	>(searchProps, ['value', 'setValue', 'debounceTime'])

	const wasEnter = useRef(false)
	const [localValue, setLocalValue] = useState<FilterValues>(value)
	const changedValue = useDebounce(localValue, debounceTime)

	useEffectWithoutMount(() => {
		if (wasEnter.current) {
			wasEnter.current = false
		} else {
			setValue(changedValue)
		}
	}, [changedValue])

	const onKeyPress = (e: React.KeyboardEvent<Element>) => {
		if (e.key === 'Enter') {
			wasEnter.current = true
			setValue(localValue)
		} else {
			wasEnter.current = false
		}
	}

	return (
		<FormlessText
			value={localValue}
			onChange={v => {
				setLocalValue(v as string)
			}}
			onKeyPress={onKeyPress}
			inputIcon={faSearch}
			{...searchProps}
		/>
	)
}
