import * as React from 'react'
import { Modal } from '@/components/Modal/Modal'
import { Button } from '@/components'
import { faHistory, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/utils/hooks'
import styled from 'styled-components'

export interface ConfirmationOptions {
	catchOnCancel?: boolean
	title: string
	description: string | React.ReactNode
	cancel?: boolean
}

interface ConfirmationDialogProps extends ConfirmationOptions {
	open: boolean
	onSubmit: (cancel?: boolean) => void
	onClose: () => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	title,
	description,
	onSubmit,
	onClose
}) => {
	const { t } = useAppContext()

	return (
		<Modal
			onClose={onClose}
			open={open}
			header={title}
			zIndex={9999}
			stickyFooter
			contentStyle={{
				width: 700
			}}
			footer={close => (
				<>
					<Button schema="warn" onClick={() => onSubmit(true)} icon={faHistory}>
						{t('CANCEL')}
					</Button>
					<Button icon={faTrash} schema="danger" onClick={() => onSubmit()}>
						{t('SAVE_AND_UPDATE_ELEMENTS')}
					</Button>
					<Button schema="transparent" onClick={close} icon={faTimes}>
						{t('SAVE_AND_DO_NOT_UPDATE_ELEMENTS')}
					</Button>
				</>
			)}
		>
			<PopupText>
				<span>{description}</span>
			</PopupText>
		</Modal>
	)
}

const PopupText = styled.div`
	display: flex;
	align-items: center;
`
