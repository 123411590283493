import { RelationDto } from '@/api/models'
import { hasObjectAllValues } from '@/utils/collections'
import { useMemo } from 'react'
import { DependenciesFilterValues } from '../components/DependenciesFilter'
import { TypeEnum } from '../types'
import Fuse from 'fuse.js'
import { omit } from 'lodash'

export const useDependenciesFilteredData = (
	filter: DependenciesFilterValues,
	data: RelationDto[] = []
) => {
	const filteredData = useMemo(() => {
		let dataValues = data.filter(item => {
			if (
				[TypeEnum.PK_FK_COLUMN].includes(
					item.relationType as TypeEnum
				)
			) {
				return false
			}

			const valuesEqual = hasObjectAllValues(item, omit(filter, 'name'))

			return valuesEqual
		})

		if (filter.name) {
			const options = {
				keys: ['name']
			}

			const fuse = new Fuse(dataValues, options)

			const search = fuse.search(filter.name)

			dataValues = search.map(fuseItem => fuseItem.item)
		}

		return dataValues
	}, [data, filter])

	return filteredData
}
