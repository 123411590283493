import { Loader } from '@/components'
import { Flex, ThCell } from '@/components/Layout'
import { useAppContext, useAppStore } from '@/utils/hooks'
import styled from 'styled-components'
import React, { useCallback, useMemo } from 'react'
import { addStructureToRelease, getReleaseStructures } from '@/api'
import { ReleaseDto, StructureDto } from '@/api/models'
import { useApi, useApiRequest } from '@/api/hooks'
import { ReleaseObject } from './ReleaseObject'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faPlus } from '@fortawesome/free-solid-svg-icons'
import { TREE_PANEL_DRAG_DROP_NODE_ID } from '@/pages/User/pages/Home/components/TreePanel/drag'
import { NativeMap } from '@/utils/collections'
import { sortBy } from 'lodash'
import { RELEASE_OBJECTS_FORBIDDEN } from '../../constants'

type Props = {
	node: StructureDto
	release: ReleaseDto
	editMode: boolean
}

const getAllChildren = (
	nodeId: number,
	childrenMap: NativeMap<number[]>
): number[] => {
	const children = childrenMap[nodeId]
	const childrenIds = [] as number[]

	if (children) {
		for (const child of children) {
			const childrenNested = getAllChildren(child, childrenMap)
			childrenIds.push(...childrenNested)
		}
	}

	return [nodeId, ...childrenIds]
}

export const ReleaseObjects = ({ node, release, editMode }: Props) => {
	const { t } = useAppContext()
	const nodeState = useAppStore(state => state.node)

	const request = useApiRequest()

	const { data: objects, loading, invalidate } = useApi(
		getReleaseStructures(node.id, release.id!)
	)

	const objectsSorted = useMemo(() => sortBy(objects, o => o.structureId), [
		objects
	])

	const reloadObjects = useCallback(() => invalidate(), [invalidate])

	const onDrop = async (e: React.DragEvent) => {
		const addedNodeId = parseFloat(
			e.dataTransfer.getData(TREE_PANEL_DRAG_DROP_NODE_ID)
		)

		const allNestedNodes = getAllChildren(addedNodeId, nodeState.children)

		const fitlerSystemsAndFolders = allNestedNodes.filter(
			node =>
				!RELEASE_OBJECTS_FORBIDDEN.includes(nodeState.nodes[node]?.type!) &&
				!objects?.some(
					object => object.structureId === nodeState.nodes[node]?.id!
				)
		)

		await request(
			addStructureToRelease(node.id, release.id!, fitlerSystemsAndFolders)
		)

		invalidate()
	}

	const onDragOver = (e: React.DragEvent) => {
		e.preventDefault()
	}

	return (
		<>
			<HeaderWrapper>
				<Header>{t('ELEMENTS_IN_RELEASE')}</Header>
				{editMode && (
					<>
						<DropZone onDrop={onDrop} onDragOver={onDragOver}>
							<FontAwesomeIcon icon={faPlus} style={{ marginRight: '4px' }} />
							{t('ADD_ELEMENT')}
						</DropZone>
						<Info title={t('RELEASE_DRAG_DROP_HINT')}>
							<FontAwesomeIcon icon={faInfo} />
						</Info>
					</>
				)}
			</HeaderWrapper>
			<Flex align="stretch">
				<ThCell>{t('NAME')}</ThCell>
				<ThCell>{t('CODE')}</ThCell>
				<ThCell>{t('TYPE')}</ThCell>
				<TextCell>{t('PATH')}</TextCell>
				<Actions />
			</Flex>

			<Loader loaded={!loading} absolute />

			{objectsSorted?.map(object => (
				<ReleaseObject
					key={object.structureId}
					node={node}
					release={release}
					object={object}
					editMode={editMode}
					reloadObjects={reloadObjects}
				/>
			))}
		</>
	)
}

const HeaderWrapper = styled(Flex)`
	margin-top: 10px;
	margin-bottom: 10px;
`

const Header = styled.div`
	font-size: 16px;
	margin-right: 6px;
`

const DropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border: ${props => `1px dashed ${props.theme.colors.primary.base}`};
	height: 30px;
	width: 200px;
	margin-right: 6px;
`

const Info = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: ${props => `1px solid ${props.theme.colors.primary.base}`};
	padding: 6px;
	width: 10px;
	height: 10px;
`

const Actions = styled.div`
	width: 40px;
`

const TextCell = styled(ThCell)`
	flex: 2;
	text-overflow: ellipsis;
	white-space: nowrap;
`
