import styled, { css } from 'styled-components'
import {
	TableMode,
	OpenedTableData,
	TableDataForm,
	HistoryTableFormData,
	ReferenceTableFormData
} from '@/store/modules/table/types'
import {
	TABLE_MODE_DISABLED_FIELDS,
	TABLE_MODE_DISABLED_FIELDS_GENERATED
} from './constants'
import { HistoryTableData } from '@/api/schemas'
import { has } from 'lodash'
import { SqlTypeEnum } from '@/typings/enum/SqlTypeEnum'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'

export const listValue = <T extends object, K extends keyof T>(
	list: T[],
	valueKey: K,
	labelKey: keyof T,
	value: T[K] | undefined | null,
	unknown = '',
	getItem = false
) => {
	const item = list.find(item => item[valueKey] == value)

	return item ? (getItem ? item : item[labelKey]) : unknown
}

export const listValues = <T extends object, K extends keyof T>(
	list: T[],
	valueKey: K,
	labelKey: keyof T,
	values: T[K][] | undefined | null
) =>
	values
		? values.map(item => listValue(list, valueKey, labelKey, item)).join(', ')
		: ''

export const boolValue = (value: boolean | undefined) => (value ? 'Yes' : 'No')

export const ReadonlyContainer = styled.div`
	display: flex;
	border-bottom: 1px solid
		${props => props.theme.colors.input.compact.horizontalBorder};
`

export const ReadonlyTextItem = styled.div`
	flex: 1;
	padding: 5px 5px;
`

export const ReadonlyTextareaItem = styled.div`
	flex: 2;
	padding: 5px 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

export const ReadonlyCheckboxItem = styled.div`
	min-width: 100px;
	padding: 5px 5px;
`

export const ReadonlyHeader = styled.div`
	display: flex;
	color: ${props => props.theme.colors.form.compact.header.color};
	border-bottom: 1px solid
		${props => props.theme.colors.input.compact.horizontalBorder};
`
export const ActionButtons = styled.div<{
	isFirst: boolean
	isDisabled: boolean
}>`
	display: flex;
	flex-grow: 0;
	width: 30px;
	button {
		padding: 0;
		margin: auto;
		height: 24px;
	}
	${props =>
		props.isFirst &&
		css`
			margin-top: 27px;
		`}

	${props =>
		props.isDisabled &&
		css`
			opacity: 0;

			button {
				cursor: initial;
			}
		`}
`

export const getUnsyncedFields = (mode: TableMode, form?: TableDataForm) =>
	(mode === TableMode.HISTORY
		? form?.historyTable && form?.historyTable.nonSyncFields
		: mode === TableMode.REFERENCE
		? form?.referenceTable && form?.referenceTable.nonSyncFields
		: []) || []

/** Adds unsynced fields when they are missing from original object to properly overwrite all table.form values */
const getOverwritedFields = (
	initForm?: ReferenceTableFormData | HistoryTableFormData,
	unsyncedFields?: (keyof HistoryTableData)[]
) =>
	unsyncedFields?.reduce((fields, field) => {
		if (has(fields, field)) {
			return fields
		}

		return {
			...fields,
			[field]: undefined
		}
	}, initForm) ?? {}

/**
 * Get form data for currently selected table mode
 */
export const getModeForm = (
	mode: TableMode,
	form?: TableDataForm,
	unsyncedFields?: (keyof HistoryTableData)[]
) => {
	if (form) {
		switch (mode) {
			case TableMode.HISTORY:
				const historyForm = getOverwritedFields(
					form.historyTable,
					unsyncedFields
				)

				return {
					...form,
					...historyForm
				}
			case TableMode.REFERENCE:
				const referenceForm = getOverwritedFields(
					form.referenceTable,
					unsyncedFields
				)

				return {
					...form,
					...referenceForm
				}
		}
	}

	return form
}

export const getIsFieldDisabled = (
	fieldName: keyof TableDataForm,
	mode: TableMode
) => TABLE_MODE_DISABLED_FIELDS.includes(fieldName) && mode !== TableMode.TABLE

export const getIsFieldDisabledGenerated = (
	fieldName: keyof TableDataForm,
	mode: TableMode,
	canGenerateNaming?: boolean
) =>
	TABLE_MODE_DISABLED_FIELDS_GENERATED.includes(fieldName) &&
	mode !== TableMode.TABLE &&
	canGenerateNaming

export const getSqlType = (tableMode: TableMode) => {
	switch (tableMode) {
		case TableMode.TABLE:
			return SqlTypeEnum.TABLE
		case TableMode.HISTORY:
			return SqlTypeEnum.TABLE_HISTORY
		case TableMode.REFERENCE:
			return SqlTypeEnum.TABLE_REFERENCE
	}
}

export const getNamingTypeCode = (tableMode: TableMode) => {
	switch (tableMode) {
		case TableMode.TABLE:
			return NamingDtoTypeEnum.TABLE_CODE
		case TableMode.HISTORY:
			return NamingDtoTypeEnum.HISTORICAL_TABLE_CODE
		case TableMode.REFERENCE:
			return NamingDtoTypeEnum.REFERENCE_TABLE_CODE
	}
}

export const getNamingTypeName = (tableMode: TableMode) => {
	switch (tableMode) {
		case TableMode.HISTORY:
			return NamingDtoTypeEnum.HISTORICAL_TABLE_NAME
		case TableMode.REFERENCE:
			return NamingDtoTypeEnum.REFERENCE_TABLE_NAME
		default:
			return NamingDtoTypeEnum.HISTORICAL_TABLE_NAME
	}
}
