import { NewStructureDto, StructureDto } from '@/api/models'
import { Button, DialogWrapper, ListEditTab, Loader } from '@/components'
import { initReadMapping } from '@/store/modules/readMapping/actions'
import { isNotUndefined } from '@/utils/collections'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { AddModal } from '../../../../components/AddModal'
import { useOpenTab } from '../../../../components/Dependencies/hooks/useOpenTab'
import { TabsActions } from '../../../../components/TabActions'
import { Container } from '../../components/StyledComponents'
import { MappingsProperties } from './Properties'

type Props = {
	node: StructureDto
}

export const Mappings = ({ node }: Props) => {
	const { t } = useAppContext()
	const [isLoading, setLoading] = useState(false)
	const { openTabById } = useOpenTab()

	const dispatch = useAppDispatch()
	const children = useAppStore(state => state.node.children)
	const nodes = useAppStore(state => state.node.nodes)
	const readMappings = useAppStore(state => state.readMapping.mappings)
	const refInit = useRef(false)

	const childMappings = useMemo(() => {
		const myChildren = children && children[node.id]

		return myChildren
			? myChildren
					.map(id => nodes[id])
					.filter(isNotUndefined)
					.filter(
						node =>
							node.type === StructureDto.TypeEnum.MAPPING_READ ||
							node.type === StructureDto.TypeEnum.MAPPING_WRITE
					)
			: []
	}, [children, node.id, nodes])

	useEffect(() => {
		if (refInit.current) {
			return
		}

		setLoading(true)

		Promise.all(
			childMappings.map(node => dispatch(initReadMapping({ nodeId: node.id })))
		).then(() => {
			refInit.current = true
			setLoading(false)
		})
	}, [childMappings, dispatch])

	const onEdit = useCallback(
		(nodeId: number) => {
			openTabById(nodeId)
		},
		[openTabById]
	)

	const mappingDetails = childMappings.map(node => ({
		node,
		detail: readMappings[node.id]
	}))

	const data = useMemo(
		() => ({
			mappings:
				mappingDetails?.map(detail => ({
					...detail.detail?.form,
					id: detail.node.id,
					type: detail.node.type,
					parentStructureId: detail.node.parentStructureId
				})) ?? [],
			counter: mappingDetails?.length ?? 0
		}),
		[mappingDetails]
	)

	return (
		<>
			{node.canEdit && (
				<TabsActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<AddModal
									type={NewStructureDto.TypeEnum.MAPPING_READ}
									onClose={onClose}
									node={node}
								/>
							)
						}
					>
						{onClick => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW_MAPPING_READ')}
							</Button>
						)}
					</DialogWrapper>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<AddModal
									type={NewStructureDto.TypeEnum.MAPPING_WRITE}
									onClose={onClose}
									node={node}
								/>
							)
						}
					>
						{onClick => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW_MAPPING_WRITE')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActions>
			)}
			<Container>
				<LoaderContainer>
					<Loader loaded={!isLoading} absolute />
				</LoaderContainer>

				<ListEditTab
					node={node}
					data={data}
					editMode={false}
					itemsKey={'mappings'}
					idCounterKey={'counter'}
					isRowOrderable
					properties={MappingsProperties(t, onEdit)}
					tabKey="mappings"
					onChange={() => undefined}
					createEmpty={() => ({} as any)}
					disablePanelProperties
				/>
			</Container>
		</>
	)
}

const LoaderContainer = styled.div`
	position: relative;
`
