import { Actions } from '@/store/modules/table/actions'
import {
	TABLE_UPDATE_HISTORY_COLUMNS,
	TABLE_UPDATE_STEREOTYPES_COLUMNS
} from '@/store/modules/table/constants'
import { AnyAction, Dispatch, Middleware } from 'redux'
import reducers from '../../modules'

type StoreState = ReturnType<typeof reducers>

const handleActionType = (
	getState: () => StoreState,
	next: Dispatch<AnyAction>,
	action: AnyAction,
	actionType: string
) => {
	const start = getState().actionsTimestamps[actionType]?.[action.node.id]
		?.start

	if (start === action?.timestamp) {
		return next(action)
	}

	return
}

/** Cancel action if another one was called (api requests race condition) */
export const cancelAction: Middleware<{}, StoreState> = ({
	getState
}) => next => (action: Actions) => {
	if (action?.type) {
		const actionType = [
			TABLE_UPDATE_STEREOTYPES_COLUMNS,
			TABLE_UPDATE_HISTORY_COLUMNS
		].find(actionType => actionType === action.type)

		if (actionType) {
			return handleActionType(getState, next, action, actionType)
		}
	}

	return next(action)
}
