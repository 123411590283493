import { AppDispatch } from '@/store/utils'
import { checkUniqueName } from '@/store/modules/misc/actions'
import { Validator } from '@/components/UberForm/Validators'
import { asyncDebounce } from './async'

export const uniqueNameValidator = (
	dispatch: AppDispatch,
	original?: string,
	systemFolderId?: number
): Validator => {
	return asyncDebounce(async value => {
		if (!value || typeof value !== 'string' || value === original) {
			return
		}

		const res = await dispatch(checkUniqueName(value, systemFolderId))

		if (res.errors && (res.errors?.length ?? 0) > 0) {
			return res.errors.join('\n')
		}

		return
	}, 500)
}
