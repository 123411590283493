import { ItemPropertyType } from '../../../../../../../../components/Properties/types'
import { ItemProperty } from '../../../../../../../../components/Properties/types'
import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { StructureDto } from '@/api/models'
import { DialogWrapper } from '@/components'
import { DeleteModal } from '../../../../components/DeleteModal'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

export const CollectionsProperties = memoizeOne(
	(
		t: TranslationHelper,
		onEdit: (nodeId: number) => void
	): ItemProperty<StructureDto>[] => [
		{
			field: 'name',
			label: t('TABLE_INDEXES_NAME'),
			type: ItemPropertyType.TEXT,
			width: 100,
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'code',
			label: t('TABLE_COLUMNS_CODE'),
			type: ItemPropertyType.TEXT,
			codeSource: 'name',
			width: 100,
			showWhenPanelOpened: true,
			autoWidth: true
		},
		{
			field: 'description',
			label: t('DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			width: 200,
			flex: 2
		},
		{
			field: 'stereotypeCode',
			label: t('STEREOTYPE'),
			type: ItemPropertyType.TEXT,

			width: 200
		},
		{
			field: 'type',
			label: t('TYPE'),
			type: ItemPropertyType.TEXT,
			width: 200
		},
		{
			field: 'actions',
			label: t('ACTIONS'),
			type: ItemPropertyType.TEXTAREA,
			width: 60,
			flex: 0,
			sticky: true,
			formatter: ({ item }) => (
				<Wrapper>
					<Action onClick={() => onEdit(item.id)} title={t('EDIT')}>
						<FontAwesomeIcon icon={faEdit} />
					</Action>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <DeleteModal node={item} onClose={onClose} />
						}
					>
						{onClick => (
							<Action
								onClick={onClick}
								style={{ marginLeft: 'auto' }}
								title={t('DELETE')}
							>
								<FontAwesomeIcon icon={faTimes} color="red" />
							</Action>
						)}
					</DialogWrapper>
				</Wrapper>
			)
		}
	]
)

const Wrapper = styled.div`
	position: sticky;
	right: 30px;
	top: 0px;
	display: flex;
	margin-right: 5px;
`

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`
