import React, { useMemo, useState } from 'react'
import { useAppContext } from '@/utils/hooks'
import { LightTable, Loader } from '@/components'
import { StructureDto, ValidationMessageDto } from '@/api/models'
import { checkStructure } from '@/api'
import { useApi } from '@/api/hooks'
import { Container } from '@/components/Container'
import { LightTableColumn } from '@/components/LightTable'
import styled, { css } from 'styled-components'
import { Filter, FilterValues } from './Filter'
import { filterValidationData } from './helpers'

type Props = {
	node: StructureDto
}

export const Validation = ({ node }: Props) => {
	const { t } = useAppContext()
	const validation = useApi(checkStructure(node.id))

	const [filter, setFilter] = useState<FilterValues>({})

	const filteredData = useMemo(
		() => filterValidationData(validation.data || [], filter),
		[validation.data, filter]
	)

	const model = useMemo(
		(): LightTableColumn<ValidationMessageDto>[] => [
			{
				field: 'id',
				title: t('VALIDATION_ID'),
				width: 5
			},
			{
				field: 'severity',
				title: t('VALIDATION_SEVERITY'),
				width: 10,
				// eslint-disable-next-line react/display-name
				formatter: value => (
					<SeverityBadge severity={value as ValidationMessageDto.SeverityEnum}>
						{value}
					</SeverityBadge>
				)
			},
			{
				field: 'nodeName',
				title: t('VALIDATION_ELEMENT'),
				width: 80,
				formatter: (_, row) => `${row.nodeName} (${row.nodeType})`
			},
			{
				field: 'elementName',
				title: t('VALIDATION_ATTRIBUTE'),
				width: 80,
				formatter: (_, row) => `${row.elementName} (${row.elementType})`
			},
			{
				field: 'message',
				title: t('VALIDATION_MESSAGE'),
				width: 200
			},
			{
				field: 'description',
				title: t('VALIDATION_DESCRIPTION'),
				width: 200
			}
		],
		[t]
	)

	return (
		<Container>
			<Filter onChange={values => setFilter(values)} />

			{validation.loading ? (
				<Loader loaded={false} />
			) : (
				<LightTable
					keyField="id"
					isLoading={validation.reloading}
					data={filteredData}
					columns={model}
				/>
			)}
		</Container>
	)
}

const SeverityBadge = styled.span<{
	severity: ValidationMessageDto.SeverityEnum
}>`
	display: inline-block;
	color: white;
	padding: 3px 5px;

	${props =>
		props.severity === ValidationMessageDto.SeverityEnum.ERROR &&
		css`
			background: ${props.theme.colors.danger.base};
		`};

	${props =>
		props.severity === ValidationMessageDto.SeverityEnum.WARN &&
		css`
			background: ${props.theme.colors.warn.base};
		`};

	${props =>
		props.severity === ValidationMessageDto.SeverityEnum.INFO &&
		css`
			background: ${props.theme.colors.info.light};
		`};
`
