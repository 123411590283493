import { NodeStatus } from '@/api/schemas/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faLock } from '@fortawesome/free-solid-svg-icons'
import { mainColors } from '@/styles'
import React from 'react'
import { isLocked, isLockedByMe } from '@/utils/locking'
import { StructureDto, UserDto } from '@/api/models'
import { TranslationHelper } from '@/context/Locale'

export const renderFrozenIcon = (nodeStatus: string | undefined) => {
	if (nodeStatus) {
		return (
			nodeStatus === NodeStatus.FROZEN && (
				<>
					<FontAwesomeIcon
						icon={faBan}
						size="sm"
						color={mainColors.frozen}
						title="FROZEN"
					/>
				</>
			)
		)
	}

	return null
}

export const renderLockedIcon = (
	node: StructureDto,
	user: UserDto | null,
	t: TranslationHelper
) => {
	if (isLocked(node)) {
		return (
			<FontAwesomeIcon
				icon={faLock}
				size="sm"
				color={
					isLockedByMe(node, user) ? mainColors.success : mainColors.danger
				}
				title={
					isLockedByMe(node, user)
						? t('LOCKED_BY_YOU')
						: t('LOCKED_BY_USER', [node.lockUserName as string])
				}
			/>
		)
	}

	return null
}
