/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import cn from 'classnames'
import { LightTableColumn } from '../types'
import { getCellValue } from '../helpers'

type Props<T> = {
	column: LightTableColumn<T>
	style: React.CSSProperties
	row: T
	rowIndex: number
	isExpandable?: boolean
	value: any
	scrollLeft: number
	onRowExpand?: (row: T, index: number) => void
}

const Cell = <T extends any>({
	column,
	style,
	row,
	rowIndex,
	isExpandable,
	value,
	onRowExpand,
	scrollLeft
}: Props<T>) => {
	return (
		<div
			className={cn(
				'div-td',
				getCellValue(column.tdClassName, value, row, rowIndex),
				column.sticky && 'sticky-column',
				isExpandable && column.expandable && 'expandable'
			)}
			style={{
				...(column.align
					? {
							textAlign: column.align
					  }
					: undefined),
				...style,
				...(column.sticky &&
					scrollLeft !== 0 && { transform: `translateX(${scrollLeft}px)` }),
				...getCellValue(column.tdStyle, value, row, rowIndex)
			}}
			{...{
				...(isExpandable && column.expandable
					? {
							onClick: e => {
								e.stopPropagation()
								e.preventDefault()
								onRowExpand && onRowExpand(row, rowIndex)
							}
					  }
					: undefined)
			}}
			role="cell"
		>
			{column.htmlInString ? (
				<span dangerouslySetInnerHTML={{ __html: value as any }} />
			) : column.formatter ? (
				column.formatter(value, row, rowIndex)
			) : (
				value
			)}
		</div>
	)
}

export default React.memo(Cell) as typeof Cell
