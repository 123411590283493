import { components } from 'react-select'
import { OptionProps } from 'react-select/src/components/Option'
import { SingleValueProps } from 'react-select/src/components/SingleValue'
import React from 'react'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { mainColors } from '@/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const { Option, SingleValue } = components

const CustomSingleValue = ({ children, ...props }: SingleValueProps<any>) => {
	return (
		<SingleValue {...props}>
			{/*TODO: refactor when final BE data is ready => use renderFrozenIcon*/}
			{props.data.value === 'frozen' && (
				<FontAwesomeIcon
					icon={faBan}
					size="sm"
					color={mainColors.frozen}
					title="FROZEN"
					style={{ marginRight: '5px' }}
				/>
			)}

			{children}
		</SingleValue>
	)
}

const CustomOption = ({ children, ...props }: OptionProps<any, any>) => {
	return (
		<Option {...props}>
			{/*TODO: refactor when final BE data is ready => use renderFrozenIcon*/}
			{props.data.value === 'frozen' && (
				<FontAwesomeIcon
					icon={faBan}
					size="sm"
					color={mainColors.frozen}
					title="FROZEN"
					style={{ marginRight: '5px' }}
				/>
			)}
			{children}
		</Option>
	)
}

/**
 * This object represents the main logic for react-select customization,
 * for more information please visit https://react-select.com/components
 */

const CustomComponentsOptions = {
	SingleValue: CustomSingleValue,
	Option: CustomOption
}

export default CustomComponentsOptions
