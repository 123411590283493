import React from 'react'
import { Button, LightTable } from '@/components'
import { Container } from '@/components/Container'
import { faBox, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/utils/hooks'
import { DialogWrapper } from '@/components'
import { getAllObjectSettings } from '@/api'
import { useApi } from '@/api/hooks'
import { ObjectSettingsDto } from '@/api/models'
import { ObjectSettingsModal } from './components/ObjectSettingsModal'
import {
	TitleContainer,
	TitleText,
	TitleActions,
	TitleIcon
} from '../../styles'
import { useObjectSettingsColumns } from './hooks/useObjectSettingsColumns'
import { useFilter } from '../../hooks/useFilter'
import {
	ObjectSettingsFilter,
	ObjectSettingsFilterValues
} from './components/ObjectSettingsFilter'
import { useObjectSettingsFilteredData } from './hooks/useObjectSettingsFilteredData'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'

export const ObjectSettings = () => {
	const { t } = useAppContext()
	const objectSettings = useApi(getAllObjectSettings())
	const { filter, onChangeFilter } = useFilter<ObjectSettingsFilterValues>()

	const handleSave = useSettingsPageSaveHandler(objectSettings)

	const columns = useObjectSettingsColumns(handleSave)

	const objectSettingsFiltered = useObjectSettingsFilteredData(
		filter,
		objectSettings.data
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faBox} color="#602655" />
				<TitleText>{t('OBJECT_SETTINGS')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<ObjectSettingsModal onSave={handleSave} onClose={onClose} />
							)
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<ObjectSettingsFilter onChangeFilter={onChangeFilter} />
				<LightTable<ObjectSettingsDto>
					data={objectSettingsFiltered}
					columns={columns}
					keyField={'id'}
					isLoading={objectSettings.loading}
				/>
			</Container>
		</>
	)
}
