import styled, { css } from 'styled-components'

export const ThCell = styled.div<{ flex?: string | number }>`
	border-width: 1px;
	border-style: solid;
	border-left-width: 0;
	box-sizing: border-box;

	padding: 8px 6px;
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: 0px;
	white-space: nowrap;

	${props =>
		css`
			flex: ${props.flex || 1};
			background: ${props.theme.colors.primary.base};
			border-color: ${props.theme.colors.form.compact.header.borderColor};
			color: #fff;
		`}

	&:first-child {
		border-left-width: 1px;
	}
`
