import { ColumnsPickerValue } from '@/components/ColumnsPicker/ColumnsPicker'
import { FormValue } from '@/components/UberForm/Form'
import {
	EditingAddress,
	ItemProperty,
	MoveDir,
	PropertiesErrorProps
} from '../types'

export type Item = Record<string, any> | any

export type TablePropertiesContextProps = {
	items: Item[]
	properties: ItemProperty<Item>[]
	readonly: boolean
	onChange?: (
		item: Item,
		key: string,
		value: FormValue | ColumnsPickerValue
	) => void
	onSelect?: (index: number) => void
	onDelete?: (item: Item) => void
	isDeletable?: (item: Item, itemIndex: number) => boolean
	isReadonly?: (item: Item) => boolean
	isRowOrderable?: boolean
	onRowOrderChanged?: (oldIndex: number, newIndex: number) => void
	errors?: PropertiesErrorProps
	selectedItemIndex?: number
	propertiesHidden?: boolean
	isLastRowOrderable?: boolean
	columnWidthsParent?: Record<string, number>
	children?: React.ReactNode
}

export type RowOrderable = { up?: boolean; down?: boolean; enabled: boolean }

export type TablePropertiesContextState = {
	focused: EditingAddress | null
	dirLast: MoveDir | null
	columnWidths: Record<string, number>
	draggingRowIndex: number | null
	dragOverRowIndex: number | null
	tableProps: ItemProperty<Item>[]
	handleMove: (dir: MoveDir) => void
	handleFocus: (rowUpdate: number, fieldUpdate: number) => void
	handleRowDrop: (dropIndex: number) => void
	resolveIsRowOrderable: (itemIndex: number) => RowOrderable
	refContainer: React.RefObject<HTMLDivElement>
	scrollbarWidth: number | null
	onDragEnter: (rowIndex: number) => void
	//** React-window hides rows based on scroll */
	firstRenderedItemIndex?: number
} & Omit<TablePropertiesContextProps, 'children' | 'columnWidthsParent'>

export interface TablePropertiesContextType {
	state: TablePropertiesContextState
	dispatch: React.Dispatch<Actions>
}

export enum ActionTypeEnum {
	FOCUSED_SET,
	DIR_LAST_SET,
	COLUMNS_WIDTHS_SET,
	DRAG_OVER_INDEX_SET,
	DRAGGING_ROW_INDEX_SET,
	SCROLLBAR_WIDTH_SET,
	SYNC_CONTEXT_AND_PROPS
}

type ActionFocusedSet = {
	type: ActionTypeEnum.FOCUSED_SET
	payload: {
		focused: EditingAddress | null
	}
}

type ActionDirLastSet = {
	type: ActionTypeEnum.DIR_LAST_SET
	payload: {
		dirLast: MoveDir | null
	}
}

type ActionColumnsWidthsSet = {
	type: ActionTypeEnum.COLUMNS_WIDTHS_SET
	payload: {
		columnWidths: Record<string, number>
	}
}

type ActionDragOverRowIndex = {
	type: ActionTypeEnum.DRAG_OVER_INDEX_SET
	payload: {
		dragOverRowIndex: number | null
	}
}

type ActionDraggingRowIndex = {
	type: ActionTypeEnum.DRAGGING_ROW_INDEX_SET
	payload: {
		draggingRowIndex: number | null
	}
}

type ActionScrollbarWidthSet = {
	type: ActionTypeEnum.SCROLLBAR_WIDTH_SET
	payload: {
		scrollbarWidth: number | null
	}
}

type ActionSyncContextAndProps = {
	type: ActionTypeEnum.SYNC_CONTEXT_AND_PROPS
	payload: Partial<TablePropertiesContextState>
}

export type Actions =
	| ActionFocusedSet
	| ActionDirLastSet
	| ActionColumnsWidthsSet
	| ActionDragOverRowIndex
	| ActionDraggingRowIndex
	| ActionScrollbarWidthSet
	| ActionSyncContextAndProps
