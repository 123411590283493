import { useCallback, useMemo } from 'react'
import { useAppStore, useAppDispatch } from '@/utils/hooks'
import { loadNode } from '@/store/modules/node/general-actions'
import { openTab } from '@/store/modules/tab/actions'
import { StructureDto } from '@/api/models'

export const useOpenTab = () => {
	const nodes = useAppStore(state => state.node.nodes)
	const dispatch = useAppDispatch()

	const openTabById = useCallback(
		async (nodeId: number) => {
			if (nodes[nodeId]) {
				nodeId = nodes[nodeId]?.id ?? -1
			} else {
				nodeId = (await dispatch(loadNode(nodeId))).id
			}

			dispatch(openTab(nodes[nodeId] as StructureDto, false))
		},
		[dispatch, nodes]
	)

	const result = useMemo(() => ({ openTabById }), [openTabById])

	return result
}
