import React from 'react'

import { DialogWrapper } from '@/components'
import { AddModal } from '../../../components/AddModal'
import { StructureDto, NewStructureDto } from '@/api/models'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ContextMenuItem } from '@/components/ContextMenu/ContextMenu'

type Props = {
	node: StructureDto
	type: NewStructureDto.TypeEnum
	label: string
	icon: IconProp
}

export const AddNodeItem = ({ node, type, label, icon }: Props) => (
	<DialogWrapper
		dialog={(opened, onClose) =>
			opened && <AddModal node={node} type={type} onClose={onClose} />
		}
	>
		{onClick => (
			<ContextMenuItem
				onClick={onClick}
				role="menuitem"
				icon={icon}
				color="gray"
				title={label}
			/>
		)}
	</DialogWrapper>
)
