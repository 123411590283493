/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	useContext,
	useState,
	useEffect,
	EffectCallback,
	DependencyList,
	useRef,
	useCallback,
	useLayoutEffect,
	RefObject
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ResizeObserver from 'resize-observer-polyfill'
import { AppContextType, AppContext } from '@/context/AppContext'
import { StoreState } from '@/store'
import {
	AuthContext,
	AuthContextInterface
} from '@/context/AuthContext/AuthContext'
import { AppDispatch } from '@/store/utils'
import { FormContext } from '@/components/UberForm/FormContext'
import { FormFieldContext } from '@/components/UberForm/FormFieldContext'

export const useAppContext = () => {
	return useContext(AppContext) as AppContextType
}

export const useFormContext = () => {
	return useContext(FormContext)
}

export const useFormFieldContext = () => {
	return useContext(FormFieldContext)
}

export const useAuthContext = () => {
	return useContext(AuthContext) as AuthContextInterface
}

export const useAppStore = <T>(selector: (state: StoreState) => T) => {
	return useSelector(selector)
}

export const useAppDispatch = useDispatch as () => AppDispatch

export const useDebounce = <T>(value: T, delay: number): T => {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [value])

	return debouncedValue
}

export const useDebounceCallback = <T, A extends any[]>(
	value: (...args: A) => T,
	delay = 100
): ((...args: A) => void) => {
	const callback = useRef<(...args: A) => T>()
	const callArgs = useRef<A>()
	const timeout = useRef<ReturnType<typeof setTimeout>>()

	useEffect(
		() => () => (timeout.current ? clearTimeout(timeout.current) : undefined),
		[]
	)

	callback.current = value

	return useCallback(
		(...args: A) => {
			callArgs.current = args

			if (timeout.current) {
				clearTimeout(timeout.current)
			}

			timeout.current = setTimeout(() => {
				if (callback.current && callArgs.current) {
					callback.current.apply(null, callArgs.current)
				}
			}, delay)
		},
		[callback, callArgs, delay]
	)
}

export const useEffectWithoutMount = (
	effect: EffectCallback,
	deps?: DependencyList
) => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		if (!mounted) {
			setMounted(true)

			return
		}

		return effect()
	}, deps)
}

/**
 * Attaches event callback to window when mounting and deattaches it when unmounting.
 * @param target
 * @param event
 * @param callback
 * @param cancelBubble
 */
export const useWindowEvent = <E extends Event>(
	event: string,
	callback: (e: E) => void,
	cancelBubble = false
) => useEvent(window, event, callback, cancelBubble)

/**
 * Attaches event callback to document when mounting  and deattaches it when unmounting.
 * @param target
 * @param event
 * @param callback
 * @param cancelBubble
 */
export const useDocumentEvent = <E extends Event>(
	event: string,
	callback: (e: E) => void,
	cancelBubble = false
) => useEvent(document, event, callback, cancelBubble)

/**
 * Attaches event callback to target when mounting and deattaches it when unmounting.
 * @param target
 * @param event
 * @param callback
 * @param cancelBubble
 */
export const useEvent = <E extends Event>(
	target: EventTarget,
	event: string,
	callback: (e: E) => void,
	cancelBubble = false
) => {
	// Hold reference to callback
	const callbackRef = useRef(callback)
	callbackRef.current = callback

	// Since we use ref, .current will always be correct callback
	const listener = useCallback(e => {
		if (callbackRef.current) {
			callbackRef.current(e as E)
		}
	}, [])

	useEffect(() => {
		// Add our listener on mount
		target.addEventListener(event, listener, cancelBubble)

		// Remove it on dismount
		return () => target.removeEventListener(event, listener)
	}, [])
}

/**
 * Returns previous props
 * @param value
 */
export const usePrevious = <T extends {}>(value: T) => {
	const ref = useRef<T>()

	useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}

const hasAnyParentClass = (element: Element, classname?: string): boolean => {
	if (!classname) {
		return false
	}

	if (
		element.className &&
		element.className.split(' ').indexOf(classname) >= 0
	) {
		return true
	}

	return element.parentNode
		? hasAnyParentClass(element.parentNode as Element, classname)
		: false
}

/**
 * Call function when user clicks outside HTML element
 * @param ref useRef
 * @param onClickOutside call when user clicks outside ref
 * @param classNameToOmit if element or any of his parents have this className, do not call onClickOutside
 */
export const useClickOutside = (
	onClickOutside: () => void,
	element?: HTMLElement | null,
	classNamesToOmit?: string[]
) => {
	const handleClickOutside = (event: MouseEvent) => {
		if (
			(element &&
				!element.contains(event.target as Element) &&
				classNamesToOmit?.every(
					classNameToOmit =>
						!hasAnyParentClass(event.target as Element, classNameToOmit)
				)) ??
			true
		) {
			onClickOutside()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	})
}

/**
 * setInterval cleared up before unmount
 * @param callback
 * @param delay
 */
export const useInterval = (callback: () => void, delay: number) => {
	const savedCallback = useRef<() => void>()

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		const tick = () => {
			if (savedCallback.current) {
				savedCallback.current()
			}
		}

		if (delay !== null) {
			const id = setInterval(tick, delay)

			return () => clearInterval(id)
		}
	}, [delay])
}

/**
 * Debug which prop changed
 * @param name
 * @param props
 */
export const useWhyUpdate = (name: string, props: Record<string, any>) => {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps = useRef<Record<string, any>>({})

	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({ ...previousProps.current, ...props })
			// Use this object to keep track of changed props
			const changesObj = {} as Record<string, any>

			// Iterate through keys
			allKeys.forEach(key => {
				// If previous is different from current
				if (previousProps.current[key] !== props[key]) {
					// Add to changesObj
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key]
					}
				}
			})

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				console.error('[useWhyUpdate]', name, changesObj)
			}
		}

		// Finally update previousProps with current props for next hook call
		previousProps.current = props
	})
}

export const useResizeObserver = (ref: RefObject<Element>) => {
	const [entry, setEntry] = useState(null as ResizeObserverEntry | null)

	useLayoutEffect(() => {
		if (ref.current) {
			const observer = new ResizeObserver(([entry]) => setEntry(entry))
			observer.observe(ref.current)

			return () => {
				observer.disconnect()
			}
		}
	}, [ref])

	return entry
}

export const useLocalStore = <R>(storeKey: string, defaultValue: R) => {
	const existing = localStorage.getItem(storeKey)

	const [value, setValue] = useState(
		existing !== null ? JSON.parse(existing) : defaultValue
	)

	const setter = useCallback(
		(newValue: R) => {
			localStorage[storeKey] = JSON.stringify(newValue)

			return setValue(newValue)
		},
		[setValue, storeKey]
	)

	return [value, setter]
}
