import React, { useCallback, useMemo, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faArrowUp,
	faArrowDown,
	faTimes
} from '@fortawesome/free-solid-svg-icons'

import {
	TableColumn,
	TableConstraintColumn,
	TableIndexColumn
} from '@/api/schemas'
import { SelectFormField, CheckBoxFormField } from '@/components/UberForm'
import { FormValue } from '@/components/UberForm/Form'
import { SortIcon } from '../../pages/User/pages/Home/pages/TableDetail/components/SortIcon'

export type ColumnsPickerValue = TableConstraintColumn[]

type ColumnsPickerItem = ColumnsPickerValue[0]

interface Props {
	field: string
	columns: TableColumn[]
	value: ColumnsPickerValue
	orders?: boolean
	onChange: (value: ColumnsPickerValue, field: string) => void
}

interface ColumnOption {
	value: string
	label: string
	sortType?: TableIndexColumn.SortTypeEnum
}

export const ColumnsPicker = ({
	field,
	columns,
	value,
	onChange,
	orders
}: Props) => {
	useEffect(() => {
		// remove columns for other constraint types (Foreign key etc.)
		const valueFiltered = value.filter(
			(v: TableConstraintColumn) => !v.foreignColumnUuid
		)

		onChange(valueFiltered, field)
	}, [])

	const handleColumnChange = useCallback(
		(v: FormValue) => {
			if (typeof v === 'string') {
				const newValue: ColumnsPickerValue = [
					...value,
					{
						...JSON.parse(v),
						...(orders && { sortType: TableIndexColumn.SortTypeEnum.ASC })
					}
				]

				onChange(newValue, field)
			}
		},
		[field, value]
	)

	const handleUp = (item: ColumnsPickerItem) => {
		const newValue = [...value]
		const index = value.findIndex(v => v.uuid === item.uuid)

		if (index > 0) {
			const b = value[index - 1]
			newValue[index] = b
			newValue[index - 1] = item

			onChange(newValue, field)
		}
	}

	const handleDown = (item: ColumnsPickerItem) => {
		const newValue = [...value]
		const index = value.findIndex(v => v.uuid === item.uuid)

		if (index < value.length - 1) {
			const b = value[index + 1]
			newValue[index] = b
			newValue[index + 1] = item

			onChange(newValue, field)
		}
	}

	const handleRemove = (item: ColumnsPickerItem) => {
		onChange(
			value.filter(v => v.uuid !== item.uuid),
			field
		)
	}

	const onChangeItemSort = (item: ColumnsPickerItem) => {
		onChange(
			value.map(v =>
				v.uuid !== item.uuid
					? v
					: {
							...v,
							sortType:
								v.sortType === TableIndexColumn.SortTypeEnum.DESC
									? TableIndexColumn.SortTypeEnum.ASC
									: TableIndexColumn.SortTypeEnum.DESC
					  }
			),
			field
		)
	}

	const items = useMemo(
		() =>
			columns
				.filter(c => !value.find(v => v.uuid === c.uuid))
				.reduce((items, column) => {
					items.push({
						value: JSON.stringify({
							uuid: column.uuid,
							code: column.code
						}),
						label: column.name
					})

					return items
				}, [] as ColumnOption[]),
		[columns]
	)

	return (
		<Container>
			<Values>
				{value &&
					value
						.map(col => ({
							item: col,
							col: columns.find(c => c.uuid === col.uuid)
						}))
						.filter(({ col }) => col !== undefined)
						.map(
							({ col, item }, index) =>
								col && (
									<Column key={col.id}>
										{orders && (
											<SortIcon
												sortType={
													item.sortType ?? TableIndexColumn.SortTypeEnum.DESC
												}
											/>
										)}
										<Name>{col.name}</Name>
										<Action
											isHidden={index === 0}
											onClick={() => handleUp(item)}
										>
											<FontAwesomeIcon icon={faArrowUp} />
										</Action>
										<Action
											isHidden={index === value.length - 1}
											onClick={() => handleDown(item)}
										>
											<FontAwesomeIcon icon={faArrowDown} />
										</Action>
										<Action onClick={() => handleRemove(item)}>
											<FontAwesomeIcon icon={faTimes} />
										</Action>
										{orders && (
											<CheckBoxField
												name={'sort' + col.id}
												initialValue={
													item.sortType === TableIndexColumn.SortTypeEnum.DESC
												}
												onChange={() => onChangeItemSort(item)}
												title={TableIndexColumn.SortTypeEnum.DESC}
												showTitlePlaceholder={false}
											/>
										)}
									</Column>
								)
						)}
			</Values>

			<AddAction>
				<SelectField
					name="column"
					options={items}
					initialValue={null}
					onChange={handleColumnChange}
					formless
					compact
					hideTitle
					allowEmpty
				/>
			</AddAction>
		</Container>
	)
}

const Container = styled.div`
	border: 1px solid #ddd;
	width: 100%;
`

const Column = styled.div`
	display: flex;
	padding: 5px 3px;
`

const Values = styled.div`
	padding: 5px;
`

const AddAction = styled.div`
	border-top: 1px solid #ddd;
	display: flex;
	align-items: center;
	margin-top: 10px;
`

const Name = styled.div`
	flex: 1;
`

const Action = styled.div<{ isHidden?: boolean }>`
	margin-left: 5px;
	cursor: pointer;

	${props => css`
		visibility: ${props.isHidden ? 'hidden' : 'initial'};
	`}
`

const SelectField = (styled(SelectFormField)`
	width: 100%;
	border: 0;
	margin-bottom: 1px;
	border-right: 1px solid #ddd;

	.react-select__control {
		border: 0;
	}
` as unknown) as new <T>() => SelectFormField<T>

const CheckBoxField = styled(CheckBoxFormField)`
	width: 42px;
	margin: 0 6px 0 10px;
	flex: 0 1 auto;
	& label {
		height: auto;
		padding: 0 0 0 16px;
	}
`
