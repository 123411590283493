import { createGlobalStyle, css } from 'styled-components'
import reset from './reset'

export const GlobalStyle = createGlobalStyle`
${reset} 

* {
	padding: 0;
	margin: 0;
}

body {
	font-family: Blinker, Arial, Helvetica, sans-serif;
	font-size: 14px;
	${props => css`
		color: ${props.theme.colors.text.base};
	`}
	
}

input[type=text],
input[type=password],
input[type=number],
textarea {
	${props => css`
		border: 1px solid ${props.theme.colors.input.border};
		padding: ${props.theme.input.padding};
		border-radius: ${props.theme.input.borderRadius};
		font-size: ${props.theme.input.fontSize};
		width: ${props.theme.input.width};
		transition: border-color, background-color, box-shadow 0.2s ease;

		:disabled {
			border-color: ${props.theme.colors.input.disabled.border};
			background: ${props.theme.colors.input.disabled.background};
		}
	`}
}

.react-select__option--is-focused.react-select__option--is-selected {
			background: #000 !important;
}

button, a {
	cursor: pointer;
}

.ace_editor, .ace_editor * {
	font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
	font-size: 12px !important;
	font-weight: 400 !important;
	letter-spacing: 0 !important;
}
`
