import React, { Suspense } from 'react'
import { Tabs } from '@/components/Tabs/Tabs'
import { StructureDto } from '@/api/models'
import { Overview } from '../pages/Overview/Overview'
import {
	FolderData,
	OpenedFolderData,
	OpenedFolderTab
} from '@/store/modules/folder/types'
import { Loader } from '@/components'
import { useAppContext } from '@/utils/hooks'
import { Validation } from '../../../components/Validation/Validation'
import { Dependencies } from '../../../components/Dependencies/Dependencies'

const Diagram = React.lazy(() =>
	import(/* webpackChunkName: "diagram" */ '../pages/Diagram/Diagram')
)

type Props = {
	node: StructureDto
	onChange: (v: Partial<FolderData>) => void
	editMode: boolean
	data: OpenedFolderData
	systemNodeId: number
}

export const FolderTabs = ({
	node,
	onChange,
	editMode,
	data,
	systemNodeId
}: Props) => {
	const { t } = useAppContext()

	return (
		<Tabs
			tabs={[
				{
					id: OpenedFolderTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							key={OpenedFolderTab.Overview}
							node={node}
							readOnly={!editMode}
							onChange={onChange}
							data={data}
							systemNodeId={systemNodeId}
						/>
					)
				},
				{
					id: OpenedFolderTab.Diagram,
					title: t('TAB_DIAGRAM'),
					content: (
						<Suspense
							key={OpenedFolderTab.Diagram}
							fallback={<Loader loaded={false} />}
						>
							<Diagram data={data} nodeId={node.id} onChange={onChange} />
						</Suspense>
					)
				},
				{
					id: OpenedFolderTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={OpenedFolderTab.Validation} node={node} />
				},
				{
					id: OpenedFolderTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={OpenedFolderTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					)
				}
			]}
		/>
	)
}
