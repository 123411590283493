import React, { useMemo } from 'react'
import { FullTextSearchDto } from '@/api/models'
import styled from 'styled-components'
import {
	HeadlineBitType,
	parseHeadline,
	HeadlineBit
} from '../parsers/headline'
import { EntityTypeIcon } from '@/pages/User/pages/Home/components/EntityTypeIcon'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import { openTab } from '@/store/modules/tab/actions'
import { getNodeDetail } from '@/api'
import { useApiRequest } from '@/api/hooks'

type Props = {
	item: FullTextSearchDto
}

const renderBit = (bit: HeadlineBit, index: number) => {
	switch (bit.type) {
		case HeadlineBitType.Match: {
			return <Match key={index}>{bit.text}</Match>
		}

		case HeadlineBitType.Text: {
			return <Text key={index}>{bit.text}</Text>
		}

		case HeadlineBitType.NewLine: {
			return ''
		}
	}
}

export const Result = ({ item }: Props) => {
	const dispatch = useAppDispatch()
	const request = useApiRequest()

	const nodes = useAppStore(state => state.node.nodes)

	const headline = useMemo(
		() => (item.headline ? parseHeadline(item.headline) : []),
		[item]
	)

	const handleClick = async (e: React.MouseEvent) => {
		if (e.button === 1) {
			e.preventDefault()
		}

		if (item.id !== undefined) {
			const { data: node } =
				{ data: nodes[item.id] } || (await request(getNodeDetail(item.id)))

			if (node) {
				dispatch(openTab(node, false))
			}
		}
	}

	return (
		<C onMouseDown={handleClick}>
			<Name>
				{item.type && (
					<Icon>
						<EntityTypeIcon type={item.type} />
					</Icon>
				)}
				{item.name}
			</Name>
			<Data>{headline.map(renderBit)}</Data>
		</C>
	)
}

const C = styled.div`
	cursor: pointer;
	padding: 0.5rem 0.5rem;

	&:hover {
		background-color: #eee;
	}
`

const Name = styled.div``

const Icon = styled.span`
	margin-right: 0.2rem;
`

const Data = styled.div`
	overflow: hidden;
	color: #999;
`

const Match = styled.span`
	font-weight: bold;
`

const Text = styled.span``
