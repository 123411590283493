import React, { useMemo, useState } from 'react'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { StructureDto, NewHistoryDto } from '@/api/models'
import {
	CheckBoxFormField,
	SelectFormField,
	TextAreaFormField
} from '@/components/UberForm'
import { unlockNode, loadNodeChildren } from '@/store/modules/node/actions'
import { pushHistory } from '@/store/modules/history/actions'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/utils/hooks'
import styled from 'styled-components'
import { Flex } from '@/components/Layout'
import { cancelEditingTab } from '@/store/modules/tab/actions'
import { useTabContext } from '@/context/TabContext/TabContext'
import { getApiRef, useApi, useApiRequest } from '@/api/hooks'
import {
	addStructureToRelease,
	getAllNodeHistory,
	getNodeDetail,
	getSystemReleases
} from '@/api'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { RELEASE_OBJECTS_FORBIDDEN } from '../../pages/SystemDetail/pages/Releases/constants'
import { loadNode } from '@/store/modules/node/general-actions'
import { usePrePushValidation } from './hooks/usePrePushValidation'
import { ValidationsModal } from './components/ValidationsModal'

type Props = {
	onClose: () => void
	beforePush?: () => Promise<void>
	afterPush?: (values: Partial<FormDataPushModal>) => void
	/** Node for single edit */
	node?: StructureDto
	/** Nodes for batch edit */
	nodes?: StructureDto[]
	onExitEditMode?: (node: StructureDto) => Promise<void>
}

const FIX_IMPACTS_ELEMENT_TYPES = [StructureDto.TypeEnum.TABLE]

export type FormDataPushModal = NewHistoryDto & {
	exitEditMode: boolean
	unlockNode?: boolean
	releaseId?: number
	fixImpacts?: boolean
}

export const PushModal = ({
	node: nodeInit,
	onExitEditMode,
	onClose,
	beforePush,
	afterPush,
	nodes
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const [exitEditMode, setExitEditMode] = useState(nodes ? true : false)
	const { onSaveError } = useTabContext()
	const nodesMap = useAppStore(state => state.node.nodes)
	const { isLoading, validations } = usePrePushValidation(nodeInit?.id)

	const node = useMemo(() => (nodeInit ? nodeInit : nodes?.[0]), [
		nodeInit,
		nodes
	]) as StructureDto

	const systemId = useMemo(() => findSystemNodeId(node, nodesMap), [
		node,
		nodesMap
	])

	const request = useApiRequest()

	const { data: releases, loading } = useApi(getSystemReleases(systemId))

	const canAddToRelease = useMemo(
		() => !RELEASE_OBJECTS_FORBIDDEN.includes(node.type),
		[node.type]
	)

	const handleSubmit = async (form: Partial<FormDataPushModal>) => {
		try {
			await beforePush?.()

			// Batch edit
			if (nodes) {
				if (form.releaseId) {
					const releases = nodes.map(node =>
						request(addStructureToRelease(systemId, form.releaseId!, [node.id]))
					)

					await Promise.allSettled(releases)
				}

				const histories = nodes.map(node =>
					dispatch(
						pushHistory(node, {
							description: form.description || ''
						})
					)
				)

				await Promise.allSettled(histories)

				const children = nodes.map(node => dispatch(loadNodeChildren(node.id)))

				await Promise.allSettled(children)
				const nodesLoaded = nodes.map(node => dispatch(loadNode(node.id)))
				await Promise.allSettled(nodesLoaded)

				nodes.forEach(node => {
					getApiRef(getAllNodeHistory(node.id)).invalidate()
					getApiRef(getNodeDetail(node.id)).invalidate()
				})

				if (form.exitEditMode) {
					nodes.forEach(node => dispatch(cancelEditingTab(node)))

					if (form.unlockNode) {
						const unlocks = nodes.map(node => dispatch(unlockNode(node)))
						await Promise.allSettled(unlocks)
					}
					// TODO: Maybe needed even for batch edit?
					// await onExitEditMode(node)
				}
			}
			// Single element edit
			else {
				if (form.releaseId) {
					await request(
						addStructureToRelease(systemId, form.releaseId, [node.id])
					)
				}

				await dispatch(
					pushHistory(
						node,
						{
							description: form.description || ''
						},
						form.fixImpacts
					)
				)

				await dispatch(loadNodeChildren(node.id))
				await dispatch(loadNode(node.id))
				getApiRef(getAllNodeHistory(node.id)).invalidate()
				getApiRef(getNodeDetail(node.id)).invalidate()

				if (form.exitEditMode) {
					dispatch(cancelEditingTab(node))
					form.unlockNode && (await dispatch(unlockNode(node)))
					await onExitEditMode?.(node)
				}
			}
		} catch (e) {
			onSaveError(e)
		}

		afterPush?.(form)
		onClose()
	}

	return (
		<ModalForm<FormDataPushModal>
			open={true}
			saveTitle={t('PUSH')}
			saveIcon={faUpload}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			onClose={onClose}
			contentStyle={{
				width: '440px'
			}}
			header={t('PUSH_MODAL_HEADER')}
			initialValues={{
				exitEditMode: nodes ? true : false,
				unlockNode: nodes ? true : false
			}}
			isLoading={isLoading}
			footerBefore={<ValidationsModal validations={validations} />}
		>
			<TextAreaFormField
				title={t('PUSH_DESCRIPTION')}
				name="description"
				required={true}
			/>
			<Flex>
				<Checkbox
					name="exitEditMode"
					onChange={value => setExitEditMode(value as boolean)}
					title={t('PUSH_EXIT_EDIT_MODE')}
				/>
				{exitEditMode && (
					<Checkbox
						name="unlockNode"
						title={nodes ? t('PUSH_LOCK_NODES') : t('PUSH_LOCK_NODE')}
					/>
				)}
				{nodeInit &&
					FIX_IMPACTS_ELEMENT_TYPES.includes(nodeInit.type) &&
					validations.length > 0 && (
						<Checkbox name="fixImpacts" title={t('FIX_IMPACTS')} />
					)}
			</Flex>

			{canAddToRelease && (
				<SelectFormField
					name="releaseId"
					title={
						nodes ? t('ADD_ELEMENTS_TO_RELEASE') : t('ADD_ELEMENT_TO_RELEASE')
					}
					options={releases}
					valueKey="id"
					labelKey="name"
					allowEmpty
					clearable
					loading={loading}
				/>
			)}
		</ModalForm>
	)
}

const Checkbox = styled(CheckBoxFormField)`
	flex: 1;
`
