import { StructureDto } from '@/api/models'
import { StoreState } from '@/store'
import { TableMode } from '@/store/modules/table/types'
import { AppDispatch } from '@/store/utils'
import { useMemo } from 'react'
import { getStructureTypeActions } from './getStructureTypeActions'

export type InitDataParams = {
	nodeId: number
	abortController?: AbortController
	version?: number
	editMode?: boolean
	mode?: TableMode
	force?: boolean
	envId?: number
}

export type StructureTypeActions = {
	initData: (params: InitDataParams) => (dispatch: AppDispatch) => Promise<void>
	updateData: (
		node: StructureDto,
		update: Record<string, any>,
		mode?: TableMode
	) => unknown
	saveData: (
		node: StructureDto
	) => (dispatch: AppDispatch, getState: () => StoreState) => Promise<any>
}

/** Get redux actions for specific structure type */
export const useStructureTypeActions = (type: StructureDto.TypeEnum) => {
	const structureTypeActions = useMemo<StructureTypeActions>(
		() => getStructureTypeActions(type),
		[type]
	)

	return structureTypeActions
}
