import React, { useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '@/utils/hooks'
import {
	faUser,
	faSignOutAlt,
	faCaretDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { logout } from '@/store/modules/auth/actions'
import { useAppContext, useAppStore } from '@/utils/hooks'
import { DropdownMenu } from '@/components'
import { KeycloakProfile } from 'keycloak-js'
import { useKeycloak } from '@react-keycloak/web'

const userProfile: KeycloakProfile = {}

const Userbar = ({ className = '' }: { className?: string }) => {
	const dispatch = useAppDispatch()
	const { keycloak } = useKeycloak()
	const { t } = useAppContext()

	const handleLogoutClick = () => {
		dispatch(logout())
	}

	if (!keycloak.authenticated) {
		return <></>
	}

	keycloak.loadUserProfile().then(value => {
		userProfile.firstName = value.firstName
		userProfile.lastName = value.lastName
	})

	return (
		<>
			<DropdownMenu
				trigger={isOpen => (
					<UserbarButton
						className={className}
						isOpen={isOpen}
						aria-haspopup="true"
					>
						<UserName>
							{userProfile.firstName + ' ' + userProfile.lastName}
						</UserName>
						<UserIcon icon={faUser} />
						<UserDropIcon icon={faCaretDown} />
					</UserbarButton>
				)}
				contentStyle={{ padding: '0', width: '220px' }}
			>
				<Container role="menu">
					<UserBackgroundIcon icon={faUser} />
					<MenuItem>
						{userProfile.firstName + ' ' + userProfile.lastName}
					</MenuItem>
					<MenuItem>{keycloak.realm}</MenuItem>
					{/*<MenuItemLink onClick={() => setChangePasswordModal(true)}>
						{t('CHANGE_PASSWORD')}
					</MenuItemLink>*/}
					<Separator />
					<MenuButton onClick={handleLogoutClick} role="menuitem">
						<MenuButtonIcon icon={faSignOutAlt} />
						<MenuButtonText>{t('LOGOUT')}</MenuButtonText>
					</MenuButton>
				</Container>
			</DropdownMenu>
			{/*{changePasswordModal && (
				<ChangePassword
					open={changePasswordModal}
					onClose={() => setChangePasswordModal(false)}
				/>
			)}*/}
		</>
	)
}

export default Userbar

const UserbarButton = styled.button<{ isOpen: boolean }>`
	font-size: 16px;
	user-select: none;
	cursor: pointer;
	color: white;
	&:hover {
		color: #f0f0f0;
	}
`

const Container = styled.div`
	padding: 5px 0 5px 0;
	position: relative;
	color: ${props => props.theme.colors.text.base};

	box-shadow: ${props => props.theme.styles.rightMenusShadows};
`

const MenuButton = styled.button`
	background: transparent;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	padding: 8px 12px;
	display: flex;
	cursor: pointer;
	width: 100%;
	&:hover {
		background: ${props => props.theme.colors.button.primary.background};
		color: white;
	}
`

const Separator = styled.div`
	height: 5px;
`

const UserIcon = styled(FontAwesomeIcon)`
	margin: 0 4px 0 8px;
`

const UserName = styled.div``

const UserDropIcon = styled(FontAwesomeIcon)``

const MenuButtonIcon = styled(FontAwesomeIcon)`
	flex: 0;
`

const UserBackgroundIcon = styled(FontAwesomeIcon)`
	position: absolute;
	opacity: 0.15;
	top: 13px;
	left: 15px;
	font-size: 64px;
`

const MenuButtonText = styled.div`
	flex: 1;
`

const MenuItem = styled.div`
	padding: 6px 10px;
	text-align: right;
`

const MenuItemLink = styled(MenuItem)`
	cursor: pointer;
	color: ${props => props.theme.mainColors.primary};
`
