import React, { useCallback, useState, useEffect, useRef } from 'react'
import {
	Header,
	Footer,
	Body,
	Dialog,
	PopupBackground,
	Popup,
	Maximizer,
	Resizer
} from './styles'
import { Portal } from '../Portal/Portal'
import { CSSProperties } from 'styled-components'
import { useWindowEvent, useAppStore } from '@/utils/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { saveDialogSize } from '@/store/modules/user/actions'
import mainColors from '@/styles/mainColors'

interface ResizeData {
	width: number
	height: number
	isMaximized: boolean
}

export interface ModalProps {
	children: React.ReactNode | ((close: () => void) => React.ReactNode)
	contentStyle?: React.CSSProperties
	headerStyle?: CSSProperties
	bodyStyle?: CSSProperties
	footerStyle?: CSSProperties
	header?: React.ReactNode | ((close: () => void) => React.ReactNode)
	footer?: React.ReactNode | ((close: () => void) => React.ReactNode)
	open?: boolean
	onClose?: () => void
	disablePortal?: boolean
	closeOnEscape?: boolean
	stretchFooterButtons?: boolean
	resizable?: boolean
	maximizeButtonVisible?: boolean
	dialogId?: string
	zIndex?: number
	maximized?: boolean
	stickyFooter?: boolean
}

const RELATIVE_MAX_WIDTH = 0.98
const RELATIVE_MAX_HEIGHT = 0.98

const getResizedStyle = (
	resizedData: ResizeData,
	isResizing: boolean
): CSSProperties => {
	const resizingStyle = !isResizing
		? {
				transitionDuration: '0.25s',
				transitionProperty: 'all',
				transitionTimingFunction: 'ease-in'
		  }
		: {}

	if (resizedData.isMaximized) {
		return {
			width: `${RELATIVE_MAX_WIDTH * 100}%`,
			height: `${RELATIVE_MAX_HEIGHT * 100}%`,
			...resizingStyle
		}
	} else {
		return {
			width: resizedData.width <= 0 ? 1 : resizedData.width,
			height: resizedData.height <= 0 ? 1 : resizedData.height,
			...resizingStyle
		}
	}
}

const getResizedData = (e: MouseEvent): ResizeData => {
	const width = (e.clientX - window.innerWidth / 2) * 2
	const height = (e.clientY - window.innerHeight / 2) * 2

	return {
		width: width <= 0 ? 1 : width,
		height: height <= 0 ? 1 : height,
		isMaximized:
			window.innerWidth * RELATIVE_MAX_WIDTH < width &&
			window.innerHeight * RELATIVE_MAX_HEIGHT < height
	}
}

export const Modal = ({
	children,
	contentStyle,
	header,
	footer,
	open,
	onClose,
	disablePortal,
	headerStyle,
	footerStyle,
	bodyStyle,
	stretchFooterButtons = true,
	closeOnEscape = true,
	maximizeButtonVisible,
	resizable,
	dialogId,
	zIndex,
	maximized,
	stickyFooter
}: ModalProps) => {
	const stopEvent = useCallback((e: React.MouseEvent) => {
		e.nativeEvent.stopImmediatePropagation()
	}, [])

	const refMaximized = useRef(false)
	const [isResizing, setIsResizing] = useState(false)
	const [resizedData, setResizedData] = useState({} as ResizeData)
	const { dialog } = useAppStore(state => state.user)
	const dispatch = useDispatch()

	useEffect(() => {
		if (dialogId) {
			const dialogSize = dialog[dialogId]

			if (dialogSize) {
				setResizedData({
					width: dialogSize.width,
					height: dialogSize.height,
					isMaximized:
						dialogSize.isMaximized ||
						(window.innerWidth * RELATIVE_MAX_WIDTH < dialogSize.width &&
							window.innerHeight * RELATIVE_MAX_HEIGHT < dialogSize.height)
				})
			}
		}
	}, [])

	useWindowEvent('keyup', (e: KeyboardEvent) => {
		if (closeOnEscape && open && onClose && e.key === 'Escape') {
			onClose()
		}
	})

	useWindowEvent('mouseup', () => {
		if (isResizing) {
			setIsResizing(false)
			trySaveCurrentDialogData(resizedData)
		}
	})

	const trySaveCurrentDialogData = useCallback(
		(data: ResizeData) => {
			if (dialogId) {
				dispatch(
					saveDialogSize(
						dialogId,
						data.width <= 0 ? 1 : data.width,
						data.height <= 0 ? 1 : data.height,
						data.isMaximized
					)
				)
			}
		},
		[dialogId, dispatch]
	)

	useWindowEvent('mousemove', (e: MouseEvent) => {
		if (isResizing) {
			e.preventDefault()
			setResizedData(getResizedData(e))
		}
	})

	const onResizerMouseDown = () => {
		setIsResizing(true)
	}

	const onMaximizeClick = useCallback(() => {
		if (!maximizeButtonVisible) {
			return
		}

		const data = { ...resizedData, isMaximized: !resizedData.isMaximized }

		if (
			!data.isMaximized &&
			window.innerWidth * RELATIVE_MAX_WIDTH < data.width &&
			window.innerHeight * RELATIVE_MAX_HEIGHT < data.height
		) {
			data.width = window.innerWidth / 1.5
			data.height = window.innerHeight / 1.75
		}

		setResizedData(data)
		trySaveCurrentDialogData(data)
	}, [maximizeButtonVisible, resizedData, trySaveCurrentDialogData])

	const getPopupStyle = () => {
		if (resizable) {
			const resizedStyle: CSSProperties = getResizedStyle(
				resizedData,
				isResizing
			)

			return { minWidth: 400, minHeight: 400, ...contentStyle, ...resizedStyle }
		} else {
			return contentStyle
		}
	}

	useEffect(() => {
		if (maximized && !refMaximized.current) {
			onMaximizeClick()
			refMaximized.current = true
		}
	}, [maximized, onMaximizeClick])

	const popup = (
		<>
			{open && (
				<PopupBackground zIndex={zIndex}>
					<Popup style={getPopupStyle() as React.CSSProperties}>
						<Dialog role="dialog" onClick={stopEvent}>
							{header && (
								<Header
									style={headerStyle as React.CSSProperties}
									onDoubleClick={onMaximizeClick}
								>
									{typeof header === 'function'
										? header(onClose || (() => null))
										: header}
									{maximizeButtonVisible && (
										<Maximizer onClick={onMaximizeClick}>
											<FontAwesomeIcon
												icon={faWindowMaximize}
												color={
													resizedData?.isMaximized ? mainColors.primary : 'gray'
												}
											/>
										</Maximizer>
									)}
								</Header>
							)}
							<Body style={bodyStyle as React.CSSProperties}>
								{typeof children === 'function'
									? children(onClose || (() => null))
									: children}
							</Body>
							{footer && (
								<Footer
									style={footerStyle as React.CSSProperties}
									stretchFooterButtons={stretchFooterButtons}
									stickyFooter={stickyFooter}
								>
									{typeof footer === 'function'
										? footer(onClose || (() => null))
										: footer}
								</Footer>
							)}
						</Dialog>
						{resizable && <Resizer onMouseDown={onResizerMouseDown} />}
					</Popup>
				</PopupBackground>
			)}
		</>
	)

	if (disablePortal) {
		return popup
	} else {
		return <Portal>{popup}</Portal>
	}
}
