import React from 'react'
import { Portal } from '../Portal/Portal'
import { ModalProps, Modal } from './Modal'
import Form, { FormProps } from '../UberForm/Form'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Button, { Schema } from '../Button/Button'
import { Submit } from '../UberForm'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { splitProps } from '@/utils/collections'
import { useAppContext } from '@/utils/hooks'

interface Props<T = {}> extends FormProps<T>, ModalProps {
	saveTitle?: string
	saveIcon?: IconProp
	saveSchema?: Schema
	cancelTitle?: string
	cancelIcon?: IconProp
	cancelSchema?: Schema
	formRef?: React.RefObject<Form<T>>
	footerBefore?: React.ReactNode
}

export const ModalForm = <T extends object>(props: Props<T>) => {
	const { t } = useAppContext()

	const {
		saveTitle,
		saveIcon,
		saveSchema,
		cancelTitle,
		cancelIcon,
		cancelSchema,
		children,
		footer,
		footerBefore
	} = props

	let iconSave: IconProp = faCheck

	if (saveIcon) {
		iconSave = saveIcon
	}

	let iconCancel: IconProp = faTimes

	if (cancelIcon) {
		iconCancel = cancelIcon
	}

	const formProps = { ...props }

	const modalProps = splitProps(formProps, [
		'children',
		'contentStyle',
		'bodyStyle',
		'header',
		'footer',
		'open',
		'onClose',
		'disablePortal',
		'headerStyle',
		'resizable',
		'maximizeButtonVisible',
		'maximized'
	])

	return (
		<Portal>
			<Form<T> ref={props.formRef} {...formProps}>
				<Modal
					{...modalProps}
					disablePortal
					footer={close =>
						footer || (
							<>
								{footerBefore}
								<Submit schema={saveSchema || 'success'} icon={iconSave}>
									{saveTitle || t('SAVE')}
								</Submit>
								<Button
									onClick={close}
									schema={cancelSchema || 'transparent'}
									icon={iconCancel}
								>
									{cancelTitle || t('CANCEL')}
								</Button>
							</>
						)
					}
				>
					{children}
				</Modal>
			</Form>
		</Portal>
	)
}
