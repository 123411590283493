import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { DomainDto } from '@/api/models'
import { ItemProperty, ItemPropertyType } from '@/components/Properties/types'
import { SystemMapping } from '@/api/schemas'

export const SystemLookupProperties = memoizeOne(
	(
		t: TranslationHelper,
		domains: DomainDto[]
	): ItemProperty<SystemMapping>[] => [
		{
			field: 'name',
			label: t('FIELD_NAME'),
			type: ItemPropertyType.TEXT,
			showWhenPanelOpened: true
		},
		{
			field: 'code',
			label: t('FIELD_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			showWhenPanelOpened: true
		},
		{
			field: 'domainId',
			label: t('FIELD_DOMAIN'),
			type: ItemPropertyType.OPTION,
			options: domains,
			optionValue: 'id',
			optionLabel: 'name',
			isNumeric: true
		},
		{
			field: 'dataType',
			label: t('DOMAIN_DATA_TYPE'),
			type: ItemPropertyType.TEXT,
			disabled: true			
		},
		{
			field: 'value',
			label: t('FIELD_SCRIPT'),
			type: ItemPropertyType.TEXTAREA,
			flex: 2
		},
		{
			field: 'description',
			label: t('FIELD_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 2
		}
	]
)
