import React, { useMemo } from 'react'
import { Tabs } from '@/components/Tabs/Tabs'
import { Workflows } from '../pages/Workflows'
import { StructureDto } from '@/api/models'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { selectTabInTab } from '@/store/modules/tab/actions'
import { Validation } from '../../../components/Validation/Validation'
import { Dependencies } from '../../../components/Dependencies/Dependencies'

export enum WorkflowFolderTab {
	Workflows = 'Workflows',
	Validation = 'Validation',
	Dependencies = 'Dependencies'
}

export const WorkflowFolderTabs = ({
	node,
	selectedTab,
	editMode
}: {
	node: StructureDto
	selectedTab: string | null
	editMode: boolean
}) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const tabs = useMemo(
		() => [
			{
				id: WorkflowFolderTab.Workflows,
				title: t('TAB_WORKFLOWS'),
				content: <Workflows node={node} key={WorkflowFolderTab.Workflows} />
			},
			{
				id: WorkflowFolderTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={WorkflowFolderTab.Validation} node={node} />
			},
			{
				id: WorkflowFolderTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={WorkflowFolderTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				)
			}
		],
		[editMode, node, t]
	)

	return (
		<Tabs
			onChange={tab => dispatch(selectTabInTab(node.id, tab.id as string))}
			selectedTabId={selectedTab || WorkflowFolderTab.Workflows}
			tabs={tabs}
		/>
	)
}
