import { StructureDto, StructureDetailDto } from '@/api/models'
import { WriteMappingData } from '@/api/schemas'
import { WriteMappingTab, WriteMappingDataForm } from './types'
import {
	MAPPING_WRITE_INIT,
	MAPPING_WRITE_SAVE,
	MAPPING_WRITE_UPDATE,
	MAPPING_WRITE_SELECT_TAB
} from './constants'
import { apiCallAction, AppDispatch, UpdateDeepPartial } from '@/store/utils'
import { updateDataNode } from '@/api'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { loadNodeOrHistoryVersion } from '../node/utils'

interface InitWriteMapping {
	type: typeof MAPPING_WRITE_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

interface SaveWriteMapping {
	type: typeof MAPPING_WRITE_SAVE
	payload: void
	metadata: {
		node: StructureDto
	}
}

interface UpdateWriteMapping {
	type: typeof MAPPING_WRITE_UPDATE
	node: StructureDto
	update: UpdateDeepPartial<WriteMappingDataForm>
}

interface SelectWriteMappingTab {
	type: typeof MAPPING_WRITE_SELECT_TAB
	node: StructureDto
	tab: WriteMappingTab
}

export const initWriteMapping = ({
	nodeId,
	editMode = false,
	force = false,
	version,
	envId
}: InitDataParams) => async (dispatch: AppDispatch) => {
	const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

	dispatch({
		type: MAPPING_WRITE_INIT,
		node,
		editMode,
		force
	} as InitWriteMapping)
}

export const updateWriteMapping = (
	node: StructureDto,
	update: UpdateDeepPartial<WriteMappingDataForm>
): Actions => ({
	type: MAPPING_WRITE_UPDATE,
	node,
	update
})

export const saveWriteMapping = (node: StructureDto) =>
	apiCallAction(
		getState => {
			const mapping = getState().writeMapping.mappings[node.id]

			if (!mapping) {
				throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
			}

			const formData: WriteMappingData = {
				...mapping.form,
				columns: (mapping.form.columns || []).filter(c => !!c.columnCode)
			}

			return updateDataNode(node.id, {
				data: JSON.stringify(formData)
			})
		},
		MAPPING_WRITE_SAVE,
		{ node }
	)

export const selectWriteMappingTab = (
	node: StructureDto,
	tab: WriteMappingTab
): Actions => ({
	type: MAPPING_WRITE_SELECT_TAB,
	node,
	tab
})

export type Actions =
	| InitWriteMapping
	| SaveWriteMapping
	| UpdateWriteMapping
	| SelectWriteMappingTab
