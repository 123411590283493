import React, { useCallback, useEffect, useState } from 'react'
import { getSystemRelease, updateSystemRelease } from '@/api'
import { useApi, useApiRequest } from '@/api/hooks'
import { StructureDto } from '@/api/models'
import { Container } from '../../../../TableDetail/components/StyledComponents'
import { Button, Loader } from '@/components'
import { Form, TextAreaFormField, TextFormField } from '@/components/UberForm'
import {
	useAppContext,
	useAppDispatch,
	useDebounce,
	useDebounceCallback
} from '@/utils/hooks'
import styled from 'styled-components'
import { Col, Flex, Row } from '@/components/Layout'
import { saveSystem, updateSystem } from '@/store/modules/system/actions'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { ReleaseEnvironments } from './Environments/ReleaseEnvironments'
import { ReleaseObjects } from './Objects/ReleaseObjects'

type Props = {
	node: StructureDto
	selectedReleaseId: number
	editMode: boolean
	reloadReleases: () => void
}

type FormData = {
	name: string
	description: string
}

export const ReleaseDetail = ({
	node,
	selectedReleaseId,
	editMode,
	reloadReleases
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const request = useApiRequest()
	const [formData, setFormData] = useState<FormData | undefined>(undefined)

	const { data: release, loading, invalidate } = useApi(
		getSystemRelease(node.id, selectedReleaseId)
	)

	const updateRequest = useDebounceCallback(async () => {
		if (!formData?.name) {
			return
		}

		await request(
			updateSystemRelease(node.id, selectedReleaseId, {
				...release,
				...formData
			})
		)

		reloadReleases()
	}, 300)

	useEffect(() => {
		invalidate()
	}, [])

	const onChange = (values: FormData) => {
		setFormData(values)
		updateRequest()
	}

	const goBack = useCallback(() => {
		dispatch(
			updateSystem(node, {
				selectedReleaseId: undefined
			})
		)

		if (editMode) {
			dispatch(saveSystem(node))
		}
	}, [node, dispatch, editMode])

	if (loading) {
		return <Loader loaded={!loading} />
	}

	if (!release) {
		return (
			<Container>
				<HeaderContainer>
					<Header>{t('RELEASE_DETAIL')}</Header>
					<ButtonList
						onClick={goBack}
						schema="primaryOutlined"
						icon={faArrowLeft}
					>
						{t('LIST_OF_RELEASES')}
					</ButtonList>
				</HeaderContainer>
				{t('RELEASE_NOT_FOUND')}
			</Container>
		)
	}

	return (
		<Container>
			<Form<FormData>
				initialValues={{
					name: release?.name,
					description: release?.description
				}}
				onChange={onChange}
				disabled={!editMode}
			>
				<HeaderContainer>
					<Header>{t('RELEASE_DETAIL')}</Header>
					<ButtonList
						onClick={goBack}
						schema="primaryOutlined"
						icon={faArrowLeft}
					>
						{t('LIST_OF_RELEASES')}
					</ButtonList>
				</HeaderContainer>

				<Row>
					<Col size="custom" width={['100%', '100%', '50%', '35%', '15%']}>
						<TextFormField
							title={t('NAME')}
							name="name"
							required
							enableAutocomplete={false}
						/>
					</Col>

					<Col size="custom" width={['100%', '100%', '50%', '35%', '15%']}>
						<TextAreaFormField title={t('DESCRIPTION')} name="description" />
					</Col>
					<Col size="custom" width={['100%', '100%', '100%', '100%', '70%']}>
						<ReleaseEnvironments
							node={node}
							release={release}
							editMode={editMode}
						/>
					</Col>
				</Row>

				<ReleaseObjects node={node} release={release} editMode={editMode} />
			</Form>
		</Container>
	)
}

const HeaderContainer = styled(Flex)`
	margin-bottom: 10px;
`

const Header = styled.div`
	font-size: 18px;
	margin-right: 6px;
`

const ButtonList = styled(Button)`
	margin-right: 10px;
`
