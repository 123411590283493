import React, { useState } from 'react'
import styled from 'styled-components'

import { DetailTab } from '../DetailTab/DetailTab'
import { closeTab, cancelEditingTab } from '@/store/modules/tab/actions'
import { StructureDto } from '@/api/models'
import { CancelEditModal } from '../CancelEditModal'
import { Head } from './components/Head'
import { useAppStore, useAppDispatch, useAppContext } from '@/utils/hooks'
import { MessageModal } from '@/components'
import { loadNodeChildren } from '@/store/modules/node/actions'
import { ApiError } from '@/api/utils'
import { TabContext } from '@/context/TabContext/TabContext'
import { OpenedTab } from '@/store/modules/tab/types'

export const ViewPanel = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const nodes = useAppStore(state => state.node.nodes)
	const openedTabs = useAppStore(state => state.tab.openedTabs)
	const selectedTab = useAppStore(state => state.tab.selectedTab)

	const [message, setMessage] = useState(null)
	const [nodesToConfirm, setNodesToConfirm] = useState([] as StructureDto[])

	const currentTab =
		selectedTab !== undefined ? openedTabs[selectedTab] : undefined

	const currentNode = currentTab ? nodes[currentTab.nodeId] : undefined

	const handleSaveError = async (e: unknown) => {
		if (currentNode) {
			dispatch(cancelEditingTab(currentNode))
			dispatch(loadNodeChildren(currentNode.parentStructureId as number))
		}

		if (e instanceof ApiError && e.response.status === 423) {
			const data = await e.response.json()
			setMessage(data.errorDescription)
		} else {
			throw e
		}
	}

	const handleCloseConfirm = () => {
		setNodesToConfirm(nodesToConfirm.slice(1))
	}

	const handleCloseRequest = (nodes: StructureDto[]) => {
		setNodesToConfirm(nodes)
	}

	if (!openedTabs || openedTabs.length === 0) {
		return <></>
	}

	return (
		<TabContext.Provider
			value={{ tab: currentTab as OpenedTab, onSaveError: handleSaveError }}
		>
			<Container role="main">
				<Head onConfirm={handleCloseRequest} />
				<TabContent role="tabpanel">
					{currentNode && currentTab && (
						<DetailTab
							tab={currentTab}
							node={currentNode}
							key={`${currentTab.nodeId}`}
						/>
					)}
				</TabContent>

				<MessageModal
					title={t('ERROR_WHEN_SAVING')}
					opened={!!message}
					onClose={() => setMessage(null)}
					message={message || ''}
					type="warn"
				/>

				{nodesToConfirm.length > 0 && (
					<CancelEditModal
						node={nodesToConfirm[0]}
						onConfirm={async () => {
							dispatch(closeTab(nodesToConfirm[0].id))
						}}
						onClose={handleCloseConfirm}
					/>
				)}
			</Container>
		</TabContext.Provider>
	)
}

const Container = styled.div`
	padding: 0;
	height: 100%;
	display: flex;
	overflow: hidden;
	flex-direction: column;
`

const TabContent = styled.div`
	z-index: 1;
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
	overflow: auto;
	background-color: ${props =>
		props.theme.colors.layout.tabContentBackgroundColor};
`
