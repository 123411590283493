import { OpenedTab } from './types'
import {
	TAB_OPEN,
	TAB_EDIT,
	TAB_CANCEL_EDIT,
	TAB_SELECT,
	TAB_CLOSE,
	TAB_SET_PERMANENT,
	TAB_SET_PROPERTIES_SHOWN,
	TAB_SET_PROPERTY_ITEM,
	TAB_CLOSE_RELATED,
	TAB_IMPORT_STATE,
	TAB_SELECT_TAB,
	TAB_SET_PROPERTIES_WIDTH
} from './constants'
import { findSystemNodeId } from '../node/helpers'
import { Actions } from './actions'
import { closeDeletedTabs } from './helpers'

export type State = Readonly<typeof initialState>
export type TabSession = State

const initialState = {
	openedTabs: [] as OpenedTab[],
	selectedId: undefined as number | undefined,
	selectedTab: undefined as number | undefined,
	temporaryTab: undefined as number | undefined
}

export const getTabSession = (state: State) => ({
	...state
})

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case TAB_OPEN: {
			const { node, nodes, editMode } = action

			const opened = state.openedTabs.findIndex(item => item.nodeId === node.id)

			if (opened >= 0) {
				return {
					...state,
					selectedId: node.id,
					selectedTab: opened,
					temporaryTab:
						opened === state.temporaryTab && !action.replace
							? undefined
							: state.temporaryTab
				}
			}

			const item: OpenedTab = {
				nodeId: node.id,
				parentNodeId: node.parentStructureId,
				systemNodeId: findSystemNodeId(node, nodes),
				editMode,
				propertiesShown: {},
				properitesItems: {},
				selectedTab: null
			}

			const openedTabs = [...state.openedTabs]
			let selectedTab = state.selectedTab
			let temporaryTab = state.temporaryTab

			if (action.replace) {
				if (temporaryTab === undefined) {
					temporaryTab =
						selectedTab === undefined
							? openedTabs.length > 0
								? openedTabs.length - 1
								: 0
							: selectedTab + 1

					openedTabs.splice(temporaryTab, 0, item)
				} else {
					openedTabs[temporaryTab] = item
				}

				selectedTab = temporaryTab
			} else {
				openedTabs.push(item)
				selectedTab = state.openedTabs.length
			}

			return {
				...state,
				selectedId: node.id,
				openedTabs,
				selectedTab,
				temporaryTab
			}
		}

		case TAB_EDIT: {
			const openedTabs: OpenedTab[] = state.openedTabs.map(tab => ({
				...tab,
				editMode: tab.nodeId === action.node.id ? true : tab.editMode
			}))

			return {
				...state,
				openedTabs
			}
		}

		case TAB_CANCEL_EDIT: {
			const openedTabs: OpenedTab[] = state.openedTabs.map(tab => ({
				...tab,
				editMode: tab.nodeId === action.node.id ? false : tab.editMode
			}))

			return {
				...state,
				openedTabs
			}
		}

		case TAB_SELECT: {
			return {
				...state,
				selectedId: action.node.id,
				selectedTab: state.openedTabs.findIndex(
					n => n.nodeId === action.node.id
				)
			}
		}

		case TAB_CLOSE_RELATED: {
			const { node, children } = action

			const { openedTabs, selectedTab, temporaryTab } = closeDeletedTabs(
				node,
				children,
				state
			)

			return {
				...state,
				openedTabs,
				selectedTab,
				temporaryTab
			}
		}

		case TAB_CLOSE: {
			const selected =
				state.selectedTab !== undefined
					? state.openedTabs[state.selectedTab]
					: undefined

			const temporary =
				state.temporaryTab !== undefined
					? state.openedTabs[state.temporaryTab]
					: undefined

			const openedTabs = state.openedTabs.filter(
				table => table.nodeId !== action.nodeId
			)

			const newSelected =
				selected !== undefined
					? openedTabs.findIndex(item => item.nodeId === selected.nodeId)
					: -1

			const newTemporary =
				temporary !== undefined
					? openedTabs.findIndex(item => item.nodeId === temporary.nodeId)
					: -1

			const selectedTab =
				newSelected < 0
					? openedTabs.length > 0
						? openedTabs.length - 1
						: undefined
					: newSelected

			return {
				...state,
				openedTabs,
				selectedTab,
				temporaryTab: newTemporary < 0 ? undefined : newTemporary,
				selectedId:
					selectedTab === undefined ? undefined : openedTabs[selectedTab].nodeId
			}
		}

		case TAB_SET_PERMANENT: {
			const index = state.openedTabs.findIndex(
				item => item.nodeId === action.node.id
			)

			if (index === state.temporaryTab) {
				return {
					...state,
					temporaryTab: undefined
				}
			}

			return state
		}

		case TAB_SET_PROPERTIES_SHOWN: {
			const { tabKey, shown } = action

			const openedTabs: OpenedTab[] = state.openedTabs.map(tab => ({
				...tab,
				propertiesShown:
					tab.nodeId === action.node.id
						? {
								...tab.propertiesShown,
								[tabKey]: {
									...tab.propertiesShown[tabKey],
									shown
								}
						  }
						: tab.propertiesShown
			}))

			return {
				...state,
				openedTabs
			}
		}

		case TAB_SET_PROPERTIES_WIDTH: {
			const { tabKey, width } = action

			const openedTabs: OpenedTab[] = state.openedTabs.map(tab => ({
				...tab,
				propertiesShown:
					tab.nodeId === action.node.id
						? {
								...tab.propertiesShown,
								[tabKey]: {
									...tab.propertiesShown[tabKey],
									width
								}
						  }
						: tab.propertiesShown
			}))

			return {
				...state,
				openedTabs
			}
		}

		case TAB_SET_PROPERTY_ITEM: {
			const openedTabs: OpenedTab[] = state.openedTabs.map(tab => ({
				...tab,
				properitesItems:
					tab.nodeId === action.node.id
						? {
								...tab.properitesItems,
								[action.tab]: action.item
						  }
						: tab.properitesItems
			}))

			return {
				...state,
				openedTabs
			}
		}

		case TAB_IMPORT_STATE: {
			const storedState = action.session

			return {
				...state,
				...storedState
			}
		}

		case TAB_SELECT_TAB: {
			const openedTabs: OpenedTab[] = state.openedTabs.map(tab => ({
				...tab,
				selectedTab: action.tab
			}))

			return {
				...state,
				openedTabs
			}
		}

		default:
			return state
	}
}
