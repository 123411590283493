import { isEqual, uniq } from 'lodash'

/** If a property of all objects in array has same value, then keep it.
 * Otherwise set it to undefined
 */
export const getGroupedData = <T extends any[]>(arr: T) => {
	return arr.reduce((formSum, form, i) => {
		const formKeys = uniq([...Object.keys(form), ...Object.keys(formSum)])

		const formGrouped = { ...form }

		if (i !== 0) {
			for (const formKey of formKeys) {
				if (isEqual(formSum[formKey], form[formKey])) {
					continue
				}

				formGrouped[formKey] = undefined
				formSum[formKey] = undefined
			}
		}

		return {
			...formSum,
			...formGrouped
		}
	}, {} as any)
}
