import {
	CsvImportDomainDto,
	CsvImportExistingDomainDto,
	ImportReturnMessage
} from '@/api/models'
import { LightTable } from '@/components'
import {
	Form,
	CheckBoxFormField,
	SelectFormField,
	Submit,
	CheckBoxPure
} from '@/components/UberForm'
import { useAppContext, useAuthContext } from '@/utils/hooks'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ImportStructureProps } from '../../../hooks/useImport'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import {
	CheckDuplicateTable,
	useCheckDuplicateTables
} from '../hooks/useCheckDuplicateTables'
import { useCheckMessages } from '../hooks/useCheckMessages'
import { useCsvImporterColumns } from '../hooks/useCsvImporterColumns'
import { useNamesNormalisations } from '../hooks/useNamesNormalisations'
import { useOnCheckSubmit } from '../hooks/useOnCheckSubmit'
import { getRowsWithoutDuplicates } from '../utils/getRowsWithoutDuplicates'
import { StepTitle } from './styled'

const INITIAL_VALUES = {
	caseSensitive: false,
	namesNormalisation: 'NONE',
	generateCodes: false,
	createNewDomains: false,
	importAllColumns: false
} as ImportStructureProps

export const Check = () => {
	const { t } = useAppContext()
	const auth = useAuthContext()
	const namesNormalisations = useNamesNormalisations()
	const { columns, subColumns } = useCsvImporterColumns()

	const [formValues, setFormValues] = useState<ImportStructureProps>(
		INITIAL_VALUES
	)

	const handleSubmit = useOnCheckSubmit()

	const {
		state: {
			checkResult,
			formDataCheck,
			rows,
			onMapped,
			createAnywayTableNames
		}
	} = useCsvImporterContext()

	const onSubmit = async (values: ImportStructureProps) => {
		await handleSubmit(values)
	}

	const {
		duplicateTablesColumns,
		duplicateTablesData,
		duplicateTablesToggleAll,
		isAllDuplicatesChecked
	} = useCheckDuplicateTables()

	const checkErrors = useCheckMessages()

	useEffect(() => {
		if ((checkResult?.errors?.length ?? 0) > 0) {
			return
		}

		if (checkResult && rows) {
			const rowsWithoutDuplicates = getRowsWithoutDuplicates(
				createAnywayTableNames,
				rows,
				checkResult
			)

			onMapped(
				rowsWithoutDuplicates,
				{ ...formValues, importAllColumns: !formValues.importAllColumns },
				true
			)
		}
	}, [onMapped, checkResult, rows, createAnywayTableNames, formValues])

	return (
		<>
			<StepTitle>{t('CHECK')}</StepTitle>
			<Form<ImportStructureProps>
				onSubmit={onSubmit}
				onChange={values => {
					setFormValues(values)
				}}
				disabled={!!formDataCheck}
				initialValues={INITIAL_VALUES}
			>
				<Wrapper>
					<CheckboxStyled
						name="caseSensitive"
						title={t('IMPORT_CASE_SENSITIVE')}
					/>
					{auth.canManageLOVs() && (
						<CheckboxStyled
							name="createNewDomains"
							title={t('IMPORT_CREATE_NEW_DOMAINS')}
						/>
					)}
					<CheckboxStyled
						name="importAllColumns"
						title={t('DO_NOT_IMPORT_TECHNICAL_COLUMNS')}
					/>
					<SelectStyled
						name="namesNormalisation"
						title={t('IMPORT_NAMES_NORMALISATION')}
						options={namesNormalisations}
						valueKey="value"
						labelKey="label"
						required={true}
						clearable={false}
					/>
				</Wrapper>
				{!formDataCheck && <SubmitStyled>{t('CHECK')}</SubmitStyled>}
			</Form>
			{checkResult?.errors?.map(value => (
				<TextError key={value.messageType}>
					{checkErrors[value.messageType as ImportReturnMessage.MessageTypeEnum]}:{' '}
					{value.object}
				</TextError>
			))}
			{checkResult?.warnings
				?.filter(
					warning =>
						![
							ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_FOLDER,
							ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_SYSTEM
						].includes(warning.messageType as ImportReturnMessage.MessageTypeEnum)
				)
				.map(value => (
					<TextWarning key={value.messageType}>
						{checkErrors[value.messageType as ImportReturnMessage.MessageTypeEnum]}
						{value.object}
					</TextWarning>
				))}
			{duplicateTablesData.length > 0 && (
				<>
					<DuplicatesWrapper>
						{t('DUPLICIT_TABLES')}:&nbsp;
						<CheckboxDuplicates
							name="duplicatesAll"
							value={isAllDuplicatesChecked}
							onChange={() => duplicateTablesToggleAll()}
							title={t('CREATE_ALL_DUPLICIT_TABLES_ANYWAY')}
						/>
					</DuplicatesWrapper>
					<LightTable<CheckDuplicateTable>
						data={duplicateTablesData}
						columns={duplicateTablesColumns}
						keyField="index"
					/>
				</>
			)}

			{checkResult?.domainDtoList && (
				<>
					<div style={{ margin: '10px 0' }}>{t('DOMAINS')}</div>
					<LightTable<CsvImportDomainDto>
						data={checkResult.domainDtoList}
						columns={columns}
						keyField={'domainName'}
						isRowExpandable={row =>
							row.matchedDomain == null && row.similarDomain?.length != 0
						}
						expandedRowComponent={row => (
							<LightTable<CsvImportExistingDomainDto>
								data={row.similarDomain ? row.similarDomain : []}
								columns={subColumns}
								keyField={'code'}
							/>
						)}
					/>
				</>
			)}
		</>
	)
}

const Wrapper = styled.div`
	display: flex;
	align-items: flex-end;
`

const DuplicatesWrapper = styled.div`
	display: flex;
	align-items: center;
`

const CheckboxDuplicates = styled(CheckBoxPure)`
	flex: 0 1 auto;
	margin: 0;
	margin-left: 6px;
`

const CheckboxStyled = styled(CheckBoxFormField)`
	flex: 0 1 auto;
`

const SelectStyled = styled(SelectFormField)`
	width: 225px;
	flex: 0 1 auto;
`

const TextError = styled.div`
	color: ${props => props.theme.colors.danger.base};
`

const TextWarning = styled.div`
	color: ${props => props.theme.colors.warn.base};
`

const SubmitStyled = styled(Submit)`
	margin-bottom: 10px;
`
