import styled, { css } from 'styled-components'

export const Legend = styled.legend<{ isHighlighted?: boolean }>`
	text-transform: uppercase;
	letter-spacing: 0.5px;
	${props =>
		props.isHighlighted &&
		css`
			background: ${props => props.theme.colors.warn.light};
		`}
`
