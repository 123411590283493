import { CustomAttributeDto } from '@/api/models'
import { hasObjectAllValues } from '@/utils/collections'
import { useMemo } from 'react'
import { CustomAttributesFilterValues } from '../components/CustomAttributesFilter'

export const useCustomAttributesFilteredData = (
	filter: CustomAttributesFilterValues,
	data: CustomAttributeDto[] = []
) => {
	return useMemo(() => {
		return data.filter(item => {
			return hasObjectAllValues(item, filter)
		})
	}, [data, filter])
}
