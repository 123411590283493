import React from 'react'
import { TablePropertiesContextProvider } from './context/TablePropertiesContext'
import { TablePropertiesWrapper } from './tableProperties/TablePropertiesWrapper'
import { TablePropertiesContextProps } from './context/types'

export const TableProperties = <T,>(props: TablePropertiesContextProps) => {
	return (
		<TablePropertiesContextProvider {...props}>
			<TablePropertiesWrapper>{props.children}</TablePropertiesWrapper>
		</TablePropertiesContextProvider>
	)
}
