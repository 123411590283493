import { useAppContext } from '@/utils/hooks'
import { floor } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useCsvImporterContext } from '../../context/CsvImporterContext'
import { CsvFields } from '../../types/CsvField'
import { CsvColumnCard } from './components/CsvColumnCard'
import { CsvFieldCard } from './components/CsvFieldCard'

type Props = {
	fields: CsvFields
}

export const Mapping = ({ fields }: Props) => {
	const { t } = useAppContext()

	const {
		state: { firstRow }
	} = useCsvImporterContext()

	const splitIndex = floor((firstRow?.length ?? 0) / 2)

	const { halfFirst, halfSecond } = useMemo(() => {
		return {
			halfFirst: firstRow?.slice(0, splitIndex),
			halfSecond: firstRow?.slice(splitIndex)
		}
	}, [firstRow, splitIndex])

	return (
		<>
			<div>{t('DRAG_AND_DROP_COLUMNS_TO_MATCH_FIELDS')}:</div>
			<Container>
				<WrapperColumns>
					{halfFirst?.map((value, columnIndex) => (
						<Item key={value}>
							<CsvColumnCard columnIndex={columnIndex} />
						</Item>
					))}
				</WrapperColumns>
				<WrapperColumns>
					{halfSecond?.map((value, columnIndex) => (
						<Item key={value}>
							<CsvColumnCard columnIndex={columnIndex + splitIndex} />
						</Item>
					))}
				</WrapperColumns>
			</Container>
			<div style={{ marginTop: '10px' }}>{t('FIELDS')}:</div>
			<WrapperFields>
				{fields.map(field => (
					<Item key={field.name}>
						<CsvFieldCard field={field} />
					</Item>
				))}
			</WrapperFields>
		</>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
`

const WrapperColumns = styled.div`
	display: flex;
	width: 100%;
	margin: 0 -4px;
`

const Item = styled.div`
	min-width: 175px;
	width: 175px;
	padding: 6px 4px;
	box-sizing: border-box;
`

const WrapperFields = styled.div`
	display: flex;
	width: 100%;
	margin: 2px -4px 0 -4px;
	flex-wrap: wrap;
`
