import { FOLDER_INIT, FOLDER_UPDATE, FOLDER_SAVE } from './constants'
import { StructureDetailDto, StructureDto } from '@/api/models'
import { AppDispatch } from '@/store/utils'
import { DeepPartial } from 'redux'
import { FolderData } from './types'
import { StoreState } from '@/store'
import { apiCallAction } from '@/store/utils'
import { updateDataNode } from '@/api'
import { omit } from 'lodash'
import { DiagramData } from '@/api/schemas/diagram'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { loadNodeOrHistoryVersion } from '../node/utils'

type FolderInit = {
	type: typeof FOLDER_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

type FolderUpdate = {
	type: typeof FOLDER_UPDATE
	node: StructureDto
	update: DeepPartial<FolderData>
}

type FolderSave = {
	type: typeof FOLDER_SAVE
	payload: void
	metadata: {
		nodeId: number
	}
}

export const initFolder = ({
	nodeId,
	editMode = false,
	version,
	force,
	envId
}: InitDataParams) => async (dispatch: AppDispatch) => {
	const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

	dispatch({
		type: FOLDER_INIT,
		node,
		editMode,
		force
	} as FolderInit)
}

export const updateFolder = (
	node: StructureDto,
	update: Partial<FolderData>
): Action => ({
	type: FOLDER_UPDATE,
	node,
	update
})

export const saveFolder = (node: StructureDto) => async (
	dispatch: AppDispatch,
	getState: () => StoreState
) => {
	const opened = getState().folder.folders[node.id]

	if (!opened) {
		throw new Error(`Saving unopened state ${node.id}`)
	}

	const { name, diagram, generateCode, generateTableColumnCode } = opened.form

	const formData = omit(opened.form, [
		'generateCode',
		'generateTableColumnCode'
	])

	const folderData: FolderData = {
		...formData,
		name: name as string,
		diagram: diagram as DiagramData,
		namingConfig: {
			generateCode,
			generateTableColumnCode
		}
	}

	await dispatch(
		apiCallAction<FolderSave>(
			() =>
				updateDataNode(node.id, {
					data: JSON.stringify(folderData)
				}),
			FOLDER_SAVE,
			{ nodeId: node.id }
		)
	)
}

export type Action = FolderInit | FolderUpdate | FolderSave
