import React from 'react'
import { generateCode } from '@/store/modules/misc/actions'
import { FormlessText } from '@/components/UberForm/Input/Text/Text'
import {
	useDebounce,
	useEffectWithoutMount,
	useAppDispatch
} from '@/utils/hooks'
import { FormValue } from '../UberForm/Form'
import { withFormField, FormFieldContext } from '../UberForm/FormFieldContext'
import styled, { css } from 'styled-components'

type Props = FormFieldContext & {
	value?: string
	nameValue?: string
	error?: string | null
	readonlyContainer?: (value?: string) => React.ReactNode
	autoFocus?: boolean
	updateCode?: boolean
	onFocusCustom?: () => void
	onBlurCustom?: () => void
}

export const CodeInputFormless = ({
	name,
	value,
	nameValue,
	onChange,
	error,
	onFocus,
	onBlur,
	onFocusCustom,
	onBlurCustom,
	autoFocus,
	readonly,
	readonlyContainer,
	disabled,
	updateCode = true
}: Props) => {
	const dispatch = useAppDispatch()
	const debouncedName = useDebounce(nameValue, 200)

	useEffectWithoutMount(() => {
		if (updateCode) {
			const codeFetch = dispatch(generateCode(debouncedName))

			codeFetch.then(code => {
				if (code.result !== value) {
					onChange && onChange(code.result)
				}
			})
		}
	}, [debouncedName, updateCode])

	const handleChange = (value: FormValue) => {
		onChange && onChange(value)
	}

	return (
		<>
			{readonly ? (
				readonlyContainer ? (
					readonlyContainer(value)
				) : (
					<FormlessTextStyled value={value} disabled />
				)
			) : (
				<FormlessTextStyled
					name={name}
					value={value}
					onFocus={e => {
						onFocus && onFocus(e)
						onFocusCustom && onFocusCustom()
					}}
					onBlur={e => {
						onBlur && onBlur(e)
						onBlurCustom && onBlurCustom()
					}}
					error={error}
					onChange={handleChange}
					autoFocus={autoFocus}
					disabled={disabled}
				/>
			)}
		</>
	)
}

const FormlessTextStyled = styled(FormlessText)<
	React.ComponentProps<typeof FormlessText & { error?: string }>
>`
	${props =>
		props.error &&
		css`
			background: ${props.theme.colors.input.error.background};
			border-color: ${props.theme.colors.input.error.border};
		`}
`

export const CodeInput = withFormField(CodeInputFormless)
