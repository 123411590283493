export enum SqlTypeEnum {
	TABLE_HISTORY = 'TABLE_HISTORY',
	TABLE_REFERENCE = 'TABLE_REFERENCE',
	TABLE = 'TABLE',
	MAPPING = 'MAPPING',
	MAPPING_READ = 'MAPPING_READ',
	MAPPING_WRITE = 'MAPPING_WRITE',
	WORKFLOW = 'WORKFLOW',
	VIEW = 'VIEW',
	API_NODE = 'API_NODE',
	QUEUE = 'QUEUE',
	API_COLLECTION = 'API_COLLECTION'
}
