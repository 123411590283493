import { StructureDto } from '@/api/models'
import mainColors from './mainColors'
import { darken, lighten } from 'polished'

export default {
	[StructureDto.TypeEnum.ROOT]: '#f5c338',
	[StructureDto.TypeEnum.SYSTEM]: mainColors.danger,
	[StructureDto.TypeEnum.FOLDER]: mainColors.warn,
	[StructureDto.TypeEnum.FOLDER_WORKFLOW]: '#4287f5',
	[StructureDto.TypeEnum.TABLE]: lighten(0.1, mainColors.primary),
	[StructureDto.TypeEnum.TABLE_COLUMN]: lighten(0.1, mainColors.primary),
	[StructureDto.TypeEnum.VIEW]: lighten(0.3, mainColors.primary),
	[StructureDto.TypeEnum.MAPPING]: mainColors.success,
	[StructureDto.TypeEnum.MAPPING_READ]: mainColors.primary,
	[StructureDto.TypeEnum.MAPPING_DEFAULT]: mainColors.success,
	[StructureDto.TypeEnum.WORKFLOW]: '#6ba0db',
	[StructureDto.TypeEnum.API_NODE]: '#0086C6',
	[StructureDto.TypeEnum.API_COLLECTION]: '#006DA2',
	[StructureDto.TypeEnum.QUEUE]: darken(0.1, mainColors.primary),
	[StructureDto.TypeEnum.MAPPING_WRITE]: '#FFC0CB',
	[StructureDto.TypeEnum.CONSTANT_DOMAIN]: '#FFC0CB'
}
