import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import {
	Form,
	TextAreaFormField,
	SelectFormField,
	TextFormField,
	NamingInputFormField,
	useGetNamingConfig
} from '@/components/UberForm'
import { OpenedViewData } from '@/store/modules/view/types'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { Container } from '../../../TableDetail/components/StyledComponents'
import { ViewData, IdCodeName } from '@/api/schemas'
import { booleanString } from '@/utils/booleans'
import { uniqueNameValidator } from '@/utils/validators'
import { useApi } from '@/api/hooks'
import { getUsersForCombo, getSystemUsers } from '@/api'
import { Col, Row, Legend, FieldSet } from '@/components/Layout'
import { FormlessFormField } from '@/components/UberForm/FormField'
import ObjectSettingsControl from '@/pages/User/pages/Home/components/ObjectSettingsControl'
import { ObjectSettingsUpdateDto, StructureDto } from '@/api/models'
import { useCustomAttributesInitValues } from '../../../../components/CustomAttributes/hooks/useCustomAttributes'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'

interface Props {
	node?: StructureDto
	data: OpenedViewData
	editMode: boolean
	systemNodeId: number
	onChange: (v: Partial<ViewData>) => void
	/** Selected nodes for batch edit */
	selectedNodes?: StructureDto[]
}

export const Overview = ({
	node,
	onChange,
	data,
	editMode,
	systemNodeId,
	selectedNodes
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const [users, isUserLoading] = useApi(getUsersForCombo())

	const [systemUsers, isSystemUserLoading] = useApi(
		getSystemUsers(systemNodeId)
	)

	const handleObjectSettingsChange = (newValue: IdCodeName[]) => {
		onChange({
			objectSettings: newValue
		})
	}

	const {
		initialValuesCustomAttributes,
		parseCustomAttribute,
		customAttributes
	} = useCustomAttributesInitValues(data.form, StructureDto.TypeEnum.VIEW)

	const handleChange = useCallback(
		(
			item: Partial<ViewData>,
			source?: FormlessFormField<ViewData> | undefined
		) => {
			if (source) {
				const name = source.props.name

				if (name === 'responsiblePersonId' || name === 'responsibleAnalystId') {
					const user = users?.find(u => u.id === item[name])

					const userName =
						name === 'responsiblePersonId'
							? 'responsiblePersonName'
							: 'responsibleAnalystName'

					onChange({
						[name]: user?.id,
						[userName]: user?.value
					})

					return
				}

				if (name === 'ownerId') {
					const owner = systemUsers?.find(u => u.id === item[name])

					onChange({
						[name]: owner?.id,
						ownerName: owner?.name
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name]
						},
						data.form.customAttributes,
						customAttributes
					)
				)
			}
		},
		[
			data.form.customAttributes,
			onChange,
			parseCustomAttribute,
			systemUsers,
			users
		]
	)

	const viewStates = useMemo(
		() => [
			{
				label: t('VIEW_STATUS_LOGICAL_IN_PROGRESS'),
				value: ViewData.ViewStatusEnum.LOGICAL_IN_PROGRESS
			},
			{
				label: t('VIEW_STATUS_LOGICAL_COMPLETED'),
				value: ViewData.ViewStatusEnum.LOGICAL_COMPLETED
			},
			{
				label: t('VIEW_STATUS_PHYSICAL_IN_PROGRESS'),
				value: ViewData.ViewStatusEnum.PHYSICAL_IN_PROGRESS
			},
			{
				label: t('VIEW_STATUS_PHYSICAL_COMPLETED'),
				value: ViewData.ViewStatusEnum.PHYSICAL_COMPLETED
			},
			{
				label: t('VIEW_STATUS_FROZEN'),
				value: ViewData.ViewStatusEnum.FROZEN
			}
		],
		[t]
	)

	const namingConfig = useGetNamingConfig(data.form, node)

	return (
		<Container>
			<Form<ViewData>
				onChange={handleChange}
				defaultValues={{ ...data.form, ...initialValuesCustomAttributes }}
				disabled={!editMode}
				key={booleanString(editMode)}
				enableFieldHighlight
			>
				<Row>
					<Col size="medium">
						<TextFormField
							title={t('VIEW_NAME')}
							name="name"
							initialValue={data.form.name}
							validators={[uniqueNameValidator(dispatch, data.original.name)]}
							disabled={Boolean(selectedNodes)}
						/>
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('VIEW_CODE')}
							callNamingWorkingDataParams={namingConfig}
							initialValue={data.form.code}
							disabled={Boolean(selectedNodes)}
							validators={[
								uniqueNameValidator(dispatch, data.original.code, systemNodeId)
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col size="medium">
						<TextAreaFormField
							name="description"
							title={t('VIEW_DESCRIPTION')}
							initialValue={data.form.description}
							rows={5}
						/>
					</Col>
					<Col size="medium">
						<TextAreaFormField
							name="comment"
							title={t('VIEW_COMMENT')}
							initialValue={data.form.comment}
							rows={5}
						/>
					</Col>
				</Row>

				<ObjectSettingsControl
					name="objectSettings"
					label={t('OBJECTS')}
					onChange={handleObjectSettingsChange}
					disabled={!editMode}
					data={data.form.objectSettings}
					type={ObjectSettingsUpdateDto.StructureTypeEnum.VIEW}
				/>

				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="status"
								title={t('VIEW_STATUS')}
								initialValue={data.form.status}
								options={viewStates}
								valueKey="value"
								labelKey="label"
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsiblePersonId"
								title={t('RESPONSIBLE_PERSON')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsiblePersonId}
								isNumeric
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsibleAnalystId"
								title={t('RESPONSIBLE_ANALYST')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsibleAnalystId}
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="ownerId"
								title={t('NODE_OWNER')}
								options={systemUsers || []}
								fieldIsLoading={isSystemUserLoading}
								initialValue={data.form.ownerId}
								valueKey="id"
								labelKey="name"
								isNumeric
								allowEmpty
								clearable
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</Container>
	)
}

const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
