import { getStereotypeTypesForObjectSettings } from '@/api'
import { useApi } from '@/api/hooks'
import { Form, SelectFormField } from '@/components/UberForm'
import { useAppContext } from '@/utils/hooks'
import React from 'react'
import { FilterCell, FilterContainer } from '../../../components/Filter'

export type ObjectSettingsFilterValues = {
	structureType?: string
}

type Props = {
	onChangeFilter: (values: ObjectSettingsFilterValues) => void
}

export const ObjectSettingsFilter = ({ onChangeFilter }: Props) => {
	const { t } = useAppContext()
	const elements = useApi(getStereotypeTypesForObjectSettings())

	return (
		<Form<ObjectSettingsFilterValues> onChange={onChangeFilter}>
			<FilterContainer>
				<FilterCell width={50} />
				<FilterCell width={50} maxWidth={300} />
				<FilterCell width={50}>
					<SelectFormField
						title={t('ELEMENT')}
						name="structureType"
						options={elements.data || []}
						fieldIsLoading={elements.loading}
						valueKey="code"
						labelKey="code"
					/>
				</FilterCell>
				<FilterCell width={50} />
				<FilterCell width={50} />
				<FilterCell width={60} maxWidth={80} />
				<FilterCell width={60} maxWidth={60} />
			</FilterContainer>
		</Form>
	)
}
