import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useAppDispatch } from '@/utils/hooks'
import { createGlobalStyle } from 'styled-components'
import { loadUsers } from '@/store/modules/user/actions'
import { useAppStore, useAppContext } from '@/utils/hooks'
import { Button, LightTable, Loader } from '@/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '@/components/Container'
import { Flex } from '@/components/Layout'
import {
	faUsers,
	faEdit,
	faTimes,
	faPlus,
	faKey,
	faLockOpen,
	faLock,
	faShieldAlt
} from '@fortawesome/free-solid-svg-icons'
import { UserModal } from './components/UserModal'
import { ResetModal } from './components/ResetPasswordModal'
import { UnblockModal } from './components/UnblockModal'
import { BlockModal } from './components/BlockModal'
import { PermissionsModal } from './components/PermissionsModal'
import { DialogWrapper, RemoveModal } from '@/components'
import { LightTableColumn } from '@/components/LightTable'
import { UserDto } from '@/api/models'
import {
	TitleContainer,
	TitleIcon,
	TitleText,
	TitleActions
} from '../../styles'
import {deleteUser} from '@/api'

const CLASSNAME_DANGER = 'light-table__row--danger'

export const Users = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const users = useAppStore(state => state.user.list)
	const loggedUserId = useAppStore(state => state.auth.user?.id)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		getUsers()
	}, [])

	const getUsers = useCallback(async () => {
		setLoading(true)
		await dispatch(loadUsers())
		setLoading(false)
	}, [dispatch, setLoading])

	const columns: LightTableColumn<UserDto>[] = useMemo(
		() => [
			{
				field: 'name',
				title: t('USER_NAME'),
				width: 80,
				formatter: cell => cell || '-'
			},
			{
				field: 'surname',
				title: t('USER_SURNAME'),
				width: 80,
				formatter: cell => cell || '-'
			},
			{
				field: 'loginName',
				title: t('USER_LOGIN_NAME'),
				width: 80
			},
			{
				field: 'email',
				title: t('EMAIL'),
				width: 80
			},
			{
				field: 'department',
				title: t('USER_DEPARTMENT'),
				width: 80,
				formatter: cell => cell || '-'
			},
			{
				field: 'description',
				title: t('USER_DESC'),
				width: 80,
				formatter: cell => cell || '-'
			},
			{
				field: '',
				width: 130,
				maxWidth: 130,
				// eslint-disable-next-line react/display-name
				formatter: (_, user) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<UserModal user={user} onSave={getUsers} onClose={onClose} />
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<PermissionsModal
										user={user}
										onSave={getUsers}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('USER_PERMISSIONS_EDIT')}
										icon={faShieldAlt}
									/>
								</Button>
							)}
						</DialogWrapper>
						{user.hardLock ? (
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<UnblockModal
											user={user}
											onSave={getUsers}
											onClose={onClose}
										/>
									)
								}
							>
								{onClick => (
									<Button size="sm" schema="transparent" onClick={onClick}>
										<FontAwesomeIcon
											title={t('USER_STATUS_UNBLOCK')}
											icon={faLockOpen}
										/>
									</Button>
								)}
							</DialogWrapper>
						) : (
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<BlockModal
											user={user}
											onSave={getUsers}
											onClose={onClose}
										/>
									)
								}
							>
								{onClick => (
									<Button size="sm" schema="transparent" onClick={onClick}>
										<FontAwesomeIcon
											title={t('USER_STATUS_BLOCK')}
											icon={faLock}
										/>
									</Button>
								)}
							</DialogWrapper>
						)}

						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && <ResetModal user={user} onClose={onClose} />
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('RESET_PASSWORD')} icon={faKey} />
								</Button>
							)}
						</DialogWrapper>

						{loggedUserId !== user.id && (
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<RemoveModal
											header={t('REMOVE_USER')}
											removeId={user.id}
											callback={deleteUser}
											textOnModal={t('REMOVE_DESC', [user.loginName])}
											onRemove={getUsers}
											onClose={onClose}
										/>
									)
								}
							>
								{onClick => (
									<Button size="sm" schema="transparent" onClick={onClick}>
										<FontAwesomeIcon
											title={t('REMOVE')}
											color="#d00"
											icon={faTimes}
										/>
									</Button>
								)}
							</DialogWrapper>
						)}
					</Flex>
				)
			}
		],
		[getUsers]
	)

	const trClassName = useCallback(
		(user: UserDto) => (user.hardLock ? CLASSNAME_DANGER : ''),
		[]
	)

	return (
		<>
			<TitleContainer>
				<Flex>
					<FontAwesomeIcon icon={faUsers} />
					<TitleText>{t('USERS')}</TitleText>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <UserModal onSave={getUsers} onClose={onClose} />
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</Flex>
			</TitleContainer>

			<Container>
				<Loader loaded={!loading} absolute />
				<UserTableStyle />
				<LightTable<UserDto>
					data={users}
					columns={columns}
					keyField={'id'}
					isLoading={loading}
					trClassName={trClassName}
				/>
			</Container>
		</>
	)
}

const UserTableStyle = createGlobalStyle`
	.${CLASSNAME_DANGER} {
			text-decoration: line-through;
		}
`
