import React, { useState } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from '@/api/keycloak'
import { Root } from './Root'
import { hot } from 'react-hot-loader'

const KeycloakComponent = () => {
	const [token, setToken] = useState<undefined | string>(undefined)

	return (
		<ReactKeycloakProvider
			initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
			authClient={keycloak}
			onTokens={tokens =>
				setToken(prev => (prev === tokens.token ? prev : tokens.token))
			}
		>
			<Root token={token} />
		</ReactKeycloakProvider>
	)
}

export const Keycloak = hot(module)(KeycloakComponent)
