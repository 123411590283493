import React, { useCallback } from 'react'
import { StructureDto, NewStructureDto } from '@/api/models'
import { SystemData } from '@/api/schemas'
import { Title } from '../../components/Title'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Button, Loader, DialogWrapper } from '@/components'
import {
	initSystem,
	updateSystem,
	saveSystem
} from '@/store/modules/system/actions'
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import { SystemTabs } from './components/Tabs'
import { debounce } from 'debounce'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { AddModal } from '../../components/AddModal'
import { UpdateDeepPartial } from '@/store/utils'
import { useTabContext } from '@/context/TabContext/TabContext'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useDeletedDomains } from '@/utils/domain'
import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { useNodeInit } from '../../hooks/useNodeInit'

export const SystemDetail = () => {
	const dispatch = useAppDispatch()
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const { systems } = useAppStore(state => state.system)

	const {
		state: { node, systemNodeId, editMode }
	} = useDetailTabContext()

	const data = systems[node.id]

	const domains = useDeletedDomains(systemNodeId, data)

	useNodeInit()

	const handleEdit = async () => {
		await dispatch(initSystem({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initSystem({ nodeId: node.id, editMode: false }))
	}

	const saveDebounced = useCallback(
		debounce(async () => {
			try {
				await dispatch(saveSystem(node))
			} catch (e) {
				onSaveError(e)
			}
		}, 1000),
		[node]
	)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<SystemData>) => {
			if (editMode) {
				dispatch(updateSystem(node, v))
				saveDebounced()
			}
		},
		[editMode, dispatch, node, saveDebounced]
	)

	if (!data) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type as StructureDto.TypeEnum}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						onEdit={handleEdit}
						onCancel={handleCancel}
						dirty={data.dirty}
						moreButtons={
							node.canEdit && (
								<>
									<DialogWrapper
										dialog={(opened, onClose) =>
											opened && (
												<AddModal
													node={node}
													type={NewStructureDto.TypeEnum.FOLDER}
													onClose={onClose}
												/>
											)
										}
									>
										{onClick => (
											<Button
												icon={faFolderPlus}
												onClick={onClick}
												schema="success"
												coloredIcon={true}
											>
												{t('ADD_FOLDER')}
											</Button>
										)}
									</DialogWrapper>
								</>
							)
						}
					/>
				}
			/>
			<SystemTabs
				data={data}
				node={node}
				editMode={editMode}
				onChange={handleChange}
				domains={domains || []}
				systemNodeId={systemNodeId}
			/>
		</>
	)
}
