import React from 'react'
import { StructureDto, DomainDto } from '@/api/models'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { UpdateDeepPartial } from '@/store/utils'
import { ListEditTab } from '@/components'
import { createEmptySystemMapping } from '@/store/modules/system/helpers'
import { useAppContext } from '@/utils/hooks'
import { SystemConstantsProperties } from './Properties'

type Props = {
	node: StructureDto
	data: OpenedSystemData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
	domains: DomainDto[]
}

export const Constants = ({
	node,
	data,
	editMode,
	onChange,
	domains
}: Props) => {
	const { t } = useAppContext()

	return (
		<ListEditTab
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptySystemMapping}
			itemsKey={'constants'}
			idCounterKey={'constantsLastId'}
			onChange={onChange}
			properties={SystemConstantsProperties(t, domains)}
			tabKey="constant"
		/>
	)
}
