export default {
	APPLICATION_NAME: 'MDD',
	LOGIN: 'Login',
	LOGOUT: 'Logout',
	LOGIN_USERNAME: 'Username',
	LOGIN_PASSWORD: 'Password',
	LOGIN_SUBMIT: 'Login',
	LOGIN_EXPIRED_TOKEN: 'Your session has expired',
	LOGIN_INVALID_CREDENTIALS: 'Invalid username or password',
	LOGIN_SOFT_LOCK: 'Your account is locked for {0}',
	LOGIN_HARD_LOCK:
		'Your account is banned. Contact admin to unlock your account',
	VALIDATOR_LENGTH_RANGE_ERROR: 'Input has to be between {0} and {1} long',
	VALIDATOR_LENGTH_MAX_ERROR: 'Maximal length of input is {0}',
	VALIDATOR_LENGTH_MIN_ERROR: 'Minimal length of input is {0}',
	VALIDATOR_INTEGER_ERROR: 'Input has to be integer',
	VALIDATOR_FLOAT_ERROR: 'Input has to be decimal',
	VALIDATOR_NUMBER_MINMAX_ERROR: 'Number has to be between {0} and {1}',
	VALIDATOR_NUMBER_MIN_ERROR: 'Number has to be at least {0}',
	VALIDATOR_NUMBER_MAX_ERROR: 'Number has to be at most {0}',
	VALIDATOR_REQUIRED: 'Field is required',
	VALIDATOR_REGEXP: 'Value does not match expression {0}',
	VALIDATOR_DUPLICATE: 'Duplicate value',
	FILTER_NO_RESULT_TEXT: 'No results',
	FILTER_ALL_PLACEHOLDER: 'All',
	FROM: 'From',
	TO: 'To',
	VALIDATOR_NUMBER_RANGE_ERROR:
		'Starting value has to be less or equal to ending value',
	CHECKBOX_TRUE: 'Yes',
	CHECKBOX_FALSE: 'No',
	ADD_NEW_TABLE: 'Add new table',
	ADD_NEW_VIEW: 'Add new view',
	ADD_NEW_FOLDER: 'Add new folder',
	ADD_NEW_SYSTEM: 'Add new system',
	ADD_NEW_MAPPING: 'Add new mapping',
	ADD_NEW_WORKFLOW: 'Add new workflow',
	ADD_NEW_API_NODE: 'Add new api node',
	ADD_NEW_API_COLLECTION: 'Add new rowset',
	ADD_NEW_MAPPING_READ: 'Add new read mapping',
	ADD_NEW_MAPPING_WRITE: 'Add new write mapping',
	ADD_NEW_QUEUE: 'Add new queue',
	ADD_TABLE: 'Add table',
	ADD_FOLDER: 'Add folder',
	ADD_SYSTEM: 'Add system',
	ADD_WORKFLOW: 'Add workflow',
	CLOSE: 'Close',
	EDIT: 'Edit',
	SAVE: 'Save',
	CANCEL: 'Cancel',
	RESET: 'Reset',
	CREATE: 'Create',
	LOCK: 'Lock',
	UNLOCK: 'Unlock',
	PUSH: 'Push',
	OK: 'OK',
	CONFIRM: 'Confirm',
	STEREOTYPE: 'Stereotype',
	STEREOTYPES: 'Stereotypes',
	SUB_STEREOTYPE: 'Sub stereotype',
	NAME: 'Name',
	DESCRIPTION: 'Description',
	COMMENT: 'Comment',
	CODE: 'Code',
	CATEGORY: 'Category',
	OBJECTS: 'Objects',
	STATUS: 'Status',
	PROJECT_VIEW: 'Project view',
	PROJECT_METADATA: 'Project metadata',
	TECHNICAL_METADATA: 'Technical metadata',
	SCRIPT: 'Script',
	PRE_SCRIPT: 'Pre-script',
	POST_SCRIPT: 'Post-script',
	NAVBAR_MENU_PROJECT_VIEW: 'Project view',
	NAVBAR_MENU_SETTINGS: 'Settings',
	TAB_OVERVIEW: 'Overview',
	TAB_COLUMNS: 'Columns',
	TAB_STRUCTURE: 'Attributes',
	TAB_INDEXES: 'Indexes',
	TAB_CONSTRAINTS: 'Constraints',
	TAB_DIAGRAM: 'Diagram',
	SETTINGS_TITLE: 'Settings',
	SETTINGS_TAB_GENERAL: 'General',
	GENERATE_SQL: 'Generate SQL',
	GENERATED_SQL_MODAL_TITLE: 'Generated SQL',
	INDEXES_NO_COLUMNS_INFO:
		'You will be able to create indexes as soon as you define at least one table column.',
	KEYS_NO_COLUMNS_INFO:
		'You will be able to create keys as soon as you define at least one table column.',
	TABLE_GENERAL_NAME: 'Name',
	TABLE_GENERAL_CODE: 'Code',
	TABLE_GENERAL_COMMENT: 'Comment',
	TABLE_GENERAL_STEREOTYPE: 'Stereotype',
	TABLE_GENERAL_VERSION: 'Version',
	TABLE_GENERAL_DESCRIPTION: 'Description',
	TABLE_COLUMNS_CODE: 'Code',
	TABLE_COLUMNS_NAME: 'Name',
	TABLE_COLUMNS_DESCRIPTION: 'Description',
	TABLE_COLUMNS_COMMENT: 'Comment',
	TABLE_COLUMNS_STEREOTYPE: 'Stereotype',
	TABLE_COLUMNS_STEREOTYPE_PLACEHOLDER: 'Select stereotype...',
	TABLE_COLUMNS_DOMAIN: 'Domain',
	TABLE_COLUMNS_DOMAIN_PLACEHOLDER: 'Select domain...',
	TABLE_COLUMNS_DATA_TYPE: 'Data type',
	TABLE_COLUMNS_MANDATORY: 'Not null',
	TABLE_COLUMNS_INFLAG: 'In flag',
	TABLE_COLUMNS_OUTFLAG: 'Out flag',
	TABLE_COLUMNS_FOREIGN_KEY: 'Foreign Key',
	TABLE_COLUMNS_VIRTUAL_COLUMN_SCRIPT: 'Script',
	TABLE_COLUMNS_PRIMARY_KEY_ORDER: 'Primary key order',
	TABLE_COLUMNS_EMPTY: 'There are no columns',
	TABLE_INDEXES_NAME: 'Name',
	TABLE_INDEXES_DESCRIPTION: 'Description',
	TABLE_INDEXES_COMMENT: 'Comment',
	TABLE_INDEXES_COLUMNS: 'Columns',
	TABLE_INDEXES_COLUMNS_PLACEHOLDER: 'Select columns...',
	TABLE_INDEXES_SORT_TYPE: 'Sort type',
	TABLE_INDEXES_EMPTY: 'There are no indexes',
	TABLE_CONSTRAINTS_NAME: 'Name',
	TABLE_CONSTRAINTS_CODE: 'Code',
	TABLE_CONSTRAINTS_DESCRIPTION: 'Description',
	TABLE_CONSTRAINTS_COMMENT: 'Comment',
	TABLE_CONSTRAINTS_ENABLED: 'Enabled',
	TABLE_CONSTRAINTS_PHYSICAL_OPTIONS: 'Physical options',
	TABLE_CONSTRAINTS_CONSTRAINT_NAME: 'Constraint type',
	TABLE_CONSTRAINTS_CONSTRAINT_NAME_PLACEHOLDER: 'Select constraint type...',
	TABLE_CONSTRAINTS_COLUMNS: 'Columns',
	TABLE_CONSTRAINTS_REFERENCE: 'Reference',
	TABLE_CONSTRAINTS_COLUMNS_PLACEHOLDER: 'Select columns...',
	TABLE_CONSTRAINTS_SCRIPT: 'Script',
	TABLE_CONSTRAINTS_EXPRESSION: 'Expression',
	TABLE_CONSTRAINTS_EMPTY: 'There are no keys',
	TABLE_CONSTRAINTS_PRIMARY_KEY: 'Primary key',
	TABLE_CONSTRAINTS_FOREIGN_KEY: 'Foreign key',
	TABLE_CONSTRAINTS_AVAILABLE_COLUMNS: 'Available columns',
	TABLE_CONSTRAINTS_SELECT_FOREIGN_KEY: 'Select column',
	TABLE_CONSTRAINTS_CLEAR_FOREIGN_KEY: 'Clear',
	TABLE_CONSTRAINTS_NO_PRIMARY_KEYS:
		'Selected table does not have any primary key',
	TABLE_CONSTRAINTS_SELECT_TABLE: 'Select table',
	CONTEXT_ADD_TABLE: 'Add table',
	CONTEXT_ADD_VIEW: 'Add view',
	CONTEXT_ADD_FOLDER: 'Add folder',
	CONTEXT_ADD_WORKFLOW: 'Add workflow',
	CONTEXT_ADD_MAPPING: 'Add mapping',
	CONTEXT_ADD_QUEUE: 'Add queue',
	CONTEXT_DELETE: 'Delete',
	CONTEXT_OPEN_TAB: 'Open in new tab',
	CONTEXT_LOCK: 'Lock',
	CONTEXT_UNLOCK: 'Unlock',
	COPYRIGHT: '© 2021 Adastra, Member of Adastra Group',
	PARENT: 'Parent',
	TYPE: 'Type',
	YES: 'YES',
	NO: 'NO',
	DELETE_TABLE: 'Delete table',
	DELETE_VIEW: 'Delete view',
	DELETE_FOLDER: 'Delete folder',
	DELETE_SYSTEM: 'Delete system',
	DELETE_WORKFLOW: 'Delete workflow',
	DELETE_MAPPING: 'Delete mapping',
	DELETE_MAPPING_READ: 'Delete read mapping',
	DELETE_MAPPING_WRITE: 'Delete write mapping',
	DELETE_NODE_HEADER_NOT_EMPTY: 'Delete not empty node',
	DELETE_NODE_MESSAGE: 'Do you want to delete "{0}"?',
	DELETE_NODE_MESSAGE_NOT_EMPTY:
		'Entity {0} has some children. Are you really want to delete {0} and all his children?',
	DELETE: 'Delete',
	REMOVE: 'Remove',
	LOCKED_BY_USER: 'Locked by {0}',
	LOCKED_BY_YOU: 'Locked by you',
	INDEX_EDIT_MODAL_TITLE: 'Edit index {0}',
	TABLE_FORM_IS_INVALID_TITLE: 'Unable to save table',
	TABLE_FORM_IS_INVALID: "There're some invalid values in the form",
	PROPERTIES_TITLE: 'Properties',
	PROPERTIES_UP: 'Up',
	PROPERTIES_DOWN: 'Down',
	TECHNOLOGY: 'Technology',
	SYSTEM_TECHNOLOGY: 'System technology',
	WORKFLOW_TECHNOLOGY: 'Workflow technology',
	TAB_MAPPINGS: 'Mappings',
	API_MAPPINGS: 'API Mappings',
	TAB_MAPPINGS_API_COLLECTION: 'Rowsets',
	MAPPING_NAME: 'Name',
	MAPPING_CODE: 'Code',
	MAPPING_DESCRIPTION: 'Description',
	MAPPING_COMMENT: 'Comment',
	MAPPING_STEREOTYPE: 'Stereotype',
	MAPPING_STATUS: 'Status',
	TAB_COLUMN_MAPPINGS: 'Column mappings',
	TAB_SOURCES: 'Sources',
	TAB_OTHERS: 'Others',
	ADD_NEW: 'Add new',
	MAPPING_COLUMNS_COLUMN: 'Column',
	MAPPING_COLUMNS_MAPPING: 'Mapping',
	MAPPING_COLUMNS_OUTER_MAPPING: 'Outer mapping',
	MAPPING_COLUMNS_DESCRIPTION: 'Description',
	MAPPING_COLUMNS_COMMENT: 'Comment',
	MAPPING_COLUMNS_KEY_FLAG: 'Key flag',
	MAPPING_COLUMNS_UPDATE_FLAG: 'Update flag',
	MAPPING_COLUMNS_GROUP_BY_FLAG: 'Group by flag',
	MAPPING_OUT_WHERE: 'Out where',
	MAPPING_SOURCE_HINTS: 'Source hints',
	MAPPING_TARGET_HINTS: 'Target hints',
	MAPPING_PRE_MAPPING_SCRIPT: 'Pre-mapping script',
	MAPPING_POST_MAPPING_SCRIPT: 'Post-mapping script',
	MAPPING_CONTEXT_SCRIPT: 'Mapping context script',
	MAPPING_DELETE_CONTEXT_SCRIPT: 'Mapping delete context script',
	MAPPING_FIELD_DESCRIPTION: '{0} description',
	MAPPING_TRUNCATE: 'Truncate',
	MAPPING_DISTINCT: 'Distinct',
	MAPPING_SOURCES: 'Sources',
	MAPPING_SOURCES_ALIAS: 'Alias',
	MAPPING_SOURCES_STEREOTYPE: 'Stereotype',
	MAPPING_SOURCES_STEREOTYPE_FROM: 'FROM',
	MAPPING_SOURCES_STEREOTYPE_JOIN: 'JOIN',
	MAPPING_SOURCES_STEREOTYPE_LEFT_JOIN: 'LEFT JOIN',
	MAPPING_SOURCES_STEREOTYPE_LEFT_OUTER_JOIN: 'LEFT OUTER JOIN',
	MAPPING_SOURCES_STEREOTYPE_RIGHT_JOIN: 'RIGHT JOIN',
	MAPPING_SOURCES_STEREOTYPE_RIGHT_OUTER_JOIN: 'RIGHT OUTER JOIN',
	MAPPING_SOURCES_STEREOTYPE_FULL_OUTER_JOIN: 'FULL OUTER JOIN',
	MAPPING_SOURCES_EXPRESSION: 'Expression',
	MAPPING_SOURCES_SOURCE_CONTEXT: 'Source Context',
	MAPPING_SOURCES_OBJECT: 'Source Object',
	MAPPING_SOURCES_OBJECT_TYPE: 'Object Type',
	MAPPING_SOURCES_OBJECT_TYPE_TABLE: 'Table',
	MAPPING_SOURCES_OBJECT_TYPE_VIEW: 'View',
	MAPPING_SOURCES_OBJECT_TYPE_SUB_SELECT: 'Sub Select',
	MAPPING_SOURCES_OBJECT_TYPE_DUMMY: 'Dummy',
	MAPPING_CRITERIA: 'Criteria',
	MAPPING_TARGET_CRITERIA: 'Target criteria',
	NOTHING_HERE: 'Nothing here',
	TAB_CONSTANTS: 'Constants',
	TAB_LOOKUPS: 'Lookups',
	FIELD_NAME: 'Name',
	FIELD_CODE: 'Code',
	FIELD_DOMAIN: 'Domain',
	FIELD_VALUE: 'Value',
	FIELD_DESCRIPTION: 'Description',
	FIELD_SCRIPT: 'Script',
	AUTOSAVE_DIRTY: 'Autosaving changes…',
	AUTOSAVE_SAVED: 'Working data saved',
	PUSH_MODAL_HEADER: 'Push changes',
	PUSH_DESCRIPTION: 'Version description',
	PUSH_EXIT_EDIT_MODE: 'Exit edit mode',
	PUSH_LOCK_NODE: 'Unlock node',
	PUSH_LOCK_NODES: 'Unlock nodes',
	CANCEL_EDIT_HEADER: 'Cancel edit of {0}',
	CANCEL_EDIT_MESSAGE:
		'This will erase your autosaved working version of {0}. Do you want to proceed?',
	UNLOCK_HEADER: 'Unlock',
	UNLOCK_MESSAGE_LOCKED_BY_USER:
		'Unlocking will erase autosaved working version made by {0}. Do you want to proceed? ',
	UNLOCK_MESSAGE_LOCKED_BY_YOU:
		'Unlocking will erase your autosaved working version. Do you want to proceed?',
	SOME_SELECTED_ELEMENTS_ARE_LOCKED_BY_OTHER_USERS_PROCEED:
		'Some selected elements are locked by other users. Unlocking will erase their autosaved working versions. Do you want to proceed?',
	SOME_SELECTED_ELEMENTS_ARE_LOCKED_BY_OTHER_USERS_SKIP:
		'Some selected elements are locked by other users and you do not have "force unlock" permission. You will not edit these locked elements during batch editing. Do you want to proceed?',
	PROCEED: 'Proceed',
	ENTITY_TYPE_AGGREGATE: 'Aggregate',
	ENTITY_TYPE_AUXILIARY: 'Auxiliary',
	ENTITY_TYPE_COMPOSITION: 'Composition',
	ENTITY_TYPE_FUNDAMENTAL: 'Fundamental',
	ENTITY_TYPE_METADATA: 'Metadata',
	ENTITY_TYPE_RELATION: 'Relation',
	ENTITY_TYPE_SNAPSHOT: 'Snapshot',
	ENTITY_TYPE_SPECIALISATION: 'Specialisation',
	ENTITY_TYPE_TRANSACTION: 'Transaction',
	TABLE_STATUS_LOGICAL_IN_PROGRESS: 'Logical in progress',
	TABLE_STATUS_LOGICAL_COMPLETED: 'Logical completed',
	TABLE_STATUS_PHYSICAL_IN_PROGRESS: 'Physical in progress',
	TABLE_STATUS_PHYSICAL_COMPLETED: 'Physical completed',
	TABLE_STATUS_FROZEN: 'Frozen',
	TABLE_RETENTION_STRATEGY_INFINITE: 'Infinite',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY: 'Keep full history',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_LABEL: 'Keep full history for',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_AND_EOM:
		'Keep full history and EOM',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_AND_EOM_LABEL: 'EOM for',
	TABLE_GENERAL_BUSINESS_DOMAIN: 'Business domain',
	TABLE_GENERAL_ENTITY_TYPE: 'Entity type',
	TABLE_GENERAL_TABLE_STATUS: 'Status',
	TABLE_GENERAL_TABLE_RETENTION_STRATEGY: 'Retention strategy',
	TAB_WORKFLOWS: 'Workflows',
	WORKFLOW_NAME: 'Name',
	WORKFLOW_CODE: 'Code',
	LAST_VERSION: 'Version {0} by {1}',
	MAPPING_STATUS_LOGICAL_IN_PROGRESS: 'Logical in progress',
	MAPPING_STATUS_LOGICAL_COMPLETED: 'Logical completed',
	MAPPING_STATUS_PHYSICAL_IN_PROGRESS: 'Physical in progress',
	MAPPING_STATUS_PHYSICAL_COMPLETED: 'Physical completed',
	MAPPING_STATUS_FROZEN: 'Frozen',
	CONSTRAINT_TYPE_PRIMARY_KEY: 'Primary key',
	CONSTRAINT_TYPE_FOREIGN_KEY: 'Foreign key',
	CONSTRAINT_TYPE_CUSTOM: 'Custom',
	CONSTRAINT_TYPE_UNIQUE: 'Unique',
	CONSTRAINT_TYPE_CHECK: 'Check',
	TECHNICAL_COLUMNS_TITLE: 'Technical columns',
	TECHNICAL_COLUMN_UPDATE_AFFECTED_MESSAGE: 'This change affects {0} tables.',
	TECHNICAL_COLUMN_ADD_TO_TABLES:
		'Add this column to all tables with selected stereotypes.',
	TECHNICAL_COLUMN_UPDATE_CONFIRM_TITLE: 'Update tables?',
	TABLE_GENERAL_HAS_HISTORY_TABLE: 'Has history table',
	HISTORY_TABLE: 'History table',
	TAB_GRAPH: 'Graph',
	TAB_PHYSICAL_OPTIONS: 'Physical options',
	TABLE_SCRIPT: 'Script',
	IMPORT_COLUMNS: 'Import columns',
	IMPORT_CSV_COLUMNS_ERROR:
		'At least 2 columns (Column name, Data type) are required. {0} columns found.',
	IMPORT_CSV_FORMAT_ERROR:
		"File doesn't seem to be CSV. Only CSV files can be imported.",
	EXPECTED_FORMAT: 'Expected format',
	IMPORT_BUTTON: 'Import',
	IMPORT_CSV_INFO:
		'CSV file, first row is header, with {0} separator and 3 columns:',
	IMPORT_COLUMN_NAME: 'Column name',
	IMPORT_COLUMN_TYPE: 'Column data type',
	IMPORT_COLUMN_COMMENT: 'Column comment',
	IMPORT_MORE_TEXT: 'And {0} more...',
	DROP_FILE: 'Drop file here',
	SELECT_FILE: 'Select file',
	IMPORT_FAILED: 'Import failed',
	IMPORT_FAILED_UKNOWN_ERROR: 'Failed to import the file.',
	PARITITION_STRATEGY_NO_PARTITION: 'No partition',
	PARITITION_STRATEGY_KEY: 'Key',
	PARITITION_STRATEGY_SOURCE_SYSTEM: 'Source System',
	PARITITION_STRATEGY_SNAPSHOT_DATA: 'Snapshot Date',
	PARITITION_STRATEGY_SOURCE_AND_SNAPSHOT: 'Source System + Snapshot Date',
	PARITITION_STRATEGY_KEY_AND_SNAPSHOT:
		'Key  + Snapshot Date (key is select from column list)',
	PARITITION_STRATEGY_SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE:
		'Source System + Snapshot Date + Snapshot Date Effective',
	PARITITION_STRATEGY_KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE:
		'Key + Snapshot Date + Snapshot Date Effective (key is select from column list)',
	PARITITION_STRATEGY_SOURCE_SYSTEM_AND_VALID_TO: 'Source System + Valid_to',
	TABLE_PARTITIONING_STRATEGY: 'Partitioning strategy',
	TABLE_PARTITIONING_COLUMN: 'Partitioning column',
	ERROR_WHEN_SAVING: 'Error when saving',
	ORIGINAL_TABLE: 'Original table',
	SWITCH_TO_ORIGINAL_TABLE: 'Switch to original table',
	SWITCH_TO_HISTORY_TABLE: 'Switch to history table',
	FINISH_NODE: 'Finish node',
	INIT_NODE: 'Init node',
	PARALLEL_START: 'Parallel start',
	PARALLEL_MERGE: 'Parallel merge',
	NO_MAPPING_SELECTED: 'No mapping selected',
	NAME_NOT_SET: 'Name not set',
	UNUSED_PORT: 'Unused {0} port',
	LEFT_PORT: 'left',
	RIGHT_PORT: 'right',
	ZOOM_TO_FIT: 'Zoom to fit',
	ZOOM_IN: 'Zoom in',
	ZOOM_OUT: 'Zoom out',
	UNKNOWN: '(unknown)',
	SELECT_MAPPING: 'Select mapping...',
	ISSUES_TITLE: 'Issues',
	USERS: 'Users',
	NEW_USER: 'Create new user',
	UPDATE_USER: 'Update user',
	REMOVE_USER: 'Remove user',
	REMOVE_DESC: 'Are you sure you really want to delete "{0}"',
	EMAIL: 'Email',
	VALIDATOR_EMAIL: 'Wrong email format',
	UNSYNCHRONIZED: 'Unsynchronized',
	MORE_ACTIONS: 'More actions',
	USER_NAME: 'Name',
	USER_SURNAME: 'Surname',
	USER_DESC: 'Description',
	USER_DEPARTMENT: 'Department',
	USER_PERMISSIONS_EDIT: 'Edit permissions',
	USER_STATUS_UNBLOCK: 'Unblock user',
	USER_STATUS_UNBLOCK_INFO:
		'Are you sure you really want to unblock user "{0}"',
	USER_STATUS_BLOCK: 'Block user',
	USER_STATUS_BLOCK_INFO: 'Are you sure you really want to block user "{0}"',
	USER_LOGIN_NAME: 'Login name',
	USER_PASSWORD: 'Password',
	CHANGE_PASSWORD: 'Change password',
	OLD_PASSWORD: 'Old password',
	NEW_PASSWORD: 'New password',
	NEW_PASSWORD_REPEAT: 'Confirm your password',
	VALIDATOR_COMPARE_PASSWORDS: 'Passwords do not match',
	TAB_ENVIRONMENTS: 'Environments',
	FIELD_ID: 'Id',
	FIELD_CONNECTION: 'Connection String',
	FIELD_REMEMBER_PASSWORD: 'Remember password',
	FIELD_PRODUCTION: 'Production',
	FIELD_DIRECT_DEPLOYMENT: 'Direct deployment',
	UNLOCK_NODE: 'Unlock node',
	VALIDATOR_PASSWORD_STRENGTH:
		'Password it too weak. Increase the password length, add capital letter or number or a special character.',
	RESPONSIBLE_PERSON: 'Responsible person',
	RESPONSIBLE_ANALYST: 'Responsible analyst',
	RESET_PASSWORD: 'Reset password',
	RESET_DESC: 'Are you sure you really want to reset password for user "{0}"',
	RESET_BUTTON: 'Reset',
	BUTTON_PERMISSIONS: 'Permissions',
	ACRONYMS: 'Acronyms',
	NAMING: 'Naming',
	NAMING_CURRENT_NODE_NAME: 'Current Node Name',
	NAMING_CURRENT_NODE_CODE: 'Current Node Code',
	NAMING_CURRENT_NODE_STEREOTYPE_NAME: 'Current Node Stereotype Name',
	NAMING_CURRENT_NODE_STEREOTYPE_CODE: 'Current Node Stereotype Code',
	NAMING_CURRENT_NODE_TYPE: 'Current node type',
	NAMING_CURRENT_NODE_TYPE_ABBR: 'Current node type abbr',
	NAMING_PARENT_NODE_NAME: 'Parent Node Name',
	NAMING_PARENT_NODE_CODE: 'Parent Node Code',
	NAMING_PARENT_SYSTEM_NAME: 'Parent System Name',
	NAMING_PARENT_SYSTEM_CODE: 'Parent System Code',
	NAMING_OWNER_NAME: 'Owner name',
	NAMING_DATEYYYYMMDD: 'Date YYYYMMDD',
	NAMING_PRIMARY_KEY_TABLE_CODE: 'Table code',
	NAMING_PRIMARY_KEY_TABLE_STEREOTYPE_CODE: 'Table stereotype',
	NAMING_PREVIEW_DDL_CURRENT_NODE_NAME: 'Current Node',
	NAMING_PREVIEW_DDL_CURRENT_NODE_CODE: 'CRNT_NODE',
	NAMING_PREVIEW_DDL_CURRENT_NODE_STEREOTYPE_NAME: 'Current Node Stereotype',
	NAMING_PREVIEW_DDL_CURRENT_NODE_STEREOTYPE_CODE: 'CRNT_NODE_STEREOTYPE',
	NAMING_PREVIEW_DDL_CURRENT_NODE_TYPE: 'TABLE',
	NAMING_PREVIEW_DDL_CURRENT_NODE_TYPE_ABBR: 'T',
	NAMING_PREVIEW_DDL_PARENT_NODE_NAME: 'Parent Node',
	NAMING_PREVIEW_DDL_PARENT_NODE_CODE: 'PRNT_NODE',
	NAMING_PREVIEW_DDL_PARENT_SYSTEM_NAME: 'Parent System',
	NAMING_PREVIEW_DDL_PARENT_SYSTEM_CODE: 'PRNT_SYS',
	NAMING_PREVIEW_DDL_OWNER_NAME: 'Owner',
	NAMING_PREVIEW_DDL_DATEYYYYMMDD: '20100423',
	NAMING_PREVIEW_DDL_MAPPING_CURRENT_NODE_TYPE: 'MAPPING',
	NAMING_PREVIEW_DDL_MAPPING_CURRENT_NODE_TYPE_ABBR: 'MAP',
	NAMING_PREVIEW_PRIMARY_KEY_TABLE_NAME: 'Table name',
	NAMING_PREVIEW_PRIMARY_KEY_TABLE_CODE: 'TBL_CODE',
	NAMING_PREVIEW_PRIMARY_KEY_TABLE_STEREOTYPE_CODE: 'STEREOTYPE',
	NAMING_PREVIEW_DDL_OBJECT_CODE: 'MAPPING',
	NAMING_PREVIEW_DDL_OBJECT_NAME: 'Mapping',
	REMOVE_ACRONYM: 'Remove acronym',
	NETWORK_ERROR: 'Network error',
	NETWORK_ERROR_MESSAGE: 'Error occured when loading data: {0}',
	DOMAIN_UPDATE_CONFIRM_TITLE: 'Update columns?',
	DOMAIN_UPDATE_AFFECTED_MESSAGE:
		'This change affects {0} columns in {1} tables.',
	PERMISSIONS_USER: 'User',
	INHERITED_RIGHTS: 'Inherited rights',
	EXPLICIT_RIGHTS: 'Explicit rights',
	SEARCH_PLACEHOLDER: 'Search...',
	FILTER_BUTTON: 'Filter',
	UPDATE_ACRONYM: 'Update acronym',
	ADD_NEW_ACRONYM: 'Add new acronym',
	ACRONYM_WORD: 'Word',
	ACRONYM: 'Acronym',
	PERMISSION_MANAGE_USERS: 'Manage users',
	PERMISSION_MANAGE_LOV: 'Manage LOVs',
	PERMISSION_CREATE_DELETE_SYSTEM: 'Create/Delete systems',
	PERMISSION_FORCE_UNLOCK: 'Force unlock',
	USER_PERMISSIONS: 'Permissions',
	TABLE_COLUMNS_MANDATORY_SHORT: 'NN',
	TABLE_COLUMNS_INFLAG_SHORT: 'IN',
	TABLE_COLUMNS_OUTFLAG_SHORT: 'OUT',
	TABLE_COLUMNS_PRIMARY_KEY_ORDER_SHORT: 'PK',
	TABLE_COLUMNS_FOREIGN_KEY_SHORT: 'FK',
	PERMISSIONS_TITLE: 'Permissions',
	PASSWORD_VERY_WEAK: 'Very weak',
	PASSWORD_WEAK: 'Weak',
	PASSWORD_GOOD: 'Good',
	PASSWORD_STRONG: 'Strong',
	PASSWORD_VERY_STRONG: 'Very strong',
	SEARCH_EMPTY: 'Nothing found',
	CLOSE_TAB: 'Close tab',
	CLOSE_ALL_TABS: 'Close all tabs',
	CLOSE_OTHER_TABS: 'Close other tabs',
	TEMPLATE: 'template',
	TEMPLATES: 'Templates',
	TEMPLATE_UPDATE: 'Update template',
	TEMPLATE_VERSIONS: 'Template versions',
	TEMPLATE_NEW: 'Create template',
	TEMPLATE_DELETE: 'Delete template',
	TEMPLATE_DESCRIPTION: 'Description',
	TEMPLATE_DATA: 'Script',
	TAB_DEPLOYMENTS: 'Deployments',
	ENVIRONMENT: 'Environment',
	DEPLOYED_VERSION: 'Deployed version',
	DEPLOYED_VERSION_INFO: 'Version {0}',
	DEPLOYED_BY: 'Deployed by',
	DEPLOYED_UNPUSHED_CHANGES_WARN:
		'Unpushed changes will only be reflected in the preview',
	DEPLOYED_UNPUSHED_CHANGES_WARN_TITLE: 'Unpushed changes',
	DROP_TABLE: 'Drop table',
	PREVIEW_DDL: 'Preview',
	CREATE_DDL: 'Create',
	MARK_AS_DEPLOYED: 'Mark as deployed',
	DOWNLOAD: 'Download',
	DOWNLOAD_ZIP: 'Download zip',
	DDL_CREATION_FAILED: 'DDL generation failed',
	DEPLOYMENT: 'Deployment',
	DEPLOYMENT_MARK_CREATED: 'Structure was successfully marked as deployed',
	TABLE_GENERAL_PROJECT_METADATA: 'Project metadata',
	TABLE_GENERAL_BUSINESS_METADATA: 'Business metadata',
	TABLE_GENERAL_TECHNICAL_METADATA: 'Technical metadata',
	VIEW_NAME: 'Name',
	VIEW_CODE: 'Code',
	VIEW_DESCRIPTION: 'Description',
	VIEW_COMMENT: 'Comment',
	VIEW_STATUS: 'Status',
	VIEW_STATUS_LOGICAL_IN_PROGRESS: 'Logical in progress',
	VIEW_STATUS_LOGICAL_COMPLETED: 'Logical completed',
	VIEW_STATUS_PHYSICAL_IN_PROGRESS: 'Physical in progress',
	VIEW_STATUS_PHYSICAL_COMPLETED: 'Physical completed',
	VIEW_STATUS_FROZEN: 'Frozen',
	VIEW_COLUMNS_CODE: 'Code',
	VIEW_COLUMNS_NAME: 'Name',
	VIEW_COLUMNS_DESCRIPTION: 'Description',
	VIEW_COLUMNS_COMMENT: 'Comment',
	VIEW_COLUMNS_MAPPING: 'Mapping',
	VIEW_FIELD_DESCRIPTION: '{0} description',
	VIEW_SOURCE: 'Source script',
	VIEW_CRITERIA: 'Criteria script',
	TABLE_INDEXES_UNIQUE: 'Unique',
	TABLE_INDEXES_PHYSICAL_OPTIONS: 'Physical options',
	CONTEXT_ADD_API_NODE: 'Add api node',
	CONTEXT_ADD_API_ROWSET: 'Add rowset',
	CONTEXT_ADD_MAPPING_READ: 'Add read mapping',
	CONTEXT_ADD_MAPPING_WRITE: 'Add write mapping',
	SWITCH_TO_TRANSLATION_TABLE: 'Switch to Translation Table',
	TABLE_PRE_TABLE_SCRIPT: 'Pre-table script',
	TABLE_POST_TABLE_SCRIPT: 'Post-table script',
	TAB_PREVIEW: 'Preview',
	TECHNOLOGIES: 'Technologies',
	HISTORICAL_TABLE: 'Historical table',
	REFERENCE_TABLE: 'Reference table',
	DDL_SCRIPT: 'DDL script',
	DEFAULT_MAPPING: 'Default mapping',
	DEFAULT_MAPPING_HISTORICAL: 'Historical mapping',
	DEFAULT_MAPPING_REFERENCE: 'Reference mapping',
	DEFAULT_MAPPING_INTERFACE: 'Interface mapping',
	SEQUENCE: 'Sequence',
	ELEMENT: 'Element',
	SEQUENCE_COLUMN: 'Sequence column',
	TAB_USERS: 'System users',
	FIELD_USERNAME: 'Username',
	FIELD_COMMENT: 'Comment',
	DAYS: 'Days',
	MONTHS: 'Months',
	YEARS: 'Years',
	BUSINESS_DOMAINS: 'Business domains',
	CREATE_BUSINESS_DOMAIN: 'Create business domain',
	UPDATE_BUSINESS_DOMAIN: 'Update business domain',
	BUSINESS_DOMAIN_DESCRIPTION: 'Description',
	BUSINESS_DOMAIN_NAME: 'Name',
	REMOVE_BUSINESS_DOMAIN: 'Remove business domain',
	COMPANY: 'Company',
	DOMAINS: 'Domains',
	CREATE_DOMAIN: 'Create domain',
	UPDATE_DOMAIN: 'Update domain',
	REMOVE_DOMAIN: 'Remove domain',
	DOMAIN_NAME: 'Domain name',
	DOMAIN_CODE: 'Code',
	DOMAIN_DESCRIPTION: 'Description',
	DOMAIN_DATA_TYPE: 'Data type',
	DOMAIN_TECHNOLOGY_CODE: 'Technology code',
	DOMAIN_COMPANY_NAME: 'Company name',
	DOMAIN_CUSTOM: 'Custom',
	DOMAIN_HAS_TEMPLATE: 'Has template',
	DOMAIN_TEXT_VALUE_FLAG: 'Text value',
	DOMAIN_TEMPLATE: 'Template',
	DOMAIN_TEMPLATE_VALUE_FORMAT: 'Template value format',
	DOMAIN_TEMPLATE_VALUE_REGEX: 'Template value regex',
	DOMAIN_DEFAULT_VALUE: 'Default value',
	DOMAIN_MANDATORY: 'Mandatory', // only for import
	TABLE_GENERAL_SEQUENCE_COLUMN: 'Sequence Column',
	TABLE_GENERAL_SEQUENCE: 'Sequence',
	TEMPLATE_ELEMENT: 'Element',
	TEMPLATE_VERSION: 'Version',
	TEMPLATE_AUTHOR: 'Author',
	NODE_OWNER: 'Owner',
	MAPPING_READ: 'Read mapping',
	MAPPINGS_READ: 'Read mappings',
	WRITE_MAPPING: 'Write mapping',
	WRITE_MAPPINGS: 'Write mappings',
	PARALLEL: 'Parallel',
	BASIC: 'Basic',
	MAPPINGS_NOT_EXISTS: 'Mappings not exists',
	GRAPH_EDITOR_UNABLE_FIND_TARGET: 'Unable to find target/source port',
	GRAPH_EDITOR_ISSUE_NO_INIT_NODE: 'No init node',
	GRAPH_EDITOR_ISSUE_NO_FINISH_NODE: 'No finish node',
	DOMAIN_NOT_NULL: 'Not null',
	RAW_JSON: 'Raw',
	SQL: 'Sql',
	DIAGRAM_AUTO_LAYOUT: 'Auto layout',
	DIAGRAM_MANUAL_LAYOUT: 'Manual layout',
	CODE_PREVIEW: 'code preview',
	TECH_COLUMN_NAME: 'Name',
	TECH_COLUMN_CODE: 'Code',
	TECH_COLUMN_DESCRIPTION: 'Description',
	TECH_COLUMN_COMMENT: 'Comment',
	TECH_COLUMN_DOMAIN: 'Domain',
	TECH_COLUMN_STEREOTYPE: 'Table Stereotype assignment',
	TECH_COLUMN_NOT_NULL: 'Not null',
	TECH_COLUMN_PRIMARY_KEY: 'Primary key',
	TECH_COLUMN_FOREIGN_KEY: 'Secondary key',
	TECH_COLUMN_STEREOTYPE_COLUMN: 'Column stereotype',
	TECH_COLUMN_SCRIPT: 'Script',
	REMOVE_TECH_COLUMN: 'Remove technical column',
	UPDATE_TECH_COLUMN: 'Update technical column',
	CREATE_TECH_COLUMN: 'Create technical column',
	TEMPLATE_COMMENT: 'Comment',
	EXPORT: 'Export',
	IMPORT: 'Import',
	IMPORT_STRUCTURE: 'Import structure',
	IMPORT_SYSTEM: 'Import system',
	IMPORT_ERROR_TITLE: 'Wrong file format',
	IMPORT_ERROR_DESCRIPTION: 'Please, upload file with valid format.',
	IMPORT_NAMES_NORMALISATION: 'Names normalisation',
	IMPORT_NAMES_NORMALISATION_NONE: 'None',
	IMPORT_NAMES_NORMALISATION_UPPERCASE_FIRST_CHAR:
		'Uppercase the first characters',
	IMPORT_NAMES_NORMALISATION_UPPERCASE_ALL: 'Uppercase all',
	IMPORT_CASE_SENSITIVE: 'Case sensitive',
	IMPORT_CREATE_NEW_DOMAINS: 'Create new domains',
	IMPORT_GENERATE_CODES: 'Generate Codes',
	CREATE_SYSTEM: 'Create system',
	REFRESH: 'Refresh',
	ERROR: 'Error',
	DUPLICATE: 'Duplicate',
	NO_FOREIGN_KEY_SELECTED: 'No Foreign key selected',
	UPDATED_BY: 'Updated by',
	UPDATED_AT: 'Updated at',
	VERSION: 'Version',
	VERSION_TO_COMPARE: 'Version to compare',
	VERSIONS: 'Versions',
	ACTIVE: 'Active',
	FUNCTIONAL_NODES: 'Functional',
	EXECUTE_WRITE_NODE: 'Execute (Write)',
	EXECUTE_READ_NODE: 'Execute (Read)',
	EXECUTE_WRITE_CALL_MAPPING: 'Call {0}',
	EXECUTE_WRITE_RUN_MAPPING: 'Run {0}',
	EXECUTE_WRITE_RUN_SCRIPT: 'Run script',
	EXECUTE_READ_RUN_MAPPING: 'Read {0}',
	EXECUTE_MAPPING_UNSET: '<< UNDEFINED >>',
	EXECUTE_READ_MAPPING: 'Mapping',
	EXECUTE_WRITE_MAPPING: 'Mapping',
	EXECUTE_WRITE_METHOD: 'Execution method',
	EXECUTE_WRITE_METHOD_CALL_MAPPING: 'Call mapping',
	EXECUTE_WRITE_METHOD_RUN_MAPPING: 'Run mapping',
	EXECUTE_WRITE_METHOD_RUN_SCRIPT: 'Run script',
	EXECUTE_WRITE_SCRIPT: 'Script',
	FINISH_SCRIPT: 'Run script',
	INIT_TYPE: 'Start type',
	INIT_TYPE_INTERVAL: 'Interval',
	INIT_TYPE_CRON: 'Cron string',
	INIT_TYPE_WORKFLOW_FINISH: 'Other workflow finish',
	INIT_TYPE_SCRIPT: 'Run script',
	INIT_INTERVAL: 'Interval',
	INIT_INTERVAL_DAILY: 'Daily',
	INIT_INTERVAL_WEEKLY: 'Weekly',
	INIT_INTERVAL_MONTHLY: 'Monthly',
	INIT_WEEKDAY: 'Weekday',
	INIT_DAY: 'Day',
	INIT_TIME: 'Time',
	INIT_OTHER_WORKFLOW: 'Other workflow',
	INIT_CRON: 'Cron string',
	INIT_SCRIPT: 'Script',
	HH: 'HH',
	MM: 'MM',
	MONDAY: 'Monday',
	TUESDAY: 'Tuesday',
	WEDNESDAY: 'Wednesday',
	THURSDAY: 'Thursday',
	FRIDAY: 'Friday',
	SATURDAY: 'Saturday',
	SUNDAY: 'Sunday',
	ERROR_NO_SCRIPT: 'Script not set',
	ERROR_NO_CRON: 'Cron string not set',
	ERROR_NO_OTHER_WORKFLOW: 'Other workflow not selected',
	ERROR_NO_DAY: 'Day not set',
	ERROR_NO_WEEKDAY: 'Weekday not set',
	ERROR_NO_TIME: 'Time not set',
	TAB_VALIDATION: 'Validation',
	VALIDATION_ID: 'ID',
	VALIDATION_SEVERITY: 'Severity',
	VALIDATION_NODE: 'Node',
	VALIDATION_ELEMENT: 'Element',
	VALIDATION_ATTRIBUTE: 'Attribute',
	VALIDATION_MESSAGE: 'Message',
	VALIDATION_DESCRIPTION: 'Description',
	VALIDATION_FULLTEXT: 'Fulltext search',
	API_NODE_COLUMN_TYPE_IN: 'In',
	API_NODE_COLUMN_TYPE_OUT: 'Out',
	API_NODE_COLUMN_TYPE_IN_OUT: 'In/Out',
	API_NODE_COLUMN_TYPE_INTERNAL: 'Internal',
	API_NODE_COLUMNS_TYPE: 'Type',
	MAPPING_CONDITION: 'Mapping Run Condition',
	MAPPING_ROWSET: 'Rowset',
	ORDER_OF_EXECUTION: 'Order of execution',
	TEXT_SOURCES: 'Text',
	STRUCTURED_SOURCES: 'Structured',
	DEFAULT: 'Default',
	REMOVE_OBJECT_SETTINGS: 'Remove object types',
	OBJECT_SETTINGS: 'Object types',
	OBJECT_TYPE: 'Object type',
	OBJECT_TYPE_CODE: 'Object type code',
	OBJECT_TYPE_NAME: 'Object type name',
	UPDATE_OBJECT_SETTINGS: 'Update object types',
	CREATE_OBJECT_SETTINGS: 'Create object types',
	ADD_OBJECT_SETTINGS: 'Add object',
	OUTPUT_FORMAT: 'Output format',
	FILE_EXTENSION: 'File extension',
	PYTHON: 'Python',
	PLAIN_TEXT: 'Plain text',
	PREVIEW_ERROR_OBJECT_TYPE:
		'System unable to generate preview. You must select object type first.',
	UPDATE_STEREOTYPE: 'Update stereotype',
	CREATE_STEREOTYPE: 'Create stereotype',
	ADD_STEREOTYPE: 'Add stereotype',
	REMOVE_STEREOTYPE: 'Remove stereotype',
	MASS_DOWNLOAD: 'Generate script',
	MASS_DOWNLOAD_BUTTON: 'Generate',
	MASS_DOWNLOAD_STRATEGY: 'Settings',
	MASS_DOWNLOAD_SORTING: 'Sorting',
	MASS_DOWNLOAD_SORTING_DEFAULT: 'Default sorting',
	SORT_BY_NAME_ASC: 'By Structure Name ASC',
	SORT_BY_TYPE_AND_NAME_ASC: 'By Structure Type and Name ASC',
	SELECT_NODE: 'Select node',
	SELECT_ALL_NODE: 'Select all node children ',
	UNSELECT_NODE: 'Unselect node',
	UNSELECT_ALL_NODE: 'Unselect all node children',
	ENTER_SELECT_MODE: 'Enter select mode',
	CANCEL_SELECT_MODE: 'Cancel select mode',
	BATCH_EXPORT: 'Generate script',
	ZIP_PER_STRUCTURE: 'Separate files in Zip',
	ZIP_OWNER_FOLDER_PER_OBJECT_TYPE: 'Zip owner folder per object type',
	ZIP_PER_OWNER_AND_OBJECT_TYPE: 'Zip per owner and object type',
	SINGLE_FILE: 'Single file',
	THROW_EVENT: 'Throw event',
	INPUT_PARAMETER: 'Input parameter',
	WAIT_UNTIL_FINISHED: 'Wait until finished',
	WAIT_FOR_EVENT: 'Wait for event',
	ON_OFF: 'On/Off',
	LOCK_RESOURCE: 'Lock resource',
	UNLOCK_RESOURCE: 'Unlock resource',
	SUBFLOW_EXECUTE: 'Subflow execute',
	EVENT_NAME: 'Event name',
	RESOURCE_NAME: 'Resource name',
	PROPERTIES: 'Properties',
	PUSH_TO_GIT: 'Push to git',
	DEPLOYMENT_GIT: 'Deployment to git repository',
	DEPLOYMENT_GIT_PUSHED: 'Successfully pushed to git repository',
	RELEASES: 'Releases',
	ADD_NEW_RELEASE: 'Add new release',
	ADD_ENVIRONMENTS: 'Add environments',
	RELEASE_DETAIL: 'Release detail',
	LIST_OF_RELEASES: 'List of releases',
	PATH: 'Path',
	ELEMENTS_IN_RELEASE: 'Elements in release',
	ADD_ELEMENT: 'Drop element to add',
	ADD_ELEMENT_TO_RELEASE: 'Add element to release',
	ADD_ELEMENTS_TO_RELEASE: 'Add elements to release',
	RELEASE_DRAG_DROP_HINT:
		'Drag & Drop elements from the tree in the left panel to the "Drop element" zone',
	REMOVE_ELEMENT_FROM_RELEASE: 'Remove element from release',
	REMOVE_ELEMENT_FROM_RELEASE_DESCRIPTION:
		'Do you want to remove element "{0}" from release?',
	RELEASE_DATE: 'Release date',
	RELEASED_TIME: 'Released time',
	RELEASED: 'Released',
	ENVIRONMENTS_IN_RELEASE: 'Environments in release',
	RELEASE_NOT_FOUND: 'Release not found',
	ONLY_PUSHED_ENVIRONMENTS_CAN_BE_ADDED:
		'Only pushed environments can be added',
	ENVIRONMENTS: 'Environments',
	ADD_ENVIRONMENT: 'Add environment',
	ADD: 'Add',
	LATEST_RELEASE: 'Latest release',
	RESET_VERSIONS: 'Reset versions',
	TAKE_OVER_VERSIONS: 'Take over versions',
	VERSIONS_RESET: 'Versions reset',
	VERSIONS_TOOK_OVER: 'Versions took over',
	DONE: 'Done',
	SOURCE_ENVIRONMENT: 'Source environment',
	REMOVE_FILE: 'Remove file',
	PREVIEW: 'Preview',
	NEXT: 'Next',
	BACK: 'Back',
	HAS_HEADERS: 'Has headers',
	COLUMN: 'Column',
	TABLE_BASICS: 'Table basics',
	DRAG_AND_DROP_COLUMNS_TO_MATCH_FIELDS:
		'Drag & Drop columns to match the fields',
	DRAG_COLUMN_HERE: 'Drag column here',
	STEP: 'Step',
	FIELD_IS_NOT_MATCHED: 'Field is not matched',
	FIELDS: 'Fields',
	INDEX_NAME: 'Index name',
	PRIMARY_KEY_NAME: 'Primary key name',
	TABLE_EXTENDED: 'Table extended',
	COLUMNS: 'Columns',
	OF: 'of',
	CHECK: 'Check',
	TABLE_NAME: 'Table name',
	TABLE_CODE: 'Table code',
	TABLE_DESCRIPTION: 'Table description',
	TABLE_COMMENT: 'Table comment',
	EXISTING: 'Existing',
	DOMAIN_NOT_FOUND: 'Domain not found',
	INCOMPATIBLE_TARGET: 'Incompatible target',
	INCONSISTENT_DOMAINS: 'Inconsistent domains',
	INCONSISTENT_TABLES: 'Inconsistent tables',
	TABLE_ALREADY_EXISTS_IN_FOLDER: 'Table already exists in folder',
	TABLE_ALREADY_EXISTS_IN_SYSTEM: 'Table already exists in system',
	TABLE_STEREOTYPE_NOT_FOUND: 'Table stereotype not found',
	STEREOTYPE_NOT_FOUND: 'Stereotype not found',
	CREATE_ANYWAY: 'Create anyway',
	DUPLICIT_TABLES: 'Duplicit tables',
	CREATE_ALL_DUPLICIT_TABLES_ANYWAY: 'Create all duplicit tables anyway',
	DATABASE_CONNECTION: 'Database connection',
	SUBWORKFLOW: 'Subworkflow',
	CSV_CAN_BE_IMPORTED_ONLY_IN_FOLDER: 'CSV can be imported only in folder',
	STEREOTYPE_CODE: 'Stereotype code',
	TABLE_STEREOTYPE_CODE: 'Table stereotype code',
	COLUMN_WITH_SAME_NAME_ALREADY_EXISTS: 'Column with same name already exists',
	COLUMN_WITH_SAME_CODE_ALREADY_EXISTS: 'Column with same code already exists',
	DUPLICATE_COLUMN_NAME: 'Duplicate column name',
	DUPLICATE_COLUMN_CODE: 'Duplicate column code',
	DELETE_SELECTED_ELEMENTS: 'Delete selected elements and their children',
	NUMBER_OF_SELECTED_ELEMENTS: 'Number of selected elements',
	BATCH_DELETE: 'Batch delete',
	SYSTEM_USER: 'System user',
	PERMISSIONS: 'Permissions',
	DO_NOT_IMPORT_TECHNICAL_COLUMNS: 'Do not import technical columns',
	BATCH_EDIT: 'Batch edit',
	ELEMENTS: 'Elements',
	WARNING: 'Warning',
	WARNING_LOCKED_ELEMENTS_BY_OTHER_USERS:
		'Warning: Locked elements by other users',
	CANCEL_AND_UNLOCK_ALL_ELEMENTS: 'Cancel and unlock all elements',
	ROOT: 'Root',
	SYSTEM: 'System',
	FOLDER: 'Folder',
	FOLDER_WORKFLOW: 'Folder workflow',
	TABLE: 'Table',
	MAPPING_DEFAULT: 'Mapping default',
	MAPPING: 'Mapping',
	WORKFLOW: 'Workflow',
	VIEW: 'View',
	API_NODE: 'Api node',
	API_COLLECTION: 'Api collection',
	QUEUE: 'Queue',
	ELEMENT_TYPE: 'Element type',
	SELECT_ELEMENT_TYPE_FOR_EDITING: 'Select element type for editing',
	SELECT: 'Select',
	DOWNLOAD_VERSION_TO_COMPARE: 'Download (version to compare)',
	FIELD_GIT_URL: 'Git URL',
	FIELD_GIT_PRIVATE_KEY: 'Private Key',
	FIELD_GIT_PUBLIC_KEY: 'Public Key',
	FIELD_GIT_LOGIN_NAME: 'GIT LoginName',
	FIELD_GIT_PASSWORD_TOKEN: 'GIT Password Token',
	MAXIMIZE: 'Maximize',
	VIEW_HISTORY: 'View history',
	SCRIPT_EDITOR: 'Script editor',
	AUTOCOMPLETE_KEYWORDS: 'Autocomplete keywords',
	AUTOCOMPLETE_SNIPPETS: 'Autocomplete snippets',
	AUTOCOMPLETE_ELEMENTS: 'Autocomplete elements (table names & columns)',
	GENERATE_ELEMENT_CODE: 'Generate element code',
	GENERATE_TABLE_COLUMN_CODE: 'Generate table column code',
	UNDEFINED_INHERIT:
		'When undefined then it inherits value from parent folders & system',
	true: 'Yes',
	false: 'No',
	inherit: 'Inherit',
	CURRENT_NODE_NAME_ACRONYM: 'Current node name acronym',
	PARENT_NODE_NAME_ACRONYM: 'Parent node name acronym',
	MASTER_NODE_NAME: 'Master node name',
	MASTER_NODE_NAME_ACRONYM: 'Master node name acronym',
	MASTER_NODE_CODE: 'Master node code',
	NAMING_TAB_ELEMENT_TYPES: 'Element types',
	NAMING_TAB_GENERATED_ELEMENTS: 'Generated elements',
	OTHERS: 'Others',
	NAMING_TABLE_CODE: 'Table code',
	NAMING_VIEW_CODE: 'View code',
	NAMING_API_NODE_CODE: 'API node code',
	NAMING_QUEUE_CODE: 'Queue code',
	NAMING_HISTORICAL_TABLE_NAME: 'Historical table name',
	NAMING_HISTORICAL_TABLE_CODE: 'Historical table code',
	NAMING_REFERENCE_TABLE_NAME: 'Reference table name',
	NAMING_REFERENCE_TABLE_CODE: 'Reference table code',
	NAMING_API_COLLECTION_CODE: 'Rowset code',
	NAMING_DEFAULT_MAPPING_NAME: 'Default mapping name',
	NAMING_DEFAULT_MAPPING_CODE: 'Default mapping code',
	NAMING_HISTORICAL_DEFAULT_MAPPING_NAME: 'Historical default mapping name',
	NAMING_HISTORICAL_DEFAULT_MAPPING_CODE: 'Historical default mapping code',
	NAMING_INTERFACE_DEFAULT_MAPPING_NAME: 'Interface default mapping name',
	NAMING_INTERFACE_DEFAULT_MAPPING_CODE: 'Interface default mapping code',
	NAMING_MAPPING_CODE: 'Mapping code',
	NAMING_SEQUENCE_NAME: 'Sequence name',
	NAMING_DDL_SCRIPT_NAME: 'DDL script name',
	NAMING_EXPORT_NAME: 'Export name',
	FILTER: 'Filter',
	MAKE_CURRENTLY_DISPLAYED_VERSION_THE_LATEST_ONE:
		'Make currently displayed version the latest one',
	REPLACE_VERSION: 'Replace version',
	IGNORE_ERRORS: 'Ignore errors',
	DATE_OF_DEPLOYMENT: 'Date of deployment',
	NO_DEPLOYMENT_FOR_SELECTED_ENV: 'No deployment for selected env',
	SOURCE_SUBSELECT: 'Source subselect',
	UPDATE_DEPENDENCY: 'Update dependency',
	CREATE_DEPENDENCY: 'Create dependency',
	SEARCH_ELEMENT_TYPE: 'Search element type',
	DEPENDENCIES: 'Dependencies',
	SEARCH: 'Search',
	FIX_IMPACTS: 'Fix impacts',
	SHOW_IMPACTS: 'Show impacts',
	IMPACTS: 'Impacts',
	MESSAGE: 'Message',
	ALLOWED_IGNORE: 'Allowed ignore',
	EDITABLE_BY_ACTUAL_USER: 'Editable by actual user',
	SUFFIX: 'Suffix',
	USE_SUFFIX_ONLY_4_SYSTEM: 'Use suffix only for system',
	CUSTOM_ATTRIBUTES: 'Custom attributes',
	REGEX_VALIDATION: 'Regex validation',
	CUSTOM_ATTRIBUTE: 'Custom attribute',
	DELETE_CUSTOM_ATTRIBUTE: 'Delete custom attribute',
	CUSTOM_ATTRIBUTE_NEW: 'Create custom attribute',
	CUSTOM_ATTRIBUTE_UPDATE: 'Update custom attribute',
	INPUT_TYPE: 'Input type',
	CUSTOM_LISTS_OF_VALUES: 'Custom lists of values',
	CUSTOM_LOV_UPDATE: 'Update custom LOV',
	CUSTOM_LOV_NEW: 'Create custom LOV',
	DELETE_CUSTOM_LOV: 'Delete custom LOV',
	CUSTOM_LOV: 'Custom LOV',
	VALUE: 'Value',
	LABEL: 'Label',
	VALIDATOR_REGEX: 'Regex validation failed',
	ELEMENT_TYPES: 'Element types',
	WRONG_REGEX_SYNTAX_IN_SETTINGS:
		'Wrong regex syntax in settings for this custom attribute',
	WRONG_REGEX_SYNTAX: 'Wrong regex syntax',
	MAPPING_TYPE: 'Mapping type',
	TARGET_TABLE: 'Target table',
	CLICK_TO_OPEN_TABLE: 'Click to open the table',
	MAPPING_WRITE: 'Write mapping',
	NAME_CANNOT_CONTAIN_DOT: 'Name cannot have dot / period',
	SHOW_IMPACTED_TABLES: 'Show impacted tables',
	SHOW_IMPACTED_TABLES_AND_COLUMNS: 'Show impacted tables and columns',
	IMPACTED_TABLES: 'Impacted tables',
	IMPACTED_TABLES_AND_COLUMNS: 'Impacted tables and columns',
	IMPACTED_COLUMNS: 'Impacted columns',
	UPDATE_IMPACTED_TABLES: 'Update impacted tables',
	DOWNLOAD_IMPACT_AS_CSV: 'Download impact as CSV',
	SAVE_AND_UPDATE_ELEMENTS: 'Save and update elements',
	SAVE_AND_DO_NOT_UPDATE_ELEMENTS: 'Save and do not update elements',
	RELATION_STATUS_OK: 'Ok',
	RELATION_STATUS_PK_SIDE_REMOVED: 'Primary key side removed',
	RELATION_STATUS_FK_COL_NOT_SET: 'Foreign column not set',
	RELATION_STATUS_PK_COL_REMOVED: 'Primary key column removed',
	RELATION_STATUS_BROKEN: 'Broken',
	RELATION_TYPE_PK_FK_TABLE: 'Foreign table',
	RELATION_TYPE_PK_FK_COLUMN: 'Foreign column',
	RELATION_TYPE_LINK: 'Link',
	RELATION_TYPE_LINK_COLUMN: 'Link to column',
	RELATION_TYPE_CUSTOM_LINK: 'Custom link',
	ACTIONS: 'Actions'
}
