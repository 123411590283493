import { StructureDetailDto } from '@/api/models'
import { store } from '@/store'
import { AppDispatch } from '@/store/utils'
import { loadNodeHistoryVersion, loadNode } from './general-actions'

export const loadNodeOrHistoryVersion = async (
	nodeId: number,
	version?: number,
	envId?: number,
	abortController?: AbortController
) => {
	const dispatch = store.dispatch as AppDispatch
	let node: StructureDetailDto | null = null

	if (version) {
		node = ((await dispatch(
			loadNodeHistoryVersion(nodeId, version, envId)
		)) as unknown) as StructureDetailDto

		node.id = nodeId
	} else {
		node = await dispatch(loadNode(nodeId, abortController))
	}

	return node
}
