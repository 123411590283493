import {
	getAllCustomAttributes,
	getAllCustomAttributesForCombo,
	getStereotypes
} from '@/api'
import { useApi, useApiRequest } from '@/api/hooks'
import {
	CustomAttributeComboDto,
	StereotypeDto,
	StructureDto
} from '@/api/models'
import { TableMode } from '@/store/modules/table/types'
import { customAttributesUpsert } from '@/utils/collections'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX } from '../constants'

export type CustomAttributeForm = {
	id: number
	name: string
	version: number
	value: any
}

type Form = {
	customAttributes?: CustomAttributeForm[]
	[index: string]: any
}

export const useCustomAttributesInitValues = (
	form: Form,
	structureType: StructureDto.TypeEnum,
	mode?: TableMode
) => {
	const [{ customAttributes, structureTypeStereotypes }, setData] = useState({
		structureTypeStereotypes: [] as StereotypeDto[],
		customAttributes: [] as CustomAttributeComboDto[]
	})

	const getStereotypesByStructureType = () => {
		let stereotypeType

		switch (structureType) {
			case StructureDto.TypeEnum.MAPPING_DEFAULT:
				stereotypeType = StereotypeDto.TypeEnum.MAPPING
				break
			case StructureDto.TypeEnum.SYSTEM:
				return Promise.resolve(null)
			case StructureDto.TypeEnum.API_COLLECTION:
				stereotypeType = StereotypeDto.TypeEnum.API_ROWSET
				break
			default:
				stereotypeType = structureType as any
		}

		return request(getStereotypes({ type: stereotypeType }))
	}

	// Had to use useApiRequest instead of useApi - for some reason the cache did not work correctly
	const request = useApiRequest()

	useEffect(() => {
		const call = async () => {
			const customAttributes = request(
				getAllCustomAttributesForCombo(structureType)
			)

			const results = await Promise.all([
				getStereotypesByStructureType(),
				customAttributes
			])

			setData({
				structureTypeStereotypes: results[0]?.data ?? [],
				customAttributes: results[1]?.data ?? []
			})
		}

		call()
	}, [request, structureType])

	const getCustomAttributesFiltered = useCallback(
		(stereotypeId?: number) => {
			if (
				[(TableMode.HISTORY, TableMode.REFERENCE)].includes(
					mode ?? TableMode.TABLE
				)
			) {
				return [] as CustomAttributeComboDto[]
			}

			return (
				customAttributes.filter(customAttribute => {
					const stereotypesForType = customAttribute.stereotypeIds?.filter(
						stereotypeId => {
							if (structureTypeStereotypes.length === 0) {
								return true
							}

							return [
								...structureTypeStereotypes.map(stereotype => stereotype.id)
							].includes(stereotypeId)
						}
					)

					if (
						stereotypesForType === undefined ||
						stereotypesForType.length === 0
					) {
						return true
					}

					if (stereotypeId === undefined) {
						return false
					}

					return stereotypesForType.includes(stereotypeId)
				}) ?? []
			)
		},
		[customAttributes, mode, structureTypeStereotypes]
	)

	const customAttributesFiltered = useMemo(
		() => getCustomAttributesFiltered(form?.stereotypeId),
		[form, getCustomAttributesFiltered]
	)

	const initialValuesCustomAttributes = useMemo(
		() =>
			form?.customAttributes?.reduce((initialValues, customAttribute) => {
				const fieldName = `${CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX}.${customAttribute.id}.${customAttribute.name}`

				return {
					...initialValues,
					[fieldName]: customAttribute.value
				}
			}, {} as Record<string, any>) ?? {},
		[form]
	)

	const resetValuesCustomAttributes = useMemo(
		() =>
			Object.keys(initialValuesCustomAttributes).reduce(
				(reset, key) => ({
					...reset,
					[key]: undefined
				}),
				{}
			),
		[initialValuesCustomAttributes]
	)

	const getCustomAttributesReset = useCallback(
		(newStereotypeId?: number) => {
			const newCustomAttributes = getCustomAttributesFiltered(newStereotypeId)

			const customAttributesRedux = form?.customAttributes?.filter(
				customAttribute =>
					newCustomAttributes.some(
						newCustomAttribute => newCustomAttribute.id === customAttribute.id
					)
			)

			const customAttributesForm =
				customAttributesRedux?.reduce(
					(formValues, customAttribute) => ({
						...formValues,
						[`${CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX}.${customAttribute.id}.${customAttribute.name}`]: customAttribute.value
					}),
					resetValuesCustomAttributes
				) ?? resetValuesCustomAttributes

			return {
				customAttributesRedux,
				customAttributesForm
			}
		},
		[form, getCustomAttributesFiltered, resetValuesCustomAttributes]
	)

	const customAttributesAll = useApi(getAllCustomAttributes())

	/** Check if update contains custom attribute and format the update object accordingly */
	const parseCustomAttribute = useCallback(
		(
			update: Record<string, any>,
			customAttributes?: any[],
			customAttrTemplates?: CustomAttributeComboDto[]
		) => {
			return Object.keys(update).reduce((updateNew, key) => {
				if (key.includes(CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX)) {
					const [_, id, name] = key.split('.')
					const customAttributeId = parseFloat(id)

					const attribute = customAttributesAll.data?.find(
						customAttribute => customAttribute.id === customAttributeId
					)

					const parsedData = customAttributes?.reduce(
						(acc, { id, name, version, value }) => {
							// Convert id and version to numbers if they are strings
							id = !isNaN(id) ? parseInt(id) : id
							version = !isNaN(version) ? parseInt(version) : version

							const key = `${id}:${version}`
							const existingEntry = acc[key]

							// If there is no existing entry with the same id and version, or the existing
							// entry has an older version number, add the current entry to the accumulator.
							if (!existingEntry || existingEntry.version < version) {
								acc[key] = { id, name, version, value }
							}

							return acc
						},
						{}
					)

					// Convert the values of parsedData back into an array
					const parsedCA: CustomAttributeComboDto[] = Object.values(parsedData)

					const syncCustomAttributesWithTemplates = parsedCA?.filter(
						customAttribute =>
							customAttrTemplates?.find(
								(customAttrTemplate: CustomAttributeComboDto) => {
									return customAttrTemplate.id == customAttribute.id
								}
							)
					)

					if (attribute === undefined) {
						console.error('Unknown attribute')

						return updateNew
					}

					const updatedCustomAttribute: CustomAttributeForm = {
						id: attribute.id as number,
						name: attribute.name,
						value: update[key],
						version: attribute.version as number
					}

					return {
						...updateNew,
						customAttributes: customAttributesUpsert(
							syncCustomAttributesWithTemplates ?? [],
							updatedCustomAttribute
						)
					}
				}

				return {
					...updateNew,
					[key]: update[key]
				}
			}, {} as Record<string, any>)
		},
		[customAttributesAll.data]
	)

	return useMemo(
		() => ({
			initialValuesCustomAttributes,
			parseCustomAttribute,
			customAttributes: customAttributesFiltered,
			getCustomAttributesReset
		}),
		[
			initialValuesCustomAttributes,
			parseCustomAttribute,
			customAttributesFiltered,
			getCustomAttributesReset
		]
	)
}
