import React, { useMemo } from 'react'
import { ReleaseDto, StructureDto } from '@/api/models'
import styled from 'styled-components'
import { Flex } from '@/components/Layout/Flex'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DialogWrapper } from '@/components'
import { Cell, ActionsCell } from '@/components/Layout'
import { DeleteModal } from './DeleteModal'
import { deleteSystemRelease } from '@/api'

interface Props {
	node: StructureDto
	release: ReleaseDto
	editMode: boolean
	onEdit: (node: ReleaseDto) => void
	reloadReleases: () => void
}

export const Release = ({
	node,
	release,
	editMode,
	onEdit,
	reloadReleases
}: Props) => {
	const deleteRequest = useMemo(
		() => deleteSystemRelease(node.id, release.id!),
		[node.id, release.id]
	)

	return (
		<Flex align="stretch">
			<Cell>{release.name}</Cell>
			<TextCell>{release.description}</TextCell>

			<Actions>
				<Action onClick={() => onEdit(release)}>
					<FontAwesomeIcon icon={faEdit} />
				</Action>
				{editMode && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<DeleteModal
									object={release}
									deleteRequest={deleteRequest}
									onClose={() => {
										reloadReleases()
										onClose()
									}}
								/>
							)
						}
					>
						{onClick => (
							<Action onClick={onClick}>
								<FontAwesomeIcon icon={faTimes} />
							</Action>
						)}
					</DialogWrapper>
				)}
			</Actions>
		</Flex>
	)
}

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`

const Actions = styled(Flex)`
	width: 40px;
`

const TextCell = styled(Cell)`
	flex: 5;
	text-overflow: ellipsis;
	white-space: nowrap;
`
