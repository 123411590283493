import React from 'react'
import { CustomAttributeDto } from '@/api/models'
import { LightTableColumn } from '@/components/LightTable'
import { useMemo } from 'react'
import { useAppContext } from '@/utils/hooks'
import { Flex } from '@/components/Layout'
import { Button, DialogWrapper, RemoveModal } from '@/components'
import { CustomAttributesModal } from '../components/CustomAttributesModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { deleteCustomAttribute, getStereotypes } from '@/api'
import { columnsAudit } from '../../../utils'
import { useApi } from '@/api/hooks'

export const useCustomAttributesColumns = (handleSave: () => void) => {
	const { t } = useAppContext()
	const stereotypes = useApi(getStereotypes())

	return useMemo<LightTableColumn<CustomAttributeDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 80,
				formatter: cell => cell || '-'
			},
			{
				field: 'description',
				title: t('DESCRIPTION'),
				width: 80,
				formatter: cell => cell || '-'
			},
			{
				field: 'structureTypes',
				title: t('ELEMENT_TYPES'),
				width: 80,
				formatter: (cell: CustomAttributeDto.StructureTypesEnum[]) =>
					cell?.join(', ') || '-'
			},
			{
				field: 'stereotypeIds',
				title: t('STEREOTYPES'),
				width: 80,
				formatter: (cell: number[]) =>
					cell
						?.map(
							stereotypeId =>
								stereotypes.data?.find(
									stereotype => stereotype.id === stereotypeId
								)?.name
						)
						.join(', ') || '-'
			},
			{
				field: 'type',
				title: t('INPUT_TYPE'),
				width: 76,
				maxWidth: 76,
				formatter: cell => cell || '-'
			},
			...columnsAudit(t),
			{
				field: '',
				width: 60,
				maxWidth: 60,
				// eslint-disable-next-line react/display-name
				formatter: (_, customAttribute) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<CustomAttributesModal
										customAttribute={customAttribute}
										onSave={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>

						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<RemoveModal
										removeId={customAttribute.id}
										callback={deleteCustomAttribute}
										textOnModal={t('REMOVE_DESC', [
											customAttribute.description || t('CUSTOM_ATTRIBUTE')
										])}
										header={t('DELETE_CUSTOM_ATTRIBUTE')}
										onRemove={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('REMOVE')}
										color="#d00"
										icon={faTimes}
									/>
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				)
			}
		],
		[handleSave, stereotypes.data, t]
	)
}
