import React from 'react'

import { Button, DialogWrapper } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { StructureDto, NewStructureDto } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import { Title } from '../../components/Title'
import { AddModal } from '../../components/AddModal'
import { WorkflowFolderTabs } from './components/Tabs'
import { NodeButtons } from '../../components/NodeButtons'
import { PermissionsButton } from '../../components/PermissionsButton'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'

export const WorkflowFolderDetail = () => {
	const { t } = useAppContext()

	const {
		state: { node, selectedTab, editMode }
	} = useDetailTabContext()

	return (
		<>
			<Title
				type={node.type as StructureDto.TypeEnum}
				title={node.name}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<NodeButtons
						buttons={
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<AddModal
											node={node}
											type={NewStructureDto.TypeEnum.WORKFLOW}
											onClose={onClose}
										/>
									)
								}
							>
								{onClick => (
									<Button
										disabled={!node.canEdit}
										icon={faPlus}
										onClick={onClick}
										schema="success"
										coloredIcon={true}
									>
										{t('ADD_WORKFLOW')}
									</Button>
								)}
							</DialogWrapper>
						}
						moreButtons={<PermissionsButton node={node} />}
					/>
				}
			/>
			<WorkflowFolderTabs
				node={node}
				selectedTab={selectedTab}
				editMode={editMode}
			/>
		</>
	)
}
