import { SystemData, SystemMapping } from '@/api/schemas'
import { GenerateCodeEnum } from '@/typings/enum/GenerateCodeEnum'

export interface OpenedSystemData {
	form: SystemDataForm
	original: SystemDataForm
	tab: SystemTab
	dirty: boolean
	parsedEditMode: boolean
	systemTechnologyName?: string
	workflowTechnologyName?: string
	selectedReleaseId?: number
}

export type SystemDataForm = Omit<
	Partial<SystemData>,
	'columns' | 'constants' | 'namingConfig'
> & {
	lookups: SystemMapping[]
	constants: SystemMapping[]
	generateCode?: GenerateCodeEnum
	generateTableColumnCode?: GenerateCodeEnum
}

export enum SystemTab {
	Overview = 'Overview',
	Constants = 'Constants',
	Lookups = 'Lookups',
	Environments = 'Environments',
	Releases = 'Releases',
	Permissions = 'Permissions',
	Users = 'Users',
	Validation = 'Validation',
	Dependencies = 'Dependencies'
}
