import React, { useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { PossibleForeignKeys } from '../../types'
import { TableColumn, TableConstraintColumn } from '@/api/schemas'
import styled from 'styled-components'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FormValue } from '@/components/UberForm/Form'
import Button from '@/components/Button/Button'
import { FormInput, Select } from '../PropertyFieldStyles'
import { generateCode } from '@/store/modules/misc/actions'
import { ColumnsPickerValue } from '@/components/ColumnsPicker/ColumnsPicker'

type Props<T> = {
	value?: TableConstraintColumn[]
	name: string
	onChange: (value: ColumnsPickerValue, field: string) => void
	disabled: boolean
	onFocus: () => void
	onBlur: () => void
	foreignKeyTableId?: number
	primaryKeys?: TableColumn[]
	possibleForeignKeys?: PossibleForeignKeys
	fromProps?: boolean
	error?: string
	readOnly?: boolean
}

export const ForeignKey = <T extends {}>({
	value: valueInit,
	name,
	onChange,
	disabled,
	onFocus,
	onBlur,
	fromProps,
	primaryKeys,
	possibleForeignKeys,
	foreignKeyTableId,
	error,
	readOnly
}: Props<T>) => {
	const dispatch = useAppDispatch()
	const { t } = useAppContext()

	const value = useMemo<TableConstraintColumn[]>(
		() => (valueInit ? valueInit : []),
		[valueInit]
	)

	useEffect(() => {
		// remove columns for other constraint types (Primary key etc.)
		const valueFiltered = value.filter(v => v.foreignColumnUuid) ?? []
		onChange(valueFiltered, name)
	}, [])

	const [newColumns, setNewColumns] = useState<{
		[primaryKeyUUID: string]: { inProgress: boolean; defaultValue: string }
	}>({})

	//FIXME - Why is foreignKeyTableId null during readOnly view?
	if (!foreignKeyTableId) {
		const columns = value.map((fkConstraint, i) => {
			return <div key={fkConstraint?.uuid}>{fkConstraint?.code}</div>
		})

		if (columns && columns.length > 0) {
			return <div>{columns}</div>
		} else {
			return <>{t('TABLE_CONSTRAINTS_SELECT_TABLE')}</>
		}
	}

	if (!primaryKeys || !primaryKeys.length) {
		return <>{t('TABLE_CONSTRAINTS_NO_PRIMARY_KEYS')}</>
	}

	return (
		<Wrapper>
			{primaryKeys.map((pk, i) => {
				const valueFK = value.find(v => v.foreignColumnUuid === pk.uuid)
				const isFirst = i === 0

				const { inProgress, defaultValue } = newColumns[pk.uuid] ?? {
					inProgress: false,
					defaultValue: ''
				}

				const options = possibleForeignKeys?.[pk.uuid]
					?.map(fk => ({
						value: fk.uuid,
						label: fk.name
					}))
					// do not show columns already used as FK in the same constraint
					.filter(
						opt =>
							!value.find(
								cur =>
									cur.uuid === opt.value && cur.foreignColumnUuid !== pk.uuid
							)
					)

				const handleFieldChange = (valueSelect: FormValue) => {
					const fk = possibleForeignKeys?.[pk.uuid]?.find(
						fk => fk.uuid === valueSelect
					)

					if (fk) {
						onChange(
							[
								...value.filter(v => v.foreignColumnUuid !== pk.uuid),
								{
									code: fk.code ?? '',
									uuid: fk.uuid,
									foreignColumnCode: pk.code,
									foreignColumnUuid: pk.uuid
								}
							],
							name
						)
					} else {
						onClear()
					}
				}

				const onClear = () => {
					const valueClear = value.filter(
						(v: TableConstraintColumn) => v.foreignColumnUuid !== pk.uuid
					)

					onChange(valueClear, name)
				}

				const onAddNew = () => {
					setNewColumns(prev => ({
						...prev,
						[pk.uuid]: {
							inProgress: true,
							defaultValue: pk.name
						}
					}))
				}

				const onClose = () => {
					setNewColumns(prev => ({
						...prev,
						[pk.uuid]: {
							inProgress: false,
							defaultValue: pk.name
						}
					}))
				}

				const createColumn = async (nameColumn: string) => {
					const codeApi = await dispatch(generateCode(nameColumn))

					onChange(
						[
							...value.filter(v => v.foreignColumnUuid !== pk.uuid),
							{
								code: codeApi.result,
								uuid: v4(),
								name: nameColumn,
								foreignColumnCode: pk.code,
								foreignColumnUuid: pk.uuid
							}
						],
						name
					)

					onClose()
				}

				const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
					switch (e.key) {
						case 'Enter': {
							if (e.currentTarget.value) {
								createColumn(e.currentTarget.value)
							}

							break
						}

						case 'Escape': {
							onClose()
						}
					}
				}

				return (
					<Box key={pk.uuid}>
						<BoxColumn>
							{isFirst && (
								<ColumnTitle>{t('TABLE_CONSTRAINTS_PRIMARY_KEY')}</ColumnTitle>
							)}
							<PrimaryKey title={pk.name}>
								<ItemText>{pk.name}</ItemText>
								<ItemText>{pk.dataType}</ItemText>
							</PrimaryKey>
						</BoxColumn>

						<BoxColumnFK>
							{isFirst && (
								<ColumnTitle>{t('TABLE_CONSTRAINTS_FOREIGN_KEY')}</ColumnTitle>
							)}

							<BoxFKs>
								{inProgress ? (
									<FormInputFK
										error={error}
										onKeyDown={onKeyDown}
										onBlur={onClose}
										autoFocus
										focused
										fromProps
										defaultValue={defaultValue}
									/>
								) : (
									<>
										{readOnly ? (
											<>
												{options?.find(o => o.value === valueFK?.uuid)?.label ??
													t('NO_FOREIGN_KEY_SELECTED')}
											</>
										) : (
											<>
												<SelectFK
													fromProps={!!fromProps}
													name={pk.uuid}
													options={options}
													hideTitle={true}
													initialValue={valueFK ? valueFK.uuid : null}
													value={valueFK ? valueFK.uuid : null}
													onChange={handleFieldChange}
													disabled={disabled || readOnly}
													clearable={true}
													allowEmpty
													formless
													onFocus={onFocus}
													onBlur={onBlur}
													error={error}
													openMenuOnFocus
													placeholder={t(
														'TABLE_CONSTRAINTS_SELECT_FOREIGN_KEY'
													)}
												/>
												<AddIcon
													schema="default"
													onClick={onAddNew}
													disabled={disabled || readOnly}
													title={t('ADD_NEW')}
													icon={faPlus}
												/>
											</>
										)}
									</>
								)}
							</BoxFKs>
						</BoxColumnFK>
					</Box>
				)
			})}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
`

const Box = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	&:not(:last-child) {
		border-bottom: 1px solid #ddd;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}
`

const BoxColumn = styled.div`
	display: flex;
	flex-direction: column;
`

const BoxColumnFK = styled(BoxColumn)`
	flex: 1;
	height: 100%;
`

const BoxFKs = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`

const ColumnTitle = styled.div`
	width: 100%;
	margin-top: 5px;
	margin-bottom: 8px;
`

const Common = styled.div`
	height: 30px;
	width: 170px;
	padding: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const PrimaryKey = styled(Common)`
	background: #f0f0f0;
	margin-right: 10px;
	flex-wrap: wrap;
`

const ItemText = styled.div`
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
	&:nth-child(2) {
		padding-bottom: 1px;
		font-size: 10px;
	}
`

const SelectFK = styled(Select)`
	width: 100%;
	.react-select__input {
		width: 100%;
		overflow: hidden;
	}
`

const FormInputFK = styled(FormInput)`
	flex: 1;
	border: 1px solid ${props => props.theme.colors.input.border};
	padding: 7px;
`

const AddIcon = styled(Button)`
	height: 32px;
	width: 31px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	color: hsl(0, 0%, 80%);
	border-left: none;
	background: #fff;
`
