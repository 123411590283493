import { getObjectSettingsByType } from '@/api'
import { NewStructureDto, ObjectSettingsComboDto } from '@/api/models'
import { apiCallAction, thunkAction } from '@/store/utils'

export const OBJECT_SETTINGS_BY_TYPE = 'OBJECT_SETTINGS_BY_TYPE'

interface LoadObjectSettingsByType {
	type: typeof OBJECT_SETTINGS_BY_TYPE
	payload: ObjectSettingsComboDto[]
	metadata: {
		type: NewStructureDto.TypeEnum
	}
}

export const getDefaultObjectSettings = (type: NewStructureDto.TypeEnum) =>
	thunkAction(async dispatch => {
		const objectSettings = await dispatch(
			apiCallAction<LoadObjectSettingsByType>(
				() => getObjectSettingsByType(type),
				OBJECT_SETTINGS_BY_TYPE,
				{ type }
			)
		)

		const defaultOs = objectSettings?.find(os => os.defaultFlag)

		if (defaultOs) {
			return { id: defaultOs.id, code: defaultOs.code, name: defaultOs.name }
		}
	})
