import { AppContext, AppContextType } from '@/context/AppContext'
import EnhancedComponent from './EnhancedComponent'

/**
 * Adds AppContext.
 */
export default class EnhancedComponentWithContext<
	P,
	S = {}
> extends EnhancedComponent<P, S> {
	static contextType = AppContext

	context!: AppContextType
}
