import React from 'react'
import styled from 'styled-components'
import { Form, TextAreaFormField } from '@/components/UberForm'
import { Container } from '../../../TableDetail/components/StyledComponents'
import { OpenedMappingData } from '@/store/modules/mapping/types'
import { useAppContext } from '@/utils/hooks'
import { StructureDto } from '@/api/models'
import { SourceItem } from '@/api/schemas'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Source } from '../../../../components/Source/Source'

interface SourcesValues {
	criteria?: string
	criteriaDescription?: string
	sources?: string
	sourcesDescription?: string
	sourceItems: SourceItem[]
	sourceItemsLastId: number
	targetCriteria: string
	targetCriteriaDescription: string
}

interface Props {
	node: StructureDto
	data: OpenedMappingData
	systemNodeId: number
	editMode: boolean
	onChange: (v: Partial<SourcesValues>) => void
}

export const Sources = ({ onChange, data, editMode }: Props) => {
	const { t } = useAppContext()

	return (
		<StyledContainer>
			<Form<SourcesValues>
				onChange={values =>
					onChange({
						...values,
						sourceItems: data.form.sourceItems,
						sourceItemsLastId: data.form.sourceItemsLastId
					})
				}
				defaultValues={data.form}
				disabled={!editMode}
				enableFieldHighlight
			>
				<Wrapper>
					<Source data={data} onChange={onChange} />

					<TextAreaFormField
						name="sourcesDescription"
						title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_SOURCES')])}
						initialValue={data.form.sourcesDescription}
					/>
					<AceEditorField
						name="criteria"
						title={t('MAPPING_CRITERIA')}
						initialValue={data.form.criteria}
					/>
					<TextAreaFormField
						name="criteriaDescription"
						title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_CRITERIA')])}
						initialValue={data.form.criteriaDescription}
					/>
					<AceEditorField
						name="targetCriteria"
						title={t('MAPPING_TARGET_CRITERIA')}
						initialValue={data.form.targetCriteria}
					/>
					<TextAreaFormField
						name="targetCriteriaDescription"
						title={t('MAPPING_FIELD_DESCRIPTION', [
							t('MAPPING_TARGET_CRITERIA')
						])}
						initialValue={data.form.targetCriteriaDescription}
					/>
				</Wrapper>
			</Form>
		</StyledContainer>
	)
}

const StyledContainer = styled(Container)`
	textarea {
		border-radius: 0;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`
