import { AppDispatch } from '../utils'
import { updateActionsTimestamps } from './actionsTimestamps/actions'

/**
 * Ensure that data match TS / JSON schema
 * @param data data to validate
 * @param defaultData proper data according to TS typings
 * @param keys  keys of defaultData
 */
export const ensureValidData = <T extends {}>(
	data: Partial<T>,
	defaultData: T
): T => {
	const result = { ...data }
	const keys = Object.keys(defaultData) as (keyof T)[]

	keys.forEach(key => {
		if (result[key] === undefined) {
			;(result[key] as T[keyof T]) = defaultData[key]
		}
	})

	return result as T
}

export const setTimestampStart = (
	dispatch: AppDispatch,
	actionType: string,
	id: number | string
) => {
	const timeStamp = Date.now()

	dispatch(
		updateActionsTimestamps({
			id,
			actionType,
			start: timeStamp
		})
	)

	return timeStamp
}
