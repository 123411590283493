import React from 'react'

import { splitProps } from '@/utils/collections'
import FormField, { FormFieldProps, FormlessFormField } from '../FormField'
import TextArea, { TextAreaProps } from '../Input/TextArea/TextArea'

/**
 * Form field with textarea.
 */
export default class TextAreaFormField extends React.PureComponent<
	FormFieldProps & Omit<TextAreaProps, 'onChange'> & { formless?: boolean }
> {
	render() {
		const fieldProps = { ...this.props }
		const inputProps = splitProps(fieldProps, ['rows', 'autoFocus'])

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps}>
				<TextArea {...inputProps} />
			</FF>
		)
	}
}
