import { StructureDto, StereotypeDto } from '@/api/models'
import {
	ApiCollectionData,
	ApiCollectionType
} from '@/api/schemas/api-collection'
import { CodeInput } from '@/components'
import { Col, Row, Legend, FieldSet } from '@/components/Layout'
import {
	Form,
	FormField,
	TextAreaFormField,
	TextFormField,
	SelectFormField
} from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { OpenedApiCollectionData } from '@/store/modules/apiCollection/types'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { booleanString } from '@/utils/booleans'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { uniqueNameValidator } from '@/utils/validators'
import React, { useCallback, useMemo, useRef } from 'react'
import { Container } from '../../components/StyledComponents'
import { useStereotypes } from '../../../../utils'
import { useApi } from '@/api/hooks'
import { getUsersForCombo } from '@/api'
import styled from 'styled-components'
import { useCustomAttributesInitValues } from '../../../../components/CustomAttributes/hooks/useCustomAttributes'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'

type Props = {
	node: StructureDto
	data: OpenedApiCollectionData
	editMode: boolean
	onChange: (data: Partial<ApiCollectionData>) => void
}

export const Overview = ({ node, data, editMode, onChange }: Props) => {
	const { t } = useAppContext()
	const refForm = useRef<Form<ApiCollectionData>>(null)

	const dispatch = useAppDispatch()

	const stereotypes = useStereotypes(StereotypeDto.TypeEnum.API_ROWSET)
	const [users, isUserLoading] = useApi(getUsersForCombo())

	const {
		initialValuesCustomAttributes,
		getCustomAttributesReset,
		parseCustomAttribute,
		customAttributes
	} = useCustomAttributesInitValues(
		data.form,
		StructureDto.TypeEnum.API_COLLECTION
	)

	const states = useMemo(
		() => [
			{
				label: t('MAPPING_STATUS_LOGICAL_IN_PROGRESS'),
				value: ApiCollectionData.ApiCollectionStatusEnum.LOGICAL_IN_PROGRESS
			},
			{
				label: t('MAPPING_STATUS_LOGICAL_COMPLETED'),
				value: ApiCollectionData.ApiCollectionStatusEnum.LOGICAL_COMPLETED
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_IN_PROGRESS'),
				value: ApiCollectionData.ApiCollectionStatusEnum.PHYSICAL_IN_PROGRESS
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_COMPLETED'),
				value: ApiCollectionData.ApiCollectionStatusEnum.PHYSICAL_COMPLETED
			},
			{
				label: t('MAPPING_STATUS_FROZEN'),
				value: ApiCollectionData.ApiCollectionStatusEnum.FROZEN
			}
		],
		[t]
	)

	const handleChange = useCallback(
		(
			item: Partial<ApiCollectionData>,
			source?: FormlessFormField<ApiCollectionData>
		) => {
			if (source) {
				const name = source.props.name

				if (name === 'stereotypeId') {
					const stereotype = stereotypes.data?.find(
						col => col.id === item.stereotypeId
					)

					const {
						customAttributesForm,
						customAttributesRedux
					} = getCustomAttributesReset(stereotype?.id)

					refForm.current?.setValues(customAttributesForm)

					onChange({
						stereotypeId: stereotype?.id,
						stereotypeCode: stereotype?.code,
						customAttributes: customAttributesRedux
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name]
						},
						data.form.customAttributes,
						customAttributes
					)
				)
			}
		},
		[
			data.form.customAttributes,
			onChange,
			parseCustomAttribute,
			getCustomAttributesReset,
			stereotypes.data
		]
	)

	const apiCollectionTypes = useMemo(
		() => [
			{
				label: t('API_NODE_COLUMN_TYPE_IN'),
				value: ApiCollectionType.IN
			},
			{
				label: t('API_NODE_COLUMN_TYPE_OUT'),
				value: ApiCollectionType.OUT
			},
			{
				label: t('API_NODE_COLUMN_TYPE_IN_OUT'),
				value: ApiCollectionType.IN_OUT
			},
			{
				label: t('API_NODE_COLUMN_TYPE_INTERNAL'),
				value: ApiCollectionType.INTERNAL
			}
		],
		[t]
	)

	const form = data.form

	const nodes = useAppStore(state => state.node.nodes)

	const systemNodeId = useMemo(() => node && findSystemNodeId(node, nodes), [
		node,
		nodes
	])

	return (
		<Container>
			<Form<ApiCollectionData>
				onChange={handleChange}
				disabled={!editMode}
				defaultValues={{ ...form, ...initialValuesCustomAttributes }}
				key={booleanString(editMode)}
				ref={refForm}
				enableFieldHighlight
			>
				<Row>
					<Col size="medium">
						<TextFormField
							title={t('TABLE_GENERAL_NAME')}
							name="name"
							initialValue={form.name}
							validators={[
								uniqueNameValidator(dispatch, data.original.name, systemNodeId)
							]}
							required
						/>
					</Col>
					<Col size="medium">
						<FormField
							name="code"
							title={t('TABLE_GENERAL_CODE')}
							initialValue={form.code}
							validators={[
								uniqueNameValidator(dispatch, data.original.code, systemNodeId)
							]}
						>
							<CodeInput value={form.code} nameValue={form.name} />
						</FormField>
					</Col>
				</Row>
				<Row alignItems="flex-end">
					<Col size="medium">
						<TextAreaFormField
							title={t('TABLE_GENERAL_COMMENT')}
							name="comment"
							initialValue={form.comment}
							rows={5}
						/>
					</Col>
					<Col size="medium">
						<TextAreaFormField
							title={t('TABLE_GENERAL_DESCRIPTION')}
							name="description"
							initialValue={form.description}
							rows={5}
						/>
					</Col>
				</Row>
				<Row alignItems="flex-end">
					<Col size="medium">
						<SelectFormField
							name="stereotypeId"
							title={t('TABLE_GENERAL_STEREOTYPE')}
							initialValue={form.stereotypeId}
							fieldIsLoading={stereotypes.reloading}
							options={stereotypes.data || []}
							valueKey="id"
							labelKey="name"
							isNumeric
							allowEmpty
						/>
					</Col>
					<Col size="medium">
						<SelectFormField
							name="type"
							title={t('API_NODE_COLUMNS_TYPE')}
							initialValue={form.type}
							options={apiCollectionTypes}
							valueKey="value"
							labelKey="label"
							allowEmpty
						/>
					</Col>
				</Row>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="status"
								title={t('MAPPING_STATUS')}
								initialValue={data.form.status}
								options={states}
								valueKey="value"
								labelKey="label"
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsiblePersonId"
								title={t('RESPONSIBLE_PERSON')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsiblePersonId}
								isNumeric
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsibleAnalystId"
								title={t('RESPONSIBLE_ANALYST')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsibleAnalystId}
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</Container>
	)
}

const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
