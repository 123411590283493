import { TechnologyDto } from '@/api/models'
import { Actions } from './actions'
import { TECHNOLOGY_LOAD_SYSTEM, TECHNOLOGY_LOAD_WORKFLOW } from './constants'

type State = Readonly<typeof initialState>

const initialState = {
	system: [] as TechnologyDto[],
	workflow: [] as TechnologyDto[]
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case TECHNOLOGY_LOAD_SYSTEM: {
			return {
				...state,
				system: action.payload
			}
		}

		case TECHNOLOGY_LOAD_WORKFLOW: {
			return {
				...state,
				workflow: action.payload
			}
		}

		default:
			return state
	}
}
