import { TablePermission } from '@/api/models'
import { TableColumn, TableData, HistoryTableData } from '@/api/schemas'

export enum TableMode {
	TABLE = 'TABLE',
	HISTORY = 'HISTORY',
	REFERENCE = 'REFERENCE'
}

export interface OpenedTableData {
	form: TableDataForm
	original: TableDataForm
	tab: TableTab
	stereotypeColumns: TableColumnFlat[]
	historyColumns: TableColumnFlat[]
	selected: {
		key: number | null
		column: number | null
		index: number | null
	}
	dirty: boolean
	mode: TableMode
	parsedEditMode: boolean
	referenceTableMode: boolean
}

export type PropertyItemType = 'column' | 'index' | 'key'

export enum TableTab {
	General = 'General',
	Columns = 'Columns',
	Constraints = 'Constraints',
	Indexes = 'Indexes',
	Mappings = 'Mappings',
	PhysicalOptions = 'PhysicalOptions',
	Preview = 'Preview',
	Deployments = 'Deployments',
	Validation = 'Validation',
	Permissions = 'Permissions',
	Dependencies = 'Dependencies'
}

export type TableColumnFlat = Omit<TableColumn, 'domain' | 'stereotype'> & {
	domainId?: number
	domainName?: string
	domainCode?: string

	stereotypeId?: number
	stereotypeName?: string
	stereotypeCode?: string
}

export type TablePermissionFlat = Omit<TablePermission, 'user'> & {
	userId?: number
	userName?: string
	userCode?: string
	userDescription?: string
	userComment?: string
}

export type TableDataForm = Omit<
	TableData,
	'columns' | 'historyTable' | 'referenceTable' | 'objectPermissions'
> & {
	columns: TableColumnFlat[]
	objectPermissions: TablePermissionFlat[]
	historyTable?: HistoryTableFormData
	referenceTable?: ReferenceTableFormData
}

export type HistoryTableFormData = Omit<HistoryTableData, 'columns'> & {
	columns?: TableColumnFlat[]
}

export type ReferenceTableFormData = Omit<HistoryTableData, 'columns'> & {
	columns?: TableColumnFlat[]
}
