import React from 'react'
import styled, { css } from 'styled-components'

import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { nextFrame } from '@/utils/async'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons'

export interface TextProps extends FormFieldContext {
	placeholder?: string
	className?: string
	maxLength?: number
	value?: string
	type?: 'password' | 'text' | 'number'
	enableAutocomplete?: boolean
	autoFocus?: boolean
	clearable?: boolean
	error?: string | null
	role?: string
	inputIcon?: IconDefinition
	formatter?: (value: string) => string
	refInput?: React.RefObject<HTMLInputElement>
	isRequired?: boolean
	titleContainer?: string
}

export class FormlessText extends React.PureComponent<TextProps> {
	static defaultProps = {
		type: 'text'
	}

	input = React.createRef<HTMLInputElement>()

	async componentDidMount() {
		const { refInput, autoFocus } = this.props

		await nextFrame()

		if (this.input.current && autoFocus) {
			this.input.current.focus()
		}

		if (refInput && autoFocus) {
			refInput.current?.focus()
		}
	}

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value

		if (typeof this.props.maxLength === 'number') {
			value = value.substr(0, Math.min(value.length, this.props.maxLength))
		}

		if (this.props.onChange) {
			this.props.onChange(value || undefined)
		}
	}

	onFocus = (e: React.FocusEvent) => {
		const { onFocus } = this.props

		if (onFocus) {
			onFocus(e)
		}
	}

	onBlur = (e: React.FocusEvent) => {
		const { onBlur } = this.props

		if (onBlur) {
			onBlur(e)
		}
	}

	handleClear = () => {
		if (this.props.onChange) {
			this.props.onChange(undefined)
		}
	}

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (this.props.onKeyPress) {
			this.props.onKeyPress(e)
		}
	}

	render() {
		const {
			id,
			value,
			placeholder,
			className,
			name,
			disabled,
			type,
			compact,
			enableAutocomplete,
			clearable,
			error,
			role,
			inputIcon,
			formatter,
			refInput,
			isRequired,
			titleContainer
		} = this.props

		return (
			<Container title={titleContainer}>
				{inputIcon && (
					<InputIcon>
						<FontAwesomeIcon icon={inputIcon} />
					</InputIcon>
				)}
				<Input
					ref={refInput ?? this.input}
					type={type}
					id={id}
					name={name}
					className={className}
					disabled={disabled}
					placeholder={placeholder}
					value={value ? (formatter ? formatter(value) : value) : ''}
					compact={!!compact}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyPress={this.onKeyPress}
					error={error}
					autoComplete={enableAutocomplete ? 'on' : 'off'}
					role={role}
					hasIcon={inputIcon !== undefined}
					required={isRequired}
				/>
				{clearable && value && (
					<Clear onClick={this.handleClear}>
						<FontAwesomeIcon icon={faTimes} />
					</Clear>
				)}
			</Container>
		)
	}
}

const Container = styled.div`
	position: relative;
	flex: 1;
`

const Clear = styled.div`
	position: absolute;
	opacity: 0.5;
	right: 12px;
	top: 0;
	transform: translate(50%, 50%);
	cursor: pointer;
`

const InputIcon = styled.div`
	position: absolute;
	left: 0;
	opacity: 0.5;
	transform: translate(50%, 50%);
	& ~ input {
		padding-left: 26px !important;
	}
`

const Input = styled.input<{
	compact: boolean
	disabled?: boolean
	error?: string | null
	hasIcon?: boolean
}>`
	&& {
		${props => css`
			font-size: ${props.theme.input.fontSize};
			border-color: ${props.compact
				? `${props.theme.colors.input.compact.horizontalBorder} ${props.theme.colors.input.compact.verticalBorder}`
				: props.theme.colors.input.border};
			border-radius: ${props.compact ? 0 : props.theme.input.borderRadius};
			padding: ${props.compact
				? props.theme.input.compact.padding
				: props.theme.input.padding};
			:focus {
				border-color: ${props.theme.colors.primary.base};
				box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
			}
			:hover {
				box-shadow: ${props.disabled
					? 'none'
					: `0 0 3px ${props.theme.colors.primary.shadowColor}`};
			}
		`}

		${props => props.error && props.theme.colors.form}

		${props =>
			props.hasIcon &&
			css`
				padding-left: 24px;
			`}

		${props =>
			props.compact &&
			css`
				height: 26px;
			`}
	}
`

export default withFormField(FormlessText)
