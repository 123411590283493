import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { DomainDto } from '@/api/models'
import { ItemProperty, ItemPropertyType } from '@/components/Properties/types'
import { SystemMapping } from '@/api/schemas'
import { RegExpProps } from '@/components/RegExpValidatedInput/RegExpValidatedInput'

const regExpCreator = (domains: DomainDto[]) => (
	item: SystemMapping
): RegExpProps | undefined => {
	if (!item.domainId) {
		return undefined
	}

	const domain = domains.find(x => x.id === item.domainId && x.hasTemplate)

	if (!domain || !domain.templateValueRegex) {
		return undefined
	}

	return {
		sampleValue: domain.templateValueFormat,
		expression: new RegExp(domain.templateValueRegex)
	}
}

export const SystemConstantsProperties = memoizeOne(
	(
		t: TranslationHelper,
		domains: DomainDto[]
	): ItemProperty<SystemMapping>[] => [
		{
			field: 'name',
			label: t('FIELD_NAME'),
			type: ItemPropertyType.TEXT,
			showWhenPanelOpened: true
		},
		{
			field: 'code',
			label: t('FIELD_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			showWhenPanelOpened: true
		},
		{
			field: 'domainId',
			label: t('FIELD_DOMAIN'),
			type: ItemPropertyType.OPTION,
			options: domains,
			optionValue: 'id',
			optionLabel: 'name',
			isNumeric: true
		},
		{
			field: 'value',
			label: t('FIELD_VALUE'),
			type: ItemPropertyType.REGEXP_VALIDATED,
			regExp: regExpCreator(domains)
		},
		{
			field: 'description',
			label: t('FIELD_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 2
		}
	]
)
