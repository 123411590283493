import React from 'react'
import { TextFormField } from '@/components/UberForm'
import { useAppContext } from '@/utils/hooks'
import { toLocaleString } from '@/utils/numbers'
import styled from '@emotion/styled'

type Props = {
	isUpdating: boolean
	smallTopMargin?: boolean
}

export const AuditForm = ({ isUpdating, smallTopMargin }: Props) => {
	const { t } = useAppContext()

	if (!isUpdating) {
		return null
	}

	return (
		<Wrapper isUpdating smallTopMargin={smallTopMargin}>
			<ColumnBig>
				<TextFormField title={t('UPDATED_BY')} name="updatedBy" disabled />
			</ColumnBig>
			<ColumnBig>
				<TextFormField
					title={t('UPDATED_AT')}
					name="updatedAt"
					disabled
					formatter={value => toLocaleString(value)}
				/>
			</ColumnBig>
			<ColumnSmall>
				<TextFormField title={t('VERSION')} name="version" disabled />
			</ColumnSmall>
		</Wrapper>
	)
}

const Wrapper = styled.div<Props>`
	display: flex;
	margin: 10px -10px 0 -10px;
	margin-top: ${props => (props.smallTopMargin ? '5px' : '10px')};
`

const ColumnBig = styled.div`
	width: 40%;
	padding: 0 10px;
`

const ColumnSmall = styled.div`
	width: 20%;
	padding: 0 10px;
`
