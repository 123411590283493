import React, { useMemo, useState } from 'react'
import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { ItemProperty, ItemPropertyType } from '@/components/Properties/types'
import { SystemData, SystemEnvironment } from '@/api/schemas'
import { Button, DialogWrapper, Loader, MessageModal } from '@/components'
import { Flex } from '@/components/Layout'
import styled from 'styled-components'
import { useApi, useApiRequest } from '@/api/hooks'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { getNodeDetail, resetVersion, takeOverVersion } from '@/api'
import { ModalForm } from '@/components/Modal/ModalForm'
import { SelectFormField } from '@/components/UberForm'
import { EnvironmentDto, ReleaseDto, StructureDto } from '@/api/models'
import {
	saveSystem,
	selectSystemTab,
	updateSystem
} from '@/store/modules/system/actions'
import { editTab, openTab } from '@/store/modules/tab/actions'
import { SystemTab } from '@/store/modules/system/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

type FormDataTakeover = {
	sourceEnvId: string
}

const productionCheckboxPredicate = (
	item: SystemEnvironment,
	listOfValues: SystemEnvironment[]
) => !!listOfValues?.find(i => i.production && item.id !== i.id)

export const SystemEnvironmentProperties = memoizeOne(
	(
		t: TranslationHelper,
		systemNode: StructureDto,
		envs?: EnvironmentDto[]
	): ItemProperty<SystemEnvironment>[] => [
		{
			field: 'name',
			label: t('FIELD_NAME'),
			type: ItemPropertyType.TEXT,
			showWhenPanelOpened: true
		},
		{
			field: 'code',
			label: t('FIELD_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			showWhenPanelOpened: true
		},
		{
			field: 'description',
			label: t('FIELD_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 2
		},
		{
			field: 'connection',
			label: t('FIELD_CONNECTION'),
			type: ItemPropertyType.TEXT,
			flex: 2
		},
		{
			field: 'gitUrl',
			label: t('FIELD_GIT_URL'),
			type: ItemPropertyType.TEXT,
			flex: 2
		},
		{
			field: 'gitLoginName',
			label: t('FIELD_GIT_LOGIN_NAME'),
			type: ItemPropertyType.TEXT,
			flex: 2
		},
		{
			field: 'gitPasswordToken',
			label: t('FIELD_GIT_PASSWORD_TOKEN'),
			type: ItemPropertyType.TEXT,
			flex: 2
		},
		{
			field: 'directDeployment',
			label: t('FIELD_DIRECT_DEPLOYMENT'),
			type: ItemPropertyType.CHECKBOX,
			flex: 2,
			hideInTable: true
		},
		{
			field: 'rememberPassword',
			label: t('FIELD_REMEMBER_PASSWORD'),
			type: ItemPropertyType.CHECKBOX,
			flex: 2,
			hideInTable: true
		},
		{
			field: 'production',
			label: t('FIELD_PRODUCTION'),
			type: ItemPropertyType.CHECKBOX,
			flex: 2,
			disabled: productionCheckboxPredicate
		},
		{
			field: 'latestRelease',
			label: t('LATEST_RELEASE'),
			type: ItemPropertyType.TEXT,
			flex: 2,
			disabled: true,
			formatter: ({ item, fromProps }) => {
				const { t } = useAppContext()
				const dispatch = useAppDispatch()

				const onReleaseRedirect = async (release?: ReleaseDto) => {
					if (!release) {
						return
					}

					dispatch(selectSystemTab(systemNode, SystemTab.Releases))
					dispatch(updateSystem(systemNode, { selectedReleaseId: release.id! }))
					dispatch(openTab(systemNode, false))
				}

				const env = useMemo(
					() => envs?.find(env => env.frontendId === item.id),
					[envs, item]
				)

				return (
					<div
						style={
							fromProps
								? {
										marginBottom: '10px',
										padding: '0 10px',
										width: '100%',
										cursor: 'pointer'
								  }
								: { padding: '0 5px', cursor: 'pointer' }
						}
						onClick={() => onReleaseRedirect(env?.lastRelease)}
					>
						{fromProps && `${t('LATEST_RELEASE')}: `}
						{env?.lastRelease?.name && (
							<>
								<FontAwesomeIcon icon={faEdit} />
								&nbsp;
							</>
						)}
						{env?.lastRelease?.name ?? 'N/A'}
					</div>
				)
			}
		},
		{
			field: 'actions' as any,
			label: '',
			type: ItemPropertyType.TEXT,
			flex: 3.3,
			formatter: ({ item, readonly, fromProps }) => {
				const { t } = useAppContext()
				const apiError = useAppStore(state => state.apiError.title)

				const systemId = useAppStore(
					state =>
						state.tab.openedTabs[state.tab.selectedTab as number]?.systemNodeId
				)

				const { data: systemDetail } = useApi(getNodeDetail(systemId))

				const envsOptions = useMemo(() => {
					if (!systemDetail?.data) {
						return []
					}

					const pushedData = JSON.parse(systemDetail.data) as SystemData

					const envsFiltered = pushedData.environments.filter(
						systemEnv => systemEnv.id !== item.id
					)

					return envsFiltered
				}, [item.id, systemDetail])

				const request = useApiRequest()

				const [status, setStatus] = useState({
					loading: false,
					message: ''
				})

				const onReset = async () => {
					setStatus({
						loading: true,
						message: ''
					})

					const { error } = await request(resetVersion(systemId, item.id))

					if (error) {
						setStatus({
							loading: false,
							message: ''
						})

						return
					}

					setStatus({
						loading: false,
						message: t('VERSIONS_RESET')
					})
				}

				const onTakeover = async (data: FormDataTakeover) => {
					setStatus({
						loading: true,
						message: ''
					})

					const { error } = await request(
						takeOverVersion(systemId, item.id, parseFloat(data.sourceEnvId))
					)

					if (error) {
						setStatus({
							loading: false,
							message: ''
						})

						return
					}

					setStatus({
						loading: false,
						message: t('VERSIONS_TOOK_OVER')
					})
				}

				const onClose = () => {
					setStatus({
						loading: false,
						message: ''
					})
				}

				return (
					<Flex
						style={
							fromProps
								? {
										marginBottom: '10px',
										padding: '0 10px',
										width: '100%'
								  }
								: { justifyContent: 'center' }
						}
					>
						<Loader loaded={!status.loading} absolute />
						<DialogWrapper
							dialog={(opened, onClose) => (
								<ModalForm
									onClose={() => {
										onClose()
									}}
									header={t('RESET_VERSIONS')}
									saveTitle={t('RESET_VERSIONS')}
									onSubmit={async () => {
										await onReset()
										onClose()
									}}
									open={opened}
								>
									<></>
								</ModalForm>
							)}
						>
							{onClick => (
								<ButtonReset
									disabled={readonly}
									isLoading={status.loading}
									size="sm"
									onClick={onClick}
								>
									{t('RESET_VERSIONS')}
								</ButtonReset>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) => (
								<ModalForm<FormDataTakeover>
									onClose={() => {
										onClose()
									}}
									header={t('TAKE_OVER_VERSIONS')}
									saveTitle={t('TAKE_OVER_VERSIONS')}
									onSubmit={async data => {
										await onTakeover(data)
										onClose()
									}}
									open={opened}
								>
									<Warning>
										{t('ONLY_PUSHED_ENVIRONMENTS_CAN_BE_ADDED')}
									</Warning>
									<SelectFormField
										name="sourceEnvId"
										title={t('SOURCE_ENVIRONMENT')}
										options={envsOptions}
										valueKey="id"
										labelKey="name"
										required
										placeholder=""
									/>
								</ModalForm>
							)}
						>
							{onClick => (
								<Button disabled={readonly} size="sm" onClick={onClick}>
									{t('TAKE_OVER_VERSIONS')}
								</Button>
							)}
						</DialogWrapper>

						{status.message && !apiError && (
							<MessageModal
								title={t('DONE')}
								message={status.message}
								type="info"
								onClose={onClose}
							/>
						)}
					</Flex>
				)
			}
		}
	]
)

const Warning = styled.div`
	margin-bottom: 10px;
`

const ButtonReset = styled(Button)`
	margin-right: 10px;
`
