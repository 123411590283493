import { Actions } from './actions'
import { UPDATE_ACTIONS_TIMESTAMPS } from './constants'

export type Timestamp = {
	timestamp: number
}

export type State = Readonly<{
	[actionType: string]: {
		[id in string | number]: {
			start: number
		}
	}
}>

const initialState = {} as State

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case UPDATE_ACTIONS_TIMESTAMPS: {
			const { actionType, id, start } = action.payload

			return {
				...state,
				[actionType]: {
					...(state[actionType] ?? {}),
					[id]: {
						start: start
					}
				}
			}
		}

		default: {
			return state
		}
	}
}
