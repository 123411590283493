import { CustomAttributeForm } from '@/pages/User/pages/Home/components/CustomAttributes/hooks/useCustomAttributes'
import { IdCodeName } from './general'

export interface QueueData {
	name?: string
	code?: string
	description?: string
	comment?: string
	objectSettings?: IdCodeName[]
	stereotypeId?: number
	stereotypeCode?: string
	status?: QueuOverview.QueueStatusEnum
	responsiblePersonId?: number
	responsiblePersonName?: string
	responsibleAnalystId?: number
	responsibleAnalystName?: string
	script?: string
	preScript?: string
	postScript?: string
	ownerId?: number
	ownerName?: string
	customAttributes?: CustomAttributeForm[]
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace QueuOverview {
	export enum QueueStatusEnum {
		LOGICAL_IN_PROGRESS = 'logical_in_progress',
		LOGICAL_COMPLETED = 'logical_completed',
		PHYSICAL_IN_PROGRESS = 'physical_in_progress',
		PHYSICAL_COMPLETED = 'physical_completed',
		FROZEN = 'frozen'
	}
}
