import { ensureValidData } from '../helpers'
import {
	ApiCollectionData,
	ApiCollectionColumn
} from '@/api/schemas/api-collection'
import { v4 } from 'uuid'

export const defaultApiCollectionData: Readonly<ApiCollectionData> = {
	name: '',
	code: '',
	columns: [],
	columnsLastId: 0
}

export const getInitialApiCollectionData = (
	name: string,
	code = ''
): ApiCollectionData => {
	return ensureValidData(
		{
			name,
			code
		},
		defaultApiCollectionData
	)
}

export const createEmptyColumn = (
	columns: ApiCollectionColumn[]
): ApiCollectionColumn => ({
	id: -1,
	uuid: v4(),
	order: Math.max(0, ...columns.map(c => c.order || 0)) + 1,
	name: '',
	notNull: false
})
