import { camelCase } from 'lodash'
import { useEffect } from 'react'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import { CsvMapping, ActionTypeEnum } from '../context/types'
import { useFields } from './useFields'

/** Match fields on init (with same header name in csv as field name) */
export const useMatchFields = () => {
	const {
		state: { firstRow },
		dispatch
	} = useCsvImporterContext()

	const fields = useFields()

	useEffect(() => {
		if (firstRow) {
			let mappingUpdate = {} as CsvMapping
			const steps = Object.keys(fields)

			firstRow.forEach((value, columnIndex) => {
				for (const step of steps) {
					const fieldMatching = fields[
						(step as unknown) as keyof typeof fields
					].find(field => {
						const valueLowercase = camelCase(value).toLowerCase()

						return (
							field.name.toLowerCase() === valueLowercase ||
							camelCase(field.label).toLowerCase() === valueLowercase
						)
					})

					if (fieldMatching) {
						mappingUpdate = {
							...mappingUpdate,
							[fieldMatching.name]: columnIndex
						}

						break
					}
				}
			})

			dispatch({
				type: ActionTypeEnum.CSV_MAPPING_UPDATE,
				mapping: mappingUpdate
			})
		}
	}, [firstRow, fields])
}
