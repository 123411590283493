import { Text } from '@/components/UberForm'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useApiRequest } from '@/api/hooks'
import { useDebounce } from '@/utils/hooks'
import { searchFullTextJson } from '@/api'
import { FullTextSearchDto } from '@/api/models'
import { Results } from './components/Results'
import { transformQuery } from './parsers/query'

type Props = {}

export const Search = ({}: Props) => {
	const request = useApiRequest()
	const [search, setSearch] = useState('')
	const [loading, setLoading] = useState(false)
	const [focused, setFocused] = useState(false)

	const [results, setResults] = useState(
		undefined as FullTextSearchDto[] | undefined
	)

	const query = useDebounce(search, 300)

	useEffect(() => {
		async function load() {
			setLoading(true)

			const { data: res } = await request(
				searchFullTextJson(transformQuery(query))
			)

			if (res !== null) {
				setResults(res)
			}

			setLoading(false)
		}

		if (query && query.length > 0) {
			load()
		} else {
			setResults(undefined)
			setLoading(false)
		}
	}, [query])

	return (
		<C>
			<StyledText
				inputIcon={faSearch}
				value={search}
				onChange={v => setSearch(v as string)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				clearable
			/>

			{focused && (results || loading) && (
				<Results results={results} loading={loading} />
			)}
		</C>
	)
}

const C = styled.div`
	margin-left: auto;
	flex-grow: 1;
	margin-right: 10px;
	max-width: 500px;
	position: relative;
`

const StyledText = styled(Text)`
	${props => css`
		color: #fff;

		&&&:focus {
			border-color: ${props.theme.colors.input.border};
		}
	`}
`
