import React, { useState } from 'react'
import { faTimes, faUnlock, faUpload } from '@fortawesome/free-solid-svg-icons'
import { Button, MessageModal } from '@/components'
import { Modal } from '@/components/Modal/Modal'
import { useAppContext } from '@/utils/hooks'
import { BatchEditSwitch } from './BatchEditSwitch'
import { toLower } from 'lodash'
import { useBatchEditContext } from './context/BatchEditContext'
import { useLocking } from './hooks/useLocking'
import { usePush } from './hooks/usePush'
import { ActionTypeEnum } from './context/types'
import { useOnUnlock } from './hooks/useOnUnlock'
import { PushModal } from '../../../PushModal/PushModal'
import { useResetData } from './hooks/useResetData'

export const BatchEditModal = () => {
	const { t } = useAppContext()
	const [showPushModal, setShowPushModal] = useState(false)

	const {
		state: {
			onClose,
			submitting,
			errors,
			locking,
			selectedNodes,
			selectedStructures
		},
		dispatch: dispatchContext
	} = useBatchEditContext()

	useLocking()
	const { afterPush, beforePush } = usePush()
	const onUnlock = useOnUnlock()
	const { resetting, resetData } = useResetData()

	return (
		<>
			<Modal
				open={true}
				contentStyle={{
					width: 1200,
					minWidth: 1200,
					height: 700,
					minHeight: 700
				}}
				header={`${t('BATCH_EDIT')}: ${selectedNodes.length} ${toLower(
					t('ELEMENTS')
				)}`}
				maximizeButtonVisible
				resizable
				footer={close => (
					<>
						<Button
							icon={faUpload}
							onClick={() => setShowPushModal(true)}
							isLoading={submitting}
							disabled={locking || resetting}
						>
							{t('PUSH')}
						</Button>
						<Button
							schema="warn"
							isLoading={resetting}
							onClick={async () => {
								await resetData()
								close()
							}}
							icon={faTimes}
							disabled={locking || resetting}
						>
							{t('CANCEL')}
						</Button>
						<Button
							schema="warn"
							onClick={async () => {
								await onUnlock()
								await resetData()

								onClose()
							}}
							icon={faUnlock}
							isLoading={locking || resetting}
							disabled={locking || resetting}
						>
							{t('CANCEL_AND_UNLOCK_ALL_ELEMENTS')}
						</Button>
					</>
				)}
				onClose={async () => {
					await resetData()
					onClose()
				}}
			>
				<BatchEditSwitch />
			</Modal>
			{showPushModal && (
				<PushModal
					nodes={selectedStructures}
					onClose={() => {
						setShowPushModal(false)
					}}
					beforePush={beforePush}
					afterPush={afterPush}
				/>
			)}
			{Object.entries(errors).map(([errorId, errorText]) => (
				<MessageModal
					key={errorId}
					title={t('ERROR_WHEN_SAVING')}
					opened={!!errorText}
					onClose={() => {
						const { [errorId]: _, ...errorsRemoved } = errors

						dispatchContext({
							type: ActionTypeEnum.SET,
							state: {
								errors: errorsRemoved
							}
						})
					}}
					message={errorText || ''}
					type="warn"
				/>
			))}
		</>
	)
}
