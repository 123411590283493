/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/no-var-requires */
import ace, { Ace } from 'ace-builds'

ace.config.setModuleUrl(
	'ace/snippets/sql',
	require('ace-builds/src-noconflict/snippets/sql.js')
)

ace.config.setModuleUrl(
	'ace/snippets/json',
	require('ace-builds/src-noconflict/snippets/json.js')
)

ace.config.setModuleUrl(
	'ace/mode/sql',
	require('ace-builds/src-noconflict/mode-sql.js')
)

ace.config.setModuleUrl(
	'ace/mode/json',
	require('ace-builds/src-noconflict/mode-json.js')
)

ace.config.setModuleUrl(
	'ace/theme/sqlserver',
	require('ace-builds/src-noconflict/theme-sqlserver.js')
)

ace.config.setModuleUrl(
	'ace/ext/language_tools',
	require('ace-builds/src-noconflict/ext-language_tools.js')
)

ace.config.setModuleUrl(
	'ace/ext/language_tools',
	require('ace-builds/src-noconflict/ext-language_tools.js')
)
