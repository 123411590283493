import React, { useCallback, useMemo } from 'react'
import { Row } from '../components/Row'
import { useTablePropertiesContext } from '../context/TablePropertiesContext'
import { ActionTypeEnum } from '../context/types'

type Props = {
	index: number
	style: React.CSSProperties
}

export const TablePropertiesRow = ({ style, index }: Props) => {
	const {
		state: {
			tableProps,
			readonly,
			columnWidths,
			items,
			dragOverRowIndex,
			draggingRowIndex,
			handleRowDrop,
			handleFocus,
			handleMove,
			onChange,
			onSelect,
			onDelete,
			onRowOrderChanged,
			focused,
			isDeletable,
			isReadonly,
			errors,
			resolveIsRowOrderable
		},
		dispatch
	} = useTablePropertiesContext()

	const item = useMemo(() => items[index], [items, index])

	const isRowOrderable = useMemo(() => resolveIsRowOrderable(index), [
		resolveIsRowOrderable,
		index
	])

	const onDragChanged = useCallback(
		(isDrag: boolean) => {
			dispatch({
				type: ActionTypeEnum.DRAGGING_ROW_INDEX_SET,
				payload: { draggingRowIndex: isDrag ? index : null }
			})

			if (!isDrag && dragOverRowIndex !== null) {
				dispatch({
					type: ActionTypeEnum.DRAG_OVER_INDEX_SET,
					payload: { dragOverRowIndex: null }
				})
			}
		},
		[dispatch, dragOverRowIndex, index]
	)

	return (
		<React.Fragment key={index}>
			<div style={style}>
				<Row
					index={index}
					isDragging={draggingRowIndex !== null}
					isDragOver={dragOverRowIndex === index}
					onDragChanged={onDragChanged}
					onRowDrop={handleRowDrop}
					key={index}
					properties={tableProps}
					item={item}
					listOfValues={items}
					readonly={readonly}
					onChange={onChange}
					onSelect={onSelect}
					onDelete={onDelete}
					columnWidths={columnWidths}
					isDeletable={isDeletable}
					onRowOrderChanged={onRowOrderChanged}
					isReadonly={isReadonly}
					isRowOrderable={isRowOrderable}
					errors={errors}
					isFocused={focused?.row === index}
					focusedField={focused?.row === index ? focused?.field : undefined}
					onFocus={handleFocus}
					onMove={handleMove}
				/>
			</div>
		</React.Fragment>
	)
}
