import React, { useMemo, useEffect, useState, useRef } from 'react'
import { Resizable } from '@/components/Resizable/Resizable'
import { Properties } from './Properties'
import { ItemProperty, PropertiesErrorProps } from './types'
import { FormValue } from '@/components/UberForm/Form'
import styled, { css } from 'styled-components'
import {
	setTabPropertiesShown,
	setTabPropertiesWidth
} from '@/store/modules/tab/actions'
import { StructureDto } from '@/api/models'
import { usePropertiesPanel } from './utils'
import {
	useDebounceCallback,
	useAppDispatch,
	useAppStore,
	useResizeObserver
} from '@/utils/hooks'
import { ColumnsPickerValue } from '../ColumnsPicker/ColumnsPicker'

type Props<T> = {
	node: StructureDto
	item: T | undefined
	index: number | undefined
	listOfValues: T[]
	properties: ItemProperty<T>[]
	onChange: (
		item: T,
		key: string,
		value: FormValue | ColumnsPickerValue
	) => void
	readonly: boolean
	tabKey: string
	errors?: PropertiesErrorProps
	children?:
		| React.ReactNode
		| ((propertiesWidth: number, propertiesHidden: boolean) => React.ReactNode)
	onSelect?: (index: number) => void
	panelPropertiesMinWidth?: number
	panelPropertiesDefaultOpen?: boolean
}

export const PanelProperties = <T extends object>({
	node,
	item,
	index,
	properties,
	onChange,
	readonly,
	children,
	listOfValues,
	errors,
	tabKey,
	onSelect,
	panelPropertiesMinWidth,
	panelPropertiesDefaultOpen
}: Props<T>) => {
	const dispatch = useAppDispatch()
	const { propertiesHidden } = usePropertiesPanel(node.id, tabKey)
	const refContainer = useRef<HTMLDivElement | null>(null)
	const entry = useResizeObserver(refContainer)

	const propertiesStoreWidth =
		useAppStore(
			state =>
				state.tab.openedTabs.find(t => t.nodeId === node.id)?.propertiesShown[
					tabKey
				]?.width
		) || 500

	const propertiesWidth = useMemo(
		() => (propertiesHidden ? 0 : propertiesStoreWidth),
		[propertiesHidden, propertiesStoreWidth]
	)

	const onWidthChange = useDebounceCallback((width: number) => {
		dispatch(setTabPropertiesWidth(node, tabKey, width))
	}, 50)

	const panelOpen = () => dispatch(setTabPropertiesShown(node, tabKey, true))

	useEffect(() => {
		if (panelPropertiesDefaultOpen) {
			panelOpen()
		}
	}, [panelPropertiesDefaultOpen])

	return (
		<PropsContainer propertiesHidden={propertiesHidden} ref={refContainer}>
			{typeof children !== 'function' && children}
			{typeof children === 'function' &&
				children(propertiesWidth, propertiesHidden)}
			{properties.length > 0 && (
				<Resizable
					initialWidth={propertiesStoreWidth}
					minWidth={panelPropertiesMinWidth ?? 150}
					maxWidth={entry?.contentRect?.width}
					onChange={onWidthChange}
				>
					<Properties
						item={item}
						index={index}
						onChange={onChange}
						properties={properties}
						listOfValues={listOfValues}
						readonly={readonly}
						isHidden={propertiesHidden}
						onShow={() => panelOpen()}
						onHide={() => dispatch(setTabPropertiesShown(node, tabKey, false))}
						errors={errors}
						propertiesWidth={propertiesWidth}
						onSelect={onSelect}
					/>
				</Resizable>
			)}
		</PropsContainer>
	)
}

const PropsContainer = styled.div<{ propertiesHidden: boolean }>`
	flex-grow: 1;
	position: relative;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	${props =>
		props.propertiesHidden &&
		css`
			.resizable {
				width: 0px !important;
			}

			.resizable-resizer {
				display: none;
			}
		`}
`
