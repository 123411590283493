import React from 'react'
import { AceEditor } from './AceEditor'
import {
	FormFieldProps,
	FormlessFormField
} from '@/components/UberForm/FormField'
import { FormField } from '@/components/UberForm'
import { AceEditorLabel } from './AceEditorLabel'
import { AceEditorProps } from './AceEditor'

type Props = AceEditorProps & FormFieldProps & { formless?: boolean }

export const AceEditorField = (props: Props) => {
	const fieldProps = { ...props }

	const FF = props.formless ? FormlessFormField : FormField

	return (
		<FF
			customLabel={(title: string, inputId: string, isHighlighted: boolean) => (
				<AceEditorLabel htmlFor={inputId} isHighlighted={isHighlighted}>
					{title}
				</AceEditorLabel>
			)}
			{...fieldProps}
		>
			<AceEditor {...fieldProps} />
		</FF>
	)
}
