import React from 'react'
import { PrePushValidationDto } from '@/api/models'
import { Button, DialogWrapper, LightTable, MessageModal } from '@/components'
import { useAppContext } from '@/utils/hooks'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useValidationsColumns } from '../hooks/useTemplatesColumns'

type Props = {
	validations: PrePushValidationDto[]
}

export const ValidationsModal = ({ validations }: Props) => {
	const { t } = useAppContext()
	const columns = useValidationsColumns()

	return (
		<>
			{validations.length > 0 && (
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<MessageModal
								title={t('IMPACTS')}
								type={'warn'}
								onClose={onClose}
								width={800}
								message={() => (
									<LightTable<PrePushValidationDto>
										data={validations}
										columns={columns}
										keyField={'structureId'}
									/>
								)}
							/>
						)
					}
				>
					{onClick => (
						<Button
							onClick={onClick}
							icon={faExclamationTriangle}
							schema="warn"
							style={{ marginLeft: 'auto' }}
						>
							{t('SHOW_IMPACTS')} ({validations.length})
						</Button>
					)}
				</DialogWrapper>
			)}
		</>
	)
}
