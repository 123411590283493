import { NativeMap } from '@/utils/collections'

import en from '@/i18n/en'

export type LANGUAGE = 'en'
export type LANGUAGE_KEY = keyof typeof en

type LanguageValues = (string | number)[]

export type TranslationHelper = Locale['translate']

const locales = {
	en
}

/**
 * Enhance given ComposedComponent with i18n implementation
 * @param ComposedComponent React component to be enhanced
 */
class Locale {
	private locale = 'en' as LANGUAGE

	getLocale = () => {
		return this.locale
	}

	setLocale = (locale: LANGUAGE = 'en') => {
		this.locale = locale.split('-')[0].toLowerCase() as LANGUAGE
	}

	resolveLabel = (label: string, context: NativeMap<string>) => {
		return context[label]
	}

	translate = (label: LANGUAGE_KEY, values?: LanguageValues): string => {
		const { locale } = this
		const result = this.resolveLabel(label, locales[locale])

		if (values && result) {
			return values
				.map(v => (v !== null && v !== undefined ? v.toString() : ''))
				.reduce((text, value, index) => {
					return text.replace(
						new RegExp(`\\{${index}\\}`, 'g'),
						value.toString()
					)
				}, result)
		}

		return result !== undefined ? result : '??' + label + '??'
	}
}

export default Locale
