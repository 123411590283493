import { apiCallAction } from '@/store/utils'
import { NODE_LOAD, NODE_LOAD_HISTORY_VERSION } from './constants'
import { HistoryDetailDto, StructureDetailDto } from '@/api/models'
import { getNodeDetail, getNodeHistoryDetailByVersion } from '@/api'

export interface LoadNode {
	type: typeof NODE_LOAD
	payload: StructureDetailDto
}

export interface LoadNodeHistoryVersion {
	type: typeof NODE_LOAD_HISTORY_VERSION
	payload: HistoryDetailDto
}

export const loadNode = (id: number, abortController?: AbortController) => {
	return apiCallAction<LoadNode>(
		() => getNodeDetail(id, abortController),
		NODE_LOAD
	)
}

export const loadNodeHistoryVersion = (
	id: number,
	version: number,
	envId?: number
) =>
	apiCallAction<LoadNodeHistoryVersion>(
		() => getNodeHistoryDetailByVersion(id, version, { envId }),
		NODE_LOAD_HISTORY_VERSION
	)
