import { StructureDto } from '@/api/models'

export const isEditableNodeType = (type: StructureDto.TypeEnum) =>
	[
		StructureDto.TypeEnum.TABLE,
		StructureDto.TypeEnum.VIEW,
		StructureDto.TypeEnum.MAPPING,
		StructureDto.TypeEnum.WORKFLOW,
		StructureDto.TypeEnum.SYSTEM,
		StructureDto.TypeEnum.API_COLLECTION,
		StructureDto.TypeEnum.API_NODE,
		StructureDto.TypeEnum.MAPPING_READ,
		StructureDto.TypeEnum.QUEUE
	].includes(type)

export const hasNodePermissions = (type: StructureDto.TypeEnum) =>
	[
		StructureDto.TypeEnum.FOLDER,
		StructureDto.TypeEnum.FOLDER_WORKFLOW,
		StructureDto.TypeEnum.SYSTEM
	].includes(type)

export const isImportableNodeType = (type: StructureDto.TypeEnum) =>
	[
		StructureDto.TypeEnum.ROOT,
		StructureDto.TypeEnum.SYSTEM,
		StructureDto.TypeEnum.FOLDER,
		StructureDto.TypeEnum.FOLDER_WORKFLOW,
		StructureDto.TypeEnum.TABLE,
		StructureDto.TypeEnum.API_NODE,
		StructureDto.TypeEnum.QUEUE
	].includes(type)

export const isDublicableNodeType = (type: StructureDto.TypeEnum) =>
	[
		StructureDto.TypeEnum.SYSTEM,
		StructureDto.TypeEnum.FOLDER,
		StructureDto.TypeEnum.FOLDER_WORKFLOW,
		StructureDto.TypeEnum.TABLE,
		StructureDto.TypeEnum.VIEW,
		StructureDto.TypeEnum.API_NODE,
		StructureDto.TypeEnum.API_COLLECTION,
		StructureDto.TypeEnum.MAPPING,
		StructureDto.TypeEnum.MAPPING_DEFAULT,
		StructureDto.TypeEnum.MAPPING_READ,
		StructureDto.TypeEnum.QUEUE
	].includes(type)

export const isNestableNodeType = (type: StructureDto.TypeEnum) =>
	[
		StructureDto.TypeEnum.SYSTEM,
		StructureDto.TypeEnum.FOLDER,
		StructureDto.TypeEnum.FOLDER_WORKFLOW
	].includes(type)
