import { Actions } from './actions'
import { HISTORY_PUSH, HISTORY_REVERT, HISTORY_GET } from './constants'
import { NativeMap } from '@/utils/collections'
import { HistoryDto } from '@/api/models'

type State = Readonly<typeof initialState>

const initialState = {
	list: {} as NativeMap<HistoryDto[]>
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case HISTORY_PUSH:

		case HISTORY_REVERT: {
			return {
				...state
			}
		}

		case HISTORY_GET: {
			return {
				...state,
				list: {
					...state.list,
					[action.metadata.structureId]: action.payload
				}
			}
		}

		default:
			return state
	}
}
