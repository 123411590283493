import { ItemPropertyType } from '../../../../../../../../components/Properties/types'
import { ItemProperty } from '../../../../../../../../components/Properties/types'
import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { SystemUser, TablePermission } from '@/api/models'
import { capitalize } from 'lodash'
import { ApiHookResult } from '@/api/hooks'
import { MappingPermission } from '@/api/schemas'

export const PermissionsProperties = memoizeOne(
	(
		t: TranslationHelper,
		permissions: MappingPermission.PermissionsEnum[],
		systemUsers: ApiHookResult<SystemUser[]>
	): ItemProperty<TablePermission>[] => {
		return [
			{
				field: 'userId',
				label: t('SYSTEM_USER'),
				type: ItemPropertyType.OPTION,
				options: systemUsers.data ?? [],
				optionValue: 'id',
				optionLabel: 'name',
				flex: 1,
				showWhenPanelOpened: true,
				isNumeric: true
			},
			{
				field: 'permissions',
				label: t('PERMISSIONS'),
				type: ItemPropertyType.OPTIONS,
				flex: 2,
				showWhenPanelOpened: true,
				options: permissions.map(p => ({ value: p, label: capitalize(p) }))
			}
		]
	}
)
