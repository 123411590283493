import React, { useState, useEffect, RefObject } from 'react'
import styled, { css } from 'styled-components'
import { Portal } from '../Portal/Portal'

type Props = {
	error?: React.ReactNode
	focus: boolean
	wrapperRef: RefObject<HTMLDivElement>
}

type Position = {
	left: number
	top: number
}

export const ErrorTooltip = ({ error, focus, wrapperRef }: Props) => {
	const [position, setPosition] = useState(null as Position | null)

	useEffect(() => {
		const handleResize = () => {
			setPosition(calculatePosition())
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [focus])

	const calculatePosition = () => {
		if (!wrapperRef.current || !focus) {
			return null
		}

		const { left, bottom } = wrapperRef.current.getBoundingClientRect()

		if (!left || !bottom) {
			return null
		}

		return {
			left: left + 14,
			top: window.pageYOffset + bottom
		}
	}

	if (!error || !focus || !position) {
		return null
	}

	return (
		<Portal>
			<Container style={position}>{error}</Container>
		</Portal>
	)
}

const Container = styled.div`
	position: absolute;
	padding: 5px 10px;
	z-index: 1001;

	${props => css`
		background: ${props.theme.colors.form.error.background};
		color: ${props.theme.colors.form.error.color};

		&::before {
			content: ' ';
			position: absolute;
			top: -10px;
			left: 10%;
			margin-left: -5px;
			border: 5px solid transparent;
			border-bottom-color: ${props.theme.colors.form.error.background};
		}
	`}
`
