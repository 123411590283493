import React from 'react'
import { render } from 'react-dom'
import 'simplebar/dist/simplebar.min.css'
import { Keycloak } from './Keycloak'
import Promise from 'bluebird'

Promise.config({
	cancellation: true
})

render(<Keycloak />, document.getElementById('application'))
