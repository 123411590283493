import React, { RefObject } from 'react'

import {
	Label,
	Input,
	Span
} from '@/components/UberForm/Input/CheckBox/CheckBox'

interface Props {
	checked: boolean
	name?: string
	disabled: boolean
	onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
	onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	focused: boolean
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	fromProps?: boolean
	refCheckbox?: RefObject<HTMLInputElement>
	error?: string
	label?: string
}

export const Checkbox = ({
	checked,
	name,
	onChange,
	disabled,
	onFocus,
	onBlur,
	label,
	focused,
	refCheckbox,
	fromProps
}: Props) => {
	return (
		<Label
			checked={checked}
			focused={focused}
			disabled={disabled}
			compact={false}
			isHorizontal={false}
		>
			<Input
				type="checkbox"
				ref={refCheckbox}
				id={name}
				name={name}
				disabled={disabled}
				onChange={onChange}
				// prevent spacebar to change value (handled by Cell)
				onKeyUp={e => !fromProps && e.preventDefault()}
				checked={checked}
				tabIndex={fromProps ? 0 : -1}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			{label && <Span compact={false}>{label}</Span>}
		</Label>
	)
}
