import { StructureDto } from '@/api/models'
import { State } from '.'
import { NativeMap } from '@/utils/collections'

export const removeDeletedNodes = (deletedNode: StructureDto, state: State) => {
	const removedIds: number[] = []
	const parentId = deletedNode.parentStructureId as number

	const updatedNodes = { ...state.nodes }

	const updatedChildren: NativeMap<number[]> = {
		...state.children,
		[parentId]: (state.children[parentId] || []).filter(
			x => x !== deletedNode.id
		)
	}

	const deleteNodeIncludingChildren = (id: number) => {
		delete updatedNodes[id]
		delete updatedChildren[id]
		removedIds.push(id)

		const children = state.children[id] || []
		children.forEach(childId => deleteNodeIncludingChildren(childId))
	}

	deleteNodeIncludingChildren(deletedNode.id)

	return {
		nodes: updatedNodes,
		children: updatedChildren,
		removedIds
	}
}

export const findSystemNodeId = (
	node: StructureDto,
	nodes: NativeMap<StructureDto>
): number => {
	if (node.type === StructureDto.TypeEnum.SYSTEM || !node.parentStructureId) {
		return node.id
	}

	// TODO: How can we be sure that parent is loaded
	const parent = nodes[node.parentStructureId]

	if (!parent) {
		return node.id
	}

	return findSystemNodeId(parent, nodes)
}

export const getParents = (
	node: StructureDto,
	nodes: NativeMap<StructureDto>,
	parentIds: number[]
): number[] => {
	if (
		node.parentStructureId === undefined ||
		node.parentStructureId === null ||
		nodes[node.parentStructureId] === undefined
	) {
		return parentIds
	}

	return getParents(nodes[node.parentStructureId] as StructureDto, nodes, [
		...parentIds,
		node.parentStructureId
	])
}
