import React, { memo } from 'react'

import styled from 'styled-components'
import Button, { Size, Schema } from '../Button/Button'
import { NamingPlaceholder } from '@/pages/User/pages/Settings/pages/Naming/utils'
import { useAppContext } from '@/utils/hooks'

interface Props {
	disabled?: boolean
	size?: Size
	schema?: Schema
	onClick: (key: string) => void
	items: NamingPlaceholder
	buttonClassName?: string
	className?: string
	preventDefault?: boolean
}

const SimpleButtonsGroup = ({
	disabled = false,
	size = 'sm',
	schema,
	buttonClassName,
	className,
	items,
	preventDefault,
	onClick
}: Props) => {
	const { t } = useAppContext()

	return (
		<Container className={className}>
			{Object.keys(items).map(key => (
				<StyledButton
					className={buttonClassName}
					key={key}
					onClick={() => onClick(key)}
					onMouseDown={e => preventDefault && e.preventDefault()}
					size={size}
					schema={schema}
					disabled={disabled}
				>
					{t(items[key])}
				</StyledButton>
			))}
		</Container>
	)
}

const StyledButton = styled(Button)`
	flex: 0;
	border-radius: 5px;
	padding: 4px 8px;
	white-space: nowrap;
	text-transform: initial;
	background: white;
	color: ${({ theme }) => theme.mainColors.primary};
`

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	${StyledButton} {
		margin-right: 2px;
	}
`

export default memo(SimpleButtonsGroup)
