import { TableMode } from '@/store/modules/table/types'
import { useAppDispatch } from '@/utils/hooks'
import { useCallback, useMemo, useState } from 'react'
import { useBatchEditContext } from '../context/BatchEditContext'
import { useTypeData } from './useTypeData'

/** Reset data to previous state after cancelling batch edit */
export const useResetData = () => {
	const dispatch = useAppDispatch()
	const [resetting, setResetting] = useState(false)

	const {
		state: { selectedStructures, type }
	} = useBatchEditContext()

	const { initData } = useTypeData(type)

	const resetData = useCallback(async () => {
		setResetting(true)

		const reloads = selectedStructures.map(node =>
			dispatch(
				initData({
					nodeId: node.id,
					editMode: false,
					mode: TableMode.TABLE,
					force: true
				})
			)
		)

		await Promise.allSettled(reloads)
		setResetting(false)
	}, [dispatch, initData, selectedStructures])

	const result = useMemo(() => ({ resetting, resetData }), [
		resetData,
		resetting
	])

	return result
}
