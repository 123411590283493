import { ApiNodeData } from '@/api/schemas/api-node'
import { updateTabData } from '@/store/utils'
import { NativeMap } from '@/utils/collections'
import { ensureValidData } from '../helpers'
import { flatMappingPermission } from '../mapping/helpers'
import { MappingPermissionFlat } from '../mapping/types'
import { GeneralNodeActions } from '../tab/actions'
import { TAB_CLOSE } from '../tab/constants'
import { Actions } from './actions'
import {
	API_NODE_INIT,
	API_NODE_SAVE,
	API_NODE_SELECT_TAB,
	API_NODE_UPDATE,
	API_NODE_UPDATE_GRAPH_DATA
} from './constants'
import { defaultApiNodeData, getInitialApiNodeData } from './helpers'
import { ApiNodeTab, OpenedApiNodeData } from './types'

export type State = Readonly<typeof initialState>

const initialState = {
	nodes: {} as NativeMap<OpenedApiNodeData>
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const nodes = { ...state.nodes }
			delete nodes[action.nodeId]

			return {
				...state,
				nodes
			}
		}

		case API_NODE_INIT: {
			const { node, editMode, force } = action
			const previous = state.nodes[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: ApiNodeData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultApiNodeData)
				: getInitialApiNodeData(node.name)

			data.columns = data.columns.filter(c => !c.stereotypeColumn)

			if (typeof data.columnsLastId !== 'number') {
				data.columnsLastId = Math.max(0, ...data.columns.map(c => c.id))
			}

			const objectPermissions =
				data.objectPermissions
					?.filter(x => x.user)
					.map(flatMappingPermission) ?? []

			data.objectPermissions = objectPermissions as MappingPermissionFlat[]

			if (typeof data.objectPermissionLastId !== 'number') {
				data.objectPermissionLastId = Math.max(
					0,
					...(data.objectPermissions?.map(c => c.id ?? 0) ?? [0])
				)
			}

			const apiNode: OpenedApiNodeData = {
				form: {
					...data
				},
				original: data,
				tab: previous ? previous.tab : ApiNodeTab.General,
				selected: previous
					? previous.selected
					: {
							column: null
					  },
				dirty: false,
				parsedEditMode: editMode,
				graph: {
					zoom: 1,
					offset: { x: 0, y: 0 }
				}
			}

			return {
				...state,
				nodes: {
					...state.nodes,
					[node.id]: apiNode
				}
			}
		}

		case API_NODE_SAVE: {
			const id = action.metadata.node.id
			const node = state.nodes[id]

			const nodes = {
				...state.nodes,
				[id]: {
					...node,
					dirty: false
				}
			}

			return {
				...state,
				nodes
			}
		}

		case API_NODE_UPDATE: {
			const { node, update } = action

			return {
				...state,
				nodes: updateTabData(state.nodes, node.id, node => ({
					...node,
					form: {
						...node.form,
						...update
					},
					dirty: true
				}))
			}
		}

		case API_NODE_SELECT_TAB: {
			const { node, tab } = action

			return {
				...state,
				nodes: updateTabData(state.nodes, node.id, node => ({
					...node,
					tab
				}))
			}
		}

		case API_NODE_UPDATE_GRAPH_DATA: {
			const { node, update } = action

			return {
				...state,
				nodes: updateTabData(state.nodes, node.id, node => ({
					...node,
					graph: {
						...node.graph,
						...update
					}
				}))
			}
		}

		default:
			return state
	}
}
