export enum TreeItemDropDirection {
	UP = 'UP',
	INSIDE = 'INSIDE'
}

export type GetDragDrop<N> = (node: N) => DragDrop
export interface DragDrop {
	draggable: boolean
	onDragStart: (e: React.DragEvent) => void
	onDrop: (e: React.DragEvent, dropDirection: TreeItemDropDirection) => void
	onDragLeave: (e: React.DragEvent) => void
	onDragEnter: (e: React.DragEvent) => void
	onDragOver: (e: React.DragEvent) => void
	canDropInside?: boolean
}

export interface TreeNode {
	key: string | number
	title: string
	label?: React.ReactNode
	children?: this[]
}
