import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFolder,
	faProjectDiagram,
	faTable,
	faDatabase,
	faFolderOpen,
	faWindowMaximize,
	faWifi,
	faLayerGroup,
	faRetweet,
	faTasks
} from '@fortawesome/free-solid-svg-icons'

import { StructureDto } from '@/api/models'
import entityColors from '@/styles/entityColors'

const typeToIcon = {
	[StructureDto.TypeEnum.ROOT]: faFolder,
	[StructureDto.TypeEnum.SYSTEM]: faDatabase,
	[StructureDto.TypeEnum.FOLDER]: faFolder,
	[StructureDto.TypeEnum.FOLDER_WORKFLOW]: faFolder,
	[StructureDto.TypeEnum.TABLE]: faTable,
	[StructureDto.TypeEnum.TABLE_COLUMN]: faTable,
	[StructureDto.TypeEnum.VIEW]: faWindowMaximize,
	[StructureDto.TypeEnum.MAPPING]: faRetweet,
	[StructureDto.TypeEnum.MAPPING_DEFAULT]: faRetweet,
	[StructureDto.TypeEnum.WORKFLOW]: faProjectDiagram,
	[StructureDto.TypeEnum.API_NODE]: faWifi,
	[StructureDto.TypeEnum.MAPPING_READ]: faRetweet,
	[StructureDto.TypeEnum.API_COLLECTION]: faLayerGroup,
	[StructureDto.TypeEnum.QUEUE]: faTasks,
	[StructureDto.TypeEnum.MAPPING_WRITE]: faRetweet,
	[StructureDto.TypeEnum.CONSTANT_DOMAIN]: faRetweet
}

const typeToExpandedIcon = {
	[StructureDto.TypeEnum.FOLDER]: faFolderOpen,
	[StructureDto.TypeEnum.FOLDER_WORKFLOW]: faFolderOpen
}

export const EntityTypeIcon = ({
	type,
	expanded
}: {
	type: StructureDto.TypeEnum
	expanded?: boolean
}) => (
	<FontAwesomeIcon
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		icon={(expanded && (typeToExpandedIcon as any)[type]) || typeToIcon[type]}
		color={entityColors[type]}
		fixedWidth
	/>
)
