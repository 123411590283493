import React, { useCallback, useMemo } from 'react'
import { useAppDispatch } from '@/utils/hooks'
import { Loader, Button } from '@/components'
import { Overview } from './pages/Overview/Overview'
import { Columns } from './pages/Columns/Columns'
import { Indexes } from './pages/Indexes/Indexes'
import { TableData, HistoryTableData } from '@/api/schemas'
import { TableTab, TableMode } from '@/store/modules/table/types'
import {
	saveTable,
	updateTable,
	initTable,
	selectTableTab,
	syncTableField
} from '@/store/modules/table/actions'
import { TabProps, Tabs } from '@/components/Tabs/Tabs'
import { Title } from '../../components/Title'
import { Constraints } from './pages/Constraints/Constraints'
import { PhysicalOptions } from './pages/PhysicalOptions/PhysicalOptions'
import { Mappings } from './pages/Mappings/Mappings'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppStore, useDebounceCallback, useAppContext } from '@/utils/hooks'
import styled from 'styled-components'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { useTabContext } from '@/context/TabContext/TabContext'
import { Deployments } from '../../components/Deployments/Deployments'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { Preview } from '../../components/Preview/Preview'
import { getUnsyncedFields, getModeForm } from './utils'
import { Validation } from '../../components/Validation/Validation'
import { useHandleModeSwitch } from './hooks/useHandleModeSwitch'
import { useUpdateNaming } from './hooks/useUpdateNaming'
import { Permissions } from './pages/Permissions/Permissions'
import { useDeletedDomains } from '@/utils/domain'
import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { uniqBy } from 'lodash'
import { useNodeInit } from '../../hooks/useNodeInit'
import { Dependencies } from '../../components/Dependencies/Dependencies'

const TableDetailComponent = () => {
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const dispatch = useAppDispatch()

	const {
		state: { node, systemNodeId, editMode }
	} = useDetailTabContext()

	const tables = useAppStore(state => state.table.tables)
	const table = tables[node.id]
	const mode = table ? table.mode : TableMode.TABLE
	const objectTypes = table?.form.objectSettings
	const domains = useDeletedDomains(systemNodeId, table)

	/** Update data in client from BE */
	const reloadTableData = useCallback(() => {
		dispatch(initTable({ nodeId: node.id, editMode, mode, force: true }))
	}, [dispatch, editMode, mode, node.id])

	useNodeInit(mode)

	//To stereotypeColumns add history columns (flattened) (if TableMode.HISTORY) and sort all together
	const technicalColumns = useMemo(
		() =>
			uniqBy(
				(table?.stereotypeColumns || []).concat(
					table?.historyColumns && mode === TableMode.HISTORY
						? table.historyColumns
						: []
				),
				column => column.code
			).sort((a, b) => a.order - b.order),
		[table, mode]
	)

	const [unsyncedFields, modeForm] = useMemo(() => {
		const unsyncedFields = getUnsyncedFields(mode, table?.form)
		const modeForm = getModeForm(mode, table?.form, unsyncedFields)

		return [unsyncedFields, modeForm]
	}, [mode, table])

	const handleSave = useDebounceCallback(async () => {
		if (!table || !editMode) {
			return
		}

		try {
			await dispatch(saveTable(node))
		} catch (e) {
			onSaveError(e)
		}
	}, 1000)

	const handleEdit = async () => {
		await dispatch(initTable({ nodeId: node.id, editMode: true, mode }))
	}

	const handleCancel = async () => {
		await dispatch(initTable({ nodeId: node.id, editMode: false, mode }))
	}

	const handleChange = useCallback(
		(data: UpdateDeepPartial<TableData>) => {
			if (!editMode) {
				return
			}

			if (data.hasHistoryTable !== undefined) {
				if (!data.historyTable) {
					data.historyTable = {}
				}
			}

			dispatch(updateTable(node, data, mode))
			handleSave()
		},
		[editMode, dispatch, node, mode, handleSave]
	)

	const handleTabChange = (tab: TabProps) => {
		dispatch(selectTableTab(node, tab.id as TableTab))
	}

	const handleModeSwitch = useHandleModeSwitch(mode, node, editMode, table)
	const updateNaming = useUpdateNaming(node, table)

	const beforePush = useCallback(async () => {
		if (table?.form.hasHistoryTable) {
			await updateNaming(TableMode.HISTORY)
		}

		if (table?.form.hasReferenceTable) {
			await updateNaming(TableMode.REFERENCE)
		}

		try {
			await dispatch(saveTable(node))
		} catch (e) {
			onSaveError(e)
		}
	}, [table, updateNaming, dispatch, node, onSaveError])

	const handleSync = useCallback(
		(field: keyof HistoryTableData) => {
			dispatch(syncTableField(node, field, mode))
			handleChange({})
		},
		[dispatch, handleChange, mode, node]
	)

	const tabs = useMemo((): TabProps[] => {
		const { form = null } = table || {}

		if (!form || !table || !modeForm) {
			return []
		}

		return [
			{
				id: TableTab.General,
				title: t('TAB_OVERVIEW'),
				content: (
					<Overview
						node={node}
						data={table}
						systemNodeId={systemNodeId}
						editMode={editMode}
						mode={mode}
						onChange={handleChange}
						key={TableTab.General}
						onSync={handleSync}
						unsyncedFields={unsyncedFields}
						modeForm={modeForm}
						modeFormTable={table.form}
					/>
				)
			},
			{
				id: TableTab.Columns,
				title: t('TAB_COLUMNS'),
				content: (
					<Columns
						key={TableTab.Columns}
						data={table}
						node={node}
						systemNodeId={systemNodeId}
						editMode={editMode && mode === TableMode.TABLE}
						domains={domains ?? []}
						onChange={handleChange}
						technicalColumns={technicalColumns}
						reloadTableData={reloadTableData}
					/>
				)
			},
			{
				id: TableTab.Indexes,
				title: t('TAB_INDEXES'),
				content: (
					<Indexes
						key={TableTab.Indexes}
						data={table}
						node={node}
						editMode={editMode && mode === TableMode.TABLE}
						onChange={handleChange}
						technicalColumns={technicalColumns}
					/>
				)
			},
			{
				id: TableTab.Constraints,
				title: t('TAB_CONSTRAINTS'),
				content: (
					<Constraints
						key={TableTab.Constraints}
						data={table}
						node={node}
						systemNodeId={systemNodeId}
						editMode={editMode && mode === TableMode.TABLE}
						onChange={handleChange}
						technicalColumns={technicalColumns}
					/>
				)
			},
			{
				id: TableTab.PhysicalOptions,
				title: t('TAB_PHYSICAL_OPTIONS'),
				content: (
					<PhysicalOptions
						key={TableTab.PhysicalOptions}
						editMode={editMode}
						onChange={handleChange}
						onSync={handleSync}
						unsyncedFields={unsyncedFields}
						modeForm={modeForm}
					/>
				)
			},
			{
				id: TableTab.Permissions,
				title: t('PERMISSIONS'),
				content: (
					<Permissions
						key={TableTab.Permissions}
						node={node}
						editMode={editMode && mode === TableMode.TABLE}
						onChange={handleChange}
						data={table}
						systemNodeId={systemNodeId}
					/>
				)
			},
			...(mode === TableMode.TABLE
				? [
						{
							id: TableTab.Mappings,
							title: t('TAB_MAPPINGS'),
							content: <Mappings node={node} key="mappings" />
						}
				  ]
				: []),
			{
				id: TableTab.Preview,
				title: t('TAB_PREVIEW'),
				content: (
					<Preview
						key={TableTab.Preview}
						node={node}
						showObjectTypes
						objectTypes={objectTypes}
					/>
				)
			},
			{
				id: TableTab.Deployments,
				title: t('TAB_DEPLOYMENTS'),
				content: (
					<Deployments
						node={node}
						key={TableTab.Deployments}
						editMode={editMode}
					/>
				)
			},
			{
				id: TableTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={TableTab.Validation} node={node} />
			},
			{
				id: TableTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={TableTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				)
			}
		]
	}, [
		table,
		modeForm,
		t,
		node,
		systemNodeId,
		editMode,
		mode,
		handleChange,
		handleSync,
		unsyncedFields,
		domains,
		technicalColumns,
		reloadTableData,
		objectTypes
	])

	if (!table) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={
					mode === TableMode.HISTORY
						? table.form.historyTable?.name
						: mode === TableMode.REFERENCE
						? table.form.referenceTable?.name
						: table.form.name
				}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<RightButtons>
						<EditableNodeActions
							node={node}
							editMode={editMode}
							dirty={table.dirty}
							onEdit={handleEdit}
							onCancel={handleCancel}
							beforePush={beforePush}
							afterPush={reloadTableData}
							contentCustom={
								<>
									{(table.form.hasHistoryTable ||
										table.form.hasReferenceTable) && (
										<Switcher>
											<Button icon={faExchangeAlt} onClick={handleModeSwitch}>
												{mode === TableMode.HISTORY ||
												mode === TableMode.REFERENCE
													? t('SWITCH_TO_ORIGINAL_TABLE')
													: table.form.hasHistoryTable
													? t('SWITCH_TO_HISTORY_TABLE')
													: t('SWITCH_TO_TRANSLATION_TABLE')}
											</Button>
										</Switcher>
									)}
								</>
							}
						/>
					</RightButtons>
				}
			/>

			<Tabs tabs={tabs} onChange={handleTabChange} selectedTabId={table.tab} />
		</>
	)
}

const Switcher = styled.div`
	display: flex;

	> button {
		&:not(:hover) {
			background-color: ${props =>
				props.theme.colors.button.default.background};
			color: ${props => props.theme.colors.button.default.color};
			border-color: #ddd;
			& svg {
				color: ${props => props.theme.colors.button.primary.background};
			}
		}
		border-top: none;
		border-bottom: none;
		border-radius: 0;
	}
`

const RightButtons = styled.div`
	display: flex;
	height: 100%;
	position: relative;
`

export const TableDetail = React.memo(TableDetailComponent)
