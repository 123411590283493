import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { mainColors } from '@/styles'
import { lighten } from 'polished'
import { stopEvent } from '@/utils/events'
import { GetDragDrop, TreeNode, TreeItemDropDirection } from './types'
import { useWindowEvent } from '@/utils/hooks'
export interface TreeNodeProps<N extends TreeNode> {
	onItemClick?: (node: N) => void
	onItemDblClick?: (node: N) => void
	onItemContextMenu?: (node: N, e: React.MouseEvent) => void
	onItemExpand?: (node: N, expanded: boolean) => void
	onFocus?: (element: HTMLElement) => void
	iconComponent?: (
		node: N,
		expanded: boolean,
		highlighted: boolean
	) => React.ReactNode
	actionComponent?: (
		node: N,
		expanded: boolean,
		highlighted: boolean
	) => React.ReactNode
	hideArrows?: boolean
	focused?: string | number | null
	getDragDrop?: GetDragDrop<N>
	onCheckChange?: (isChecked: boolean, item: N, isSingle: boolean) => void
	showCheckbox?: boolean
	isChecked?: boolean
	isCheckedFunc?: (item: N) => boolean
}

type Props<N extends TreeNode> = TreeNodeProps<N> & {
	node: N
	highlighted: string | number | null
	level: number
	expanded: (string | number)[]
}

export const TreeItem = <N extends TreeNode>({
	expanded,
	node,
	onItemClick,
	onItemExpand,
	onItemDblClick,
	highlighted,
	level,
	focused,
	actionComponent,
	hideArrows,
	iconComponent,
	onItemContextMenu,
	onFocus,
	onCheckChange,
	showCheckbox,
	isCheckedFunc,
	getDragDrop
}: Props<N>) => {
	const { title, children, label } = node
	const isHighlighted = !!(highlighted && highlighted === node.key)
	const [dropActiveUp, setDropActiveUp] = useState(false)
	const [isCtrl, setIsCtrl] = useState(false)
	const [targetParent, setTargetParent] = useState<EventTarget | null>(null)
	const [dropActiveInside, setDropActiveInside] = useState(false)
	const itemRef = useRef<HTMLDivElement>(null)

	useWindowEvent<KeyboardEvent>('keydown', e => {
		setIsCtrl(e.ctrlKey)
	})

	useWindowEvent<KeyboardEvent>('keyup', e => {
		setIsCtrl(e.ctrlKey)
	})

	useEffect(() => {
		const item = itemRef.current

		if (onFocus && item && focused === node.key) {
			onFocus(item)
		}
	}, [itemRef, focused, onFocus])

	const isExpanded = useMemo(() => expanded.includes(node.key), [
		expanded,
		node.key
	])

	const handleClick = useCallback(() => {
		if (onItemClick) {
			onItemClick(node)
		}

		if (onItemExpand && node.children !== undefined && !isExpanded) {
			onItemExpand(node, true)
		}
	}, [node, onItemClick, onItemExpand, isExpanded])

	const handleDblClick = useCallback(() => {
		if (onItemDblClick) {
			onItemDblClick(node)
		}
	}, [onItemDblClick, node])

	const handleIconClick = useCallback(() => {
		if (onItemClick) {
			onItemClick(node)
		}

		if (onItemExpand && node.children !== undefined) {
			onItemExpand(node, !isExpanded)
		}
	}, [node, isExpanded, onItemClick, onItemExpand])

	const handleMouseDown = useCallback((event: React.MouseEvent) => {
		if (event.nativeEvent.detail > 1) {
			event.stopPropagation()
			event.preventDefault()
		}
	}, [])

	const handleContextMenu = useCallback(
		(event: React.MouseEvent) => {
			if (onItemContextMenu) {
				onItemContextMenu(node, event)
			}
		},
		[onItemContextMenu, node]
	)

	const dragDrop = useMemo(() => (getDragDrop ? getDragDrop(node) : null), [
		node,
		getDragDrop
	])

	return (
		<Container>
			<DragDropContainer
				highlighted={isHighlighted}
				level={level}
				hasChildren={!!children}
				hideArrows={!!hideArrows}
				active={dropActiveInside}
			>
				<Title
					ref={itemRef}
					level={level}
					hasChildren={!!children}
					hideArrows={!!hideArrows}
					onContextMenu={handleContextMenu}
					role="treeitem"
					{...(dragDrop && {
						draggable: dragDrop.draggable,
						onDragStart: dragDrop.onDragStart,
						onDragEnter: dragDrop.onDragEnter,
						onDragOver: dragDrop.onDragOver,
						onDragLeave: dragDrop.onDragLeave
					})}
					{...(dragDrop?.canDropInside && {
						onDragEnter: stopEvent(e => {
							dragDrop.onDragEnter(e)
							setDropActiveInside(true)
							setTargetParent(e.target)
						}),
						onDragLeave: e => {
							// prevent call from children
							if (targetParent == e.target) {
								e.preventDefault()
								e.stopPropagation()
								dragDrop.onDragLeave(e)
								setDropActiveInside(false)
							}
						},
						onDrop: stopEvent(e => {
							dragDrop.onDrop(e, TreeItemDropDirection.INSIDE)
							setDropActiveInside(false)
						})
					})}
				>
					{dragDrop && (
						<TitleDropUp
							active={dropActiveUp}
							onDragEnter={stopEvent(() => setDropActiveUp(true))}
							onDragLeave={stopEvent(() => setDropActiveUp(false))}
							onDrop={stopEvent(e => {
								dragDrop.onDrop(e, TreeItemDropDirection.UP)
								setDropActiveUp(false)
							})}
						/>
					)}
					{children && !hideArrows && (
						<TitleArrow
							isExpanded={isExpanded}
							onClick={handleIconClick}
							onMouseDown={handleMouseDown}
							role="button"
						>
							<FontAwesomeIcon icon={faChevronRight} fixedWidth />
						</TitleArrow>
					)}
					{showCheckbox && (
						<input
							type="checkbox"
							name="nodeSelect"
							checked={isCheckedFunc && isCheckedFunc(node)}
							onChange={e => {
								onCheckChange?.(e.currentTarget.checked, node, isCtrl)
							}}
						/>
					)}
					<TitleIcon
						onClick={!hideArrows ? handleClick : handleIconClick}
						onDoubleClick={!hideArrows ? handleDblClick : undefined}
						onMouseDown={handleMouseDown}
						role="button"
					>
						{iconComponent && iconComponent(node, isExpanded, isHighlighted)}
					</TitleIcon>

					<TitleText
						onClick={handleClick}
						onDoubleClick={handleDblClick}
						onMouseDown={handleMouseDown}
						title={title}
					>
						{label || title}
					</TitleText>
					<TitleActions>
						{actionComponent &&
							actionComponent(node, isExpanded, isHighlighted)}
					</TitleActions>
				</Title>
			</DragDropContainer>
			{isExpanded && children && (
				<Children level={level}>
					{children.map(c => (
						<TreeItem<N>
							node={c}
							key={c.key}
							showCheckbox={showCheckbox}
							isCheckedFunc={isCheckedFunc}
							onCheckChange={onCheckChange}
							onItemClick={onItemClick}
							onItemExpand={onItemExpand}
							onItemDblClick={onItemDblClick}
							onItemContextMenu={onItemContextMenu}
							iconComponent={iconComponent}
							actionComponent={actionComponent}
							highlighted={highlighted}
							level={level + 1}
							expanded={expanded}
							onFocus={onFocus}
							focused={focused}
							getDragDrop={getDragDrop}
						/>
					))}
				</Children>
			)}
		</Container>
	)
}

// gap in px between items
export const TREE_ITEM_GAP = 6

const Container = styled.div`
	position: relative;
`

const DragDropContainer = styled.div<{
	highlighted: boolean
	level: number
	hasChildren: boolean
	hideArrows: boolean
	active: boolean
}>`
	padding: 3px 0;

	${props => css`
		padding-left: ${props.level * 10 +
			(!props.hasChildren && !props.hideArrows ? 20 : 0)}px;
	`}

	${props =>
		props.highlighted &&
		css`
			background: #e3eeff;
		`}

	&:hover {
		background-color: ${props => (props.highlighted ? '#e3eeff' : '#f3f3f3')};
	}

	&:active {
		background-color: #f0f0f0;
	}

	background: ${props => props.active && lighten(0.3, mainColors.highlight)};
`

const TitleText = styled.div`
	position: relative;
	padding: 2px 5px 2px 0;
	flex: 1;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const TitleDropUp = styled.div<{ active: boolean }>`
	position: absolute;
	top: -${TREE_ITEM_GAP}px;
	left: 0;
	width: 100%;
	height: ${TREE_ITEM_GAP}px;
	z-index: 1;
	background: ${props => (props.active ? mainColors.highlight : 'transparent')};
`

const TitleArrow = styled.div<{ isExpanded: boolean }>`
	padding: 2px 0;
	margin-right: 3px;
	flex-grow: 0;
	flex-shrink: 0;
	transition: transform 0.1s;
	color: #777;

	${props =>
		props.isExpanded &&
		css`
			transform: rotate(90deg);
		`}
`

const TitleIcon = styled.div`
	padding: 2px 5px 2px 0px;
	flex-grow: 0;
	flex-shrink: 0;
`

const TitleActions = styled.div`
	transition: 0.15s;
`

const Title = styled.div<{
	level: number
	hasChildren: boolean
	hideArrows: boolean
}>`
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition-duration: 0.2s;
	transition-property: background, opacity;
	box-sizing: border-box;
`

const Children = styled.div<{ level: number }>`
	position: relative;

	&::before {
		content: '';
		border-left: 1px solid #ddd;
		height: 100%;
		width: 1px;
		position: absolute;
		z-index: 1;
		top: -3px;
		${props => css`
			left: ${8 + props.level * 10}px;
		`}
	}
`
