import { State } from '.'
import { OpenedTab } from './types'
import { StructureDto } from '@/api/models'
import { NativeMap } from '@/utils/collections'

export const closeDeletedTabs = (
	node: StructureDto,
	children: NativeMap<number[]>,
	state: State
) => {
	const removedIndexes: number[] = []
	const removedIds: number[] = []

	const addNode = (id: number) => {
		removedIds.push(id)
		;(children[id] || []).forEach(c => addNode(c))
	}

	const clearIfRemoved = (index: number | undefined) => {
		if (index === undefined) {
			return undefined
		}

		if (removedIndexes.includes(index)) {
			return undefined
		}

		const indexShift = removedIndexes.filter(x => x < index).length

		return index - indexShift
	}

	addNode(node.id)

	const openedTabs = state.openedTabs.reduce((acc, tab, index) => {
		if (removedIds.includes(tab.nodeId)) {
			removedIndexes.push(index)
		} else {
			acc.push({ ...tab })
		}

		return acc
	}, [] as OpenedTab[])

	return {
		openedTabs,
		selectedTab: clearIfRemoved(state.selectedTab),
		temporaryTab: clearIfRemoved(state.temporaryTab)
	}
}
