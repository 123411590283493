import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { ItemProperty, ItemPropertyType } from '@/components/Properties/types'
import { SystemUsers } from '@/api/schemas'

export const SystemUsersProperties = memoizeOne(
	(t: TranslationHelper): ItemProperty<SystemUsers>[] => [
		{
			field: 'name',
			label: t('FIELD_USERNAME'),
			type: ItemPropertyType.TEXT,
			showWhenPanelOpened: true
		},
		{
			field: 'comment',
			label: t('FIELD_COMMENT'),
			type: ItemPropertyType.TEXT,
			showWhenPanelOpened: true
		},
		{
			field: 'description',
			label: t('FIELD_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 2
		}
	]
)
