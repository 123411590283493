import React, { useState } from 'react'
import { ModalProps } from '@/components/Modal/Modal'
import { useAppContext } from '@/utils/hooks'
import { useApi, useApiRequest } from '@/api/hooks'
import { deleteTechnicalColumn, getTablesByTechnicalColumn } from '@/api'
import styled from 'styled-components'
import { ModalForm } from '@/components/Modal/ModalForm'
import { CheckBoxFormField } from '@/components/UberForm'
import { TechnicalColumnDto } from '@/api/models'

type Props = Partial<ModalProps> & {
	technicalColumn: TechnicalColumnDto
	onRemove: () => void
	onClose: () => void
}

export const TechnicalRemoveModal = ({
	technicalColumn,
	onClose,
	onRemove
}: Props) => {
	const [updateElements, setUpdateElements] = useState(true)
	const { t } = useAppContext()
	const request = useApiRequest()

	const tables = useApi(getTablesByTechnicalColumn(technicalColumn.id || 0))

	const isSomethingImpacted = (tables.data?.length ?? 0) > 0

	const handleSubmit = async () => {
		await request(
			deleteTechnicalColumn(technicalColumn.id || 0, {
				updateElements: isSomethingImpacted ? updateElements : false
			})
		)

		onRemove()
		onClose()
	}

	return (
		<ModalForm
			open={true}
			isLoading={tables.loading}
			onClose={onClose}
			saveTitle={t('REMOVE')}
			saveSchema="danger"
			header={t('REMOVE_TECH_COLUMN')}
			initialValues={{
				updateElements: true
			}}
			onSubmit={handleSubmit}
		>
			<div style={{ marginBottom: '10px', display: 'flex' }}>
				{t('REMOVE_DESC', [technicalColumn.name])}
			</div>
			{!tables.loading && isSomethingImpacted && (
				<>
					<div style={{ marginBottom: '10px', display: 'flex' }}>
						{t('IMPACTED_TABLES')}: {tables.data?.length ?? ''}
					</div>

					<Checkbox
						name="updateElements"
						onChange={value => setUpdateElements(value as boolean)}
						title={t('UPDATE_IMPACTED_TABLES')}
					/>
				</>
			)}
		</ModalForm>
	)
}

const Checkbox = styled(CheckBoxFormField)`
	flex: 1;
`
