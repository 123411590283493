import cn from 'classnames'
import React from 'react'

import { iterateColumns } from '../helpers'
import { Sort } from '../sort'
import { LightTableColumn } from '../types'

interface Props<T> {
	columns: LightTableColumn<T>[]
	className?: string
	sort: Sort
	onSortChanged: (sort: Sort) => void
	width: string
	scrollLeft?: number
}

export class LightTableHead<T> extends React.PureComponent<Props<T>> {
	container = React.createRef<HTMLDivElement>()
	table = React.createRef<HTMLTableElement>()

	getWidth() {
		return this.table.current ? this.table.current.offsetWidth : null
	}

	getHeight() {
		return this.container.current ? this.container.current.offsetHeight : null
	}

	handleSortClick = (field: string) => {
		const { sort, onSortChanged } = this.props

		const order =
			sort.field !== field ? 'asc' : sort.order === 'asc' ? 'desc' : 'asc'

		onSortChanged({ field, order })
	}

	renderFirstRow = (columns: LightTableColumn<T>[]) => (
		<div className="div-tr first-row-header" role="row">
			{iterateColumns(columns).map(({ column, style }) => (
				<div
					key={(column.key || column.field || '').toString()}
					style={{
						...(column.headerAlign
							? {
									textAlign: column.headerAlign
							  }
							: undefined),
						...style,
						...column.thStyle
					}}
					className={cn('div-th', column.thClassName, {
						['sticky-column']: column.sticky
					})}
					role="columnheader"
				>
					{column.title}
				</div>
			))}
		</div>
	)

	renderSecondRow = (columns: LightTableColumn<T>[], sort: Sort) => (
		<div className="div-tr second-row-header" role="row">
			{iterateColumns(columns).map(({ column, style }) => (
				<div
					key={(column.key || column.field || '').toString()}
					style={{
						...(column.subHeaderAlign || column.align
							? {
									textAlign: column.subHeaderAlign || column.align
							  }
							: undefined),
						...column.thStyle,
						...style
					}}
					className={cn('div-th', column.thClassName, {
						['sortable']: column.sortable,
						['sticky-column']: column.sticky
					})}
					onClick={
						column.sortable
							? this.handleSortClick.bind(this, column.field as string)
							: undefined
					}
					role="columnheader"
				>
					{column.subTitle}
					{column.sortable && (
						<div
							className={cn('order', {
								['current']: sort.field === column.field
							})}
						>
							{(sort.field !== column.field || sort.order === 'desc') && (
								<span className="caret" />
							)}
							{(sort.field !== column.field || sort.order === 'asc') && (
								<span className="caret up" />
							)}
						</div>
					)}
				</div>
			))}
		</div>
	)

	render() {
		const { columns, className, sort, width, scrollLeft } = this.props

		const secondRow = columns.filter(
			column => column.sortable || !!column.subTitle
		)

		return (
			<div className={cn('light-table-head', className)} ref={this.container}>
				<div
					className="light-table-head-table"
					ref={this.table}
					style={{
						minWidth: width,
						marginLeft: scrollLeft !== undefined ? -scrollLeft : undefined
					}}
				>
					{this.renderFirstRow(columns)}
					{secondRow.length > 0 && this.renderSecondRow(columns, sort)}
				</div>
			</div>
		)
	}
}
