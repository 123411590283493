import React from 'react'
import styled, { css } from 'styled-components'

import HelpIcon from '../HelpIcon/HelpIcon'

const LabelComponent = ({
	hideTitle,
	hideTooltip,
	showTitlePlaceholder,
	required,
	inputId,
	isHorizontal,
	title,
	tooltip,
	isFieldHighlighted,
	compact,
	customLabel
}: {
	hideTitle: boolean
	hideTooltip: boolean
	showTitlePlaceholder: boolean
	required: boolean
	inputId: string
	isHorizontal: boolean
	title: string | undefined
	tooltip: string | undefined
	isFieldHighlighted?: boolean
	compact?: boolean | undefined
	customLabel?: (
		title: string,
		inputId: string,
		isHighlighted: boolean
	) => JSX.Element
}) => (
	<>
		{title &&
			(!hideTitle || showTitlePlaceholder) &&
			(customLabel?.(title, inputId, isFieldHighlighted ?? false) || (
				<Container
					showTitlePlaceholder={!!showTitlePlaceholder}
					isRequired={!!required}
					htmlFor={inputId}
					compact={!!compact}
					isHorizontal={isHorizontal}
					isFieldHighlighted={isFieldHighlighted}
				>
					{!hideTitle && (
						<>
							<span dangerouslySetInnerHTML={{ __html: title }} />
							{!hideTooltip && tooltip && <HelpIcon tooltip={tooltip} />}
						</>
					)}
				</Container>
			))}
	</>
)

export const Label = React.memo(LabelComponent)

const Container = styled.label<{
	showTitlePlaceholder: boolean
	isRequired: boolean
	compact: boolean
	isHorizontal: boolean
	isFieldHighlighted?: boolean
}>`
	margin-bottom: 0;
	margin-top: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 17px;

	${props =>
		props.compact &&
		css`
			background: ${props.theme.colors.form.compact.header.background};
			border-width: 1px;
			border-color: ${props.theme.colors.form.compact.header.borderColor};
			border-style: solid;
			padding: 4px 6px;
			margin-bottom: -1px;
			height: 25px;
			color: ${props.theme.colors.form.compact.header.color};
		`}



	${props =>
		props.isRequired &&
		css`
			&::after {
				content: '*';
				display: inline-block;
				margin-left: 3px;
				position: relative;
				margin-right: -10px;
			}
		`}	

	${props =>
		props.isHorizontal &&
		css`
			padding-top: 5px;
			padding-bottom: 5px;
			height: auto;
		`}

		${props =>
			props.isFieldHighlighted &&
			css`
				background: ${props => props.theme.colors.warn.light};
			`}
`
