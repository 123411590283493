export enum QueryTokenType {
	Word = 1,
	Keyword,
	String
}

export type QueryToken = {
	type: QueryTokenType
	contents: string
}

const STRING_DELIMITER = '"'
const AND_KEYWORD = 'and'
const OR_KEYWORD = 'or'

const keywords = [AND_KEYWORD, OR_KEYWORD]

const tokenizeQuery = (query: string) => {
	let pos = 0
	const tokens = [] as QueryToken[]

	while (pos < query.length) {
		if (query.substr(pos, STRING_DELIMITER.length) === STRING_DELIMITER) {
			let nextPos = query.indexOf(STRING_DELIMITER, pos + 1)

			if (nextPos === -1) {
				nextPos = query.length
			}

			tokens.push({
				type: QueryTokenType.String,
				contents: query.substring(pos + 1, nextPos)
			})

			pos = nextPos + 1
		} else {
			const keyword = keywords.find(
				keyword =>
					query.substr(pos, keyword.length + 1).toLowerCase() ===
					keyword.toLowerCase() + ' '
			)

			if (keyword) {
				tokens.push({
					type: QueryTokenType.Keyword,
					contents: keyword
				})

				pos += keyword.length + 1
			} else {
				let nextPos = query.indexOf(' ', pos)

				if (nextPos === -1) {
					nextPos = query.length
				}

				keywords
					.map(keyword => query.toLowerCase().indexOf(keyword, pos))
					.forEach(pos => {
						if (pos !== -1 && pos < nextPos) {
							nextPos = pos
						}
					})

				tokens.push({
					type: QueryTokenType.Word,
					contents: query.substring(pos, nextPos)
				})

				pos = nextPos + 1
			}
		}
	}

	return tokens
}

export const transformQuery = (query: string) => {
	const tokens = tokenizeQuery(query)

	return tokens
		.map((t, index) => {
			const next = index < tokens.length - 1 ? tokens[index + 1] : undefined

			switch (t.type) {
				case QueryTokenType.Word:
					return (
						t.contents.replace(/([\*\:\&\|\\])/g, '\\$1') +
						':*' +
						(next && next?.type !== QueryTokenType.Keyword ? '|' : '')
					)

				case QueryTokenType.Keyword:
					switch (t.contents) {
						case AND_KEYWORD:
							return '&'
						case OR_KEYWORD:
							return '|'
						default:
							return ''
					}

				case QueryTokenType.String:
					return '(' + t.contents.split(' ').join('&') + ')'
			}
		})
		.join('')
		.replace(/[\&\|]$/, '')
}
