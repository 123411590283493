import React, { useCallback, useMemo, useState } from 'react'
import { StructureDto } from '@/api/models'
import { Breadcrumb } from '../Breadcrumb'
import { OpenedTab } from '@/store/modules/tab/types'
import styled from 'styled-components'
import { ViewHistory } from './components/ViewHistory'
import { DetailTabContextProvider } from './context/DetailTabContext'
import { DetailTabContextState } from './context/types'
import { DetailTabSwitcher } from './components/DetailTabSwitcher'

interface Props {
	node: StructureDto
	tab: OpenedTab
}

const DetailTabComponent = ({
	node,
	tab: { systemNodeId, editMode: editModeProp, selectedTab }
}: Props) => {
	const [version, setVersion] = useState<undefined | number>(undefined)
	const [versionMax, setVersionMax] = useState<undefined | number>(undefined)
	const [versionActive, setVersionActive] = useState(false)

	const onChangeVersion = useCallback(
		(version?: number) => setVersion(version),
		[]
	)

	const onChangeActive = useCallback(
		(active: boolean) => setVersionActive(active),
		[]
	)

	const onChangeVersionMax = useCallback(
		(versionMax?: number) => setVersionMax(versionMax),
		[]
	)

	const editMode = useMemo(() => (version ? false : editModeProp), [
		editModeProp,
		version
	])

	const initialState = useMemo<DetailTabContextState>(
		() => ({
			node,
			systemNodeId,
			editMode,
			editModeProp,
			selectedTab,
			version,
			versionMax,
			versionActive
		}),
		[
			editMode,
			node,
			selectedTab,
			systemNodeId,
			version,
			versionActive,
			versionMax,
			editModeProp
		]
	)

	if (!node) {
		return <></>
	}

	return (
		<DetailTabContextProvider initialState={initialState}>
			{node.type !== StructureDto.TypeEnum.SYSTEM ? (
				<HeaderWrapper>
					<Breadcrumb node={node} />
					<ViewHistory
						onChangeVersion={onChangeVersion}
						onChangeActive={onChangeActive}
						onChangeVersionMax={onChangeVersionMax}
					/>
				</HeaderWrapper>
			) : (
				<HeaderWrapper>
					<ViewHistory
						onChangeVersion={onChangeVersion}
						onChangeActive={onChangeActive}
						onChangeVersionMax={onChangeVersionMax}
					/>
				</HeaderWrapper>
			)}

			<DetailTabSwitcher />
		</DetailTabContextProvider>
	)
}

const HeaderWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	border-bottom: 1px solid #ddd;
`

export const DetailTab = React.memo(DetailTabComponent)
