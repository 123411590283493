import React from 'react'
import styled from 'styled-components'

import Userbar from './components/Userbar'
import NavbarMenu from './components/NavbarMenu'
import { useAppStore, useAppContext } from '@/utils/hooks'
import { Search } from './components/Search/Search'

const Navbar = () => {
	const { t } = useAppContext()
	const user = useAppStore(store => store.auth.user)

	return (
		<NavContainer>
			<ApplicationTitle>{t('APPLICATION_NAME')}</ApplicationTitle>
			{user && <UserCompany>{user.companyName}</UserCompany>}
			<StyledNavbarMenu />
			<Search />
			<StyledUserbar />
		</NavContainer>
	)
}

export default Navbar

const NavContainer = styled.nav`
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	background: ${props => props.theme.colors.layout.topBackgroundColor};
	color: white;
	padding: 10px 10px 10px 20px;
	height: 24px;
	margin-bottom: 6px;
`

const ApplicationTitle = styled.div`
	font-size: 24px;
	font-weight: 600;
`

const UserCompany = styled.div`
	font-size: 24px;
	font-weight: 200;
	margin-left: 8px;
	&:before {
		content: '-';
		margin-right: 8px;
	}
`

const StyledUserbar = styled(Userbar)`
	margin-left: 4px;
	margin-right: 4px;
`

const StyledNavbarMenu = styled(NavbarMenu)`
	margin-left: 24px;
`
