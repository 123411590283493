import styled, { css } from 'styled-components'
import Button from '@/components/Button/Button'
import { ITEM_HEIGHT } from './tableProperties/constants'

export const Buttons = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 10px;
`

export const ButtonSwitch = styled(Button)`
	margin: 0 5px;
`

export const Container = styled.div<{ isHidden: boolean }>`
	display: ${props => (!props.isHidden ? 'flex' : 'none')};
	flex-direction: column;
	background: #fff;
	height: 100%;
`

export const ToggleButton = styled.div`
	opacity: 0.7;
	transition: 0.2s opacity;
	:hover {
		opacity: 1;
	}
`

export const Title = styled.div`
	padding: 8px 12px;
	border-bottom: 1px solid #ddd;
	background: #f0f0f0;
	text-transform: uppercase;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	cursor: pointer;

	svg {
		margin-right: 10px;
		cursor: pointer;
	}
`

export const Values = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	overflow: auto;
	margin: 0;
	padding: 0 10px;
	align-content: flex-start;
`

export const ValueRow = styled.div<{
	valueWidth?: string
	propertiesWidth: number
}>`
	display: flex;
	flex-direction: column;
	min-width: 250px;
	margin-bottom: 10px;
	padding: 0 10px;
	width: 100%;
	box-sizing: border-box;
	min-height: ${ITEM_HEIGHT}px;
	${props =>
		props.propertiesWidth > 450 &&
		css`
			width: ${props.valueWidth ? props.valueWidth : '100%'};
		`}
	${props =>
		props.valueWidth &&
		css`
			min-width: auto;
		`}
`

export const ValueLabel = styled.div`
	padding: 5px 0px 2px 0px;
	color: #666;
`

export const ReadonlyValue = styled.div`
	padding: 5px;
	width: 100%;
`

export const ValueInput = styled.div`
	display: flex;
	width: 100%;
	height: ${ITEM_HEIGHT}px;
	flex: 1;

	textarea,
	input[type='text'] {
		border: 1px solid #ddd;
		padding: 5px;
	}
`

export const OpenButton = styled.div`
	position: absolute;
	left: -25px;
	top: -1px;

	> div {
		transform: rotate(-90deg) translate(-100%, 0);
		transform-origin: top left;
		padding: 7px;
		display: flex;
		background: #eee;
		border: 1px solid #ddd;
		cursor: pointer;
		transition: 0.2s color;
		color: #777;
		:hover {
			color: #333;
		}
	}
`

export const OpenButtonIcon = styled.span`
	flex: 0;
	margin: 2px;
	margin: 1px 4px;
`

export const OpenButtonText = styled.span`
	text-transform: uppercase;
	flex: 1;
	margin: 0 4px;
`
