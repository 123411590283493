import { importColumnsFromCSVCheck } from '@/api'
import { useApiRequest } from '@/api/hooks'
import { ImportColumn } from '@/api/models'
import { ImportStructureProps } from '../../hooks/useImport'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import { ActionTypeEnum } from '../context/types'
import { booleanFromString } from '../utils/booleanFromString'

export const useOnCheckSubmit = () => {
	const request = useApiRequest()

	const {
		state: { hasHeaders, inputDataParsed, mapping, onMapped, nodeId },
		dispatch
	} = useCsvImporterContext()

	const onSubmit = async (values: ImportStructureProps) => {
		const formDataCheck = {
			...values,
			importAllColumns: !values.importAllColumns,
			generateCodes: false
		}

		const rows = inputDataParsed?.data.slice(hasHeaders ? 1 : 0).map(row => {
			const mapped = mapping as Record<string, number>

			return {
				name: row[mapped['name']],
				code: row[mapped['code']],
				domainName: row[mapped['domainName']],
				dataType: row[mapped['dataType']],
				defaultValue: row[mapped['defaultValue']],
				mandatoryFlag: booleanFromString(row[mapped['mandatory']]),
				description: row[mapped['description']],
				comment: row[mapped['comment']],
				primaryKeyName: row[mapped['primaryKeyName']],
				indexName: row[mapped['indexName']],
				stereotypeCode: row[mapped['stereotypeCode']]
			} as ImportColumn
		})

		if (rows) {
			const checkResult = await request(
				importColumnsFromCSVCheck(nodeId, rows, formDataCheck)
			)

			dispatch({
				type: ActionTypeEnum.ROWS_SET,
				rows
			})

			dispatch({
				type: ActionTypeEnum.CHECK_RESULT_SET,
				checkResult: checkResult.data || undefined
			})

			if (checkResult) {
				if (checkResult.data?.errors?.length !== 0) {
					onMapped()

					return
				}

				onMapped(rows, formDataCheck, true)
			}
		}
	}

	return onSubmit
}
