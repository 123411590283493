import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '@/constants'
import { useAppContext, useAuthContext } from '@/utils/hooks'

const NavbarMenu = ({ className = '' }: { className?: string }) => {
	const location = useLocation()
	const { t } = useAppContext()
	const auth = useAuthContext()

	return (
		<div role="menubar" className={className}>
			<StyledLink
				current={location ? location.pathname === ROUTES.PROJECT_VIEW : false}
				to={ROUTES.PROJECT_VIEW}
				role="menuitem"
			>
				{t('NAVBAR_MENU_PROJECT_VIEW')}
			</StyledLink>
			{auth.canSeeSettings() && (
				<StyledLink
					current={location ? location.pathname === ROUTES.SETTINGS : false}
					to={ROUTES.SETTINGS}
					role="menuitem"
				>
					{t('NAVBAR_MENU_SETTINGS')}
				</StyledLink>
			)}
		</div>
	)
}

export default NavbarMenu

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledLink = styled(({ current, ...props }) => <Link {...props} />)<{
	current: boolean
}>`
	display: inline-block;
	padding: 14px 14px 7px 14px;
	margin-bottom: 7px;
	margin-right: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 15px;
	color: white;
	text-decoration: none;
	transition: 0.25s all;
	border-bottom: ${props =>
		props.current ? '1px solid #fff' : '1px solid transparent'};
	&:hover {
		border-bottom: 1px solid #fff;
	}
`
