import { StructureDto } from '@/api/models'
import {
	TAB_OPEN,
	TAB_SELECT,
	TAB_SET_PERMANENT,
	TAB_CLOSE,
	TAB_EDIT,
	TAB_CANCEL_EDIT,
	TAB_SET_PROPERTIES_SHOWN,
	TAB_SET_PROPERTIES_WIDTH,
	TAB_SET_PROPERTY_ITEM,
	TAB_CLOSE_RELATED,
	TAB_IMPORT_STATE,
	TAB_SELECT_TAB
} from './constants'
import { StoreState } from '@/store'
import { NativeMap } from '@/utils/collections'
import { TabSession } from '.'
import { AppDispatch } from '@/store/utils'
import { isEditable } from '@/utils/locking'
import { loadNode } from '../node/general-actions'

interface OpenTab {
	type: typeof TAB_OPEN
	node: StructureDto
	replace: boolean
	editMode: boolean
	nodes: NativeMap<StructureDto>
}

interface SelectTab {
	type: typeof TAB_SELECT
	node: StructureDto
}

interface SetTabPermanent {
	type: typeof TAB_SET_PERMANENT
	node: StructureDto
}

interface CloseTab {
	type: typeof TAB_CLOSE
	nodeId: number
}

interface EditTab {
	type: typeof TAB_EDIT
	node: StructureDto
}

interface CancelEditTab {
	type: typeof TAB_CANCEL_EDIT
	node: StructureDto
}

interface TabSetPropertiesShown {
	type: typeof TAB_SET_PROPERTIES_SHOWN
	node: StructureDto
	tabKey: string
	shown: boolean
}

interface TabSetPropertiesWidth {
	type: typeof TAB_SET_PROPERTIES_WIDTH
	node: StructureDto
	tabKey: string
	width: number
}

interface TabSetPropertyItem {
	type: typeof TAB_SET_PROPERTY_ITEM
	node: StructureDto
	tab: string
	item: number
}

interface TabCloseRelated {
	type: typeof TAB_CLOSE_RELATED
	node: StructureDto
	children: NativeMap<number[]>
}

interface TabImportState {
	type: typeof TAB_IMPORT_STATE
	session: TabSession
}

interface TabSelectTab {
	type: typeof TAB_SELECT_TAB
	tab: string
}

export type GeneralNodeActions = CloseTab

export type Actions =
	| GeneralNodeActions
	| OpenTab
	| SelectTab
	| SetTabPermanent
	| EditTab
	| CancelEditTab
	| TabSetPropertiesShown
	| TabSetPropertyItem
	| TabCloseRelated
	| TabImportState
	| TabSelectTab
	| TabSetPropertiesWidth

export const openTab = (
	node: StructureDto,
	replace: boolean,
	editMode = false
) => (dispatch: AppDispatch, getValues: () => StoreState): Actions =>
	dispatch({
		type: TAB_OPEN,
		node,
		replace,
		editMode,
		nodes: getValues().node.nodes
	})

export const selectTab = (node: StructureDto): Actions => ({
	type: TAB_SELECT,
	node
})

export const setTabPermanent = (node: StructureDto): Actions => ({
	type: TAB_SET_PERMANENT,
	node
})

export const closeTab = (nodeId: number): Actions => ({
	type: TAB_CLOSE,
	nodeId
})

export const editTab = (node: StructureDto): Actions => ({
	type: TAB_EDIT,
	node
})

export const cancelEditingTab = (node: StructureDto): Actions => ({
	type: TAB_CANCEL_EDIT,
	node
})

export const setTabPropertiesShown = (
	node: StructureDto,
	tabKey: string,
	shown: boolean
): Actions => ({
	type: TAB_SET_PROPERTIES_SHOWN,
	node,
	tabKey,
	shown
})

export const setTabPropertiesWidth = (
	node: StructureDto,
	tabKey: string,
	width: number
): Actions => ({
	type: TAB_SET_PROPERTIES_WIDTH,
	node,
	tabKey,
	width
})

export const setTabPropertyItem = (
	node: StructureDto,
	tab: string,
	item: number
): Actions => ({
	type: TAB_SET_PROPERTY_ITEM,
	node,
	tab,
	item
})

export const closeTabAndRelated = (node: StructureDto) => (
	dispatch: AppDispatch,
	getValues: () => StoreState
): Actions =>
	dispatch({
		type: TAB_CLOSE_RELATED,
		node,
		children: getValues().node.children
	})

export const loadTabState = (session: TabSession) => async (
	dispatch: AppDispatch,
	getValues: () => StoreState
) => {
	const updatedSession = { ...session }
	updatedSession.openedTabs = updatedSession.openedTabs.filter(tab => !!tab)

	await Promise.all(
		updatedSession.openedTabs.map(tab =>
			dispatch(loadNode(tab.nodeId))
				.then(data => {
					// Exit edit mode if we don't have edit rights or we lost the lock
					if (tab.editMode && !isEditable(data, getValues().auth.user)) {
						tab.editMode = false
					}
				})
				.catch(() => {
					console.warn(
						'Node',
						tab.nodeId,
						'specified in user session not found'
					)

					updatedSession.openedTabs = updatedSession.openedTabs.filter(
						t => t.nodeId !== tab.nodeId
					)
				})
		)
	)

	// Reset selectedTab and temproraryTab indexes when openedTabs size was changed
	if (updatedSession.openedTabs.length !== session.openedTabs.length) {
		updatedSession.selectedTab = undefined
		updatedSession.temporaryTab = undefined
	}

	// Reset selected tab index if out of bounds
	if (
		updatedSession.selectedTab !== undefined &&
		updatedSession.selectedTab >= updatedSession.openedTabs.length
	) {
		updatedSession.selectedTab = undefined
	}

	// Reset temporary tab index if out of bounds
	if (
		updatedSession.temporaryTab !== undefined &&
		updatedSession.temporaryTab >= updatedSession.openedTabs.length
	) {
		updatedSession.temporaryTab = undefined
	}

	dispatch({
		type: TAB_IMPORT_STATE,
		session: updatedSession
	})
}

export const selectTabInTab = (nodeId: number, tab: string): Actions => ({
	type: TAB_SELECT_TAB,
	tab
})
