import { AcronymDto } from '@/api/models'
import { Actions } from './actions'
import { LOAD_ACRONYMS } from './constants'
type State = Readonly<typeof initialState>

const initialState = {
	acronyms: [] as AcronymDto[],
	totalPages: 0
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case LOAD_ACRONYMS: {
			return {
				...state,
				...action.payload
			}
		}

		default: {
			return state
		}
	}
}
