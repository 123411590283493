/* eslint-disable @typescript-eslint/no-explicit-any */
import { Actions } from './actions'
import { GeneralNodeActions } from '../tab/actions'
import { TableData, HistoryTableData } from '@/api/schemas'
import { OpenedTableData, TableTab, TableMode } from './types'
import {
	getInitialTableData,
	defaultTableData,
	flatColumns,
	flatTablePermission
} from './helpers'
import { TAB_CLOSE } from '../tab/constants'
import {
	TABLE_INIT,
	TABLE_SAVE,
	TABLE_UPDATE,
	TABLE_SELECT_TAB,
	TABLE_SYNC_FIELD,
	TABLE_UPDATE_STEREOTYPES_COLUMNS,
	TABLE_UPDATE_HISTORY_COLUMNS
} from './constants'
import { NativeMap, uniqueMerge } from '@/utils/collections'
import { updateTabData } from '@/store/utils'
import { ensureValidData } from '../helpers'

export type State = Readonly<typeof initialState>

const initialState = {
	tables: {} as NativeMap<OpenedTableData>
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const tables = { ...state.tables }
			delete tables[action.nodeId]

			return {
				...state,
				tables
			}
		}

		case TABLE_INIT: {
			const { node, editMode, force } = action
			let { mode } = action
			const previous = state.tables[node.id]

			// Don't do anything if the table is already initialized in read mode with same params
			if (previous && !force && editMode === previous.parsedEditMode) {
				// Only allow history mode when history table is enabled
				if (!previous.form.hasHistoryTable && mode === TableMode.HISTORY) {
					mode = TableMode.TABLE
				}

				if (!previous.form.hasReferenceTable && mode === TableMode.REFERENCE) {
					mode = TableMode.TABLE
				}

				if (mode !== previous.mode) {
					// We don't need to reload data when switching between history mode and normal mode, only change the historyMode flag
					return {
						tables: {
							...state.tables,
							[node.id]: {
								...previous,
								mode
							}
						}
					}
				}

				if (editMode && previous.parsedEditMode) {
					return state
				}
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: TableData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultTableData())
				: getInitialTableData(node.name)

			// Only allow history mode when history table is enabled
			if (!data.hasHistoryTable && mode === TableMode.HISTORY) {
				mode = TableMode.TABLE
			}

			if (!data.hasReferenceTable && mode === TableMode.REFERENCE) {
				mode = TableMode.TABLE
			}

			const objectPermissions =
				data.objectPermissions?.filter(x => x.user).map(flatTablePermission) ??
				[]

			const columns = data.columns
				.filter(c => !c.stereotypeColumn && !c.historyColumn)
				.map(flatColumns)

			const stereotypeColumns = data.columns
				.filter(c => c.stereotypeColumn)
				.map(flatColumns)

			const historyColumns = data.historyTable?.columns?.map(flatColumns) ?? []

			if (data.historyTable?.columns) {
				data.historyTable.columns = historyColumns
			}

			if (typeof data.columnsLastId !== 'number') {
				data.columnsLastId = Math.max(0, ...data.columns.map(c => c.id))
			}

			if (typeof data.constraintsLastId !== 'number') {
				data.constraintsLastId = Math.max(0, ...data.constraints.map(c => c.id))
			}

			if (typeof data.indexesLastId !== 'number') {
				data.indexesLastId = Math.max(0, ...data.indexes.map(c => c.id))
			}

			if (typeof data.objectPermissionLastId !== 'number') {
				data.objectPermissionLastId = Math.max(
					0,
					...data.objectPermissions.map(c => c.id as number)
				)
			}

			const table: OpenedTableData = {
				form: {
					...data,
					columns,
					objectPermissions
				},
				original: data,
				tab: previous ? previous.tab : TableTab.General,
				selected: previous
					? previous.selected
					: {
							key: null,
							index: null,
							column: null
					  },
				dirty: false,
				parsedEditMode: editMode,
				referenceTableMode: false,
				mode,
				stereotypeColumns,
				historyColumns
			}

			return {
				...state,
				tables: {
					...state.tables,
					[node.id]: table
				}
			}
		}

		case TABLE_SAVE: {
			const id = action.metadata.node.id
			const node = state.tables[id]

			const tables = {
				...state.tables,
				[id]: {
					...node,
					dirty: false
				}
			}

			return {
				...state,
				tables
			}
		}

		case TABLE_UPDATE: {
			const { node, update, mode } = action

			return {
				...state,
				tables: updateTabData(state.tables, node.id, node => ({
					...node,
					form: {
						...node.form,
						...(mode === TableMode.HISTORY
							? ({
									historyTable: {
										...node.form.historyTable,
										...update,
										nonSyncFields: uniqueMerge(
											(node.form.historyTable || {}).nonSyncFields || [],
											Object.keys(update)
										)
									}
							  } as any)
							: mode === TableMode.REFERENCE
							? ({
									referenceTable: {
										...node.form.referenceTable,
										...update,
										nonSyncFields: uniqueMerge(
											(node.form.referenceTable || {}).nonSyncFields || [],
											Object.keys(update)
										)
									}
							  } as any)
							: update)
					},
					dirty: true
				}))
			}
		}

		case TABLE_SELECT_TAB: {
			const { node, tab } = action

			return {
				...state,
				tables: updateTabData(state.tables, node.id, node => ({
					...node,
					tab
				}))
			}
		}

		case TABLE_SYNC_FIELD: {
			const { node, field, mode } = action

			return {
				...state,
				tables: updateTabData(
					state.tables,
					node.id,
					node => {
						const original = (mode === TableMode.HISTORY
							? {
									...node.form.historyTable,
									nonSyncFields: (
										(node.form.historyTable &&
											node.form.historyTable.nonSyncFields) ||
										[]
									).filter(f => f !== field)
							  }
							: mode === TableMode.REFERENCE
							? {
									...node.form.referenceTable,
									nonSyncFields: (
										(node.form.referenceTable &&
											node.form.referenceTable.nonSyncFields) ||
										[]
									).filter(f => f !== field)
							  }
							: {}) as HistoryTableData

						delete original[field]

						return {
							...node,
							form: {
								...node.form,
								[mode === TableMode.HISTORY
									? 'historyTable'
									: 'referenceTable']: original
							}
						}
					},
					true
				)
			}
		}

		case TABLE_UPDATE_STEREOTYPES_COLUMNS: {
			const { node, stereotypeColumns } = action

			return {
				...state,
				tables: updateTabData(state.tables, node.id, node => ({
					...node,
					stereotypeColumns
				}))
			}
		}

		case TABLE_UPDATE_HISTORY_COLUMNS: {
			const { node, historyColumns, historyFormColumns } = action

			return {
				...state,
				tables: updateTabData(state.tables, node.id, node => ({
					...node,
					historyColumns,
					form: {
						...node.form,
						historyTable: {
							...node.form.historyTable,
							columns: historyFormColumns
						}
					}
				}))
			}
		}

		default:
			return state
	}
}
