import numeral from 'numeral'
import { Locale } from '@/context/Locale'
import { AnyObject, FormValue } from './Form'
import { checkPasswordStrength, PasswordStrength } from '@/utils/passwords'

export interface NumberRangeValue {
	from: string
	to: string
}
export type Validator = (
	value: FormValue,
	values: AnyObject,
	locale: Locale
) => Promise<string | null | undefined> | string | null | undefined

const isInteger = (value: string | number) =>
	!isNaN(value as number) && parseFloat(value as string) % 1 === 0

const isFloat = (value: string | number) => !isNaN(value as number)

export default {
	/**
	 * Checks if text input is within specified range.
	 * @param minLength minimum text input length
	 * @param maxLength maximum text input length
	 */
	lengthRange: (minLength: number, maxLength: number): Validator => (
		value: FormValue,
		_,
		locale
	) => {
		if (
			typeof value === 'string' &&
			(value.length > maxLength || value.length < minLength)
		) {
			return locale.translate('VALIDATOR_LENGTH_RANGE_ERROR', [
				minLength,
				maxLength
			])
		}
	},

	/**
	 * Checks if text input has maximal length.
	 * @param maxLength maximal text input length
	 */
	maxLength: (maxLength: number): Validator => (
		value: FormValue,
		_,
		locale
	) => {
		if (typeof value === 'string' && value.length > maxLength) {
			return locale.translate('VALIDATOR_LENGTH_MAX_ERROR', [maxLength])
		}
	},

	/**
	 * Checks if text input has minimal length.
	 * @param minLength minimum text input length
	 */
	minLength: (minLength: number): Validator => (
		value: FormValue,
		_,
		locale
	) => {
		if (typeof value === 'string' && value.length < minLength) {
			return locale.translate('VALIDATOR_LENGTH_MIN_ERROR', [minLength])
		}
	},

	/**
	 * Checks if value is numeric and within range.
	 * @param integer only accept integer
	 * @param minValue minimum
	 * @param maxValue maximum
	 */
	numeric: (
		integer = false,
		minValue?: number,
		maxValue?: number
	): Validator => (value: FormValue, _, locale) => {
		if (typeof value === 'number') {
			if (integer && !isInteger(value)) {
				return locale.translate('VALIDATOR_INTEGER_ERROR')
			} else if (!isFloat(value)) {
				return locale.translate('VALIDATOR_FLOAT_ERROR')
			}

			if (minValue !== undefined && maxValue !== undefined) {
				if (value < minValue || value > maxValue) {
					return locale.translate('VALIDATOR_NUMBER_MINMAX_ERROR', [
						minValue,
						maxValue
					])
				}
			}

			if (minValue !== undefined) {
				if (value < minValue) {
					return locale.translate('VALIDATOR_NUMBER_MIN_ERROR', [minValue])
				}
			}

			if (maxValue !== undefined) {
				if (value > maxValue) {
					return locale.translate('VALIDATOR_NUMBER_MAX_ERROR', [maxValue])
				}
			}
		}
	},

	email: (): Validator => (value: FormValue, _, locale) => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (typeof value === 'string' && !re.test(value)) {
			return locale.translate('VALIDATOR_EMAIL')
		}
	},

	passwordStrength: (): Validator => (value: FormValue, _, locale) => {
		if (!value) {
			return
		}

		const result = checkPasswordStrength(value as string)

		if (!result) {
			return
		}

		if (result.complexity <= PasswordStrength.WEAK) {
			return locale.translate('VALIDATOR_PASSWORD_STRENGTH')
		}
	},

	comparePasswords: (): Validator => (value: FormValue, _, locale) => {
		if (_.password && _.confirmPassword && _.password !== _.confirmPassword) {
			return locale.translate('VALIDATOR_COMPARE_PASSWORDS')
		}
	},

	customRegex: (regex: string): Validator => (value: FormValue, _, locale) => {
		try {
			const flags = regex.replace(/.*\/([gimy]*)$/, '$1')
			const pattern = regex.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1')
			const re = new RegExp(pattern, flags)

			if (typeof value === 'string' && !re.test(value)) {
				return `${locale.translate('VALIDATOR_REGEX')}: ${regex}`
			}
		} catch (e) {
			return `${locale.translate('WRONG_REGEX_SYNTAX_IN_SETTINGS')}: ${regex}`
		}
	},
	isRightRegexSyntax: (optional: boolean): Validator => (
		value: FormValue,
		_,
		locale
	) => {
		try {
			if (optional && !value) {
				return
			}

			const flags = value.replace(/.*\/([gimy]*)$/, '$1')
			const pattern = value.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1')
			new RegExp(pattern, flags)
		} catch (e) {
			return `${locale.translate('WRONG_REGEX_SYNTAX')}`
		}
	},
	includesPeriod: (): Validator => (value: FormValue, _, locale) => {
		if (!value) {
			return
		}

		if (value.includes('.')) {
			return `${locale.translate('NAME_CANNOT_CONTAIN_DOT')}`
		}

		return
	}
}
