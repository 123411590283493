import React, { useEffect, useState } from 'react'
import { DialogWrapper } from '@/components'
import { DeleteModal } from '../../../components/DeleteModal'
import { useAppDispatch, useAuthContext, useAppStore } from '@/utils/hooks'
import { openTab, cancelEditingTab } from '@/store/modules/tab/actions'
import { lockNode } from '@/store/modules/node/actions'
import { StructureDto, NewStructureDto } from '@/api/models'
import {
	faTrash,
	faArrowRight,
	faLock,
	faUnlock,
	faFolderPlus,
	faProjectDiagram,
	faTable,
	faWindowMaximize,
	faWifi,
	faLayerGroup,
	faRetweet,
	faDownload,
	faFileExport,
	faCopy,
	faTasks,
	faFileArchive,
	faEdit
} from '@fortawesome/free-solid-svg-icons'
import { isLocked, isLockedByOther } from '@/utils/locking'
import {
	isEditableNodeType,
	isDublicableNodeType,
	isImportableNodeType
} from '@/utils/nodes'
import { UnlockModal } from '../../../components/UnlockModal'
import { useAppContext } from '@/utils/hooks'
import { AddNodeItem } from './AddNodeItem'
import {
	ContextMenuItems,
	ContextMenuItem
} from '@/components/ContextMenu/ContextMenu'
import { ExportStructure } from './ExportStructure'
import { ImportStructureModal } from './ImportStructureModal'
import { MassSqlExportModal } from './MassSqlExportModal'
import { DeleteNodesModal } from './DeleteNodesModal'
import { loadNode } from '@/store/modules/node/general-actions'
import { BatchEdit } from './BatchEdit/BatchEdit'
import { DuplicateNodeModal } from '@/pages/User/pages/Home/components/TreePanel/components/DuplicateNodeModal'

type Props = {
	node: StructureDto
}

export const ItemMenu = ({ node }: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const auth = useAuthContext()
	const user = useAppStore(state => state.auth.user)
	const { nodes, selectedNodes, selectMode } = useAppStore(state => state.node)

	const open = () => dispatch(openTab(node, false))
	const lock = () => dispatch(lockNode(node))

	const [download, setDownload] = useState(false)

	const canDuplicate = node.canEdit && isDublicableNodeType(node.type)

	const canDelete =
		node.type === StructureDto.TypeEnum.SYSTEM
			? auth.canDeleteSystem() && !isLockedByOther(node, user)
			: node.canEdit &&
			  !isLockedByOther(node, user) &&
			  node.type !== StructureDto.TypeEnum.ROOT &&
			  node.type !== StructureDto.TypeEnum.FOLDER_WORKFLOW

	const canImport = node.canEdit && isImportableNodeType(node.type)

	useEffect(() => {
		const loadNodes = async () => {
			for (const nodeId of selectedNodes) {
				if (!nodes[nodeId]) {
					try {
						await dispatch(loadNode(nodeId))
					} catch (e) {
						console.error(e)
					}
				}
			}
		}

		loadNodes()
	}, [dispatch, nodes, selectedNodes])

	if (!selectMode) {
		return (
			<ContextMenuItems>
				<ContextMenuItem
					onClick={open}
					icon={faArrowRight}
					role="menuitem"
					title={t('CONTEXT_OPEN_TAB')}
				/>

				{node.canEdit &&
					(node.type === StructureDto.TypeEnum.FOLDER ||
						node.type === StructureDto.TypeEnum.SYSTEM) && (
						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.FOLDER}
							label={t('CONTEXT_ADD_FOLDER')}
							icon={faFolderPlus}
						/>
					)}

				{node.canEdit && node.type === StructureDto.TypeEnum.FOLDER && (
					<>
						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.TABLE}
							label={t('CONTEXT_ADD_TABLE')}
							icon={faTable}
						/>

						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.VIEW}
							label={t('CONTEXT_ADD_VIEW')}
							icon={faWindowMaximize}
						/>

						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.API_NODE}
							label={t('CONTEXT_ADD_API_NODE')}
							icon={faWifi}
						/>

						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.QUEUE}
							label={t('CONTEXT_ADD_QUEUE')}
							icon={faTasks}
						/>
					</>
				)}

				{node.canEdit && node.type === StructureDto.TypeEnum.API_NODE && (
					<>
						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.API_COLLECTION}
							label={t('CONTEXT_ADD_API_ROWSET')}
							icon={faLayerGroup}
						/>

						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.MAPPING_READ}
							label={t('CONTEXT_ADD_MAPPING_READ')}
							icon={faRetweet}
						/>

						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.MAPPING_WRITE}
							label={t('CONTEXT_ADD_MAPPING_WRITE')}
							icon={faRetweet}
						/>
					</>
				)}

				{node.canEdit && node.type === StructureDto.TypeEnum.TABLE && (
					<AddNodeItem
						node={node}
						type={NewStructureDto.TypeEnum.MAPPING}
						label={t('CONTEXT_ADD_MAPPING')}
						icon={faRetweet}
					/>
				)}

				{node.canEdit &&
					node.type === StructureDto.TypeEnum.FOLDER_WORKFLOW && (
						<AddNodeItem
							node={node}
							type={NewStructureDto.TypeEnum.WORKFLOW}
							label={t('CONTEXT_ADD_WORKFLOW')}
							icon={faProjectDiagram}
						/>
					)}

				{node.canEdit && isEditableNodeType(node.type) && (
					<>
						{!isLocked(node) && (
							<ContextMenuItem
								onClick={lock}
								role="menuitem"
								icon={faLock}
								title={t('CONTEXT_LOCK')}
							/>
						)}

						{isLocked(node) && (
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<UnlockModal
											node={node}
											onConfirm={() => dispatch(cancelEditingTab(node))}
											onClose={onClose}
										/>
									)
								}
							>
								{onClick => (
									<ContextMenuItem
										onClick={onClick}
										role="menuitem"
										icon={faUnlock}
										title={t('CONTEXT_UNLOCK')}
									/>
								)}
							</DialogWrapper>
						)}
					</>
				)}

				{canDuplicate && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <DuplicateNodeModal node={node} onClose={onClose} />
						}
					>
						{onClick => (
							<ContextMenuItem
								onClick={onClick}
								role="menuitem"
								icon={faCopy}
								title={t('DUPLICATE')}
							/>
						)}
					</DialogWrapper>
				)}

				{canDelete && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <DeleteModal node={node} onClose={onClose} />
						}
					>
						{onClick => (
							<ContextMenuItem
								onClick={onClick}
								role="menuitem"
								icon={faTrash}
								title={t('CONTEXT_DELETE')}
							/>
						)}
					</DialogWrapper>
				)}

				<ContextMenuItem
					onClick={() => setDownload(true)}
					icon={faDownload}
					role="menuitem"
					title={t('EXPORT')}
				/>

				{download && (
					<ExportStructure
						nodeId={node.id}
						onDownloadFinish={() => setDownload(false)}
					/>
				)}

				{canImport && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <ImportStructureModal node={node} onClose={onClose} />
						}
					>
						{onClick => (
							<ContextMenuItem
								onClick={onClick}
								icon={faFileExport}
								role="menuitem"
								title={t('IMPORT')}
							/>
						)}
					</DialogWrapper>
				)}

				{canImport && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<MassSqlExportModal
									node={node}
									onClose={onClose}
									selectedNodes={selectedNodes}
								/>
							)
						}
					>
						{onClick => (
							<ContextMenuItem
								onClick={onClick}
								icon={faFileArchive}
								role="menuitem"
								title={t('BATCH_EXPORT')}
							/>
						)}
					</DialogWrapper>
				)}
			</ContextMenuItems>
		)
	} else {
		return (
			<ContextMenuItems>
				{canImport && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<MassSqlExportModal
									node={node}
									onClose={onClose}
									selectedNodes={selectedNodes}
								/>
							)
						}
					>
						{onClick => (
							<ContextMenuItem
								onClick={onClick}
								icon={faFileArchive}
								role="menuitem"
								title={t('BATCH_EXPORT')}
							/>
						)}
					</DialogWrapper>
				)}
				{selectedNodes.length > 0 && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <DeleteNodesModal onClose={onClose} />
						}
					>
						{onClick => (
							<ContextMenuItem
								onClick={onClick}
								icon={faTrash}
								role="menuitem"
								title={t('BATCH_DELETE')}
							/>
						)}
					</DialogWrapper>
				)}
				{selectedNodes.length > 1 && (
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <BatchEdit onClose={onClose} node={node} />
						}
					>
						{onClick => (
							<ContextMenuItem
								onClick={onClick}
								icon={faEdit}
								role="menuitem"
								title={t('BATCH_EDIT')}
							/>
						)}
					</DialogWrapper>
				)}
			</ContextMenuItems>
		)
	}
}
