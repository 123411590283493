import FileSaver from 'file-saver'
import { useCallback } from 'react'

export const useSaveFile = () => {
	const saveFile = useCallback(
		(fileName: string, blobPart: BlobPart[], type?: string) => {
			const blob = new Blob(blobPart, {
				type: type ?? 'text/plain;charset=utf-8'
			})

			FileSaver.saveAs(blob, fileName)
		},
		[]
	)

	return saveFile
}
