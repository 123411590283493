import { IdCodeName } from './general'
import { SystemUser } from '@/api/models'
import { SourceItem } from '.'
import { CustomAttributeForm } from '@/pages/User/pages/Home/components/CustomAttributes/hooks/useCustomAttributes'

export interface ViewData {
	name?: string
	code?: string
	comment?: string
	description?: string
	status?: ViewData.ViewStatusEnum
	responsibleAnalystId?: number
	responsiblePersonId?: number
	columnsLastId?: number
	columns: ViewColumn[]
	sourceScript?: string
	sourceDescription?: string
	criteriaScript?: string
	criteriaDescription?: string
	ownerId?: number
	ownerName?: string
	objectSettings?: IdCodeName[]
	objectPermissions?: ViewPermission[]
	objectPermissionLastId?: number
	sourceItems?: SourceItem[]
	sourceItemsLastId?: number
	customAttributes?: CustomAttributeForm[]
}

export interface ViewColumn {
	id: number
	order: number
	name?: string
	code: string
	comment?: string
	description?: string
	mapping?: string
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ViewData {
	export enum ViewStatusEnum {
		LOGICAL_IN_PROGRESS = 'logical_in_progress',
		LOGICAL_COMPLETED = 'logical_completed',
		PHYSICAL_IN_PROGRESS = 'physical_in_progress',
		PHYSICAL_COMPLETED = 'physical_completed',
		FROZEN = 'frozen'
	}
}

export interface ViewPermission {
	id?: number // int64
	user?: SystemUser
	permissions?: ViewPermission.PermissionsEnum[]
	order?: number // int64
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ViewPermission {
	export enum PermissionsEnum {
		SELECT = 'SELECT'
	}
}
