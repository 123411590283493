import { StructureDto, StructureDetailDto } from '@/api/models'
import { loadNodeChildren } from '../node/actions'
import { SystemTab, SystemDataForm } from './types'
import {
	SYSTEM_INIT,
	SYSTEM_SAVE,
	SYSTEM_UPDATE,
	SYSTEM_SELECT_TAB,
	SYSTEM_LOAD_MAPPINGS
} from './constants'
import { apiCallAction, UpdateDeepPartial } from '@/store/utils'
import { updateDataNode, getMappingsOfSystemNodes } from '@/api'
import { AppDispatch, thunkAction } from '@/store/utils'
import { SystemData } from '@/api/schemas'
import { omit } from 'lodash'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { loadNodeOrHistoryVersion } from '../node/utils'

interface InitSystem {
	type: typeof SYSTEM_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

interface SaveSystem {
	type: typeof SYSTEM_SAVE
	payload: void
	metadata: {
		node: StructureDto
	}
}

interface UpdateSystem {
	type: typeof SYSTEM_UPDATE
	node: StructureDto
	update: UpdateDeepPartial<SystemDataForm>
}

interface SelectSystemTab {
	type: typeof SYSTEM_SELECT_TAB
	node: StructureDto
	tab: SystemTab
}

interface SystemLoadMappings {
	type: typeof SYSTEM_LOAD_MAPPINGS
	payload: StructureDto[]
	metadata: {
		systemId: number
	}
}

export const initSystem = ({
	nodeId,
	editMode = false,
	force = false,
	version,
	envId
}: InitDataParams) => async (dispatch: AppDispatch) => {
	const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

	dispatch({
		type: SYSTEM_INIT,
		node,
		editMode,
		force
	} as InitSystem)
}

export const updateSystem = (
	node: StructureDto,
	update: UpdateDeepPartial<SystemDataForm>
): Actions => ({
	type: SYSTEM_UPDATE,
	node,
	update
})

export const saveSystem = (node: StructureDto) =>
	thunkAction(async (dispatch, getState) => {
		const system = getState().system.systems[node.id]

		if (!system) {
			throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
		}

		const {
			name,
			code,
			constants,
			constantsLastId,
			lookups,
			lookupsLastId,
			environments,
			environmentsLastId,
			systemUsers,
			generateCode,
			generateTableColumnCode
		} = system.form

		const formData = omit(system.form, [
			'generateCode',
			'generateTableColumnCode'
		])

		const systemData: SystemData = {
			...formData,
			name: name as string,
			code: code as string,
			constants: (constants || []).filter(c => !!c.name),
			constantsLastId: constantsLastId as number,
			lookups: (lookups || []).filter(c => !!c.name),
			lookupsLastId: lookupsLastId as number,
			environments: (environments || []).filter(c => !!c.name),
			environmentsLastId: environmentsLastId as number,
			systemUsers: (systemUsers || []).filter(c => !!c.name),
			namingConfig: {
				generateCode,
				generateTableColumnCode
			}
		}

		await dispatch(
			apiCallAction<SaveSystem>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(systemData)
					}),
				SYSTEM_SAVE,
				{ node }
			)
		)

		await dispatch(loadNodeChildren(node.parentStructureId as number))
	})

export const selectSystemTab = (
	node: StructureDto,
	tab: SystemTab
): Actions => ({
	type: SYSTEM_SELECT_TAB,
	node,
	tab
})

export const loadSystemMappings = (systemId: number) =>
	apiCallAction<SystemLoadMappings>(
		() => getMappingsOfSystemNodes(systemId),
		SYSTEM_LOAD_MAPPINGS,
		{ systemId }
	)

export type Actions =
	| InitSystem
	| SaveSystem
	| UpdateSystem
	| SelectSystemTab
	| SystemLoadMappings
