import React from 'react'
import { FormValue, AnyObject } from './Form'
import { FormInput } from './FormField'

export interface FormFieldContext<T = AnyObject> {
	disabled?: boolean
	required?: boolean
	loading?: boolean
	name?: string
	value?: FormValue
	id?: string
	isHorizontal?: boolean
	withQueryString?: boolean
	readonly?: boolean
	compact?: boolean
	onChange?: (value: FormValue) => void
	register?: (input: FormInput<T>) => void
	onFocus?: (e: React.FocusEvent) => void
	onBlur?: (e: React.FocusEvent) => void
	onKeyPress?: (e: React.KeyboardEvent) => void
	isFieldHighlighted?: boolean
}

export const FormFieldContext = React.createContext<FormFieldContext>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	null as any
)

export const withFormField = <T extends object>(Component: T): T => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const CM = Component as any

	return class WithFormField extends React.Component {
		render() {
			return (
				<FormFieldContext.Consumer>
					{(context: FormFieldContext) => <CM {...context} {...this.props} />}
				</FormFieldContext.Consumer>
			)
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any
}
