import React from 'react'
import styled from 'styled-components'
import { Form, TextAreaFormField } from '@/components/UberForm'
import { Container } from '../../../TableDetail/components/StyledComponents'
import { OpenedViewData } from '@/store/modules/view/types'
import { useAppContext } from '@/utils/hooks'
import { ViewData } from '@/api/schemas'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Source } from '../../../../components/Source/Source'

interface Props {
	data: OpenedViewData
	editMode: boolean
	onChange: (v: Partial<ViewData>) => void
}

export const Sources = ({ onChange, data, editMode }: Props) => {
	const { t } = useAppContext()

	return (
		<StyledContainer>
			<Form<ViewData>
				onChange={onChange}
				defaultValues={data.form}
				disabled={!editMode}
			>
				<Source
					data={data}
					onChange={onChange}
					sourceFieldName="sourceScript"
				/>

				<TextAreaFormField
					name="sourceDescription"
					title={t('VIEW_FIELD_DESCRIPTION', [t('VIEW_SOURCE')])}
					initialValue={data.form.sourceDescription}
				/>
				<AceEditorField
					name="criteriaScript"
					title={t('VIEW_CRITERIA')}
					initialValue={data.form.criteriaScript}
				/>
				<TextAreaFormField
					name="criteriaDescription"
					title={t('VIEW_FIELD_DESCRIPTION', [t('VIEW_CRITERIA')])}
					initialValue={data.form.criteriaDescription}
				/>
			</Form>
		</StyledContainer>
	)
}

const StyledContainer = styled(Container)`
	textarea {
		border-radius: 0;
	}
`
