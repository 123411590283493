import React from 'react'
import { StructureDto } from '@/api/models'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { UpdateDeepPartial } from '@/store/utils'
import { ListEditTab } from '@/components'
import { createEmptySystemEnvironment } from '@/store/modules/system/helpers'
import { useAppContext } from '@/utils/hooks'
import { SystemEnvironmentProperties } from './Properties'
import { useApi } from '@/api/hooks'
import { getSystemEnvironments } from '@/api'

type Props = {
	node: StructureDto
	data: OpenedSystemData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
}

export const Environments = ({ node, data, editMode, onChange }: Props) => {
	const { t } = useAppContext()

	const { data: envs } = useApi(getSystemEnvironments(node.id))

	return (
		<ListEditTab
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptySystemEnvironment}
			itemsKey={'environments'}
			idCounterKey={'environmentsLastId'}
			onChange={onChange}
			properties={SystemEnvironmentProperties(t, node, envs)}
			tabKey="environments"
		/>
	)
}
