import React from 'react'
import SimpleButtonsGroup from '@/components/SimpleButtonsGroup/SimpleButtonsGroup'
import styled, { css } from 'styled-components'
import { useFormFieldContext } from '@/utils/hooks'
import { NamingPlaceholder } from '../utils'
import { replaceAt } from '@/utils/string'
import { nextFrame } from '@/utils/async'

type NamingPlaceholdersProps = {
	placeholders: NamingPlaceholder
	isTextFocused: boolean
	inputElement: HTMLInputElement | null
}

const NamingPlaceholders = ({
	placeholders,
	isTextFocused,
	inputElement
}: NamingPlaceholdersProps) => {
	const formFieldContext = useFormFieldContext()

	const handleClick = async (value: string) => {
		const inputText = (formFieldContext.value || '') as string
		const placeholderText = `{${value}}`

		if (!inputElement) {
			formFieldContext.onChange?.(inputText + placeholderText)

			return
		}

		const selectionStart = inputElement.selectionStart || 0
		const selectionEnd = inputElement.selectionEnd || 0
		const newSelection = selectionStart + placeholderText.length

		formFieldContext.onChange?.(
			replaceAt(inputText, placeholderText, selectionStart, selectionEnd)
		)

		await nextFrame()

		inputElement.setSelectionRange(newSelection, newSelection)
	}

	return (
		<StyledSimpleButtonsGroup
			preventDefault
			isTextFocused={isTextFocused}
			disabled={!isTextFocused}
			onClick={handleClick}
			items={placeholders}
		/>
	)
}

const StyledSimpleButtonsGroup = styled(SimpleButtonsGroup)<{
	isTextFocused: boolean
}>`
	& button {
		margin-top: 2px;
	}
	${props => css`
		display: ${props.isTextFocused ? 'flex' : 'none'};
	`}
`

export default NamingPlaceholders
