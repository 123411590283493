import { ThCell } from '@/components/Layout'
import React from 'react'
import styled from 'styled-components'
import { useTablePropertiesContext } from '../context/TablePropertiesContext'

export const TablePropertiesHeader = () => {
	const {
		state: { isRowOrderable, tableProps, readonly, columnWidths }
	} = useTablePropertiesContext()

	return (
		<HeaderRow role="row">
			{isRowOrderable && !readonly && <RowOrderColumn />}
			{tableProps.map(prop => (
				<Cell
					key={prop.field}
					style={{
						width: (prop.field && columnWidths[prop.field]) || prop.width,
						minWidth: (prop.field && columnWidths[prop.field]) || prop.width,
						flexGrow: prop.flex,
						...(prop.sticky
							? { position: 'sticky', right: '0px', top: '0px' }
							: {})
					}}
					role="columnheader"
				>
					<HeaderCell title={prop.tableLabel || prop.label}>
						{prop.tableLabel || prop.label}
					</HeaderCell>
				</Cell>
			))}
			<Actions />
		</HeaderRow>
	)
}

const HeaderRow = styled.div`
	position: sticky;
	top: 0;
	left: 0;
	display: flex;
	align-items: stretch;
	padding-left: 2px;
	z-index: 2;
`

const RowOrderColumn = styled.div`
	width: 26px;
	min-width: 26px;
	background: white;
`

const Cell = styled(ThCell)`
	padding: 0;
	border-width: 0;
	border-right-width: 1px;

	&:last-child {
		border-right-width: 0;
	}
	> div {
		padding: 8px 6px;
		background: ${props => props.theme.colors.primary.base};
		color: #fff;
	}
`

const HeaderCell = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`

const Actions = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	width: 30px;
	min-width: 30px;
	background: white;
`
