import styled, { css } from 'styled-components'
import { Tabs } from '@/components/Tabs/Tabs'

export const Container = styled.div<{ isRowOrderable?: boolean }>`
	padding: 16px 32px 16px 16px;
	${props => css`
		padding-left: ${props.isRowOrderable ? '0' : '16px'};
	`}
	overflow: auto;
	height: 100%;
	box-sizing: border-box;
`

export const Header = styled.div`
	font-size: 140%;
	margin-bottom: 10px;
`

export const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
