import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'

import Button, { Schema, Size } from '../../../Button/Button'
import { FormContext, withForm } from '../../FormContext'

interface Props {
	form?: FormContext
	icon?: IconProp
	schema?: Schema
	size?: Size
	disabled?: boolean
	children?: React.ReactNode
	className?: string
}

class Submit extends React.PureComponent<Props> {
	render() {
		const {
			children,
			icon,
			schema,
			size,
			form,
			disabled,
			className
		} = this.props

		const { valid = true, validating = false, submitting = false } = form || {}

		return (
			<Button
				type="submit"
				className={className}
				disabled={disabled || validating || !valid || submitting}
				isLoading={submitting}
				icon={icon}
				schema={schema}
				size={size}
			>
				{children}
			</Button>
		)
	}
}

export default withForm(Submit) as typeof Submit
