import { deleteStereotype } from '@/api'
import { StereotypeDto } from '@/api/models'
import { DialogWrapper, Button, RemoveModal } from '@/components'
import { Flex } from '@/components/Layout'
import { LightTableColumn } from '@/components/LightTable'
import { useAppContext } from '@/utils/hooks'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { StereoTypeModal } from '../components/StereoTypeModal'

export const useStereoTypesColumns = (handleSave: () => void) => {
	const { t } = useAppContext()

	const columns = useMemo<LightTableColumn<StereotypeDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 50
			},
			{
				field: 'code',
				title: t('CODE'),
				width: 50,
				maxWidth: 300
			},
			{
				field: 'type',
				title: t('TYPE'),
				width: 50
			},
			{
				field: 'category',
				title: t('CATEGORY'),
				width: 50
			},
			{
				field: 'description',
				title: t('TABLE_GENERAL_DESCRIPTION'),
				width: 50
			},
			{
				field: '',
				width: 60,
				maxWidth: 60,
				// eslint-disable-next-line react/display-name
				formatter: (_, stereoType) => (
					<Flex>
						{!stereoType.internal && stereoType.category !== 'BEHAVIORAL' && (
							<>
								<DialogWrapper
									dialog={(opened, onClose) =>
										opened && (
											<StereoTypeModal
												stereoType={stereoType}
												onSave={handleSave}
												onClose={onClose}
											/>
										)
									}
								>
									{onClick => (
										<Button size="sm" schema="transparent" onClick={onClick}>
											<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
										</Button>
									)}
								</DialogWrapper>

								<DialogWrapper
									dialog={(opened, onClose) =>
										opened && (
											<RemoveModal
												callback={deleteStereotype}
												onRemove={handleSave}
												removeId={stereoType.id}
												textOnModal={t('REMOVE_DESC', [stereoType.name])}
												header={t('REMOVE_STEREOTYPE')}
												onClose={onClose}
											/>
										)
									}
								>
									{onClick => (
										<Button size="sm" schema="transparent" onClick={onClick}>
											<FontAwesomeIcon
												title={t('REMOVE')}
												color="#d00"
												icon={faTimes}
											/>
										</Button>
									)}
								</DialogWrapper>
							</>
						)}
					</Flex>
				)
			}
		],
		[handleSave, t]
	)

	return columns
}
