import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { TextProps, FormlessText } from '../UberForm/Input/Text/Text'
import { ErrorTooltip } from './ErrorTooltip'

type Props = Omit<TextProps, 'error'> & { error?: React.ReactNode }

export const InputWithError = ({ error, ...textProps }: Props) => {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const [focus, setFocus] = useState(false)

	return (
		<ErrorWrapper
			ref={wrapperRef}
			hasError={error !== undefined && error !== null}
		>
			<FormlessText
				{...textProps}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
			/>
			<ErrorTooltip error={error} focus={focus} wrapperRef={wrapperRef} />
		</ErrorWrapper>
	)
}

const ErrorWrapper = styled.div<{ hasError: boolean }>`
	${props =>
		props.hasError &&
		css`
			input,
			select {
				border-color: ${props.theme.colors.input.error.border};
				background: ${props.theme.colors.input.error.background};
			}
		`};
`
