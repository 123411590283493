import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import styled, { css } from 'styled-components'
import memoizeOne from 'memoize-one'
import cn from 'classnames'
import { getQsValue } from '@/utils/querystring'
import Tooltip from '../Tooltip/Tooltip'
import { FormContext, withForm } from './FormContext'
import { FormFieldContext } from './FormFieldContext'
import { Validator } from './Validators'
import { AnyObject, FormValue } from './Form'
import { Label } from './Label'
import { shallowEqual, ConnectedProps, connect } from 'react-redux'
import { EnhancedPureComponentWithContext } from '../EnhancedComponents'
import Button from '../Button/Button'
import { StoreState } from '@/store'
import { isEqual, isNil } from 'lodash'

export interface FormFieldRecap {
	title: string | undefined
	value: FormValue | undefined
}

export interface FormFieldProps<T = AnyObject> {
	className?: string
	form?: FormContext<T>
	/** Name of the form field, used as index in values object */
	name: Extract<keyof T, string>
	/** Field label */
	title?: string
	/** Hide field label */
	hideTitle?: boolean
	/** Field tooltip */
	tooltip?: string
	/** Show the field tooltip */
	hideTooltip?: boolean
	/** Array of validators used on this field value */
	validators?: Validator[]
	/** List of fields that should revalidated when this field changes */
	dependentFields?: Extract<keyof T, string>[]
	/** List of names of fields that this field uses for validation and such */
	uses?: string[]
	/** Indicates if field is required */
	required?: boolean
	/** Indicates if field is readonly */
	readonly?: boolean
	/** Indicates if the field is disabled */
	disabled?: boolean | ((values: Partial<T>) => boolean)
	/** Tooltip show on input itself */
	inputTooltip?: string
	/** Show label as placeholder */
	showTitlePlaceholder?: boolean
	/** Show the error on top of field */
	showErrorTop?: boolean
	/** Only show error when field is focused */
	hideErrorOnBlur?: boolean
	/** Don't include this field in form recap */
	skipRecap?: boolean
	/** Icon of action button */
	actionButtonIcon?: IconProp
	/** Indicates if action button is disabled */
	actionButtonDisabled?: boolean
	/** Action button tooltip */
	actionButtonTooltip?: string
	/** Field name used in subobject in array mode */
	arrayName?: string
	/** Field index in array */
	arrayIndex?: number

	/** Override loading state for this field */
	fieldIsLoading?: boolean

	/** Fire onChange event for the default value */
	fireDefaultValue?: boolean

	/** Fired when field value is changed */
	onChange?: (value: FormValue, field: string) => void
	/** Fired when field is focused */
	onFocus?: (e: React.FocusEvent) => void
	/** Fired when field focus is lost */
	onBlur?: (e: React.FocusEvent) => void
	/** Fired when valid state of field changes */
	onValidChange?: (
		valid: boolean,
		field: string,
		message: string | null
	) => void
	/** Fired when user clicks on action button */
	actionButtonOnClick?: (value?: FormValue) => void

	/** Value set upon mounting when field is not wrapped in form */
	initialValue?: FormValue
	/** Perform validation upon mounting the field */
	validateOnMount?: boolean

	/** Compact mode - try to make inputs as small as possible */
	compact?: boolean
	/** Label component to be rendered instead of standard field label */
	customLabel?: (
		title: string,
		inputId: string,
		isHighlighted: boolean
	) => JSX.Element
}

interface FormFieldState {
	value?: FormValue
	touched: boolean
	dirty: boolean
	error: string | null | undefined
	validating: boolean
	isFocused: boolean
	isFieldHighlighted: boolean
}

export interface FormInput<T = AnyObject> {
	validate?: (value: FormValue, values: Partial<T>) => string | void
	recap?: (value: FormValue) => string | null | undefined
}

function isEmpty(value: FormValue) {
	if (value === null || value === undefined) {
		return true
	}

	if (typeof value === 'string') {
		return value.trim().length === 0
	}

	if (Array.isArray(value)) {
		return value.length === 0
	}
}

const mapStateToProps = (state: StoreState) => ({
	formHighlights: state.formHighlights
})

type PropsFromRedux = Partial<ConnectedProps<typeof connector>>

export class FormlessFormField<
	T = AnyObject
> extends EnhancedPureComponentWithContext<
	FormFieldProps<T> & PropsFromRedux,
	FormFieldState
> {
	static defaultProps = {
		labelCols: 2,
		inputCols: 5,
		hideTitle: false,
		hideTooltip: false
	}

	state = {
		error: null,
		touched: false,
		dirty: false,
		validating: false,
		isFocused: false,
		value: null as FormValue,
		isFieldHighlighted: false
	}

	private input!: FormInput<T>
	private lastValidationId = 0

	async componentDidMount() {
		if (this.props.form) {
			this.props.form.register(this)

			if (this.props.form.withQueryString) {
				let value = getQsValue(this.props.name)

				if (value !== undefined) {
					if (value === 'false') {
						value = false
					}

					if (value === 'true') {
						value = true
					}

					if (typeof value === 'number') {
						value = value.toString()
					}

					await this.setValue(value, false)
				}
			}
		} else {
			if (this.props.initialValue) {
				await this.setValue(this.props.initialValue, false, true)
			}
		}

		if (this.props.validateOnMount) {
			await this.validate()
		}
	}

	componentWillUnmount() {
		if (this.props.form) {
			this.props.form.unregister(this)
		}
	}

	handleChange = async (
		updatedValue: FormValue,
		internal = false,
		fireFormChange = true
	) => {
		const original = this.state.value

		await this.setState({
			value: updatedValue,
			touched: internal ? this.state.touched : true
		})

		const { value } = this.state
		const { name, form, onChange } = this.props

		if (original !== updatedValue && fireFormChange) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			form && form.onFieldChange(this, value as any)
			onChange && onChange(value, name)
		}

		this.validate()
	}

	handleFocus = (e: React.FocusEvent) => {
		this.setState({ isFocused: true })

		if (this.props.onFocus) {
			this.props.onFocus(e)
		}
	}

	handleBlur = (e: React.FocusEvent) => {
		const { touched } = this.state

		this.setState({ isFocused: false, dirty: touched })

		if (this.props.onBlur) {
			this.props.onBlur(e)
		}
	}

	handleRegister = (input: FormInput<T>) => {
		this.input = input
	}

	setValue = async (
		value: FormValue,
		fireFormChange = true,
		isDefault = false
	) => {
		return this.handleChange(
			value,
			true,
			!!(fireFormChange || (isDefault && this.props.fireDefaultValue))
		)
	}

	validate = async (submitted = false) => {
		const { name, form, onValidChange } = this.props
		const { dirty } = this.state

		// Validation id helps us track concurrent validations and prevent race conditions
		this.lastValidationId++
		const thisValidationId = this.lastValidationId

		const error = await this.doValidate(submitted)

		// Only process the results when this is the last validation
		if (thisValidationId === this.lastValidationId) {
			await this.setState({
				error,
				validating: false,
				dirty: dirty || submitted
			})

			if ((submitted || dirty) && form) {
				form.onFieldValidated(name, error === null)
			}

			if (onValidChange) {
				onValidChange(error === null, name, error)
			}
		}
	}

	shouldRecap = () => !this.props.skipRecap

	recap = (): FormFieldRecap => {
		const value =
			this.input && this.input.recap
				? this.input.recap(this.state.value)
				: this.state.value

		return {
			title: this.props.title,
			value: value || undefined
		}
	}

	// @TODO: Optimization: Save context object when mounted or use state
	getContext = memoizeOne(
		(
			props: FormFieldProps<T>,
			value: FormValue,
			isFieldHighlighted: boolean
		): FormFieldContext<T> => {
			const {
				name,
				form,
				required,
				disabled,
				readonly,
				compact,
				fieldIsLoading
			} = props

			const { id = null, submitting = false } = form || {}
			const inputId = `${id}-${name}`

			let isDisabled = submitting

			if (typeof disabled === 'boolean') {
				isDisabled = disabled
			} else if (typeof disabled === 'function') {
				isDisabled = disabled(form ? form.getValues() : {})
			}

			return {
				disabled: readonly || (form && form.disabled) || isDisabled,
				id: inputId,
				name,
				onChange: this.handleChange,
				register: this.handleRegister,
				value,
				required,
				loading: fieldIsLoading ? true : form ? form.loading : false,
				onFocus: this.handleFocus,
				onBlur: this.handleBlur,
				withQueryString: form ? form.withQueryString : false,
				isHorizontal: form ? form.isHorizontal : false,
				readonly,
				compact,
				isFieldHighlighted
			}
		}
	)

	handleActionButtonClick = () => {
		const { actionButtonOnClick } = this.props

		if (actionButtonOnClick) {
			actionButtonOnClick(this.state.value)
		}
	}

	componentDidUpdate(prevProps: FormFieldProps<T>) {
		if (
			!shallowEqual(prevProps.initialValue, this.props.initialValue) &&
			!shallowEqual(this.props.initialValue, this.state.value)
		) {
			this.setValue(this.props.initialValue, false, true)
		}

		if (
			this.props.form?.enableFieldHighlight &&
			this.props.formHighlights?.active &&
			this.props.formHighlights.prevVersionValues
		) {
			const originalValue = this.props.formHighlights.prevVersionValues[
				this.props.name
			]

			if (
				!isEqual(originalValue, this.state.value) &&
				!(isNil(originalValue) && isNil(this.state.value))
			) {
				this.setState({ isFieldHighlighted: true })
			} else {
				this.setState({ isFieldHighlighted: false })
			}
		}

		if (!this.props.formHighlights?.active && this.state.isFieldHighlighted) {
			this.setState({ isFieldHighlighted: false })
		}
	}

	render() {
		const {
			name,
			title,
			hideTitle,
			children,
			required,
			form,
			tooltip,
			hideTooltip,
			inputTooltip,
			showTitlePlaceholder,
			showErrorTop,
			hideErrorOnBlur,
			actionButtonOnClick,
			actionButtonIcon,
			actionButtonDisabled,
			actionButtonTooltip,
			readonly,
			className,
			compact,
			customLabel
		} = this.props

		const { id = null, isHorizontal = false } = form || {}
		const { error, isFocused, dirty, isFieldHighlighted } = this.state
		const inputId = `${id}-${name}`

		let childrenContainer = children

		if (actionButtonOnClick) {
			childrenContainer = (
				<React.Fragment>
					{children}
					<span>
						<Button
							icon={actionButtonIcon}
							disabled={actionButtonDisabled}
							onClick={this.handleActionButtonClick}
							tooltip={actionButtonTooltip}
						/>
					</span>
				</React.Fragment>
			)
		}

		const FFC = FormFieldContext as React.Context<FormFieldContext<T>>

		return (
			<FFC.Provider
				value={this.getContext(
					this.props,
					this.state.value,
					isFieldHighlighted
				)}
			>
				<Container
					isHorizontal={isHorizontal}
					className={cn('form-field', className)}
					compact={!!compact}
				>
					<Label
						hideTitle={!!hideTitle}
						hideTooltip={!!hideTooltip}
						inputId={inputId}
						compact={compact}
						isHorizontal={isHorizontal}
						required={!!required}
						showTitlePlaceholder={!!showTitlePlaceholder}
						title={title}
						tooltip={tooltip}
						customLabel={customLabel}
						isFieldHighlighted={isFieldHighlighted}
					/>

					<Value
						readonly={!!readonly}
						withActionButton={!!actionButtonOnClick}
						hasError={dirty && !!error}
					>
						{inputTooltip ? (
							<Tooltip content={inputTooltip}>{childrenContainer}</Tooltip>
						) : (
							childrenContainer
						)}
						{dirty && error && (
							<HelpBlock
								hideWhenNotActive={!!hideErrorOnBlur}
								focused={isFocused}
								isOnTop={!!showErrorTop}
							>
								{error}
							</HelpBlock>
						)}
					</Value>
				</Container>
			</FFC.Provider>
		)
	}

	private doValidate = async (submitted: boolean) => {
		const { value, dirty } = this.state
		const { validators, name, required, form } = this.props
		const { locale } = this.context

		const getValues = form ? form.getValues : () => ({})

		const onFieldValidating =
			(submitted || dirty) && form && form.onFieldValidating

		if (required && isEmpty(value)) {
			return locale.translate('VALIDATOR_REQUIRED')
		}

		if (this.input && this.input.validate) {
			const result = this.input.validate(value, getValues())

			if (typeof result === 'string') {
				return result
			}
		}

		if (!validators) {
			return null
		}

		for (const validator of validators) {
			let error: string | null | undefined | Promise<string | null | undefined>

			// Value can be object, right now only in case of NumberRange
			if (value && typeof value === 'object' && !Array.isArray(value)) {
				for (const key of Object.keys(value) as ['from', 'to']) {
					const sub = validator(value[key], getValues(), locale)

					if (sub) {
						error = sub
						break
					}
				}
			} else {
				error = validator(value, getValues(), locale)
			}

			// Normal error occured
			if (typeof error === 'string') {
				return error
			}

			// Async validator
			// @TODO: This stops other validations!
			if (error instanceof Promise) {
				await this.setState({ validating: true })

				if (onFieldValidating) {
					onFieldValidating(name)
				}

				const delayedError = await error

				if (typeof delayedError === 'string') {
					return delayedError
				} else {
					return null
				}
			}
		}

		return null
	}
}

export const HelpBlock = styled.div<{
	hideWhenNotActive: boolean
	focused: boolean
	isOnTop: boolean
}>`
	position: absolute;
	left: 14px;
	z-index: 4;

	padding: 5px 10px;

	${props => css`
		background: ${props.theme.colors.form.error.background};
		color: ${props.theme.colors.form.error.color};

		&::before {
			content: ' ';
			position: absolute;
			top: -10px;
			left: 10%;
			margin-left: -5px;
			border: 5px solid transparent;
			border-bottom-color: ${props.theme.colors.form.error.background};
		}
	`}

	${props =>
		props.hideWhenNotActive &&
		css`
			opacity: 0;
			display: none;
		`}

	${props =>
		props.focused &&
		css`
			opacity: 1;
			display: inherit;
		`}

	${props =>
		props.isOnTop &&
		css`
			white-space: normal;
			min-width: 120px;
			bottom: 30px;
			text-align: left;

			&::before {
				bottom: 0;
				transform: rotate(180deg);
				transform-origin: bottom;
			}
		`}
`

const Value = styled.div<{
	withActionButton: boolean
	readonly: boolean
	hasError: boolean
}>`
	position: relative;

	${props =>
		props.withActionButton &&
		css`
			width: 100%;
			display: flex;

			input,
			select {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			button {
				height: 100%;
				padding: 5px;
				width: 23px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-left: 0;
			}
		`}

		${props =>
			props.hasError &&
			css`
				input,
				select {
					border-color: ${props.theme.colors.input.error.border};
					background: ${props.theme.colors.input.error.background};
				}
			`}

	${props =>
		props.readonly &&
		css`
			/* padding: ${props.theme.input.padding}; */
		`}
`

const Container = styled.div<{ isHorizontal: boolean; compact: boolean }>`
	margin-right: ${props => (props.compact ? '-1px' : '6px')};
	margin-bottom: ${props => (props.compact ? '-1px' : '6px')};
	flex: 1;
	${props =>
		props.compact &&
		css`
			width: 0;
		`}

	&:hover {
		z-index: 2;
	}

	&:focus-within {
		z-index: 3;
		${HelpBlock} {
			opacity: 1;
			display: inherit;
		}
	}

	${props =>
		props.isHorizontal &&
		css`
			margin-right: 10px;

			:last-child {
				margin-right: 0;
			}
		`}
`

const connector = connect(mapStateToProps)

export default withForm(
	connector(FormlessFormField as any) as any
) as typeof FormlessFormField
