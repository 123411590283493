import { getStereotypes } from '@/api'
import { useApi } from '@/api/hooks'
import { DomainDto, StereotypeDto, StructureDto } from '@/api/models'
import { ListEditTab } from '@/components'
import {
	ApiNodeDataForm,
	OpenedApiNodeData
} from '@/store/modules/apiNode/types'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/utils/hooks'
import React, { useCallback, useMemo } from 'react'
import { ColumnProperties } from './Properties'
import { duplication } from './validation'
import { createEmptyColumn } from '@/store/modules/apiNode/helpers'
import { useDomainTechnology } from '@/utils/domain'
import { ApiNodeColumnType } from '@/api/schemas/api-node'

type Props = {
	node: StructureDto
	data: OpenedApiNodeData
	systemNodeId: number
	editMode: boolean
	onChange: (v: UpdateDeepPartial<ApiNodeDataForm>) => void
	domains: DomainDto[]
}

const ColumnsComponent = ({
	node,
	data,
	systemNodeId,
	editMode,
	onChange,
	domains
}: Props) => {
	const { t } = useAppContext()
	const { getDomainData } = useDomainTechnology(systemNodeId)

	const columnStereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.API_NODE_ATTRIBUTE })
	)

	const apiNodeColumnTypes = useMemo(
		() => [
			{
				label: t('API_NODE_COLUMN_TYPE_IN'),
				value: ApiNodeColumnType.IN
			},
			{
				label: t('API_NODE_COLUMN_TYPE_OUT'),
				value: ApiNodeColumnType.OUT
			},
			{
				label: t('API_NODE_COLUMN_TYPE_IN_OUT'),
				value: ApiNodeColumnType.IN_OUT
			},
			{
				label: t('API_NODE_COLUMN_TYPE_INTERNAL'),
				value: ApiNodeColumnType.INTERNAL
			}
		],
		[t]
	)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<ApiNodeDataForm>) => {
			if (v.columns) {
				Object.values(v.columns).forEach(c => {
					if (c.domainId) {
						const d = domains.find(d => d.id === c.domainId)
						const domainData = getDomainData(d)
						const colData = data.form.columns?.find(col => col.id === c.id)

						// don't change domain data, when domain is same
						if (colData && colData.domainId === c.domainId) {
							return
						}

						if (d) {
							c.dataType = domainData?.dataType
						} else {
							c.dataType = undefined
						}
					} else if (c.stereotypeId) {
					}
				})
			}

			onChange(v)
		},
		[onChange, domains, getDomainData, data.form.columns]
	)

	const errors = useMemo(() => duplication(data.form.columns ?? [], t), [
		data.form.columns,
		t
	])

	const properties = ColumnProperties(
		t,
		domains,
		columnStereotypes,
		apiNodeColumnTypes
	)

	return (
		<>
			<ListEditTab
				node={node}
				data={data.form}
				editMode={editMode}
				createEmpty={createEmptyColumn}
				itemsKey={'columns'}
				idCounterKey={'columnsLastId'}
				onChange={handleChange}
				properties={properties}
				tabKey="column"
				errors={errors}
			/>
		</>
	)
}

export const Columns = React.memo(ColumnsComponent)
