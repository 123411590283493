import React, { useCallback } from 'react'
import { Loader } from '@/components'
import { ViewTabs } from './components/Tabs'
import {
	initView,
	selectViewTab,
	saveView,
	updateView
} from '@/store/modules/view/actions'
import { Title } from '../../components/Title'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { useAppDispatch, useAppStore } from '@/utils/hooks'
import { useTabContext } from '@/context/TabContext/TabContext'
import { TabProps } from '@/components/Tabs/Tabs'
import { ViewTab, ViewDataForm } from '@/store/modules/view/types'
import { debounce } from 'debounce'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { UpdateDeepPartial } from '@/store/utils'
import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { useNodeInit } from '../../hooks/useNodeInit'

export const ViewDetail = () => {
	const { onSaveError } = useTabContext()

	const {
		state: { node, systemNodeId, editMode }
	} = useDetailTabContext()

	const dispatch = useAppDispatch()
	const views = useAppStore(state => state.view.views)
	const view = views[node.id]
	const objectTypes = view?.form.objectSettings

	const data = views[node.id]

	useNodeInit()

	const handleTabChange = useCallback(
		(tab: TabProps) => {
			dispatch(selectViewTab(node, tab.id as ViewTab))
		},
		[dispatch, node]
	)

	const saveDebounced = useCallback(
		debounce(async () => {
			try {
				await dispatch(saveView(node))
			} catch (e) {
				onSaveError(e)
			}
		}, 1000),
		[node.id]
	)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<ViewDataForm>) => {
			if (editMode) {
				dispatch(updateView(node, v))
				saveDebounced()
			}
		},
		[editMode, dispatch, node, saveDebounced]
	)

	const handleEdit = useCallback(async () => {
		await dispatch(initView({ nodeId: node.id, editMode: true }))
	}, [dispatch, node.id])

	const handleCancel = useCallback(async () => {
		await dispatch(initView({ nodeId: node.id, editMode: false }))
	}, [dispatch, node.id])

	if (!data) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						dirty={data.dirty}
						onEdit={handleEdit}
						onCancel={handleCancel}
					/>
				}
			/>
			<ViewTabs
				node={node}
				data={data}
				editMode={editMode}
				systemNodeId={systemNodeId}
				objectTypes={objectTypes}
				onTabChange={handleTabChange}
				onChange={handleChange}
			/>
		</>
	)
}
