import { AppContext, AppContextType } from '@/context/AppContext'
import EnhancedPureComponent from './EnhancedPureComponent'

/**
 * Adds AppContext.
 */
export default class EnhancedPureComponentWithContext<
	P,
	S = {}
> extends EnhancedPureComponent<P, S> {
	static contextType = AppContext

	context!: AppContextType
}
