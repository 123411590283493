import React from 'react'
import { Button, LightTable } from '@/components'
import { Container } from '@/components/Container'
import { faBorderStyle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/utils/hooks'
import { DialogWrapper } from '@/components'
import { getStereotypes } from '@/api'
import { useApi } from '@/api/hooks'
import { StereotypeDto } from '@/api/models'
import {
	TitleContainer,
	TitleText,
	TitleActions,
	TitleIcon
} from '../../styles'
import { StereoTypeModal } from './components/StereoTypeModal'
import { useFilter } from '../../hooks/useFilter'
import {
	StereoTypeFilter,
	StereoTypeFilterValues
} from './components/StereoTypeFilter'
import { useStereoTypeFilteredData } from './hooks/useStereoTypeFilteredData'
import { useStereoTypesColumns } from './hooks/useStereoTypesColumns'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'

export const StereoTypes = () => {
	const { t } = useAppContext()
	const stereoTypes = useApi(getStereotypes())
	const { filter, onChangeFilter } = useFilter<StereoTypeFilterValues>()

	const handleSave = useSettingsPageSaveHandler(stereoTypes)

	const columns = useStereoTypesColumns(handleSave)
	const stereotypes = useStereoTypeFilteredData(filter, stereoTypes.data)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faBorderStyle} color="#000" />
				<TitleText>{t('STEREOTYPES')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<StereoTypeModal onSave={handleSave} onClose={onClose} />
							)
						}
					>
						{onClick => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<StereoTypeFilter onChangeFilter={onChangeFilter} />
				<LightTable<StereotypeDto>
					data={stereotypes}
					columns={columns}
					keyField={'id'}
					isLoading={stereoTypes.loading}
				/>
			</Container>
		</>
	)
}
