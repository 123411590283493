import React from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { BusinessDomainDto } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import { TextFormField, TextAreaFormField } from '@/components/UberForm'
import { useApiRequest } from '@/api/hooks'
import { updateBusinessDomain, createBusinessDomain } from '@/api'
import { auditFieldsRemove, auditFieldsAdd } from '../../../utils'
import { AuditForm } from '../../../components/AuditForm'

type Props = {
	businessDomain?: Partial<BusinessDomainDto>
	onSave: () => void
	onClose: () => void
}

export const BusinessDomainModal = ({
	businessDomain,
	onSave,
	onClose
}: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const handleSubmit = async (form: Partial<BusinessDomainDto>) => {
		auditFieldsRemove(form)

		const action =
			businessDomain && businessDomain.id
				? updateBusinessDomain(businessDomain.id, form as BusinessDomainDto)
				: createBusinessDomain(form as BusinessDomainDto)

		const result = await request(action)

		if (result) {
			onSave()
			onClose()
		}
	}

	return (
		<>
			<ModalForm<BusinessDomainDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: '550px'
				}}
				initialValues={{
					id: businessDomain?.id,
					name: businessDomain?.name,
					description: businessDomain?.description,
					...auditFieldsAdd(businessDomain)
				}}
				header={
					businessDomain
						? t('UPDATE_BUSINESS_DOMAIN')
						: t('CREATE_BUSINESS_DOMAIN')
				}
				onSubmit={handleSubmit}
			>
				<TextFormField title={t('BUSINESS_DOMAIN_NAME')} name="name" required />
				<TextAreaFormField
					title={t('BUSINESS_DOMAIN_DESCRIPTION')}
					name="description"
				/>
				<AuditForm isUpdating={!!businessDomain} />
			</ModalForm>
		</>
	)
}
