export enum NamingDtoTypeEnum {
	TABLE_CODE = 'TABLE_CODE',
	MAPPING_CODE = 'MAPPING_CODE',
	INTERFACE_CODE = 'INTERFACE_CODE',
	VIEW_CODE = 'VIEW_CODE',
	API_NODE_CODE = 'API_NODE_CODE',
	FOLDER_CODE = 'FOLDER_CODE',
	QUEUE_CODE = 'QUEUE_CODE',
	SYSTEM_CODE = 'SYSTEM_CODE',
	HISTORICAL_TABLE_NAME = 'HISTORICAL_TABLE_NAME',
	REFERENCE_TABLE_NAME = 'REFERENCE_TABLE_NAME',
	HISTORICAL_TABLE_CODE = 'HISTORICAL_TABLE_CODE',
	REFERENCE_TABLE_CODE = 'REFERENCE_TABLE_CODE',
	DEFAULT_MAPPING_NAME = 'DEFAULT_MAPPING_NAME',
	DEFAULT_MAPPING_CODE = 'DEFAULT_MAPPING_CODE',
	HISTORICAL_DEFAULT_MAPPING_NAME = 'HISTORICAL_DEFAULT_MAPPING_NAME',
	REFERENCE_DEFAULT_MAPPING_NAME = 'REFERENCE_DEFAULT_MAPPING_NAME',
	INTERFACE_DEFAULT_MAPPING_NAME = 'INTERFACE_DEFAULT_MAPPING_NAME',
	HISTORICAL_DEFAULT_MAPPING_CODE = 'HISTORICAL_DEFAULT_MAPPING_CODE',
	REFERENCE_DEFAULT_MAPPING_CODE = 'REFERENCE_DEFAULT_MAPPING_CODE',
	INTERFACE_DEFAULT_MAPPING_CODE = 'INTERFACE_DEFAULT_MAPPING_CODE',
	DDL_SCRIPT_NAME = 'DDL_SCRIPT_NAME',
	EXPORT_NAME = 'EXPORT_NAME',
	PRIMARY_KEY_NAME = 'PRIMARY_KEY_NAME',
	SEQUENCE_NAME = 'SEQUENCE_NAME',
	API_COLLECTION_CODE = 'API_COLLECTION_CODE',
	WORKFLOW_CODE = 'WORKFLOW_CODE'
}
