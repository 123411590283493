import { searchStructure } from '@/api'
import { useApi } from '@/api/hooks'
import { SelectFormField } from '@/components/UberForm'
import { useAppContext, useDebounce } from '@/utils/hooks'
import { slice } from 'lodash'
import React, { useMemo, useState } from 'react'
import { WriteMappingData } from './AddModal'

type Props = {
	onChangeWriteMapping: (value: WriteMappingData) => void
}

export const AddModalWriteMapping = ({ onChangeWriteMapping }: Props) => {
	const { t } = useAppContext()

	const [search, setSearch] = useState('')

	const searchDebounced = useDebounce(search, 300)

	const searchStructures = useApi(
		searchStructure(searchDebounced, {
			nodeType: 'TABLE'
		})
	)

	const elements = useMemo(() => {
		const elements = slice(searchStructures.data || [], 0, 30)

		return elements
	}, [searchStructures.data])

	return (
		<SelectFormField
			title={t('TARGET_TABLE')}
			name="targetTableId"
			options={elements}
			fieldIsLoading={searchStructures.loading}
			valueKey="structureId"
			labelKey="fullPath"
			required
			isNumeric
			onInputChange={search => {
				if (search) {
					setSearch(search)
				}
			}}
			placeholder={t('SEARCH')}
			onChange={value => {
				if (value) {
					const fullPath = elements.find(
						element => element.structureId === value
					)?.fullPath

					onChangeWriteMapping({
						targetTableFullPath: fullPath ?? ''
					})
				}
			}}
		/>
	)
}
