import React from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { DomainDto, DomainTechnologyDto, TechnologyDto } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import { TextFormField } from '@/components/UberForm'
import { Wrapper, Column } from '../../../styles'
import { auditFieldsRemove } from '../../../utils'
import { useConfirmationDomain } from './hooks/useConfirmationDomain'

type Props = {
	domain: DomainDto
	technology: TechnologyDto
	onSave: () => void
	onClose: () => void
}

export const DomainTechnologyModal = ({
	domain,
	technology,
	onSave,
	onClose
}: Props) => {
	const { t } = useAppContext()
	const { onConfirmationDomain } = useConfirmationDomain()

	const domainTechnology = domain.domainTechnologies?.find(
		dt => dt.technologyId === technology.id
	)

	const handleSubmit = async (form: Partial<DomainTechnologyDto>) => {
		// TODO: we should find a better way to handle audit fields
		auditFieldsRemove(domain)

		const domainParsed = {
			...domain,
			domainTechnologies: [
				...(domain.domainTechnologies ?? []).filter(
					dt => dt.technologyId !== technology.id
				),
				{
					...(domainTechnology ?? {
						technologyId: technology.id,
						technologyCode: technology.code
					}),
					defaultValue: form.defaultValue,
					dataType: form.dataType
				}
			]
		} as DomainDto

		const postProcess = (dto: DomainDto | null) => {
			if (dto) {
				onSave()
				onClose()
			}
		}

		await onConfirmationDomain(domainParsed, postProcess)
	}

	return (
		<>
			<ModalForm<DomainTechnologyDto>
				open
				onClose={onClose}
				contentStyle={{
					width: '550px',
					maxHeight: '90%',
					overflow: 'auto'
				}}
				initialValues={{
					defaultValue: domainTechnology?.defaultValue,
					dataType: domainTechnology?.dataType
				}}
				header={`${t('UPDATE_DOMAIN')} - ${domain.name} - ${technology?.name}`}
				onSubmit={handleSubmit}
			>
				<Wrapper>
					<Column width="50%">
						<TextFormField
							title={t('DOMAIN_DATA_TYPE')}
							name="dataType"
							required
						/>
					</Column>
					<Column width="50%">
						<TextFormField
							title={t('DOMAIN_DEFAULT_VALUE')}
							name="defaultValue"
						/>
					</Column>
				</Wrapper>
			</ModalForm>
		</>
	)
}
