import React, { useCallback, useState } from 'react'
import { Modal } from '@/components/Modal/Modal'
import { Button, MessageModal } from '@/components'
import { useApiRequest } from '@/api/hooks'
import {
	StructureDto,
	ReleaseEnvironmentDto,
	ReleaseDto,
	MassExportConfigDto
} from '@/api/models'
import styled from 'styled-components'
import {
	faTimes,
	faDownload,
	faDatabase
} from '@fortawesome/free-solid-svg-icons'
import { useAppContext, useAppStore } from '@/utils/hooks'
import { DDL_MODAL_ID } from '@/constants'
import { MassSqlExportModal } from '@/pages/User/pages/Home/components/TreePanel/components/MassSqlExportModal'
import {
	createDeployments,
	createReleasePreview,
	createReleaseScripts
} from '@/api'
import FileSaver from 'file-saver'
import { AceEditorWithoutForm } from '@/components/AceEditor/AceEditor'

type Props = {
	node: StructureDto
	release: ReleaseDto
	env: ReleaseEnvironmentDto
	preview?: boolean
	onClose: () => void
}

export const DdlModal = ({ node, release, env, preview, onClose }: Props) => {
	const { t } = useAppContext()
	const apiError = useAppStore(state => state.apiError.title)
	const request = useApiRequest()

	const [massExportConfigDto, setMassExportConfigDto] = useState<
		MassExportConfigDto | undefined
	>(undefined)

	const [previewSql, setPreviewSql] = useState<string | null>(null)

	const [deploy, setDeploy] = useState({
		deploying: false,
		showSuccess: false
	})

	const onCloseMass = () => {
		onClose()
	}

	const onExport = async (massExportConfigDto: MassExportConfigDto) => {
		const massExport = {
			...massExportConfigDto,
			environmentId: env.id!
		} as MassExportConfigDto

		setMassExportConfigDto(massExport)

		if (preview) {
			const { data } = await request(
				createReleasePreview(node.id, release.id!, env.id!, massExport)
			)

			setPreviewSql(data)

			return
		}

		const { data: buffer } = await request(
			createReleaseScripts(node.id, release.id!, env.id!, massExport)
		)

		if (!buffer) {
			return
		}

		let fileName = 'export.zip'
		let type = 'application/zip'

		if (
			massExport.massExportStrategy ===
			MassExportConfigDto.MassExportStrategyEnum.SINGLE_FILE
		) {
			fileName = 'export.txt'
			type = 'application/text'
		}

		const blob = new Blob([new Uint8Array(buffer as ArrayBuffer)], {
			type
		})

		FileSaver.saveAs(blob, fileName)
	}

	const saveFile = useCallback(
		(fileName: string, blobPart: BlobPart[], type?: string) => {
			const blob = new Blob(blobPart, {
				type: type ?? 'text/plain;charset=utf-8'
			})

			FileSaver.saveAs(blob, fileName)
		},
		[]
	)

	const handleDownload = useCallback(() => {
		if (previewSql) {
			saveFile(`export.txt`, [previewSql])
		}
	}, [previewSql, saveFile])

	const onDeploy = async () => {
		setDeploy(prev => ({ ...prev, deploying: true }))

		await request(
			createDeployments(node.id, release.id!, env.id!, massExportConfigDto!)
		)

		if (apiError) {
			setDeploy(prev => ({
				...prev,
				deploying: false
			}))

			return
		}

		setDeploy({
			deploying: false,
			showSuccess: true
		})
	}

	const onInfoClose = () => {
		setDeploy(prev => ({
			...prev,
			showSuccess: false
		}))

		onClose()
	}

	return (
		<>
			<MassSqlExportModal
				node={node}
				env={env}
				open={!Boolean(massExportConfigDto)}
				onClose={onCloseMass}
				saveTitle={t('SAVE')}
				onExport={onExport}
				enableExportStrategy={!preview}
			/>
			<Modal
				open={Boolean(massExportConfigDto)}
				onClose={onClose}
				stretchFooterButtons={false}
				resizable={preview}
				maximizeButtonVisible={preview}
				dialogId={DDL_MODAL_ID}
				header={
					preview
						? `${t('PREVIEW_DDL')}: ${env.name}`
						: `${t('CREATE_DDL')}: ${env.name}`
				}
				contentStyle={
					preview
						? { width: 800, minHeight: 530, minWidth: 530 }
						: { width: 300 }
				}
				footer={
					<>
						{!preview && (
							<Button
								onClick={onDeploy}
								icon={faDatabase}
								isLoading={deploy.deploying}
							>
								{t('MARK_AS_DEPLOYED')}
							</Button>
						)}
						{preview && (
							<Button
								icon={faDownload}
								onClick={handleDownload}
								disabled={previewSql === null}
							>
								{t('DOWNLOAD')}
							</Button>
						)}
						<Button onClick={onClose} schema="transparent" icon={faTimes}>
							{t('CLOSE')}
						</Button>
					</>
				}
			>
				{previewSql && (
					<SqlData>
						<AceEditorWithoutForm
							name="sqlPreview"
							value={previewSql}
							disabled
						/>
					</SqlData>
				)}
			</Modal>

			<MessageModal
				type="info"
				opened={deploy.showSuccess}
				onClose={onInfoClose}
				title={t('DEPLOYMENT')}
				message={t('DEPLOYMENT_MARK_CREATED')}
			/>
		</>
	)
}

const SqlData = styled.pre`
	position: relative;
	min-height: 400px;
	height: 100%;
`
