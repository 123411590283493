import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
	Form,
	NamingInputFormField,
	SelectFormField,
	TextAreaFormField,
	TextFormField
} from '@/components/UberForm'
import {
	HistoryTableData,
	IdCodeName,
	PartitioningStrategy,
	TableData,
	TableOverview
} from '@/api/schemas'
import {
	OpenedTableData,
	TableDataForm,
	TableMode
} from '@/store/modules/table/types'
import { Container } from '../../components/StyledComponents'
import ObjectSettingsControl from '@/pages/User/pages/Home/components/ObjectSettingsControl'
import { Col, Flex, Legend, Placeholder, Row } from '@/components/Layout'
import { useAppContext, useAppDispatch } from '@/utils/hooks'
import {
	ObjectSettingsUpdateDto,
	StereotypeDto,
	StructureDto
} from '@/api/models'
import { booleanString } from '@/utils/booleans'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { SyncFormField, UnsyncedFields } from '../../components/SyncFormField'
import {
	updateColumnsWithHistoryColumns,
	updateSequenceCode,
	updateStereotypeColumns
} from '@/store/modules/table/actions'
import { uniqueNameValidator } from '@/utils/validators'
import { useApi } from '@/api/hooks'
import styled from 'styled-components'
import { defaultHistoryTableData } from '@/store/modules/table/helpers'
import {
	useNodeStates,
	useResponsibleUsers,
	useStereotypes,
	useSystemUsers
} from '../../../../utils'
import { getAllBusinessDomains } from '@/api'
import {
	useEntityTypes,
	usePartitionStrategies,
	useRetentionTimes,
	useStrategies
} from '../../lists'
import { useTechnologyId } from '@/utils/domain'
import {
	getIsFieldDisabled,
	getIsFieldDisabledGenerated,
	getNamingTypeCode,
	getSqlType
} from '../../utils'
import { uniqBy } from 'lodash'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'
import { useCustomAttributesInitValues } from '../../../../components/CustomAttributes/hooks/useCustomAttributes'
import { FieldSetGap, FieldSetProject } from '../../../../components/FieldSet'
import { PageType } from '@/pages/User/pages/Home/components/CustomAttributes/types'

type Props = {
	node?: StructureDto
	data: OpenedTableData
	systemNodeId: number
	editMode: boolean
	mode: TableMode
	onChange: (data: Partial<TableData>) => void
	unsyncedFields: UnsyncedFields
	onSync: (field: keyof HistoryTableData) => void
	modeForm: TableDataForm
	modeFormTable: TableDataForm
	/** Selected nodes for batch edit */
	selectedNodes?: StructureDto[]
}

export const Overview = ({
	node,
	data,
	systemNodeId,
	editMode,
	mode,
	onChange,
	unsyncedFields,
	onSync,
	modeForm,
	modeFormTable,
	selectedNodes
}: Props) => {
	const { t } = useAppContext()
	const refForm = useRef<Form<TableData>>(null)
	const refInit = useRef(false)

	const dispatch = useAppDispatch()
	const technologyId = useTechnologyId(systemNodeId)

	const stereotypes = useStereotypes(StereotypeDto.TypeEnum.TABLE)
	const businessDomains = useApi(getAllBusinessDomains())
	const userList = useResponsibleUsers(modeForm)
	const systemUsers = useSystemUsers(systemNodeId)

	const isHistoryMode = mode === TableMode.HISTORY

	const hasPartitioningColumn =
		modeForm.partitioningStrategy === PartitioningStrategy.KEY ||
		modeForm.partitioningStrategy === PartitioningStrategy.KEY_AND_SNAPSHOT ||
		modeForm.partitioningStrategy ===
			PartitioningStrategy.KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE

	const isKeepingFullStory =
		modeForm.retentionStrategy ===
			TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY ||
		modeForm.retentionStrategy ===
			TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY_AND_EOM

	const isKeepingFullStoryAndEOM =
		modeForm.retentionStrategy ===
		TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY_AND_EOM

	const entityTypes = useEntityTypes(t)
	const nodeStates = useNodeStates(t)
	const strategies = useStrategies(t)
	const retentionTimes = useRetentionTimes(t)
	const partitionStrategies = usePartitionStrategies(t)

	const canGenerateNaming = useCanGenerateNaming(node?.id)

	const {
		initialValuesCustomAttributes,
		getCustomAttributesReset,
		parseCustomAttribute,
		customAttributes
	} = useCustomAttributesInitValues(
		data.form,
		StructureDto.TypeEnum.TABLE,
		mode
	)

	useEffect(() => {
		refInit.current = false
	}, [mode])

	const handleObjectSettingsChange = (newValue: IdCodeName[]) => {
		onChange({
			objectSettings: newValue
		})
	}

	const handleChange = useCallback(
		(item: Partial<TableData>, source?: FormlessFormField<TableData>) => {
			if (source) {
				const name = source.props.name

				if (name === 'code' && !refInit.current) {
					if (item.code) {
						refInit.current = true
					}

					return
				}

				refInit.current = true

				if (name === 'stereotypeId' && technologyId) {
					const stereotype = stereotypes.data?.find(s => s.id === item[name])

					const {
						customAttributesForm,
						customAttributesRedux
					} = getCustomAttributesReset(stereotype?.id)

					refForm.current?.setValues(customAttributesForm)

					onChange({
						stereotypeId: stereotype?.id,
						stereotypeCode: stereotype?.code,
						customAttributes: customAttributesRedux
					})

					if (selectedNodes) {
						selectedNodes.forEach(node =>
							dispatch(updateStereotypeColumns(node, technologyId, item[name]))
						)
					} else if (node) {
						dispatch(updateStereotypeColumns(node, technologyId, item[name]))
					}

					if (stereotype?.code === 'reference') {
						onChange({
							hasHistoryTable: false,
							historyTable: defaultHistoryTableData(),
							hasReferenceTable: true
						})
					} else {
						onChange({
							hasReferenceTable: false
						})
					}

					if (!stereotype?.children) {
						onChange({
							subStereotypeId: undefined
						})
					}

					return
				}

				if (name === 'sequenceColumnCode') {
					if (selectedNodes) {
						selectedNodes.forEach(node =>
							dispatch(
								updateSequenceCode(
									node,
									{ sequenceColumnCode: item.sequenceColumnCode },
									mode,
									[
										NamingDtoTypeEnum.SEQUENCE_NAME,
										node.id!,
										{
											sequence_column: item.sequenceColumnCode
										}
									]
								)
							)
						)
					} else if (node) {
						dispatch(
							updateSequenceCode(
								node,
								{ sequenceColumnCode: item.sequenceColumnCode },
								mode,
								[
									NamingDtoTypeEnum.SEQUENCE_NAME,
									node.id!,
									{
										sequence_column: item.sequenceColumnCode
									}
								]
							)
						)
					}

					onChange({
						[name]: item[name]
					})

					return
				}

				if (name === 'retentionStrategy') {
					if (item[name] === TableOverview.RetentionStrategyEnum.INFINITE) {
						onChange({
							retentionStrategyFullHistoryValue: undefined,
							retentionStrategyFullHistoryUnit: undefined,
							retentionStrategyEomValue: undefined,
							retentionStrategyEomUnit: undefined
						})
					}

					if (
						item[name] === TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY
					) {
						onChange({
							retentionStrategyFullHistoryValue: undefined,
							retentionStrategyFullHistoryUnit: undefined
						})
					}

					onChange({
						[name]: item[name]
					})

					return
				}

				if (name === 'businessDomainId') {
					const businessDomain = businessDomains.data?.find(
						d => d.id === item[name]
					)

					onChange({
						businessDomainId: businessDomain?.id,
						businessDomainName: businessDomain?.name
					})

					return
				}

				if (name === 'partitioningColumnId') {
					const column = modeForm.columns?.find(d => d.id === item[name])

					onChange({
						partitioningColumnId: item[name],
						partitioningColumnCode: column?.code
					})

					return
				}

				if (name === 'responsiblePersonId' || name === 'responsibleAnalystId') {
					const user = userList.data?.find(u => u.id === item[name])

					const userName =
						name === 'responsiblePersonId'
							? 'responsiblePersonName'
							: 'responsibleAnalystName'

					onChange({
						[name]: user?.id,
						[userName]: user?.value
					})

					return
				}

				if (name === 'hasHistoryTable' && technologyId) {
					if (selectedNodes) {
						selectedNodes.forEach(node =>
							dispatch(
								updateColumnsWithHistoryColumns(
									node,
									technologyId,
									item.hasHistoryTable as boolean
								)
							)
						)
					} else if (node) {
						dispatch(
							updateColumnsWithHistoryColumns(
								node,
								technologyId,
								item.hasHistoryTable as boolean
							)
						)
					}

					onChange({
						hasHistoryTable: item.hasHistoryTable
					})

					return
				}

				if (name === 'ownerId') {
					const owner = systemUsers?.data?.find(u => u.id === item[name])

					onChange({
						ownerId: owner?.id,
						ownerName: owner?.name
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name]
						},
						modeForm.customAttributes,
						customAttributes
					)
				)
			}
		},
		[
			technologyId,
			onChange,
			parseCustomAttribute,
			modeForm.customAttributes,
			modeForm.columns,
			stereotypes.data,
			getCustomAttributesReset,
			selectedNodes,
			node,
			dispatch,
			mode,
			businessDomains.data,
			userList.data,
			systemUsers
		]
	)

	const namingConfig = useMemo(
		() =>
			node && editMode
				? ([
						getNamingTypeCode(mode),
						node.id,
						getSqlType(mode),
						{
							master_node_code: data.form.code,
							master_node_name: data.form.name,
							body: { data: JSON.stringify(modeForm) }
						}
				  ] as const)
				: undefined,
		[data.form.code, data.form.name, editMode, mode, modeForm, node]
	)

	return (
		<Container>
			<Form<TableData>
				onChange={handleChange}
				disabled={!editMode}
				defaultValues={{ ...modeForm, ...initialValuesCustomAttributes }}
				key={booleanString(editMode)}
				ref={refForm}
				enableFieldHighlight
			>
				<Row>
					<Col size="medium">
						<TextFormField
							title={t('TABLE_GENERAL_NAME')}
							name="name"
							initialValue={modeForm.name}
							validators={[
								uniqueNameValidator(dispatch, data.original.name, systemNodeId)
							]}
							required
							disabled={
								getIsFieldDisabledGenerated('name', mode, canGenerateNaming) ||
								Boolean(selectedNodes)
							}
						/>
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('TABLE_GENERAL_CODE')}
							callNamingWorkingDataParams={namingConfig}
							initialValue={modeForm.code}
							disabled={
								getIsFieldDisabledGenerated('code', mode, canGenerateNaming) ||
								Boolean(selectedNodes)
							}
							validators={[
								uniqueNameValidator(dispatch, data.original.code, systemNodeId)
							]}
						/>
					</Col>
					<Col size="medium">
						<SelectFormField
							name="stereotypeId"
							title={t('TABLE_GENERAL_STEREOTYPE')}
							initialValue={modeForm.stereotypeId}
							fieldIsLoading={stereotypes.reloading}
							options={stereotypes.data || []}
							valueKey="id"
							labelKey="name"
							isNumeric
							allowEmpty
							disabled={getIsFieldDisabled('stereotypeId', mode)}
						/>
					</Col>
				</Row>
				<Row alignItems="flex-end">
					<Col size="medium">
						<SyncFormField
							unsyncedFields={unsyncedFields}
							disabled={!editMode}
							field="comment"
							onSync={onSync}
						>
							<TextAreaFormField
								title={t('TABLE_GENERAL_COMMENT')}
								name="comment"
								initialValue={modeForm.comment}
								rows={5}
							/>
						</SyncFormField>
					</Col>
					<Col size="medium">
						<SyncFormField
							unsyncedFields={unsyncedFields}
							disabled={!editMode}
							field="description"
							onSync={onSync}
						>
							<TextAreaFormField
								title={t('TABLE_GENERAL_DESCRIPTION')}
								name="description"
								initialValue={modeForm.description}
								rows={5}
							/>
						</SyncFormField>
					</Col>
					<Col size="medium">
						<Placeholder flexGrow={1}>
							<CheckboxFormField
								name="hasHistoryTable"
								title={t('TABLE_GENERAL_HAS_HISTORY_TABLE')}
								initialValue={modeForm.hasHistoryTable}
								isHidden={modeForm.hasReferenceTable || isHistoryMode}
							/>
						</Placeholder>
					</Col>
				</Row>
				<SyncFormField
					unsyncedFields={unsyncedFields}
					disabled={!editMode}
					field="objectSettings"
					onSync={onSync}
				>
					<ObjectSettingsControl
						name="objectSettings"
						label={t('OBJECTS')}
						onChange={handleObjectSettingsChange}
						disabled={!editMode}
						data={modeForm.objectSettings ?? []}
						type={ObjectSettingsUpdateDto.StructureTypeEnum.TABLE}
					/>
				</SyncFormField>
				<FieldSetProject>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="status"
								onSync={onSync}
							>
								<SelectFormField
									name="status"
									title={t('TABLE_GENERAL_TABLE_STATUS')}
									initialValue={modeForm.status}
									options={nodeStates}
									valueKey="value"
									labelKey="label"
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="responsiblePersonId"
								onSync={onSync}
							>
								<SelectFormField
									name="responsiblePersonId"
									title={t('RESPONSIBLE_PERSON')}
									options={userList.data || []}
									valueKey="id"
									fieldIsLoading={userList.reloading}
									labelKey="value"
									initialValue={modeForm.responsiblePersonId}
									isNumeric
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="responsibleAnalystId"
								onSync={onSync}
							>
								<SelectFormField
									name="responsibleAnalystId"
									title={t('RESPONSIBLE_ANALYST')}
									options={userList.data || []}
									fieldIsLoading={userList.reloading}
									valueKey="id"
									labelKey="value"
									initialValue={modeForm.responsibleAnalystId}
									isNumeric
									allowEmpty
								/>
							</SyncFormField>
						</Col>
					</Row>
				</FieldSetProject>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_BUSINESS_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="businessDomainId"
								onSync={onSync}
							>
								<SelectFormField
									name="businessDomainId"
									title={t('TABLE_GENERAL_BUSINESS_DOMAIN')}
									initialValue={modeForm.businessDomainId}
									options={businessDomains.data}
									fieldIsLoading={businessDomains.reloading}
									valueKey="id"
									labelKey="name"
									isNumeric
									allowEmpty
								/>
							</SyncFormField>
						</Col>
					</Row>
				</FieldSetGap>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="entityType"
								onSync={onSync}
							>
								<SelectFormField
									name="entityType"
									title={t('TABLE_GENERAL_ENTITY_TYPE')}
									initialValue={modeForm.entityType}
									options={entityTypes}
									valueKey="value"
									labelKey="label"
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="partitioningStrategy"
								onSync={onSync}
							>
								<SelectFormField
									title={t('TABLE_PARTITIONING_STRATEGY')}
									name="partitioningStrategy"
									options={partitionStrategies}
									initialValue={modeForm.partitioningStrategy}
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						{hasPartitioningColumn ? (
							<Col size="medium">
								<SyncFormField
									unsyncedFields={unsyncedFields}
									disabled={!editMode}
									field="partitioningColumnId"
									onSync={onSync}
								>
									<SelectFormField
										title={t('TABLE_PARTITIONING_COLUMN')}
										name="partitioningColumnId"
										options={modeFormTable.columns?.filter(
											c =>
												!!c.name &&
												!!modeFormTable.constraints?.find(
													x => !!x.columns.find(y => y.code === c.code)
												)
										)}
										initialValue={modeForm.partitioningColumnId}
										valueKey="id"
										labelKey="name"
										isNumeric
										allowEmpty
										disabled={Boolean(selectedNodes)}
									/>
								</SyncFormField>
							</Col>
						) : (
							<Placeholder flexGrow={1} flexShrink={1} />
						)}
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="sequenceColumnCode"
								onSync={onSync}
							>
								<SelectFormField
									name="sequenceColumnCode"
									title={t('TABLE_GENERAL_SEQUENCE_COLUMN')}
									options={uniqBy(
										modeFormTable.columns?.filter(c => !!c?.name),
										column => column?.code
									)}
									disabled={Boolean(selectedNodes)}
									valueKey="code"
									labelKey="name"
									initialValue={modeForm.sequenceColumnCode}
									allowEmpty
									clearable
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="sequenceCode"
								onSync={onSync}
							>
								<TextFormFieldNoPadding
									title={t('TABLE_GENERAL_SEQUENCE')}
									name="sequenceCode"
									type="text"
									initialValue={modeForm.sequenceCode}
									readonly={true}
									disabled={getIsFieldDisabled('sequenceCode', mode)}
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="ownerId"
								onSync={onSync}
							>
								<SelectFormField
									name="ownerId"
									title={t('NODE_OWNER')}
									options={systemUsers.data || []}
									fieldIsLoading={systemUsers.loading}
									initialValue={modeForm.ownerId}
									valueKey="id"
									labelKey="name"
									isNumeric
									allowEmpty
									clearable
								/>
							</SyncFormField>
						</Col>
						<Col size="medium">
							<SyncFormField
								unsyncedFields={unsyncedFields}
								disabled={!editMode}
								field="retentionStrategy"
								onSync={onSync}
							>
								<SelectFormField
									name="retentionStrategy"
									title={t('TABLE_GENERAL_TABLE_RETENTION_STRATEGY')}
									initialValue={modeForm.retentionStrategy}
									options={strategies}
									valueKey="value"
									labelKey="label"
									allowEmpty
								/>
							</SyncFormField>
						</Col>
						{isKeepingFullStory ? (
							<Col size="medium">
								<Flex>
									<SyncFormFieldNoPadding
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyFullHistoryValue"
										onSync={onSync}
									>
										<TextFormFieldNoPadding
											title={t(
												'TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_LABEL'
											)}
											name="retentionStrategyFullHistoryValue"
											type="number"
											initialValue={modeForm.retentionStrategyFullHistoryValue}
											required
											disabled={getIsFieldDisabled(
												'retentionStrategyFullHistoryValue',
												mode
											)}
										/>
									</SyncFormFieldNoPadding>
									<SyncFormField
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyFullHistoryUnit"
										onSync={onSync}
									>
										<SelectFormField
											name="retentionStrategyFullHistoryUnit"
											title=" "
											initialValue={modeForm.retentionStrategyFullHistoryUnit}
											options={retentionTimes}
											valueKey="value"
											labelKey="label"
										/>
									</SyncFormField>
								</Flex>
							</Col>
						) : (
							<Placeholder />
						)}
						{isKeepingFullStoryAndEOM ? (
							<Col size="medium">
								<Flex>
									<SyncFormField
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyEomValue"
										onSync={onSync}
									>
										<TextFormFieldNoPadding
											title={t(
												'TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_AND_EOM_LABEL'
											)}
											name="retentionStrategyEomValue"
											type="number"
											initialValue={modeForm.retentionStrategyEomValue}
											required
											disabled={getIsFieldDisabled(
												'retentionStrategyEomValue',
												mode
											)}
										/>
									</SyncFormField>
									<SyncFormField
										unsyncedFields={unsyncedFields}
										disabled={!editMode}
										field="retentionStrategyEomUnit"
										onSync={onSync}
									>
										<SelectFormField
											name="retentionStrategyEomUnit"
											title=" "
											initialValue={modeForm.retentionStrategyEomUnit}
											options={retentionTimes}
											valueKey="value"
											labelKey="label"
										/>
									</SyncFormField>
								</Flex>
							</Col>
						) : (
							<Placeholder />
						)}
					</Row>
				</FieldSetGap>
				<CustomAttributes
					customAttributes={customAttributes}
					page={PageType.OVERVIEW_PAGE}
				/>
			</Form>
		</Container>
	)
}

const SyncFormFieldNoPadding = styled(SyncFormField)`
	padding: 0 !important;
`

const TextFormFieldNoPadding = styled(TextFormField)`
	padding: 0 !important;
`
