import React, { useState } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import {
	StereotypeUpdateDto,
	StereotypeCreateDto,
	StereotypeDto
} from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import {
	TextFormField,
	TextAreaFormField,
	SelectFormField,
	FormField
} from '@/components/UberForm'
import { useApi, useApiRequest } from '@/api/hooks'
import {
	createStereotype,
	getStereotypeCategories,
	getStereotypeTypes,
	updateStereotype
} from '@/api'
import { CodeInput } from '@/components'
import { FormValue } from '@/components/UberForm/Form'

type Props = {
	stereoType?: Partial<StereotypeDto>
	onSave: () => void
	onClose: () => void
}

export const StereoTypeModal = ({ stereoType, onSave, onClose }: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const types = useApi(getStereotypeTypes())
	const categories = useApi(getStereotypeCategories())

	const [stereoTypeName, setStereoTypeName] = useState(stereoType?.name || '')

	const handleSubmit = async (form: Partial<StereotypeDto>) => {
		const action =
			stereoType && stereoType.id
				? updateStereotype(stereoType.id, form as StereotypeUpdateDto)
				: createStereotype(form as StereotypeCreateDto)

		const result = await request(action)

		if (result) {
			onSave()
			onClose()
		}
	}

	const onNameChange = (v: FormValue) => {
		setStereoTypeName(v as string)
	}

	return (
		<>
			<ModalForm<StereotypeDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: '550px'
				}}
				initialValues={{
					id: stereoType?.id,
					name: stereoType?.name,
					code: stereoType?.code,
					type: stereoType?.type,
					category: stereoType?.category || 'USER_DEFINED',
					description: stereoType?.description,
					hasSubtypes: !!stereoType?.hasSubtypes,
					children: stereoType?.children || []
				}}
				header={stereoType ? t('UPDATE_STEREOTYPE') : t('CREATE_STEREOTYPE')}
				onSubmit={handleSubmit}
			>
				<TextFormField
					title={t('NAME')}
					name="name"
					required
					onChange={v => onNameChange(v)}
				/>
				<FormField name="code" title={t('CODE')}>
					<CodeInput nameValue={stereoTypeName} />
				</FormField>
				<SelectFormField
					title={t('TYPE')}
					name="type"
					options={types.data || []}
					fieldIsLoading={types.loading}
					valueKey="code"
					required
					labelKey="code"
					clearable={false}
				/>
				<SelectFormField
					title={t('CATEGORY')}
					name="category"
					required
					disabled={true}
					options={categories.data || []}
					fieldIsLoading={categories.loading}
					valueKey="code"
					labelKey="code"
					clearable={false}
				/>
				<TextAreaFormField title={t('DESCRIPTION')} name="description" />
			</ModalForm>
		</>
	)
}
