import { NewStructureDto, StructureDto, StructureDetailDto } from '@/api/models'
import { Button, DialogWrapper, ListEditTab, Loader } from '@/components'
import { isNotUndefined } from '@/utils/collections'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { AddModal } from '../../../../components/AddModal'
import { TabsActions } from '../../../../components/TabActions'
import { Container } from '../../components/StyledComponents'
import { useApi } from '@/api/hooks'
import { getNodesOfSpecificParent } from '@/api'
import { initApiCollection } from '@/store/modules/apiCollection/actions'
import { CollectionsProperties } from './Properties'
import { useOpenTab } from '../../../../components/Dependencies/hooks/useOpenTab'

type Props = {
	node: StructureDetailDto
}

export const Collections = ({ node }: Props) => {
	const { t } = useAppContext()
	const [isLoading, setLoading] = useState(false)
	const { openTabById } = useOpenTab()

	const dispatch = useAppDispatch()
	const children = useAppStore(state => state.node.children)
	const nodes = useAppStore(state => state.node.nodes)
	const nodeChildren = useApi(getNodesOfSpecificParent(node.id))

	const childCollections = useMemo(() => {
		const myChildren = children && children[node.id]

		return myChildren
			? myChildren
					.map(id => nodes[id])
					.filter(isNotUndefined)
					.filter(node => node.type === StructureDto.TypeEnum.API_COLLECTION)
			: []
	}, [children])

	useEffect(() => {
		setLoading(true)

		Promise.all(
			childCollections.map(node =>
				dispatch(initApiCollection({ nodeId: node.id }))
			)
		).then(() => setLoading(false))
	}, [childCollections])

	const onEdit = useCallback(
		(nodeId: number) => {
			openTabById(nodeId)
		},
		[openTabById]
	)

	const collectionDetails = childCollections?.map(node => {
		//FIXME - How should the data be gathered properly?

		const x = node as StructureDetailDto
		const jsonData = x?.data ? JSON.parse(x?.data!) : null

		return {
			node,
			jsonData,
			detail: nodeChildren[node?.id]
		}
	})

	const data = useMemo(
		() => ({
			collections:
				collectionDetails?.map(detail => ({
					...detail.jsonData,
					id: detail.node.id,
					parentStructureId: detail.node.parentStructureId
				})) ?? [],
			counter: collectionDetails?.length ?? 0
		}),
		[collectionDetails]
	)

	return (
		<>
			{node.canEdit && (
				<TabsActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<AddModal
									type={NewStructureDto.TypeEnum.API_COLLECTION}
									onClose={onClose}
									node={node}
								/>
							)
						}
					>
						{onClick => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActions>
			)}
			<Container>
				<LoaderContainer>
					<Loader loaded={!isLoading} absolute />
				</LoaderContainer>

				<ListEditTab
					node={node}
					data={data}
					editMode={false}
					itemsKey={'collections'}
					idCounterKey={'counter'}
					isRowOrderable
					properties={CollectionsProperties(t, onEdit)}
					tabKey="collections"
					onChange={() => undefined}
					createEmpty={() => ({} as any)}
					disablePanelProperties
				/>
			</Container>
		</>
	)
}

const LoaderContainer = styled.div`
	position: relative;
`
