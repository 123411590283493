import React, { useMemo, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faArrowDown,
	faArrowUp,
	faChevronLeft,
	faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { ItemPropertyType, PropertiesProps } from './types'
import { useAppContext, useAppStore } from '@/utils/hooks'
import { PropertyField } from './components/PropertyField'
import { errorGet, valueGet } from './utils'
import { clamp } from '@/utils/numbers'
import { StructureDto, TableColumn } from '@/api/models'
import {
	Buttons,
	ButtonSwitch,
	Container,
	OpenButton,
	OpenButtonIcon,
	OpenButtonText,
	ReadonlyValue,
	Title,
	ToggleButton,
	ValueInput,
	ValueLabel,
	ValueRow,
	Values
} from './styles'
import { CustomAttributes } from '@/pages/User/pages/Home/components/CustomAttributes/CustomAttributes'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { useCustomAttributesInitValues } from '@/pages/User/pages/Home/components/CustomAttributes/hooks/useCustomAttributes'
import { TableMode } from '@/store/modules/table/types'
import { Form, Reset } from '@/components/UberForm'
import { CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX } from '@/pages/User/pages/Home/components/CustomAttributes/constants'
import { TableData } from '@/api/schemas'
import { PageType } from '@/pages/User/pages/Home/components/CustomAttributes/types'

export const Properties = <T extends TableColumn>({
	item,
	index,
	properties,
	onChange,
	listOfValues,
	readonly,
	onHide,
	onShow,
	isHidden,
	errors,
	propertiesWidth,
	onSelect
}: PropertiesProps<T>) => {
	const { t } = useAppContext()
	const refContainer = useRef<HTMLDivElement>(null)

	const indexCurrent = index ?? -1

	const switcher = (switchIndex: -1 | 1) => {
		if (onSelect && item && listOfValues) {
			if (indexCurrent !== -1) {
				const selected = indexCurrent + switchIndex
				const selectedIndex = clamp(selected, 0, listOfValues.length - 1)
				onSelect(selectedIndex)
			}
		}
	}

	const {
		state: { node }
	} = useDetailTabContext()

	const tables = useAppStore(state => state.table.tables)
	const table = tables[node.id]

	const form = table?.form !== undefined ? table?.form : null

	const mode = table ? table.mode : TableMode.TABLE

	const refForm = useRef<Form<TableData>>(null)

	const { customAttributes } = useCustomAttributesInitValues(
		form!,
		StructureDto.TypeEnum.TABLE_COLUMN,
		mode
	)

	const getDefaultValues = useMemo(
		() =>
			item?.customAttributes?.reduce((initialValues, customAttribute) => {
				const fieldName = `${CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX}.${customAttribute.id}.${item.code}.${customAttribute.name}`

				return {
					...initialValues,
					[fieldName]: customAttribute.value
				}
			}, {} as Record<string, any>) ?? {},
		[item]
	)

	return (
		<>
			{isHidden && (
				<OpenButton onClick={onShow} role="button">
					<div>
						<OpenButtonText>{t('PROPERTIES_TITLE')}</OpenButtonText>
						<OpenButtonIcon>
							<FontAwesomeIcon icon={faChevronLeft} color="#888" />
						</OpenButtonIcon>
					</div>
				</OpenButton>
			)}
			<Container isHidden={!!isHidden} role="complementary" ref={refContainer}>
				<Title onClick={onHide}>
					<ToggleButton>
						<FontAwesomeIcon icon={faChevronRight} color="#888" />
					</ToggleButton>
					{t('PROPERTIES_TITLE')}
				</Title>
				{item && (
					<Values>
						{properties.map(prop => {
							const value = valueGet(prop, item)
							const error = errorGet(properties, prop, item, errors)

							if (prop.formatter) {
								return (
									<prop.formatter item={item} readonly={!!readonly} fromProps />
								)
							}

							return (
								(typeof prop.condition !== 'function' ||
									prop.condition(item, listOfValues)) &&
								prop.field && (
									<ValueRow
										key={prop.field}
										valueWidth={prop.propertiesWidth}
										propertiesWidth={propertiesWidth}
									>
										{prop.type !== ItemPropertyType.CHECKBOX && (
											<ValueLabel>{prop.label}</ValueLabel>
										)}
										<ValueInput>
											{!readonly ? (
												<PropertyField
													prop={prop}
													item={item}
													compact={false}
													listOfValues={listOfValues}
													value={value}
													readonly={!!readonly}
													onChange={onChange}
													fromProps
													error={error}
												/>
											) : (
												<ReadonlyValue>
													<PropertyField
														prop={prop}
														item={item}
														compact={false}
														value={value}
														listOfValues={listOfValues}
														readonly={readonly}
														onChange={onChange}
														fromProps
													/>
												</ReadonlyValue>
											)}
										</ValueInput>
									</ValueRow>
								)
							)
						})}

						{//TODO: remove this condition once the logic can be used for all the components
						node.type === 'TABLE' && (
							<ValueRow propertiesWidth={propertiesWidth}>
								<Form defaultValues={getDefaultValues} ref={refForm}>
									<CustomAttributes
										item={item}
										customAttributes={customAttributes}
										page={PageType.COLUMN_PAGE}
									/>
									<Reset
										values={getDefaultValues}
										isColumnPage
										itemId={item.id}
									>
										<></>
									</Reset>
								</Form>
							</ValueRow>
						)}

						{!readonly && (
							<Buttons>
								<ButtonSwitch
									size="sm"
									schema="primary"
									onClick={() => switcher(1)}
									icon={faArrowDown}
									disabled={indexCurrent >= listOfValues.length - 1}
								>
									{t('PROPERTIES_DOWN')}
								</ButtonSwitch>
								<ButtonSwitch
									size="sm"
									schema="primary"
									onClick={() => switcher(-1)}
									icon={faArrowUp}
									disabled={indexCurrent <= 0}
								>
									{t('PROPERTIES_UP')}
								</ButtonSwitch>
							</Buttons>
						)}
					</Values>
				)}
			</Container>
		</>
	)
}
