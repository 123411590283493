import { CustomAttributeComboDto } from '@/api/models'

export type CustomAttributesSwitcherProps<T> = {
	customAttribute: CustomAttributeComboDto
	item?: T
	page?: PageType | undefined
}

export type CustomAttributesProps<T> = {
	customAttributes: CustomAttributeComboDto[]
	item?: T
	page?: PageType | undefined
}

export enum PageType {
	OVERVIEW_PAGE = 'OVERVIEW_PAGE',
	COLUMN_PAGE = 'COLUMN_PAGE'
}
