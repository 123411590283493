import React, { useState } from 'react'
import { useAppContext } from '@/utils/hooks'
import { useMemo } from 'react'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import { ActionTypeEnum, CsvImporterStepEnum } from '../context/types'
import { Check } from './Check'
import { Preview } from './Preview'
import { Button } from '@/components'
import styled from 'styled-components'
import { Mapping } from './Mapping/Mapping'
import { useFields } from '../hooks/useFields'
import { StepTitle } from './styled'
import { getEnumKeys } from '@/utils/enum'
import { useStepErrors } from '../hooks/useStepErrors'
import { useMatchFields } from '../hooks/useMatchFields'

export const Wrapper = () => {
	const { t } = useAppContext()
	const [showErrors, setShowErrors] = useState(false)
	const errors = useStepErrors()
	useMatchFields()

	const {
		state: { step, resetImport },
		dispatch
	} = useCsvImporterContext()

	const fields = useFields()

	const renderStep = useMemo(() => {
		switch (step) {
			case CsvImporterStepEnum.PREVIEW:
				return <Preview />
			case CsvImporterStepEnum.TABLE_BASICS:
				return (
					<>
						<StepTitle>{t('TABLE_BASICS')}</StepTitle>
						<Mapping fields={fields[CsvImporterStepEnum.TABLE_BASICS]} />
					</>
				)
			case CsvImporterStepEnum.TABLE_EXTENDED:
				return (
					<>
						<StepTitle>{t('TABLE_EXTENDED')}</StepTitle>
						<Mapping fields={fields[CsvImporterStepEnum.TABLE_EXTENDED]} />
					</>
				)
			case CsvImporterStepEnum.COLUMNS:
				return (
					<>
						<StepTitle>{t('COLUMNS')}</StepTitle>
						<Mapping fields={fields[CsvImporterStepEnum.COLUMNS]} />
					</>
				)
			case CsvImporterStepEnum.CHECK:
				return <Check />
			default:
				return <>Unknown step</>
		}
	}, [step])

	return (
		<>
			<Actions>
				{step !== 0 && (
					<ButtonBack
						schema="primaryOutlined"
						onClick={() => {
							resetImport()
							setShowErrors(false)
							dispatch({ type: ActionTypeEnum.STEP_SET, step: step - 1 })
						}}
					>
						{t('BACK')}
					</ButtonBack>
				)}
				<Steps>
					{t('STEP')}
					<StepsCounter>
						{step + 1} {t('OF')} {getEnumKeys(CsvImporterStepEnum).length}
					</StepsCounter>
				</Steps>
				{step !== CsvImporterStepEnum.CHECK && (
					<Button
						onClick={() => {
							resetImport()

							if (errors) {
								setShowErrors(true)

								return
							}

							setShowErrors(false)
							dispatch({ type: ActionTypeEnum.STEP_SET, step: step + 1 })
						}}
					>
						{t('NEXT')}
					</Button>
				)}
			</Actions>
			{showErrors && errors?.map(e => <ErrorStep key={e}>{e}</ErrorStep>)}
			{renderStep}
		</>
	)
}

const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
`

const Steps = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 16px;
	margin-left: 16px;
`

const StepsCounter = styled.div`
	margin-top: 4px;
`

const ButtonBack = styled(Button)``

const ErrorStep = styled.div`
	color: ${props => props.theme.colors.danger.base};
`
