import { WriteMappingData, WriteMappingColumn } from '@/api/schemas'
import { ensureValidData } from '../helpers'
import { v4 } from 'uuid'

export const defaultWriteMappingData: Readonly<WriteMappingData> = {
	sourceItems: [],
	sourceItemsLastId: 0
}

export const getInitialWriteMappingData = (
	name: string,
	code = '',
	targetTableId: number,
	targetTableFullPath: string
) =>
	ensureValidData(
		{
			name,
			code,
			targetTableId,
			targetTableFullPath,
			description: '',
			columns: [],
			columnsLastId: 0
		},
		defaultWriteMappingData
	)

export const createEmptyWriteMappingColumn = (
	columns: WriteMappingColumn[]
): WriteMappingColumn => ({
	id: -1,
	uuid: v4(),
	order: Math.max(0, ...columns.map(c => c.order || 0)) + 1,
	columnCode: '',
	keyFlag: false,
	mapping: '',
	mappingDescription: '',
	mappingComment: '',
	updateFlag: false
})
