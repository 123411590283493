import styled, { css } from 'styled-components'

export const Flex = styled.div<{
	align?: 'center' | 'stretch'
}>`
	display: flex;
	${props => css`
		align-items: ${props.align || 'center'};
	`}
`

export const FlexItem = styled.div<{ flex?: number | string }>`
	${props => css`
		flex: ${props.flex || 1};
	`}
`
