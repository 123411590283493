import React, { useCallback, useMemo } from 'react'
import { Modal, ModalProps } from '@/components/Modal/Modal'
import { useAppContext } from '@/utils/hooks'
import { Button, LightTable, Loader } from '@/components'
import { StructureObjectDto, TechnicalColumnDto} from '@/api/models'
import { useApi } from '@/api/hooks'
import { getTablesByTechnicalColumn} from '@/api'
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { LightTableColumn } from '@/components/LightTable'
import { useOpenTab } from '@/pages/User/pages/Home/components/Dependencies/hooks/useOpenTab'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDownloadImpactCsv } from './hooks/useDownloadImpactCsv'

type Props = Partial<ModalProps> & {
	technicalColumn: TechnicalColumnDto
	children?: React.ReactNode
}

export const TechnicalImpactModal = ({ open, onClose, technicalColumn }: Props) => {
	const { t } = useAppContext()
	const { openTabById } = useOpenTab()
	const { push } = useHistory()

	const handleEdit = useCallback(
		(tableId?: number) => {
			if (tableId === undefined) {
				return
			}

			push('/')
			openTabById(tableId)
		},
		[openTabById, push]
	)

	const tables = useApi(getTablesByTechnicalColumn(technicalColumn.id||0))

	const { isDownloading, onDownloadCsv } = useDownloadImpactCsv()

	const columnsLightTable = useMemo<LightTableColumn<StructureObjectDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 175,
				formatter: cell => <ElipsisOverflow cell={cell} />
			},
			{
				field: 'code',
				title: t('CODE'),
				width: 175,
				formatter: cell => <ElipsisOverflow cell={cell} />
			},
			{
				field: 'structureId',
				width: 30,
				maxWidth: 30,
				formatter: cell => (
					<Action
						onClick={() => handleEdit(cell as number | undefined)}
						title={t('EDIT')}
					>
						<FontAwesomeIcon icon={faEdit} />
					</Action>
				)
			}
		],
		[handleEdit, t]
	)

	const isSomethingImpacted = (tables.data?.length ?? 0) > 0

	return (
		<Modal
			open={open}
			onClose={onClose}
			header={t('IMPACTED_TABLES')}
			stickyFooter
			footer={
				<Button icon={faCheck} onClick={onClose}>
					{t('CLOSE')}
				</Button>
			}
			contentStyle={{
				width: 700,
				minWidth: 700
			}}
			resizable
		>
			<>
				<Loader loaded={!tables.loading} absolute />
				{!tables.loading && (
					<>
						<div style={{ marginBottom: '10px', display: 'flex' }}>
							{t('IMPACTED_TABLES')}: {tables.data?.length ?? ''}
							{isSomethingImpacted && (
								<Button
									onClick={() => onDownloadCsv(technicalColumn)}
									isLoading={isDownloading}
									disabled={isDownloading}
									style={{ marginLeft: 'auto' }}
								>
									{t('DOWNLOAD_IMPACT_AS_CSV')}
								</Button>
							)}
						</div>

						{isSomethingImpacted && (
							<LightTable<StructureObjectDto>
								data={tables.data || []}
								columns={columnsLightTable}
								keyField={'structureId'}
							/>
						)}
					</>
				)}
			</>
		</Modal>
	)
}

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`

const CellWrapper = styled.div`
	overflow: hidden;
`

const CellValue = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

type PropsRow = {
	cell?: string | number
}

const ElipsisOverflow = ({ cell }: PropsRow) => (
	<CellWrapper title={`${cell}`}>
		<CellValue>{cell}</CellValue>
	</CellWrapper>
)
