import { StructureDto, StructureDetailDto } from '@/api/models'
import { MappingData } from '@/api/schemas'
import { MappingTab, MappingDataForm } from './types'
import {
	MAPPING_INIT,
	MAPPING_SAVE,
	MAPPING_UPDATE,
	MAPPING_SELECT_TAB
} from './constants'
import { apiCallAction, AppDispatch, UpdateDeepPartial } from '@/store/utils'
import { updateDataNode } from '@/api'
import { inflateMappingPermission } from './helpers'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { loadNodeOrHistoryVersion } from '../node/utils'

interface InitMapping {
	type: typeof MAPPING_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

interface SaveMapping {
	type: typeof MAPPING_SAVE
	payload: void
	metadata: {
		node: StructureDto
	}
}

interface UpdateMapping {
	type: typeof MAPPING_UPDATE
	node: StructureDto
	update: UpdateDeepPartial<MappingDataForm>
}

interface SelectMappingTab {
	type: typeof MAPPING_SELECT_TAB
	node: StructureDto
	tab: MappingTab
}

export const initMapping = ({
	nodeId,
	editMode = false,
	force = false,
	version,
	envId
}: InitDataParams) => async (dispatch: AppDispatch) => {
	const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

	dispatch({
		type: MAPPING_INIT,
		node,
		editMode,
		force
	} as InitMapping)
}

export const updateMapping = (
	node: StructureDto,
	update: UpdateDeepPartial<MappingDataForm>
): Actions => ({
	type: MAPPING_UPDATE,
	node,
	update
})

export const saveMapping = (node: StructureDto) =>
	apiCallAction(
		getState => {
			const mapping = getState().mapping.mappings[node.id]

			if (!mapping) {
				throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
			}

			const formData: MappingData = {
				...mapping.form,
				columns: (mapping.form.columns || []).filter(c => !!c.columnCode),
				objectPermissions: (mapping.form.objectPermissions || [])
					.filter(c => !!c.userName)
					.map(inflateMappingPermission)
			}

			return updateDataNode(node.id, {
				data: JSON.stringify(formData)
			})
		},
		MAPPING_SAVE,
		{ node }
	)

export const selectMappingTab = (
	node: StructureDto,
	tab: MappingTab
): Actions => ({
	type: MAPPING_SELECT_TAB,
	node,
	tab
})

export type Actions =
	| InitMapping
	| SaveMapping
	| UpdateMapping
	| SelectMappingTab
