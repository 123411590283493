import React, { useCallback } from 'react'
import { selectTab, openTab, closeTab } from '@/store/modules/tab/actions'
import { EntityTypeIcon } from '../../EntityTypeIcon'
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { StructureDto } from '@/api/models'
import { useAppDispatch, useAppContext } from '@/utils/hooks'
import { OpenedTab } from '@/store/modules/tab/types'
import { ContextMenuWrapper } from '@/components/ContextMenu/ContextMenuWrapper'
import { ContextMenu } from '@/components'
import {
	ContextMenuItems,
	ContextMenuItem
} from '@/components/ContextMenu/ContextMenu'

type Props = {
	node: StructureDto | undefined
	tab: OpenedTab
	isSelected: boolean
	isTemporary: boolean
	onConfirm: (nodes: StructureDto[]) => void
	onCloseOther: (node: StructureDto) => void
	onCloseAll: () => void
	isVertical?: boolean
}

const handleMouseDown = (event: React.MouseEvent) => {
	if (event.nativeEvent.detail > 1) {
		event.stopPropagation()
		event.preventDefault()
	}
}

export const HeadItem = ({
	node,
	tab,
	isSelected,
	isTemporary,
	onConfirm,
	onCloseOther,
	onCloseAll,
	isVertical
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const closeItem = useCallback((tab: OpenedTab, node: StructureDto) => {
		tab.editMode ? onConfirm([node]) : dispatch(closeTab(node.id))
	}, [])

	const handleClick = useCallback(() => {
		node && dispatch(selectTab(node))
	}, [node])

	const handleDblClick = useCallback(() => {
		node && dispatch(openTab(node, false))
	}, [node])

	const handleMouseUp = useCallback(
		(e: React.MouseEvent) => {
			if (e.button === 1) {
				e.stopPropagation()
				node && closeItem(tab, node)
			}
		},
		[node, tab, closeItem]
	)

	const handleCloseClick = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation()
			node && closeItem(tab, node)
		},
		[node, tab, closeItem]
	)

	const handleCloseOtherClick = useCallback(() => {
		onCloseOther(node as StructureDto)
	}, [onCloseOther, node])

	const contextMenu = useCallback(
		(opened, x, y) => (
			<ContextMenu opened={opened} x={x} y={y}>
				<ContextMenuItems minWidth={150}>
					<ContextMenuItem onClick={handleCloseClick}>
						{t('CLOSE_TAB')}
					</ContextMenuItem>
					<ContextMenuItem onClick={handleCloseOtherClick}>
						{t('CLOSE_OTHER_TABS')}
					</ContextMenuItem>
					<ContextMenuItem onClick={onCloseAll}>
						{t('CLOSE_ALL_TABS')}
					</ContextMenuItem>
				</ContextMenuItems>
			</ContextMenu>
		),
		[handleCloseClick, onCloseAll]
	)

	return node ? (
		<ContextMenuWrapper menu={contextMenu}>
			<TabHead
				role="tab"
				aria-selected={isSelected}
				isSelected={isSelected}
				isTemporary={isTemporary}
				isVertical={!!isVertical}
				key={`${node.id}`}
				onClick={handleClick}
				onDoubleClick={handleDblClick}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				title={node.name}
			>
				<EntityTypeIcon type={node.type} />
				<TabHeadText>
					{node.name || '(unnamed)'}
					{tab.editMode && <EditIcon icon={faCircle} />}
				</TabHeadText>
				<TabHeadClose role="button" onClick={handleCloseClick}>
					<FontAwesomeIcon icon={faTimes} color="#999" />
				</TabHeadClose>
			</TabHead>
		</ContextMenuWrapper>
	) : (
		<TabHead
			role="tab"
			isSelected={isSelected}
			isTemporary={isTemporary}
			isVertical={!!isVertical}
		>
			...
		</TabHead>
	)
}

const TabHeadClose = styled.div`
	margin-left: 2px;
	transition: 0.2s opacity;
`

const EditIcon = styled(FontAwesomeIcon)`
	color: ${props => props.theme.colors.button.success.background};
	margin-left: 5px;
	margin-bottom: 1px;
	font-size: 75%;
`

const TabHead = styled.div<{
	isSelected: boolean
	isTemporary: boolean
	isVertical: boolean
}>`
	padding: 8px 10px;
	border-radius: 0;	cursor: pointer;
	overflow: visible;
	position: relative;
	z-index: ${props => (props.isSelected ? 2 : 1)};
	background-color: ${props => (props.isSelected ? '#fff' : '#f5f5f5')};
	display: flex;
	align-items: center;
	flex-grow: 0;
	transition: 0.2s background-color;
	min-height: 0;

	${props =>
		!props.isVertical &&
		css`
			margin-bottom: -1px;
			border-right: 1px solid ${props.theme.colors.layout.borderColor};
			border-top: 1px solid ${props.theme.colors.layout.borderColor};
			border-bottom: 1px solid
				${props.isSelected ? '#fff' : props.theme.colors.layout.borderColor};
		`}


	${props =>
		props.isSelected &&
		css`
			box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.075);
		`}

	${props =>
		props.isTemporary &&
		css`
			font-style: italic;
		`}

		${TabHeadClose} {
		opacity: ${props => (props.isSelected ? 0.75 : 0.5)};		
	}

	&:hover {
		background-color: ${props => (props.isSelected ? '#fff' : '#f8f8f8')};

		${TabHeadClose} {
			opacity: 1;		
		}	
	}
`

const TabHeadText = styled.span`
	padding-left: 5px;
	padding-right: 5px;
	user-select: none;
	white-space: nowrap;
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
`
