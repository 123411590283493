import { NativeMap } from '@/utils/collections'
import { OpenedWriteMappingData, WriteMappingTab } from './types'
import { Actions } from './actions'
import { GeneralNodeActions } from '../tab/actions'
import { WriteMappingData } from '@/api/schemas'
import { TAB_CLOSE } from '../tab/constants'
import {
	MAPPING_WRITE_INIT,
	MAPPING_WRITE_SAVE,
	MAPPING_WRITE_UPDATE,
	MAPPING_WRITE_SELECT_TAB
} from './constants'
import { updateTabData } from '@/store/utils'
import { getInitialWriteMappingData, defaultWriteMappingData } from './helpers'
import { ensureValidData } from '../helpers'

type State = Readonly<typeof initialState>

const initialState = {
	mappings: {} as NativeMap<OpenedWriteMappingData>
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const mappings = { ...state.mappings }
			delete mappings[action.nodeId]

			return {
				...state,
				mappings
			}
		}

		case MAPPING_WRITE_INIT: {
			const { node, editMode, force } = action

			const previous = state.mappings[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: WriteMappingData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultWriteMappingData)
				: getInitialWriteMappingData(node.name, '', -1, '')

			if (!data.columns) {
				data.columns = []
			}

			if (typeof data.columnsLastId !== 'number') {
				data.columnsLastId = Math.max(0, ...data.columns.map(c => c.id))
			}

			return {
				...state,
				mappings: {
					...state.mappings,
					[node.id]: {
						form: data,
						original: data,
						stereotypeId: node.stereotypeId,
						tab: previous ? previous.tab : WriteMappingTab.Overview,
						dirty: false,
						parsedEditMode: editMode
					} as OpenedWriteMappingData
				}
			}
		}

		case MAPPING_WRITE_SAVE: {
			const { node } = action.metadata

			return {
				...state,
				mappings: updateTabData(state.mappings, node.id, node => ({
					...node,
					dirty: false
				}))
			}
		}

		case MAPPING_WRITE_UPDATE: {
			const { node, update } = action

			return {
				...state,
				mappings: updateTabData(state.mappings, node.id, node => ({
					...node,
					form: {
						...node.form,
						...update
					},
					dirty: true
				}))
			}
		}

		case MAPPING_WRITE_SELECT_TAB: {
			return {
				...state,
				mappings: updateTabData(state.mappings, action.node.id, {
					tab: action.tab
				})
			}
		}

		default:
			return state
	}
}
