import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { selectSystemTab } from '@/store/modules/system/actions'
import { GrowTabs } from '../../TableDetail/components/StyledComponents'
import { OpenedSystemData, SystemTab } from '@/store/modules/system/types'
import { StructureDto, DomainDto } from '@/api/models'
import { SystemData } from '@/api/schemas'
import { Overview } from '../pages/Overview/Overview'
import { Constants } from '../pages/Constants/Constants'
import { Environments } from '../pages/Environments/Environments'
import { Lookups } from '../pages/Lookups/Lookups'
import { Users } from '../pages/Users/Users'
import { useAppContext } from '@/utils/hooks'
import { UpdateDeepPartial } from '@/store/utils'
import { Validation } from '../../../components/Validation/Validation'
import { Releases } from '../pages/Releases/Releases'
import { Dependencies } from '../../../components/Dependencies/Dependencies'

type Props = ReturnType<typeof mapDispatchToProps> & {
	node: StructureDto
	data: OpenedSystemData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<SystemData>) => void
	domains: DomainDto[]
	systemNodeId: number
}

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ selectSystemTab }, dispatch)

const SystemTabsComponent = ({
	selectSystemTab,
	node,
	data,
	editMode,
	onChange,
	domains,
	systemNodeId
}: Props) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={tab => selectSystemTab(node, tab.id as SystemTab)}
			selectedTabId={data.tab}
			tabs={[
				{
					id: SystemTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							key={SystemTab.Overview}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
							systemNodeId={systemNodeId}
						/>
					)
				},
				{
					id: SystemTab.Constants,
					title: t('TAB_CONSTANTS'),
					content: (
						<Constants
							key={SystemTab.Constants}
							node={node}
							data={data}
							editMode={editMode}
							domains={domains}
							onChange={onChange}
						/>
					)
				},
				{
					id: SystemTab.Lookups,
					title: t('TAB_LOOKUPS'),
					content: (
						<Lookups
							key={SystemTab.Lookups}
							node={node}
							data={data}
							editMode={editMode}
							domains={domains}
							onChange={onChange}
						/>
					)
				},
				{
					id: SystemTab.Environments,
					title: t('TAB_ENVIRONMENTS'),
					content: (
						<Environments
							key={SystemTab.Environments}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					)
				},
				{
					id: SystemTab.Releases,
					title: t('RELEASES'),
					content: (
						<Releases
							key={SystemTab.Releases}
							node={node}
							editMode={editMode}
							data={data}
						/>
					)
				},
				{
					id: SystemTab.Users,
					title: t('TAB_USERS'),
					content: (
						<Users
							key={SystemTab.Users}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					)
				},
				{
					id: SystemTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={SystemTab.Validation} node={node} />
				},
				{
					id: SystemTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={SystemTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					)
				}
			]}
		/>
	)
}

export const SystemTabs = connect(null, mapDispatchToProps)(SystemTabsComponent)
