import { getSystemEnvironments } from '@/api'
import { useApi } from '@/api/hooks'
import { Flex } from '@/components/Layout'
import { Select } from '@/components/UberForm'
import { useAppContext } from '@/utils/hooks'
import { toLocaleString } from '@/utils/numbers'
import React, { useEffect, useRef } from 'react'
import { useDetailTabContext } from '../context/DetailTabContext'
import { ActionTypeEnum } from '../context/types'

type Props = {
	replacing: boolean
}

export const SelectEnv = ({ replacing }: Props) => {
	const { t } = useAppContext()
	const refInitEnvId = useRef(false)

	const {
		state: { node, systemNodeId, envId },
		dispatch
	} = useDetailTabContext()

	const envs = useApi(getSystemEnvironments(systemNodeId))

	useEffect(() => {
		return () => {
			dispatch({
				type: ActionTypeEnum.DETAIL_TAB_UPDATE,
				update: {
					envId: undefined
				}
			})
		}
	}, [dispatch])

	useEffect(() => {
		if (refInitEnvId.current) {
			return
		}

		if (envs.data) {
			refInitEnvId.current = true

			dispatch({
				type: ActionTypeEnum.DETAIL_TAB_UPDATE,
				update: {
					envId: envs.data.find(env => env.production)?.frontendId
				}
			})
		}
	}, [dispatch, envs.data])

	return (
		<>
			<Flex style={{ marginRight: 6 }}>
				{node.deploymentDto ? (
					<>
						{t('DATE_OF_DEPLOYMENT')}:&nbsp;
						{toLocaleString(node.deploymentDto.created)}
					</>
				) : (
					<>{t('NO_DEPLOYMENT_FOR_SELECTED_ENV')}</>
				)}
			</Flex>
			<div style={{ width: 100, marginRight: 5 }}>
				<Select
					name="env"
					value={envId}
					options={envs.data}
					loading={envs.loading}
					valueKey="frontendId"
					labelKey="name"
					clearable={false}
					isNumeric
					onChange={value => {
						dispatch({
							type: ActionTypeEnum.DETAIL_TAB_UPDATE,
							update: {
								envId: value
							}
						})
					}}
					disabled={replacing}
				/>
			</div>
		</>
	)
}
