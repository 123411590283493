/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useState, useMemo } from 'react'
import { Modal } from '@/components/Modal/Modal'
import { Button, Loader } from '@/components'
import { faUpload, faTimes, faServer } from '@fortawesome/free-solid-svg-icons'
import {
	StructureDto,
	MassExportConfigDto,
	StructureObjectPair,
	ObjectSettingsDto,
	ReleaseEnvironmentDto
} from '@/api/models'
import { useAppContext } from '@/utils/hooks'

import { CheckBox, SelectFormField } from '@/components/UberForm'
import { useApi, useApiRequest } from '@/api/hooks'
import {
	generateMassExportFile,
	getAllObjectSettings,
	generateMassExportPushGit
} from '@/api'
import FileSaver from 'file-saver'
import styled from 'styled-components'
import { Legend, FieldSet } from '@/components/Layout'
import { NativeMap } from '@/utils/collections'
import { MassExportSortingEnum } from '@/typings/enum/MassExportSortingEnum'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'

type Props = {
	env?: ReleaseEnvironmentDto
	onClose: () => void
	node?: StructureDto
	title?: string
	selectedNodes?: number[]
	saveTitle?: string
	onExport?: (massExportConfigDto: MassExportConfigDto) => void
	open?: boolean
	enableExportStrategy?: boolean
}

export const MassSqlExportModal = ({
	env,
	node,
	onClose,
	title,
	selectedNodes,
	saveTitle,
	onExport,
	open,
	enableExportStrategy = true
}: Props) => {
	const { t } = useAppContext()

	const objectSettings = useApi(getAllObjectSettings())

	const request = useApiRequest()

	const [massExportStrategy, setMassExportStrategy] = useState(
		MassExportConfigDto.MassExportStrategyEnum.SINGLE_FILE
	)

	const [massExportSorting, setMassExportSorting] = useState(
		MassExportSortingEnum.DEFAULT
	)

	const [selectedObjectSettings, setSelectedObjectSettings] = useState(
		[] as number[]
	)

	const [ignoreErrors, setIgnoreErrors] = useState(false)

	const [exporting, setExporting] = useState(false)
	const [pushingGit, setPushingToGit] = useState(false)

	const handleMassGitPush = async () => {
		setPushingToGit(true)

		const massExportConfigDto = {
			massExportStrategy: massExportStrategy,
			massExportSorting: massExportSorting,
			structureObjectPairs: getStructureObjectPairs(),
			structureIds: selectedNodes,
			environmentId: env?.id!
		} as MassExportConfigDto

		await request(
			generateMassExportPushGit(node?.id as number, massExportConfigDto)
		)

		setPushingToGit(false)
	}

	const getStructureObjectPairs = (): StructureObjectPair[] =>
		selectedObjectSettings.map(osId => {
			const os = objectSettings?.data?.find(o => o.id == osId)

			return {
				objectType: os,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				structureType: os?.structureType as any
			}
		})

	const handleMassExport = async () => {
		const massExportConfigDto: MassExportConfigDto = {
			massExportStrategy: massExportStrategy,
			massExportSorting: massExportSorting,
			structureObjectPairs: getStructureObjectPairs(),
			structureIds:
				selectedNodes?.length ?? 0 > 0 ? selectedNodes : node ? [node.id] : []
		}

		if (onExport) {
			onExport(massExportConfigDto)

			return
		}

		setExporting(true)

		const { data, error } = await request(
			generateMassExportFile(massExportConfigDto, { ignoreErrors })
		)

		if (error) {
			setExporting(false)

			return
		}

		let fileName, type

		if (
			massExportStrategy === MassExportConfigDto.MassExportStrategyEnum.SINGLE_FILE
		) {
			fileName = 'export.txt'
			type = 'application/text'
		} else {
			fileName = 'export.zip'
			type = 'application/zip'
		}

		const blob = new Blob([new Uint8Array(data as ArrayBuffer)], {
			type
		})

		FileSaver.saveAs(blob, fileName)

		setExporting(false)
	}

	const massExportStrategies = useMemo(
		() => [
			{
				label: t('SINGLE_FILE'),
				value: MassExportConfigDto.MassExportStrategyEnum.SINGLE_FILE
			},
			{
				label: t('ZIP_PER_STRUCTURE'),
				value: MassExportConfigDto.MassExportStrategyEnum.ZIP_PER_STRUCTURE
			},
			{
				label: t('ZIP_OWNER_FOLDER_PER_OBJECT_TYPE'),
				value:
					MassExportConfigDto.MassExportStrategyEnum
						.ZIP_OWNER_FOLDER_PER_OBJECT_TYPE
			},
			{
				label: t('ZIP_PER_OWNER_AND_OBJECT_TYPE'),
				value:
					MassExportConfigDto.MassExportStrategyEnum.ZIP_PER_OWNER_AND_OBJECT_TYPE
			}
		],
		[t]
	)

	const massExportSortingOptions = useMemo(
		() => [
			{
				label: t('MASS_DOWNLOAD_SORTING_DEFAULT'),
				value: MassExportSortingEnum.DEFAULT
			},
			{
				label: t('SORT_BY_NAME_ASC'),
				value: MassExportSortingEnum.SORT_BY_NAME_ASC
			},
			{
				label: t('SORT_BY_TYPE_AND_NAME_ASC'),
				value: MassExportSortingEnum.SORT_BY_TYPE_AND_NAME_ASC
			}
		],
		[t]
	)

	const objectSettingsMap = (objectSettings?.data || []).reduce(
		(pVal, cVal) => {
			const vKey = cVal.structureType?.toString() || ''

			if (pVal[vKey]) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				pVal[vKey]!.push(cVal)
			} else {
				pVal[vKey] = [cVal]
			}

			return pVal
		},
		{} as NativeMap<ObjectSettingsDto[]>
	)

	const handleGroupClick = (stKey: string) => {
		const ossIds =
			objectSettings?.data
				?.filter(os => os.structureType === stKey)
				.map(os => os.id) || []

		const isAllChecked = ossIds.every(ossId =>
			selectedObjectSettings.some(oId => oId === ossId)
		)

		if (isAllChecked) {
			setSelectedObjectSettings(
				selectedObjectSettings.filter(os => !ossIds.some(o => o === os))
			)
		} else {
			setSelectedObjectSettings([...selectedObjectSettings, ...ossIds])
		}
	}

	const handleChecked = (isChecked: boolean, id: number) => {
		if (isChecked) {
			setSelectedObjectSettings([...selectedObjectSettings, id])
		} else {
			setSelectedObjectSettings(
				selectedObjectSettings.filter(osId => osId !== id)
			)
		}
	}

	return (
		<Modal
			open={open ?? true}
			onClose={onClose}
			contentStyle={{ width: 600 }}
			header={title ? title : t('MASS_DOWNLOAD')}
			footer={
				<>
					<Button
						schema="primary"
						icon={faUpload}
						disabled={
							exporting ||
							objectSettings.loading ||
							!selectedObjectSettings.length
						}
						isLoading={exporting || objectSettings.loading}
						onClick={handleMassExport}
					>
						{saveTitle ?? t('MASS_DOWNLOAD_BUTTON')}
					</Button>

					<Button schema="transparent" onClick={onClose} icon={faTimes}>
						{t('CANCEL')}
					</Button>

					{env && (
						<Button
							schema="primary"
							icon={faServer}
							disabled={
								pushingGit ||
								objectSettings.loading ||
								!selectedObjectSettings.length
							}
							isLoading={pushingGit || objectSettings.loading}
							onClick={handleMassGitPush}
						>
							{t('PUSH_TO_GIT')}
						</Button>
					)}
				</>
			}
		>
			<Loader loaded={true} absolute />

			<ObjectTypesFieldSet>
				<StyledLegend>{t('OBJECT_SETTINGS')}</StyledLegend>

				{Object.keys(objectSettingsMap).map(stKey => (
					<div key={stKey}>
						<StructureTypeButton
							onClick={() => handleGroupClick(stKey)}
							schema="transparent"
						>
							{stKey}
						</StructureTypeButton>

						{objectSettingsMap[stKey]?.map(os => {
							return (
								<StyledCheckbox
									name={`${stKey}--${os.code}`}
									key={os.id}
									title={os.code}
									value={selectedObjectSettings.some(oId => oId === os.id)}
									onChange={val => handleChecked(val as boolean, os.id)}
								/>
							)
						})}
					</div>
				))}
			</ObjectTypesFieldSet>

			{enableExportStrategy && (
				<SelectFormField
					name="massExportStrategy"
					formless
					title={t('MASS_DOWNLOAD_STRATEGY')}
					options={massExportStrategies || []}
					valueKey="value"
					labelKey="label"
					onChange={val =>
						setMassExportStrategy(
							val as MassExportConfigDto.MassExportStrategyEnum
						)
					}
					initialValue={massExportStrategy}
				/>
			)}

			<SelectFormField
				name="massExportSorting"
				formless
				title={t('MASS_DOWNLOAD_SORTING')}
				options={massExportSortingOptions || []}
				valueKey="value"
				labelKey="label"
				onChange={val => setMassExportSorting(val)}
				initialValue={massExportSorting}
			/>

			<CheckboxFormField
				name="ignoreErrors"
				title={t('IGNORE_ERRORS')}
				onChange={val => setIgnoreErrors(val)}
			/>
		</Modal>
	)
}

const ObjectTypesFieldSet = styled(FieldSet)`
	margin-bottom: 15px;
	padding-bottom: 13px;
	max-height: 400px;
	overflow-x: auto;
	padding-top: 0;
`

const StyledCheckbox = styled(CheckBox)`
	margin-left: 18px;
`

const StyledLegend = styled(Legend)`
	letter-spacing: 0;
`

const StructureTypeButton = styled(Button)`
	padding-top: 12px;
	font-size: 105%;
	padding-bottom: 6px;
	padding-left: 6px;
`
