import styled, { css } from 'styled-components'

export const Placeholder = styled.div<{
	flexGrow?: number
	flexShrink?: number
	flexBasis?: string
}>`
	${props => css`
		flex-grow: ${props.flexGrow || 'initial'};
		flex-shrink: ${props.flexShrink || 'initial'};
		flex-basis: ${props.flexBasis || 'initial'};
	`}
`
