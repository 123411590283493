import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { StructureDto, NewStructureDto } from '@/api/models'
import { Button, Loader, DialogWrapper } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Flex } from '@/components/Layout/Flex'
import { openTab } from '@/store/modules/tab/actions'
import { ThCell, Empty } from '@/components/Layout'
import { useAppContext, useAppStore, useAppDispatch } from '@/utils/hooks'
import { Workflow } from './Workflows/components/Workflow'
import { Container } from '../../TableDetail/components/StyledComponents'
import { AddModal } from '../../../components/AddModal'
import { TabsActions } from '../../../components/TabActions'
import { isNotUndefined } from '@/utils/collections'
import { initWorkflow } from '@/store/modules/workflow/actions'
import { WorkflowsProps } from '@/pages/User/pages/Home/types'

export const Workflows = ({ node }: WorkflowsProps) => {
	const { t } = useAppContext()
	const refLoaded = useRef(false)
	const [isLoading, setLoading] = useState(false)

	const dispatch = useAppDispatch()
	const children = useAppStore(state => state.node.children)
	const nodes = useAppStore(state => state.node.nodes)
	const workflows = useAppStore(state => state.workflow.workflows)

	const childWorkflows = useMemo(() => {
		const myChildren = children && children[node.id]

		return myChildren
			? myChildren
					.map(id => nodes[id])
					.filter(isNotUndefined)
					.filter(node => node.type === StructureDto.TypeEnum.WORKFLOW)
			: []
	}, [children, node.id, nodes])

	const getInitWf = async (abortController: AbortController): Promise<void> => {
		setLoading(true)

		try {
			await Promise.all(
				childWorkflows.map(node =>
					dispatch(initWorkflow({ nodeId: node.id, abortController }))
				)
			)

			refLoaded.current = true
			setLoading(false)
		} catch (e) {
			console.log('The request was cancelled by the user')
		}
	}

	useEffect(() => {
		const abortController = new AbortController()

		if (refLoaded.current) {
			return
		}

		getInitWf(abortController)

		return () => {
			abortController.abort()
		}
	}, [])

	const handleEdit = useCallback(
		(node: StructureDto) => {
			dispatch(openTab(node, false))
		},
		[dispatch]
	)

	const workflowDetails = childWorkflows.map(node => ({
		node,
		detail: workflows[node.id]
	}))

	return (
		<>
			{node.canEdit && (
				<TabsActions>
					<Flex align="center">
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<AddModal
										type={NewStructureDto.TypeEnum.WORKFLOW}
										onClose={onClose}
										node={node}
									/>
								)
							}
						>
							{onClick => (
								<Button size="sm" icon={faPlus} onClick={onClick}>
									{t('ADD_NEW')}
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				</TabsActions>
			)}
			<Container>
				<LoaderContainer>
					<Loader loaded={!isLoading} absolute />
				</LoaderContainer>

				<Flex align="stretch">
					<ThCell>{t('WORKFLOW_NAME')}</ThCell>
					<ThCell>{t('WORKFLOW_CODE')}</ThCell>
					<Actions />
				</Flex>

				{workflowDetails.map(workflow => (
					<Workflow
						key={workflow.node.id}
						node={workflow.node}
						workflow={workflow.detail}
						onEdit={handleEdit}
					/>
				))}

				{workflowDetails.length === 0 && <Empty>{t('NOTHING_HERE')}</Empty>}
			</Container>
		</>
	)
}

const Actions = styled.div`
	flex: 1;
	width: 16px;
`

const LoaderContainer = styled.div`
	position: relative;
`
