/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, RefObject, useMemo } from 'react'
import { ItemProperty, ItemPropertyType } from '../types'
import { TableIndexColumn } from '@/api/schemas'
import { FormValue } from '@/components/UberForm/Form'
import { CodeInputFormless } from '@/components/CodeInput/CodeInput'
import {
	ColumnsPicker,
	ColumnsPickerValue
} from '@/components/ColumnsPicker/ColumnsPicker'
import {
	listValue,
	listValues
} from '@/pages/User/pages/Home/pages/TableDetail/utils'
import { SortIcon } from '@/pages/User/pages/Home/pages/TableDetail/components/SortIcon'
import { RegExpValidatedInputFormless } from '@/components/RegExpValidatedInput/RegExpValidatedInput'
import { Checkbox } from './inputs/Checkbox'
import { ForeignKey } from './inputs/ForeignKey'
import { TextArea } from './inputs/TextArea'
import { Select, ErrorTooltip, FormInput } from './PropertyFieldStyles'
import { Position } from '@/components/Tooltip/Tooltip'
import { ValueWrapper } from './ValueWrapper'
import { AceEditor } from './inputs/AceEditor'

interface Props<T> {
	prop: ItemProperty<T>
	item: T
	listOfValues: T[]
	readonly: boolean
	onChange?: (
		item: T,
		key: string,
		value: FormValue | ColumnsPickerValue
	) => void
	value: any | null | undefined
	compact: boolean
	fromProps?: boolean
	error?: string
	autoFocus?: boolean
	refCheckbox?: RefObject<HTMLInputElement>
	onDisableEditing?: () => void
}

export const PropertyField = <T,>({
	prop,
	item,
	value,
	readonly,
	listOfValues,
	onChange,
	fromProps,
	error,
	compact,
	autoFocus,
	refCheckbox,
	onDisableEditing
}: Props<T>) => {
	const [focused, setFocused] = useState(false)
	const onFocus = useCallback(() => setFocused(true), [])

	const onBlur = useCallback(() => {
		setFocused(false)
	}, [])

	const handleChange = useCallback(
		(name: string, value: string | boolean) => {
			item && onChange && onChange(item, name, value)
		},
		[item, onChange]
	)

	const handleFieldChange = useCallback(
		(value: FormValue, field: string) => {
			item && onChange && onChange(item, field, value)
		},
		[item, onChange]
	)

	const handleColumnsChange = useCallback(
		(value: ColumnsPickerValue, field: string) => {
			item && onChange && onChange(item, field, value)
		},
		[item, onChange]
	)

	const handleCodeChange = useCallback(
		value => {
			prop.field && handleFieldChange(value, prop.field)
		},
		[prop.field, handleFieldChange]
	)

	const options = useMemo(() => {
		if (typeof prop.options === 'function') {
			return prop.options(item)
		}

		if (Array.isArray(prop.options)) {
			return prop.options
		}

		return []
	}, [prop, item])

	const isDisabled = useMemo(
		() =>
			typeof prop.disabled === 'function'
				? prop.disabled(item, listOfValues)
				: typeof prop.disabled === 'boolean'
				? prop.disabled
				: false,
		[item, listOfValues, prop]
	)

	const regExp = prop.regExp?.(item)

	if (prop.type === ItemPropertyType.CHECKBOX) {
		// render Checkbox because we want to change it on click in both readonly and editing modes
		return (
			<Checkbox
				checked={typeof value === 'boolean' ? value : false}
				name={prop.field}
				onChange={e => handleChange(e.target.name, e.target.checked)}
				disabled={isDisabled}
				onFocus={onFocus}
				onBlur={onBlur}
				error={error}
				label={fromProps ? prop.label : ''}
				focused={focused}
				refCheckbox={refCheckbox}
				fromProps={fromProps}
			/>
		)
	}

	if (prop.type === ItemPropertyType.GENERATED_CODE) {
		return (
			<ErrorTooltip
				shown={!!error && focused}
				showOnHover={false}
				content={error}
				position={Position.Bottom}
			>
				<CodeInputFormless
					value={typeof value === 'string' ? value : ''}
					name={prop.field}
					nameValue={
						(prop.codeSource &&
							((item as any)[prop.codeSource] as unknown)) as string
					}
					onChange={handleCodeChange}
					onFocusCustom={onFocus}
					onBlurCustom={onBlur}
					autoFocus={autoFocus}
					error={error}
					readonly={readonly}
					disabled={isDisabled || !prop.disableGenerateCode}
					readonlyContainer={value => (
						<ValueWrapper disabled={isDisabled}>{value}</ValueWrapper>
					)}
					updateCode={!fromProps && !prop.disableGenerateCode}
				/>
			</ErrorTooltip>
		)
	}

	return !readonly && !prop.isLoading ? (
		<>
			{prop.type === ItemPropertyType.TEXT && (
				<ErrorTooltip
					shown={!!error && focused}
					showOnHover={false}
					content={error}
					position={Position.Bottom}
				>
					<FormInput
						value={
							typeof value === 'string' || typeof value === 'number'
								? value
								: ''
						}
						type="text"
						name={prop.field}
						autoComplete="off"
						onChange={e => handleChange(e.target.name, e.target.value)}
						disabled={isDisabled}
						onFocus={onFocus}
						onBlur={onBlur}
						focused={focused}
						error={error}
						autoFocus={autoFocus}
						fromProps={fromProps}
					/>
				</ErrorTooltip>
			)}

			{prop.type === ItemPropertyType.REGEXP_VALIDATED && (
				<RegExpValidatedInputFormless
					value={typeof value === 'string' ? value : ''}
					name={prop.field}
					enableAutocomplete={false}
					onChange={handleCodeChange}
					disabled={isDisabled}
					expression={regExp?.expression}
					sampleValue={regExp?.sampleValue}
				/>
			)}
			{prop.type === ItemPropertyType.TEXTAREA && (
				<TextArea
					value={typeof value === 'string' ? value : ''}
					onChange={handleChange}
					disabled={isDisabled}
					onFocus={onFocus}
					onBlur={onBlur}
					autoFocus={autoFocus}
					focused={focused}
					fromProps={fromProps}
					prop={prop}
					onDisableEditing={onDisableEditing}
				/>
			)}

			{prop.type === ItemPropertyType.ACE_EDITOR && (
				<AceEditor
					value={value}
					disabled={isDisabled}
					autoFocus={autoFocus}
					focused={focused}
					fromProps={fromProps}
					prop={prop}
					onDisableEditing={onDisableEditing}
					{...prop.aceEditorProps}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={handleChange as any}
				/>
			)}

			{prop.type === ItemPropertyType.FOREIGN_KEY && (
				<ForeignKey<T>
					value={value}
					name={prop.field ?? ''}
					onChange={handleColumnsChange}
					disabled={isDisabled}
					onFocus={onFocus}
					onBlur={onBlur}
					fromProps={fromProps}
					foreignKeyTableId={prop.foreignKeyTableId}
					primaryKeys={prop.primaryKeys}
					possibleForeignKeys={prop.possibleForeignKeys}
					error={error}
				/>
			)}
			{prop.type === ItemPropertyType.OPTION && (
				<Select
					fromProps={!!fromProps}
					name={prop.field || ''}
					options={options}
					hideTitle={true}
					initialValue={value as any}
					onChange={handleFieldChange}
					disabled={isDisabled}
					valueKey={prop.optionValue}
					labelKey={prop.optionLabel}
					isNumeric={prop.isNumeric}
					clearable={prop.clearable}
					allowEmpty
					compact={compact}
					formless
					loading={!!prop.isLoading}
					onFocus={onFocus}
					onBlur={onBlur}
					error={error}
					autoFocus={autoFocus}
					openMenuOnFocus
					placeholder=""
				/>
			)}
			{prop.type === ItemPropertyType.OPTIONS && (
				<Select
					fromProps={!!fromProps}
					name={prop.field || ''}
					options={options}
					hideTitle={true}
					initialValue={value as any}
					onChange={handleFieldChange}
					disabled={isDisabled}
					valueKey={prop.optionValue}
					labelKey={prop.optionLabel}
					isNumeric={prop.isNumeric}
					clearable={prop.clearable}
					multi
					allowEmpty
					compact={compact}
					formless
					loading={!!prop.isLoading}
					onFocus={onFocus}
					onBlur={onBlur}
					error={error}
					autoFocus={autoFocus}
					openMenuOnFocus
					placeholder=""
				/>
			)}
			{(prop.type === ItemPropertyType.COLUMNS ||
				prop.type === ItemPropertyType.COLUMNS_WITH_ORDER) && (
				<ColumnsPicker
					field={prop.field || ''}
					columns={options}
					value={value ?? []}
					onChange={handleColumnsChange}
					orders={prop.type === ItemPropertyType.COLUMNS_WITH_ORDER}
				/>
			)}
		</>
	) : (
		<>
			{(prop.type === ItemPropertyType.TEXT ||
				prop.type === ItemPropertyType.NUMBER ||
				prop.type === ItemPropertyType.REGEXP_VALIDATED) && (
				<ValueWrapper title={value} disabled={isDisabled}>
					{value}
				</ValueWrapper>
			)}
			{(prop.type === ItemPropertyType.TEXTAREA ||
				prop.type === ItemPropertyType.ACE_EDITOR) &&
				(fromProps ? (
					value
				) : (
					<ValueWrapper title={value} disabled={isDisabled}>
						{value}
					</ValueWrapper>
				))}
			{prop.type === ItemPropertyType.OPTION && (
				<ValueWrapper
					disabled={isDisabled}
					title={listValues(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						[value]
					)}
				>
					{listValue(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						value
					)}
				</ValueWrapper>
			)}

			{prop.type === ItemPropertyType.OPTIONS && (
				<ValueWrapper
					disabled={isDisabled}
					title={listValues(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						Array.isArray(value) ? value : []
					)}
				>
					{listValues(
						options,
						prop.optionValue || 'value',
						prop.optionLabel || 'label',
						Array.isArray(value) ? value : []
					)}
				</ValueWrapper>
			)}
			{prop.type === ItemPropertyType.FOREIGN_KEY && (
				<ForeignKey<T>
					value={value}
					name={prop.field ?? ''}
					onChange={handleColumnsChange}
					disabled={isDisabled}
					onFocus={onFocus}
					onBlur={onBlur}
					fromProps={fromProps}
					primaryKeys={prop.primaryKeys}
					possibleForeignKeys={prop.possibleForeignKeys}
					error={error}
					readOnly
				/>
			)}
			{prop.type === ItemPropertyType.COLUMNS && (
				<ValueWrapper
					disabled={isDisabled}
					title={listValues(
						options,
						'code',
						'name',
						Array.isArray(value) ? value.map(v => v.code) : []
					)}
				>
					{listValues(
						options,
						'code',
						'name',
						Array.isArray(value) ? value.map(v => v.code) : []
					)}
				</ValueWrapper>
			)}
			{prop.type === ItemPropertyType.COLUMNS_WITH_ORDER &&
				Array.isArray(value) &&
				(value as TableIndexColumn[]).map((ci, index) => (
					<React.Fragment key={ci.code}>
						<SortIcon sortType={ci.sortType} />
						<span>{options.find(o => o.id === ci.code)?.name ?? ci.code}</span>
						{index != value.length - 1 && ', '}
					</React.Fragment>
				))}
		</>
	)
}
