import React, { useMemo, useState } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { StructureDto, NewStructureDto } from '@/api/models'
import { SelectFormField, TextFormField } from '@/components/UberForm'
import { addNode, addSystemNode } from '@/store/modules/node/actions'
import { generateCode } from '@/store/modules/misc/actions'
import { getInitialTableData } from '@/store/modules/table/helpers'
import { ModalForm } from '@/components/Modal/ModalForm'
import { getInitialMappingData } from '@/store/modules/mapping/helpers'
import { getInitialSystemData } from '@/store/modules/system/helpers'
import { getInitialWorkflowData } from '@/store/modules/workflow/helpers'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { uniqueNameValidator } from '@/utils/validators'
import { useApi } from '@/api/hooks'
import {
	getAllSystemTechnologies,
	getAllWorkflowTechnologies,
	getObjectSettingsByType
} from '@/api'
import { getInitialViewData } from '@/store/modules/view/helpers'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { getInitialApiCollectionData } from '@/store/modules/apiCollection/helpers'
import { getInitialApiNodeData } from '@/store/modules/apiNode/helpers'
import { getInitialReadMappingData } from '@/store/modules/readMapping/helpers'
import { getInitialQueueData } from '@/store/modules/queue/helpers'
import { getInitialWriteMappingData } from '@/store/modules/writeMapping/helpers'
import { AddModalWriteMapping } from './AddModalWriteMapping'

type Props = {
	type: NewStructureDto.TypeEnum
	node?: StructureDto
	onClose: () => void
}

type FormData = {
	name: string
	targetTableId?: number
	systemTechnologyId?: number
	workflowTechnologyId?: number
}

export type WriteMappingData = {
	targetTableFullPath: string
}

export const AddModal = ({ type, node, onClose }: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const nodes = useAppStore(state => state.node.nodes)

	// TODO: we dont need to get this if node type is not SYSTEM
	const systemTechnologies = useApi(getAllSystemTechnologies())
	const workflowTechnologies = useApi(getAllWorkflowTechnologies())
	const objectSettings = useApi(getObjectSettingsByType(type))

	const systemNodeId = useMemo(() => node && findSystemNodeId(node, nodes), [
		node,
		nodes
	])

	const isSystem = type === StructureDto.TypeEnum.SYSTEM
	const isWriteMapping = type === StructureDto.TypeEnum.MAPPING_WRITE

	const [writeMapping, setWriteMapping] = useState<WriteMappingData>({
		targetTableFullPath: ''
	})

	const onChangeWriteMapping = (value: WriteMappingData) =>
		setWriteMapping(value)

	const getDefaultObjectSettings = () => {
		const defaultOs = objectSettings?.data?.find(os => os.defaultFlag)

		if (defaultOs) {
			return { id: defaultOs.id, code: defaultOs.code, name: defaultOs.name }
		}
	}

	const getNodeData = (name: string, code: string, form: Partial<FormData>) => {
		if (isSystem) {
			return getInitialSystemData(
				name,
				code,
				form.systemTechnologyId,
				form.workflowTechnologyId
			)
		}

		if (type === StructureDto.TypeEnum.TABLE) {
			const defaultOs = getDefaultObjectSettings()

			return getInitialTableData(name, code, defaultOs)
		}

		if (type === StructureDto.TypeEnum.VIEW) {
			return getInitialViewData(name, code)
		}

		if (type === StructureDto.TypeEnum.MAPPING) {
			const defaultOs = getDefaultObjectSettings()

			return getInitialMappingData(name, code, defaultOs)
		}

		if (type === StructureDto.TypeEnum.MAPPING_READ) {
			return getInitialReadMappingData(name, code)
		}

		if (type === StructureDto.TypeEnum.MAPPING_WRITE) {
			return getInitialWriteMappingData(
				name,
				code,
				form.targetTableId as number,
				writeMapping.targetTableFullPath
			)
		}

		if (type === StructureDto.TypeEnum.WORKFLOW) {
			return getInitialWorkflowData(name, code)
		}

		if (type === StructureDto.TypeEnum.API_COLLECTION) {
			return getInitialApiCollectionData(name, code)
		}

		if (type === StructureDto.TypeEnum.API_NODE) {
			return getInitialApiNodeData(name, code)
		}

		if (type === StructureDto.TypeEnum.QUEUE) {
			const defaultOs = getDefaultObjectSettings()

			return getInitialQueueData(name, code, defaultOs)
		}

		return { name }
	}

	const handleSubmit = async (form: Partial<FormData>) => {
		const name = form.name as string
		const code = await dispatch(generateCode(name))
		const nodeData = getNodeData(name, code.result, form)

		const data = {
			type,
			data: JSON.stringify(nodeData)
		}

		const action =
			type !== StructureDto.TypeEnum.SYSTEM && node
				? addNode(node.id as number, data)
				: addSystemNode(data)

		await dispatch(action)
		onClose()
	}

	const getHeader = () => {
		switch (type) {
			case StructureDto.TypeEnum.SYSTEM:
				return t('ADD_NEW_SYSTEM')
			case StructureDto.TypeEnum.FOLDER:
				return t('ADD_NEW_FOLDER')
			case StructureDto.TypeEnum.TABLE:
				return t('ADD_NEW_TABLE')
			case StructureDto.TypeEnum.VIEW:
				return t('ADD_NEW_VIEW')
			case StructureDto.TypeEnum.MAPPING:
				return t('ADD_NEW_MAPPING')
			case StructureDto.TypeEnum.WORKFLOW:
				return t('ADD_NEW_WORKFLOW')
			case StructureDto.TypeEnum.API_NODE:
				return t('ADD_NEW_API_NODE')
			case StructureDto.TypeEnum.API_COLLECTION:
				return t('ADD_NEW_API_COLLECTION')
			case StructureDto.TypeEnum.MAPPING_READ:
				return t('ADD_NEW_MAPPING_READ')
			case StructureDto.TypeEnum.MAPPING_WRITE:
				return t('ADD_NEW_MAPPING_WRITE')
			case StructureDto.TypeEnum.QUEUE:
				return t('ADD_NEW_QUEUE')
			default:
				return undefined
		}
	}

	return (
		<ModalForm<FormData>
			saveTitle={t('CREATE')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '400px'
			}}
			header={getHeader()}
		>
			<TextFormField
				title={t('NAME')}
				name="name"
				required
				validators={[uniqueNameValidator(dispatch, undefined, systemNodeId)]}
				autoFocus
				enableAutocomplete={false}
			/>

			{isSystem && (
				<>
					<SelectFormField
						title={t('SYSTEM_TECHNOLOGY')}
						name="systemTechnologyId"
						options={systemTechnologies.data || []}
						fieldIsLoading={systemTechnologies.reloading}
						valueKey="id"
						labelKey="name"
						required
						isNumeric
					/>

					<SelectFormField
						title={t('WORKFLOW_TECHNOLOGY')}
						name="workflowTechnologyId"
						options={workflowTechnologies.data || []}
						fieldIsLoading={workflowTechnologies.reloading}
						valueKey="id"
						labelKey="name"
						required
						isNumeric
					/>
				</>
			)}
			{isWriteMapping && (
				<AddModalWriteMapping onChangeWriteMapping={onChangeWriteMapping} />
			)}
		</ModalForm>
	)
}
