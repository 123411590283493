import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import FormField, { FormFieldProps } from '@/components/UberForm/FormField'
import Text, { TextProps } from '@/components/UberForm/Input/Text/Text'
import { splitProps } from '@/utils/collections'
import NamingPlaceholders from './NamingPlaceholders'
import { NamingPlaceholder } from '../utils'

type NamingFormFieldProps = Omit<TextProps, 'onChange' | 'onFocus' | 'onBlur'> &
	FormFieldProps & {
		placeholders: NamingPlaceholder
	}

const NamingFormField = (props: NamingFormFieldProps) => {
	const refInput = useRef<HTMLInputElement>(null)
	const fieldProps = { ...props }
	const [isTextFocused, setIsTextFocused] = useState(false)

	const inputProps = splitProps({ ...fieldProps, refInput }, [
		'placeholder',
		'type',
		'autoFocus',
		'enableAutocomplete',
		'refInput'
	])

	const handleFocus = (e: React.FocusEvent) => {
		setIsTextFocused(true)
		fieldProps.onFocus?.(e)
	}

	const handleBlur = (e: React.FocusEvent) => {
		setIsTextFocused(false)
		fieldProps.onBlur?.(e)
	}

	return (
		<Container>
			<FormField {...fieldProps} onFocus={handleFocus} onBlur={handleBlur}>
				<TextContainer>
					<StyledText {...inputProps} />
				</TextContainer>

				<NamingPlaceholders
					placeholders={props.placeholders}
					isTextFocused={isTextFocused}
					inputElement={refInput.current}
				/>
			</FormField>
		</Container>
	)
}

const TextContainer = styled.div`
	display: flex;
	margin-bottom: 2px;
`

const StyledText = styled(Text)`
	flex: 1;
`

const Container = styled.div`
	margin-top: 5px;
	margin-bottom: 10px;
`

export default NamingFormField
