import styled, { css } from 'styled-components'

export const AceEditorLabel = styled.label<{ isHighlighted?: boolean }>`
	padding: 2px 6px 3px 6px;
	display: inline-block;
	background-color: #f5f5f5;
	border: 1px solid #ccc;

	${props =>
		css`
			background-color: ${props.theme.colors.form.compact.header.background};
			border: 1px solid ${props.theme.colors.form.compact.header.borderColor};
		`}
	border-bottom: 0;
	margin-top: 6px;

	${props =>
		props.isHighlighted &&
		css`
			background: ${props => props.theme.colors.warn.light};
		`}
`
