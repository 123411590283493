import {
	USER_GET,
	USER_LIST_GET,
	USER_CONTEXT_LOADED,
	USER_DIALOG_SIZE_CHANGED
} from './constants'
import { UserDto, SessionContextDto } from '@/api/models'
import { apiCallAction } from '@/store/utils'
import {
	getUser,
	getUsers,
	getSessionContext,
	updateSessionContext
} from '@/api'
import { StoreState } from '@/store'
import { SESSION_VERSION, UserSession } from '.'
import { getNodeSession } from '../node'
import { getTabSession } from '../tab'
import { getSettingsSession } from '../settings'

interface LoadUser {
	type: typeof USER_GET
	payload: UserDto
}

interface LoadUserList {
	type: typeof USER_LIST_GET
	payload: UserDto[]
}

interface UserContextLoaded {
	type: typeof USER_CONTEXT_LOADED
	payload: SessionContextDto
}

interface DialogSizeChanged {
	type: typeof USER_DIALOG_SIZE_CHANGED
	dialogId: string
	width: number
	height: number
	isMaximized: boolean
}

export const loadUser = (userId: number) =>
	apiCallAction<LoadUser>(() => getUser(userId), USER_GET)

export const loadUsers = () =>
	apiCallAction<LoadUserList>(() => getUsers(), USER_LIST_GET)

export const loadUserSession = () =>
	apiCallAction<UserContextLoaded>(
		() => getSessionContext(),
		USER_CONTEXT_LOADED
	)

export const updateUserSession = (lastData: string | null) => async (
	dispatch: Function,
	getValues: () => StoreState
): Promise<SessionContextDto> => {
	const data = JSON.stringify({
		__version: SESSION_VERSION,
		node: getNodeSession(getValues().node),
		tab: getTabSession(getValues().tab),
		settings: getSettingsSession(getValues().settings),
		dialog: getValues().user.dialog
	} as UserSession)

	if (data !== lastData) {
		return dispatch(
			apiCallAction(() => updateSessionContext({ context: data }))
		)
	}

	return { context: lastData }
}

export const saveDialogSize = (
	dialogId: string,
	width: number,
	height: number,
	isMaximized: boolean
): Actions => ({
	type: USER_DIALOG_SIZE_CHANGED,
	dialogId,
	width,
	height,
	isMaximized
})

export type Actions =
	| LoadUser
	| LoadUserList
	| UserContextLoaded
	| DialogSizeChanged
