export const getParsedGenerateTableColumnCode = (
	generateTableColumnCode: string | undefined
) => {
	switch (generateTableColumnCode) {
		case 'true':
			return true

		case 'false':
			return false

		default:
			return true
	}
}
