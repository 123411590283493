import { getAvailableStructureForSourceObject, getSystemUsers } from '@/api'
import { useApi } from '@/api/hooks'
import { SourceEnums, SourceItem } from '@/api/schemas'
import { ItemProperty, ItemPropertyType } from '@/components/Properties/types'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { useAppContext } from '@/utils/hooks'
import { useMemo } from 'react'
import { getAvailableStructures } from './getAvailableStructures'
import { useSourceOptions } from './useSourceOptions'

export const useSourceItemProperties = (): ItemProperty<SourceItem>[] => {
	const { t } = useAppContext()

	const {
		state: { node, systemNodeId }
	} = useDetailTabContext()

	const systemTables = useApi(
		getAvailableStructureForSourceObject(node.id, 'SYSTEM')
	)

	const [systemUsers, isSystemUserLoading] = useApi(
		getSystemUsers(systemNodeId)
	)

	const { sourceObjectTypes, sourceStereotypes } = useSourceOptions()

	const sourceItemProperties = useMemo<ItemProperty<SourceItem>[]>(
		() => [
			{
				field: 'stereotype',
				label: t('MAPPING_SOURCES_STEREOTYPE'),
				type: ItemPropertyType.OPTION,
				flex: 1.5,
				width: 100,
				optionValue: 'value',
				optionLabel: 'label',
				options: sourceStereotypes || [],
				isNumeric: true
			},
			{
				field: 'objectType',
				label: t('MAPPING_SOURCES_OBJECT_TYPE'),
				type: ItemPropertyType.OPTION,
				flex: 1,
				width: 120,
				optionValue: 'value',
				optionLabel: 'label',
				options: sourceObjectTypes || [],
				isNumeric: true
			},
			{
				field: 'objectCode',
				label: t('MAPPING_SOURCES_OBJECT'),
				type: ItemPropertyType.OPTION,
				options: column => getAvailableStructures(systemTables.data, column),
				optionValue: 'code',
				optionLabel: 'fullPath',
				isNumeric: true,
				isLoading: systemTables.loading,
				flex: 4,
				disabled: column =>
					column?.objectType === SourceEnums.ObjectTypeEnum.SUB_SELECT,
				autoWidth: true
			},
			{
				field: 'ownerId',
				label: t('NODE_OWNER'),
				type: ItemPropertyType.OPTION,
				flex: 1.5,
				optionValue: 'id',
				optionLabel: 'name',
				options: systemUsers || [],
				isLoading: isSystemUserLoading,
				isNumeric: true,
				width: 200
			},
			{
				field: 'objectCustom',
				label: t('SOURCE_SUBSELECT'),
				type: ItemPropertyType.ACE_EDITOR,
				flex: 2,
				disabled: column =>
					column.objectType != SourceEnums.ObjectTypeEnum.SUB_SELECT,
				width: 200
			},
			{
				field: 'alias',
				label: t('MAPPING_SOURCES_ALIAS'),
				type: ItemPropertyType.TEXT,
				flex: 2,
				width: 200
			},
			{
				field: 'expression',
				label: t('MAPPING_SOURCES_EXPRESSION'),
				type: ItemPropertyType.ACE_EDITOR,
				flex: 2,
				width: 200
			},
			{
				field: 'sourceContext',
				label: t('MAPPING_SOURCES_SOURCE_CONTEXT'),
				type: ItemPropertyType.TEXTAREA,
				flex: 2,
				width: 200
			}
		],
		[
			t,
			sourceStereotypes,
			sourceObjectTypes,
			systemUsers,
			isSystemUserLoading,
			systemTables.loading,
			systemTables.data
		]
	)

	return sourceItemProperties
}
