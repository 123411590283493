import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { TableData, TableColumn, TableConstraint } from '@/api/schemas'
import { OpenedTableData } from '@/store/modules/table/types'
import { ListEditTab } from '@/components'
import { StructureDto } from '@/api/models'
import { ConstraintsProperties } from './Properties'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext, useAppDispatch, useAppStore } from '@/utils/hooks'
import { createEmptyKey } from '@/store/modules/table/helpers'
import { initTable } from '@/store/modules/table/actions'
import { useApi } from '@/api/hooks'
import { getAllTablesForSystemNode } from '@/api'
import {
	findPrimarykeyColumns,
	getPossibleForeignKeys,
	getParsedData
} from './utils'
import { PossibleForeignKeys } from '@/components/Properties/types'
import { duplication } from './validation'

type Props = {
	node: StructureDto
	data: OpenedTableData
	systemNodeId: number
	editMode: boolean
	onChange: (v: UpdateDeepPartial<TableData>) => void
	technicalColumns: TableColumn[]
}

const ConstraintsComponent = ({
	node,
	data,
	systemNodeId,
	editMode,
	onChange,
	technicalColumns
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const tables = useAppStore(state => state.table.tables)
	const systemTables = useApi(getAllTablesForSystemNode(systemNodeId))

	// primaryKeys of referenced table
	const [primaryKeys, setPrimaryKeys] = useState<TableColumn[]>([])

	// Columns of current table with the same data type as PK
	const [possibleForeignKeys, setPossibleForeignKeys] = useState<
		PossibleForeignKeys
	>({})

	const [foreignKeyTableToProcess, setForeignKeyTableToProcess] = useState(
		null as { id: number } | null
	)

	const foreignKeyTable = useMemo(
		() => foreignKeyTableToProcess && tables[foreignKeyTableToProcess.id],
		[tables, foreignKeyTableToProcess]
	)

	const setForeignKeyTable = useCallback(
		async (constraints?: TableConstraint[]) => {
			// update foreignKeyTable only when the onChange is called for single constraint
			if (!constraints || constraints.length !== 1) {
				return
			}

			const changedData = Object.values(constraints).map(data => ({
				constraintType: data.constraintType,
				foreignKeyTableId: data.foreignKeyTableId as number
			}))[0]

			if (!changedData.foreignKeyTableId) {
				return
			}

			if (!tables[changedData.foreignKeyTableId]) {
				await dispatch(initTable({ nodeId: changedData.foreignKeyTableId }))
			}

			setForeignKeyTableToProcess({ id: changedData.foreignKeyTableId })
		},
		[dispatch, tables]
	)

	// update primaryKeys & possibleForeignKeys on row change
	const onSelect = useCallback(
		(item: TableConstraint) => {
			setForeignKeyTable([item])
		},
		[setForeignKeyTable]
	)

	useEffect(() => {
		if (!foreignKeyTableToProcess || !foreignKeyTable) {
			return
		}

		const { primaryColumns } = findPrimarykeyColumns(
			foreignKeyTable.form,
			foreignKeyTable
		)

		const possibleForeignKeys = getPossibleForeignKeys(
			primaryColumns,
			data.form.columns,
			data.stereotypeColumns
		)

		setPrimaryKeys(primaryColumns)
		setPossibleForeignKeys(possibleForeignKeys)
	}, [foreignKeyTableToProcess, data.form.columns, data.stereotypeColumns])

	const handleChange = async (v: UpdateDeepPartial<TableData>) => {
		// parse data to change form value in COLUMNS & CONSTRAINTS tabs
		const {
			columns,
			columnsLastId,
			constraints,
			constraintTypeChanged,
			foreignKeyTableChanged,
			current
		} = getParsedData(v, data, primaryKeys)

		// clear reference table when constraint type changed
		if (constraintTypeChanged && current) {
			onChange({ constraints: { [current]: { foreignKeyTableId: undefined } } })
		}

		// clear columns when referenced table changed
		if (foreignKeyTableChanged && current) {
			onChange({ constraints: { [current]: { columns: [] } } })
		}

		if (columns.length > 0) {
			onChange({
				constraints,
				columns,
				columnsLastId
			})
		} else {
			onChange(v)
		}

		setForeignKeyTable(Object.values(v.constraints as object))
	}

	const errors = useMemo(() => duplication(data.form.constraints, t), [
		data.form.constraints,
		t
	])

	return (
		<ListEditTab
			idKey="id"
			node={node}
			data={data.form}
			editMode={editMode}
			createEmpty={createEmptyKey}
			itemsKey={'constraints'}
			idCounterKey={'constraintsLastId'}
			onChange={handleChange}
			panelPropertiesMinWidth={500}
			panelPropertiesDefaultOpen
			properties={ConstraintsProperties(
				t,
				node,
				[...data.form.columns.filter(c => c.name), ...technicalColumns],
				systemTables,
				possibleForeignKeys,
				primaryKeys,
				foreignKeyTableToProcess?.id
			)}
			tabKey="key"
			onSelect={onSelect}
			errors={errors}
		/>
	)
}

export const Constraints = React.memo(ConstraintsComponent)
