import { DomainDto } from '@/api/models'
import { useCallback, useEffect, useMemo } from 'react'
import { useAppStore, useAppDispatch } from './hooks'
import { initSystem } from '@/store/modules/system/actions'
import { useApi } from '@/api/hooks'
import { getAllDomainsFilteredBySystemTechnology } from '@/api'

export const useTechnologyId = (systemNodeId: number) => {
	const dispatch = useAppDispatch()
	const systems = useAppStore(state => state.system.systems)
	const system = systems[systemNodeId]

	useEffect(() => {
		if (!system) {
			dispatch(initSystem({ nodeId: systemNodeId }))
		}
	}, [dispatch, system, systemNodeId])

	return system?.form.systemTechnologyId
}

export const useDomainTechnology = (systemNodeId: number) => {
	const systemTechnologyId = useTechnologyId(systemNodeId)

	const getDomainData = useCallback(
		(domain?: DomainDto) => {
			const domainSystem = domain?.domainTechnologies?.find(
				dt => dt.technologyId === systemTechnologyId
			)

			return domainSystem
		},
		[systemTechnologyId]
	)

	return { getDomainData }
}

export const excludeCustomDomains = (domains?: DomainDto[]) =>
	domains?.filter(d => d.custom !== true) ?? []

type DomainPartial = {
	[index: string]: any
	domainId?: number
	domain?: {
		id: number
		[index: string]: any
	}
}[]

type Data = {
	original: {
		columns?: DomainPartial
		lookups?: DomainPartial
		constants?: DomainPartial
		[index: string]: any
	}
	[index: string]: any
}

const getDeletedDomains = (
	data?: DomainPartial,
	domainsSystem?: DomainDto[],
	flatData?: boolean
) =>
	(data
		?.map(col =>
			domainsSystem?.find(
				d => d.id === (flatData ? col.domainId : col.domain?.id) && d.deleted
			)
		)
		.filter(v => v) as DomainDto[]) ?? []

/** Keep deleted domains in data (to show them in selects etc. in previous versions) */
export const useDeletedDomains = (systemNodeId: number, data?: Data) => {
	const { data: domainsSystem } = useApi(
		getAllDomainsFilteredBySystemTechnology(systemNodeId)
	)

	const domains = useMemo(() => {
		const domainsDeleted = [
			...getDeletedDomains(data?.original.columns, domainsSystem),
			...getDeletedDomains(data?.original.lookups, domainsSystem, true),
			...getDeletedDomains(data?.original.constants, domainsSystem, true)
		]

		return [
			...(domainsSystem?.filter(d => !d.deleted) ?? []),
			...domainsDeleted
		]
	}, [data, domainsSystem])

	return domains
}
