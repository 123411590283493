import { useCallback, useMemo, useState } from 'react'

export const useFilter = <T>() => {
	const [filter, setFilter] = useState<T>({} as T)

	const onChangeFilter = useCallback((values: T) => {
		setFilter(values)
	}, [])

	return useMemo(
		() => ({
			filter,
			onChangeFilter
		}),
		[filter, onChangeFilter]
	)
}
