import { FormValue } from '../UberForm/Form'
import { RegExpProps } from '../RegExpValidatedInput/RegExpValidatedInput'
import { ColumnsPickerValue } from '../ColumnsPicker/ColumnsPicker'
import { TableColumn } from '@/api/schemas'
import React from 'react'
import { AceEditorProps } from '../AceEditor/AceEditor'
import { CustomAttributeComboDto } from '@/api/models'

export type Predicate<T> = (value: T, listOfValues: T[]) => boolean

// primaryKeyUUID matches data type for column data type
export type PossibleForeignKeys = { [primaryKeyUUID: string]: TableColumn[] }

export interface ItemProperty<
	T,
	K extends Extract<keyof T, string> = Extract<keyof T, string>,
	K2 extends Extract<keyof T, string> = Extract<keyof T, string>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	O = any
> {
	label: string
	field?: any
	type: ItemPropertyType
	/** Column width */
	width?: number
	/** Similiar to width, but not */
	flex?: number
	disabled?: boolean | Predicate<T>
	/** Condition which evaluates if the field should be shown */
	condition?: Predicate<T>
	/** Hide this field in table view */
	hideInTable?: boolean
	/** Label used when properties are displayed in table */
	tableLabel?: string
	/** Construct property value independently */
	value?: (item: T) => FormValue | ColumnsPickerValue

	/**
	 * Select specific values
	 */
	/** Select options */
	options?: O[] | ((item: T | undefined) => O[])
	/** Key used to get value from option, defaults to 'value' */
	optionValue?: string
	/** Is the current field loading? */
	isLoading?: boolean
	/** Key used to get label from option, defaults to 'label' */
	optionLabel?: string
	isNumeric?: boolean
	clearable?: boolean

	/**
	 * Code specific values
	 */
	/** Name of field that is used as source for code */
	codeSource?: any

	/**
	 * Regexp validated specific values
	 */
	regExp?: (item: T) => RegExpProps | undefined

	/**
	 *  Responsive width for Properties component
	 *  If resizable panel is big, this width is applied
	 */
	propertiesWidth?: string

	/** Show field in table if panel is opened */
	showWhenPanelOpened?: boolean

	/** auto resize to widest test in column */
	autoWidth?: boolean

	/** primaryKeys from referenced table */
	primaryKeys?: TableColumn[]

	/** foreign key table id from referenced table */
	foreignKeyTableId?: number

	/** Columns matching data type from referenced Table Primary key */
	possibleForeignKeys?: PossibleForeignKeys

	formatter?: React.FC<{ item: T; readonly: boolean; fromProps?: boolean }>

	/** Do not auto generate code in ItemPropertyType.GENERATED_CODE */
	disableGenerateCode?: boolean

	/** Ace editor props */
	aceEditorProps?: AceEditorProps

	/** Stick to the right side of table when overflow */
	sticky?: boolean
}

export enum ItemPropertyType {
	TEXT,
	TEXTAREA,
	NUMBER,
	OPTION,
	OPTIONS,
	COLUMNS,
	COLUMNS_WITH_ORDER,
	GENERATED_CODE,
	REGEXP_VALIDATED,
	CHECKBOX,
	FOREIGN_KEY,
	ACE_EDITOR
}

export type PropertiesChangeCallback<T> = (
	item: T,
	key: string,
	value: FormValue | ColumnsPickerValue,
	customAttributes?: CustomAttributeComboDto
) => void

export interface PropertiesErrorProps {
	[key: number]: {
		[column: string]: {
			message: string
		}
	}
}

export type EditingAddress = {
	row: number
	field: number
}

export enum MoveDir {
	Up = 1,
	Down = 2,
	Left = 3,
	Right = 4
}

export interface PropertiesProps<T> {
	item?: T
	index?: number
	listOfValues: T[]
	properties: ItemProperty<T>[]
	onChange: (
		item: T,
		key: string,
		value: FormValue | ColumnsPickerValue
	) => void
	onHide: () => void
	onShow: () => void
	propertiesWidth: number
	readonly?: boolean
	isHidden?: boolean
	errors?: PropertiesErrorProps
	onSelect?: (index: number) => void
}

export interface CustomAttributeInitialValue {
	[index: string]: any
}

export type GetInitialValuesType = () => CustomAttributeInitialValue[]
