import { LANGUAGE_KEY } from '@/context/Locale/Locale'
import { NamingDtoTypeEnum } from '@/typings/enum/NamingDtoTypeEnum'
import { useAppContext } from '@/utils/hooks'
import { useCallback, useMemo } from 'react'

export type NamingPlaceholder = {
	[index: string]: LANGUAGE_KEY
}

/* eslint-disable @typescript-eslint/camelcase */
export const placeholdersDdl: NamingPlaceholder = {
	current_node_name: 'NAMING_CURRENT_NODE_NAME',
	current_node_name_acronym: 'CURRENT_NODE_NAME_ACRONYM',
	current_node_code: 'NAMING_CURRENT_NODE_CODE',
	current_node_stereotype_name: 'NAMING_CURRENT_NODE_STEREOTYPE_NAME',
	current_node_stereotype_code: 'NAMING_CURRENT_NODE_STEREOTYPE_CODE',
	current_node_type: 'NAMING_CURRENT_NODE_TYPE',
	current_node_type_abbr: 'NAMING_CURRENT_NODE_TYPE_ABBR',
	parent_node_name: 'NAMING_PARENT_NODE_NAME',
	parent_node_name_acronym: 'PARENT_NODE_NAME_ACRONYM',
	parent_node_code: 'NAMING_PARENT_NODE_CODE',
	parent_system_name: 'NAMING_PARENT_SYSTEM_NAME',
	parent_system_code: 'NAMING_PARENT_SYSTEM_CODE',
	owner_name: 'NAMING_OWNER_NAME',
	dateYYYYMMDD: 'NAMING_DATEYYYYMMDD',
	sequence_column: 'SEQUENCE_COLUMN',
	object_code: 'OBJECT_TYPE_CODE',
	object_name: 'OBJECT_TYPE_NAME',
	file_extension: 'FILE_EXTENSION'
}

export const placeholdersDllTableMode: NamingPlaceholder = {
	...placeholdersDdl,
	master_node_name: 'MASTER_NODE_NAME',
	master_node_name_acronym: 'MASTER_NODE_NAME_ACRONYM',
	master_node_code: 'MASTER_NODE_CODE'
}

export enum NamingTabEnum {
	ELEMENT_TYPES = 'elementTypes',
	GENERATED_ELEMENTS = 'generatedElements',
	OTHERS = 'others'
}

export const useGetNamingTabContent = () => {
	const { t } = useAppContext()

	const content = useMemo(() => {
		return {
			[NamingTabEnum.ELEMENT_TYPES]: [
				{
					title: t('NAMING_TABLE_CODE'),
					name: NamingDtoTypeEnum.TABLE_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_MAPPING_CODE'),
					name: NamingDtoTypeEnum.MAPPING_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_API_NODE_CODE'),
					name: NamingDtoTypeEnum.API_NODE_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_VIEW_CODE'),
					name: NamingDtoTypeEnum.VIEW_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_QUEUE_CODE'),
					name: NamingDtoTypeEnum.QUEUE_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_API_COLLECTION_CODE'),
					name: NamingDtoTypeEnum.API_COLLECTION_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('WORKFLOW'),
					name: NamingDtoTypeEnum.WORKFLOW_CODE,
					placeholders: placeholdersDdl
				}
			],
			[NamingTabEnum.GENERATED_ELEMENTS]: [
				{
					title: t('NAMING_HISTORICAL_TABLE_NAME'),
					name: NamingDtoTypeEnum.HISTORICAL_TABLE_NAME,
					placeholders: placeholdersDllTableMode
				},
				{
					title: t('NAMING_HISTORICAL_TABLE_CODE'),
					name: NamingDtoTypeEnum.HISTORICAL_TABLE_CODE,
					placeholders: placeholdersDllTableMode
				},
				{
					title: t('NAMING_REFERENCE_TABLE_NAME'),
					name: NamingDtoTypeEnum.REFERENCE_TABLE_NAME,
					placeholders: placeholdersDllTableMode
				},
				{
					title: t('NAMING_REFERENCE_TABLE_CODE'),
					name: NamingDtoTypeEnum.REFERENCE_TABLE_CODE,
					placeholders: placeholdersDllTableMode
				},
				{
					title: t('NAMING_DEFAULT_MAPPING_NAME'),
					name: NamingDtoTypeEnum.DEFAULT_MAPPING_NAME,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_DEFAULT_MAPPING_CODE'),
					name: NamingDtoTypeEnum.DEFAULT_MAPPING_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_HISTORICAL_DEFAULT_MAPPING_NAME'),
					name: NamingDtoTypeEnum.HISTORICAL_DEFAULT_MAPPING_NAME,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_HISTORICAL_DEFAULT_MAPPING_CODE'),
					name: NamingDtoTypeEnum.HISTORICAL_DEFAULT_MAPPING_CODE,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_INTERFACE_DEFAULT_MAPPING_NAME'),
					name: NamingDtoTypeEnum.INTERFACE_DEFAULT_MAPPING_NAME,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_INTERFACE_DEFAULT_MAPPING_CODE'),
					name: NamingDtoTypeEnum.INTERFACE_DEFAULT_MAPPING_CODE,
					placeholders: placeholdersDdl
				}
			],
			[NamingTabEnum.OTHERS]: [
				{
					title: t('PRIMARY_KEY_NAME'),
					name: NamingDtoTypeEnum.PRIMARY_KEY_NAME,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_SEQUENCE_NAME'),
					name: NamingDtoTypeEnum.SEQUENCE_NAME,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_DDL_SCRIPT_NAME'),
					name: NamingDtoTypeEnum.DDL_SCRIPT_NAME,
					placeholders: placeholdersDdl
				},
				{
					title: t('NAMING_EXPORT_NAME'),
					name: NamingDtoTypeEnum.EXPORT_NAME,
					placeholders: placeholdersDdl
				}
			]
		}
	}, [t])

	const getNamingTabContent = useCallback(
		(tab: NamingTabEnum) => content[tab],
		[content]
	)

	return getNamingTabContent
}
