import React, { useCallback, useMemo } from 'react'
import { LightTableColumn } from '@/components/LightTable'
import { Audit } from './types'
import { TranslationHelper } from '@/context/Locale/Locale'
import { toLocaleString } from '@/utils/numbers'
import { splitProps } from '@/utils/collections'
import { ApiHookResult } from '@/api/hooks'

export const auditFieldsRemove = (formValues: Record<string, any>) =>
	splitProps(formValues, [
		'updatedBy',
		'updatedAt',
		'version',
		'createdBy',
		'createdAt',
		'deleted'
	])

export const auditFieldsAdd = (auditObject?: Record<string, any>) => ({
	updatedAt: auditObject?.updatedAt,
	updatedBy: auditObject?.updatedBy,
	version: auditObject?.version
})

export const columnsAudit = (t: TranslationHelper): any =>
	[
		{
			field: 'updatedBy',
			title: t('UPDATED_BY'),
			width: 200,
			maxWidth: 200
		},
		{
			field: 'updatedAt',
			title: t('UPDATED_AT'),
			width: 170,
			maxWidth: 170,
			// eslint-disable-next-line react/display-name
			formatter: _ => <div>{toLocaleString(_ as string)}</div>
		},
		{
			field: 'version',
			title: t('VERSION'),
			width: 58,
			maxWidth: 58
		}
	] as LightTableColumn<Audit>[]

export const useSettingsPageSaveHandler = (data: ApiHookResult<any>) =>
	useCallback(() => {
		data.invalidate()
	}, [data])

export const rowOrderChangeHandler = (
	oldIndex: number,
	newIndex: number,
	dataSource: ApiHookResult<any[]>,
	request: any,
	apiMethod: any
) => {
	if (dataSource) {
		const movedItem = dataSource?.data?.find((_, index) => index === oldIndex)

		const remainingItems =
			dataSource?.data?.filter((_, index) => index !== oldIndex) || []

		const reorderedItems = [
			...remainingItems.slice(0, newIndex),
			movedItem,
			...remainingItems.slice(newIndex)
		] as any[]

		Promise.all(
			reorderedItems
				.map((item, i) => {
					if (item.ordering !== i) {
						auditFieldsRemove(item)

						return request(
							apiMethod(item.id as number, {
								...item,
								ordering: i
							})
						)
					}
				})
				.filter(item => item !== undefined)
		).then(() => dataSource.invalidate())
	}
}

export const usePageItemsSort = (dataSource: ApiHookResult<any[]>) => {
	return useMemo(() => {
		if (dataSource.data) {
			return dataSource.data?.sort((a, b) => {
				return !a.ordering && !b.ordering ? a.ordering - b.ordering : 0
			})
		}

		return []
	}, [dataSource.data])
}
