/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames'
import React from 'react'
import { isVisible } from './helpers'
import LightTableBody from './components/Body'
import { LightTableHead } from './components/Head'
import { Sort } from './sort'
import { LightTableColumn, RowCallback } from './types'

import './LightTable.less'

export interface LightTableProps<T, K1 extends keyof T = keyof T> {
	/** Data to display */
	data: T[]
	/** Definition of table columns */
	columns: LightTableColumn<T>[]
	/** Field used to get key for rows */
	keyField: K1
	/** Limit number of displayed rows */
	limit?: number

	/** Global classname of the component */
	className?: string
	/** Global styles of the component */
	style?: React.CSSProperties

	/** Classname for every row */
	trClassName?: string | RowCallback<T, string>
	/** Style for every row */
	trStyle?: React.CSSProperties | RowCallback<T, React.CSSProperties>

	/** Classname applied to header table */
	theadClassName?: string
	/** Classname applied to body table */
	tbodyClassName?: string

	/** Hide header table */
	hideHeader?: boolean

	/** Are data being loaded - applies blur effect */
	isLoading?: boolean

	/** Display striped rows */
	striped?: boolean

	/** Callback defining if row can be expanded */
	isRowExpandable?: (row: T, index: number) => boolean
	/** Callback called when row is epxanded */
	expandedRowComponent?: (row: T, index: number) => React.ReactNode
	/** Event called when row is expanded and shrunk */
	onExpand?: (row: T, index: number, isExpanded: boolean) => void
	/** Classname of expanded row */
	expandedTrClassName?: string | RowCallback<T, string>
	/** List of rows to be expanded by default */
	defaultExpanded?: string[]

	/** Function called when sorting rows - when not defined sorting doesn't do anything */
	sortFunction?: (a: T, b: T, sort: Sort) => number
	/** Event calledn when user changes sorting preferences */
	onSortChange?: (sort: Sort) => void
	/** Default sort key/order */
	defaultSort?: Sort

	/** Forced min width of table contents */
	width?: string

	/** Called when table layout changes */
	onUpdate?: () => void

	/** User clicks on specific row */
	onRowClick?: (row: T) => void

	windowedRendering?: boolean
	windowedRowHeight?: number

	scrollLeft?: number
	isRowOrderable?: boolean
	onRowOrderChanged?: (oldIndex: number, newIndex: number) => void
	dragArrowVisible?: boolean
}

export default class LightTable<T = any> extends React.PureComponent<
	LightTableProps<T>
> {
	/** Table header */
	head = React.createRef<LightTableHead<T>>()

	/** Table body */
	body = React.createRef<LightTableBody<T>>()

	state = {
		sort: this.props.defaultSort || { field: null, order: 'asc' }
	}

	componentDidUpdate() {
		this.props.onUpdate && this.props.onUpdate()
	}

	getHeaderContainer = () => {
		return this.head.current ? this.head.current.container.current : null
	}

	getWidth = () => {
		return this.head.current ? this.head.current.getWidth() : null
	}

	getHeaderHeight = () => {
		return this.head.current ? this.head.current.getHeight() : null
	}

	getBodyContainer = () => {
		return this.body.current ? this.body.current.container.current : null
	}

	getBodyScroller = () => {
		return this.body.current ? this.body.current.scroller.current : null
	}

	getHeaderScroller = () => {
		return this.head.current ? this.head.current.table.current : null
	}

	handleSortChange = (sort: Sort) => {
		const { onSortChange } = this.props

		this.setState({ sort }, () => {
			if (onSortChange) {
				onSortChange(sort)
			}
		})
	}

	render() {
		const {
			className,
			style,
			columns,
			keyField,
			data,
			trClassName,
			trStyle,
			hideHeader,
			tbodyClassName,
			theadClassName,
			isRowExpandable,
			expandedRowComponent,
			onExpand,
			expandedTrClassName,
			isLoading,
			sortFunction,
			defaultExpanded,
			striped,
			limit,
			width,
			onUpdate,
			onRowClick,
			windowedRendering,
			windowedRowHeight,
			scrollLeft,
			isRowOrderable,
			onRowOrderChanged,
			dragArrowVisible = true
		} = this.props

		const { sort } = this.state

		let sorted: T[] = data

		if (sort && sortFunction) {
			sorted = sorted.slice()
			sorted.sort((a, b) => sortFunction(a, b, sort))
		}

		if (limit !== undefined) {
			sorted = sorted.slice(0, limit)
		}

		const actualWidth =
			width ||
			columns.filter(c => isVisible(c)).reduce((w, c) => w + c.width, 0) + 'px'

		return (
			<div
				role="table"
				className={cn('light-table', className, {
					'is-loading': isLoading,
					striped: striped
				})}
				style={style}
			>
				{!hideHeader && (
					<LightTableHead<T>
						ref={this.head}
						columns={columns}
						className={theadClassName}
						sort={sort}
						onSortChanged={this.handleSortChange}
						width={actualWidth}
						scrollLeft={scrollLeft}
					/>
				)}
				<LightTableBody<T>
					dragArrowVisible={dragArrowVisible}
					ref={this.body}
					className={tbodyClassName}
					columns={columns}
					data={sorted}
					keyField={keyField}
					trClassName={trClassName}
					trStyle={trStyle}
					isRowExpandable={isRowExpandable}
					expandedRowComponent={expandedRowComponent}
					onExpand={onExpand}
					expandedTrClassName={expandedTrClassName}
					defaultExpanded={defaultExpanded}
					width={actualWidth}
					onUpdate={onUpdate}
					onRowClick={onRowClick}
					windowedRendering={windowedRendering}
					windowedRowHeight={windowedRowHeight}
					scrollLeft={scrollLeft}
					isRowOrderable={isRowOrderable}
					onRowOrderChanged={onRowOrderChanged}
				/>
			</div>
		)
	}
}
