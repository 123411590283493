import React from 'react'
import { FormlessText } from '@/components/UberForm/Input/Text/Text'
import {
	useEffectWithoutMount,
	useAppDispatch,
	useDebounce
} from '@/utils/hooks'
import styled, { css } from 'styled-components'
import {
	callNamingWorkingData,
	CallNamingWorkingDataParams
} from '@/store/modules/table/utils/callNaming'
import { FormValue } from '../../Form'
import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { useCanGenerateNaming } from './useCanGenerateNaming'
import { StructureDto } from '@/api/models'

export type NamingProps = FormFieldContext & {
	callNamingWorkingDataParams?: Readonly<CallNamingWorkingDataParams>
	error?: string | null
	readonlyContainer?: (value?: string) => React.ReactNode
	autoFocus?: boolean
	updateNaming?: boolean
	onFocusCustom?: () => void
	onBlurCustom?: () => void
	node?: StructureDto
}

export const NamingInputFormless = ({
	id,
	node,
	callNamingWorkingDataParams,
	name,
	value,
	onChange,
	error,
	onFocus,
	onBlur,
	onFocusCustom,
	onBlurCustom,
	autoFocus,
	readonly,
	readonlyContainer,
	disabled,
	updateNaming = true
}: NamingProps) => {
	const dispatch = useAppDispatch()

	const debouncedParams = useDebounce(callNamingWorkingDataParams, 200)
	const canGenerateNaming = useCanGenerateNaming(node?.id)

	useEffectWithoutMount(() => {
		if (updateNaming && canGenerateNaming && debouncedParams) {
			const naming = dispatch(
				callNamingWorkingData(debouncedParams as CallNamingWorkingDataParams)
			)

			naming.then(naming => {
				const result = naming?.result

				if (result !== value) {
					onChange?.(result)
				}
			})
		}
	}, [updateNaming, canGenerateNaming, debouncedParams])

	const handleChange = (value: FormValue) => {
		onChange?.(value)
	}

	return (
		<>
			{readonly ? (
				readonlyContainer ? (
					readonlyContainer(value)
				) : (
					<FormlessTextStyled value={value} disabled />
				)
			) : (
				<FormlessTextStyled
					id={id}
					name={name}
					value={value}
					onFocus={e => {
						onFocus && onFocus(e)
						onFocusCustom && onFocusCustom()
					}}
					onBlur={e => {
						onBlur && onBlur(e)
						onBlurCustom && onBlurCustom()
					}}
					error={error}
					onChange={handleChange}
					autoFocus={autoFocus}
					disabled={disabled || canGenerateNaming}
				/>
			)}
		</>
	)
}

const FormlessTextStyled = styled(FormlessText)<
	React.ComponentProps<typeof FormlessText & { error?: string }>
>`
	${props =>
		props.error &&
		css`
			background: ${props.theme.colors.input.error.background};
			border-color: ${props.theme.colors.input.error.border};
		`}
`

export const NamingInput = withFormField(NamingInputFormless)
