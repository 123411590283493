import { useAppContext } from '@/utils/hooks'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { TRANSFER_KEY_CSV_COLUMN } from '../../../constants'
import { useCsvImporterContext } from '../../../context/CsvImporterContext'
import { ActionTypeEnum } from '../../../context/types'
import { CsvField } from '../../../types/CsvField'
import { CsvColumnCard } from './CsvColumnCard'

type Props = {
	field: CsvField
}

export const CsvFieldCard = ({ field: { name, label, required } }: Props) => {
	const { t } = useAppContext()

	const {
		state: { mapping },
		dispatch
	} = useCsvImporterContext()

	const columnIndexMapped = useMemo(() => mapping[name], [mapping, name])

	return (
		<Container
			onDrop={event => {
				const columnIndex = event.dataTransfer.getData(TRANSFER_KEY_CSV_COLUMN)

				if (columnIndex) {
					const columnIndexParsed = parseFloat(columnIndex)

					dispatch({
						type: ActionTypeEnum.CSV_MAPPING_UPDATE,
						mapping: {
							[name]: columnIndexParsed
						}
					})
				}
			}}
			onDragOver={event => event.preventDefault()}
		>
			<Label>
				{label} {required && <Required>*</Required>}&nbsp;
				{!isNil(columnIndexMapped) && (
					<RemoveIcon
						onClick={() =>
							dispatch({
								type: ActionTypeEnum.CSV_MAPPING_UPDATE,
								mapping: {
									[name]: undefined
								}
							})
						}
					>
						<FontAwesomeIcon
							title={t('REMOVE')}
							color="#000"
							icon={faTimes}
							style={{ height: '0.85rem' }}
						/>
					</RemoveIcon>
				)}
			</Label>
			{!isNil(columnIndexMapped) ? (
				<CsvColumnCard columnIndex={columnIndexMapped} />
			) : (
				<Wrapper>{t('DRAG_COLUMN_HERE')}</Wrapper>
			)}
		</Container>
	)
}

const Container = styled.div``

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 4px;
	border: 2px dashed #ddd;
	background: #f0f0f0;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Label = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2px;
`

const Required = styled.span`
	color: ${props => props.theme.colors.danger.base};
`

const RemoveIcon = styled.div`
	cursor: pointer;
	margin-left: auto;
`
