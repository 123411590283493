import Keycloak from 'keycloak-js'

export const tenantId = (() => {
	switch (window.location.hostname) {
		case "127.0.0.1":
		case "localhost":
			return process.env.APP_API_AUTH_DEFAULT_TENANT || "default";
		default:
			console.warn("Can't resolve tenantId")
			return window.location.hostname.split(".")[0];
	}
})()

const keycloak = Keycloak({
	url: process.env.APP_API_AUTH_URL,
	clientId: process.env.APP_API_CLIENT_ID,
	realm: tenantId
})

export default keycloak
