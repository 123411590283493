import React, { useState } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { UserDto, NewUserDto, EditUserInfoDto } from '@/api/models'
import { useAppContext } from '@/utils/hooks'
import {
	Validators,
	TextFormField,
	FormField,
	Text,
	PasswordStrengthMeter
} from '@/components/UberForm'
import { MIN_PASSWORD_LENGTH } from '@/constants'
import { PermissionsModal } from './PermissionsModal'
import { useApiRequest } from '@/api/hooks'
import { addUser, editUser } from '@/api'

type Props = {
	user?: Partial<UserDto>
	onSave: () => void
	onClose: () => void
}

export const UserModal = ({ user, onSave, onClose }: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const [password, setPassword] = useState('')
	const [createdUser, setCreatedUser] = useState(null as UserDto | null)

	const handleSubmitCreate = async (form: NewUserDto) => {
		const { data } = await request(addUser(form))
		setCreatedUser(data)
	}

	const handleSubmitModify = async (form: EditUserInfoDto) => {
		await request(editUser(user?.id as number, { body: form }))
		onSave()
		onClose()
	}

	const onClosePermissions = () => {
		onSave()
		onClose()
	}

	return (
		<>
			<ModalForm<EditUserInfoDto | NewUserDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: '400px'
				}}
				initialValues={{
					loginName: user?.loginName,
					email: user?.email,
					name: user?.name,
					surname: user?.surname,
					department: user?.department,
					description: user?.description
				}}
				header={user ? t('UPDATE_USER') : t('NEW_USER')}
				onSubmit={v =>
					user?.id
						? handleSubmitModify(v as EditUserInfoDto)
						: handleSubmitCreate(v as NewUserDto)
				}
			>
				<TextFormField title={t('USER_LOGIN_NAME')} name="loginName" required />
				<TextFormField
					title={t('EMAIL')}
					name="email"
					required
					validators={[Validators.email()]}
				/>
				{!user && (
					<FormField
						name="password"
						title={t('USER_PASSWORD')}
						required
						validators={[
							Validators.minLength(MIN_PASSWORD_LENGTH),
							Validators.passwordStrength()
						]}
						onChange={value => setPassword(value as string)}
					>
						<PasswordStrengthMeter password={password} />
						<Text />
					</FormField>
				)}
				<TextFormField title={t('USER_NAME')} name="name" required />
				<TextFormField title={t('USER_SURNAME')} name="surname" required />
				<TextFormField title={t('USER_DEPARTMENT')} name="department" />
				<TextFormField title={t('USER_DESC')} name="description" />
			</ModalForm>

			{createdUser && (
				<PermissionsModal
					user={createdUser}
					onSave={onClosePermissions}
					onClose={onClosePermissions}
				/>
			)}
		</>
	)
}
