import { CustomAttributeComboDto, TableColumn } from '@/api/models'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import {
	CheckBoxFormField,
	SelectFormField,
	TextFormField,
	Validators
} from '@/components/UberForm'
import ArrayFormField from '@/components/UberForm/arrayField/ArrayFormField'
import { InputTypeEnum } from '@/components/UberForm/arrayField/types'
import NumberFormField from '@/components/UberForm/Helpers/NumberFormField'
import { useAppContext, useAppStore } from '@/utils/hooks'
import React, { useMemo } from 'react'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { TableMode } from '@/store/modules/table/types'
import {
	getModeForm,
	getUnsyncedFields
} from '@/pages/User/pages/Home/pages/TableDetail/utils'
import { getCustomAttributeName } from '@/pages/User/pages/Home/components/CustomAttributes/utils'
import { CustomAttributesSwitcherProps } from '@/pages/User/pages/Home/components/CustomAttributes/types'

export const CustomAttributesSwitcher = <T extends TableColumn>({
	customAttribute,
	item,
	page
}: CustomAttributesSwitcherProps<T>) => {
	const { t } = useAppContext()

	const { state } = useDetailTabContext() || {}

	const tables = useAppStore(state => state.table.tables)
	const table = tables[state?.node.id]
	const mode = table ? table.mode : TableMode.TABLE

	const [unsyncedFields, modeForm] = useMemo(() => {
		const unsyncedFields = getUnsyncedFields(mode, table?.form)
		const modeForm = getModeForm(mode, table?.form, unsyncedFields)

		return [unsyncedFields, modeForm]
	}, [mode, table])

	const sharedProps = useMemo(
		() => ({
			name: getCustomAttributeName(page, customAttribute, item),
			title: `${customAttribute.name}`
		}),
		[customAttribute, item, page]
	)

	switch (customAttribute.type) {
		case CustomAttributeComboDto.TypeEnum.BOOLEAN: {
			return <CheckBoxFormField {...sharedProps} />
		}

		case CustomAttributeComboDto.TypeEnum.NUMBER: {
			return <NumberFormField {...sharedProps} />
		}

		case CustomAttributeComboDto.TypeEnum.SCRIPT: {
			return <AceEditorField {...sharedProps} />
		}

		case CustomAttributeComboDto.TypeEnum.ARRAY: {
			return (
				<ArrayFormField
					{...sharedProps}
					fields={[
						{
							name: 'value',
							inputType: InputTypeEnum.TEXT,
							placeholder: t('VALUE')
						}
					]}
				/>
			)
		}

		case CustomAttributeComboDto.TypeEnum.LOV: {
			return (
				<SelectFormField {...sharedProps} options={customAttribute.lovValues} />
			)
		}

		case CustomAttributeComboDto.TypeEnum.STRING: {
			return (
				<TextFormField
					{...sharedProps}
					validators={
						customAttribute.regex
							? [Validators.customRegex(customAttribute.regex)]
							: undefined
					}
				/>
			)
		}

		default: {
			console.error('Unknown custom attribute type')

			return <>Unknown custom attribute type</>
		}
	}
}
